import React, { useContext, useEffect, useState } from "react";
import Banner from "../../components/Banner";
import "./login.scss";
import LoginForm from "../../components/LoginForm/LoginForm";
import RadiantBorder from "../../components/RadiantBorder/RadiantBorder";
import OtpComponent from "../../components/OtpForm/OtpComponent";
import ForgotCredential from "../../components/ForgotCredentials";
import Credentailrecover from "../../components/CredentialRecover";
import UserIdGeneration from "../../components/UserIdGeneration";
import PasswordRecover from "../../components/PasswordRecover";
import EnterNewPassword from "../../components/EnterNewPassword";
import RecoverCredential from "../../components/RecoverCredential";

import DataMapping from "../../../../Shared/context/data-mapping";
import FailurePopUp from "../../../../Retail/src/global/FailurePopUp";
import { RESET_CONTEXT } from "../../../../Shared/context/data-mapping/action/action.type";
import useReactiveForm from "../../hooks/useReactiveForm/useReactiveForm";
import Validators from "../../hooks/useReactiveForm/validator";

const Login = () => {
    const [loginTab, setLoginTab] = useState("mobNum");
    const [counter, setCounter] = useState(0);
    const [captcha, setCaptcha] = useState(null);
    const [userContext, setUserContext] = useState("")
    const [recieveOtp, setRecieveOtp] = useState("")
    const [otpTimerFlag, setOtpTimerFlag] = useState(false);
    const [forgotDetails, setForgotDetails] = useState({
        mobile: "",
        email: ""
    })
    const [otpDetails, setOtpDetails] = useState({
        otpRef: "",
        otpAuthToken: ""
    })
    const [,dispatchData] = useContext(DataMapping);

    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": "",
    });


    const [forgotForm, setForgotForm] = useState({
        field: { value: "", error: "" }
    })
    const [newId, setNewId] = useState("");

    const COMPONENT_MAP = {
        mobNum: [LoginForm, OtpComponent],
        emailId: [LoginForm, OtpComponent],
        userId: [LoginForm],
        helpUs: [RecoverCredential],
        forgotId: [ForgotCredential, OtpComponent, UserIdGeneration],
        forgotPass: [ForgotCredential, Credentailrecover, OtpComponent, EnterNewPassword, PasswordRecover]
    }

    const [loginTabForm, handleForm] = useReactiveForm({
        fieldOne: ["", [Validators.required, Validators.pattern(/^\d+$/)]],
        fieldTwo: ["", [Validators.required, Validators.pattern(/^[a-z0-9]+$/i)]]
    })

    const Component = COMPONENT_MAP[loginTab][counter];

    useEffect(() => {
        dispatchData({ type: RESET_CONTEXT });
    }, [])

    return (
        <div className="c_login_container">
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            <div className="c_login_title">
                {
                    loginTab === 'mobNum' || loginTab === 'emailId' || loginTab === 'userId' ? <>
                        <p className="c_compo_title">Log in</p>
                        <p className="c_compo_desc">To manage your Finances today!</p>
                    </> : null
                }
            </div>

            <div className="c_banner_container ">
                <Banner />
            </div>
            <div className="c_form_container ">
                <RadiantBorder>
                    <Component
                        captcha={captcha}
                        setLoginTab={setLoginTab}
                        loginTab={loginTab}
                        setCounter={setCounter}
                        counter={counter}
                        setCaptcha={setCaptcha}
                        otpDetails={otpDetails}
                        setOtpDetails={setOtpDetails}
                        loginTabForm={loginTabForm}
                        handleForm={handleForm}
                        forgotForm={forgotForm}
                        setForgotForm={setForgotForm}
                        newId={newId}
                        setNewId={setNewId}
                        forgotDetails={forgotDetails}
                        setForgotDetails={setForgotDetails}
                        userContext={userContext}
                        setUserContext={setUserContext}
                        recieveOtp={parseInt(recieveOtp)}
                        setRecieveOtp={setRecieveOtp}
                        setOtpTimerFlag={setOtpTimerFlag}
                        otpTimerFlag={otpTimerFlag}
                        setFailurePopUpModal={setFailurePopUpModal}
                        setFailurePopUpContent={setFailurePopUpContent}
                    />
                </RadiantBorder>
            </div>
        </div>

    );
};

export default Login;






