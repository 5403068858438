import isTrue from './isBooleanTrue';
import isEqRef from './isEqualByReference';

export default function isEqualByValue(val1, val2, caseInsensitive) {
    var val1Str = String(val1);
    var val2Str = String(val2);
    if (isTrue(caseInsensitive)) {
        val1Str = val1Str.toLowerCase();
        val2Str = val2Str.toLowerCase();
    }
    return isEqRef(val1Str, val2Str);
}