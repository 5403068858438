import React from "react";
import PropTypes  from "prop-types";

const ValidationPill = ({checkValidFor, valid}) => {
    return (
        <>
            <span className='validation-icon'>
              <img
                src={valid ? "assets/images/check_circle_green.svg" : "assets/images/check_circle_gray.svg"}
                alt={valid ? "check_circle_green" : "check_circle_gray"}
              />
              <span>{checkValidFor}</span>
            </span>
        </>
    )
}

ValidationPill.propTypes = {
  valid : PropTypes.bool,
  checkValidFor:PropTypes.string
}
export default ValidationPill;