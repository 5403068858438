import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const pledgeDetailsFoliowise = (data) => {

    let requestJson = {

        "header": {
            "authToken": "d61714a3ae14ea55403cba80301723f31449653fa1a897aeea5ad90898dc98bf"
        },
        "body": {
            "inputFieldType": "LoanAccountID",
            "inputFieldValue": 1347
        }


    }

    return new Promise((resolve, reject) => {
        // axios.post("/shaft/api/las-esb/pledge-details-folio-wise/v1", data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-esb/pledge-details-folio-wise/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default pledgeDetailsFoliowise;