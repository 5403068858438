import React from "react";
import "./Loader.scss"
const Loader = () => {
    return (
        <>
            <div className="loader-container">
                <div>
                    <div className="loader-logo">
                        <img src="assets/images/TataCapitalLogo.svg" alt="" />
                    </div>
                    <div className="animation-line">

                    </div>
                </div>



            </div>
        </>
    )
}

export default Loader