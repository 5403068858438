import React from "react";
import PropTypes from "prop-types";
import "./SlickCard.scss";
import { off } from "process";

const SlickCard = ({
  src = "",
  alt = "",
  loanName = "",
  loanNumber = "",
  handleClick,
  selected = false,
  offerFlag,
  offerKey,
}) => {
  return (
    <div
      id={loanNumber}
      className="c_slick_card_comp c_slick_card"
      onClick={handleClick}
    >
      {offerFlag === "Y" && (
        <img
          src={"assets/images/balloon.svg"}
          className="slick_ballon slick_ballon_icon"
          alt="OfferNotification"
          width={"40px"}
          height={"40px"}
        />
        //   <img
        //   className="slick_ballon"
        //   src={`assets/gifs/success.gif`}
        //   alt="green-tick"
        //   width={"40px"}
        //   height={"40px"}
        // />
        // <img
        //   className="slick_ballon tick-icon"
        //   src="assets/images/right-tick.svg"
        //   width={"20px"}
        //   height={"20px"}
        // />
      )}
      <img />
      <img className="c_two_wheeler_icon" src={src} alt={alt} />
      <div className="c_loan_summary">
        <p
          className={`c_para_loan_name ${
            selected ? "c_para_loan_name_color" : ""
          }`}
        >
          {loanName}
        </p>
        <p
          className={`c_para_loan_number ${
            selected ? "c_para_loan_number_color" : ""
          }`}
        >
          {loanNumber}
        </p>
        {/* {offerFlag === "Y" && <span> Offer</span>} */}
      </div>
    </div>
  );
};

SlickCard.propTypes = {
  handleClick: PropTypes.func,
  src: PropTypes.string,
  alt: PropTypes.string,
  loanName: PropTypes.string,
  loanNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  selected: PropTypes.bool,
};

export default SlickCard;
