import React, { useRef,useContext, useEffect } from "react";
import "./credentailrecover.scss";
import Input from "../../FormFields/Input";
import Button from "../../FormFields/Button";
import { generateOtp } from "../../services/generateOtp";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import { maskDetails } from "../../services/maskDetails";
import PropTypes from 'prop-types'


const Credentailrecover = ({
  forgotDetails,
  userContext,
  recieveOtp,
  setRecieveOtp,
  setUserContext,
  setCounter,
  setOtpDetails,
  setOtpTimerFlag,
}) => {

  const emailRef = useRef();
  const mobRef = useRef();
const [, dispathData] = useContext(DataMapping)
  let maskedMobileNumber = maskDetails(forgotDetails?.mobile);
  let maskedEmailId = maskDetails(forgotDetails?.email);


    useEffect(()=>{
        setRecieveOtp(forgotDetails?.mobile);
        setUserContext("mobNum");
    }, [])

  const handleClick = () => {
    dispathData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA
  })
    if (recieveOtp) {
        generateOtp(userContext,recieveOtp, "", "", "forgotJourney").then((res) => {
          if (res.body.retStatus == "SUCCESS") {
            setOtpDetails(() => {
              return {
                otpRef: res.body.otpRefNo,
                otpAuthToken: res.header.authToken,
              };
            })
            dispathData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA
          })
            setCounter((prev) => prev + 1)
            setOtpTimerFlag(true);
          }
        })
    } else {
        dispathData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA
        })
    }

  }

  

  return (
    <>
      <div className="c_credentialrecover_wrap">
        <div className="c_otp_arrow_backbtn" onClick={() => setCounter((prev) => prev - 1)}>
          <img src="assets/images/arrow_back.svg" className="" />
          Back
        </div>
        <p className="c_title">Forgot Password?</p>
        <p className="c_description">
          No worries, Please choose how you want to receive OTP
        </p>
        <div className="c_inputwrap">
          <form>
            <div className="c_input c_credential_recover">
              <Input
                defaultChecked="checked"
                className="c_radio"
                type="radio"
                id="mobnum"
                name="recover"
                value="withMo"
                onChange={() => {
                  setRecieveOtp(forgotDetails?.mobile)
                  setUserContext("mobNum")
                }}
              />
              
              <label className="c_otp_div_label" htmlFor="mobnum">
                <div className="c_otp_div" id="mobile" ref={mobRef} onClick={() => {
                  setRecieveOtp(forgotDetails?.mobile)
                  setUserContext("mobNum")
                }}>{
                    maskedMobileNumber.encryptedValue
                }</div>
              </label>
            </div>
            <div className="c_input c_credential_recover">
              <Input
                className="c_radio"
                type="radio"
                id="email"
                name="recover"
                value="withEmail"
                onChange={() => {
                  setRecieveOtp(forgotDetails?.email)
                  setUserContext("emailId")
                }}
              />
              <label className="c_otp_div_label" htmlFor="email">
                <div className="c_otp_div" id="email" ref={emailRef} onClick={() => {
                  setRecieveOtp(forgotDetails?.email)
                  setUserContext("emailId")
                }}>{
                    forgotDetails?.email && maskedEmailId.encryptedValue
                }</div>
              </label>
            </div>
            <span className="error"></span>
          </form>
          <div className="c_recover_btn">
            <Button className="login" onClick={handleClick}>
              Continue
              <img
                className="c_next_arrow"
                src="assets/images/right-arrow-white.svg"
                alt=""
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

Credentailrecover.propTypes = {
  forgotDetails:PropTypes.object,
  userContext:PropTypes.string,
  recieveOtp:PropTypes.string,
  setRecieveOtp:PropTypes.func,
  setUserContext:PropTypes.func,
  setCounter:PropTypes.func,
  setOtpDetails:PropTypes.func,
  setOtpTimerFlag:PropTypes.func,
}

export default Credentailrecover;
