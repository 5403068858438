import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const generateOtp = (data) => {
    let requestJson = {
        "header": {
            "grantType": "otp",
            "serviceType": "mobile"
        },
        "body": {
            "mobileNo": "9594779789"
        }
    }
    return new Promise((resolve, reject) => {
        
        stalwartApiCall('POST', data, '/shaft/api/login/generate-otp/v1').then(response => {
            
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export default generateOtp;