import React, { useContext } from "react";
import "./DidYouKnow.scss";
import DidYouKnowText from "../DidYouKnowText/DidYouKnowText";
import PropTypes from "prop-types";
import DataMapping from "../../../../Shared/context/data-mapping";

const DidYouKnow = ({ heading = "" }) => {

  const [data] = useContext(DataMapping)

  const nudges = ["Always pay on time for maintaining healthy Credit score and enjoy better interest rates for future credits.",
    "Non-payment of EMIs on time attract additional penal interest. Pay on time and avoid paying extra charges.",
    "Non - payment of EMIs on time attract additional penal interest.Pay on time and avoid paying extra charges.",
    "Consistently pay your loan Equated Monthly Instalments (EMIs) and credit card bills on time. A credit report free of EMI defaults and late payments perks up your credit score."
  ]

  const currentNudges = data?.offers?.body?.offerDetails.find(elem => elem["LOAN_ACCOUNT_NO"] === data.currentActiveData.accountNumber);
  // console.log(currentNudges,"currentNudges");

//   const nudgeArray = [];

// for (let i = 1; i <= 10; i++) {
//     const nudgeKey = `NUDGE_${i}`;
//     if (currentNudges[nudgeKey]) {
//         nudgeArray.push(currentNudges[nudgeKey]);
//     }
// }

// console.log(nudgeArray);


  return (
    <div className="c_did_you_know">
      <div className="c_did_you_know_heading_div">
        <h2 className="c_did_you_know_heading">{heading}</h2>
      </div>
      <div className="c_did_you_know_comp">
        {currentNudges && currentNudges.Nudge ? (
          currentNudges.Nudge.map((dynamicNudge, i) => (
            <div key={i}>
              <DidYouKnowText
                icon="icon"
                src="assets/images/did-you-know-icon.svg"
                text={dynamicNudge}
              />
            </div>
          ))
        ) : (
          nudges.map((staticNudge, i) => (
            <div key={i}>
              <DidYouKnowText
                icon="icon"
                src="assets/images/did-you-know-icon.svg"
                text={staticNudge}
              />
            </div>
          ))
        )}
      </div>
    </div >
  );
};

DidYouKnow.propTypes = {
  heading: PropTypes.string,
};

export default DidYouKnow;
