import React from "react";
import "./DashboardDataHead.scss";
import SlickCard from "../SlickCard";
import PropTypes from "prop-types";
import { useContext } from "react";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DashboardDataHead = ({
  data,
  setActiveLoan,
  activeLoan,
  setPreapprovedOfferFlag,
  attemptFlag,
  payNowFlag,
  setPaymentSummeryFlag,
  setAttemptFlag,
  paymentFlag,
  validateFlagEmail,
  setValidateFlagEmail,
  validateFlagMobile,
  setValidateFlagMobile,
  setPayNowFlag,
  disableBtn,
  setDisableBtn
}) => {
  const [contextData, dispatcher] = useContext(DataMapping);

  useEffect(() => {
    const getofferKey = (loan) => {
      const keysToCheck = [
        "SETTLEMENT_CAMPAIGN",
        "EMI_OVERDUE_CAMPAIGN",
        "ODBC_CAMPAIGN",
        "LOS_POOL_CAMPAIGN",
        "ALREADY_SETTLED_POOL_CAMPAIGN",
      ];

      for (const key of keysToCheck) {
        if (loan[key] === "Y") {
          return key;
        }
      }

      return null;
    };
    // console.log(data, "dataaaaa");
    const storedPopupArray = sessionStorage.getItem("hidePopupData");
    let popupData = storedPopupArray ? JSON.parse(storedPopupArray) : [];
    if (activeLoan === data[0].contractNumber) {
      // console.log(":A");
      const result = data.find((element) => {
        if (
          element.offerData &&
          element.offerData["LOAN_ACCOUNT_NO"] === activeLoan
        ) {
          return true;
        }
        return false;
      });

      // console.log(result, "result");
      if(sessionStorage.getItem("phase1-payment-failure") || sessionStorage.getItem("phase1-payment-success")){
        setPreapprovedOfferFlag(false);
        setAttemptFlag(false);
        sessionStorage.removeItem("phase1-payment-failure");
        sessionStorage.removeItem("phase1-payment-success")
      }else{
        if (result?.offerData) {
          let currentOfferName = getofferKey(result?.offerData);
          // console.log(currentOfferName, "hebhcjbehbchj");
          dispatcher({
            name: "currentOfferPopup",
            payload: currentOfferName,
            type: STOREDATA,
          });
          if (
            result?.contractNumber ===
            contextData?.attempt?.loanAttempts[activeLoan]
          ) {
            if (contextData?.attempt?.loanAttempts[activeLoan].attempts == 0) {
              setAttemptFlag(true);
            }
          }
  
          if (
            result?.settlementFlag === "Y" &&
            result?.offerFlag === "Y" &&
            result?.loanStatus === "Active"
          ) {
            if (
              result?.offerData["IS_EMI_NORMALIZED"] == "Y" ||
              sessionStorage.getItem("emi-payment-success") ||
              sessionStorage.getItem("emi-payment-failure")
            ) {
              if (sessionStorage.getItem("emi-payment-failure")) {
                dispatcher({
                  name: "offerModaLFlag",
                  payload: {
                    modalFlag: false,
                    accountType: "availOfferModal",
                  },
                  type: STOREDATA,
                });
              } else if (sessionStorage.getItem("emi-payment-success")) {
                if (result?.offerData["IS_OFFER_AVAILED"] == "N") {
                  if (result?.offerData["NO_OF_ATTEMPTS"] > 0) {
                    const emiOverdueFlowFunc = () => {
                      if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                        if (result?.offerData["IS_TOTAL_OVERDUE_PAID"] == "N") {
                          setPreapprovedOfferFlag(false);
                          setAttemptFlag(false);
                          dispatcher({
                            type: STOREDATA,
                            payload: true,
                            name: "callpgs",
                          });
                          dispatcher({
                            name: "offerModaLFlag",
                            payload: {
                              modalFlag: false,
                              accountType: "availOfferModal",
                            },
                            type: STOREDATA,
                          });
                        } else if (
                          result?.offerData["IS_TOTAL_OVERDUE_PAID"] !== "N"
                        ) {
                          setPreapprovedOfferFlag(true);
                          setAttemptFlag(true);
                          dispatcher({
                            name: "offerModaLFlag",
                            payload: {
                              modalFlag: false,
                              accountType: "availOfferModal",
                            },
                            type: STOREDATA,
                          });
                        }
                      } else if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(false);
                        dispatcher({
                          name: "offerModaLFlag",
                          payload: {
                            modalFlag: false,
                            accountType: "availOfferModal",
                          },
                          type: STOREDATA,
                        });
                      }
                    };
                    if (sessionStorage.getItem("emi-payment-success")) {
                      if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey ==
                        "greyed-out"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(true);
                        dispatcher({
                          name: "offerModaLFlag",
                          payload: {
                            modalFlag: false,
                            accountType: "availOfferModal",
                          },
                          type: STOREDATA,
                        });
                      } else if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey == "phase1"
                      ) {
                        setPreapprovedOfferFlag(false);
                        setAttemptFlag(false);
                        sessionStorage.clear("phase1");
                        dispatcher({
                          name: "offerModaLFlag",
                          payload: {
                            modalFlag: false,
                            accountType: "availOfferModal",
                          },
                          type: STOREDATA,
                        });
                      } else if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey ==
                        "not-normalised"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(false);
                        sessionStorage.clear("not-normalised");
                        sessionStorage.setItem(
                          "emi-payment-edge-case-not-normalised",
                          "true"
                        );
                        // emiOverdueFlowFunc();
                        // dispatcher({
                        //   name: "offerModaLFlag",
                        //   payload: {
                        //     modalFlag: true,
                        //     accountType: "availOfferModal",
                        //   },
                        //   type: STOREDATA,
                        // });
                      }
                    } else {
                      emiOverdueFlowFunc();
                    }
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] == "Y") {
                  if (result?.offerData["NO_OF_ATTEMPTS"] > 0) {
                    const emiOverdueFlowFunc = () => {
                      if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                        if (result?.offerData["IS_TOTAL_OVERDUE_PAID"] == "N") {
                          setPreapprovedOfferFlag(false);
                          setAttemptFlag(false);
                          dispatcher({
                            type: STOREDATA,
                            payload: true,
                            name: "callpgs",
                          });
                        } else if (
                          result?.offerData["IS_TOTAL_OVERDUE_PAID"] !== "N"
                        ) {
                          setPreapprovedOfferFlag(true);
                          setAttemptFlag(true);
                        }
                      } else if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(false);
                      }
                    };
                    if (sessionStorage.getItem("emi-payment-success")) {
                      if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey ==
                        "greyed-out"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(true);
                      } else if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey == "phase1"
                      ) {
                        setPreapprovedOfferFlag(false);
                        setAttemptFlag(false);
                        sessionStorage.clear("phase1");
                      } else if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey ==
                        "not-normalised"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(false);
                        sessionStorage.clear("not-normalised");
                        // emiOverdueFlowFunc();
                      }
                    } else {
                      emiOverdueFlowFunc();
                    }
                  }
                }
                dispatcher({
                  name: "offerModaLFlag",
                  payload: {
                    modalFlag: false,
                    accountType: "availOfferModal",
                  },
                  type: STOREDATA,
                });
              } else if (result?.offerData["IS_OFFER_AVAILED"] == "Y") {
                if (result?.offerData["NO_OF_ATTEMPTS"] > 0) {
                  const emiOverdueFlowFunc = () => {
                    if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                      if (result?.offerData["IS_TOTAL_OVERDUE_PAID"] == "N") {
                        setPreapprovedOfferFlag(false);
                        setAttemptFlag(false);
                        dispatcher({
                          type: STOREDATA,
                          payload: true,
                          name: "callpgs",
                        });
                        dispatcher({
                          name: "currentOfferPopup",
                          payload: "NON_OFFER",
                          type: STOREDATA,
                        });
                        dispatcher({
                          name: "offerModaLFlag",
                          payload: {
                            modalFlag: false,
                            accountType: "availOfferModal",
                          },
                          type: STOREDATA,
                        });
                      } else if (
                        result?.offerData["IS_TOTAL_OVERDUE_PAID"] !== "N" ||
                        result?.offerData["IS_TOTAL_OVERDUE_PAID"] == "Y"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(true);
                        dispatcher({
                          name: "offerModaLFlag",
                          payload: {
                            modalFlag: false,
                            accountType: "availOfferModal",
                          },
                          type: STOREDATA,
                        });
                      }
                    } else if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(false);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: true,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                    }
                  };
                  if (sessionStorage.getItem("emi-payment-success")) {
                    if (
                      contextData?.emiOverdueOfferRes?.emiOfferKey == "greyed-out"
                    ) {
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                    } else if (
                      contextData?.emiOverdueOfferRes?.emiOfferKey == "phase1"
                    ) {
                      setPreapprovedOfferFlag(false);
                      setAttemptFlag(false);
                      sessionStorage.clear("phase1");
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                    } else if (
                      contextData?.emiOverdueOfferRes?.emiOfferKey ==
                      "not-normalised"
                    ) {
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(false);
                      sessionStorage.clear("not-normalised");
                      sessionStorage.setItem(
                        "emi-payment-edge-case-not-normalised",
                        "true"
                      );
                      // emiOverdueFlowFunc();
                    }
                  } else {
                    emiOverdueFlowFunc();
                  }
                }
              }
            } else if (result?.offerData["IS_EMI_NORMALIZED"] == "N") {
              if (result?.offerData["NO_OF_ATTEMPTS"] > 0) {
                if (result?.offerData["IS_OFFER_AVAILED"] === "N") {
                  setPreapprovedOfferFlag(true);
                  setAttemptFlag(false);
                  setPayNowFlag(false);
                  const isStringPresent = popupData?.includes("pay");
  
                  if (!isStringPresent) {
                    dispatcher({
                      name: "offerModaLFlag",
                      payload: {
                        modalFlag: true,
                        accountType: "availOfferModal",
                      },
                      type: STOREDATA,
                    });
                    if (sessionStorage.getItem("settlement-mid-journey")) {
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                      sessionStorage.clear("settlement-mid-journey");
                    }
                  } else if (isStringPresent) {
                    const elementToRemove = "pay";
                    popupData = popupData.filter(
                      (item) => item !== elementToRemove
                    );
                    sessionStorage.setItem(
                      "hidePopupData",
                      JSON.stringify(popupData)
                    );
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "Y") {
                  const isPaymentDone = popupData?.includes("pay");
                  if (isPaymentDone) {
                    if (sessionStorage.getItem("payment-success")) {
                      //GreyOut Payment Component Logic
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "paymentPlanModal",
                        },
                        type: STOREDATA,
                      });
                      sessionStorage.clear("payment-success");
                    } else if (sessionStorage.getItem("payment-failed")) {
                      //GreyOut Payment Component Logic
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: true,
                          accountType: "paymentPlanModal",
                        },
                        type: STOREDATA,
                      });
                      sessionStorage.clear("payment-failed");
                    }
                  } else {
                    if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                      //GreyOut Payment Component Logic
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "paymentPlanModal",
                        },
                        type: STOREDATA,
                      });
                    } else if (
                      result?.offerData["IS_OFFER_AVAILED"] === "Y" &&
                      result?.offerData["IS_PAYMENT_DONE"] === "N"
                    ) {
                      sessionStorage.setItem("paymentmodal-background-amount-flag",true)
                      if (result?.offerData["IS_TRANCHE_OPTED"]) {
                        if (result?.offerData["IS_TRANCHE_OPTED"] === "N") {
                          if (result?.offerData["NO_OF_TRANCHES_OPTED"]) {
                            if (
                              result?.offerData["NO_OF_TRANCHES_OPTED"] === "1"
                            ) {
                              setPreapprovedOfferFlag(true);
                              setPaymentSummeryFlag(false);
                              setAttemptFlag(true);
                              const currentLoanApprovedAmount =
                                contextData?.approvedLoanAmounts
                                  ?.approvedLoanData || {};
  
                              if (
                                currentLoanApprovedAmount[
                                  contextData.currentActiveData.accountNumber
                                ]
                              ) {
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue:
                                      result?.offerData["OFFER_APPROVED_AMOUNT"],
                                  },
                                };
  
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              } else {
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue:
                                      result?.offerData["OFFER_APPROVED_AMOUNT"],
                                  },
                                };
  
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              }
                              dispatcher({
                                name: "offerModaLFlag",
                                payload: {
                                  modalFlag: true,
                                  accountType: "paymentPlanModal",
                                },
                                type: STOREDATA,
                              });
                            }
                          } else {
                            setPreapprovedOfferFlag(true);
                            setPaymentSummeryFlag(false);
                            setAttemptFlag(true);
                            const currentLoanApprovedAmount =
                              contextData?.approvedLoanAmounts
                                ?.approvedLoanData || {};
  
                            if (
                              currentLoanApprovedAmount[
                                contextData.currentActiveData.accountNumber
                              ]
                            ) {
                              const updatedLoanApprovalData = {
                                ...currentLoanApprovedAmount,
                                [contextData.currentActiveData.accountNumber]: {
                                  approvedAmountValue:
                                    result?.offerData["OFFER_APPROVED_AMOUNT"],
                                },
                              };
  
                              dispatcher({
                                name: "approvedLoanAmounts",
                                payload: {
                                  approvedLoanData: updatedLoanApprovalData,
                                },
                                type: STOREDATA,
                              });
                            } else {
                              const updatedLoanApprovalData = {
                                ...currentLoanApprovedAmount,
                                [contextData.currentActiveData.accountNumber]: {
                                  approvedAmountValue:
                                    result?.offerData["OFFER_APPROVED_AMOUNT"],
                                },
                              };
  
                              dispatcher({
                                name: "approvedLoanAmounts",
                                payload: {
                                  approvedLoanData: updatedLoanApprovalData,
                                },
                                type: STOREDATA,
                              });
                            }
                            dispatcher({
                              name: "offerModaLFlag",
                              payload: {
                                modalFlag: true,
                                accountType: "paymentPlanModal",
                              },
                              type: STOREDATA,
                            });
                          }
                        } else if (
                          result?.offerData["IS_TRANCHE_OPTED"] === "Y"
                        ) {
                          if (result?.offerData["NO_OF_TRANCHES_OPTED"] === "1") {
                            const currentLoanApprovedAmount =
                              contextData?.approvedLoanAmounts
                                ?.approvedLoanData || {};
  
                            if (
                              currentLoanApprovedAmount[
                                contextData.currentActiveData.accountNumber
                              ]
                            ) {
                              const updatedLoanApprovalData = {
                                ...currentLoanApprovedAmount,
                                [contextData.currentActiveData.accountNumber]: {
                                  approvedAmountValue:
                                    result?.offerData["OFFER_APPROVED_AMOUNT"],
                                },
                              };
  
                              dispatcher({
                                name: "approvedLoanAmounts",
                                payload: {
                                  approvedLoanData: updatedLoanApprovalData,
                                },
                                type: STOREDATA,
                              });
                            } else {
                              const updatedLoanApprovalData = {
                                ...currentLoanApprovedAmount,
                                [contextData.currentActiveData.accountNumber]: {
                                  approvedAmountValue:
                                    result?.offerData["OFFER_APPROVED_AMOUNT"],
                                },
                              };
  
                              dispatcher({
                                name: "approvedLoanAmounts",
                                payload: {
                                  approvedLoanData: updatedLoanApprovalData,
                                },
                                type: STOREDATA,
                              });
                            }
                            setAttemptFlag(true);
                            setPreapprovedOfferFlag(true);
                            setPaymentSummeryFlag(false);
                            dispatcher({
                              name: "offerModaLFlag",
                              payload: {
                                modalFlag: true,
                                accountType: "paymentPlanModal",
                              },
                              type: STOREDATA,
                            });
                          } else if (
                            result?.offerData["NO_OF_TRANCHES_OPTED"] === "2"
                          ) {
                            const getTrancheAmount = (amount, percentage) => {
                              const percentageDecimal =
                                parseFloat(percentage) / 100;
                              const result = amount * percentageDecimal;
                              return result;
                            };
                            if (
                              result?.offerData["TRANCHE1_PAYMENT_STATUS"] ===
                              "Success"
                            ) {
                              const currentLoanApprovedAmount =
                                contextData?.approvedLoanAmounts
                                  ?.approvedLoanData || {};
  
                              if (
                                currentLoanApprovedAmount[
                                  contextData.currentActiveData.accountNumber
                                ]
                              ) {
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue: getTrancheAmount(
                                      result?.offerData["OFFER_APPROVED_AMOUNT"],
                                      result?.offerData["TRANCHE_2"]
                                    ),
                                  },
                                };
  
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              } else {
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue: getTrancheAmount(
                                      result?.offerData["OFFER_APPROVED_AMOUNT"],
                                      result?.offerData["TRANCHE_2"]
                                    ),
                                  },
                                };
  
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              }
                            } else {
  
                              const currentLoanApprovedAmount = contextData?.approvedLoanAmounts?.approvedLoanData || {};
  
                              if (
                                currentLoanApprovedAmount[contextData.currentActiveData.accountNumber]
                              ) {
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue: getTrancheAmount(
                                  result?.offerData["OFFER_APPROVED_AMOUNT"],
                                  result?.offerData["TRANCHE_1"]
                                ),
                                  },
                                };
                    
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              }else{
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue: getTrancheAmount(
                                  result?.offerData["OFFER_APPROVED_AMOUNT"],
                                  result?.offerData["TRANCHE_1"]
                                ),
                                  },
                                };
                    
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              }
                            }
                            setAttemptFlag(true);
                            setPreapprovedOfferFlag(true);
                            setPaymentSummeryFlag(false);
                            dispatcher({
                              name: "offerModaLFlag",
                              payload: {
                                modalFlag: true,
                                accountType: "paymentPlanModal",
                              },
                              type: STOREDATA,
                            });
                          }
                        }
                      } else {
                        setAttemptFlag(true);
                        setPreapprovedOfferFlag(true);
                        setPaymentSummeryFlag(false);
                        const currentLoanApprovedAmount = contextData?.approvedLoanAmounts?.approvedLoanData || {};
  
                        if (
                          currentLoanApprovedAmount[contextData.currentActiveData.accountNumber]
                        ) {
                          const updatedLoanApprovalData = {
                            ...currentLoanApprovedAmount,
                            [contextData.currentActiveData.accountNumber]: {
                              approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                            },
                          };
              
                          dispatcher({
                            name: "approvedLoanAmounts",
                            payload: {
                              approvedLoanData: updatedLoanApprovalData,
                            },
                            type: STOREDATA,
                          });
                        }else{
                          const updatedLoanApprovalData = {
                            ...currentLoanApprovedAmount,
                            [contextData.currentActiveData.accountNumber]: {
                              approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                            },
                          };
              
                          dispatcher({
                            name: "approvedLoanAmounts",
                            payload: {
                              approvedLoanData: updatedLoanApprovalData,
                            },
                            type: STOREDATA,
                          });
                        }
                        dispatcher({
                          name: "offerModaLFlag",
                          payload: {
                            modalFlag: true,
                            accountType: "paymentPlanModal",
                          },
                          type: STOREDATA,
                        });
                      }
                    }
                  }
                }
              } else if (result?.offerData["NO_OF_ATTEMPTS"] == 0) {
                setPreapprovedOfferFlag(true);
                setAttemptFlag(true);
                dispatcher({
                  name: "offerModaLFlag",
                  payload: {
                    modalFlag: false,
                    accountType: "availOfferModal",
                  },
                  type: STOREDATA,
                });
              }
            }
          } else if (
            result?.settlementFlag === "N" &&
            result?.offerFlag === "Y" &&
            result?.loanStatus === "Active"
          ) {
            if (currentOfferName === "EMI_OVERDUE_CAMPAIGN") {
              const emiOverdueFlowFunc = () => {
                if (result?.offerData["IS_OFFER_AVAILED"] === "Y") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                    if (result?.offerData["IS_TOTAL_OVERDUE_PAID"] === "Y") {
                      // console.log("emi overdue offer full payment done");
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                    } else if (
                      result?.offerData["IS_TOTAL_OVERDUE_PAID"] === "N"
                    ) {
                      setPreapprovedOfferFlag(false);
                      setAttemptFlag(false);
                      dispatcher({
                        type: STOREDATA,
                        payload: true,
                        name: "phaseDataFlag",
                      });
                      dispatcher({
                        type: STOREDATA,
                        payload: true,
                        name: "callpgs",
                      });
                      dispatcher({
                        name: "currentOfferPopup",
                        payload: "NON_OFFER",
                        type: STOREDATA,
                      });
                    }
                  } else if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(false);
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "N") {
                  setPreapprovedOfferFlag(true);
                  setAttemptFlag(false);
                }
              };
              if (sessionStorage.getItem("emi-payment-success")) {
                if (
                  contextData?.emiOverdueOfferRes?.emiOfferKey == "greyed-out"
                ) {
                  setPreapprovedOfferFlag(true);
                  setAttemptFlag(true);
                } else if (
                  contextData?.emiOverdueOfferRes?.emiOfferKey == "phase1"
                ) {
                  setPreapprovedOfferFlag(false);
                  setAttemptFlag(false);
                  dispatcher({
                    type: STOREDATA,
                    payload: true,
                    name: "phaseDataFlag",
                  });
                  sessionStorage.clear("phase1");
                } else if (
                  contextData?.emiOverdueOfferRes?.emiOfferKey == "not-normalised"
                ) {
                  setPreapprovedOfferFlag(true);
                  setAttemptFlag(false);
                  sessionStorage.clear("not-normalised");
                  // emiOverdueFlowFunc();
                }
              } else {
                emiOverdueFlowFunc();
              }
            } else if (contextData?.currentOfferPopup === "ODBC_CAMPAIGN") {
              const odbcCampaignFunc = () => {
                if (result?.offerData["IS_OFFER_AVAILED"] === "Y") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(true);
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "N") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(true);
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "Y") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(false);
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "N") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(false);
                  }
                }
              };
  
              if (sessionStorage.getItem("odbc-forego-payment-success")) {
                setPreapprovedOfferFlag(true);
                setAttemptFlag(true);
                sessionStorage.clear("odbc-forego-payment-success");
              } else if (sessionStorage.getItem("odbc-payment-failure")) {
                setPreapprovedOfferFlag(true);
                setAttemptFlag(false);
                sessionStorage.clear("odbc-payment-failure");
              } else if (sessionStorage.getItem("odbc-payment-success")) {
                setPreapprovedOfferFlag(true);
                setAttemptFlag(true);
                sessionStorage.clear("odbc-payment-success");
              } else {
                odbcCampaignFunc();
              }
            }
          } else if (
            result?.settlementFlag === "N" &&
            result?.offerFlag === "Y" &&
            result?.loanStatus === "Closed"
          ) {
            setPreapprovedOfferFlag(true);
          } else if (
            result?.settlementFlag === "N" &&
            result?.offerFlag === "N" &&
            result?.loanStatus === "Active"
          ) {
            
            setPreapprovedOfferFlag(false);
            setAttemptFlag(false);
          } else {
            setPreapprovedOfferFlag(false);
            dispatcher({
              name: "offerModaLFlag",
              payload: {
                modalFlag: false,
                accountType: "availOfferModal",
              },
              type: STOREDATA,
            });
          }
        } else {
          setPreapprovedOfferFlag(false);
          setAttemptFlag(false);
        }
      }
    } else if (activeLoan !== data[0].contractNumber) {
      // console.log(":B");
      // console.log(data);
      // console.log(payNowFlag,attemptFlag,disableBtn,"flagsssss");

      const result = data.find((element) => {
        if (
          element.offerData &&
          element.offerData["LOAN_ACCOUNT_NO"] === activeLoan
        ) {
          return true;
        }
        return false;
      });
      // console.log(result, "result");

      if(sessionStorage.getItem("phase1-payment-failure") || sessionStorage.getItem("phase1-payment-success")){
        setPreapprovedOfferFlag(false);
        setAttemptFlag(false);
        sessionStorage.removeItem("phase1-payment-failure");
        sessionStorage.removeItem("phase1-payment-success")
      }else{
        if (result?.offerFlag) {
          let currentOfferName = getofferKey(result?.offerData);
          // console.log(currentOfferName, "hebhcjbehbchj");
  
          if (
            result?.settlementFlag === "Y" &&
            result?.offerFlag === "Y" &&
            result?.loanStatus === "Active"
          ) {
            if (
              result?.offerData["IS_EMI_NORMALIZED"] == "Y" ||
              sessionStorage.getItem("emi-payment-success") ||
              sessionStorage.getItem("emi-payment-failure")
            ) {
              if (sessionStorage.getItem("emi-payment-failure")) {
                dispatcher({
                  name: "offerModaLFlag",
                  payload: {
                    modalFlag: false,
                    accountType: "availOfferModal",
                  },
                  type: STOREDATA,
                });
              } else if (sessionStorage.getItem("emi-payment-success")) {
                if (result?.offerData["IS_OFFER_AVAILED"] == "N") {
                  if (result?.offerData["NO_OF_ATTEMPTS"] > 0) {
                    const emiOverdueFlowFunc = () => {
                      if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                        if (result?.offerData["IS_TOTAL_OVERDUE_PAID"] == "N") {
                          setPreapprovedOfferFlag(false);
                          setAttemptFlag(false);
                          dispatcher({
                            type: STOREDATA,
                            payload: true,
                            name: "callpgs",
                          });
                        } else if (
                          result?.offerData["IS_TOTAL_OVERDUE_PAID"] !== "N"
                        ) {
                          setPreapprovedOfferFlag(true);
                          setAttemptFlag(true);
                        }
                      } else if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(false);
                      }
                    };
                    if (sessionStorage.getItem("emi-payment-success")) {
                      if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey ==
                        "greyed-out"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(true);
                      } else if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey == "phase1"
                      ) {
                        setPreapprovedOfferFlag(false);
                        setAttemptFlag(false);
                        sessionStorage.clear("phase1");
                      } else if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey ==
                        "not-normalised"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(false);
                        sessionStorage.clear("not-normalised");
                        sessionStorage.setItem(
                          "emi-payment-edge-case-not-normalised",
                          "true"
                        );
                        // emiOverdueFlowFunc();
                      }
                    } else {
                      emiOverdueFlowFunc();
                    }
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] == "Y") {
                  if (result?.offerData["NO_OF_ATTEMPTS"] > 0) {
                    const emiOverdueFlowFunc = () => {
                      if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                        if (result?.offerData["IS_TOTAL_OVERDUE_PAID"] == "N") {
                          setPreapprovedOfferFlag(false);
                          setAttemptFlag(false);
                          dispatcher({
                            type: STOREDATA,
                            payload: true,
                            name: "callpgs",
                          });
                          dispatcher({
                            name: "currentOfferPopup",
                            payload: "NON_OFFER",
                            type: STOREDATA,
                          });
                        } else if (
                          result?.offerData["IS_TOTAL_OVERDUE_PAID"] !== "N"
                        ) {
                          setPreapprovedOfferFlag(true);
                          setAttemptFlag(true);
                        }
                      } else if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(false);
                      }
                    };
                    if (sessionStorage.getItem("emi-payment-success")) {
                      if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey ==
                        "greyed-out"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(true);
                      } else if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey == "phase1"
                      ) {
                        setPreapprovedOfferFlag(false);
                        setAttemptFlag(false);
                        sessionStorage.clear("phase1");
                      } else if (
                        contextData?.emiOverdueOfferRes?.emiOfferKey ==
                        "not-normalised"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(false);
                        sessionStorage.clear("not-normalised");
                        sessionStorage.setItem(
                          "emi-payment-edge-case-not-normalised",
                          "true"
                        );
                        // emiOverdueFlowFunc();
                      }
                    } else {
                      emiOverdueFlowFunc();
                    }
                  }
                }
                dispatcher({
                  name: "offerModaLFlag",
                  payload: {
                    modalFlag: false,
                    accountType: "availOfferModal",
                  },
                  type: STOREDATA,
                });
              } else if (result?.offerData["IS_OFFER_AVAILED"] == "Y") {
                if (result?.offerData["NO_OF_ATTEMPTS"] > 0) {
                  const emiOverdueFlowFunc = () => {
                    if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                      if (result?.offerData["IS_TOTAL_OVERDUE_PAID"] == "N") {
                        setPreapprovedOfferFlag(false);
                        setAttemptFlag(false);
                        dispatcher({
                          type: STOREDATA,
                          payload: true,
                          name: "callpgs",
                        });
                        dispatcher({
                          name: "currentOfferPopup",
                          payload: "NON_OFFER",
                          type: STOREDATA,
                        });
                        dispatcher({
                          name: "offerModaLFlag",
                          payload: {
                            modalFlag: false,
                            accountType: "availOfferModal",
                          },
                          type: STOREDATA,
                        });
                      } else if (
                        result?.offerData["IS_TOTAL_OVERDUE_PAID"] !== "N" ||
                        result?.offerData["IS_TOTAL_OVERDUE_PAID"] == "Y"
                      ) {
                        setPreapprovedOfferFlag(true);
                        setAttemptFlag(true);
                        dispatcher({
                          name: "offerModaLFlag",
                          payload: {
                            modalFlag: false,
                            accountType: "availOfferModal",
                          },
                          type: STOREDATA,
                        });
                      }
                    } else if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(false);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: true,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                    }
                  };
  
                  if (sessionStorage.getItem("emi-payment-success")) {
                    if (
                      contextData?.emiOverdueOfferRes?.emiOfferKey == "greyed-out"
                    ) {
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                    } else if (
                      contextData?.emiOverdueOfferRes?.emiOfferKey == "phase1"
                    ) {
                      setPreapprovedOfferFlag(false);
                      setAttemptFlag(false);
                      sessionStorage.clear("phase1");
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                    } else if (
                      contextData?.emiOverdueOfferRes?.emiOfferKey ==
                      "not-normalised"
                    ) {
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(false);
                      sessionStorage.clear("not-normalised");
                      sessionStorage.setItem(
                        "emi-payment-edge-case-not-normalised",
                        "true"
                      );
                      // emiOverdueFlowFunc();
                    }
                  } else {
                    emiOverdueFlowFunc();
                  }
                }
              }
            } else if (result?.offerData["IS_EMI_NORMALIZED"] == "N") {
              if (result?.offerData["NO_OF_ATTEMPTS"] > 0) {
                if (result?.offerData["IS_OFFER_AVAILED"] === "N") {
                  setPreapprovedOfferFlag(true);
                  setAttemptFlag(false);
                  setPayNowFlag(false);
                  const isStringPresent = popupData?.includes("pay");
  
                  if (!isStringPresent) {
                    dispatcher({
                      name: "offerModaLFlag",
                      payload: {
                        modalFlag: true,
                        accountType: "availOfferModal",
                      },
                      type: STOREDATA,
                    });
                    if (sessionStorage.getItem("settlement-mid-journey")) {
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                      sessionStorage.clear("settlement-mid-journey");
                    }
                  } else if (isStringPresent) {
                    const elementToRemove = "pay";
                    popupData = popupData.filter(
                      (item) => item !== elementToRemove
                    );
                    sessionStorage.setItem(
                      "hidePopupData",
                      JSON.stringify(popupData)
                    );
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "Y") {
                  const isPaymentDone = popupData?.includes("pay");
                  if (isPaymentDone) {
                    if (sessionStorage.getItem("payment-success")) {
                      //GreyOut Payment Component Logic
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "paymentPlanModal",
                        },
                        type: STOREDATA,
                      });
                      sessionStorage.clear("payment-success");
                    } else if (sessionStorage.getItem("payment-failed")) {
                      //GreyOut Payment Component Logic
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: true,
                          accountType: "paymentPlanModal",
                        },
                        type: STOREDATA,
                      });
                      sessionStorage.clear("payment-failed");
                    }
                  } else {
                    if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                      //GreyOut Payment Component Logic
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                      dispatcher({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: false,
                          accountType: "paymentPlanModal",
                        },
                        type: STOREDATA,
                      });
                    } else if (
                      result?.offerData["IS_OFFER_AVAILED"] === "Y" &&
                      result?.offerData["IS_PAYMENT_DONE"] === "N"
                    ) {
                      if (result?.offerData["IS_TRANCHE_OPTED"]) {
                        if (result?.offerData["IS_TRANCHE_OPTED"] === "N") {
                          if (result?.offerData["NO_OF_TRANCHES_OPTED"]) {
                            if (
                              result?.offerData["NO_OF_TRANCHES_OPTED"] === "1"
                            ) {
                              setPreapprovedOfferFlag(true);
                              setPaymentSummeryFlag(false);
                              const currentLoanApprovedAmount = contextData?.approvedLoanAmounts?.approvedLoanData || {};
  
                              if (
                                currentLoanApprovedAmount[contextData.currentActiveData.accountNumber]
                              ) {
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                                  },
                                };
                    
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              }else{
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                                  },
                                };
                    
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              }
                              dispatcher({
                                name: "offerModaLFlag",
                                payload: {
                                  modalFlag: true,
                                  accountType: "paymentPlanModal",
                                },
                                type: STOREDATA,
                              });
                            }
                          } else {
                            setPreapprovedOfferFlag(true);
                            setPaymentSummeryFlag(false);
                            const currentLoanApprovedAmount = contextData?.approvedLoanAmounts?.approvedLoanData || {};
  
                            if (
                              currentLoanApprovedAmount[contextData.currentActiveData.accountNumber]
                            ) {
                              const updatedLoanApprovalData = {
                                ...currentLoanApprovedAmount,
                                [contextData.currentActiveData.accountNumber]: {
                                  approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                                },
                              };
                  
                              dispatcher({
                                name: "approvedLoanAmounts",
                                payload: {
                                  approvedLoanData: updatedLoanApprovalData,
                                },
                                type: STOREDATA,
                              });
                            }else{
                              const updatedLoanApprovalData = {
                                ...currentLoanApprovedAmount,
                                [contextData.currentActiveData.accountNumber]: {
                                  approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                                },
                              };
                  
                              dispatcher({
                                name: "approvedLoanAmounts",
                                payload: {
                                  approvedLoanData: updatedLoanApprovalData,
                                },
                                type: STOREDATA,
                              });
                            }
                            dispatcher({
                              name: "offerModaLFlag",
                              payload: {
                                modalFlag: true,
                                accountType: "paymentPlanModal",
                              },
                              type: STOREDATA,
                            });
                          }
                        } else if (
                          result?.offerData["IS_TRANCHE_OPTED"] === "Y"
                        ) {
                          if (result?.offerData["NO_OF_TRANCHES_OPTED"] === "1") {
                            const currentLoanApprovedAmount = contextData?.approvedLoanAmounts?.approvedLoanData || {};
  
                            if (
                              currentLoanApprovedAmount[contextData.currentActiveData.accountNumber]
                            ) {
                              const updatedLoanApprovalData = {
                                ...currentLoanApprovedAmount,
                                [contextData.currentActiveData.accountNumber]: {
                                  approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                                },
                              };
                  
                              dispatcher({
                                name: "approvedLoanAmounts",
                                payload: {
                                  approvedLoanData: updatedLoanApprovalData,
                                },
                                type: STOREDATA,
                              });
                            }else{
                              const updatedLoanApprovalData = {
                                ...currentLoanApprovedAmount,
                                [contextData.currentActiveData.accountNumber]: {
                                  approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                                },
                              };
                  
                              dispatcher({
                                name: "approvedLoanAmounts",
                                payload: {
                                  approvedLoanData: updatedLoanApprovalData,
                                },
                                type: STOREDATA,
                              });
                            }
                            setPreapprovedOfferFlag(true);
                            setPaymentSummeryFlag(false);
                            dispatcher({
                              name: "offerModaLFlag",
                              payload: {
                                modalFlag: true,
                                accountType: "paymentPlanModal",
                              },
                              type: STOREDATA,
                            });
                          } else if (
                            result?.offerData["NO_OF_TRANCHES_OPTED"] === "2"
                          ) {
                            const getTrancheAmount = (amount, percentage) => {
                              const percentageDecimal =
                                parseFloat(percentage) / 100;
                              const result = amount * percentageDecimal;
                              return result;
                            };
                            if (
                              result?.offerData["TRANCHE1_PAYMENT_STATUS"] ===
                              "Success"
                            ) {
                              const currentLoanApprovedAmount = contextData?.approvedLoanAmounts?.approvedLoanData || {};
  
                              if (
                                currentLoanApprovedAmount[contextData.currentActiveData.accountNumber]
                              ) {
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue: getTrancheAmount(
                                  result?.offerData["OFFER_APPROVED_AMOUNT"],
                                  result?.offerData["TRANCHE_2"]
                                ),
                                  },
                                };
                    
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              }else{
                                const updatedLoanApprovalData = {
                                  ...currentLoanApprovedAmount,
                                  [contextData.currentActiveData.accountNumber]: {
                                    approvedAmountValue: getTrancheAmount(
                                  result?.offerData["OFFER_APPROVED_AMOUNT"],
                                  result?.offerData["TRANCHE_2"]
                                ),
                                  },
                                };
                    
                                dispatcher({
                                  name: "approvedLoanAmounts",
                                  payload: {
                                    approvedLoanData: updatedLoanApprovalData,
                                  },
                                  type: STOREDATA,
                                });
                              }
                            } else {
                              dispatcher({
                                name: "approvedAmount",
                                payload: getTrancheAmount(
                                  result?.offerData["OFFER_APPROVED_AMOUNT"],
                                  result?.offerData["TRANCHE_1"]
                                ),
                                type: STOREDATA,
                              });
                            }
                            setPreapprovedOfferFlag(true);
                            setPaymentSummeryFlag(false);
                            dispatcher({
                              name: "offerModaLFlag",
                              payload: {
                                modalFlag: true,
                                accountType: "paymentPlanModal",
                              },
                              type: STOREDATA,
                            });
                          }
                        }
                      } else {
                        setPreapprovedOfferFlag(true);
                        setPaymentSummeryFlag(false);
                        const currentLoanApprovedAmount = contextData?.approvedLoanAmounts?.approvedLoanData || {};
  
                        if (
                          currentLoanApprovedAmount[contextData.currentActiveData.accountNumber]
                        ) {
                          const updatedLoanApprovalData = {
                            ...currentLoanApprovedAmount,
                            [contextData.currentActiveData.accountNumber]: {
                              approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                            },
                          };
              
                          dispatcher({
                            name: "approvedLoanAmounts",
                            payload: {
                              approvedLoanData: updatedLoanApprovalData,
                            },
                            type: STOREDATA,
                          });
                        }else{
                          const updatedLoanApprovalData = {
                            ...currentLoanApprovedAmount,
                            [contextData.currentActiveData.accountNumber]: {
                              approvedAmountValue: result?.offerData["OFFER_APPROVED_AMOUNT"],
                            },
                          };
              
                          dispatcher({
                            name: "approvedLoanAmounts",
                            payload: {
                              approvedLoanData: updatedLoanApprovalData,
                            },
                            type: STOREDATA,
                          });
                        }
                        dispatcher({
                          name: "offerModaLFlag",
                          payload: {
                            modalFlag: true,
                            accountType: "paymentPlanModal",
                          },
                          type: STOREDATA,
                        });
                      }
                    }
                  }
                }
              } else if (result?.offerData["NO_OF_ATTEMPTS"] == 0) {
                setPreapprovedOfferFlag(true);
                setAttemptFlag(true);
                dispatcher({
                  name: "offerModaLFlag",
                  payload: {
                    modalFlag: false,
                    accountType: "availOfferModal",
                  },
                  type: STOREDATA,
                });
              }
            }
          } else if (
            result?.settlementFlag === "N" &&
            result?.offerFlag === "Y" &&
            result?.loanStatus === "Active"
          ) {
            if (currentOfferName === "EMI_OVERDUE_CAMPAIGN") {
              const emiOverdueFlowFunc = () => {
                if (result?.offerData["IS_OFFER_AVAILED"] === "Y") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                    if (result?.offerData["IS_TOTAL_OVERDUE_PAID"] === "Y") {
                      setPreapprovedOfferFlag(true);
                      setAttemptFlag(true);
                    } else if (
                      result?.offerData["IS_TOTAL_OVERDUE_PAID"] === "N"
                    ) {
                      setPreapprovedOfferFlag(false);
                      setAttemptFlag(false);
                      dispatcher({
                        type: STOREDATA,
                        payload: true,
                        name: "phaseDataFlag",
                      });
                      dispatcher({
                        type: STOREDATA,
                        payload: true,
                        name: "callpgs",
                      });
                      dispatcher({
                        name: "currentOfferPopup",
                        payload: "NON_OFFER",
                        type: STOREDATA,
                      });
                    }
                  } else if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(false);
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "N") {
                  setPreapprovedOfferFlag(true);
                  setAttemptFlag(false);
                }
              };
              if (sessionStorage.getItem("emi-payment-success")) {
                if (
                  contextData?.emiOverdueOfferRes?.emiOfferKey == "greyed-out"
                ) {
                  setPreapprovedOfferFlag(true);
                  setAttemptFlag(true);
                } else if (
                  contextData?.emiOverdueOfferRes?.emiOfferKey == "phase1"
                ) {
                  setPreapprovedOfferFlag(false);
                  setAttemptFlag(false);
                  dispatcher({
                    type: STOREDATA,
                    payload: true,
                    name: "phaseDataFlag",
                  });
                  sessionStorage.clear("phase1");
                } else if (
                  contextData?.emiOverdueOfferRes?.emiOfferKey == "not-normalised"
                ) {
                  setPreapprovedOfferFlag(true);
                  setAttemptFlag(false);
                  sessionStorage.clear("not-normalised");
                  // emiOverdueFlowFunc();
                }
              } else {
                emiOverdueFlowFunc();
              }
            } else if (contextData?.currentOfferPopup === "ODBC_CAMPAIGN") {
              const odbcCampaignFunc = () => {
                if (result?.offerData["IS_OFFER_AVAILED"] === "Y") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(true);
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "N") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(true);
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "Y") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(false);
                  }
                } else if (result?.offerData["IS_OFFER_AVAILED"] === "N") {
                  if (result?.offerData["IS_PAYMENT_DONE"] === "N") {
                    setPreapprovedOfferFlag(true);
                    setAttemptFlag(false);
                  }
                }
              };
  
              if (sessionStorage.getItem("odbc-forego-payment-success")) {
                setPreapprovedOfferFlag(true);
                setAttemptFlag(true);
                sessionStorage.clear("odbc-forego-payment-success");
              } else if (sessionStorage.getItem("odbc-payment-failure")) {
                setPreapprovedOfferFlag(true);
                setAttemptFlag(false);
                sessionStorage.clear("odbc-payment-failure");
              } else if (sessionStorage.getItem("odbc-payment-success")) {
                setPreapprovedOfferFlag(true);
                setAttemptFlag(true);
                sessionStorage.clear("odbc-payment-success");
              } else {
                odbcCampaignFunc();
              }
            }
          } else if (
            result?.settlementFlag === "N" &&
            result?.offerFlag === "N" &&
            result?.loanStatus === "Active"
          ) {
            setPreapprovedOfferFlag(false);
            setAttemptFlag(false);
          } else if (
            result?.settlementFlag === "N" &&
            result?.offerFlag === "Y" &&
            result?.loanStatus === "Closed"
          ) {
            setPreapprovedOfferFlag(true);
            // dispatcher({
            //   name: "offerModaLFlag",
            //   payload: {
            //     modalFlag: true,
            //     accountType: "availOfferModal",
            //   },
            //   type: STOREDATA,
            // });
          } else if (
            result?.settlementFlag === "N" &&
            result?.offerFlag === "N" &&
            result?.loanStatus === "Active"
          ) {
            setPreapprovedOfferFlag(false);
            setAttemptFlag(false);
          } else {
            setPreapprovedOfferFlag(false);
            dispatcher({
              name: "offerModaLFlag",
              payload: {
                modalFlag: false,
                accountType: "availOfferModal",
              },
              type: STOREDATA,
            });
          }
  
          if (
            contextData?.attempt?.loanAttempts[activeLoan]?.attempts == 0 ||
            result?.offerData["NO_OF_ATTEMPTS"] == 0
          ) {
            setAttemptFlag(true);
            dispatcher({
              name: "offerModaLFlag",
              payload: {
                modalFlag: false,
                accountType: "availOfferModal",
              },
              type: STOREDATA,
            });
          }
        } else {
          setPreapprovedOfferFlag(false);
          setAttemptFlag(false);
        }
      }
      // console.log(payNowFlag,attemptFlag,disableBtn,"flagsssss")
    }
  }, [activeLoan, paymentFlag]);

  

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    afterChange: (index) => handleArrowVisibility(index)
  };


  const handleArrowVisibility = (index) => {
    const slider = document.querySelector('.slick-track');
    if (!slider) return;
    const totalSlides = slider.children.length;
    const isFirstSlide = index === 0;
    const isLastSlide = index === totalSlides - 1;

    const backArrow = document.querySelector('.slick-prev');
    const forwardArrow = document.querySelector('.slick-next');

    if (isFirstSlide) {
      if (backArrow) backArrow.style.display = 'none';
    } else {
      if (backArrow) backArrow.style.display = 'block';
    }

    if (isLastSlide) {
      if (forwardArrow) forwardArrow.style.display = 'none';
    } else {
      if (forwardArrow) forwardArrow.style.display = 'block';
    }

    // if (isFirstSlide) {
    //   const backArrow = document.querySelector('.slick-prev');
    //   if (backArrow) backArrow.style.display = 'none';
    // } else {
    //   const backArrow = document.querySelector('.slick-prev');
    //   if (backArrow) backArrow.style.display = 'block';
    // }

    // if (isLastSlide) {
    //   const forwardArrow = document.querySelector('.slick-next');
    //   if (forwardArrow) forwardArrow.style.display = 'none';
    // } else {
    //   const forwardArrow = document.querySelector('.slick-next');
    //   if (forwardArrow) forwardArrow.style.display = 'block';
    // }
  };

  useEffect(() => {
    handleArrowVisibility(0); // Check the initial state of arrows
  }, []);

  return (
    <div className="c_dashboard_data_head">
      <div className="c_two_wheeler_loan_bg_color">
        {data.length > 1 ? (
          <>
            <Slider {...settings}>
              {data.map((loan, index) => {
                if (loan?.offerFlag === "Y") {
                  // console.log(loan, "loan");
                  const getofferKey = (loan) => {
                    const keysToCheck = [
                      "SETTLEMENT_CAMPAIGN",
                      "EMI_OVERDUE_CAMPAIGN",
                      "ODBC_CAMPAIGN",
                      "LOS_POOL_CAMPAIGN",
                      "ALREADY_SETTLED_POOL_CAMPAIGN",
                    ];

                    for (const key of keysToCheck) {
                      if (loan[key] === "Y") {
                        return key;
                      }
                    }

                    return null;
                  };

                  return (
                    <div className="dash-scroll">
                      <SlickCard
                        key={index}
                        selected={loan.contractNumber === activeLoan}
                        src={loan.icon}
                        alt={loan.displayName}
                        loanName={loan.displayName}
                        loanNumber={loan.contractNumber}
                        offerFlag={loan.offerFlag}
                        offerKey={
                          loan.offerFlag === "Y"
                            ? getofferKey(loan.offerData)
                            : loan.offerFlag === "N"
                            ? "NoOffer"
                            : null
                        }
                        handleClick={() => {
                          if(contextData?.activePhase1PaymentLabel !== ""){
                            dispatcher({
                              name: "phase1PaymentPreDataLabel",
                              payload: "",
                              type: STOREDATA,
                            });
                          }
                          setValidateFlagEmail(false);
                          setValidateFlagMobile(false);
                          setDisableBtn(false);
                          setPayNowFlag(false);
                          if (
                            sessionStorage.getItem("settlement-mid-journey")
                          ) {
                            sessionStorage.clear("settlement-mid-journey");
                          }
                          if (
                            contextData?.currentActiveData?.accountNumber !==
                            loan.contractNumber
                          ) {
                            dispatcher({
                              type: STOREDATA,
                              payload: {
                                accountNumber: loan?.contractNumber,
                                system:
                                  contextData?.parsedUserData[
                                    loan?.contractNumber
                                  ]?.system,
                              },
                              name: "currentActiveData",
                            });

                            const attempts =
                              contextData?.attempt?.loanAttempts[
                                loan?.contractNumber
                              ]?.attempts;

                            const result = data.find((element) => {
                              if (
                                element.offerData &&
                                element.offerData["LOAN_ACCOUNT_NO"] ===
                                  loan.contractNumber
                              ) {
                                return true;
                              }
                              return false;
                            });

                            // console.log(result, "result");

                            let offerName = getofferKey(result?.offerData);
                            dispatcher({
                              name: "currentOfferPopup",
                              payload: offerName,
                              type: STOREDATA,
                            });

                            if (offerName === "SETTLEMENT_CAMPAIGN") {
                              if (
                                result?.offerData["IS_EMI_NORMALIZED"] == "Y"
                              ) {
                                dispatcher({
                                  name: "offerModaLFlag",
                                  payload: {
                                    modalFlag: false,
                                    accountType: "availOfferModal",
                                  },
                                  type: STOREDATA,
                                });
                                if (
                                  result?.offerData["IS_PAYMENT_DONE"] === "Y"
                                ) {
                                  if (
                                    result?.offerData[
                                      "IS_TOTAL_OVERDUE_PAID"
                                    ] == "N"
                                  ) {
                                    setPreapprovedOfferFlag(false);
                                    setAttemptFlag(false);
                                    dispatcher({
                                      type: STOREDATA,
                                      payload: true,
                                      name: "callpgs",
                                    });
                                    dispatcher({
                                      name: "currentOfferPopup",
                                      payload: "NON_OFFER",
                                      type: STOREDATA,
                                    });
                                    dispatcher({
                                      name: "offerModaLFlag",
                                      payload: {
                                        modalFlag: false,
                                        accountType: "availOfferModal",
                                      },
                                      type: STOREDATA,
                                    });
                                  }
                                }
                              } else if (
                                result?.offerData["IS_EMI_NORMALIZED"] == "N"
                              ) {
                                if (
                                  result?.offerData["IS_OFFER_AVAILED"] ===
                                    "Y" &&
                                  result?.offerData["IS_PAYMENT_DONE"] === "N"
                                ) {
                                  setPaymentSummeryFlag(false);
                                  setAttemptFlag(true);
                                  dispatcher({
                                    name: "offerModaLFlag",
                                    payload: {
                                      modalFlag: true,
                                      accountType: "paymentPlanModal",
                                    },
                                    type: STOREDATA,
                                  });
                                } else if (
                                  result?.offerData["IS_OFFER_AVAILED"] ===
                                    "N" &&
                                  result?.offerData["IS_PAYMENT_DONE"] === "N"
                                ) {
                                  if (
                                    contextData?.attempt?.loanAttempts[
                                      loan?.contractNumber
                                    ]?.attempts &&
                                    contextData?.attempt?.loanAttempts[
                                      loan?.contractNumber
                                    ]?.attempts !== 0
                                  ) {
                                    setAttemptFlag(false);
                                    dispatcher({
                                      name: "offerModaLFlag",
                                      payload: {
                                        modalFlag: true,
                                        accountType: "availOfferModal",
                                      },
                                      type: STOREDATA,
                                    });
                                  } else if (
                                    result?.offerData["NO_OF_ATTEMPTS"] !== "0"
                                  ) {
                                    setAttemptFlag(false);
                                    dispatcher({
                                      name: "offerModaLFlag",
                                      payload: {
                                        modalFlag: true,
                                        accountType: "availOfferModal",
                                      },
                                      type: STOREDATA,
                                    });
                                  }
                                }
                              }
                            }

                            if (offerName === "EMI_OVERDUE_CAMPAIGN") {
                              if (
                                result?.offerData["IS_OFFER_AVAILED"] === "Y"
                              ) {
                                if (
                                  result?.offerData["IS_EMI_NORMALIZED"] == "Y"
                                ) {
                                  if (
                                    result?.offerData["IS_PAYMENT_DONE"] === "Y"
                                  ) {
                                    if (
                                      result?.offerData[
                                        "IS_TOTAL_OVERDUE_PAID"
                                      ] === "Y"
                                    ) {
                                      setPreapprovedOfferFlag(true);
                                      setAttemptFlag(true);
                                    } else if (
                                      result?.offerData[
                                        "IS_TOTAL_OVERDUE_PAID"
                                      ] === "N"
                                    ) {
                                      setPreapprovedOfferFlag(false);
                                      setAttemptFlag(false);

                                      dispatcher({
                                        type: STOREDATA,
                                        payload: true,
                                        name: "phaseDataFlag",
                                      });
                                      dispatcher({
                                        type: STOREDATA,
                                        payload: true,
                                        name: "callpgs",
                                      });
                                      dispatcher({
                                        name: "currentOfferPopup",
                                        payload: "NON_OFFER",
                                        type: STOREDATA,
                                      });
                                    }
                                  }
                                }
                              }
                            }

                            if (offerName === "ODBC_CAMPAIGN") {
                              if (
                                result?.offerData["IS_PAYMENT_DONE"] === "Y"
                              ) {
                                setAttemptFlag(true);
                              } else if (
                                result?.offerData["IS_PAYMENT_DONE"] === "N"
                              ) {
                                setAttemptFlag(false);
                              }
                            }

                            setActiveLoan(loan.contractNumber);

                            if (
                              loan.loanStatus == "Active" &&
                              loan.offerFlag === "Y"
                            ) {
                              setPreapprovedOfferFlag(true);
                            } else if (
                              loan.loanStatus == "Active" &&
                              loan.offerFlag === "N"
                            ) {
                              setPreapprovedOfferFlag(false);
                            } else if (
                              loan.loanStatus == "Closed" &&
                              loan.offerFlag === "Y"
                            ) {
                              setPreapprovedOfferFlag(true);
                            }
                          }
                        }}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="dash-scroll">
                      <SlickCard
                        key={index}
                        selected={loan.contractNumber === activeLoan}
                        src={loan.icon}
                        alt={loan.displayName}
                        loanName={loan.displayName}
                        loanNumber={loan.contractNumber}
                        offerFlag={loan.offerFlag}
                        // offerKey={
                        //   loan.offerFlag === "Y"
                        //     ? getofferKey(loan.offerData)
                        //     : loan.offerFlag === "N"
                        //     ? "NoOffer"
                        //     : null
                        // }
                        handleClick={() => {
                          if(contextData?.activePhase1PaymentLabel !== ""){
                            dispatcher({
                              name: "phase1PaymentPreDataLabel",
                              payload: "",
                              type: STOREDATA,
                            });
                          }
                          setAttemptFlag(false);
                          setDisableBtn(false);
                          setPayNowFlag(false);
                          if (
                            contextData?.currentActiveData?.accountNumber !==
                            loan.contractNumber
                          ) {
                            dispatcher({
                              type: STOREDATA,
                              payload: {
                                accountNumber: loan?.contractNumber,
                                system:
                                  contextData?.parsedUserData[
                                    loan?.contractNumber
                                  ]?.system,
                              },
                              name: "currentActiveData",
                            });
                          }

                          setActiveLoan(loan.contractNumber);

                          if (
                            loan.loanStatus == "Active" &&
                            loan.offerFlag === "Y"
                          ) {
                            setPreapprovedOfferFlag(true);
                          } else if (
                            loan.loanStatus == "Active" &&
                            loan.offerFlag === "N"
                          ) {
                            setPreapprovedOfferFlag(false);
                          } else if (
                            loan.loanStatus == "Closed" &&
                            loan.offerFlag === "Y"
                          ) {
                            setPreapprovedOfferFlag(true);
                          }
                        }}
                      />
                    </div>
                  );
                }
              })}
            </Slider>
          </>
        ) : data.length == 1 ? (
          <>
            {data.map((loan, index) => {
              if (loan?.offerFlag === "Y") {
                // console.log(loan, "loan");
                const getofferKey = (loan) => {
                  const keysToCheck = [
                    "SETTLEMENT_CAMPAIGN",
                    "EMI_OVERDUE_CAMPAIGN",
                    "ODBC_CAMPAIGN",
                    "LOS_POOL_CAMPAIGN",
                    "ALREADY_SETTLED_POOL_CAMPAIGN",
                  ];

                  for (const key of keysToCheck) {
                    if (loan[key] === "Y") {
                      return key;
                    }
                  }

                  return null;
                };

                return (
                  <div className="dash-scroll">
                    <SlickCard
                      key={index}
                      selected={loan.contractNumber === activeLoan}
                      src={loan.icon}
                      alt={loan.displayName}
                      loanName={loan.displayName}
                      loanNumber={loan.contractNumber}
                      offerFlag={loan.offerFlag}
                      offerKey={
                        loan.offerFlag === "Y"
                          ? getofferKey(loan.offerData)
                          : loan.offerFlag === "N"
                          ? "NoOffer"
                          : null
                      }
                      handleClick={() => {
                        setDisableBtn(false);
                        setPayNowFlag(false);
                        if (sessionStorage.getItem("settlement-mid-journey")) {
                          sessionStorage.clear("settlement-mid-journey");
                        }
                        if (
                          contextData?.currentActiveData?.accountNumber !==
                          loan.contractNumber
                        ) {
                          dispatcher({
                            type: STOREDATA,
                            payload: {
                              accountNumber: loan?.contractNumber,
                              system:
                                contextData?.parsedUserData[
                                  loan?.contractNumber
                                ]?.system,
                            },
                            name: "currentActiveData",
                          });

                          const attempts =
                            contextData?.attempt?.loanAttempts[
                              loan?.contractNumber
                            ]?.attempts;

                          const result = data.find((element) => {
                            if (
                              element.offerData &&
                              element.offerData["LOAN_ACCOUNT_NO"] ===
                                loan.contractNumber
                            ) {
                              return true;
                            }
                            return false;
                          });

                          // console.log(result, "result");

                          let offerName = getofferKey(result?.offerData);
                          dispatcher({
                            name: "currentOfferPopup",
                            payload: offerName,
                            type: STOREDATA,
                          });

                          if (offerName === "SETTLEMENT_CAMPAIGN") {
                            if (result?.offerData["IS_EMI_NORMALIZED"] == "Y") {
                              dispatcher({
                                name: "offerModaLFlag",
                                payload: {
                                  modalFlag: false,
                                  accountType: "availOfferModal",
                                },
                                type: STOREDATA,
                              });
                              if (
                                result?.offerData["IS_PAYMENT_DONE"] === "Y"
                              ) {
                                if (
                                  result?.offerData["IS_TOTAL_OVERDUE_PAID"] ==
                                  "N"
                                ) {
                                  setPreapprovedOfferFlag(false);
                                  setAttemptFlag(false);
                                  dispatcher({
                                    type: STOREDATA,
                                    payload: true,
                                    name: "callpgs",
                                  });
                                  dispatcher({
                                    name: "currentOfferPopup",
                                    payload: "NON_OFFER",
                                    type: STOREDATA,
                                  });
                                  dispatcher({
                                    name: "offerModaLFlag",
                                    payload: {
                                      modalFlag: false,
                                      accountType: "availOfferModal",
                                    },
                                    type: STOREDATA,
                                  });
                                }
                              }
                            } else if (
                              result?.offerData["IS_EMI_NORMALIZED"] == "N"
                            ) {
                              if (
                                result?.offerData["IS_OFFER_AVAILED"] === "Y" &&
                                result?.offerData["IS_PAYMENT_DONE"] === "N"
                              ) {
                                setAttemptFlag(true);
                                dispatcher({
                                  name: "offerModaLFlag",
                                  payload: {
                                    modalFlag: true,
                                    accountType: "paymentPlanModal",
                                  },
                                  type: STOREDATA,
                                });
                              } else if (
                                result?.offerData["IS_OFFER_AVAILED"] === "N" &&
                                result?.offerData["IS_PAYMENT_DONE"] === "N"
                              ) {
                                if (
                                  contextData?.attempt?.loanAttempts[
                                    loan?.contractNumber
                                  ]?.attempts &&
                                  contextData?.attempt?.loanAttempts[
                                    loan?.contractNumber
                                  ]?.attempts !== 0
                                ) {
                                  setAttemptFlag(false);
                                  dispatcher({
                                    name: "offerModaLFlag",
                                    payload: {
                                      modalFlag: true,
                                      accountType: "availOfferModal",
                                    },
                                    type: STOREDATA,
                                  });
                                } else if (
                                  result?.offerData["NO_OF_ATTEMPTS"] !== "0"
                                ) {
                                  setAttemptFlag(false);
                                  dispatcher({
                                    name: "offerModaLFlag",
                                    payload: {
                                      modalFlag: true,
                                      accountType: "availOfferModal",
                                    },
                                    type: STOREDATA,
                                  });
                                }
                              }
                            }
                          }

                          if (offerName === "EMI_OVERDUE_CAMPAIGN") {
                            if (result?.offerData["IS_OFFER_AVAILED"] === "Y") {
                              if (
                                result?.offerData["IS_EMI_NORMALIZED"] == "Y"
                              ) {
                                if (
                                  result?.offerData["IS_PAYMENT_DONE"] === "Y"
                                ) {
                                  if (
                                    result?.offerData[
                                      "IS_TOTAL_OVERDUE_PAID"
                                    ] === "Y"
                                  ) {
                                    setPreapprovedOfferFlag(true);
                                    setAttemptFlag(true);
                                  } else if (
                                    result?.offerData[
                                      "IS_TOTAL_OVERDUE_PAID"
                                    ] === "N"
                                  ) {
                                    setPreapprovedOfferFlag(false);
                                    setAttemptFlag(false);

                                    dispatcher({
                                      type: STOREDATA,
                                      payload: true,
                                      name: "phaseDataFlag",
                                    });
                                    dispatcher({
                                      type: STOREDATA,
                                      payload: true,
                                      name: "callpgs",
                                    });
                                    dispatcher({
                                      name: "currentOfferPopup",
                                      payload: "NON_OFFER",
                                      type: STOREDATA,
                                    });
                                  }
                                }
                              }
                            }
                          }

                          if (offerName === "ODBC_CAMPAIGN") {
                            if (result?.offerData["IS_PAYMENT_DONE"] === "Y") {
                              setAttemptFlag(true);
                            } else if (
                              result?.offerData["IS_PAYMENT_DONE"] === "N"
                            ) {
                              setAttemptFlag(false);
                            }
                          }

                          setActiveLoan(loan.contractNumber);

                          if (
                            loan.loanStatus == "Active" &&
                            loan.offerFlag === "Y"
                          ) {
                            setPreapprovedOfferFlag(true);
                          } else if (
                            loan.loanStatus == "Active" &&
                            loan.offerFlag === "N"
                          ) {
                            setPreapprovedOfferFlag(false);
                          } else if (
                            loan.loanStatus == "Closed" &&
                            loan.offerFlag === "Y"
                          ) {
                            setPreapprovedOfferFlag(true);
                          }
                        }
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="dash-scroll">
                    <SlickCard
                      key={index}
                      selected={loan.contractNumber === activeLoan}
                      src={loan.icon}
                      alt={loan.displayName}
                      loanName={loan.displayName}
                      loanNumber={loan.contractNumber}
                      offerFlag={loan.offerFlag}
                      // offerKey={
                      //   loan.offerFlag === "Y"
                      //     ? getofferKey(loan.offerData)
                      //     : loan.offerFlag === "N"
                      //     ? "NoOffer"
                      //     : null
                      // }
                      handleClick={() => {
                        setDisableBtn(false);
                        setPayNowFlag(false);
                        if (
                          contextData?.currentActiveData?.accountNumber !==
                          loan.contractNumber
                        ) {
                          dispatcher({
                            type: STOREDATA,
                            payload: {
                              accountNumber: loan?.contractNumber,
                              system:
                                contextData?.parsedUserData[
                                  loan?.contractNumber
                                ]?.system,
                            },
                            name: "currentActiveData",
                          });
                        }

                        setActiveLoan(loan.contractNumber);

                        if (
                          loan.loanStatus == "Active" &&
                          loan.offerFlag === "Y"
                        ) {
                          setPreapprovedOfferFlag(true);
                        } else if (
                          loan.loanStatus == "Active" &&
                          loan.offerFlag === "N"
                        ) {
                          setPreapprovedOfferFlag(false);
                        } else if (
                          loan.loanStatus == "Closed" &&
                          loan.offerFlag === "Y"
                        ) {
                          setPreapprovedOfferFlag(true);
                        }
                      }}
                    />
                  </div>
                );
              }
            })}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

DashboardDataHead.propTypes = {
  data: PropTypes.array,
  setActiveLoan: PropTypes.func,
  activeLoan: PropTypes.string,
};
export default DashboardDataHead;
