import React, { useEffect, useState, useContext } from "react";
import "./LoginForm.scss";
import Tabs from "../Tabs/Tabs";
import RegisterComponent from "../RegisterComponent";
import LoginServiceObject from "../../../../Retail/src/services/Login";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import PropTypes from 'prop-types';



const LoginForm = ({
    setFailurePopUpModal,
    setFailurePopUpContent,
    setLoginTab,
    loginTab,
    setCounter,
    setCaptcha,
    captcha,
    setOtpDetails,
    loginTabForm,
    handleForm,
    otpDetails,
    setOtpTimerFlag,
    otpTimerFlag
}) => {
    const [captchaImg, SetCaptchaImg] = useState(null);
    const [showError, setShowError] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [, dispathData] = useContext(DataMapping)
    const [captchaApiErrMsg, setCaptchaApiErrMsg] = useState();

    const captchaApiCall = () => {
        setApiErrorMsg(() => "");
        setShowError(() => false);
        setCaptchaApiErrMsg(() => "");

        dispathData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        let generateCaptchaRequest = {
            "header": {
                "isLegacy": "N"
            },
            "body": {}
        }
        LoginServiceObject.generateCaptchaService(generateCaptchaRequest).then((res) => {
            SetCaptchaImg(res.captchaLightTheme);
            setCaptcha(res.captchaRef);
            dispathData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
        }).catch((err) => {
            setFailurePopUpModal(() => true);
            setFailurePopUpContent(() => {
                return  {
                    apiName: "",
                    message: "We are currently facing error in captcha services. Please try again later",
                    errorCode: err
                }
            })
            dispathData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
        });
    }

    useEffect(() => {
        if (loginTab !== 'userId') {
            captchaApiCall()
        }
    }, [loginTab])

    return (
        <div className="c_login_form_wrap">

            <Tabs setTaborder={setLoginTab} loginTab={loginTab} setShowError={setShowError} />
            <RegisterComponent
                captcha={captcha}
                captchaImg={captchaImg}
                setCounter={setCounter}
                componentType={loginTab}
                setLoginTab={setLoginTab}
                captchaApiCall={captchaApiCall}
                otpDetails={otpDetails}
                setOtpDetails={setOtpDetails}
                handleForm={handleForm}
                loginTabForm={loginTabForm}
                showError={showError}
                setShowError={setShowError}
                setOtpTimerFlag={setOtpTimerFlag}
                otpTimerFlag={otpTimerFlag}
                apiErrorMsg={apiErrorMsg}
                setApiErrorMsg={setApiErrorMsg}
                captchaApiErrMsg={captchaApiErrMsg}
                setCaptchaApiErrMsg={setCaptchaApiErrMsg}
                setFailurePopUpContent={setFailurePopUpContent}
                setFailurePopUpModal={setFailurePopUpModal}
            />
        </div>
    );
};

LoginForm.propTypes = {
    setFailurePopUpModal: PropTypes.func,
    setFailurePopUpContent: PropTypes.func,
    setLoginTab: PropTypes.func,
    loginTab: PropTypes.string,
    setCounter: PropTypes.func,
    setCaptcha: PropTypes.func,
    captcha: PropTypes.string,
    setOtpDetails: PropTypes.func,
    loginTabForm: PropTypes.object,
    handleForm: PropTypes.func,
    otpDetails: PropTypes.object,
    setOtpTimerFlag: PropTypes.func,
    otpTimerFlag: PropTypes.bool
}

export default LoginForm;
