import LoginServiceObject from "../../../Retail/src/services/Login";
import commonFunctionObject from "../../../Retail/src/common-function";

export async function generateOtp(
    componentType,
    fieldOne,
    fieldTwo,
    captcha,
    context = ""
) {
    let serviceRequestType = componentType === "mobNum" ? "mobile" : "emailId";
    let requestJson = {};

    let response = await commonFunctionObject.getDeviceAndBrowserInfo();
    if (context === "forgotJourney") {
        if (serviceRequestType === "mobile") {
            requestJson = {
                header: {
                    grantType: "otp",
                    serviceType: serviceRequestType,
                    journeyType: "postLogin",
                    source: "COLLECTION",
                    deviceType: response["deviceType"],
                    browser: response["browser"]
                },
                body: {
                    mobileNo: fieldOne,
                },
            };
        } else {
            requestJson = {
                header: {
                    grantType: "otp",
                    serviceType: serviceRequestType,
                    journeyType: "postLogin",
                    source: "COLLECTION",
                    deviceType: response["deviceType"],
                    browser: response["browser"]
                },
                body: {
                    emailId: fieldOne,
                },
            };
        }
    } else if (componentType === 'mobNum') {
        requestJson = {
            header: {
                grantType: "otp",
                serviceType: serviceRequestType,
                journeyType: "login",
                source: "COLLECTION",
                deviceType: response["deviceType"],
                browser: response["browser"]
            },
            body: {
                mobileNo: fieldOne,
                captcha: fieldTwo,
                captchaRef: captcha
            },
        };
    } else if (componentType === 'emailId') {
        requestJson = {
            header: {
                grantType: "otp",
                serviceType: serviceRequestType,
                journeyType: "login",
                source: "COLLECTION",
                deviceType: response["deviceType"],
                browser: response["browser"]
            },
            body: {
                emailId: fieldOne,
                captcha: fieldTwo,
                captchaRef: captcha
            },
        };
    }
    return new Promise((resolve, reject) => {
        LoginServiceObject.callGererateOtpService(requestJson, serviceRequestType)
            .then((generateOtpResponse) => {
                resolve(generateOtpResponse);
            })
            .catch((err) => {
                reject(err)
            });
    });
}


