import React, {
  useContext,
  useEffect,
  useReducer,
} from "react";

import { useCookies } from 'react-cookie';
import 'antd/dist/antd.css';
import "./app.scss";

import reducer from "../../Shared/context/data-mapping/reducer/reducer";
import DataMapping from "../../Shared/context/data-mapping";
import PostLoginFooter from "../../Retail/src/components/PostLoginFooter/PostLoginFooter";
import Loader from "../../Retail/src/global/Loader";
import Routes from "./Routes";
import Timer from "../../Retail/src/components/Timer/Timer";
import { useLocation, useNavigate } from "react-router-dom";
import Analytics from "../../Retail/src/utilities/Analytics";
import AnalyticsContextProvider from "../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import Header from "../src/components/header";
import Footer from "../src/components/footer";

function App() {
  const analytics = new Analytics();
  const myData = JSON.parse(sessionStorage.getItem("dataMapping"));
  const [data, dispatchData] = useReducer(reducer, myData ? myData : []);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const [cookies] = useCookies();


  useEffect(() => {
    checkLoginSession()
  }, [])

  function checkLoginSession() {
    var Authorization = cookies?.['Authorization']
    if (!(data?.authToken && Authorization && data.authToken == Authorization)) {
      navigate("/login" + location.search);
    } 
  }

  return (
    <>
      <AnalyticsContextProvider.Provider value={{ analytics }}>
        <div>
          <div className="loader"></div>
          <div>
            <div className="main-background">
              <div className="main-background-wave-1"></div>
              <div className="main-background-wave-2"></div>
              {data?.["loaderFlag"] ? <Loader /> : null}
              <div className={pathname.toLowerCase() === "login" ? "main-wrapper login-wrapper" : "main-wrapper"}>
                <Header override_class={pathname.toLowerCase() === "login" ? "" : "c_overview-header"} />
                <DataMapping.Provider value={[data, dispatchData]}>
                  {data?.authToken ? <Timer /> : null}
                  <Routes />
                  {data?.["currentActiveData"] ? <PostLoginFooter portalType="collection" /> : null}
                </DataMapping.Provider>
                {pathname.toLowerCase() === "login" ? <Footer/> : null}</div>
            </div>
          </div>
        </div>
      </AnalyticsContextProvider.Provider>
    </>
  );
}

export default App;

