import React from "react";
import './SkeletonDashboardData.scss'

const SkeletonDashboardData = () => {
    return (
        <>
        <div className="skeleton-loader">

            <div className="c_dashboard_data_head">
                <div className="c_two_wheeler_loan_bg_color">
                    <div className="scroll">
                        <div className="c_slick_card_comp skeleton-class"></div>
                        <div className="c_slick_card_comp skeleton-class"></div>
                        <div className="c_slick_card_comp skeleton-class"></div>
                        <div className="c_slick_card_comp skeleton-class"></div>
                    </div>
                </div>
            </div>

            <div className="c_dashboard_info">
                <div className="c_dashboard_info_second_div">
                    <div className="c_loan_details_item">
                        <p className="c_loan_name_text"></p>
                        <span className="c_gradient_underline"></span>
                    </div >
                    <div className="c_loan_details_item">
                        <p className="c_loan_name_text"></p>
                        <span className="c_gradient_underline"></span>
                    </div >
                    <div className="c_loan_details_item">
                        <p className="c_loan_name_text"></p>
                        <span className="c_gradient_underline"></span>
                    </div >
                    <div className="c_loan_details_item">
                        <p className="c_loan_name_text"></p>
                        <span className="c_gradient_underline"></span>
                    </div >
                    <div className="c_loan_details_item">
                        <p className="c_loan_name_text"></p>
                        <span className="c_gradient_underline"></span>
                    </div >
                    <div className="c_loan_details_item">
                        <p className="c_loan_name_text"></p>
                        <span className="c_gradient_underline"></span>
                    </div >
                </div>
                {/* <div className="c_more_details_chevron_right">
                <a className="c_more_details" onClick={()=>{navigate("/account-details")}}>{textMoreDetails}</a>
                <div className="c_chevron_right-div">
                    <img className="c_chevron_right" src={src} alt={alt} />
                    <img className="c_chevron_right" src={src} alt={alt} />
                    <img className="c_chevron_right" src={src} alt={alt} />
                </div>
            </div> */}
            </div>

        </div>
        
        </>
    )
}

export default SkeletonDashboardData;