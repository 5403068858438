import {
  THEME_DARK,
  THEME_LIGHT,
  OTP_EMAIL,
  OTP_MOBILENO,
  OTP_CARD,
  OTP_NULL,
} from "./actions";
const themeAvailable = sessionStorage.getItem("currentTheme");
let newTheme = themeAvailable
  ? themeAvailable
  : sessionStorage.setItem("currentTheme", THEME_LIGHT);

export const themeReducer = (state, action) => {
  newTheme = state;

  switch (action.type) {
    case THEME_LIGHT:
      newTheme = THEME_LIGHT;
      break;
    case THEME_DARK:
      newTheme = THEME_DARK;
      break;
    default:
      newTheme = state;
      break;
  }

  sessionStorage.setItem("currentTheme", newTheme);
  return newTheme;
};

export const otpReducer = (state, action) => {
  switch (action.type) {
    case OTP_EMAIL:
      return { ...state, email: action.payload };
    case OTP_MOBILENO:
      return { ...state, mobileNo: action.payload };
    case OTP_CARD:
      return { ...state, card: action.payload };
    case OTP_NULL:
      return { email: null, mobileNo: null };
    default:
      return state;
  }
};
