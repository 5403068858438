import React, { useRef, useState } from "react";

//Context
import { useContext } from "react";
import ThemeContext from "../../../../../Shared/context/ThemeContext/store/ThemeContext";
import { THEME_LIGHT } from "../../../../../Shared/context/ThemeContext/actions";

//Components
import Button from "../../../global/Button";

//CSS
import "./UserID.scss";
import SuccessPopUp from "../../../global/SuccessPopUp";

//Toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

const UserID = ({ onForgotUserID, oninitialLogin, userid }) => {
    const [themeState] = useContext(ThemeContext);
    const [successPopUpModal, setSuccessPopUpModal] = useState(false);
    const [successPopUpContent, setSuccessPopUpContent] = useState("");
    //Use Ref
    const spanValueRef = useRef(null);
    const copyTextRef = () => {
        navigator.clipboard.writeText(userid);
        // navigator.clipboard.writeText(spanValueRef.current.innerHTML);
        toast("User ID Copied!", {
            position: "bottom-center",
            className: 'toast-bar'
        })
    };


    return (
        <div className="user-id-container">
            {successPopUpModal ? <SuccessPopUp
                onClick={() => setSuccessPopUpModal(false)}
                visible={successPopUpModal}
                setSuccessModalStat={setSuccessPopUpModal}
                successPopUpContent={successPopUpContent}
            /> : null}
            {/* <div className="user-id-container-backBtn">
                <Button
                    className={"login-ui-btns login-btn-3"}
                    onClick={onForgotUserID}
                >
                    {themeState === THEME_LIGHT ? (
                        <img
                            src={"assets/images/backButtonArrow.svg"}
                            alt="backButtonArrow"
                        />
                    ) : (
                        <img
                            src={"assets/images/lightBackButtonArrow.svg"}
                            alt="lightBackButtonArrow"
                        />
                    )}

                    <label>Back</label>
                </Button>
            </div> */}
            <div className="user-id-container-header">
                <h2>Here's your User ID</h2>
                <div></div>
                {/* <p>Here's your User ID</p> */}
            </div>
            <div className="user-id-container-user-id-value">
                <h2 ref={spanValueRef}>{userid}</h2>
            </div>
            <div className="user-id-container-btn">
                <div className="user-id-container-btn-copy-text" style={{ cursor: "pointer" }} onClick={copyTextRef}>
                    {themeState === "THEME_LIGHT" ? (
                        <img
                            src="assets/images/light-icon/copy-light-icon.svg"
                            alt="darkCopyText"
                        />
                    ) : (<img
                        src="assets/images/dark-icon/copy-dark-icon.svg"
                        alt="darkCopyText"

                    />)}
                    <span>Copy User ID</span>
                </div>
                <ToastContainer />
                <Button
                    className={"login-ui-btns login-btn-1"}
                    onClick={oninitialLogin}
                >
                    <label>Back to Login</label>
                </Button>
            </div>
        </div>
    );
};

UserID.propTypes = {
    oninitialLogin: PropTypes.func,
    userid: PropTypes.string
}

export default UserID;
