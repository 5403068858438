import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "../../container/PageWrapper";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../../../Shared/context/ThemeContext/store/PageNameContext";
import RadiantBorder from "../../global/RadiantBorder";
import "./OverDueSummary.scss";
import overdueSummaryData from "./Data";
import OverDueSummaryServiceobject from "../../services/OverDueSummary";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
import useSessionAlive from "../../customHooks/useSessionAlive";
import commonFunctionObject from "../../common-function";
import { useNavigate } from "react-router";

function OverDueSummary() {
  const { pageName, setPageName } = useContext(pageNameContext);
  const [data, dispatchData] = useContext(DataMapping);
  const navigate = useNavigate()
  const { isUserSessionAlive } = useSessionAlive();
  // const [currenntContract, setCurrentContract ] = useState({})
  const [parsData, setParseData] = useState({});
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });

  var currentAcctounNumber = data?.currentActiveData?.accountNumber;
  var ccodData = data?.parsedUserData?.[currentAcctounNumber];
  var vdData = data?.vdSummary?.[currentAcctounNumber];
  useEffect(() => {
    setPageName("Overdue Summary");
    if (data.length === 0) {
      navigate("/login")
    } else {
      createParserTopopulateData();
      commonFunctionObject.scrollToTop()
    }
  }, []);

  const createParserTopopulateData = () => {
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    OverDueSummaryServiceobject.sapAndVdParser(ccodData.sapContract, vdData)
      .then((value) => {
        setParseData(value);
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
      })
      .catch((error) => {
        setFailurePopUpContent({
          apiName: "",
          message:
            "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
          errorCode: error,
        });
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
        setFailurePopUpModal(true);
      });
  };

  useEffect(() => {
    isUserSessionAlive(data?.authToken);
  }, []);

  return (
      <div className="main-container">
        <div className="overdue-summary">
          <div className="container">
            <RadiantBorder>
              <div className="overduesummary-container">
                <ul className="overduesummary-leftside">
                  {parsData &&
                    overdueSummaryData?.populatCutomerDetail?.leftSideData.map(
                      (value) => {
                        return (
                          <>
                            {
                              <li className="overduesummary-item">
                                <span className="overduesummary-field">
                                  {value.label}
                                </span>
                                <span className="overduesummary-value">
                                  {parsData[value.apiKey]}
                                </span>
                              </li>
                            }
                          </>
                        );
                      }
                    )}

                  {/* <li className="overduesummary-item">
                                        <span className="overduesummary-field">Customer Name</span>
                                        <span className="overduesummary-value">VIKASH KUMAR SINGH</span>
                                    </li> */}
                </ul>
                <div className="overduesummary-join"></div>
                <ul className="overduesummary-rightside">
                  {parsData &&
                    overdueSummaryData?.populatCutomerDetail?.rightSideData.map(
                      (value) => {
                        return (
                          <>
                            {
                              <li className="overduesummary-item">
                                <span className="overduesummary-field">
                                  {value.label}
                                </span>
                                <span className="overduesummary-value">
                                  {parsData[value.apiKey]}
                                </span>
                              </li>
                            }
                          </>
                        );
                      }
                    )}
                </ul>
              </div>
            </RadiantBorder>

            <div className="overdue-aging-maincontainer">
              <p className="overdue-aging">Overdue Ageing</p>
              <RadiantBorder className="table-container-border">
                <div className="table-container">
                  <table className="overdue-aging-container ">
                    <thead>
                      <tr>
                        {parsData &&
                          overdueSummaryData?.populatCutomerDetail?.overdueAging.map(
                            (val, index) => {
                              return (
                                <td className="overdue-day" key={index}>
                                  {val.label}
                                </td>
                              );
                            }
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {parsData &&
                          overdueSummaryData?.populatCutomerDetail?.overdueAging.map(
                            (val, index) => {
                              return (
                                <td className="overdue-Rupee" key={index}>
                                  {parsData[val.apiKey]}
                                </td>
                              );
                            }
                          )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </RadiantBorder>
            </div>
          </div>
        </div>
        {failurePopUpModal ? (
          <FailurePopUp
            onClick={() => setFailurePopUpModal(false)}
            visible={failurePopUpModal}
            setFailureModalStat={setFailurePopUpModal}
            failurePopUpContent={failurePopUpContent}
          />
        ) : null}
      </div>
  );
}

export default OverDueSummary;
