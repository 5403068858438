import andMany from './andOperatorMany';
import isDefined from './isDefined';

export default function updateFlatObjByKeyMap(keyMappingObj, fromObj, toObj) {
    if (andMany(isDefined(keyMappingObj), isDefined(fromObj), isDefined(toObj))) {
        Object.keys(keyMappingObj).forEach(function (key) {
            var fromObjKey = key;
            var toObjKey = keyMappingObj[key];
            toObj[toObjKey] = fromObj[fromObjKey];
        });
        return toObj;
    }
};