import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const pledgingBreResult = (data) => {

    let requestJson = {

        "header": {
            "authToken": ""
        },
        "body": {
            "breRequestList": [
                {
                    "isin": "INE438A01022",
                    "scripName": "APOLLO TYRES LTD",
                    "unitstodepledge": "300",
                    "unitPrice": "0",
                    "ltv": "0",
                    "noOfSharesCustHold": "1000",
                    "sanctionedLoanAmount": "3"

                },
                {
                    "isin": "INE438A01022",
                    "scripName": "APOLLO TYRES LTD",
                    "unitstodepledge": "300",
                    "unitPrice": "0",
                    "ltv": "0",
                    "noOfSharesCustHold": "1000",
                    "sanctionedLoanAmount": "3"

                }
            ],
            "clientId": "2254",
            "gcid": "991457",
            "sanctionLimit": "20000000.00",
            "totalOutstanding": "2096205.64"

        }

    }

    return new Promise((resolve, reject) => {
        // axios.post("/shaft/api/las-esb/pledging-bre-result/v1",data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-esb/pledging-bre-result/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default pledgingBreResult;