export const getLastestFieldValFromField = (params) => {

    var array = params.split("|"), m = "";

    array.forEach(function (el) {

        if (el !== "") {

            el.split(",").forEach(function (e) {

                if (e !== "" && m === "") {

                    m = e;

                }

            });

        }

    });

    return m;

}