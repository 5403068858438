import downloadBre from "../../apis/download-bre"
import { fetchLasTransactionDetails } from "../../apis/fetch-las-transaction-details/fetch-las-transaction-details"
import generateMobileOtp from "../../apis/generate-mobile-otp/generate-mobile-otp"
import getHolidayDetails from "../../apis/get-holiday-details"
import getPledgingUrl from "../../apis/get-pledging-url/get-pledging-url"
import paymentGateway from "../../apis/payment-gateway"
import pledgeDetailsFoliowise from "../../apis/pledge-details-foliowise"
import pledgingBreResult from "../../apis/pledging-bre-result"
import { saveCollateralRelease } from "../../apis/save-collateral-release/save-collateral-release"
import { saveDisbursementInfo } from "../../apis/save-disbursement-info/save-disbursement-info"
import commonFunctionObject from "../../common-function/commonfunctions"

const LasAccountDetailsServiceObject = {

}

const pledgeDetailsFoliowiseService = (pledgeDetailsFoliowiseRequest, accountNumber) => {
    return new Promise((resolve, reject) => {

        pledgeDetailsFoliowise(pledgeDetailsFoliowiseRequest).then((pledgeDetailsFoliowiseResponse) => {
            var holdingDetailsData = {};
            if (pledgeDetailsFoliowiseResponse.header.status === 'SUCCESS') {
                if (pledgeDetailsFoliowiseResponse.body.retStatus === 'SUCCESS') {
                    var bodyData = pledgeDetailsFoliowiseResponse.body.LASPledgeDetailsFolioWise_Response.NonPOAPledgeHoldingData;
                    var holdingDataArr = [];
                    if ((bodyData) && bodyData.length !== 0) {
                        bodyData.forEach(function (element) {
                            var pledgeFolioDetails = element.PledgeFolioDetails;
                            pledgeFolioDetails.forEach(function (ele) {
                                var holdingDetailsDataObj = {};
                                var ltvAndEligibility = "";
                                holdingDetailsDataObj["Margin"] = "-";
                                holdingDetailsDataObj["AssetClass"] = (element.PledgeType) ? String(element.PledgeType) : '-';
                                holdingDetailsDataObj["Account_Number"] = (element.LoanAccount) ? String(element.LoanAccount) : '-';
                                holdingDetailsDataObj["MarketRate"] = (element.MarketRate) ? String(element.MarketRate) : '';
                                holdingDetailsDataObj["ISIN"] = (ele.ISIN_AMFI) ? String(ele.ISIN_AMFI) : '';
                                holdingDetailsDataObj["AMFI"] = (element.PledgeType) ? element.PledgeType == "LIEN" ? ele.AMFI : "" : '';
                                holdingDetailsDataObj["ScripName"] = (ele.ScripName) ? String(ele.ScripName) : '';
                                holdingDetailsDataObj["PledgeQty"] = (ele.PledgeQty) ? Number(ele.PledgeQty).toFixed(4) : '';
                                holdingDetailsDataObj["AllowedQty"] = (element.AllowableQty) ? Number(element.AllowableQty).toFixed(4) : '';
                                holdingDetailsDataObj["FolioNumber"] = (ele.Pledge_FolioNo) ? String(ele.Pledge_FolioNo) : '';
                                holdingDetailsDataObj["PledgeOrderNumber"] = (ele.Pledge_FolioNo) ? String(ele.Pledge_FolioNo) : '';
                                holdingDetailsDataObj["DpAccountNumber"] = (ele.DPID) ? String(ele.DPID) : '';
                                holdingDetailsDataObj["SecurityKey"] = (ele.SecurityKey) ? String(ele.SecurityKey) : '';
                                holdingDetailsDataObj["PledgeeClientId"] = (ele.PledgeeClientId) ? String(ele.PledgeeClientId) : '';
                                holdingDetailsDataObj["ClientID"] = (ele.ClientID) ? String(ele.ClientID) : '';
                                holdingDetailsDataObj["PledgeeDPId"] = (ele.PledgeeDPId) ? String(ele.PledgeeDPId) : '';
                                holdingDetailsDataObj["InputFieldValue"] = (ele.InputFieldValue) ? String(ele.InputFieldValue) : '';

                                holdingDetailsDataObj["Main_ISIN"] = (ele.Main_ISIN) ? String(ele.Main_ISIN) : '';
                                holdingDetailsDataObj["MarketValue"] = (element.MarketRate) ? (ele.PledgeQty) ? Number(element.MarketRate * ele.PledgeQty).toFixed(2) : '0' : '0';
                                ltvAndEligibility = calculateLTV((element.DPofSecurity) ? Number(element.DPofSecurity) : 0, Number(element.MarketRate), element.AllowableQty ? Number(element.AllowableQty) : 0, ele.PledgeQty ? Number(ele.PledgeQty) : 0);
                                holdingDetailsDataObj["LTV"] = ltvAndEligibility.ltv;
                                holdingDetailsDataObj["Eligibility"] = ltvAndEligibility.eligibility;
                                holdingDataArr.push(holdingDetailsDataObj);
                            })

                        })
                        holdingDetailsData = holdingDataArr;
                        resolve(holdingDetailsData)
                        //getBreResultList(holdingDetailsData, true);
                    } else {
                        if (pledgeDetailsFoliowiseResponse.body.LASPledgeDetailsFolioWise_Response.status.Status.toUpperCase() === "SUCCESS") {
                            holdingDetailsData[accountNumber] = holdingDataArr;
                            resolve("noData")
                            //getBreResultList(holdingDetailsData, false);
                        } else {
                            reject("L-17")
                        }
                    }
                } else {
                    reject("L-17")
                }
            } else {
                reject("L-17")
            }
            // resolve()
        }).catch(() => {
            reject("L-16")
        })
    })
}
LasAccountDetailsServiceObject.pledgeDetailsFoliowiseService = pledgeDetailsFoliowiseService


const pledgingBreResultService = (pledgingBreResultRequest) => {
    return new Promise((resolve, reject) => {
        pledgingBreResult(pledgingBreResultRequest).then((pledgingBreResultResponse) => {
            if (pledgingBreResultResponse?.header?.status.toUpperCase() === 'SUCCESS') {
                if (pledgingBreResultResponse?.body?.retStatus.toUpperCase() === 'SUCCESS') {
                    if (pledgingBreResultResponse?.body?.CalculateBREPledgingDetails_Response) {
                        var localStringObj = {
                            style: "currency",
                            currency: "INR",
                            decimal: '2'
                        }
                        var breResponseList = pledgingBreResultResponse.body.CalculateBREPledgingDetails_Response.breResponseList;
                        var breResponseArray = pledgingBreResultResponse.body.CalculateBREPledgingDetails_Response;
                        var breResponse = {};


                        breResponseArray["sanctionLimit"] = breResponseArray.sanctionLimit ? Number(breResponseArray.sanctionLimit) : '0';;
                        breResponseArray["totalOutstanding"] = breResponseArray.totalOutstanding ? Number(breResponseArray.totalOutstanding) : '0';;
                        breResponseArray["eligibleDrawingPower"] = breResponseArray.eligbileDrawingPower ? Number(breResponseArray.eligbileDrawingPower) : '0';
                        breResponseArray["breCalculatedTotal"] = breResponseArray.breCalculatedTotal ? Number(breResponseArray.breCalculatedTotal) : '0';;
                        breResponseArray["isDepledgeAllowed"] = breResponseArray.isDepledgeAllowed ? String(breResponseArray.isDepledgeAllowed) : '';
                        breResponseArray["isDisbursementAllowed"] = breResponseArray.isDisbursementAllowed ? String(breResponseArray.isDisbursementAllowed) : '';
                        breResponseArray["message"] = breResponseArray.message ? String(breResponseArray.message) : '';


                        //dataStore.set("breResponse", breResponseArray);
                        var unitsAllowedArray = [];
                        breResponseList.forEach(function (value) {
                            unitsAllowedArray.push({
                                unitsAllowed: value.unitsAllowed,
                                isin: value.scripCode
                            });
                        });

                        resolve(breResponseArray)
                    } else {
                        reject("L-05")
                    }
                } else {
                    if (pledgingBreResultResponse.body.CalculateBREPledgingDetails_Response) {
                        if (pledgingBreResultResponse.body.CalculateBREPledgingDetails_Response.status.statusCode == 200) {
                            var rejectMessage = { message: pledgingBreResultResponse.body.CalculateBREPledgingDetails_Response.message }
                            reject(rejectMessage)
                        } else {
                            reject("L-05")
                        }
                    } else {
                        reject("L-05")
                    }
                }
            } else {
                reject("L-05")
            }
        }).catch((error) => {
            reject("L-04")
        })
    })
}

LasAccountDetailsServiceObject.pledgingBreResultService = pledgingBreResultService


const getHolidayDetailsService = (requestObject) => {
    return new Promise((resolve, reject) => {
        getHolidayDetails(requestObject).then((getHolidayDetailsResponse) => {
            if (getHolidayDetailsResponse.header.status == "SUCCESS") {
                resolve(getHolidayDetailsResponse)
            } else {
                reject()
            }
        }).catch((error) => {
            reject()
        })
    })
}
LasAccountDetailsServiceObject.getHolidayDetailsService = getHolidayDetailsService


const getPledgingURLService = (pledgingURLRequest) => {
    return new Promise((resolve, reject) => {
        getPledgingUrl(pledgingURLRequest).then((pledgingURLResponse) => {
            if (pledgingURLResponse.header.status === 'SUCCESS') {
                if (pledgingURLResponse.body.finalUrl) {
                    resolve(pledgingURLResponse.body.finalUrl)
                } else {
                    reject("L-15")
                }
            } else {
                reject("L-14")
            }

        }).catch((error) => {
            reject("L-13")
        })
    })
}
LasAccountDetailsServiceObject.getPledgingURLService = getPledgingURLService


const paymentGatewayService = (paymentGatewayRequest) => {
    return new Promise((resolve, reject) => {
        paymentGateway(paymentGatewayRequest).then((paymentGatewayResponse) => {
            if (paymentGatewayResponse.header.status === "SUCCESS") {
                if (paymentGatewayResponse.body.finalUrl) {
                    resolve(paymentGatewayResponse.body.finalUrl);
                }
            } else {
                reject("L-23")
            }
        })
    })

}
LasAccountDetailsServiceObject.paymentGatewayService = paymentGatewayService


function calculateLTV(dpOfSecurity, marketRate, allowableQty, pledgeQty) {
    var ltvAndEligibility = {}
    var eligibityVal = dpOfSecurity * 100;
    var marketValueLTV = marketRate * allowableQty;
    var ltv = eligibityVal / marketValueLTV;
    var marketValue = marketRate * pledgeQty
    var finalLtv = Math.round(ltv);
    var ltvPer = (finalLtv / 100);
    var eligibility = ltvPer * marketValue;
    ltvAndEligibility["ltv"] = finalLtv;
    ltvAndEligibility["eligibility"] = eligibility.toFixed(2);

    return ltvAndEligibility;
}



function createBreRequestListArray(quantityObj, holdingDetailsData, lasopenContracts) {
    var sanctionedAmount = lasopenContracts[0].LoanAccount[0].sanctionedamount;
    var requestArray = [];
    var pledgeDetailsFolioRes = holdingDetailsData
    pledgeDetailsFolioRes.filter(function (elem) {
        if (elem.AssetClass == 'Demat') {
            var unitstodepledge = 0;
            if (quantityObj[elem.ISIN]) {
                unitstodepledge = quantityObj[elem.ISIN];
            }
            var qtyObj = {
                isin: elem.ISIN,
                scripName: elem.ScripName,
                ltv: 0,
                unitPrice: elem.MarketRate,
                loanContribution: 0,
                noOfSharesCustHold: elem.AllowedQty,
                sanctionedLoanAmount: sanctionedAmount,
                unitstodepledge: unitstodepledge,
            };
            requestArray.push(qtyObj);
        }
    });
    return requestArray;
}
LasAccountDetailsServiceObject.createBreRequestListArray = createBreRequestListArray


function filterBreRequestArrFn(requestArray) {
    var newArray = [];
    requestArray.forEach(function (element) {
        if (newArray.length > 0) {
            var filterData = newArray.filter(function (value) { return value.isin === element.isin })
            if (filterData.length <= 0) {
                newArray.push(element);
            }
        } else {
            newArray.push(element);
        }
    });
    return newArray;
}
LasAccountDetailsServiceObject.filterBreRequestArrFn = filterBreRequestArrFn


const generateMobileOtpService = (generateMobileOtpRequest) => {
    return new Promise((resolve, reject) => {
        generateMobileOtp(generateMobileOtpRequest).then((generateMobileOtpResponse) => {
            if (generateMobileOtpResponse.header.status == "SUCCESS") {
                if (generateMobileOtpResponse.body.retStatus == "SUCCESS") {
                    resolve(generateMobileOtpResponse.body.otpRefNo)
                } else {
                    reject("L-30")
                }
            } else if (generateMobileOtpResponse.header.status.toUpperCase() === "FAILURE") {
                if (generateMobileOtpResponse.body.message.toLowerCase().includes('exceeded')) {
                    reject("number of attempts exceeded");
                }
            }
            else {
                reject("L-29")
            }
        }).catch((error) => {
            reject("L-28")
        })
    })
}

LasAccountDetailsServiceObject.generateMobileOtpService = generateMobileOtpService


const saveDisbursementInfoService = (saveDisbursementInfoRequest) => {
    return new Promise((resolve, reject) => {
        saveDisbursementInfo(saveDisbursementInfoRequest).then((saveDisbursementInfoResponse) => {
            if (saveDisbursementInfoResponse.header.status == "SUCCESS") {
                resolve(saveDisbursementInfoResponse)
            } else {
                reject("L-35")
            }
        }).catch(() => {
            reject("L-34")
        })
    })
}
LasAccountDetailsServiceObject.saveDisbursementInfoRequest = saveDisbursementInfoService


const fetchLasTransactionApiService = (fetchLasTransactionRequest) => {

    return new Promise((resolve, reject) => {
        fetchLasTransactionDetails(fetchLasTransactionRequest).then((fetchLasTransactionApiResponse) => {
            if (fetchLasTransactionApiResponse.header.status === 'SUCCESS') {
                resolve(fetchLasTransactionApiResponse)

            } else {
                reject()
            }
        }).catch((error) => {
            reject()
        })
    })
}
LasAccountDetailsServiceObject.fetchLasTransactionApiService = fetchLasTransactionApiService

const saveCollateralApiService = (saveCollateralReleaseRequest) => {
    return new Promise((resolve, reject) => {
        saveCollateralRelease(saveCollateralReleaseRequest).then((saveCollateralReleaseResponse) => {
            if (saveCollateralReleaseResponse.header.status === 'SUCCESS' && saveCollateralReleaseResponse.body.retStatus === 'SUCCESS') {
                resolve(saveCollateralReleaseResponse)
            } else {
                reject("L-08")
            }
        }).catch((error) => {
            reject("L-07")
        })
    })
}
LasAccountDetailsServiceObject.saveCollateralApiService = saveCollateralApiService


const downloadBreService = (downloadBreRequest) => {
    return new Promise((resolve, reject) => {
        downloadBre(downloadBreRequest).then((downloadBreResponse) => {
            if (downloadBreResponse.header && downloadBreResponse.header.status.toUpperCase() === "SUCCESS") {
                var excel = downloadBreResponse.body.encryptedExcelData;
                var byteArr = commonFunctionObject.base64ToArrayBuffer(excel);
                var fileName = downloadBreResponse.body.fileName + ".xlsx";
                var mimeType = "application/xlsx";
                commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
                resolve()
            } else {
                reject("L-06")
            }
        }).catch(error => {
            reject("L-31")
        })
    })
}
LasAccountDetailsServiceObject.downloadBreService = downloadBreService

export default LasAccountDetailsServiceObject