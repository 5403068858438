import React from "react";
import "./Button.scss";
import PropTypes from 'prop-types'

const Button = ({ children, type, className, onClick, style, disable }) => {
  return (
    <button
      style={{ ...style }}
      onClick={onClick}
      className={`global-button ${className ? className : ""}`}
      type={type ? type : "button"}
      disabled={disable ? disable : null}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children : PropTypes.node,
  className : PropTypes.string,
  type : PropTypes.string,
  onClick :PropTypes.func,
  style :PropTypes.object,
  disable :PropTypes.bool,
}

export default Button;
