import React from 'react'
import { useContext } from 'react'
import LoanInfoComponent from '../../components/LoanInfoComponent'
import DataMapping from '../../../../Shared/context/data-mapping'
import RadiantBorder from '../../global/RadiantBorder'
import './AssestDetails.scss'
import PropTypes from 'prop-types';

const AssestDetails = ({activeContract }) => {
  const [data, dispatchData] = useContext(DataMapping)
  var currentAssetDat = data.assetDetails[activeContract]
  var enginNo = currentAssetDat.enginenumber.toString() !== "0" ? currentAssetDat.enginenumber : "-";
  var chasisNo = currentAssetDat.chasisnumber.toString() !== "0" ? currentAssetDat.chasisnumber : "-";
  return (
    <>
      <div className='asset-details'>
        <div className='asset-details-content'>
          {/* <h2>
            Assets Details
          </h2> */}
          <RadiantBorder className="asset-info-container">
            <div className='account-information-container-inner'>
              <LoanInfoComponent >
                <ul className='loan-detail-info-ul'>
                  <li><div className='label'>Vehicle registration number </div><div className='label-amount'>{currentAssetDat.registrationnumber.toString()}</div></li>
                  <li><div className='label'>Engine number </div><div className='label-amount'>{enginNo}</div></li>
                  <li><div className='label'>Chassis number </div><div className='label-amount'>{chasisNo}</div></li>
                  <li><div className='label'>Manufacturer model and variant </div><div className='label-amount'>{`${currentAssetDat.manufacturer.toString()} ${currentAssetDat.assetmodel.toString()} ${currentAssetDat.assetvariant.toString()}`} </div></li>
                </ul>
              </LoanInfoComponent>
            </div>

          </RadiantBorder>
        </div>
      </div>
    </>

  )
}

AssestDetails.propTypes = {
  activeContract : PropTypes.string
}

export default AssestDetails
