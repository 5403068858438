import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '../../global/Button/Button';
import Input from '../../global/Input';
import './Documents.scss'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RadiantBorder from '../../global/RadiantBorder';
import CustomDropDown from '../../global/CustomDropDown/CustomDropDown';
import AccountDetailsServicObject from '../../services/AccountDetailsService/AccountDetailsService'
import commonFunctionObject from "../../common-function";
// import dataObj from '../../screens/Retail-Account-Details/Data';
import dataObj from '../../../../Shared/screens/Retail-Account-Details/Data';
import Modal from '../../global/Modal';
// import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import ThemeContext from '../../../../Shared/context/ThemeContext/store/ThemeContext';
import Loader from '../../global/Loader';
import OverviewServiceObject from "../../services/Overview";
import DataMapping from '../../../../Shared/context/data-mapping';
import { STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type';
import FailurePopUp from '../../global/FailurePopUp';
import AgreementAndAnnexure from '../../components/Agreement/Agreement'
import ModernDatepicker from '../../components/Forms/ReactDatepicker'
import { useNavigate } from 'react-router-dom';
import RcUpdationPopup from '../../components/RcUpdationPopup/RcUpdationPopup';
import jsHelper from '../../utilities/jsHelper';
import EmailPopUp from '../../global/EmailPopUp';
import NocPopUp from '../../components/NocPopUp';
import AnalyticsContextProvider from '../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider';
import CustomerServicePopUp from '../../components/CustomerServicePopUp';
import PropTypes from 'prop-types';

var documentObj = [
    {
        documentName: "Loan statement",
        products: [],
        productfamily: [],
        contractStatus: [true, false]
    },
    {
        documentName: "amortization",
        products: [],
        productfamily: [],
        contractStatus: [true, false]
    },
    {
        documentName: "final it certificate",
        products: [],
        productfamily: [],
        contractStatus: [false]
    },
    {
        documentName: "provisional it certificate",
        products: [],
        productfamily: [],
        contractStatus: [false]
    },
    {
        documentName: "noc",
        products: ["personal loan",
            "business loan",
            "home loan",
            "loan against property",
            "insurance loan",
            "two wheeler",
            "used car",
            "consumer durable"],
        productfamily: [],
        contractStatus: [false]
    },
    {
        documentName: "Foreclosure later",
        products: [],
        productfamily: [],
        contractStatus: [false]
    },
    {
        documentName: "agreemnet and annexure",
        products: [],
        productfamily: ["cfab"],
        contractStatus: [true,false]
    },
    /* {
        documentName: "rc update",
        products: ["two wheeler"],
        productfamily: [],
        contractStatus: [false]
    }, */
    // {
    //     documentName: "sanction letter",
    //     products: [
    //         "personal loan",
    //         "business loan",
    //         "two wheeler",
    //         "consumer durable",
    //         "used car",
    //     ],
    //     productfamily: ["cfab"],
    //     contractStatus: [true, false]
    // },
    {
        documentName: "welcome letter",
        products: [
            "personal loan",
            "two wheeler",
            "used car",
            "business loan",
            "consumer durable",
            "insurance loan"
        ],
        productfamily: ["cfab", "tchfl", "tcfsl"],
        contractStatus: [true, false]
    },
]


const Documents = ({ activeContract,portalType }) => {
    const navigate = useNavigate();
    const { analytics } = useContext(AnalyticsContextProvider);
    const [checkredirection, setcheckredirection] = useState(true);
    const [requestRaisedModal, setRequestRaisedModal] = useState(false);
    const [content, setContent] = useState("");
    const [finalItErrorFlag, setFinalItErrorFlag] = useState(false)
    const [themeState] = useContext(ThemeContext);
    const [data, dispatchData] = useContext(DataMapping)
    const [emailPopup, setemailPopup] = useState(false);
    const [startDateError, setStartDateError] = useState("")
    const [endError, setEndError] = useState("")
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState("");
    const [loaderFlag, setLoaderFlag] = useState(false);
    const [yearState, setYearState] = useState(false);
    const [selectedYear, setSelectedYear] = useState("Select Financial Year");
    const [nocOpenClosed, setNocOpenClosed] = useState(false);

    const [emailPopUpContentObject, setEmailPopUpContentObject] = useState({
        customerName: "",
        certificateName: "",
        emailFlag: "",
        loanAccNumber: "",
        email: ""
    })

    const [optionState, setOptionState] = useState(false);
    const [selectedOption, setSelectedOption] = useState("TCFPL0291000011290249");
    const [foreclosureLetterPopup, setforeclosureLetterPopup] = useState(false);
    const [agreementPopUp, setAgreementPopUp] = useState(false)
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const soaForm = useRef(null);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    });
    const [popUpContent, setPopUpContent] = useState("");
    const [welcomeLetterTchfl, setwelcomeLetterTchfl] = useState(false);
    const [letterPopup, setLetterPopup] = useState(false);
    const [serviceRequestPopup, setserviceRequestPopup] = useState(false);

    // variable

    var base64 = require('base-64');
    const userDetail = data?.parsedUserData[activeContract];
    var productName = commonFunctionObject.getProductName(userDetail)
    var productFamily = commonFunctionObject.getProductFamily(data?.parsedUserData[activeContract])

    var loanStatus = commonFunctionObject.checkContractStatus(data?.getLoanPositionDTLS[activeContract]?.body.getLoanPositionDtlsReturn.loanStatus);
    var activeSystem = data?.currentActiveData?.system;



    const handleStartDate = (date) => {
        if (date === null || date === "") {
            setStartDateError("Please Select")
        } else {
            setStartDateError("")
        }
    }

    const handleEndDate = (date) => {
        if (date === null || date === "") {
            setEndError("Please Select")
        } else if (new Date(commonFunctionObject.replaceHyphenDate(startDate)).getTime() > new Date(commonFunctionObject.replaceHyphenDate(date)).getTime()) {
            setEndError("Please Enter a Valid Date")
        } else {
            setEndError("")
        }
    }

    const resetForm = () => {
        setStartDate("")
        setEndDate("")
        setStartDateError("")
        setEndError("")

    }

    const lastFinancialYearSoa = () => {
        var contractName = userDetail.productInfo.DISPLAY_NAME;
        var accountNumber = userDetail.CONTRACT_NUMBER;
        var sourceSystem = userDetail.SOURCE_SYSTEM;
        // var accountNumber = data?.currentActiveData.accountNumber;

        let getServerTimeRequest = {
            "header": {
                "authToken": data.authToken
            },
            "body": {
            }
        }

        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        commonFunctionObject.getServerTimeService(getServerTimeRequest).then((serverTime) => {

            if ((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) < 4) {
                var currentYear = Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear() - 2);
                var nextYear = Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear() - 1);

            } else {
                var currentYear = Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear() - 1);
                var nextYear = Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear());

            }

            var financialYearFromDate = "01/04/" + currentYear;
            console.log(financialYearFromDate)
            var financialYearToDate = "31/03/" + nextYear;
            console.log(financialYearToDate);


            var financialYearDate = {
                "startDate": financialYearFromDate,
                "endDate": financialYearToDate
            }
            soaRequest("statement", contractName, accountNumber, financialYearDate, sourceSystem);


        }).catch((error) => {
            dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false
            })
        })


        //QWERTYU
        /* var financialYearFromDate = "";
        var financialYearToDate = ""; */

    }


    const downloadSoa = (e) => {
        e.preventDefault();
        var accountNumber = data?.currentActiveData.accountNumber
        var currentUserData = data?.parsedUserData[accountNumber]

        const date = soaForm.current
        var contractName = currentUserData.DISPLAY_NAME;
        var accountNumber = currentUserData.CONTRACT_NUMBER;
        var sourceSystem = currentUserData.SOURCE_SYSTEM;

        if (startDate !== "" && endDate !== "") {
            var fromDate = ((Number(new Date(commonFunctionObject.replaceHyphenDate(startDate)).getDate()) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(startDate)).getDate()) : Number(new Date(commonFunctionObject.replaceHyphenDate(startDate)).getDate())) + "/" + (((Number(new Date((commonFunctionObject.replaceHyphenDate(startDate))).getMonth() + 1)) < 10) ? "0" + Number(new Date((commonFunctionObject.replaceHyphenDate(startDate))).getMonth() + 1) : Number(new Date((commonFunctionObject.replaceHyphenDate(startDate))).getMonth() + 1)) + "/" + Number(new Date((commonFunctionObject.replaceHyphenDate(startDate))).getFullYear())
            var toDate = ((Number(new Date(commonFunctionObject.replaceHyphenDate(endDate)).getDate()) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(endDate)).getDate()) : Number(new Date(commonFunctionObject.replaceHyphenDate(endDate)).getDate())) + "/" + (((Number(new Date((commonFunctionObject.replaceHyphenDate(endDate))).getMonth() + 1)) < 10) ? "0" + Number(new Date((commonFunctionObject.replaceHyphenDate(endDate))).getMonth() + 1) : Number(new Date((commonFunctionObject.replaceHyphenDate(endDate))).getMonth() + 1)) + "/" + Number(new Date((commonFunctionObject.replaceHyphenDate(endDate))).getFullYear())
            var formData = {
                "startDate": fromDate,
                "endDate": toDate
            };
            soaRequest("statement", contractName, accountNumber, formData, sourceSystem);
        }
        else {
            setStartDateError("Please select date")
            setEndError("Please select date")
        }
        console.log(formData)
    }



    const soaRequest = (statementId, contractName, accountNumber, formData, sourceSystem) => {
        var product_family = commonFunctionObject.getProductFamily(userDetail);
        var firstName = userDetail.FIRSTNAME;
        var lastName = userDetail.LASTNAME;
        var reqObj = {};
        var currentUserData = data?.parsedUserData[accountNumber];
        var gcid = currentUserData.GCID;

        let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
        let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        if (productName.toLowerCase() === "ccod") {

            var company = currentContract.cdiContract.COMPANY;
            var companyName = "";
            (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
        }
        else {
            var companyName = productFamily;
        }

        if (sourceSystem === "FINONE") {
            reqObj = {
                "header": {
                    "authToken": data.authToken,
                    "productFamily": product_family,
                    "emailSent": true
                },
                "body": {
                    "accountNumber": accountNumber.toString(),
                    "fromDate": formData.startDate,
                    "toDate": formData.endDate
                }
            }
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            AccountDetailsServicObject.getGenerateSoaReportService(reqObj, statementId).then((response) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                var dataObj = {
                    "documentName": statementId,
                    "productFamily": reqObj.header.productFamily,
                    "description": "Generate statement of account for " + reqObj.body.accountNumber + " loan account.",
                    "sebServiceRequestType": "statementOfAccount",
                    "errorCode": ""
                }
                // if(portalType !== 'collection'){
                    setemailPopup(true)
                    showTriggerEmailPopUp(dataObj, accountNumber, response.body.isEmailAvailable, commonFunctionObject.getLastestFieldValFromField(userDetail.EMAIL), firstName, lastName)
                // }
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "SUCCESS",
                        eventName: "accountstatementDownload"
                    }
                }
                analytics.trackData("accountstatementDownload", analytics_data);

            }).catch((error) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                setFailurePopUpContent({
                    apiName: "generate-soa-report/v1",
                    message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    errorCode: error
                })
                setFailurePopUpModal(true);
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "FAILURE",
                        eventName: "accountstatementDownload"
                    }
                }
                analytics.trackData("accountstatementDownload", analytics_data);


            });

        }
        else if (sourceSystem === "BANCS") {
            var requestType = "detail-soa";
            reqObj = {
                "header": {
                    "authToken": data.authToken,
                    "requestType": requestType,
                    "productFamily": product_family
                },
                "body": {
                    "FromDate": formData.startDate,
                    "ToDate": formData.endDate,
                    "AcctNum": base64.encode((accountNumber))
                }
            }
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            AccountDetailsServicObject.boGetUrlService(reqObj).then((bogeturlResponse) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }).catch((error) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                setFailurePopUpContent({
                    apiName: "bo-get-url/v1",
                    message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    errorCode: error
                })
                setFailurePopUpModal(true)

            })
        }
        else {

        }


    }

    // const downloadAmortSchedule = () => {
    //     if (startDate && endDate) {
    //         setLoaderFlag(true)
    //         var fromDate = ((Number(startDate.getDate()) < 10) ? "0" + Number(startDate.getDate()) : Number(startDate.getDate())) + "/" + (((Number((startDate).getMonth() + 1)) < 10) ? "0" + Number((startDate).getMonth() + 1) : Number((startDate).getMonth() + 1)) + "/" + Number((startDate).getFullYear())
    //         var toDate =  ((Number(endDate.getDate()) < 10) ? "0" + Number(endDate.getDate()) : Number(endDate.getDate())) + "/" + (((Number((endDate).getMonth() + 1)) < 10) ? "0" + Number((endDate).getMonth() + 1) : Number((endDate).getMonth() + 1)) + "/"+ Number((endDate).getFullYear())
    //         var formData = {
    //             "startDate": fromDate,
    //             "endDate": toDate
    //         }
    //         amortRequest("amortizationSchedule", userDetail.productInfo.DISPLAY_NAME, activeContract, formData)
    //     } else {
    //         alert("please enter startDate and End Date")
    //     }
    // }
    const amortLastFinancialYear = () => {
        var fromDate = "";
        var toDate = "";
        let getServerTimeRequest = {
            "header": {
                "authToken": data.authToken
            },
            "body": {
            }
        }
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        commonFunctionObject.getServerTimeService(getServerTimeRequest).then((serverTime) => {
            fromDate = ((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate()) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate()) : Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate())) + "/" + (((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1) : Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) + "/" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear())
            toDate = ((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate()) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate()) : Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate())) + "/" + (((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1) : Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) + "/" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear())
            var financialYearDate = {
                "startDate": fromDate,
                "endDate": toDate
            }
            amortRequest("amortizationschedule", userDetail.productInfo.DISPLAY_NAME, userDetail.CONTRACT_NUMBER, financialYearDate)
        }).catch((error) => {

            dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false
            })
        })

    }

    const amortRequest = (statmentID, contractName, activeContract, formData) => {

        var accountNumber = data?.currentActiveData.accountNumber
        var currentUserData = data?.parsedUserData[accountNumber]
        var gcid = currentUserData.GCID;
        let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]

        const newSatrtDateArray = formData.startDate.split("/")
        const newEndDateArray = formData.endDate.split("/")
        const newSatrtDate = newSatrtDateArray[1] + "/" + newSatrtDateArray[0] + "/" + newSatrtDateArray[2]
        const newStartDate = newEndDateArray[1] + "/" + newEndDateArray[0] + "/" + newEndDateArray[2]
        var product_family = commonFunctionObject.getProductFamily(currentUserData);
        const sourceSystem = currentUserData.SOURCE_SYSTEM
        var startDate = jsHelper.toString(newSatrtDate);
        var endDate = jsHelper.toString(newStartDate);
        var StartDateArray = startDate.split("/");
        var EndDateArray = endDate.split("/");
        var sendStartDate = StartDateArray[1] + "/" + StartDateArray[0] + "/" + StartDateArray[2];
        var sendEndDate = EndDateArray[1] + "/" + EndDateArray[0] + "/" + EndDateArray[2];

        //Adobe Call for amort
        let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        if (productName.toLowerCase() === "ccod") {

            var company = currentContract.cdiContract.COMPANY;
            var companyName = "";
            (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
        }
        else {
            var companyName = productFamily;
        }
        if (sourceSystem === "FINONE") {
            var reqObj = {
                "header": {
                    "authToken": data.authToken,
                    "productFamily": product_family,
                    "emailSent": true
                },
                "body": {
                    "accountNumber": activeContract,
                    "fromDate": sendStartDate,
                    "toDate": sendEndDate
                }
            }

            AccountDetailsServicObject.AmortizationFinoneService(reqObj).then((response) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })

                var dataObj = {
                    "documentName": statmentID,
                    "productFamily": product_family,
                    "description": "Generate Amortization Schedule letter of account for " + reqObj.body.accountNumber + " loan account.",
                    "sebServiceRequestType": "amortizationSchedule",
                    "errorCode": "252",
                }
                // if(portalType !== 'collection'){
                    setemailPopup(true)
                    showTriggerEmailPopUp(dataObj, activeContract, response.body.isEmailAvailable, commonFunctionObject.getLastestFieldValFromField(userDetail.EMAIL), userDetail.FIRSTNAME, userDetail.LASTNAME);
                // }
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "SUCCESS",
                        eventName: "amortizationscheduleDownload"
                    }
                }
                analytics.trackData("amortizationscheduleDownload", analytics_data);
            }).catch((error) => {

                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "FAILURE",
                        eventName: "amortizationscheduleDownload"
                    }
                }
                analytics.trackData("amortizationscheduleDownload", analytics_data);
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                setFailurePopUpContent({
                    apiName: "",
                    message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    errorCode: error
                })
                setFailurePopUpModal(true)

            })
        }
        if (sourceSystem === "BANCS") {
            var reqObj = {
                "header": {
                    "authToken": data.authToken,
                    "requestType": "repay-schedule",
                    "productFamily": product_family
                },
                "body": {
                    "FromDate": newSatrtDate,
                    "ToDate": newStartDate,
                    "AcctNum": base64.encode(activeContract)
                    // "AcctNum": "MDAwMDAwMDAwMDM1MjUxMjk="
                }
            }
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            AccountDetailsServicObject.AmortizationBancsService(reqObj, statmentID).then((response) => {
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "SUCCESS",
                        eventName: "amortizationscheduleDownload"
                    }
                }
                analytics.trackData("amortizationscheduleDownload", analytics_data);
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }).catch((error) => {
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "FAILURE",
                        eventName: "amortizationscheduleDownload"
                    }
                }
                analytics.trackData("amortizationscheduleDownload", analytics_data);

                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                setFailurePopUpContent({
                    apiName: "",
                    message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    errorCode: error
                })
                setFailurePopUpModal(true)
            })
        }
    }
    var disbursalDate
    disbursalDate = new Date(commonFunctionObject.convertDisbursalDate(userDetail.DISBURSAL_DATE)).getFullYear();
    var disbursalMonth = new Date(commonFunctionObject.convertDisbursalDate(userDetail.DISBURSAL_DATE)).getMonth();
    const [yearsArr, setYearsArr] = useState([])

    var yearPicker = (disbursalDate, disbursalMonth, currentDate) => {
        var yearLastTwoDigit =
            jsHelper.isDef(disbursalDate) ?
                jsHelper.isDef(disbursalMonth + 1) ?
                    disbursalMonth + 1 < 4 ?
                        jsHelper.toNum(disbursalDate) - 1
                        : jsHelper.toNum(disbursalDate)
                    : new Error("contractDetail.disbursalMonth is not defined")
                : new Error("contractDetail.years is not defined");
        var todaysYearLastTwoDigit = (Number(new Date(commonFunctionObject.replaceHyphenDate(currentDate)).getMonth()) + 1 < 4) ? Number(new Date(commonFunctionObject.replaceHyphenDate(currentDate)).getFullYear() - 1) : Number(new Date(commonFunctionObject.replaceHyphenDate(currentDate)).getFullYear())
        let newArray = []
        for (
            yearLastTwoDigit;
            yearLastTwoDigit < todaysYearLastTwoDigit;
            yearLastTwoDigit++
        ) {
            var next = 1;
            next += yearLastTwoDigit;
            newArray.push({ value: yearLastTwoDigit + "-" + next });
        }

        dispatchData({
            name: "finalItFirstItem",
            payload: newArray[0],
            type: STOREDATA
        })
        if (newArray.length === 0) {
            setFinalItErrorFlag(true)
        } else {
            setFinalItErrorFlag(false)
            setYearsArr(newArray.reverse())
        }
        // return provisionalYearsArr
    };

    useEffect(() => {
        yearPicker(disbursalDate, disbursalMonth, new Date());
    }, [activeContract]);

    const finalItLastFinancialyear = () => {
        var selectedYearSplit = selectedYear.split("-")
        var financialYearFromDate = Number(new Date().getDate(new Date())) + "/" + Number(new Date().getMonth() + 1) + "/" + selectedYearSplit[0];
        console.log(financialYearFromDate)
        var financialYearToDate = Number(new Date().getDate(new Date())) + "/" + Number(new Date().getMonth() + 1) + "/" + selectedYearSplit[1];
        console.log(financialYearToDate)
        let formData

        if (selectedYear === data?.finalItFirstItem.value) {
            formData = {
                "startDate": commonFunctionObject.convertDDMMYYYY(new Date(commonFunctionObject.convertDisbursalDate(userDetail.DISBURSAL_DATE))),
                "endDate": "30/03/" + selectedYearSplit[1]
            }
        } else {
            formData = {
                "startDate": "01/04/" + selectedYearSplit[0],
                "endDate": "30/03/" + selectedYearSplit[1]
            }
        }
        var FiscalYear = "01-APR-" + selectedYearSplit[0] + "to" + "31-MAR-" + selectedYearSplit[1];
        var product_family = commonFunctionObject.getProductFamily(userDetail);
        const sourceSystem = userDetail.SOURCE_SYSTEM
        const accountNo = userDetail.CONTRACT_NUMBER;

        var accountNumber = data?.currentActiveData.accountNumber
        var currentUserData = data?.parsedUserData[accountNumber]
        var gcid = currentUserData.GCID;

        let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
        let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        if (productName.toLowerCase() === "ccod") {

            var company = currentContract.cdiContract.COMPANY;
            var companyName = "";
            (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
        }
        else {
            var companyName = productFamily;
        }


        if (sourceSystem === "FINONE") {
            var webTopNo = currentUserData.WEBTOP_NO;


            var reqObj = {
                header: {
                    authToken: data.authToken,
                    productFamily: product_family,
                    emailSent: true,
                    documentUploadType: "finalInterestCertificate",
                },
                body: {
                    accountNumber: accountNo,
                    fromDate: formData.startDate,
                    toDate: formData.endDate,
                },
            }
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            AccountDetailsServicObject.InterestCertificateServiceFinone(reqObj, "finalitcertificate").then((response) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                var dataObj = {
                    "documentName": "finalinterestcertificate",
                    "productFamily": reqObj.header.productFamily,
                    "decscription": "Generate Final Interest Certificate for " + accountNumber + " loan account.",
                    "webtopId": webTopNo,
                    "sebServiceRequestType": "finalInterestCertificate",
                    "errorCode": "",

                }
                // if(portalType !== 'collection'){
                    setemailPopup(true)
                    showTriggerEmailPopUp(dataObj, activeContract, response.body.isEmailAvailable, commonFunctionObject.getLastestFieldValFromField(userDetail.EMAIL), userDetail.FIRSTNAME, userDetail.LASTNAME)
                // }
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "SUCCESS",
                        eventName: "finalITcertificateDownload"
                    }
                }
                analytics.trackData("finalITcertificateDownload", analytics_data);
            }).catch((error) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "FAILURE",
                        eventName: "finalITcertificateDownload"
                    }
                }
                analytics.trackData("finalITcertificateDownload", analytics_data);
                setFailurePopUpContent({
                    apiName: "",
                    message: "We are experiencing technical difficulty please try after sometime",
                    errorCode: error
                })
                setFailurePopUpModal(true)
            })

        };
        if (sourceSystem === "BANCS") {
            var reqObj = {
                header: {
                    authToken: data.authToken,
                    requestType: "prov-int-cert",
                    productFamily: product_family,
                },
                body: {
                    FiscalYear: FiscalYear,
                    Report: "1",
                    AcctNum: accountNo,
                },
            }
            AccountDetailsServicObject.InterestCertificateServiceBancs(reqObj).then((response) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "SUCCESS",
                        eventName: "finalITcertificateDownload"
                    }
                }
                analytics.trackData("finalITcertificateDownload", analytics_data);
            }).catch((error) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "FAILURE",
                        eventName: "finalITcertificateDownload"
                    }
                }
                analytics.trackData("finalITcertificateDownload", analytics_data);
                setFailurePopUpContent({
                    apiName: "",
                    message: "We are experiencing technical difficulty please try after sometime",
                    errorCode: error
                })
                setFailurePopUpModal(true)
            })

        }
    }

    const downloadProvisionalCertificate = () => {
        let getServerTimeRequest = {
            "header": {
                "authToken": data.authToken
            },
            "body": {
            }
        }

        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })

        commonFunctionObject.getServerTimeService(getServerTimeRequest)
            .then((serverTime) => {

                if ((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) < 4) {
                    var currentYear = Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear() - 1);

                } else {
                    var currentYear = Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear());
                }
                // var nextYear = Number(new Date(serverTime).getFullYear() + 1);
                var financialYearFromDate = "01/04/" + currentYear;
                console.log(financialYearFromDate)
                var financialYearToDate = ((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate()) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate()) : Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate())) + "/" + (((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1) : Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) + "/" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear());
                // var financialYearToDate = "21/10/2022";
                console.log(financialYearToDate)
                var formData = {
                    "startDate": financialYearFromDate,
                    "endDate": financialYearToDate
                }
                provisionalCertificateRequest("provisionalcertificate", userDetail.productInfo.DISPLAY_NAME, userDetail.CONTRACT_NUMBER, formData)
            }).catch((error) => {

                dispatchData({
                    name: "loaderFlag",
                    type: STOREDATA,
                    payload: false
                })
                setFailurePopUpContent({
                    type: "api-failure: generate-interest-certificate/v1",
                    message: "We are experiencing technical difficulty please try after sometime",
                    errorCode: "23",
                });
                setFailurePopUpModal(true);
            })
    }

    const provisionalCertificateRequest = (statmentID, contractName, activeContract, formData) => {
        var accountNumber = data?.currentActiveData.accountNumber
        var currentUserData = data?.parsedUserData[accountNumber]

        var webTopNo = currentUserData.WEBTOP_NO;
        var product_family = commonFunctionObject.getProductFamily(userDetail);
        const sourceSystem = userDetail.SOURCE_SYSTEM
        const accountNo = userDetail.CONTRACT_NUMBER

        if (sourceSystem === "FINONE") {
            var gcid = currentUserData.GCID;

            let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
            let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
            if (productName.toLowerCase() === "ccod") {

                var company = currentContract.cdiContract.COMPANY;
                var companyName = "";
                (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
            }
            else {
                var companyName = product_family;
            }



            var reqObj = {
                header: {
                    authToken:
                        data?.authToken,
                    productFamily: product_family,
                    emailSent: true,
                    documentUploadType: "provisionalCertificate",
                },
                body: {
                    accountNumber: accountNo,
                    fromDate: formData.startDate,
                    toDate: formData.endDate,
                },
            }
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })

            AccountDetailsServicObject.InterestCertificateServiceFinone(reqObj, "provisionalcertificate").then((response) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })

                
                var dataObj = {
                    "documentName": statmentID,
                    "productFamily": reqObj.header.productFamily,
                    "decscription": "Generate  Provisional Certificate for " + accountNumber + " loan account.",
                    "webtopId": webTopNo,
                    "sebServiceRequestType": "provisionalCertificate",
                    "errorCode": "253"
                }
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "SUCCESS",
                        eventName: "provisionalITcertificateDownload"
                    }
                }
                analytics.trackData("provisionalITcertificateDownload", analytics_data);
                // if(portalType != 'collection'){
                    setemailPopup(true)
                    showTriggerEmailPopUp(dataObj, activeContract, response.body.isEmailAvailable, commonFunctionObject.getLastestFieldValFromField(userDetail.EMAIL), userDetail.FIRSTNAME, userDetail.LASTNAME)
                // }
            }).catch((error) => {
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "FAILURE",
                        eventName: "provisionalITcertificateDownload"
                    }
                }
                analytics.trackData("provisionalITcertificateDownload", analytics_data);

                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })

                setFailurePopUpContent({
                    type: "api-failure: generate-interest-certificate/v1",
                    message: "We are experiencing technical difficulty please try after sometime",
                    errorCode: "24",
                });
                setFailurePopUpModal(true);
            })
        };
        if (sourceSystem === "BANCS") {

            var reqObj = {
                header: {
                    authToken: "44dc2da3ed4b34288c36f6b736266b8c7304abf3c607ac48e9be7e338873e3f7",
                    requestType: "prov-int-cert",
                    productFamily: product_family
                },
                body: {
                    FiscalYear: "01-APR-22+to+31-MAR-23",
                    Report: "1",
                    AcctNum: accountNo
                }
            }
            AccountDetailsServicObject.InterestCertificateServiceBancs(reqObj).then((response) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "SUCCESS",
                        eventName: "provisionalITcertificateDownload"
                    }
                }
                analytics.trackData("provisionalITcertificateDownload", analytics_data);
            }).catch((error) => {
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Account Details",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "FAILURE",
                        eventName: "provisionalITcertificateDownload"
                    }
                }
                analytics.trackData("provisionalITcertificateDownload", analytics_data);
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })

                setFailurePopUpContent({
                    type: "api-failure: generate-interest-certificate/v1",
                    message: "We are experiencing technical difficulty please try after sometime",
                    errorCode: "24",
                });
                setFailurePopUpModal(true);
            })
        }
    }

    const showTriggerEmailPopUp = (dataObj, accountNumber, emailflag, email, firstName, lastName) => {
        var custormerName = ((firstName !== "") && (lastName !== "")) ? firstName + " " + lastName : ((firstName)) ? firstName : ((lastName)) ? lastName : "Customer";
        var certificateName = "";

        switch (dataObj.documentName.toLowerCase()) {
            case "statement":
                certificateName = "Statement of Account";
                break;
            case "amortizationschedule":
                certificateName = "Repayment Schedule";
                break;
            case "foreclosureletter":
                certificateName = "Foreclosure Letter";
                break;
            case "provisionalcertificate":
                certificateName = "Provisional Certificate";
                break;
            case "finalinterestcertificate":
                certificateName = "Final IT Certificate";
                break;
            case "loanagreement":
                certificateName = "Agreement & Annexure";
                break;
            case "noc":
                certificateName = "NOC"
                break;
            // case "sanctionletter":
            //     certificateName = "Sanction Letter";
            //     break;
            case "welcomeletter":
                certificateName = "Welcome Letter";
                break;
            default:
                certificateName = "";
        }
        let newObject = {
            customerName: custormerName,
            certificateName: certificateName,
            emailFlag: emailflag,
            loanAccNumber: accountNumber,
            email: email
        }
        setEmailPopUpContentObject({ ...emailPopUpContentObject, ...newObject })
        setemailPopup(true)



        const callSrApi = () => {


            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })

            if (jsHelper.isDef(currentUserData.hasOwnProperty("WEBTOP_NO"))) {
                var queryReqObject = {
                    "header": {
                        "authToken": data?.authToken,
                        "serviceRequestType": dataObj.sebServiceRequestType,
                        "productFamily": dataObj.productFamily,
                        "systemId": 1,
                    },
                    "body": {
                        "accountNumber": accountNumber,
                        "webtopId": currentUserData.WEBTOP_NO,
                        "description": dataObj.decscription
                    }
                }
            } else {
                var queryReqObject = {
                    "header": {
                        "authToken": data.authToken,
                        "serviceRequestType": dataObj.sebServiceRequestType,
                        "productFamily": dataObj.productFamily,
                        "systemId": 1,
                    },
                    "body": {
                        "accountNumber": accountNumber,
                        "description": dataObj.decscription
                    }
                }
            }
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA,
            });

            OverviewServiceObject.querySRCall(queryReqObject, dataObj.errorCode)
                .then((queryReqObject) => {

                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA,
                    });
                }).catch((error) => {
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA,
                    });
                    setFailurePopUpContent({
                        apiName: "holding-statement/v1",
                        message:
                            "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        errorCode: error,
                    });
                    setFailurePopUpModal(true);

                })
        }
        if (emailflag) {
            //setemailPopup(`Dear ${custormerName} We have sent ${certificateName} for your Loan Account Number ${loanAccNumber} on your registered email address ${email} too. If you wish to change your email address, please  href='javascript:void(0)' id='emailSr-click'><span >click here</span></a>`);
            if(portalType === "collection"){
                setemailPopup(
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `Dear <span class="email-account-number capitalizedAphabets">${custormerName}</span>,<br></br> We have sent <span class="email-account-number">${certificateName}</span> for your Loan Account Number <span class="email-account-number">${accountNumber}</span> on your registered email address <span class="email-account-number">${email}</span> too. `,
                        }}
                    />
                );
                callSrApi();
            }else{

                setemailPopup(
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `Dear <span class="email-account-number capitalizedAphabets">${custormerName}</span>,<br></br> We have sent <span class="email-account-number">${certificateName}</span> for your Loan Account Number <span class="email-account-number">${accountNumber}</span> on your registered email address <span class="email-account-number">${email}</span> too. If you wish to change your email address, please <a className="email-sr-link" href='javascript:void(0)' id='emailSr-click'><span >click here</span></a>`,
                        }}
                    />
                );
                callSrApi();
            }
        }
        else {
            if(portalType === "collection"){
                setemailPopup(<div dangerouslySetInnerHTML={{ __html: `Dear <span class="email-account-number capitalizedAphabets">${custormerName}</span>,<br></br> We found that your email address is not registered with us.` }} />)
                callSrApi();
            }
            else{
                // setemailPopup(`Dear ${custormerName},<br><br> We found that your email address is not registered with us. Please ${<a href='javascript:void(0)' id='emailSr-click'><span >click here</span></a>} to register your email address"`);           
                setemailPopup(<div dangerouslySetInnerHTML={{ __html: `Dear <span class="email-account-number capitalizedAphabets">${custormerName}</span>,<br></br> We found that your email address is not registered with us. Please <a className="email-sr-link" href='javascript:void(0)' id='emailSr-click'><span>click here</span></a> to register your email address` }} />)
                callSrApi();
            }
        }



    }

    const foreclosureLetter = () => {
        var accountNumber = data?.currentActiveData.accountNumber
        var currentUserData = data?.parsedUserData[accountNumber]
        var disbursalDate = currentUserData.DISBURSAL_DATE;
        let productName = commonFunctionObject.getProductName(currentUserData);
        var twlcheck = checkForeclosureTWL(disbursalDate, '08/01/2019', productName);
        if (twlcheck) {
            setforeclosureLetterPopup(true);
        } else {
            setFailurePopUpContent({
                apiName: "",
                message: "Please contact customer care on 1860 267 6060 (Charges Apply) for your Foreclosure Letter",
                errorCode: ""
            })
            setFailurePopUpModal(true)
        }
    }

    const checkForeclosureTWL = (disbursalDate, foreclosureDate, productName) => {
        if (productName.toLowerCase() === 'two wheeler' || productName.toLowerCase() === "consumer durable") {
            var nextDisbDate = new Date(commonFunctionObject.replaceHyphenDate(convertDisbursalDate(disbursalDate)));
            var currentDate = new Date(commonFunctionObject.replaceHyphenDate(foreclosureDate));
            var utc1 = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            var utc2 = Date.UTC(nextDisbDate.getFullYear(), nextDisbDate.getMonth(), nextDisbDate.getDate());
            if (utc2 >= utc1) {
                return true;
            } else {
                return false
            }
        } else {
            return true;
        }
    }
    const convertDisbursalDate = (date) => {
        var splitDate = date.split('.');
        return splitDate[1] + '/' + splitDate[2] + '/' + splitDate[0];
    }
    const downloadForeClosure = () => {
        var accountNumber = data?.currentActiveData.accountNumber
        var currentUserData = data?.parsedUserData[accountNumber]
        var email = currentUserData.EMAIL;
        var firstName = data?.getUserDataV3.body.FIRST_NAME;
        var lastName = data?.getUserDataV3.body.LAST_NAME;

        var productFam = commonFunctionObject.getProductFamily(currentUserData);
        var gcid = currentUserData.GCID;
        let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber];
        if (productName.toLowerCase() === "ccod") {
            var company = currentContract.cdiContract.COMPANY;
            var companyName = "";
            (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
        }
        else {
            var companyName = productFam;
        }
        var reqObj = {
            "header": {
                "authToken": data?.authToken,
                "emailSent": true,
                productFamily: "CFAB"
            },
            "body": {
                "loanAccountNumber": accountNumber,
                "closureDate": convertDDMMYYYY(new Date()),
                "agreementDate": convertDDMMYYYY(new Date()),
                "accountNumber": accountNumber
            }
        }
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        OverviewServiceObject.getFcNonRetentionLetterService(reqObj).then((getFcNonRetentionLetterResponse) => {
            var dataObj = {
                "documentName": "foreclosureletter",
                "productFamily": "cfab",
                "decscription": "Generate Foreclosure Letter for " + reqObj.body.loanAccountNumber + " loan account",
                "sebServiceRequestType": "foreclosureLetter",
                "errorCode": "254"
            }
            // if(portalType != 'collection'){
                showTriggerEmailPopUp(dataObj, accountNumber, getFcNonRetentionLetterResponse.body.isEmailAvailable, commonFunctionObject.getLastestFieldValFromField(email), firstName, lastName)
            // }
            let analytics_data = {
                user: {
                    loginMethod: "",
                    loginStatus: "",
                    gcid: gcid,
                    loginType: "",
                    loanaccountNo: accountNumber,
                    firstloginDate: ""
                },
                products: {
                    productCode: productName,
                },
                data: {
                    ctaText: "",
                    componentName: "Account Details",
                    bannerTitle: "",
                    menuTitle: "",
                    faqTitle: "",
                    searchTerm: "",
                    lobActivity: companyName + ":" + productName,
                    status: "SUCCESS",
                    eventName: "getFcNonRetentionLetterResponse"
                }
            }
            analytics.trackData("getFcNonRetentionLetterResponse", analytics_data);

            setforeclosureLetterPopup(false)
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })


        }).catch((error) => {
            let analytics_data = {
                user: {
                    loginMethod: "",
                    loginStatus: "",
                    gcid: gcid,
                    loginType: "",
                    loanaccountNo: accountNumber,
                    firstloginDate: ""
                },
                products: {
                    productCode: productName,
                },
                data: {
                    ctaText: "",
                    componentName: "Account Details",
                    bannerTitle: "",
                    menuTitle: "",
                    faqTitle: "",
                    searchTerm: "",
                    lobActivity: companyName + ":" + productName,
                    status: "FAILURE",
                    eventName: "getFcNonRetentionLetterResponse"
                }
            }
            analytics.trackData("getFcNonRetentionLetterResponse", analytics_data);

            setFailurePopUpContent({
                apiName: "fc-non-retention/v1",
                message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                errorCode: error
            })
            setFailurePopUpModal(true)
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
        })

    }

    const payNowForeClosure = () => {
        var accountNumber = data?.currentActiveData.accountNumber
        var contarctNumber = userDetail.CONTRACT_NUMBER;
        var reqObj = {
            "header": {
                "authToken": data?.authToken,
            },
            "body": {
                "contractNo": accountNumber,
                "sourceSystem": "finone"
            }
        }
        // dispatchData({
        //     name: "loaderFlag",
        //     payload: true,
        //     type: STOREDATA
        // })
        OverviewServiceObject.getFcPgsUrlService(reqObj).then((getFcPgsUrlResponse) => {
            // dispatchData({
            //     name: "loaderFlag",
            //     payload: false,
            //     type: STOREDATA
            // })
        }).catch((error) => {
            setFailurePopUpContent({
                apiName: "get-fc-pgs-url/v1",
                message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                errorCode: error
            })
            setFailurePopUpModal(true)
        })

    }

    const convertDDMMYYYY = (date) => {
        var month = ((date.getMonth() + 1) < 10) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
        var currentDate = ((date.getDate()) < 10) ? ('0' + (date.getDate())) : (date.getDate());
        return currentDate + '/' + month + '/' + date.getFullYear();
    }

    var accountNumber = data?.currentActiveData.accountNumber
    var currentUserData = data?.parsedUserData[accountNumber]
    const agreementLetter = () => {

        let disbursalDate = new Date(commonFunctionObject.convertDisbursalDate(currentUserData.DISBURSAL_DATE));
        let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        let email = currentUserData.EMAIL
        let firstName = currentUserData.FIRSTNAME
        let lastName = currentUserData.LASTNAME;
        var gcid = currentUserData.GCID;

        let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
        if (productName.toLowerCase() === "ccod") {

            var company = currentContract.cdiContract.COMPANY;
            var companyName = "";
            (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
        }
        else {
            var companyName = productFamily;
        }

        let requestObj = {
            "header": {
                "authToken": data.authToken,
                "emailSent": true,
            },
            "body": {
                "webtopId": currentUserData.WEBTOP_NO,
                "disbursalDate": convertDDMMYYYY(disbursalDate),
                "loanAccountNumber": currentUserData.CONTRACT_NUMBER
            }
        }
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        });

        OverviewServiceObject.AgreementAnnexureService(requestObj).then((response) => {

            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            if (response) {
                if (response.header.status == "SUCCESS") {
                    if (response.body.TCL.DMSRESPONSE) {
                        var DMSRESPONSE = response.body.TCL.DMSRESPONSE;
                        console.log(DMSRESPONSE);
                        var byteArr = commonFunctionObject.base64ToArrayBuffer(DMSRESPONSE);
                        var fileName = "Agreement Letter.pdf";
                        var mimeType = "application/pdf";
                        commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);

                        var data = {
                            documentName: "Loanagreement",
                            "productFamily": "cfab",
                            "decscription": "Generate Loan Agreement for " + requestObj.body.loanAccountNumber + " loan account.",
                            "sebServiceRequestType": "loanAgreement",
                            "webtopId": requestObj.body.webtopId,
                            "errorCode": "255"
                        };
                        // if(portalType !== 'collection'){
                            showTriggerEmailPopUp(data, accountNumber, response.body.isEmailAvailable, commonFunctionObject.getLastestFieldValFromField(email), firstName, lastName)
                        // }

                    } else if (response.body.TCL && response.body.TCL.hasOwnProperty("Invalid_Request_Response")) {
                        setAgreementPopUp(true)
                    }
                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "SUCCESS",
                            eventName: "agreementDownload"
                        }
                    }
                    analytics.trackData("agreementDownload", analytics_data);
                } else if (response.header.status == "FAILURE") {
                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "FAILURE",
                            eventName: "agreementDownload"
                        }
                    }
                    analytics.trackData("agreementDownload", analytics_data);
                    let errorMsg = response.errorBody.message;
                    var searchText = "download on";
                    var searchString = errorMsg.search(searchText);
                    if (searchString > -1) {
                        setFailurePopUpContent({
                            "message": errorMsg,
                        })
                        setFailurePopUpModal(true)
                    }
                    else {
                        setFailurePopUpContent({
                            type: "agreement-letter",
                            message: "We are experiencing technical difficulty please try after sometime",
                            errorCode: "213",
                        });
                        setFailurePopUpModal(true);
                    }
                } else {
                    setFailurePopUpContent({
                        "type": "api-failure: agreement-letter",
                        "message": "Sorry something went wrong",
                        "code": "",
                        "errorId": ""
                    })
                    setFailurePopUpModal(true)

                }
            }
        }).catch((error) => {

            let analytics_data = {
                user: {
                    loginMethod: "",
                    loginStatus: "",
                    gcid: gcid,
                    loginType: "",
                    loanaccountNo: accountNumber,
                    firstloginDate: ""
                },
                products: {
                    productCode: productName,
                },
                data: {
                    ctaText: "",
                    componentName: "Account Details",
                    bannerTitle: "",
                    menuTitle: "",
                    faqTitle: "",
                    searchTerm: "",
                    lobActivity: companyName + ":" + productName,
                    status: "FAILURE",
                    eventName: "agreementDownload"
                }
            }
            analytics.trackData("agreementDownload", analytics_data);
            setFailurePopUpContent({
                type: "agreement-letter",
                message: "We are experiencing technical difficulty please try after sometime",
                code: "",
                errorId: error,
            });
            setFailurePopUpModal(true);
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA,
            });
        })
    }


    const sendSanctionLetter = () => {
        var sourceSystem = userDetail.SOURCE_SYSTEM;
        var accountNumber = userDetail.CONTRACT_NUMBER;
        var webTopNo = userDetail.WEBTOP_NO;
        var gcid = userDetail.GCID;
        var email = userDetail.EMAIL;
        var firstName = userDetail.FIRSTNAME;
        var lastName = userDetail.LASTNAME;

        let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
        if (productName.toLowerCase() === "ccod") {

            var company = currentContract.cdiContract.COMPANY;
            var companyName = "";
            (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
        }
        else {
            var companyName = productFamily;
        }

        if (sourceSystem === "FINONE") {
            reqObj = {
                header: {
                    authToken: data.authToken,
                    productFamily: productFamily,
                    emailSent: true,
                },
                body: {
                    loanAccountNumber: accountNumber,
                    webtopId: webTopNo,
                    documentUploadType: "SANCTION",
                },
            };
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })

            OverviewServiceObject.extractDocService(
                reqObj,
                productFamily,
                accountNumber,
                webTopNo,
                gcid,
                email,
                firstName,
                lastName
            )
                .then((extractDocResponse) => {
                    if (extractDocResponse === "callService") {
                        //service request
                        if (productFamily !== "" && productFamily === "CFAB") {
                            var reqObj = {
                                header: {
                                    authToken: data.authToken,
                                    serviceRequestType: "sanctionLetterRequest",
                                    productFamily: productFamily,
                                    systemId: 1
                                    // "productCode": staticProductCode
                                },
                                body: {
                                    gcid: gcid,
                                    accountNumber: accountNumber,
                                    webtopId: webTopNo,
                                    description:
                                        "Generate sanction letter for " +
                                        accountNumber +
                                        " loan account.",
                                },
                            };
                            var serviceDetails = {
                                name: "Sanction Letter",
                                response: "",
                                dataValue: "",
                                erroCode: {
                                    shaftService: "E-2009-B",
                                    otherService: "E-2009-C",
                                },
                                errorId: {
                                    shaftService: "192",
                                    otherService: "193",
                                },
                            };

                            callServiceRequest(reqObj, serviceDetails);
                        } else {
                            setwelcomeLetterTchfl(true);
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA,
                            });
                        }
                    } else {
                        //emailpopup

                        var pdf = extractDocResponse.body.dmsResponse.DMSRESPONSE;
                        var byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
                        var fileName = "Sanction Letter.pdf";
                        var mimeType = "application/pdf";
                        commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
                        var dataObj = {
                            documentName: "sanctionletter",
                            "productFamily": productFamily,
                            "decscription": "Generate Sanction letter for " + accountNumber + " loan account.",
                            "webtopId": webTopNo,
                            "sebServiceRequestType": "sanctionLetterQuery",
                            "errorCode": "252",
                        };

                        // if(portalType !== 'collection'){
                            setemailPopup(true);
                            showTriggerEmailPopUp(
                                dataObj,
                                accountNumber,
                                extractDocResponse.body.isEmailAvailable,
                                commonFunctionObject.getLastestFieldValFromField(email),
                                firstName,
                                lastName
                            );
                        // }
                        
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    }
                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "SUCCESS",
                            eventName: "sanctionletterDownload"
                        }
                    }
                    analytics.trackData("sanctionletterDownload", analytics_data);
                })
                .catch((error) => {
                    setFailurePopUpContent({
                        apiName: "extract-doc/v1",
                        message:
                            "We are experiencing technical difficulty please try after sometime",
                        errorCode: error,
                    });
                    setFailurePopUpModal(true);
                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "FAILURE",
                            eventName: "sanctionletterDownload"
                        }
                    }
                    analytics.trackData("sanctionletterDownload", analytics_data);
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                    console.log("error");
                });
        } else if (sourceSystem === "BANCS") {
            var reqObj = {
                header: {
                    authToken: data.authToken,
                    requestType: "welcome-homeloan",
                    productFamily: productFamily,
                },
                body: {
                    Report: "5",
                    AcctNum: base64.encode(accountNumber),
                },
            };
            OverviewServiceObject.getBoGetUrlService(reqObj)
                .then((getBoGetUrlResponse) => {
                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "SUCCESS",
                            eventName: "sanctionletterDownload"
                        }
                    }
                    analytics.trackData("sanctionletterDownload", analytics_data);
                })
                .catch((error) => {
                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "FAILURE",
                            eventName: "sanctionletterDownload"
                        }
                    }
                    analytics.trackData("sanctionletterDownload", analytics_data);
                    setFailurePopUpContent({
                        apiName: "bo-get-url/v1",
                        message:
                            "We are experiencing technical difficulty please try after sometime",
                        errorCode: error,
                    });
                    console.log("error");
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                    setFailurePopUpModal(true);
                });
        } else {
        }
    };

    const callServiceRequest = (reqObj, serviceDetails) => {
        OverviewServiceObject.serviceRequestService(reqObj, serviceDetails)
            .then((serviceDetails) => {
                var response = serviceDetails.response;
                if (response.header.status.toUpperCase() == "SUCCESS") {
                    if (response.body.success.toLowerCase() == "true") {
                        if (serviceDetails.name === "Welcome Letter") {
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA,
                            });
                            setPopUpContent(
                                " Your request for Welcome Letter has been registered. It will be available for download within 4 working days."
                            );
                            setLetterPopup(true);
                        } else if (serviceDetails.name.toLowerCase() === "sanction letter") {
                            setPopUpContent(
                                " Your request for Sanction Letter has been registered, request number is " + response?.body?.Id
                            );
                            setLetterPopup(true);
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA,
                            });
                        } else {
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA,
                            });
                            setFailurePopUpContent({
                                apiName: "extract-doc/v1",
                                message:
                                    "We are experiencing technical difficulty please try after sometime",
                                errorCode: "",
                            });
                            setFailurePopUpModal(true);
                        }
                    } else {
                        var checkErrorMessage = response.body.errors[0];
                        var errorServiceRequest;
                        var searchElement = checkErrorMessage.search(":");
                        var lastRequestType = checkErrorMessage.substring(
                            searchElement + 1,
                            searchElement + 9
                        );
                        if (checkErrorMessage.search("QRC Already Exists.") > -1) {
                            var duplicateServiceRequestArr = [
                                "Welcome Letter",
                                "Foreclosure Letter",
                                "Agreement & Annexure",
                                "Loan Statement",
                                "Amortization Schedule",
                                "Sanction Letter"
                            ];

                            if (duplicateServiceRequestArr.includes(serviceDetails.name)) {
                                // setFailurePopUpContent({
                                //     apiName: "",
                                //     message:
                                //         "Unable to process your request for " +
                                //         serviceDetails.name +
                                //         " as your earlier request No: " +
                                //         lastRequestType +
                                //         ", is currently being processed.",
                                //     errorCode: "",
                                // });
                                // setFailurePopUpModal(true);
                                setRequestRaisedModal(true);
                                setContent(`Unable to process your request for ${serviceDetails.name} as your earlier request No:${lastRequestType} is currently being processed.`)
                            } else {
                                setserviceRequestPopup(
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `For <span data-id="auto-pop">${serviceDetails.name}</span>, Please Visit Nearest Branch`,
                                        }}
                                    />
                                );
                            }
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA,
                            });
                        } else {
                            setFailurePopUpContent({
                                apiName: "service-request/v1" + serviceDetails.name,
                                message:
                                    "We are currently unable to process your request for the " +
                                    serviceDetails.name +
                                    " Please try after sometime",
                                errorCode: serviceDetails.errorId.otherService,
                            });
                            setFailurePopUpModal(true);
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA,
                            });
                        }
                    }
                } else {
                    setFailurePopUpContent({
                        apiName: "service-request/v1" + serviceDetails.name,
                        message:
                            "We are currently unable to process your request for the " +
                            serviceDetails.name +
                            " Please try after sometime",
                        errorCode: serviceDetails.errorId.shaftService,
                    });
                    setFailurePopUpModal(true);
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA,
                    });
                }
            })
            .catch((error) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA,
                });
                setFailurePopUpContent({
                    apiName: "service-request/v1",
                    message:
                        "We are experiencing technical difficulty please try after sometime",
                    errorCode: error,
                });
                setFailurePopUpModal(true);
            });
    };

    const sendWelcomeLetter = () => {


        var sourceSystem = userDetail.SOURCE_SYSTEM;
        var accountNumber = userDetail.CONTRACT_NUMBER;
        var webTopNo = userDetail.WEBTOP_NO;
        var gcid = userDetail.GCID;
        var email = userDetail.EMAIL;
        var firstName = userDetail.FIRSTNAME;
        var lastName = userDetail.LASTNAME;
        var currentUserData = data?.parsedUserData[accountNumber]
        var product_family = commonFunctionObject.getProductFamily(currentUserData);

        let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
        let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        if (productName.toLowerCase() === "ccod") {

            var company = currentContract.cdiContract.COMPANY;
            var companyName = "";
            (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
        }
        else {
            var companyName = productFamily;
        }

        if (sourceSystem === "FINONE") {
            var reqObj = {
                header: {
                    authToken: data?.authToken,
                    productFamily: product_family,
                    emailSent: true,
                },
                body: {
                    webtopId: webTopNo,
                    documentUploadType: "welcome",
                    loanAccountNumber: accountNumber,
                },
            };
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA,
            });
            OverviewServiceObject.extractDocService(
                reqObj,
                product_family,
                accountNumber,
                webTopNo,
                gcid,
                email,
                firstName,
                lastName
            )
                .then((extractDocResponse) => {

                    if (
                        typeof extractDocResponse !== "object" &&
                        extractDocResponse === "callService"
                    ) {
                        //service request
                        if (product_family !== "" && product_family === "CFAB") {
                            var reqObj = {
                                header: {
                                    authToken: data?.authToken,
                                    serviceRequestType: "welcomeLetter",
                                    productFamily: product_family,
                                    systemId: 1,
                                },
                                body: {
                                    gcid: gcid,
                                    accountNumber: accountNumber,
                                    webtopId: currentUserData.webTopNo,
                                    description:
                                        "Generate welcome letter for " +
                                        accountNumber +
                                        " loan account.",
                                },
                            };
                            var serviceDetails = {
                                name: "Welcome Letter",
                                response: "",
                                dataValue: "",
                                erroCode: {
                                    shaftService: "E-2009-B",
                                    otherService: "E-2009-C",
                                },
                                errorId: {
                                    shaftService: "192",
                                    otherService: "193",
                                },
                            };
                            callServiceRequest(reqObj, serviceDetails);
                        } else {
                            setwelcomeLetterTchfl(true);
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA,
                            });
                        }
                    } else {
                        //emailpopup

                        var pdf = extractDocResponse.body.dmsResponse.DMSRESPONSE;
                        var byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
                        var fileName = "Welcome Letter.pdf";
                        var mimeType = "application/pdf";
                        commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
                        var dataObj = {
                            documentName: "WelcomeLetter",
                            "productFamily": product_family,
                            "decscription": "Generate welcome letter for " + accountNumber + " loan account.",
                            "webtopId": currentUserData.webTopNo,
                            "sebServiceRequestType": "welcome",
                            "errorCode": "251",
                        };
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA,
                        });
                        
                        // if(portalType !== 'collection'){
                            setemailPopup(true);
                            showTriggerEmailPopUp(
                                dataObj,
                                accountNumber,
                                extractDocResponse.body.isEmailAvailable,
                                commonFunctionObject.getLastestFieldValFromField(email),
                                firstName,
                                lastName
                            );
                        // }
                    }
                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "SUCCESS",
                            eventName: "welcomeletterDownload"
                        }
                    }
                    analytics.trackData("welcomeletterDownload", analytics_data);
                })
                .catch((error) => {

                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "FAILURE",
                            eventName: "welcomeletterDownload"
                        }
                    }
                    analytics.trackData("welcomeletterDownload", analytics_data);
                    setFailurePopUpContent({
                        apiName: "extract-doc/v1",
                        message:
                            "We are experiencing technical difficulty please try after sometime",
                        errorCode: error,
                    });
                    setFailurePopUpModal(true);
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA,
                    });
                });
        } else if (sourceSystem === "BANCS") {
            var reqObj = {
                header: {
                    authToken: data?.authToken,
                    requestType: "welcome-homeloan",
                    productFamily: product_family,
                },
                body: {
                    Report: "5",
                    AcctNum: base64.encode(accountNumber),
                },
            };
            OverviewServiceObject.getBoGetUrlService(reqObj)
                .then((getBoGetUrlResponse) => {
                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "SUCCESS",
                            eventName: "welcomeletterDownload"
                        }
                    }
                    analytics.trackData("welcomeletterDownload", analytics_data);
                })
                .catch((error) => {
                    let analytics_data = {
                        user: {
                            loginMethod: "",
                            loginStatus: "",
                            gcid: gcid,
                            loginType: "",
                            loanaccountNo: accountNumber,
                            firstloginDate: ""
                        },
                        products: {
                            productCode: productName,
                        },
                        data: {
                            ctaText: "",
                            componentName: "Account Details",
                            bannerTitle: "",
                            menuTitle: "",
                            faqTitle: "",
                            searchTerm: "",
                            lobActivity: companyName + ":" + productName,
                            status: "FAILURE",
                            eventName: "welcomeletterDownload"
                        }
                    }
                    analytics.trackData("welcomeletterDownload", analytics_data);
                    setFailurePopUpContent({
                        apiName: "bo-get-url/v1",
                        message:
                            "We are experiencing technical difficulty please try after sometime",
                        errorCode: error,
                    });
                    setFailurePopUpModal(true);
                });
        } else {
        }
    };


    return (
        <>
            {
                emailPopup &&
                <EmailPopUp accountNumber={accountNumber} emailPopUpObject={emailPopUpContentObject} setemailPopup={setemailPopup} portalType={portalType}/>
            }
            {
                foreclosureLetterPopup && <Modal style={{
                    background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""}`,
                }}>
                    <RadiantBorder className="foreclosure-border" style={{
                        background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
                    }}>
                        <div className="foreclosure-container" >
                            <img className="foreclosure-close" src="assets/images/crossImg.svg" alt="cross image" onClick={() => setforeclosureLetterPopup(false)} />
                            <div className="foreclosure-content">
                                {/* <p style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}` }}>Please note, the foreclosure amount generated will be ass bro today's date (<span>{convertDDMMYYYY(new Date())}</span>). If you wish To foreclose the loan on a later date, please generate the foreclosure letter afresh as the amount would differ on a daily basis.</p> */}
                                <p style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}` }}>Please note, the foreclosure amount generated will be
                                    as per today’s date (<span>{convertDDMMYYYY(new Date())}</span>). If you wish to
                                    foreclose the
                                    loan on a later date,
                                    please generate the foreclosure letter afresh as the amount would differ on a daily
                                    basis</p>
                            </div>
                            <div className="foreclosure-button-container">
                                <div className="foreclosure-button">
                                    <Button onClick={() => downloadForeClosure()} >Download Foreclosure Statement</Button>
                                </div>
                                <div className="foreclosure-button">
                                    <Button onClick={() => payNowForeClosure()} >View and Foreclose Online</Button>
                                </div>
                            </div>
                        </div>
                    </RadiantBorder>
                </Modal>
            }
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {requestRaisedModal ? <CustomerServicePopUp
                onClick={() => setRequestRaisedModal(false)}
                visible={requestRaisedModal}
                setModalStat={setRequestRaisedModal}
                Content={content}
            /> : null}

            {agreementPopUp ? (
                <AgreementAndAnnexure
                    setAgreementPopUp={setAgreementPopUp}
                    emailTrigger={showTriggerEmailPopUp}
                    contractsData={currentUserData} />
            ) : (
                ""
            )}
            {welcomeLetterTchfl && (
                <Modal
                    style={{
                        background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""
                            }`,
                    }}
                >
                    <div
                        className="welcome-letter-emal-popup insurance-modal-popup"
                        style={{
                            background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
                        }}
                    >
                        <div
                            className="close-btn"
                            onClick={() => setwelcomeLetterTchfl(false)}
                        >
                            {/* <img src="assets/images/crossImg.svg" alt="close"  /> */}
                            <img src={"assets/images/crossImg.svg"} alt="close" />
                        </div>
                        <div className="description">
                            <p
                                style={{
                                    color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                }}
                            >
                                For Welcome Letter for this account call us on Customer Care{" "}
                                <br></br>1860-267-6060
                                {/*  */}
                            </p>
                            {/* {`YOu are ${cust}`} */}
                        </div>
                        <div className="proceed">
                            <Button
                                onClick={() => setwelcomeLetterTchfl(false)}
                                className="email-popup-button"
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
            {serviceRequestPopup && (
                <Modal
                    style={{
                        background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""
                            }`,
                    }}
                >
                    <div
                        className="welcome-letter-emal-popup insurance-modal-popup"
                        style={{
                            background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
                        }}
                    >
                        <div
                            className="close-btn"
                            onClick={() => setserviceRequestPopup(false)}
                        >
                            {/* <img src="assets/images/crossImg.svg" alt="close"  /> */}
                            <img src={"assets/images/crossImg.svg"} alt="close" />
                        </div>
                        <div className="description">
                            <p
                                style={{
                                    color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                }}
                            >
                                {serviceRequestPopup}
                                {/*  */}
                            </p>
                            {/* {`YOu are ${cust}`} */}
                        </div>
                        <div className="proceed">
                            <Button
                                onClick={() => setserviceRequestPopup(false)}
                                className="email-popup-button"
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
            {letterPopup && (
                <Modal
                    style={{
                        background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""
                            }`,
                    }}
                >
                    <div
                        className="welcome-letter-emal-popup insurance-modal-popup"
                        style={{
                            background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
                        }}
                    >
                        <div className="close-btn" onClick={() => setLetterPopup(false)}>
                            {/* <img src="assets/images/crossImg.svg" alt="close"  /> */}
                            <img src={"assets/images/crossImg.svg"} alt="close" />
                        </div>
                        <div className="description">
                            <p
                                style={{
                                    color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                }}
                            >
                                {popUpContent}
                                {/*  */}
                            </p>
                            {/* {`YOu are ${cust}`} */}
                        </div>
                        <div className="proceed">
                            <Button
                                onClick={() => setLetterPopup(false)}
                                className="email-popup-button"
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}

            <div className='account-details-document'>
                <ul>

                    {
                        documentObj.map((value, index) => {
                            switch (value.documentName) {
                                case "Loan statement":
                                    return (
                                        <li key={index}>
                                            <div className='document-label downloadStatement'>
                                                <h2>Download Statement</h2>
                                            </div>
                                            <div className='document-content'>
                                                <div className='document-content-section'>
                                                    <Button className="document-button btn-df" onClick={() => lastFinancialYearSoa()}>
                                                        <img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                                        <p>Last Financial Year</p>
                                                    </ Button>
                                                </div>
                                                <h2>Or Select Date Range</h2>
                                                <form ref={soaForm} onSubmit={(e) => downloadSoa(e)}>
                                                    <div className='input-group'>
                                                        <div className='inputDiv'>
                                                            <RadiantBorder style={{ borderRadius: '1rem' }}>
                                                                {/* <div className='dateInputFlexDiv soa-input' >
                                                                    <div className='download-statement-input'>
                                                                        <DatePicker className='dateInput' placeholderText='Start Date' selected={startDate} onChange={(date) => {
                                                                            setStartDate(date);
                                                                            handleStartDate(date)
                                                                        }} />
                                                                    </div>
                                                                    <div className='dateImgDiv'>
                                                                        <img src='assets/images/datepicker-icon.svg' style={{ height: 15, width: 15, }} />
                                                                    </div>
                                                                </div> */}
                                                                <ModernDatepicker placeholder='Start Date' onChange={(date) => {
                                                                    setStartDate((date));
                                                                    let formatedDate = new Date(commonFunctionObject.replaceHyphenDate(date))
                                                                    handleStartDate(formatedDate)
                                                                }} value={startDate} />
                                                            </RadiantBorder>
                                                            <p className="date-input-error">{endError}</p>
                                                        </div>
                                                        <div className='inputDiv'>
                                                            <RadiantBorder style={{ borderRadius: '1rem' }}>
                                                                <ModernDatepicker placeholder='End Date' onChange={(date) => {
                                                                    setEndDate((date));
                                                                    let formatedDate = new Date(commonFunctionObject.replaceHyphenDate(date))
                                                                    handleEndDate(formatedDate)
                                                                }} value={endDate} />
                                                            </RadiantBorder>
                                                            <p className="date-input-error">{endError}</p>
                                                        </div>
                                                    </div>
                                                    <div className='button-group'>
                                                        <Button className="document-reset-button btn-df" onClick={() => resetForm()}>
                                                            <p>Reset</p>
                                                            <img src="assets/images/reloadIcon.svg" alt="arrow" />
                                                        </ Button>
                                                        <Button type="submit" className="document-submit-button btn-df" >
                                                            <p>Submit</p>
                                                            <img src="assets/images/arrow-right.svg" alt="arrow" />
                                                        </ Button>
                                                        {loaderFlag ? <Loader /> : null}
                                                    </div>

                                                </form>
                                            </div>
                                        </li>
                                    )
                                case "amortization":
                                    return (
                                        <li key={index}>
                                            <div className='document-label'>
                                                <h2>Amortization Schedule</h2>
                                            </div>
                                            <div className='document-content'>
                                                <div className='document-content-section'>
                                                    <Button className="document-button btn-df" onClick={() => amortLastFinancialYear()}>
                                                        <img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                                        <p>Download</p>
                                                    </ Button>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                case "final it certificate":
                                    return (
                                        <li key={index}>
                                            <div className='document-label'>
                                                <h2>Final IT Certificate</h2>
                                            </div>
                                            <div className='document-content final-it-certificate'>
                                                {/* <DropDown /> */}
                                                <div className='document-content-section'>
                                                    {finalItErrorFlag ?
                                                        <p className='finalItError'>Final Interest Certificate is not applicable for this loan account as loan is disbursed in the current financial year.</p> :
                                                        <>
                                                            <CustomDropDown
                                                                className="certificate-drop-down"
                                                                optionArray={yearsArr}
                                                                optionState={yearState}
                                                                setOptionState={setYearState}
                                                                selectedOption={selectedYear}
                                                                setSelectedOption={setSelectedYear}
                                                                labelFlag={false}
                                                                labelText=""
                                                            // labelText=""
                                                            />
                                                            <Button className="document-button btn-df" disable={selectedYear === "Select Financial Year" ? true : false} onClick={() => finalItLastFinancialyear()}>
                                                                <img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                                                <p>Download</p>
                                                            </ Button>
                                                        </>
                                                    }


                                                </div>
                                            </div>
                                        </li>
                                    )
                                case "provisional it certificate":
                                    if (productName.toLowerCase() === "home loan" || productName.toLowerCase() === "insurance loan" || productName.toLowerCase() === "loan against property" || productFamily.toLowerCase() === "tchfl") {
                                        return (
                                            <li key={index}>
                                                <div className='document-label'>
                                                    <h2>Provisional IT Certificate</h2>
                                                </div>
                                                <div className='document-content'>
                                                    <div className='document-content-section'>
                                                        <Button className="document-button btn-df" onClick={() => downloadProvisionalCertificate()}>
                                                            <img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                                            <p>Provisional IT Certificate</p>
                                                        </ Button>
                                                    </div>

                                                </div>
                                            </li>
                                        )
                                    }
                                    break
                                case "Foreclosure later":
                                    let foreclousreFlag = AccountDetailsServicObject.checkForeclosure(userDetail, productName)
                                    if (foreclousreFlag && loanStatus) {
                                        return (
                                            <li key={index}>
                                                <div className='document-label'>
                                                    <h2>Foreclosure Letter</h2>
                                                </div>
                                                <div className='document-content'>
                                                    <div className='document-content-section'>
                                                        <Button className="document-button btn-df" onClick={() => foreclosureLetter()}>
                                                            <img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                                            <p>Download</p>
                                                        </ Button>
                                                    </div>

                                                </div>
                                            </li>
                                        )
                                    }
                                    break
                                case "noc":
                                    if (!loanStatus && value.products.includes(productName.toLowerCase())) {
                                        return (<li key={index}>
                                            <div className='document-label'>
                                                <h2>NOC</h2>
                                            </div>
                                            <div className='document-content'>
                                                <div className='document-content-section'>
                                                    <Button onClick={() => { setNocOpenClosed(true, "Account Details") }} className="document-button btn-df">
                                                        <img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                                        <p>Download</p>
                                                    </ Button>
                                                </div>

                                            </div>
                                        </li>
                                        )
                                    }
                                    break
                                case "agreemnet and annexure":

                                    let checkAgreementAnnexureTWL = commonFunctionObject.checkAgreementAnnexureTWL(data?.parsedUserData[activeContract].DISBURSAL_DATE)
                                    if (checkAgreementAnnexureTWL && value.productfamily.includes(productFamily.toLowerCase())) {
                                        return (
                                            <li key={index}>
                                                <div className='document-label'>
                                                    <h2>Agreement & Annexure</h2>
                                                </div>
                                                <div className='document-content'>
                                                    <div className='document-content-section'>
                                                        <Button className="document-button btn-df" onClick={agreementLetter}>
                                                            <img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                                            <p>Download</p>
                                                        </ Button>
                                                    </div>

                                                </div>
                                            </li>
                                        )
                                    }
                                    break
                                case "sanction letter":
                                    if (value.productfamily.includes(productFamily.toLowerCase())) {
                                        return (
                                            <li key={index}>
                                                <div className='document-label'>
                                                    <h2>Sanction Letter</h2>
                                                </div>
                                                <div className='document-content'>
                                                    <div className='document-content-section'>
                                                        <Button className="document-button btn-df" onClick={() => sendSanctionLetter()}>
                                                            <img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                                            <p>Download</p>
                                                        </ Button>
                                                    </div>

                                                </div>
                                            </li>
                                        )
                                    }
                                    break;
                                case "welcome letter":
                                    return (
                                        <li key={index}>
                                            <div className='document-label'>
                                                <h2>Welcome Letter</h2>
                                            </div>
                                            <div className='document-content'>
                                                <div className='document-content-section'>
                                                    <Button className="document-button btn-df" onClick={() => sendWelcomeLetter()}>
                                                        <img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                                        <p>Download</p>
                                                    </ Button>
                                                </div>

                                            </div>
                                        </li>
                                    )

                            }
                        })
                    }
                </ul>

            </div>
            {
                nocOpenClosed ? <NocPopUp userDetails={userDetail} setemailPopup={setemailPopup} showTriggerEmailPopUp={showTriggerEmailPopUp} setFailurePopUpContent={setFailurePopUpContent} setFailurePopUpModal={setFailurePopUpModal} setNocOpenClosed={setNocOpenClosed} /> : null
            }
        </>


    )
}

Documents.propTypes = {
    activeContract : PropTypes.string
}


export default Documents;