import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getCreditScoreDetails = (data) => {

    let requestJson = {
        "header": {
            "grantType": "otp",
            "serviceType": "mobile"
        },
        "body": {
            "mobileNo": "9594779789"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/db/get-credit-score-details/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/db/get-credit-score-details/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getCreditScoreDetails;