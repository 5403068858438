import generateMobileOtp from "../../apis/generate-mobile-otp/generate-mobile-otp"
import getCreditScoreDetails from "../../apis/get-credit-score-details"
import pincodeMaster from "../../apis/pincode-master"
import singleActionApi from "../../apis/single-action-api/single-action-api"
import commonFunctionObject from "../../common-function"


const CreditScoreServiceObject = {

}


const pincodeMasterService = (requestObj) => {
    return new Promise((resolve, reject) => {
        pincodeMaster(requestObj).then((pincodeMasterResponse) => {
            if (pincodeMasterResponse.header.status.toUpperCase() === "SUCCESS") {
                resolve(pincodeMasterResponse.body.data)
            } else {
                reject()
            }
        }).catch(() => {
            reject("37")
        })
    })
}
CreditScoreServiceObject.pincodeMasterService = pincodeMasterService;


const getCreditScoreDetailsService = (requestObj) => {
    return new Promise((resolve, reject) => {
        getCreditScoreDetails(requestObj).then(response => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                resolve(response.body)
            } else {
                reject("")
            }
        }).catch((error) => {
            reject("")
        })

    })
}

CreditScoreServiceObject.getCreditScoreDetailsService = getCreditScoreDetailsService;


function prePopulateInputFields(data) {
    var userData = data?.getUserDataV3?.body
    var parsedContractUserData = data?.parsedUserData[data?.currentActiveData.accountNumber];
    var address, pincode, city, regMob, regEmail, state;
    let newObject = {}
    if (data?.currentActiveData.system.toLowerCase() === "retail") {
        if (userData?.mobiles) {
            regMob = commonFunctionObject.getFirstValue(userData.mobiles);
        } else {
            regMob = "";
        }

        if (userData?.emails) {
            regEmail = commonFunctionObject.getFirstValue(userData.emails);
        } else {
            regEmail = "";
        }

        userData.contracts.forEach(function (contract) {
            if (contract.USER_CREATED_IN_OPEN_DS === "Y") {
                address = contract.ADDRESS_LINE1 + ", " + contract.ADDRESS_LINE2;
                if (contract.ADDRESS_LINE3) {
                    address += ", " + contract.ADDRESS_LINE3;
                } else {
                    address += " " + contract.ADDRESS_LINE3;
                }
                city = contract.CITY;
                state = contract.STATE;
                pincode = contract.PINCODE;
            }
        });

        var dateOfBirth = String(commonFunctionObject.getFirstValue(userData.dobs));
        newObject = {
            firstName: String(userData.FIRST_NAME),
            lastName: String(userData.LAST_NAME),
            dob: dateOfBirth.split(" ")[0],
            panNumber: String(commonFunctionObject.getFirstValue(userData.pans).toUpperCase()),
            email: String(regEmail),
            mobile: String(commonFunctionObject.getFirstValue(userData.mobiles)),
            address: String(address),
            aadharcardnumber: ""
        }
    } else if (data?.currentActiveData.system.toLowerCase() === "las") {

        newObject = {
            firstName: String(parsedContractUserData.FIRST_NAME),
            lastName: String(parsedContractUserData.LAST_NAME),
            dob: "",
            panNumber: String(parsedContractUserData.PAN_NO),
            email: String(parsedContractUserData.EMAIL_ID),
            mobile: String(parsedContractUserData.MOBILE_NO),
            address: "",
            aadharcardnumber: ""
        }
    } else if (data?.currentActiveData.system.toLowerCase() === "ccod") {
        var creditScoreForm = creditScoreParser(data);
        newObject = {
            firstName: String(creditScoreForm.firstName),
            lastName: String(creditScoreForm.lastName),
            dob: "",
            panNumber: String(creditScoreForm.panNumber),
            email: String(creditScoreForm.emailId),
            mobile: String(commonFunctionObject.getFirstValue(creditScoreForm.mobileNumber)),
            address: String(creditScoreForm.address),
            aadharcardnumber: ""
        }
    }
    return newObject
}

CreditScoreServiceObject.prePopulateInputFields = prePopulateInputFields



function creditScoreParser(data) {
    var ccodSapContractData = data?.parsedUserData[data?.currentActiveData.accountNumber].sapContract;
    var ccodCdiContractAddress = data?.parsedUserData[data?.currentActiveData.accountNumber].cdiContract;
    var name = ccodSapContractData.CUSTOMER_FIRST_NM.split(" ");
    var lastnameop = ""
    if (name.length === 3) {
        lastnameop = name[2];
    } else if (name.length === 2) {
        lastnameop = name[1];
    }
    var creditScoreObj = {
        firstName: ccodSapContractData.CUSTOMER_FIRST_NM.split(" ")[0],
        lastName: lastnameop,
        panNumber: ccodSapContractData.PAN,
        emailId: ccodSapContractData.EMAIL,
        mobileNumber: ccodSapContractData.MOBILE,
        address: ccodAddress(ccodCdiContractAddress),
        city: ccodSapContractData.CITY,
        pincode: ccodSapContractData.ZIP,
    };
    return creditScoreObj;
}


function ccodAddress(ccodCdiContractAddress) {

    var address = "";
    if (ccodCdiContractAddress.ADDRESSLINE1) {
        address += String(ccodCdiContractAddress.ADDRESSLINE1)
    }
    if (ccodCdiContractAddress.ADDRESSLINE2) {
        address += String(ccodCdiContractAddress.ADDRESSLINE2)
    }
    if (ccodCdiContractAddress.ADDRESSLINE3) {
        address += String(ccodCdiContractAddress.ADDRESSLINE3)
    }
    return address ? address : " ";
}


const generateMobileOtpService = (generateMobileOtpRequest) => {
    return new Promise((resolve, reject) => {
        generateMobileOtp(generateMobileOtpRequest).then((generateMobileOtpResponse) => {
            if (generateMobileOtpResponse.header.status.toUpperCase() == "SUCCESS") {
                if (generateMobileOtpResponse.body.retStatus == "SUCCESS") {
                    resolve(generateMobileOtpResponse.body.otpRefNo)
                } else {
                    reject("L-30")
                }
            } else if (generateMobileOtpResponse.header.status.toUpperCase() === "FAILURE") {
                if (generateMobileOtpResponse.body.message.toLowerCase().includes('exceeded')) {
                    reject("number of attempts exceeded");
                }
            }
            else {
                reject("L-29")
            }
        }).catch((error) => {
            reject("L-28")
        })
    })
}

CreditScoreServiceObject.generateMobileOtpService = generateMobileOtpService


const getAddressLines = (maxSplit, address, sepArr, start, end) => {
    maxSplit = maxSplit ? maxSplit : 1;
    sepArr = sepArr && Array.isArray(sepArr) && sepArr.length > 0 ? sepArr : [","];

    var addressArr = [];
    function getAddressLine(address, start, end) {
        if (!address) return "";
        start = start ? start : 0;
        end = end ? end : 40;

        var addr = address.substring(start, end);
        if (addr.length < address.length) {
            var sep = sepArr[0];
            var maxLen = 0;
            sepArr.forEach(function (innerSep) {
                var lastIndex = addr.lastIndexOf(innerSep);
                if (lastIndex > maxLen) {
                    maxLen = lastIndex;
                    sep = innerSep;
                }
            });
            addr = addr.substring(0, addr.lastIndexOf(sep));
        }

        return addr;
    }

    var cnt = start;
    for (var i = 0; i < maxSplit; i++) {
        var addr = getAddressLine(address, cnt, cnt + end);
        cnt += addr.length;
        addressArr.push(addr);
    }
    return addressArr;
}
CreditScoreServiceObject.getAddressLines = getAddressLines


const singleActionApiService = (singleActionRequest) => {
    return new Promise((resolve, reject) => {
        singleActionApi(singleActionRequest).then((singleActionResponse) => {
            if (singleActionResponse.header.status === 'SUCCESS') {
                if (!(singleActionResponse.body.errorString) && singleActionResponse.body.creditScore != '') {
                    resolve(singleActionResponse)
                } else {
                    reject(singleActionResponse.body.errorString)
                }
            } else {

            }
        }).catch((error) => [
            reject()
        ])
    })
}
CreditScoreServiceObject.singleActionApiService = singleActionApiService

const creditScoreInputValidationCheck = (fieldName, value) => {
    var mobregx = /^([0-9]){10}/;
    var emailregx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var regaadhar = /^[1-9][0-9]{11}$/;
    var regpan = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
    var newErrorObject = {}
    if (fieldName === "firstName") {
        if (value === "") {
            newErrorObject.firstName = "Please Enter First Name"
        } else {
            newErrorObject.firstName = ""
        }
    }
    else if (fieldName === "lastName") {
        if (value === "") {
            newErrorObject.lastName = "Please Enter Last Name"
        } else {
            newErrorObject.lastName = ""
        }

    }
    else if (fieldName === "dob") {
        if (value === "") {
            newErrorObject.dob = "Please Enter Date of Birth"
        } else {
            newErrorObject.dob = ""
        }
    }
    else if (fieldName === "email") {
        if (value === "") {
            newErrorObject.email = "Please Enter Email ID"
        } else if (!emailregx.test(value)) {
            newErrorObject.email = "Please Enter Valid Email ID"
        } else {
            newErrorObject.email = ""

        }
    }
    else if (fieldName === "panNumber") {
        if (value === "") {
            newErrorObject.panNumber = "Please Enter PAN Number"
        } else if (!regpan.test(value)) {
            newErrorObject.panNumber = "Please Enter Valid PAN Number"
        } else {
            newErrorObject.panNumber = ""
        }
    }
    else if (fieldName === "aadharcardnumber") {
        if (value === "") {
            newErrorObject.aadharcardnumber = "Please Enter Aadhar Number"
        } else if (!regaadhar.test(value)) {
            newErrorObject.aadharcardnumber = "Please Enter Valid Aadhar Number"
        } else {
            newErrorObject.aadharcardnumber = ""
        }
    }
    else if (fieldName === "mobile") {
        if (value === "") {
            newErrorObject.mobile = "Please Enter Mobile Number"
        } else if (!mobregx.test(value)) {
            newErrorObject.mobile = "Please Enter Valid Mobile Number"
        } else {
            newErrorObject.mobile = ""
        }
    }
    else if (fieldName === "address") {
        if (value === "") {
            newErrorObject.address = "Please Enter Address"
        } else {
            newErrorObject.address = ""
        }

    }
    else if (fieldName === "state") {
        if (value === "") {
            newErrorObject.state = "Please Enter State"
        } else {
            newErrorObject.state = ""
        }

    }
    else if (fieldName === "city") {
        if (value === "") {
            newErrorObject.city = "Please Enter City"
        } else {
            newErrorObject.city = ""
        }

    }
    else if (fieldName === "pincode") {
        if (value === "") {
            newErrorObject.pincode = "Please Enter Pincode"
        } else {
            newErrorObject.pincode = ""
        }

    }

    return newErrorObject

}
CreditScoreServiceObject.creditScoreInputValidationCheck = creditScoreInputValidationCheck

const creditScoreFormValidation = (creditScoreFormObject) => {
    var mobregx = /^([0-9]){10}/;
    var emailregx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var regaadhar = /^[1-9][0-9]{11}$/;
    var regpan = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
    var count = 0
    var newErrorObject = {}
    if (creditScoreFormObject.firstName === "") {
        newErrorObject.firstName = "Please Enter First Name"
    }
    if (creditScoreFormObject.lastName === "") {
        newErrorObject.lastName = "Please Enter Last Name"
    }
    if (creditScoreFormObject.dob === "") {
        newErrorObject.dob = "Please Enter Date of Birth"
    }
    if (creditScoreFormObject.email === "") {
        newErrorObject.email = "Please Enter Email ID"
    } else if (!emailregx.test(creditScoreFormObject.email)) {
        newErrorObject.email = "Please Enter Valid Email ID"
    }
    if (creditScoreFormObject.panNumber === "") {
        if (creditScoreFormObject.aadharcardnumber === "") {
            newErrorObject.panNumber = "Please Enter either Aadhar or PAN Number"
        } else if (!regaadhar.test(creditScoreFormObject.aadharcardnumber)) {
            newErrorObject.aadharcardnumber = "Please Enter Valid Aadhar Number"
        }
    } else if (!regpan.test(creditScoreFormObject.panNumber)) {
        newErrorObject.panNumber = "Please Enter Valid PAN Number"
    }
    if (creditScoreFormObject.mobile === "") {
        newErrorObject.mobile = "Please Enter Mobile Number"
    } else if (!mobregx.test(creditScoreFormObject.mobile)) {
        newErrorObject.mobile = "Please Enter Valid Mobile Number"
    }
    if (creditScoreFormObject.address === "") {
        newErrorObject.address = "Please Enter Address"
    }
    if (creditScoreFormObject.state === "") {
        newErrorObject.state = "Please Enter State"
    }
    if (creditScoreFormObject.city === "") {
        newErrorObject.city = "Please Enter City"
    }
    if (creditScoreFormObject.pincode === "") {
        newErrorObject.pincode = "Please Enter Pincode"
    }

    var finalErrrorObject = creditScoreCheckBoxValidation(newErrorObject, creditScoreFormObject)

    return finalErrrorObject
}
CreditScoreServiceObject.creditScoreFormValidation = creditScoreFormValidation

const creditScoreCheckBoxValidation = (newErrorObject, creditScoreFormObject) => {
    var checkKeyArray = ["linkCheckbox", "detailsCheckbox", "appointCheckbox"]
    Object.keys(creditScoreFormObject).forEach((keyValue) => {
        if (checkKeyArray.includes(keyValue)) {
            if (keyValue === "linkCheckbox" && creditScoreFormObject[keyValue] === false || keyValue === "detailsCheckbox" && creditScoreFormObject[keyValue] === false || keyValue === "appointCheckbox" && creditScoreFormObject[keyValue] === false) {
                newErrorObject.errorCheckbox = "Please Check all the Terms & Conditions"
                //setFormErrorObject({ ...formErrorObject, errorCheckbox: "Please Check all the Terms & Conditions" })
                //count += 1
            }
        } else {
        }
    })

    return newErrorObject

}

export default CreditScoreServiceObject;