import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const holdingStatement = (data) => {

    let requestJson = {
        "header":
        {
            "authToken": ""
        },
        "body":
        {

            "loanAccountNo": "",
            "reportType": "pdf",
            "format": "2",
            "userName": "adminiaf"

        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/las-esb/holding-statement/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-esb/holding-statement/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default holdingStatement;