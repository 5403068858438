import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const downloadBre = (data) => {
    let requestJson = {
        header: {
            authToken: "",
        },
        body: {
            "breResponseList": "breResponseList"
        },
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/las-direct/download-bre/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-direct/download-bre/v1',).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default downloadBre;