import React, { useState } from 'react'
import RadiantBorder from '../../global/RadiantBorder'
import Accordion from '../Accordion/Accordion'
import PageWrapper from "../../container/PageWrapper"
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from '../../../../Shared/context/ThemeContext/store/PageNameContext'
import { useContext } from "react";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
//styles
import './Faqpage-style.scss'
import Searchbar from '../Searchbar';
import AnalyticsContextProvider from '../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider';
import DataMapping from '../../../../Shared/context/data-mapping';
import commonFunctionObject from '../../common-function';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';


const FaqPage = ({ portalType, setIsActive, isMobile, inProfile, searchValue = "" }) => {
  const location = useLocation()
  const profilePath = location.pathname.split("/")[1];
  const { analytics } = useContext(AnalyticsContextProvider);
  const [inputValue, setInputValue] = useState(searchValue);

  const handleMobileClick = () => {
    if (!isMobile) {
      return;
    } else {
      setIsActive({
        profile: false,
        bank: false,
        password: false,
        help: false,
        branch: false,
        terms: false,
        about: false,
      });
    }
  };
  const [accordionOpen, setaccordionOpen] = useState(null)
  const [newString, setNewString] = useState("");
  const [search, setSearch] = useState();
  const [data, dispatchData] = useContext(DataMapping);
  const { customerServicePageName, setCustomerServicePageName, pageName, setPageName } = useContext(pageNameContext)

  useEffect(() => {
    if (profilePath !== "profile") {
      setPageName("FAQs")
    }
    accordionInputChange(searchValue);
    commonFunctionObject.scrollToTop()


  }, [])

  // const userDetail = data?.parsedUserData[activeContract];


  const faqClick = (value, index) => {

    var accountNumber = data?.currentActiveData.accountNumber;
    var currentUserData = data?.parsedUserData[accountNumber];
    var gcid = currentUserData.GCID;
    let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: gcid,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "",
        componentName: "FAQ",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: value.accordionTitle,
        searchTerm: "",
        lobActivity: "",
        status: "",
        eventName: "faqClick"
      }
    }
    analytics.trackData("faqClick", analytics_data);
  }

  const accordionInputChange = (value) => {

    // const value = event.target.value;

    flushSync(() => {
      setInputValue(value);
      setNewString(value);
      setSearch(faqObject.filter(obj => obj.title.toLowerCase().includes(newString.toLowerCase())))

    })

  }
  const myAccordionArray = [
    {
      accordionTitle: "What are the list of services covered under Service request?",
    },
    {
      accordionTitle: "How can I Change my Email Id?",
    },
    {
      accordionTitle: "I have two loan contracts how to update the Email for both?",
    },
    {
      accordionTitle: "Can I update the Email Id of all the accounts at one go?",
    },
    {
      accordionTitle: "I did not get the OTP after clicking on Submit?",
    },
    {
      accordionTitle: "I did not receive an SMS confirming about updation of my Email Id?"
    },
    // {
    //   accordionTitle: "I did not receive an SMS confirming about updation of my Email Id?"
    // }
    {
      accordionTitle: "what to do if my Email Id is not being updated?"
    }, {
      accordionTitle: "How to change my mobile number?"
    }, {
      accordionTitle: "I have multiple loan contract, how to update mobile number for both?"
    }, {
      accordionTitle: "Can I update my mobile number for both loan contract number together?"
    }, {
      accordionTitle: "Why I haven’t received SMS OTP for changing mobile number?"
    }, {
      accordionTitle: "Why no SMS received after updation of my mobile number?"
    }, {
      accordionTitle: "What to do if my Mobile Number is not being updated?"
    }, {
      accordionTitle: "Can I change my address online?"
    }, {
      accordionTitle: "Will I get a confirmation from Tata Capital once my address is updated?"
    }, {
      accordionTitle: "Can I get my statement’s/certificate on my office address?"
    }, {
      accordionTitle: "Can I view my closed request?"
    },
    {
      accordionTitle: "How can I track my SR types?"
    }, {
      accordionTitle: "Why I am not able to view Closed Request? "
    }, {
      accordionTitle: "How to change the address?"
    }, {
      accordionTitle: "Do I need to submit hard copy for address proof to branch or courier it?"
    },
    {
      accordionTitle: "Where can I put any Request for Loan Statement?"
    },
    {
      accordionTitle: "Where can I put any Request for Amortization Schedule?"
    }, {
      accordionTitle: "Where to put any other request which is not visible in Service Request?"
    }, {
      accordionTitle: "Can I open a case id which is being closed?"
    }, {
      accordionTitle: "Where to quote the case Id?."
    }, {
      accordionTitle: "What is E-locker?"
    },
    {
      accordionTitle: "Which documents can be stored in E-locker?"
    }, {
      accordionTitle: "Once any document is uploaded can I delete it?"
    }, {
      accordionTitle: "I am not able to upload/download any document"
    }, {
      accordionTitle: "What formats are accepted?"
    }, {
      accordionTitle: "Can I download the file that I had uploaded?"
    }, {
      accordionTitle: "Can I upload multiple documents at one go?"
    }, {
      accordionTitle: "Can I change/update my KYC details? If yes, how?"
    }, {
      accordionTitle: "I updated my PAN details but same is not visible"
    }, {
      accordionTitle: "I updated my Date of Birth but same is not visible"
    }, {
      accordionTitle: "Can I foreclose my loan account?"
    },
    {
      accordionTitle: "Are there any foreclosure charges?"
    }, {
      accordionTitle: "How and when can I make Part Pre-Payment"
    }, {
      accordionTitle: "How can I raise Disbursement Drawdown Request?"
    },
    {
      accordionTitle: "When will the amount get credited in my account?"
    }, {
      accordionTitle: "What is the procedure to depledge existing pledged securities"
    }, {
      accordionTitle: "What is the procedure to pledge additional securities"
    }, {
      accordionTitle: "I made the payment via payment gateway, but the payment was unsuccessful and amount has been deducted"
    }, {
      accordionTitle: "I am unable to raise Disbursement drawdown request"
    },
    {
      accordionTitle: "I am unable to depledge securities"
    }, {
      accordionTitle: "Will I be charged to check my Credit Score?"
    },
    {
      accordionTitle: "What is a credit score?"
    },
    {
      accordionTitle: "Why don't I have a credit score?"
    },
    {
      accordionTitle: "How often do credit scores change?"
    },
    {
      accordionTitle: "Who calculates credit scores?"
    },
    {
      accordionTitle: "How do I unsubscribe from Tata Capital’s Credit Score?"
    },
    {
      accordionTitle: "What is BBPS?"
    },
    {
      accordionTitle: "What is Bill pay?"
    },
    {
      accordionTitle: "What can I do using Bill pay?"
    },
    {
      accordionTitle: "What payment options are available in Bill pay?"
    }
  ]
  const arrayOptions = [
    {
      options: [
        { value: "Change of Email ID / Mobile Number" },
        { value: "Change of Address / PAN " },
        { value: "Change of Date of Birth" },
        { value: "Know your EMI & Mandate related information" },
        { value: "Change Bank account for EMI Deduction" },
        { value: "Loan Statements / Amortization Schedule / Foreclosure Related" },
        { value: "Others" }
      ]
    },
    {
      options: [

        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You need to go to Customer Service section in navigation bar > Service Requirements > Click on Raise New > Contact Details Related > Update Email ID > Enter new mail ID & click on raise a request. “Your SR has been created successfully”.We will get back to you for the confirmation of the same and it will be reflected in the system upon confirmation.You will also receive an SMS/Email confirmation for the request."</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>It will be done sequentially, one after the other.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>No, It will be done sequentially, one after the other.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>As of now OTP based verification is not enabled for any of the Service request.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>There are times when SMS is not received due to network congestion or bad network. Kindly check your registered email id also as in some cases where SMS is not delivered, emails are being triggered. Kindly check your registered email id for the same.</p>'
          }} />
        }
      ]
    },
    // {
    //   options: [
    //     { value: "There are times when SMS is not received due to network congestion or bad network. Kindly check your registered email id also as in some cases where SMS is not delivered, emails are being triggered.Kindly check your registered email id for the same." }
    //   ]
    // },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Your email id will be updated once our customer care team contacts you and confirms about updation. Kindly keep the SR Id with you for future reference and can refer the same to customer care team.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>"You need to go to Customer Service section in navigation bar > Service Requirements > Click on Raise New > Contact Details Related > Update Mobile number > Enter new mobile number & click on raise a request. “Your SR has been created successfully”.We will get back to you for the confirmation of the same and it will be reflected in the system upon confirmation.You will also receive an SMS/Email confirmation for the request."</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>It will be done sequentially, one after the other.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>No, It will be done sequentially, one after the other.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>As of now OTP based verification is not enabled for any of the Service request.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>There are times when SMS is not received due to network congestion or bad network. Kindly check your registered email id also as in some cases where SMS is not delivered, emails are being triggered. Kindly check your registered email id for the same.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Kindly keep the SR Id with you for future reference and can refer the same to customer care team.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Yes. You can update communication address online.Incase you wish to update Permanent address please visit any of the nearest branch.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Yes, you will receive a confirmation SMS/Email once the same has been updated.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>No, all the statements/certificates are sent only registered address only. You can download the Statement from portal instantly.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You need to go to Customer Service section in navigation bar > Service Requirements > Show all requests. You can view all your closed SR raised with their details.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You need to go to Customer Service section in navigation bar > Service Requirements > Show all requests. You can view all SRs raised with their details.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Closed Request is visible only in case of any SR raised previously or historically.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You need to go to Customer Service section in navigation bar > Service Requirements > Click on Raise New > Contact Details Related > Update Communication address. You need to fill all the details and upload relevant documents and click on Raise a new Request.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>No need to submit hard copy for address or courier it. You just need to upload the document and click on Raise a request.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You need to go to Customer Service section in navigation bar > Service Requirements > Click on Raise New > Statements & Certificate Related. You can access your statements instantly.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You need to go to Customer Service section in navigation bar > Service Requirements > Click on Raise New > Statements & Certificate Related. You can access your statements instantly.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You need to go to Customer Service section in navigation bar > Service Requirements > Click on Raise New > Others. Once the request has been filled click on raise a request.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>No. If the problem persists again after the case is closed, you can quote the case id to our customer care team for proper resolution.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Once our customer care team contacts you SR Id can be shared with them for faster resolution.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>E-locker enables you to store your document electronically with Tata Capital’s E-locker service. You can store your documents in one central secured location and access them with just a few clicks.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You can store your personal as well as professional documents in E-locker, this will be accessible to you alone even Tata Capital employees will not have access to any of your uploaded files.</p>'
          }} />
        }

      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Deletion functionality will be shortly introduced.</p>'
          }} />
        }

      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Please check your file type and size of the file. File uploaded can be of max 1 MB per file and format accepted are jpg,jpeg, gif, png, bmp, doc, docx, xls, xlsx, pdf. If issue still persists please write to us at customercare@tatacapital.com along with error screenshot.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You can upload document in format jpg, jpeg, gif, png, bmp, doc, docx, xls, xlsx, pdf.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Yes You can download the files that you have uploaded on our E-locker.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Currently you can upload documents one at a time.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>"Yes you can update or even change your KYC details. KYC details include- Mobile no, Date of Birth, Address and PAN number.You need to go to Customer Service section in navigation bar > Service Requirements > Click on Raise New > Select your request type (Mobile, DOB, etc.)-> Upload valid proof-> Submit your request Please note: If you have multiple accounts your KYC details will be updated against the a/c no which is selected in drop down. To update your KYC details in your other account please raise a new request and select account no from drop down"</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<ol style=margin-top:10px><li>PAN update usually takes 3 business days. If you have raised the request within 3 days, kindly check back later.</li><li style=margin-top:7px>PAN details are updated for account number that it was raised against. To update PAN number in your other loan account follow below path-<br> Customer service-&gt; raise new request-&gt; select a/c no from the drop down-&gt; submit your PAN details</li></ol>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<ol style=margin-top:10px><li>Date of Birth update usually takes 3 business days. If you have raised the request within 3 days, kindly check back later.</li><li style=margin-top:7px>Date of Birth details are updated for account number that it was raised against. To update DOB in your other loan account follow below path-<br> Customer service-&gt; raise new request-&gt; select a/c no from the drop down-&gt; submit your Date of Birth with valid proof</li></ol>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px> Foreclosure of loan account is allowed after certain tenure and varies from product to product. You can now raise a request for Foreclosure of Personal, Business and Used Car Loan, follow below path to raise request for foreclosure. Once the request is raised same will be addressed within 3 business days.For foreclosure of other loans kindly contact our customer care <br> Foreclosure process- Customer service-&gt; raise new request-&gt;  Statement & Certificate related</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Yes we have foreclosure charges and same varies for each product.<br> Please click below for product wise detailed charges on foreclosure </p><ul><li>Personal Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/personal-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Home Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/home-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Business Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/business-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Loan Against Property <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/loan-against-property/fees-and-charges.html" target="_blank">click here</a></li><li>Consumer Durable Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/consumer-durable-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Used Car Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/vehicle-loan/used-car-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Two Wheeler Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/vehicle-loan/two-wheeler-loan/fees-and-charges.html" target="_blank">click here</a></li></ul>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px> Part Pre-payment is not allowed in minimum 1st 6 months, however it does vary from product to product.<br> Please click below for product wise detailed charges on Part Pre-Payment</p> <ul><li>Personal Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/personal-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Home Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/home-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Business Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/business-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Loan Against Property <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/loan-against-property/fees-and-charges.html" target="_blank">click here</a></li><li>Consumer Durable Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/consumer-durable-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Used Car Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/vehicle-loan/used-car-loan/fees-and-charges.html" target="_blank">click here</a></li><li>Two Wheeler Loan <a style=color:#337AB7;text-decoration:none; href="https://www.tatacapital.com/vehicle-loan/two-wheeler-loan/fees-and-charges.html" target="_blank">click here</a></li></ul>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Select your OD account for which you need disbursal. Select Online Disbursal from Quick Links (on Dashboard), enter the required amount, key in the OTP and submit your request.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>The amount will get credited after 48 working hours of successful online disbursal request creation.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>View Holdings --&gt; Click on De pledge button --&gt; Select securities to De pledge</p>'
          }} />
        }

      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>View Holdings --&gt; Click on Incremental Pledging</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Any amount deducted in case of a payment failure will be credited back to you withing 48 hours.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Please check if you have cleared your deferral, if any. For more assistance reach out to your Relationship Manager.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Please check for margin shortfall. If you have margin shortfall, you will not be able to depledge securities. Please pledge additional securities if you want to depledge some existing pledged securities.</p>'
          }} />
        }
      ]
    }, {
      options: [

        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Checking Credit Score accessed via Tata Capital digital channels is free.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>A credit score is a number that summarizes the historical credit information on a credit report. The number reflects the likelihood that you will become delinquent on a loan or a credit obligation in the future.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Credit scoring models cannot generate a score without enough credit information.If you have little or no credit history, you probably will not have a credit score available.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Your credit score changes as your credit report changes.Therefore, it can change often since new information is added to your credit report all the time.</p>'
          }} />
        }
      ]
    }, {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Credit scores may come from several sources. Lenders may request that a credit score be provided along with your credit report. Credit reporting agencies provide the service of applying the credit scores from a number of credit score developers.Lenders specify which credit score they want delivered with the credit report.Credit scores may also be calculated by mortgage reporting companies that compile your credit reports from each of the national credit reporting companies and then deliver the combined reports and scores to the lender. Lenders may also apply their own, proprietary scores after receiving your credit report.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>If you wish to unsubscribe then please email from your registered email ID -<a style=color:#337AB7;text-decoration:none; href="mailto:customercare@tatacapital.com">customercare@tatacapital.com</a></p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>BBPS or Bharat Bill Payment System is a platform where you can pay your utility bills. Now you can pay your bills via Tata Capital Website / App.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Bill pay is an extended service of Bharat Bill Payment System. Now you can pay your bills using Tata Capital Website / App.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>Utility bill payments like electricity, loan repayments, piped gas, water, booking a gas cylinder, DTH recharge, Fastag etc can be done using this platform.</p>'
          }} />
        }
      ]
    },
    {
      options: [
        {
          value: <div dangerouslySetInnerHTML={{
            __html: '<p style=margin-top:10px>You may pay using UPI.</p>'
          }} />
        }
      ]
    }
  ]

  let faqObject = [
    {
      title: "What are the list of services covered under Service request?",
      paragraph: "Currently we offer below list of services:",
      numberList: [
        "Change of Email ID / Mobile Number",
        "Change of Address / PAN ",
        "Change of Date of Birth",
        "Know your EMI & Mandate related information",
        "Change Bank account for EMI Deduction",
        "Loan Statements / Amortization Schedule / Foreclosure Related",
        "Others"

      ]
    },
    {
      title: "How can I Change my Email Id?",
      paragraph: "You need to go to Self Service section in navigation bar > Service Requirements > Click on Raise New > Contact Details Related > Update Email ID > Enter new mail ID & click on raise a request. “Your SR has been created successfully.”",
      paragraph1: "We will get back to you for the confirmation of the same and it will be reflected in the system upon confirmation.You will also receive an SMS/Email confirmation for the request."
    },
    {
      title: "I have two loan contracts how to update the Email for both?",
      paragraph: "It will be done sequentially, one after the other."
    },
    {
      title: "Can I update the Email Id of all the accounts at one go?",
      paragraph: "No, It will be done sequentially, one after the other."
    },
    {
      title: "I did not get the OTP after clicking on Submit?",
      paragraph: "As of now OTP based verification is not enabled for any of the Service request."
    },
    {
      title: "I did not receive an SMS confirming about updation of my Email Id? ",
      paragraph: "There are times when SMS is not received due to network congestion or bad network. Kindly check your registered email id also as in some cases where SMS is not delivered, emails are being triggered. Kindly check your registered email id for the same."
    },
    {
      title: "what to do if my Email Id is not being updated?",
      paragraph: "Your email id will be updated once our customer care team contacts you and confirms about updation. Kindly keep the SR Id with you for future reference and can refer the same to customer care team."
    },
    {
      title: "How to change my mobile number?",
      paragraph: "You need to go to Self Service section in navigation bar > Service Requirements > Click on Raise New > Contact Details Related > Update Mobile number > Enter new mobile number & click on raise a request. “Your SR has been created successfully”.",
      paragraph1: "We will get back to you for the confirmation of the same and it will be reflected in the system upon confirmation.You will also receive an SMS/Email confirmation for the request."
    }, {
      title: "I have multiple loan contract, how to update mobile number for both?",
      paragraph: "It will be done sequentially, one after the other."
    }, {
      title: "Can I update my mobile number for both loan contract number together?",
      paragraph: "No, It will be done sequentially, one after the other.",
    }, {
      title: "Why I haven’t received SMS OTP for changing mobile number?",
      paragraph: "As of now OTP based verification is not enabled for any of the Service request."
    }, {
      title: "Why no SMS received after updation of my mobile number?",
      paragraph: "There are times when SMS is not received due to network congestion or bad network. Kindly check your registered email id also as in some cases where SMS is not delivered, emails are being triggered. Kindly check your registered email id for the same."
    }, {
      title: "What to do if my Mobile Number is not being updated?",
      paragraph: "Kindly keep the SR Id with you for future reference and can refer the same to customer care team."
    }, {
      title: "Can I change my address online?",
      paragraph: "Yes. You can update communication address online.Incase you wish to update Permanent address please visit any of the nearest branch."
    }, {
      title: "Will I get a confirmation from Tata Capital once my address is updated?",
      paragraph: "Yes, you will receive a confirmation SMS/Email once the same has been updated."
    }, {
      title: "Can I get my statement’s/certificate on my office address?",
      paragraph: "No, all the statements/certificates are sent only registered address only. You can download the Statement from portal instantly."
    }, {
      title: "Can I view my closed request?",
      paragraph: "You need to go to Self Service section in navigation bar > Service Requirements > Show all requests. You can view all your closed SR raised with their details."
    }, {
      title: "How can I track my SR types?",
      paragraph: "You need to go to Self Service section in navigation bar > Service Requirements > Show all requests. You can view all SRs raised with their details."
    }, {
      title: "Why I am not able to view Closed Request?",
      paragraph: "Closed Request is visible only in case of any SR raised previously or historically."
    }, {
      title: "How to change the address?",
      paragraph: "You need to go to Self Service section in navigation bar > Service Requirements > Click on Raise New > Contact Details Related > Update Communication address. You need to fill all the details and upload relevant documents and click on Raise a new Request."
    }, {
      title: "Do I need to submit hard copy for address proof to branch or courier it?",
      paragraph: "No need to submit hard copy for address or courier it. You just need to upload the document and click on Raise a request."
    }, {
      title: "Where can I put any Request for Loan Statement?",
      paragraph: "You need to go to Self Service section in navigation bar > Service Requirements > Click on Raise New > Statements & Certificate Related. You can access your statements instantly."
    }, {
      title: "Where can I put any Request for Amortization Schedule?",
      paragraph: "You need to go to Self Service section in navigation bar > Service Requirements > Click on Raise New > Statements & Certificate Related. You can access your statements instantly."
    }, {
      title: "Where to put any other request which is not visible in Service Request?",
      paragraph: "You need to go to Self Service section in navigation bar > Service Requirements > Click on Raise New > Others. Once the request has been filled click on raise a request."
    }, {
      title: "Can I open a case id which is being closed?",
      paragraph: "No. If the problem persists again after the case is closed, you can quote the case id to our customer care team for proper resolution."
    }, {
      title: "Where to quote the case Id?",
      paragraph: "Once our customer care team contacts you SR Id can be shared with them for faster resolution."
    }, {
      title: "What is E-locker?",
      paragraph: "E-locker enables you to store your document electronically with Tata Capital’s E-locker service. You can store your documents in one central secured location and access them with just a few clicks."
    }, {
      title: "Which documents can be stored in E-locker?",
      paragraph: "You can store your personal as well as professional documents in E-locker, this will be accessible to you alone even Tata Capital employees will not have access to any of your uploaded files."
    }, {
      title: "Once any document is uploaded can I delete it?",
      paragraph: "Deletion functionality will be shortly introduced."
    }, {
      title: "I am not able to upload/download any document"
    }, {
      title: "What formats are accepted?",
      paragraph: "You can upload document in format jpg, jpeg, gif, png, bmp, doc, docx, xls, xlsx, pdf."
    }, {
      title: "Can I download the file that I had uploaded?",
      paragraph: "Yes You can download the files that you have uploaded on our E-locker."
    }, {
      title: "Can I upload multiple documents at one go?",
      paragraph: "Currently you can upload documents one at a time."
    }, {
      title: "Can I change/update my KYC details? If yes, how?",
      paragraph: "Yes you can update or even change your KYC details. KYC details include- Mobile no, Date of Birth, Address and PAN number.",
      paragraph1: "You need to go to Self Service section in navigation bar > Service Requirements > Click on Raise New > Select your request type (Mobile, DOB, etc.)-> Upload valid proof-> Submit your request.",
      paragraph2: "Please note: If you have multiple accounts your KYC details will be updated against the a/c no which is selected in drop down. To update your KYC details in your other account please raise a new request and select account no from drop down."
    }, {
      title: "I updated my PAN details but same is not visible",
      numberList: [
        "PAN update usually takes 3 business days. If you have raised the request within 3 days, kindly check back later.",
        "PAN details are updated for account number that it was raised against. To update PAN number in your other loan account follow below path-Self service-> raise new request-> select a/c no from the drop down-> submit your PAN details."
      ]
    }, {
      title: "I updated my Date of Birth but same is not visible",
      numberList: [
        "Date of Birth update usually takes 3 business days. If you have raised the request within 3 days, kindly check back later.",
        "Date of Birth details are updated for account number that it was raised against. To update DOB in your other loan account follow below path-Self service-> raise new request-> select a/c no from the drop down-> submit your Date of Birth with valid proof."
      ]
    }, {
      title: "Can I foreclose my loan account?",
      paragraph: "Foreclosure of loan account is allowed after certain tenure and varies from product to product. You can now raise a request for Foreclosure of Personal, Business and Used Car Loan, follow below path to raise request for foreclosure. Once the request is raised same will be addressed within 3 business days.For foreclosure of other loans kindly contact our customer care.",
      paragraph1: "Foreclosure process- Self service-> raise new request-> Statement & Certificate related."
    }, {
      title: "Are there any foreclosure charges?",
      paragraph: "Yes we have foreclosure charges and same varies for each product.",
      paragraph1: "Please click below for product wise detailed charges on foreclosure.",
      linkList: [
        {
          foreclosureChargesLoan: "Personal Loan",
          clickhereLink: "https://www.tatacapital.com/personal-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Home Loan",
          clickhereLink: "https://www.tatacapital.com/home-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Business Loan",
          clickhereLink: "https://www.tatacapital.com/business-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Loan Against Property",
          clickhereLink: "https://www.tatacapital.com/loan-against-property/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Consumer Durable Loan",
          clickhereLink: "https://www.tatacapital.com/consumer-durable-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Used Car Loan",
          clickhereLink: "https://www.tatacapital.com/used-car-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Two Wheeler Loan",
          clickhereLink: "https://www.tatacapital.com/two-wheeler-loan/rates-and-charges.html"
        }
      ]
    }, {
      title: "How and when can I make Part Pre-Payment?",
      paragraph: "Part Pre-payment is not allowed in minimum 1st 6 months, however it does vary from product to product.",
      paragraph1: "Please click below for product wise detailed charges on Part Pre-Payment.",
      linkList: [
        {
          foreclosureChargesLoan: "Personal Loan",
          clickhereLink: "https://www.tatacapital.com/personal-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Home Loan",
          clickhereLink: "https://www.tatacapital.com/home-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Business Loan",
          clickhereLink: "https://www.tatacapital.com/business-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Loan Against Property",
          clickhereLink: "https://www.tatacapital.com/loan-against-property/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Consumer Durable Loan",
          clickhereLink: "https://www.tatacapital.com/consumer-durable-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Used Car Loan",
          clickhereLink: "https://www.tatacapital.com/used-car-loan/rates-and-charges.html"
        }, {
          foreclosureChargesLoan: "Two Wheeler Loan",
          clickhereLink: "https://www.tatacapital.com/two-wheeler-loan/rates-and-charges.html"
        }
      ]
    }, {
      title: "How can I raise Disbursement Drawdown Request?",
      paragraph: "Select your OD account for which you need disbursal. Select Online Disbursal from Quick Links (on Dashboard), enter the required amount, key in the OTP and submit your request."
    }, {
      title: "When will the amount get credited in my account?",
      paragraph: "The amount will get credited after 48 working hours of successful online disbursal request creation."
    }, {
      title: "What is the procedure to depledge existing pledged securities?",
      paragraph: "View Holdings --> Click on De pledge button --> Select securities to De pledge."
    }, {
      title: "What is the procedure to pledge additional securities?",
      paragraph: "View Holdings --> Click on Incremental Pledging."
    }, {
      title: "I made the payment via payment gateway, but the payment was unsuccessful and amount has been deducted",
      paragraph: "Any amount deducted in case of a payment failure will be credited back to you withing 48 hours."
    }, {
      title: "I am unable to raise Disbursement drawdown request",
      paragraph: "Please check if you have cleared your deferral, if any. For more assistance reach out to your Relationship Manager."
    }, {
      title: "I am unable to depledge securities",
      paragraph: "Please check for margin shortfall. If you have margin shortfall, you will not be able to depledge securities. Please pledge additional securities if you want to depledge some existing pledged securities."
    }, {
      title: "Will I be charged to check my Credit Score?",
      paragraph: "Checking Credit Score accessed via Tata Capital digital channels is free."
    }, {
      title: "What is a credit score?",
      paragraph: "A credit score is a number that summarizes the historical credit information on a credit report. The number reflects the likelihood that you will become delinquent on a loan or a credit obligation in the future."
    }, {
      title: "Why don't I have a credit score?",
      paragraph: "Credit scoring models cannot generate a score without enough credit information.If you have little or no credit history, you probably will not have a credit score available."
    }, {
      title: "How often do credit scores change?",
      paragraph: "Your credit score changes as your credit report changes.Therefore, it can change often since new information is added to your credit report all the time."
    }, {
      title: "Who calculates credit scores?",
      paragraph: "Credit scores may come from several sources. Lenders may request that a credit score be provided along with your credit report. Credit reporting agencies provide the service of applying the credit scores from a number of credit score developers.Lenders specify which credit score they want delivered with the credit report.Credit scores may also be calculated by mortgage reporting companies that compile your credit reports from each of the national credit reporting companies and then deliver the combined reports and scores to the lender. Lenders may also apply their own, proprietary scores after receiving your credit report."
    }, {
      title: "How do I unsubscribe from Tata Capital’s Credit Score?"
    }, {
      title: "What is BBPS?",
      paragraph: "BBPS or Bharat Bill Payment System is a platform where you can pay your utility bills. Now you can pay your bills via Tata Capital Website / App."
    }, {
      title: "What is Bill pay?",
      paragraph: "Bill pay is an extended service of Bharat Bill Payment System. Now you can pay your bills using Tata Capital Website / App."
    }, {
      title: "What can I do using Bill pay?",
      paragraph: "Utility bill payments like electricity, loan repayments, piped gas, water, booking a gas cylinder, DTH recharge, Fastag etc can be done using this platform."
    }, {
      title: "What payment options are available in Bill pay?",
      paragraph: "You may pay using UPI."
    }
  ]
  let collectionFaqObj = [
    {
      title: "Will I be charged to check my Credit Score?",
      paragraph: "Checking Credit Score accessed via Tata Capital digital channels is free.",
      
    },
    {
      title: "What is a credit score?",
      paragraph: "A credit score is a number that summarizes the historical credit information on a credit report. The number reflects the likelihood that you will become delinquent on a loan or a credit obligation in the future.",
    },
    {
      title: "Why don't I have a credit score?",
      paragraph: "Credit scoring models cannot generate a score without enough credit information.If you have little or no credit history, you probably will not have a credit score available."
    },
    {
      title: "How often do credit scores change?",
      paragraph: "Your credit score changes as your credit report changes.Therefore, it can change often since new information is added to your credit report all the time."
    },
    {
      title: "Who calculates credit scores?",
      paragraph: "Credit scores may come from several sources. Lenders may request that a credit score be provided along with your credit report. Credit reporting agencies provide the service of applying the credit scores from a number of credit score developers.Lenders specify which credit score they want delivered with the credit report.Credit scores may also be calculated by mortgage reporting companies that compile your credit reports from each of the national credit reporting companies and then deliver the combined reports and scores to the lender. Lenders may also apply their own, proprietary scores after receiving your credit report."
    },
    {
      title: "When will the paymenty made reflect on my account?",
      paragraph: "It takes 2 working days for the payments done to reflects in your statements."
    },
    {
      title: "What are various digital modes of payments?",
      paragraph: "You can pay us digitally via Net Banking, Debit Card, UPI and QR Codes."
    },
    {
      title: "Does non repayment of EMIs on time impact Credit score?",
      paragraph: "Yes, it impacts your Credit score which inturm will impact your ability to take loan in future.",
    }, {
      title: "What is the maximum number of tranches I can avail in settlement?",
      paragraph: "You can avail maximum 2 tranches in settlement."
    }, {
      title: "What happens if I don't make full payment of settlement by agreed dates.",
      paragraph: "If payment is not received as per agreed terms, your settlement will be terminated and you will have to pay as per normal repayment norms. You will lose out on this one time offer.",
    }, {
      title: "When will I get NDC for settled account.",
      paragraph: "It will take 10 working days to process NDC for settled account for applicable products."
    }, 
  ]
  return (
    <div className='faqpage-section'>
      <PageWrapper className="faqpage-wrapper">
        <RadiantBorder className="faq-radient-border" >
          {
            isMobile ? <div className='faq-profile-backbtn'>
              <p className="faq-details-heading" onClick={handleMobileClick}>
                {isMobile && (
                  <img
                    src="assets/images/backArrow.svg"
                    alt="back-arrow"
                    className="back-arrow-image"
                  />
                )} FAQs</p>
            </div> : ""
          }

          <div className='faq-search-accordion faq-search-1'>
            <div className='faq-search'>
              <Searchbar placeholder="Search for help topics" button={true} className="faq-search-content" forComponent="searchAccordionFaq" onFaqAccordionInputChange={(event) => { accordionInputChange(event.target.value) }} inputValue={inputValue} />
            </div>
            <div className='faq-accodion-scroll'>
              <div className='faq-accordion'>
                {
                    portalType === 'collection' ? collectionFaqObj.map((value, index) => (value.title.toLowerCase().includes(newString.toLowerCase()) ? <Accordion faqContent={value} arrayOptions={arrayOptions[index]?.options} accordionTitle={value.title} open={accordionOpen} accordionType={"FAQ"} toggleOpen={(id) => {
                        setaccordionOpen(id);
                      }} id={index + 1} key={index} /> : <></>)) : faqObject.map((value, index) => (value.title.toLowerCase().includes(newString.toLowerCase()) ? <Accordion faqContent={value} arrayOptions={arrayOptions[index]?.options} accordionTitle={value.title} open={accordionOpen} accordionType={"FAQ"} toggleOpen={(id) => {
                        setaccordionOpen(id);
                      }} id={index + 1} key={index} /> : <></>))
                  // isSearch ? search.map((value, index) => <Accordion faqContent={value} arrayOptions={arrayOptions[index]?.options} accordionTitle={value.title} open={accordionOpen} accordionType={"FAQ"} toggleOpen={(id) => {
                  //   setaccordionOpen(id);
                  // }} id={index + 1} key={index} />) :
                  //   faqObject.map((value, index) => <Accordion faqContent={value} arrayOptions={arrayOptions[index]?.options} accordionTitle={value.title} open={accordionOpen} accordionType={"FAQ"} toggleOpen={(id) => {
                  //     setaccordionOpen(id); faqClick(value, index);
                  //   }} id={index + 1} key={index} />)
                }
                {/* {
                  myAccordionArray.map((value, index) => <Accordion arrayOptions={arrayOptions[index]?.options} accordionTitle={value.accordionTitle} open={accordionOpen} accordionType={"FAQ"} toggleOpen={setaccordionOpen} id={index + 1} key={index} />)
                } */}

                {/* <Accordion accordionTitle="What are the list of services covered under Service request?" open={accordionOpen} toggleOpen={setaccordionOpen} id="1" />
                <Accordion accordionTitle="How can I Change my Email Id?" open={accordionOpen} toggleOpen={setaccordionOpen} id="2" />
                <Accordion accordionTitle="I have two loan contracts how to update the Email for both?" open={accordionOpen} toggleOpen={setaccordionOpen} id="3" />
                <Accordion accordionTitle="Can I update the Email Id of all the accounts at one go?" open={accordionOpen} toggleOpen={setaccordionOpen} id="4" />
                <Accordion accordionTitle="Can I update the Email Id of all the accounts at one go?" open={accordionOpen} toggleOpen={setaccordionOpen} id="5" /> */}
              </div>
            </div>
          </div>
        </RadiantBorder>
      </PageWrapper>
    </div>
  )
}

FaqPage.prototypes = {
  setIsActive: PropTypes.func,
  isMobile: PropTypes.bool,
  searchValue: PropTypes.string
}

export default FaqPage