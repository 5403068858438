import OverviewServiceObject from "../../../Retail/src/services/Overview"
import { STOREDATA } from "../../../Shared/context/data-mapping/action/action.type"
import { getLastestFieldValFromField } from "./getLastestFieldValFromField"

export const pgsApiCallFn = (data, userObject, dispatchData,totalODAmount,journeyType, isTrancheOpted, noOfTranchesOpted, offerType, trancheNumber, emiOverdueCharges,odbcOverdueCharges, totalOverdueAmount) => {
    let currentData = data?.getUserDataV3?.body
    let parsedUserData = data?.parsedUserData
    let currentContractNo = data?.currentActiveData.accountNumber
    let sourceSystem = parsedUserData[currentContractNo]?.productInfo?.SOURCE_SYSTEM
    
    let reqbody = {};
    if(parsedUserData[currentContractNo].system === "retail"){
        if(journeyType === "offer"){
            reqbody = {
                header: {
                    authToken: data?.authToken,
                    system:"retail",
                    journeyType: journeyType,
                    offerType: offerType
                },
                body: {
                    company: parsedUserData[currentContractNo]?.COMPANY,
                    contractNo: currentContractNo,
                    userId: currentData?.USER_ID,
                    gcid: currentData?.GCID,
                    primaryGcid: currentData?.PRIMARY_GCID,
                    sourceSystem: sourceSystem,
                    emailId: getLastestFieldValFromField(currentData?.emails),
                    productName: parsedUserData[currentContractNo]?.productInfo?.PRODUCT_NAME,
                    paymentMode: null,
                    mobileNo: getLastestFieldValFromField(currentData?.mobiles),
                    customerName: data?.customerName,
                    totalAmount: totalODAmount,
                    productCode: parsedUserData[currentContractNo]?.productInfo?.PRODUCT_CODE,
                    alternateMobileNumber: userObject?.mobile ? userObject?.mobile : "",
                    alternateEmailId: userObject?.email ? userObject?.email : "",
                    totalOverdueAmount : totalOverdueAmount,
                    isTrancheOpted: isTrancheOpted,
                    noOfTranchesOpted: noOfTranchesOpted,
                    trancheNumber: trancheNumber,
                    emiOverdueAmount: emiOverdueCharges,
                    overdueCharges: odbcOverdueCharges
                }
            }
        }else if(journeyType === "non-offer"){
            reqbody = {
                header: {
                    authToken: data?.authToken,
                    system:"retail",
                    journeyType: journeyType
                },
                body: {
                    company: parsedUserData[currentContractNo]?.COMPANY,
                    contractNo: currentContractNo,
                    userId: currentData?.USER_ID,
                    gcid: currentData?.GCID,
                    primaryGcid: currentData?.PRIMARY_GCID,
                    sourceSystem: sourceSystem,
                    emailId: getLastestFieldValFromField(currentData?.emails),
                    productName: parsedUserData[currentContractNo]?.productInfo?.PRODUCT_NAME,
                    paymentMode: null,
                    mobileNo: getLastestFieldValFromField(currentData?.mobiles),
                    customerName: data?.customerName,
                    totalAmount: userObject.amount,
                    productCode: parsedUserData[currentContractNo]?.productInfo?.PRODUCT_CODE,
                    alternateMobileNumber: userObject?.mobile ? userObject?.mobile : "",
                    alternateEmailId: userObject?.email ? userObject?.email : "",
                    totalOverdueAmount : totalODAmount
                }
            }
        }
    }else{
        reqbody = {
            header: {
                authToken: data?.authToken,
                system:"ccod"
            },
            body: {
                company: parsedUserData[currentContractNo]?.cdiContract.COMPANY,
                contractNo: currentContractNo,
                userId: currentData?.USER_ID,
                gcid: currentData?.GCID,
                primaryGcid: currentData?.PRIMARY_GCID,
                sourceSystem: "SAP",
                emailId: getLastestFieldValFromField(currentData?.emails),
                paymentMode: null,
                mobileNo: getLastestFieldValFromField(currentData?.mobiles),
                customerName: data?.customerName,
                totalAmount: userObject.amount,
                productCode: "CCOD",
                alternateMobileNumber: userObject?.mobile ? userObject?.mobile : "",
                alternateEmailId: userObject?.email ? userObject?.email : "",
                totalOverdueAmount : totalODAmount
            }
        }
    }

    return new Promise((resolve, reject) => {
        OverviewServiceObject.pgsApiCall(reqbody, data?.authToken).then((response) => {

            let redirectUrl = response.body.finalUrl;
            let uniqueTransactionId = response.body.uniqueTransactionId
            window.location.href = redirectUrl
    
            // dispatchData({
            //     name: "loaderFlag",
            //     payload: false,
            //     type: STOREDATA
            // });
            resolve(uniqueTransactionId);
        }).catch((error) => {
            // dispatchData({
            //     name: "loaderFlag",
            //     payload: false,
            //     type: STOREDATA
            // })
            reject(error);
        })

    })

}