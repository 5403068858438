import React, { useState } from "react";
import "./Profile.scss";
import PageWrapper from "../../../Retail/src/container/PageWrapper";
import ProfileEdit from "../../../Retail/src/components/ProfileEdit";
import ProfileDetails from "../../../Retail/src/components/ProfileDetails";
import ProfilePasswordChange from "../../../Retail/src/components/ProfilePasswordChange";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import { useContext } from "react";
import { useEffect } from "react";
// import Data from "./Data";
import ProfileServiceObject from "../../../Retail/src/services/Profile";
import FaqPage from "../../../Retail/src/components/FaqPage";
import DataMapping from "../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../../Retail/src/global/FailurePopUp";
import useSessionAlive from "../../../Retail/src/customHooks/useSessionAlive";
import commonFunctionObject from "../../../Retail/src/common-function";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import jsHelper from "../../../Retail/src/utilities/jsHelper";
import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import { useLocation, useNavigate } from "react-router-dom";

const Profile = ({ portalType }) => {
  const [ContextData, dispatchData] = useContext(DataMapping);
  const [data] = useContext(DataMapping);
  const { analytics } = useContext(AnalyticsContextProvider);
  const [themeState] = useContext(ThemeContext);
  var getProfilePic = ContextData?.getUserDataV3?.body.PROFILE_PIC !== undefined ? ContextData?.getUserDataV3?.body.PROFILE_PIC !== "" ? ContextData?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg"
  const [profilePic, setProfilePic] = useState(getProfilePic)
  // const [data, setData] = useState(Data);
  const { isUserSessionAlive } = useSessionAlive();
  const [accountNumberDrop, setAccountNumberDrop] = useState([]);
  const [currentContract, setCurrentContract] = useState({});
  const [optionState, setOptionState] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  // let initialContract = data.userData["userData"][Object.keys(data.userData["userData"])[2]].SYSTEM_NAME
  const [systemFlag, setSystemFlag] = useState("");
  const [bankingDetails, setBankingDetails] = useState({});
  const [companylist, setCompanyList] = useState([]);
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });
  const {
    customerServicePageName,
    setCustomerServicePageName,
    pageName,
    setPageName,
  } = useContext(pageNameContext);

  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);

  const quickLinksFlag = location.state
    ? location.state.bankFlag
      ? location.state.bankFlag
      : ""
    : "";
  console.log("quickflag profile", quickLinksFlag);
  const [active, setIsActive] = useState(
    quickLinksFlag
      ? {
        profile: false,
        bank: true,
        password: false,
        help: false,
        branch: false,
        terms: false,
        about: false,
      }
      : {
        profile: true,
        bank: false,
        password: false,
        help: false,
        branch: false,
        terms: false,
        about: false,
      }
  );
  useEffect(() => {
    if (data.length === 0) {
      navigate("/login")
    } else {
      setPageName("Profile");
      createAccountNumberDropDown();
      commonFunctionObject.scrollToTop()
      let analytics_data = {
        page: {
          name: "profile",
          siteSection: "profile",    //pass PL, BL, Home page
          pageType: "",
          siteSubSection: "",     //PL overview......
          pageURL: window.location.href,
          pathName: window.location.pathname
        },
        user: {
          loginMethod: jsHelper.toStr(data?.userLoginType?.body?.loginType),
          loginStatus: "",
          gcid: jsHelper.toStr(data?.getUserDataV3?.body?.primarySystem?.GCID),
          lobMembership: "",
          lastloginDate: jsHelper.toStr(data?.getUserDataV3?.body?.LAST_LOGGED_IN)
        },
        products: {
          productCode: ""
        }
      }

      if(portalType !== "collection"){
         analytics.trackData("pageInitialization", analytics_data)
      }
    }


  }, []);

  useEffect(() => {
    if (location?.state?.redirectionFrom === "profile-details") {

      setIsActive({
        profile: true,
        bank: false,
        password: false,
        help: false,
        branch: false,
        terms: false,
        about: false
      });
    }
  }, [location?.state?.redirectionFrom])

  const createAccountNumberDropDown = () => {
    //setAccountNumberDrop(arrayData);
    let accountNumber = ContextData?.currentActiveData?.accountNumber
    let productName = ContextData?.currentActiveData?.system === "retail" ? commonFunctionObject.getProductName(ContextData?.parsedUserData[accountNumber]) : ""
    let shortName = ContextData?.currentActiveData?.system === "retail" ? commonFunctionObject.getProductShortName(productName) : ContextData?.currentActiveData?.system === "ccod" ? "CCOD" : "LAS"
    setSelectedOption(accountNumber + " (" + shortName + ")");
    setCurrentContract(ContextData?.parsedUserData[accountNumber]);
    setSystemFlag(ContextData?.currentActiveData?.system);
    getBankingDetailsFromApi(
      accountNumber,
      ContextData?.parsedUserData[accountNumber],
      ContextData?.currentActiveData?.system,
      active
    );

    // if (quickLinksFlag) {
    //   setSelectedOption(location.state.accountnumber);
    //   setCurrentContract(ContextData?.parsedUserData[location.state.accountnumber]);
    //   setSystemFlag(
    //     ContextData?.parsedUserData[location.state.accountnumber].system
    //   );
    //   getBankingDetailsFromApi(
    //     location.state.accountnumber,
    //     ContextData?.parsedUserData[location.state.accountnumber],
    //     ContextData?.parsedUserData[location.state.accountnumber].system,
    //     active
    //   );
    // } else {
    //   setSelectedOption(arrayData[0].value);
    //   setCurrentContract(ContextData?.parsedUserData[arrayData[0].value]);
    //   setSystemFlag(ContextData?.parsedUserData[arrayData[0].value].system);

    //   getBankingDetailsFromApi(
    //     arrayData[0].value,
    //     ContextData?.parsedUserData[arrayData[0].value],
    //     ContextData?.parsedUserData[arrayData[0].value].system,
    //     active
    //   );
    // }
  };
  console.log(currentContract);

  const storeSelectAccNumberData = (selected) => {
    setCurrentContract(ContextData?.parsedUserData[selected.split(" ")[0]]);
    setSystemFlag(ContextData?.parsedUserData[selected.split(" ")[0]]?.system);
    getBankingDetailsFromApi(
      selected.split(" ")[0],
      ContextData?.parsedUserData[selected.split(" ")[0]],
      ContextData?.parsedUserData[selected.split(" ")[0]].system,
      active
    );
  };
  const getBankingDetailsFromApi = (
    accountNumber,
    currentContract,
    flagsystem,
    refObj
  ) => {
    if (flagsystem === "retail") {
      if (!bankingDetails[accountNumber] && refObj.bank) {
        callRetailBankingDetailAndStoreFn(accountNumber, currentContract);
      }
    }
    if (flagsystem === "ccod") {
      if (!bankingDetails[accountNumber] && refObj.bank) {
        console.log("Entered first funct");
        getGetDisbursalDetailFn(accountNumber, currentContract);
      }
    }
    if (flagsystem === "las") {
      if (companylist.length === 0) {
        callCompmayList();
      }
    }
  };
  const callRetailBankingDetailAndStoreFn = (
    accountNumber,
    currentContract
  ) => {
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    ProfileServiceObject.getBankingDetailService(accountNumber, ContextData.getLoanPositionDTLS[accountNumber], ContextData?.authToken)
      .then((value) => {
        setBankingDetails((prev) => ({
          ...prev,
          [accountNumber]: value,
        }));
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
      })
      .catch((error) => {
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
        setFailurePopUpContent({
          apiName: "",
          message:
            "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
          errorCode: error,
        });
        setFailurePopUpModal(true)
        console.log(error);
      });
  };
  const getGetDisbursalDetailFn = (accountNumber, currentContract) => {
    console.log("first inner");
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    ProfileServiceObject.getDisbursalDetailsApi(accountNumber, currentContract, ContextData?.authToken)
      .then((value) => {

        setBankingDetails((prev) => ({
          ...prev,
          [accountNumber]: value,
        }));
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
      })
      .catch((error) => {
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
        setFailurePopUpContent({
          apiName: "",
          message:
            "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
          errorCode: error,
        });
      });
  };
  const callCompmayList = () => {
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    ProfileServiceObject.getCompanyListService(ContextData?.authToken)
      .then((value) => {
        setCompanyList(value);
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
      })
      .catch((error) => {
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
        setFailurePopUpContent({
          apiName: "",
          message:
            "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
          errorCode: error,
        });

        console.log(error);
      });
  };
  const handleClick = (e, name, ref) => {
    if (name === "bank") {
      if (systemFlag === "las" || (systemFlag === "retail" && !commonFunctionObject.checkContractStatus(ContextData?.getLoanPositionDTLS[selectedOption.split(" ")[0]]?.body.getLoanPositionDtlsReturn.loanStatus))) {

      } else {
        const newObj = Object.keys(active).reduce((obj, key) => {
          obj[key] = name === key && ref.current.contains(e.currentTarget);
          return obj;
        }, {});
        setIsActive(newObj);
        getBankingDetailsFromApi(
          selectedOption.split(" ")[0],
          currentContract,
          systemFlag,
          newObj
        );
      }
    } else {
      const newObj = Object.keys(active).reduce((obj, key) => {
        obj[key] = name === key && ref.current.contains(e.currentTarget);
        return obj;
      }, {});
      setIsActive(newObj);
      getBankingDetailsFromApi(
        selectedOption.split(" ")[0],
        currentContract,
        systemFlag,
        newObj
      );
    }
    navigate("/profile", { state: { redirectionForm: "" } })
  };

  useEffect(() => {
    isUserSessionAlive(ContextData?.authToken);
  }, []);

  useEffect(() => {
    var getProfilePic = ContextData?.getUserDataV3?.body?.PROFILE_PIC !== undefined ? ContextData?.getUserDataV3?.body?.PROFILE_PIC !== "" ? ContextData?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg"
    setProfilePic(getProfilePic)
  }, [themeState])

  return (
    <>
      {/* <div className="bg-01-container"></div>
      <div className="bg-02-container"></div> */}
      <div className="main-container profile-desktop-view">
        <PageWrapper>
          <ProfileEdit
            portalType={portalType}
            active={active}
            setIsActive={setIsActive}
            handleClick={handleClick}
            systemFlag={systemFlag}
            ProfileImg={profilePic}
            lastLogin={commonFunctionObject?.datechange((ContextData?.getUserDataV3?.body?.LAST_LOGGED_IN)?.split(" ")[0]) + " " + (ContextData?.getUserDataV3?.body?.LAST_LOGGED_IN)?.split(" ")[1]}
            gcid={ContextData?.getUserDataV3?.body?.GCID}
            userName={
              ContextData?.customerName
            }
            selectedOption={selectedOption}
          />
          {active.profile && (
            <ProfileDetails
              portalType={portalType}
              heading={"Profile Details"}
              optionState={optionState}
              setOptionState={setOptionState}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              storeSelectAccNumberData={storeSelectAccNumberData}
              currentContract={currentContract}
              systemFlag={systemFlag}
              typeFlag="profile"
              bankLas={false}
              closeAcc={false}
              companylist={companylist}
            />
          )}
          {active.bank && (
            <ProfileDetails
              portalType={portalType}
              heading={"Bank Details"}
              bankStyle={true}
              optionState={optionState}
              setOptionState={setOptionState}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              storeSelectAccNumberData={storeSelectAccNumberData}
              currentContract={currentContract}
              systemFlag={systemFlag}
              typeFlag="bank"
              bankingDetails={bankingDetails}
              bankLas={true}
              closeAcc={true}
            />
          )}
          {active.password && <ProfilePasswordChange />}
          {active.help && (
            <FaqPage portalType={portalType} setIsActive={setIsActive} isMobile={false} />
          )}
        </PageWrapper>
      </div>
      <div className="profile-mobile-view">
        <div className="profile-mobile-view-edit">
          {Object.values(active).includes(true) ? (
            ""
          ) : (
            <ProfileEdit
              portalType={portalType}
              active={active}
              setIsActive={setIsActive}
              handleClick={handleClick}
              systemFlag={systemFlag}
              ProfileImg={profilePic}
              lastLogin={commonFunctionObject?.datechange((ContextData?.getUserDataV3?.body?.LAST_LOGGED_IN)?.split(" ")[0]) + " " + (ContextData?.getUserDataV3?.body?.LAST_LOGGED_IN)?.split(" ")[1]}
              gcid={ContextData?.getUserDataV3?.body?.GCID}
              userName={
                ContextData?.customerName
              }
              selectedOption={selectedOption}
            />
          )}

          {active.profile && (
            <ProfileDetails
              portalType={portalType}
              heading={"Profile Details"}
              isMobile={true}
              setIsActive={setIsActive}
              optionState={optionState}
              setOptionState={setOptionState}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              storeSelectAccNumberData={storeSelectAccNumberData}
              currentContract={currentContract}
              systemFlag={systemFlag}
              typeFlag="profile"
            />
          )}
          {active.bank && (
            <ProfileDetails
              portalType={portalType}
              heading={"Bank Details"}
              bankStyle={true}
              isMobile={true}
              setIsActive={setIsActive}
              optionState={optionState}
              setOptionState={setOptionState}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              storeSelectAccNumberData={storeSelectAccNumberData}
              currentContract={currentContract}
              displayObject={bankingDetails}
              systemFlag={systemFlag}
              typeFlag="bank"
              bankingDetails={bankingDetails}
              bankLas={true}
              closeAcc={true}
            />
          )}
          {active.password && (
            <ProfilePasswordChange setIsActive={setIsActive} isMobile={true} />
          )}
          {active.help && (
            <FaqPage
              portalType={portalType}
              setIsActive={setIsActive}
              inProfile={true}
              isMobile={true}
            />
          )}
        </div>
        {failurePopUpModal ? (
          <FailurePopUp
            onClick={() => setFailurePopUpModal(false)}
            visible={failurePopUpModal}
            setFailureModalStat={setFailurePopUpModal}
            failurePopUpContent={failurePopUpContent}
          />
        ) : null}
      </div>
    </>
  );
};

export default Profile;
