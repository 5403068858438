import React from 'react';
import ReactDOM from 'react-dom/client';
import Retail from './Retail';
import ThemeContext from './Shared/context/ThemeContext/store/ThemeContext';
import ThemeProvider from './Shared/context/ThemeContext/store/ThemeProvider';
import Collection from './Collection';
import AnalyticsContextProvider from './Shared/context/AnalyticsContext/AnalyticsContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <AnalyticsContextProvider>
  <ThemeProvider>
    {
      process.env.REACT_APP_NAME === "RETAIL" ? <Retail /> : <Collection />
    }
  </ThemeProvider>
  /* </AnalyticsContextProvider> */

);


