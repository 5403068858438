import React, { useEffect, useState, useRef } from 'react'
import Slider from "react-slick";
import './CreditScoreDisplay.scss'
import RadiantBorder from '../../global/RadiantBorder'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useContext } from 'react'
// import ThemeContext from '../../context/ThemeContext/store/ThemeContext'
import ThemeContext from '../../../../Shared/context/ThemeContext/store/ThemeContext';
// import { THEME_DARK } from '../../context/ThemeContext/actions'
import { THEME_DARK } from '../../../../Shared/context/ThemeContext/actions';
import ReactSpeedometer from "react-d3-speedometer"
import Accordion from '../Accordion/Accordion'
import CreditScoreServiceObject from '../../services/CreditScoreService'
import DataMapping from '../../../../Shared/context/data-mapping'
import userData from '../../screens/Overview/Data'
import { useLocation } from 'react-router-dom'
import commonFunctionObject from '../../common-function'
import { STOREACCOUNTDATA, STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type'
import Button from '../../global/Button'
import CreditscoreAccountData from '../CreditscoreAccountData/CreditscoreAccountData'
import TipsComponent from '../../global/TipsComponent/TipsComponent';
import FailurePopUp from '../../global/FailurePopUp';
import SuccessPopUp from '../../global/SuccessPopUp';
import PaymentHistoryPopup from '../PaymentHistoryPopup';
import PropTypes from 'prop-types';

const CreditScoreDisplay = ({ creditScore,portalType}) => {
  const [themeState] = useContext(ThemeContext)
  const [data, dispatchData] = useContext(DataMapping);
  const [open, setOpen] = useState(false)
  const [subaccopen, setsubaccopen] = useState(false)

  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)

  const getCreditScore = creditScore ? creditScore : data.getUserDataV3.body.CREDIT_SCORE

  const [nextUpdateOn, setNextUpdateOn] = useState("")
  const [lastUpdatedOn, setLastUpdatedOn] = useState("")
  const [creditScoreStatus, setCreditScoreStatus] = useState("")
  const [degree, setDegree] = useState(0)
  const [sliderArr, setSliderArr] = useState([]);
  const [accordionActive, setAccordionActive] = useState(0);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");
  const [totalAccountObj, setTotalAccountObj] = useState({});
  const [enquiry, setenquiry] = useState({});
  const [payemntHistoryArr, setPaymentHistoryArr] = useState([]);
  const [, setDividedArray] = useState([]);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const [paymetHistoryPopup, setpaymetHistoryPopup] = useState(false);
  const [singleCard, setsingleCard] = useState({});

  useEffect(() => {
    getcredirscoredetailscall()
    populateValues(getCreditScore)
    commonFunctionObject.scrollToTop()
    accordianOpen();
  }, []);

  const populateAccordian = (response) => {

    console.log(response)
    populateCreditScoreDetails(response);
    populateEnquiriesSection(response);
    populatePaymentHistory(response);
  }

  const accordianOpen = () => {
    if (accordionActive === accordionActive) {
      setAccordionActive(0)
    }
  }

  const getcredirscoredetailscall = () => {

    var getcredirscoredetailsObj = {
      "header": {
        "authToken": data.authToken
      },
      "body": {
        "primaryGcid": data.getUserDataV3.body.PRIMARY_GCID
      }
    }
    dispatchData({
      type: STOREDATA,
      payload: true,
      name: "loaderFlag"
    })

    CreditScoreServiceObject.getCreditScoreDetailsService(getcredirscoredetailsObj).then(response => {
      dispatchData({
        type: STOREACCOUNTDATA,
        payload: {
          response: response,
          contractNumber: portalType !== "collection" ? commonFunctionObject.appendZeros(data.currentActiveData.accountNumber) : data.currentActiveData.accountNumber,
        },
        name: "getCreditScoreDetails",
      });
      dispatchData({
        type: STOREDATA,
        payload: false,
        name: "loaderFlag"
      })


      populateAccordian(response);

    }).catch((error) => {
      setFailurePopUpContent({
        apiName: "",
        message:
          "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
        errorCode: error,
      });
      setFailurePopUpModal(true);
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA,
      });
    })
  }

  const accordianData = [
    {
      id: 1,
      title: 'TOTAL ACCOUNTS',
      desc: `The total count of your open and closed loan and Credit Cards.
        Having a healthy mix of unsecured (Credit Card, Personal Loan)
        and secured (Home Loan, Car Loan) credit lines demonstrates that lenders trust you.`
    },
    {
      id: 2,
      title: 'PAYMENTS HISTORY',
      desc: 'Invest in New Funds offer (NFOs) from various AMCs'
    },
    {
      id: 3,
      title: 'CREDIT ENQUIRIES',
      desc: 'Track your investment portfolio with just a click'
    },
    {
      id: 4,
      title: 'LEGENDS',
      desc: 'Use SIP Calculator to plan your growth journey'
    },
  ]


  const populateCreditScoreDetails = (response) => {

    console.log(response);
    totalAccountObj.totalAccount = response.creditScoreDetails[0].CREDIT_SCORE_JSON.INProfileResponse.CAIS_Account.CAIS_Summary.Credit_Account.CreditAccountTotal;
    setTotalAccountObj(totalAccountObj)
    console.log(totalAccountObj);
    totalAccountObj.accountdetailTable = response.creditScoreDetails[0].CREDIT_SCORE_JSON.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS;
  }

  const populateEnquiriesSection = (response) => {

    var enquiriesInLast30Days = response.creditScoreDetails[0].CREDIT_SCORE_JSON.INProfileResponse.CAPS.CAPS_Summary.CAPSLast30Days;
    enquiry.enquiriesInLast30Days = enquiriesInLast30Days;
    if (enquiriesInLast30Days == '0' || enquiriesInLast30Days == '1') {
      enquiry.feedback = "Excellent"

    } else if (enquiriesInLast30Days == '2' || enquiriesInLast30Days == '3') {
      enquiry.feedback = "Good"
    } else {
      enquiry.feedback = "Poor"
    }
  }



  const populatePaymentHistory = (payemntHistoryResponse) => {

    let totalAccount = payemntHistoryResponse.creditScoreDetails[0].CREDIT_SCORE_JSON.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS;
    let newDivideArr = getDividedArray(totalAccount);

    setDividedArray(newDivideArr.flat());

    let newArrayDetails = []

    newDivideArr.flat().forEach(function (elem, index) {
      let paymentPercent = calculatePaymentPercentage(elem.CAIS_Account_History);
      newArrayDetails = [...newArrayDetails, { ...elem, paymentPercent }]
    })


    setPaymentHistoryArr(newArrayDetails);



    /*     let cardNo = "";
        let cardStatus = "";
        let highestCredit = "";
        let lender = "" */


    /* creditScoreDetails[0].CREDIT_SCORE_JSON.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS.forEach(function (elem, index) {
        paymentPercentage = calculatePaymentPercentage(elem.CAIS_Account_History);
        paymentDetailsDiv.innerHTML += paymentOptionsCardTemplate(elem, index, paymentPercentage);
    }); */
  }


  const getDividedArray = (arr) => {
    var newArr = [];
    var myArr = [];
    arr.forEach(function (val, index) {
      if (index == 0) {
        myArr.push(val);
      } else if ((index + 1) % 2 == 0) {
        myArr.push(val);
        newArr.push(myArr);
        myArr = [];
      } else if (arr.length !== (index + 1)) {
        myArr.push(val);
      } else {
        myArr.push(val);
        newArr.push(myArr);
      }
    });
    return newArr;
  }

  const calculatePaymentPercentage = (accountHistoryArray) => {
    var totalCount = accountHistoryArray.length;
    var onTimeCount = 0;

    accountHistoryArray.forEach(function (elem) {
      if (elem.Days_Past_Due.toString().toLowerCase() == 's' || elem.Days_Past_Due == '0') {
        onTimeCount++;
      }
    });

    return (onTimeCount / totalCount) * 100;
  }

  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
      setOpen1(false);
      setOpen2(false);
      setOpen3(false);
    }
  }

  const handleOpen1 = () => {
    if (open1) {
      setOpen1(false);
    } else {
      setOpen1(true);
      setOpen(false);
      setOpen2(false);
      setOpen3(false);
    }
  }
  const handleOpen2 = () => {
    if (open2) {
      setOpen2(false);
    } else {
      setOpen2(true);
      setOpen(false);
      setOpen1(false);
      setOpen3(false);
    }
  }

  const handleOpen3 = () => {
    if (open3) {
      setOpen3(false);
    } else {
      setOpen3(true);
      setOpen(false);
      setOpen1(false);
      setOpen2(false);
    }
  }

  const handleopensubacc = () => {
    setsubaccopen(!subaccopen)
  }

  const populateValues = (getCreditScore) => {
    let userData = data?.getUserDataV3?.body
    let updatedDate
    if (userData.creditScoreDetails && Object.keys(userData.creditScoreDetails).length !== 0) {
      if (userData.creditScoreDetails.PORTAL_LAST_MODIFIED) {
        updatedDate = userData.creditScoreDetails.PORTAL_LAST_MODIFIED;
      }
    } else {
      var currentLoggedInDate = userData.CURRENT_LOGGED_IN.split(" ")[0]
      var currentLoggedInDateSplit = currentLoggedInDate.split("-")
      var setPortalLastModifiedDate = currentLoggedInDateSplit[2] + "-" + currentLoggedInDateSplit[0] + "-" + currentLoggedInDateSplit[1]
      updatedDate = setPortalLastModifiedDate
    }
    var newUpdatedDateSlice = updatedDate.slice(0, 10);
    var newUpdatedDateSplitArr = newUpdatedDateSlice.split('-');
    var newUpdatedDate = newUpdatedDateSplitArr[1] + "/" + newUpdatedDateSplitArr[2] + "/" + newUpdatedDateSplitArr[0];
    var nextDate = commonFunctionObject.convertDDMMYYYY(new Date(nextCreditscoreUpdatedDate(newUpdatedDate)));
    setLastUpdatedOn(commonFunctionObject.convertDDMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(updatedDate))))
    setNextUpdateOn(nextDate)

    let percentage = (getCreditScore * 100) / 900
    let decimalDegree = percentage / 100
    setDegree(180 * decimalDegree)

    if (getCreditScore >= 740 && getCreditScore <= 799) {
      setCreditScoreStatus("Very good")
      //setDegree(120)
    } else if (getCreditScore >= 800 && getCreditScore <= 850) {
      setCreditScoreStatus("Excellent")
      //setDegree(174)
    }
    //setCreditScoreStatus()
  }

  function nextCreditscoreUpdatedDate(date) {
    var today = new Date(commonFunctionObject.replaceHyphenDate(date));
    var newdate = today.setDate(today.getDate() + 90);
    return newdate;
  }

  const PreviousBtn = (props) => {
    let showPrev = props.currentSlide === 0 ? "grey-colour" : "";
    return (
      <div className="arrowCircleBack" onClick={() => customSlider.current.slickPrev()}>
        <img className={showPrev} src={`${themeState === "THEME_DARK" ? "assets/images/dark-icon/arrow-circle-right.svg" : "assets/images/light-icon/arrow-circle-right.svg"}`} alt="" />
      </div>
    )
  }

  const NextBtn = (props) => {
    var payemntHistoryArrLength = payemntHistoryArr.length
    let shownext = (payemntHistoryArrLength - 1) ? "grey-colour" : "";

    if (payemntHistoryArr) {
      shownext = payemntHistoryArr.length - 1 === props.currentSlide ? "grey-colour" : ""
    }
    return (
      <div className="arrowCircleFront" onClick={() => customSlider.current.slickNext()}>
        <img className={shownext} src={`${themeState === "THEME_DARK" ? "assets/images/dark-icon/arrow-circle-right.svg" : "assets/images/light-icon/arrow-circle-right.svg"}`} alt="" />
      </div>
    )
  }

  const customSlider = useRef();
  const settings = {
    ref: (slider) => (customSlider.current = slider),
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: 0,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
    beforeChange: (oldIndex, newIndex) => {
      setActiveSlideIndex(newIndex)
    }
  }

  const paymentTipHeading = 'How to Improve your Credit Score';
  const paymentTipContent = [
    {
      content: 'Ensure you don' + 't miss a single Credit Card bill date or EMI payment -- late payments can hurt your Credit Score. You could either set up automatic bill payments or reminders to make sure your payments are on track.'
    }
  ]

  const enquiryTipHeading1 = 'How to Improve your Credit Score';
  const enquiryTipContent1 = [
    {
      content: 'A healthy gap between each credit application will ensure that hard enquiries don' + 't cause a dip in your Credit Score.'
    }
  ]

  const enquiryTipHeading2 = 'Helpful Tip';
  const enquiryTipContent2 = [
    {
      content: '1) Keep the number of enquiries low (not more than 2 - 3 in year).'
    },
    {
      content: '2) Don' + 't apply for loans or Credit Cards with multiple banks in a short span of time - space out your applications.'
    }
  ]

  function openPopUp() {
    setpaymetHistoryPopup(true)
  }

  return (
    <div>
      {failurePopUpModal ? (
        <FailurePopUp
          onClick={() => setFailurePopUpModal(false)}
          visible={failurePopUpModal}
          setFailureModalStat={setFailurePopUpModal}
          failurePopUpContent={failurePopUpContent}
        />
      ) : null}
      {successPopUpModal ? (
        <SuccessPopUp
          onClick={() => setSuccessPopUpModal(false)}
          visible={successPopUpModal}
          setSuccessModalStat={setSuccessPopUpModal}
          successPopUpContent={successPopUpContent}
        />
      ) : null}
      <div className='creditScore_main_div'>
        {/* <div className="bg-01-container"></div>
        <div className="bg-02-container"></div> */}
        <div className='personalDetailsLeftFlexBox'>
          <RadiantBorder >
            <div className='creditScoreLeftBoxContain'>
              <div>
                <p className='congrats_Text'>Congratulations!</p>
              </div>
              <div className='speedometer_Main_Div'>

                <div id="speedometer">
                  <div className="wrapper">
                    <div className="indicator-wrapper">
                      <div className="indicator-wrapper-inner">

                        <div className="needle" style={{ transform: `rotate(${degree}deg` }}>
                          <div className='polygon'></div>
                          <img src='assets/images/Polygon.svg' className='polygon_Img' style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} />
                        </div>
                        <div className='score'>
                          <p>{getCreditScore}</p>
                        </div>
                      </div>

                    </div>


                    <div className="bar bar-1"></div>
                    <div className="bar bar-2"></div>
                    <div className="bar bar-3"></div>
                    <div className="bar bar-4"></div>
                  </div>
                </div>
              </div>
              <div >
                <p className='excellent_Text'>{creditScoreStatus}</p>
              </div>

              <div className='credit_Score_Date_Div'>
                <div>
                  <p className='credit_Score_Update_Text'>Last Updated On</p>
                  <p className='date_Text'>{lastUpdatedOn}</p>
                </div>
                <div>
                  <p className='credit_Score_Update_Text'>Next Update On</p>
                  <p className='date_Text'>{nextUpdateOn}</p>
                </div>
              </div>

              <div className='note_Text_Div' >
                <p className='note_Text_Bold'>NOTE</p>
                <p className='checkCreditScoreText'>You will shortly receive your detailed bureau report on E-mail id as confirmed/provided by you.</p>
              </div>
            </div>
          </RadiantBorder>
        </div>
        <div className='accMainDiv' >
          <div className='accChildDiv' >
            <RadiantBorder className='creditScoreBorder'>
              <div className='creditScoreThemeDive'>
                <div className='accordian_Title_Div' onClick={() => { setAccordionActive(1); handleOpen() }}>
                  <p className='accordion_Head_Text'>TOTAL ACCOUNTS</p>
                  {accordionActive === 1 && open ? <img src='assets/images/arrow-up.svg' className='accordian_Arrow_Img' style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} /> :
                    <img src='assets/images/arrow-down.svg' className='accordian_Arrow_Img' style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} />}
                </div>
                <div style={{ transition: 'all .5s ease-in' }}>
                  <div style={{ display: (accordionActive === 1 && open ? 'block' : 'none'), margin: '0 auto', width: '95%' }}>
                    <p className='account-details-accordion-text'>The total count of your open and closed loan and Credit Cards.
                      Having a healthy mix of unsecured (Credit Card, Personal Loan)
                      and secured (Home Loan, Car Loan) credit lines demonstrates that lenders trust you.</p>

                    <div className="account-details-sub-accordion" onClick={() => { handleopensubacc() }}>
                      <div className="account-details-sub-accordion-content ">
                        <p >Account Details</p>
                        <p className="total-accounts">Total Accounts : {totalAccountObj.totalAccount}</p>
                        {subaccopen ? <img src='assets/images/arrow-down.svg' className='accordian_Arrow_Img sub-accordian' onClick={() => { handleopensubacc() }} style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} /> :
                          <img src='assets/images/arrow-right.svg' className='accordian_Arrow_Img  sub-accordian' onClick={() => { handleopensubacc() }} style={{ filter: `${themeState === THEME_DARK ? "" : "invert(1)"}` }} />}
                      </div>

                      {
                        subaccopen ? <div>
                          <div className='account-detail-section' id='scrollContent'>
                            <table className="account-detail-section-container">
                              <tbody className="account-detail-section-body">
                              <tr className="account-detail-section-heading">
                                <th>
                                  Account
                                </th>
                                <th>Lender</th>
                                <th>Account Type</th>
                                <th>Account No</th>
                                <th>Ownership</th>
                                <th>Date Reported</th>
                                <th>Account Status</th>
                                <th>Date Opened</th>
                                <th>Sanction Amt/Highest Credit</th>
                                <th>Current Balance</th>
                                <th>Amount Overdue</th>
                              </tr>
                              <CreditscoreAccountData data={totalAccountObj.accountdetailTable} />
                              </tbody>
                            </table>

                          </div>

                        </div>
                          : null

                      }
                    </div>
                  </div>
                </div>
              </div>
            </RadiantBorder>
          </div>
          <div className='accChildDiv' >
            <RadiantBorder className='creditScoreBorder'>
              <div className='creditScoreThemeDive' >
                <div className='accordian_Title_Div' onClick={() => { setAccordionActive(2); handleOpen1() }}>
                  <p className='accordion_Head_Text'>PAYMENTS HISTORY</p>
                  {accordionActive === 2 && open1 ? <img src='assets/images/arrow-up.svg' className='accordian_Arrow_Img' style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} /> :
                    <img src='assets/images/arrow-down.svg' className='accordian_Arrow_Img' style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} />}
                </div>
                <div className='credit-score-payment-history' style={{ display: (accordionActive === 2 && open1 ? 'block' : 'none') }}>
                  <p className='payment_history_accordion_Text'>The percentage of your Credit Card and loan EMI
                    payments that were made on time. Ensure that you always make your
                    Loan/Credit Card and EMI payments on or before the due date.</p>
                  {payemntHistoryArr.length > 0
                    ? <div className='payments-carousel'>
                      <div className="payment-swipe">
                        <img style={themeState === "THEME_DARK" ? { filter: "invert(1) " } : null} src="assets/images/left-vector-icon.svg" alt="" onClick={() => customSlider.current.slickPrev()} />
                        <p >
                          {activeSlideIndex + 1}/{payemntHistoryArr.length}
                        </p>
                        <img style={themeState === "THEME_DARK" ? { filter: "invert(1) " } : null} src="assets/images/right-vector-icon.svg" alt="" onClick={() => customSlider.current.slickNext()} />
                      </div>
                      <Slider {...settings}>
                        {
                          payemntHistoryArr.map((ele, index) => {
                            singleCard.ele = ele;
                            return (
                              <div key={index} className='payment-card'>
                                <div className='payment-data'>
                                  <div className='payment-title'>
                                    <p>Payment Percent</p>
                                    <p>Card No.</p>
                                    <p>Card Status</p>
                                    <p>Highest Credit</p>
                                    <p>Lender</p>
                                  </div>
                                  <div className='payment-values'>
                                    <p>{ele.paymentPercent}%</p>
                                    <p>{ele.Account_Number}</p>
                                    <p>{ele.ACCOUNT_STATUS_VALUE}</p>
                                    <p>{ele.Highest_Credit_or_Original_Loan_Amount}</p>
                                    <p>{ele.Subscriber_Name}</p>
                                  </div>
                                </div>
                                <div className='payment-seperator'></div>
                                <div className='payment-details-container'>
                                  <div className='payment-details'>
                                    <p onClick={openPopUp}>View Details</p>
                                    <img src="assets/images/arrow-right-blue.svg" alt="view details arrow" />
                                  </div>
                                </div>
                              </div>

                            )
                          })
                        }

                      </Slider>
                    </div> : ""
                  }
                  <div className='payment-tips'>
                    <p>Tips:</p>
                    <TipsComponent tipHeading={paymentTipHeading} tipContent={paymentTipContent} />
                  </div>

                  {
                    paymetHistoryPopup ? <PaymentHistoryPopup paymetHistoryPopup={paymetHistoryPopup} setpaymetHistoryPopup={setpaymetHistoryPopup} singleCard={singleCard} /> : ""
                  }
                </div>
              </div>
            </RadiantBorder>
          </div>
          <div className='accChildDiv' >
            <RadiantBorder className='creditScoreBorder'>
              <div className='creditScoreThemeDive'>
                <div className='accordian_Title_Div' onClick={() => { setAccordionActive(3); handleOpen2() }}>
                  <p className='accordion_Head_Text'>CREDIT ENQUIRIES</p>
                  {accordionActive === 3 && open2 ? <img src='assets/images/arrow-up.svg' className='accordian_Arrow_Img' style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} /> :
                    <img src='assets/images/arrow-down.svg' className='accordian_Arrow_Img' style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} />}
                </div>
                <div className='credit-score-enquiry' style={{ display: (accordionActive === 3 && open2 ? 'block' : 'none') }}>
                  <div className='credit_enquiry_accordion_Text'>
                    <p>Total hard enquiries. Hard enquiries are made by credit institutions
                      for lending decisions.
                      Enquiries made by you directly via Tata Capital are soft enquiries.</p>
                  </div>
                  <div className='credit-enquiry-emoji'>
                    <p className='total-enquires'>Total Number of Credit Enquiries in last 30 days:{enquiry.enquiriesInLast30Days}</p>
                    <img className='credit-enquiry-mood' src={`assets/images/${enquiry.feedback}.svg`} alt="enquiries mood" />
                    <p className={`credit-enquiry-feedback feedback-${enquiry.feedback}`}>{enquiry.feedback}</p>
                  </div>
                  <div className='enquiry-tips'>
                    <p>Tips:</p>
                    <TipsComponent tipHeading={enquiryTipHeading1} tipContent={enquiryTipContent1} />
                    <TipsComponent tipHeading={enquiryTipHeading2} tipContent={enquiryTipContent2} />
                  </div>
                </div>
              </div>
            </RadiantBorder>
          </div>
          <div className='accChildDiv' >
            <RadiantBorder className='creditScoreBorder'>
              <div className='creditScoreThemeDive'>
                <div className='accordian_Title_Div' onClick={() => { setAccordionActive(4); handleOpen3() }}>
                  <p className='accordion_Head_Text'>LEGENDS</p>
                  {accordionActive === 4 && open3 ? <img src='assets/images/arrow-up.svg' className='accordian_Arrow_Img' style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} /> :
                    <img src='assets/images/arrow-down.svg' className='accordian_Arrow_Img' style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }} />}
                </div>
                <div style={{ width: '95%', overflow: 'hidden', display: (accordionActive === 4 && open3 ? 'block' : 'none'), margin: '0 auto' }}>
                  <div className="legend-accordion-content">
                    <ul>
                      <li>
                        <p>
                          <span className="legend-accordion-content-heading">SF/WD/WO/SETT/LED: </span><span className="legend-accordion-content-desc">Credit Account That are Suit Field/Defualt/ Written Off/ Settied.</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span className="legend-accordion-content-heading">Active: </span><span className="legend-accordion-content-desc">Credit Account That are Suit Field/Defualt/ Written Off/ Settied.</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span className="legend-accordion-content-heading">Active: </span><span className="legend-accordion-content-desc">Credit accounts which are over 90 days past due.</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span className="legend-accordion-content-heading">Closed: </span><span className="legend-accordion-content-desc">Credit accounts that have 'Date Closed' Populated.</span>
                        </p>
                      </li>
                      <li>
                        <ol>
                          <p><span className="legend-accordion-content-heading">DPD: </span><span className="legend-accordion-content-desc">Days Past Due. Number of days that have passed from the agreed payment due date of EMI</span></p>
                          <li>
                            <p><span className="legend-accordion-content-heading">DPD '0': </span><span className="legend-accordion-content-desc">The Number '0' in the circle indicates that the Payment is made as agreed and the credit account is up to date.</span></p>
                          </li>
                          <li>
                            <p><span className="legend-accordion-content-heading">DPD &gt; '0': </span><span className="legend-accordion-content-desc">The Number in the circle indicates the 'Days Past Due' reported by respective lender.</span></p>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <ol>
                          <p className="legend-accordion-content-desc">
                            When the below alphabets are shown in the DPD table, it means the lender is reporting Asset Classification and indicates the Following:
                          </p>
                          <li>
                            <p>
                              <span className="legend-accordion-content-heading"> S : Standard: </span><span className="legend-accordion-content-desc">An account which is overdue for less than 90 days is considered as a Standard as per RBI guidlines.</span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="legend-accordion-content-heading"> M : Special Mention: </span><span className="legend-accordion-content-desc">These accounts are 'Standard' asstes but for some discrepancy the lending organization would like to monitor this account closely.</span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="legend-accordion-content-heading"> B : Substandard: </span><span className="legend-accordion-content-desc">An account which is overdue by 90 days for a period of less than or equal to 12 months is Classified as Substandard asset as per RBI guidlines.</span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="legend-accordion-content-heading"> D : Doubtful: </span><span className="legend-accordion-content-desc">An account which is overdue by 90 days for more than 12 months is classified as doubtful asset as per RBI guidlines.</span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="legend-accordion-content-heading"> L : Loss: </span><span className="legend-accordion-content-desc">An account where loss has been identified but the amount has not been Written off, wholly or partially is classified as Loss asset as per RBI guidlines.</span>
                            </p>
                          </li>
                        </ol>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </RadiantBorder>

          </div>
        </div>
      </div>
    </div>)
}

CreditScoreDisplay.propTypes = {
  creditScore : PropTypes.number,
}

export default CreditScoreDisplay