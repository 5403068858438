import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import CreditScore from "../../Shared/screens/CreditScore";
import Overview from "./screens/Overview";
import Profile from '../../Shared/screens/Profile'
import RetailAccountDetails from "../../Shared/screens/Retail-Account-Details";
import Login from "./screens/Login";
import PaymentPlanOfModal from "./modals/PaymentPlanOfModal/PaymentPlanOfModal";
// import CollectionLetterPdfDownload from "./components/CollectionLetterPdfDownload/CollectionLetterPdfDownload";

let routes = [
    {
        "path": "*",
        "element": <Navigate to="/login" />
    },
    {
        "path": "/login",
        "element": <Login />
        // "element" : <CollectionLetterPdfDownload />
    },
    {
        "path": "overview",
        "element": <Overview />
    },
    {
        "path": "profile",
        "element": <Profile portalType='collection' />
    },
    {
        "path": "account-details",
        "element": <RetailAccountDetails portalType='collection' />
    },
    {
        "path": "credit-score",
        "element": <CreditScore to="/credit-score" portalType='collection' />
    },
    {
        "path": "payment-plan",
        "element": <PaymentPlanOfModal />
    }

];

let Routes = () => useRoutes(routes);

export default Routes;