import React from 'react';
import './TipsComponent.scss'
import PropTypes from 'prop-types';
const TipsComponent = ({tipHeading , tipContent}) => {
  return (
      <div className="ta-info-msg credit-score-tips-label">
          <div className="Info-msg credit-score-tip">
              <div className="credit-score-tip-heading">
                  <p>{tipHeading}</p>
              </div>
              {tipContent.map((element,index)=><p key={index} className="credit-score-definitions">{element.content}</p>)}
          </div>

      </div>
  )
}

TipsComponent.propTypes = {
    tipHeading : PropTypes.string,
    tipContent : PropTypes.array
}

export default TipsComponent