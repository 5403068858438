import React, { useState } from 'react'
//styles
import './CreditscoreAccountData.scss'
import PropTypes from 'prop-types';

const CreditscoreAccountData = ({ data }) => {

    return (
        <>
            {
                data.map((ele,index) => {
                    return (
                        <tr className='account-detail-data-row' key={index}>
                            <td>Account {index + 1}</td>
                            <td>{ele.Subscriber_Name}</td>
                            <td>{ele.ACCOUNT_TYPE_VALUE}</td>
                            <td>{ele.Account_Number}</td>
                            <td>{ele.ACCOUNT_HOLDER_TYPE_NAME}</td>
                            <td>{ele.Date_Reported}</td>
                            <td className={ele.ACCOUNT_STATUS_VALUE === "ACTIVE" ? "active":"inactive"}>{ele.ACCOUNT_STATUS_VALUE}</td>
                            <td>{ele.Open_Date}</td>
                            <td>{ele.Highest_Credit_or_Original_Loan_Amount}</td>
                            <td>{ele.Current_Balance}</td>
                            <td>{ele.Amount_Past_Due}</td>
                        </tr>
                    )
                })
            }


        </>
    )
}

CreditscoreAccountData.propTypes = {
    data : PropTypes.array
}

export default CreditscoreAccountData