import React, { useContext, useEffect, useState } from "react";
import "./OptingSettlementModal.scss";
import Button from "../../FormFields/Button/Button";
import Input from "../../FormFields/Input";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import LoginServiceObject from "../../../../Retail/src/services/Login";
function OptingSettlementModal({
  setPopOverFlag,
  setPayNowFlag,
  setIsChecked,
  setPaymentSummeryFlag,
  setAttemptFlag,
  setPaymentFlag,
  handleClickForPaymentOpt
}) {
  const [showInput, setShowInput] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [contextData, dispatchData] = useContext(DataMapping);
  const [otherReasonText, setOtherReasonText] = useState("");
  const [otherOptionValid, setOtherOptionValid] = useState(false);
  const [showSelectOptionValid, setShowSelectOptionValid] = useState(false);

  const handleClick = () => {

    let selectedOptionDetails = selectedOptions.join(',');

    let reasonsDetails = {
      reasons: selectedOptionDetails,
      otherReasonText: otherReasonText,
    };

    handleClickForPaymentOpt(
      "handleReasonSelectionData",
      "",
      reasonsDetails,
      "",
      contextData.currentActiveData.accountNumber
    );

    // console.log(selectedOptions, "selectedOptions");
    // console.log(otherReasonText, "otherReasonText");
    if (selectedOptions.length > 0) {
      setShowSelectOptionValid(false);
      const includesOther = selectedOptions.includes("Other");
      if (includesOther && otherReasonText.length === 0) {
        setOtherOptionValid(true);
        // alert("Please enter reason as you have select other option");
      } else {
        setOtherOptionValid(false);
        setPaymentSummeryFlag(true);
        sessionStorage.setItem("reason-accept-flag", true);
        dispatchData({
          name: "offerModaLFlag",
          payload: {
            modalFlag: true,
            accountType: "paymentPlanModal",
          },
          type: STOREDATA,
        });
      }
    } else if (selectedOptions.length == 0) {
      setShowSelectOptionValid(true);
    }
  };

  const handleSelectedOption = (optionValue) => {
    // console.log(selectedOptions, "selectedOptionsData");

    const currentIndex = selectedOptions.indexOf(optionValue);
    const newSelectedOptions = [...selectedOptions];
    if (currentIndex === -1) {
      newSelectedOptions.push(optionValue);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }
    setSelectedOptions(newSelectedOptions);
    if (newSelectedOptions.length > 0) {
      setShowSelectOptionValid(false);
    }
    // console.log(newSelectedOptions, "newSelectedOptions");
    if (newSelectedOptions.includes("Other")) {
      setShowInput(true);
      // setOtherReasonText("");
    } else if (!newSelectedOptions.includes("Other")) {
      setShowInput(false);
      setOtherOptionValid(false);
      setOtherReasonText("");
    }
  };

  const options = [
    { value: "Job Loss", id: "opting-settlement-1" },
    { value: "Business Loss", id: "opting-settlement-2" },
    { value: "Medical Emergency", id: "opting-settlement-3" },
    { value: "Financial Stress", id: "opting-settlement-4" },
    { value: "Other", id: "opting-settlement-5" },
  ];

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });

  const handleCrossClick = (modalType, flag) => {
    // sessionStorage.setItem("settlement-mid-journey", "true");
    setPopOverFlag(false);
    setPayNowFlag(false);
    sessionStorage.setItem("reason-reject-flag", true);

    dispatchData({
      name: "offerModaLFlag",
      payload: {
        modalFlag: false,
        accountType: "optingSettlementModal",
      },
      type: STOREDATA,
    });
  };

  const handleOtherReasonText = (e) => {
    if (e.target.value.length > 0) {
      setOtherOptionValid(false);
    } else if (e.target.value.length == 0) {
      setOtherOptionValid(true);
    }
    setOtherReasonText(e.target.value);
  };

  return (
    <div className="c_opting_settlement_modal_wrp">
      <div className="c_opting_settlement_modal">
        <img
          src="assets/images/gray-cross.svg"
          alt="gray-cross"
          className="c_opting_settlement_modal_close"
          onClick={() => handleCrossClick("availOfferModal", false)}
        />
        <div className="c_opting_settlement_modal_img_wrp">
          <img
            src="assets/images/c-question-ciricle-icon.svg"
            alt="c-question-ciricle-icon"
            width="65px"
            height="66px"
          />
        </div>
        <h2 className="c_opting_settlement_modal_title">
          Why are you opting for settlement?
        </h2>
        <div className="c_opting_settlement_modal_radio">
          {options.map((option, index) => (
            <div
              className="c_opting_settlement_modal_radio_input"
              key={option.id}
            >
              <input
                type="checkbox"
                id={option.id}
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleSelectedOption(option.value)}
              />
              <label htmlFor={option.id}>{option.value}</label>
            </div>
          ))}
        </div>
        <div className="c_opting_settlement_modal_input_box">
          {showInput && (
            <Input
              type="text"
              placeholder="Please enter your reason here"
              className="c_opting_settlement_modal_input"
              onChange={(e) => handleOtherReasonText(e)}
            />
          )}
        </div>
        <div className="c_opting_settlement_modal_input_error">
          {otherOptionValid && (
            <div className="other-reason-validation_text">
              Please enter a reason for opting other option !
            </div>
          )}
        </div>
        <div className="c_opting_settlement_modal_input_option_error">
          {showSelectOptionValid && (
            <div className="other-reason-validation_text">
              Please select any above option !
            </div>
          )}
        </div>
        <Button
          className="c_opting_settlement_modal_btn"
          onClick={() => handleClick()}
        >
          Submit
          <img src="assets/images/vector.svg" alt="vector" />
        </Button>
      </div>
    </div>
  );
}

export default OptingSettlementModal;
