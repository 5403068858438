import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const userSessionAlive = (data) => {
    let requestJson = {
        "header": {
            "authToken": data,
            "reset": true
        },
        "body": {}
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/user/user-session-alive/v1', requestJson).then(response => {
        stalwartApiCall('POST', requestJson, '/shaft/api/user/user-session-alive/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default userSessionAlive;