import React, { useContext } from "react";
import "./ProfilePasswordChange.scss";
import RadiantBorder from "../../global/RadiantBorder";
import Searchbar from "../Searchbar";
import Button from "../../global/Button";
import { useState } from "react";
import Modal from "../../global/Modal";
import Input from "../../global/Input";
import ProfileServiceObject from "../../services/Profile";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
import { useNavigate } from "react-router-dom";
import DataMapping from "../../../../Shared/context/data-mapping";
import AesUtil from "../../utilities/AesUtil";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import PropTypes from 'prop-types';

const ProfilePasswordChange = ({ isMobile, setIsActive }) => {
  const [data, dispatchData] = useContext(DataMapping)
  const handleMobileClick = () => {
    if (!isMobile) {
      return;
    } else {
      setIsActive({
        profile: false,
        bank: false,
        password: false,
        help: false,
        branch: false,
        terms: false,
        about: false,
      });
    }
  };
  const [passwordState, setPasswordState] = useState({
    currentPass: {
      value: "",
      error: "",
    },
    newPass: {
      value: "",
      error: "",
    },
    reNewPass: {
      value: "",
      error: "",
    },
  });
  const [modalState, setModalState] = useState(false);
  const capitalLetterRegex = /[A-Z]/g;
  const smallLetterRegex = /[a-z]/g;
  const numberRegex = /[0-9]/g;
  const specialCharRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const [password, setPassword] = useState("");
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    "apiName": "",
    "message": "",
    "errorCode": ""
  })
  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");
  const navigate = useNavigate();
  const [changePasswordObject, setChangePasswordObject] = useState({
    currentPass: {
      value: "",
      error: ""
    },
    newPass: {
      value: "",
      error: ""
    },
    reEnterPass: {
      value: "",
      error: ""
    },
  })

  const validation = () => {
    const passwordRegex = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/;
    let count = 0
    if (changePasswordObject.currentPass.value === "") {
      count += 1
      setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.currentPass.error = "Please Enter Current Password" })
    }
    if (changePasswordObject.newPass.value === "") {
      count += 1
      setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.newPass.error = "Please Enter New Password" })
    } else if (!passwordRegex.test(changePasswordObject.newPass.value)) {
      count += 1
      setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.newPass.error = "Password Pattern is Invalid" })
    } else {

    }

    if (changePasswordObject.reEnterPass.value === "") {
      count += 1
      setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.reEnterPass.error = "Please Re-enter the New Password" })
    } else if (changePasswordObject.newPass.value !== changePasswordObject.reEnterPass.value) {
      count += 1
      setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.reEnterPass.error = "Password Must Match With New Password" })
    } else {

    }

    if (count === 0) {
      return true
    } else {
      return false
    }

  }

  // const validation = () => {
  //   const passwordRegex = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/;
  //   if (passwordState.currentPass.value === "") {
  //     setPasswordState((prev) => ({
  //       ...prev,
  //       currentPass: {
  //         value: "",
  //         error: "Please Enter Valid Password",
  //       },
  //     }));
  //   } else {
  //     setPasswordState((prev) => ({
  //       ...prev,
  //       currentPass: {
  //         value: passwordState.currentPass.value,
  //         error: "",
  //       },
  //     }));
  //   }
  //   if (passwordState.newPass.value === "") {
  //     setPasswordState((prev) => ({
  //       ...prev,
  //       newPass: {
  //         value: "",
  //         error: "This Field Cannot Be Left Blank",
  //       },
  //     }));
  //   }
  //   else if (!passwordRegex.test(passwordState.newPass.value)) {
  //     setPasswordState((prev) => ({
  //       ...prev,
  //       newPass: {
  //         value: passwordState.newPass.value,
  //         error: "Password Pattern is Invalid",
  //       },
  //     }));
  //   } else {
  //     setPasswordState((prev) => ({
  //       ...prev,
  //       newPass: {
  //         value: passwordState.newPass.value,
  //         error: "",
  //       },
  //     }));
  //   }
  //   if (passwordState.reNewPass.value === "") {
  //     setPasswordState((prev) => ({
  //       ...prev,
  //       reNewPass: {
  //         value: "",
  //         error: "Password Must Match With New Password",
  //       },
  //     }));
  //   } else if (passwordState.newPass.value !== passwordState.reNewPass.value) {
  //     setPasswordState((prev) => ({
  //       ...prev,
  //       reNewPass: {
  //         value: passwordState.reNewPass.value,
  //         error: "Entered password does not match with new password",
  //       },
  //     }));
  //   }
  //   else {
  //     setPasswordState((prev) => ({
  //       ...prev,
  //       reNewPass: {
  //         value: passwordState.reNewPass.value,
  //         error: "",
  //       },
  //     }));
  //   }

  //   let count = 0
  //   Object.keys(passwordState).forEach(keys => {
  //     if (passwordState[keys].error !== "") {
  //       count += 1
  //     }
  //   })
  //   if (count > 0) {
  //     return false
  //   }
  //   else return true

  // }
  const validatePassword = () => {
    var currentcontract = data?.currentActiveData.accountNumber
    var currentUserData = data?.parsedUserData[currentcontract]
    if (validation()) {
      var reqObj = {
        "header": {
          "authToken": data?.authToken,
          "firstLogin": false
        },
        "body": {
          "userId": data?.getUserDataV3?.body?.PRIMARY_GCID,
          "password": AesUtil.encryptData(changePasswordObject.currentPass.value),
          "newPassword": AesUtil.encryptData(changePasswordObject.newPass.value),
          "confirmPassword": AesUtil.encryptData(changePasswordObject.reEnterPass.value)
        }
      }

      dispatchData({
        name: "loaderFlag",
        type: STOREDATA,
        payload: true
      })
      ProfileServiceObject.changePasswordService(reqObj).then((changePasswordResponse) => {
        setModalState(true)
        dispatchData({
          name: "loaderFlag",
          type: STOREDATA,
          payload: false
        })
      }).catch((error) => {
        if (error === "current password is incorrect") {
          setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.currentPass.error = "Current Password is Incorrect" })

        } else {
          setFailurePopUpContent({
            apiName: "change-password/v1",
            message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            errorCode: error
          })
          setFailurePopUpModal(true)
        }
        dispatchData({
          name: "loaderFlag",
          type: STOREDATA,
          payload: false
        })
      })
    } else {

    }


    // if (
    //   passwordState.currentPass.error === "" &&
    //   passwordState.newPass.error === "" &&
    //   passwordState.reNewPass.error === ""
    // ) {
    //   setModalState(true);
    // }
  };
  const handleInputChangeCurrentPass = (e) => {
    setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.currentPass.value = e.target.value })
    setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.currentPass.error = "" })
  };
  const handleInputChangeNewPass = (e) => {
    setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.newPass.value = e.target.value })
    setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.newPass.error = "" })

  };
  const handleInputChangeReNewPass = (e) => {
    setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.reEnterPass.value = e.target.value })
    setChangePasswordObject({ ...changePasswordObject, ...changePasswordObject.reEnterPass.error = "" })

  };

  const handleChange = (e) => {
    setPassword(e.target.value)
    handleInputChangeNewPass(e)
  }

  return (
    <RadiantBorder>
      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
      {successPopUpModal ? <SuccessPopUp
        onClick={() => setSuccessPopUpModal(false)}
        visible={successPopUpModal}
        setSuccessModalStat={setSuccessPopUpModal}
        successPopUpContent={successPopUpContent}
      /> : null}
      {modalState ? <Modal>
        <div className='password-modal-popup'>
          <img src={"assets/images/crossImg.svg"} alt="close" className="password-close-icon" onClick={() => navigate("/login")} />
          <div className='description'>
            <p>Your password has been changed successfully.</p>
            <p>Due to security reason we recommend you to log out and log in again
              with the new
              password.</p>
          </div>
          <div className='proceed' onClick={() => navigate("/login")}>
            <a href='javascript:void(0)'>OK</a>
          </div>
        </div>
      </Modal> : null}
      <div className="change-password-container">
        <p className="change-password-heading" onClick={handleMobileClick}>
          {isMobile && (
            <img
              src="assets/images/backArrow.svg"
              alt="back-arrow"
              className="back-arrow-image"
            />
          )}
          Change Password
        </p>
        <div className="password-tips-container">
          <p>Strong Password tips</p>
          <div className="password-tips">
            <img src="assets/images/greenTickMark.svg" />
            <p>
              You are requested to select a word that is not available in an
              English Dictionary and is not easily guessable.
            </p>
          </div>
          <div className="password-tips">
            <img src="assets/images/greenTickMark.svg" />
            <p>
              Do not assign your name, your family or vehicle number as your
              password as it can be easily guessed.
            </p>
          </div>
          <div className="password-tips">
            <img src="assets/images/greenTickMark.svg" />
              <ul className="password-tip-list">
                Password must contain
                <li>At least eight character</li>
                <li>At least one uppercase letter</li>
                <li>At least one numeric value</li>
              </ul>
          </div>
        </div>
        <div className="password-label-input-container bot-margin">
          <div className="change-password-label">
            <p>Current Password</p>
          </div>
          <div className="input-validation-container">
            <Input
              onChange={handleInputChangeCurrentPass}
              type={"password"}
              placeholder={"Enter Curent Password"}
              className="change-password-ui-inputs change-password-input"
              error={changePasswordObject.currentPass.error}
            />
          </div>
        </div>
        {/* <div className="password-label-input-container auth"> */}
        <div className="password-label-input-container new-password">
          <div className="change-password-label">
            <p>New Password</p>
          </div>
          <div className="input-validation-container">
            <Input
              onChange={handleInputChangeNewPass}
              type={"password"}
              placeholder={"Enter new Password"}
              className="change-password-ui-inputs change-password-input"
              error={changePasswordObject.newPass.error}
            />
            {/* <div className="validation-image-container">
              {passwordState.newPass.value.match(capitalLetterRegex) ? (
                <img
                  src="assets/images/passCapsAuth.svg"
                  alt="password-auth"
                  className="validation-image image-1"
                />
              ) : (
                <img
                  src="assets/images/passCapsAuth.svg"
                  alt="password-auth"
                  className="validation-image image-1"
                />
              )}
              {passwordState.newPass.value.match(smallLetterRegex) ? (
                <img
                  src="assets/images/passSmallAuth.svg"
                  alt="password-auth"
                  className="validation-image image-2"
                />
              ) : (
                <img
                  src="assets/images/passSmallAuth.svg"
                  alt="password-auth"
                  className="validation-image image-2"
                />
              )}
              {passwordState.newPass.value.match(numberRegex) ? (
                <img
                  src="assets/images/passNumAuth.svg"
                  alt="password-auth"
                  className="validation-image image-3"
                />
              ) : (
                <img
                  src="assets/images/passNumAuth.svg"
                  alt="password-auth"
                  className="validation-image image-3"
                />
              )}
              {passwordState.newPass.value.match(specialCharRegex) ? (
                <img
                  src="assets/images/passSpecialAuth.svg"
                  alt="password-auth"
                  className="validation-image image-4"
                />
              ) : (
                <img
                  src="assets/images/passSpecialAuth.svg"
                  alt="password-auth"
                  className="validation-image image-4"
                />
              )}
            </div> */}
            <div className="enter-password-container-verification">
              {changePasswordObject.newPass.value.match(capitalLetterRegex) ? (
                <div className="enter-password-container-verified">
                  <img src={"assets/images/greenVerify.svg"} alt="greenVerify" />
                  <span>A-Z</span>
                </div>
              ) : (

                <div className="enter-password-container-verification-process">
                  <img src={"assets/images/greyVerify.svg"} alt="greyVerify" />
                  <span>A-Z</span>
                </div>
              )}

              {!changePasswordObject.newPass.value.match(smallLetterRegex) ? (
                <div className="enter-password-container-verification-process">
                  <img src={"assets/images/greyVerify.svg"} alt="greyVerify" />
                  <span>a-z</span>
                </div>
              ) : (
                <div className="enter-password-container-verified">
                  <img src={"assets/images/greenVerify.svg"} alt="greenVerify" />
                  <span>a-z</span>
                </div>
              )}

              {!changePasswordObject.newPass.value.match(numberRegex) ? (
                <div className="enter-password-container-verification-process">
                  <img src={"assets/images/greyVerify.svg"} alt="greyVerify" />
                  <span>1-9</span>
                </div>
              ) : (
                <div className="enter-password-container-verified">
                  <img src={"assets/images/greenVerify.svg"} alt="greenVerify" />
                  <span>1-9</span>
                </div>
              )}
              {!changePasswordObject.newPass.value.match(specialCharRegex) ? (
                <div className="enter-password-container-verification-process">
                  <img src={"assets/images/greyVerify.svg"} alt="greyVerify" />
                  <span>..!@?</span>
                </div>
              ) : (
                <div className="enter-password-container-verified">
                  <img src={"assets/images/greenVerify.svg"} alt="greenVerify" />
                  <span>..!@?</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className="password-authentication-container">
          <img src="assets/images/passCapsAuth.svg" alt="password-auth" />
          <img src="assets/images/passSmallAuth.svg" alt="password-auth" />
          <img src="assets/images/passNumAuth.svg" alt="password-auth" />
          <img src="assets/images/passSpecialAuth.svg" alt="password-auth" />
        </div> */}
        {/* <div className="password-label-input-container">
          <p className="password-new-pass">New Password</p>
          <div className="password-input-auth-container">
            <Searchbar
              placeholder={"Enter New Password"}
              className="change-pass-search-bar-auth"
            />
            
          </div>
         
        </div> */}
        <div className="password-label-input-container">
          <div className="change-password-label">
            <p>Re-enter New Password</p>
          </div>
          <div className="input-validation-container">
            <Input
              onChange={handleInputChangeReNewPass}
              type={"password"}
              placeholder={"Re-enter New Password"}
              className="change-password-ui-inputs change-password-input"
              error={changePasswordObject.reEnterPass.error}
            />
          </div>
        </div>
        <div className="password-change-button-container">
          <Button onClick={validatePassword} className="password-change-button">
            Submit
          </Button>
        </div>
      </div>
    </RadiantBorder>
  );
};

ProfilePasswordChange.propTypes = {
  isMobile: PropTypes.bool,
  setIsActive: PropTypes.func
}

export default ProfilePasswordChange;
