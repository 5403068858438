import React from "react";
import './PaymentCard.scss';
import RadioButton from "../../FormFields/RadioButton/RadioButton";
import Input from "../../FormFields/Input/Input";
import PropTypes from 'prop-types';

const PaymentCard = ({ isInput, handleActive, labelName = "", amount, isActive = false, index, disabled, onChange}) => {
    return (
        <div className={`c_payment_card ${isActive ? 'c_payment_div_css' : ''}`} onClick={() => { !disabled && handleActive(index) }}>
            <div className={`c_radio_button_div ${disabled ? "disabled" : ""}`}>
                <RadioButton type="radio" className="c_radio_button" name={"paymentOptions"} id={labelName} checked={isActive} disabled={disabled} onChange={() => {}}/>
                {isInput ? <Input type="number" isActive={isActive} inputType="withUnit" unit="₹" amount={amount} onChange={onChange} className={`c_with_unit_input`} id="c_total_overdue_amount" label={labelName} value={amount} /> : <div className="c_payment_card_div"><p className={`c_payment_card_amount_text ${isActive && 'c_payment_card_amount_text c_black_active'}`}>{labelName}</p><p className="c_payment_card_amount">₹ {Number(amount).toLocaleString('en-IN')}</p></div>}
            </div>
        </div >
    )
}

PaymentCard.propTypes = {
    id: PropTypes.string,
    labelName: PropTypes.string,
    selectPaymentCard: PropTypes.func,
    placeholder: PropTypes.string,
    isInput: PropTypes.bool,
    handleActive: PropTypes.func,
    amount: PropTypes.string,
    isActive: PropTypes.bool,
    index:PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
}


export default PaymentCard;