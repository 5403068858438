import React, { useState, useEffect, useRef, useContext } from 'react'
import './otp.scss';
import Input from '../../FormFields/Input';
import { useNavigate } from 'react-router-dom';
import LoginServiceObject from '../../../../Retail/src/services/Login';
import { maskDetails } from '../../services/maskDetails';
import { generateOtp } from '../../services/generateOtp';
import { checkUserValid } from '../../services/checkUserValid';
import { useCookies } from 'react-cookie';
import { STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type';
import { dispacthUserData } from '../../services/dispatchUserData';
import DataMapping from '../../../../Shared/context/data-mapping';
import PropTypes from "prop-types";

const OTP_ARR = new Array(6).fill(0);

const OtpComponent = ({
    loginTab,
    otpDetails,
    userContext,
    loginTabForm,
    forgotForm,
    forgotDetails,
    recieveOtp,
    setOtpDetails,
    setCounter,
    setForgotForm,
    setNewId,
    otpTimerFlag,
    setFailurePopUpModal,
    setFailurePopUpContent

}) => {
    const inputEl = useRef();
    const navigate = useNavigate();
    const [resendLimit, setResendLimit] = useState(0)
    const [otpCounter, setOtpCounter] = useState(0);
    const [counterFlag, setCounterFlag] = useState(false);
    const [otpTimer, setOtpTimer] = useState(30);
    const [voiceOtpTimer, setVoiceOtpTimer] = useState(40);
    const [otpEnterFlag, setOtpEnterFlag] = useState(false)
    const [showErrorMsg, setShowErrorMsg] = useState('');
    const [otpNumber, setOtpNumber] = useState({
        otp0: "",
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
    });

    const [, dispatchData] = useContext(DataMapping);

    const getOtpValue = () => {
        return `${otpNumber.otp0}${otpNumber.otp1}${otpNumber.otp2}${otpNumber.otp3}${otpNumber.otp4}${otpNumber.otp5}`;
    }

    const [, setCookie] = useCookies();

    let maskedDetails
    if (loginTab == "mobNum" || loginTab == "emailId") {
        maskedDetails = maskDetails(loginTabForm.value.fieldOne)
    } else if (loginTab == "forgotPass") {
        maskedDetails = maskDetails(recieveOtp)
    } else {
        maskedDetails = maskDetails(forgotForm.field.value)
    }

    let otpContext = maskedDetails.context == "mobile" ? "Mobile Number" : "Email ID";

    const handleChange = (e,) => {
        let { value, name } = e.target;
        let validation_num = /^\d$/;
        if (validation_num.test(value)) {
            setOtpEnterFlag(() => true)
            setOtpNumber({
                ...otpNumber,
                [name]: value
            })
        } else {
            if (value == "") {
                setOtpEnterFlag(() => true)
                setOtpNumber({
                    ...otpNumber,
                    [name]: value
                })
            }
        }
    }

    useEffect(() => {
        let count = 0;
        for (let key in otpNumber) {
            if (otpNumber[key] !== "") {
                count++;
            }
        }
        if (count === 6) {
            handleClick()
        }
    }, [otpNumber])

    const handleKeyUp = (e) => {
        if (e.keyCode === 8) {
            setShowErrorMsg(() => '');
            if (otpCounter >= 1) {
                if (otpCounter === 6) {
                    setOtpCounter(el => el - 2);
                } else {
                    setOtpCounter(el => el - 1);
                }
            }
        } else {
            if (otpCounter <= 5 && otpEnterFlag) {
                setOtpCounter(ele => ele + 1);
                setOtpEnterFlag(() => false)

            }
        }
    }

    const handleClickOpt = () => {
        for (let key in otpNumber) {
            if (otpNumber[key] === "") {
                setCounterFlag(!counterFlag)
                setOtpCounter((Number(key.slice(-1))))
                break;
            }
        }
    }

    const getUserIdApiCall = () => {
        LoginServiceObject.getUserIdService(otpDetails.otpAuthToken, forgotForm.field.value).then((response) => {
            if (response.header.status == "SUCCESS") {
                setNewId(response.body?.PRIMARY_GCID)
                setCounter((prev) => prev + 1)
                setForgotForm({ field: { value: "", error: "" } })
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }
        }).catch(() => {
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
        })
    }

    useEffect(() => {
        if (otpCounter <= 5) {
            inputEl.current.focus();
        }
    }, [otpCounter, counterFlag])


    useEffect(() => {
        if (resendLimit === 3) {
            setCounter(0)
            navigate("/login")
        }
    }, [resendLimit])


    const handleClick = () => {

        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })

        switch (loginTab) {
            case "mobNum":
            case "emailId":
                validateOtpApiCall(loginTabForm.value.fieldOne).then((response) => {
                    const { retStatus } = response.body;
                    const { authToken } = response.header;
                    if (retStatus == "SUCCESS") {
                        setCookie('Authorization', authToken, { path: "/" });
                        LoginServiceObject.getUserDataV3Service(authToken).then(async(userDataResponse) => {
                            // console.log("userDataResponse",userDataResponse)
                            if (userDataResponse.header.status === 'SUCCESS') {
                                const checkCcod = userDataResponse.body.otherSystemDetails.some(({ SYSTEM_NAME, ccodContractDetails = [] }) => {
                                    return SYSTEM_NAME.toLowerCase() === "ccod" && ccodContractDetails.length > 0;
                                });
                                if (userDataResponse.body.contracts.length > 0 || checkCcod) {
                                    if (checkUserValid(userDataResponse)) {
                                        let getFinalData = '';
                                        let getOfferData = '';
                                        getFinalData = LoginServiceObject.commonLoginAfterUserData(userDataResponse, loginTab, loginTabForm.value.fieldOne,"collection");
                                        // console.log(getFinalData,"getFinalDataa");
                                        //get collection offer api call
                                        const retailContractNumbers = [];
                                        // Collect retail contract numbers
                                        userDataResponse?.body?.contracts.forEach((item) => {
                                          if (item?.system === "retail") {
                                            retailContractNumbers.push(item.CONTRACT_NUMBER);
                                          }
                                        });
                                        getOfferData =await LoginServiceObject.offerCollectionDetails(authToken, retailContractNumbers).then((offerDataResponse)=>{
                                            // console.log(offerDataResponse,"offerDataResponse");

                                            return offerDataResponse
                                        })
                                        // console.log(getOfferData,"getOfferData");
                                        dispacthUserData(dispatchData, userDataResponse, getFinalData,getOfferData)
                                        LoginServiceObject.userLoginTypeService(authToken).then((resp) => {
                                            if (resp.header.status === "SUCCESS") {
                                                dispatchData({
                                                    name: "setLoginType",
                                                    payload: resp.body.loginType,
                                                    type: STOREDATA
                                                })
                                            }
                                        })
                                        navigate("/overview");
                                        dispatchData({
                                            name: "authToken",
                                            payload: response.header.authToken,
                                            type: STOREDATA
                                        })
                                        dispatchData({
                                            name: "loaderFlag",
                                            payload: false,
                                            type: STOREDATA
                                        })
                                    } else {
                                        console.error("Not a valid user");
                                        dispatchData({
                                            name: "loaderFlag",
                                            payload: false,
                                            type: STOREDATA
                                        })
                                    }
                                } else {
                                    console.log("hellooooo");
                                    setFailurePopUpContent({
                                        apiName: "",
                                        message: <>Sorry, currently this service is not available. Please login to <a href='https://retailonline.tatacapital.com/#/login' target='_blank'>retailonline.tatacapital.com</a></>
                                    })
                                    setFailurePopUpModal(true);
                                    setCounter(prev => prev - 1);
                                    dispatchData({
                                        name: "loaderFlag",
                                        payload: false,
                                        type: STOREDATA
                                    })
                                }
                            }
                        })
                    }
                }).catch((error) => {
                    handleCatchError(error)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                });
                break;
            case "forgotId":
                validateOtpApiCall(loginTabForm.value.fieldOne).then((response) => {
                    const { retStatus } = response.body
                    if (retStatus == "SUCCESS") {
                        getUserIdApiCall()
                    } else {
                        dispatchData({
                            name: "authToken",
                            payload: response.header.authToken,
                            type: STOREDATA
                        })
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    }
                }).catch((error) => {
                    handleCatchError(error)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                })
                break
            case "forgotPass":
                validateOtpApiCall(userContext == "mobNum" ? forgotDetails.mobile : forgotDetails.email).then((response) => {
                    if (loginTab == "forgotPass") {
                        dispatchData({
                            name: "authToken",
                            payload: response.header.authToken,
                            type: STOREDATA
                        })
                        dispatchData({
                            name: "authToken",
                            payload: response.header.authToken,
                            type: STOREDATA
                        })
                        setCounter((prev) => prev + 1);
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    }
                }).catch((error) => {
                    handleCatchError(error)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                })
                break
            default:
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                break;
        }
    }

    const handleCatchError = (error) => {
        if (error === "03") {
            setFailurePopUpContent({
                apiName: "verify-otp/v1",
                message: "We are experiencing technical difficulty in OTP services. Please try after sometime",
                errorCode: error
            })
            setFailurePopUpModal(true)
        } else if (error === "otp invalid") {
            setShowErrorMsg("Incorrect OTP Entered")
        } else {
            setFailurePopUpContent({
                apiName: "verify-otp/v1",
                message: "We are experiencing technical difficulty in OTP services. Please try after sometime",
                errorCode: ""
            })
            setFailurePopUpModal(true)
        }
        dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA
        })
    }

    //validateOtp
    const validateOtpApiCall = (fieldValue) => {
        return new Promise((resolve, reject) => {
            LoginServiceObject.validateOtpService(otpDetails.otpAuthToken, getOtpValue(), otpDetails.otpRef, fieldValue)
                .then((validateOtpResponse) => {
                    resolve(validateOtpResponse)
                }).catch((error) => {
                    reject(error)
            })
        })
    }

    const handleOtpClick = () => {
        setOtpNumber({
            otp0: "",
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
        })

        setShowErrorMsg(() => "");

        if (otpTimer === 0) {
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            setResendLimit(prev => prev + 1)
            let callValue;
            if (loginTab == "emailId") {
                callValue = loginTabForm.value.fieldOne
            } else if (loginTab == "forgotPass") {
                callValue = recieveOtp
            } else {
                callValue = forgotForm.field.value
            }
            generateOtp("emailId", callValue, "", "", "forgotJourney").then((res) => {
                if (res.body.retStatus == "SUCCESS") {
                    setOtpDetails(() => {
                        return {
                            otpRef: res.body.otpRefNo,
                            otpAuthToken: res.header.authToken,
                        };
                    })
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                    setOtpTimer(30)
                }
            })
        }
    }


    useEffect(() => {
        // otp
        if (otpTimerFlag) {
            const timerInterval = setInterval(() => {
                setOtpTimer(old => old - 1)
            }, 1000)

            if (otpTimer === 0) {
                clearInterval(timerInterval)
            }
            return () => clearInterval(timerInterval)
        }
    }, [otpTimer])



    useEffect(() => {
        // voiceotp

        if (otpTimerFlag) {

            const timerInterval = setInterval(() => {
                setVoiceOtpTimer(old => old - 1)
            }, 1000)
            let callValue
            if (loginTab == "mobNum") {
                callValue = loginTabForm.value.fieldOne
            } else if (loginTab == "forgotPass") {
                callValue = recieveOtp
            } else {
                callValue = forgotForm.field.value
            }

            if (voiceOtpTimer === 0) {
                clearInterval(timerInterval)
                //call voice otp

                LoginServiceObject.generateVoiceOtpService(otpDetails.otpAuthToken, callValue, otpDetails.otpRef)
                    .then(() => {
                        setOtpTimer(30);
                        setShowErrorMsg(() => '');
                    }).catch((error) => {
                        setOtpTimer(30);
                        setShowErrorMsg(() => '');
                        if (error.toLowerCase() === "number of attempts exceeded") {
                            setFailurePopUpContent({
                                apiName: "",
                                message: "Please login with User ID and Password.",
                                errorCode: ""
                            })
                            setFailurePopUpModal(true)
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA
                            })
                        }
                        else {
                            setFailurePopUpContent({
                                apiName: "generate-voice-otp/v1",
                                message: "We are currently facing error in Call OTP services. Please try again later",
                                errorCode: error
                            })
                            setFailurePopUpModal(true);
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA
                            })
                        }
                    }).finally(() => {
                        console.log("finally called");
                        setOtpNumber(() => {
                            return {
                                otp0: "",
                                otp1: "",
                                otp2: "",
                                otp3: "",
                                otp4: "",
                                otp5: "",
                            }
                        })
                    })
            }
            return () => clearInterval(timerInterval)
        }


    }, [voiceOtpTimer])

    return (
        <>
            <div className='c_otp_wrap'>
                <div className="c_back_btn" onClick={() => setCounter(prevState => prevState - 1)}><img src='assets/images/arrow_back.svg' /><p>Back</p></div>
                <div className='c_otp_body'>
                    <p className='c_otp_title'>Enter OTP</p>
                    <p className='c_otp_detail'>
                        We’ve sent a 6 digit OTP to your registered {otpContext} {maskedDetails.encryptedValue}
                    </p>
                    <p className='c_otp_valid'>OTP Valid for <span>{otpTimer} secs</span></p>
                    <div className='c_otp_input'>
                        {
                            OTP_ARR.map((_, i) => <Input
                                type='password'
                                value={otpNumber[`otp${i}`]}
                                name={`otp${i}`}
                                onKeyUp={(e) => handleKeyUp(e)}
                                onChange={(e) => handleChange(e)}
                                onClick={() => handleClickOpt()}
                                ref={i == otpCounter ? inputEl : null}
                                maxLength={1}
                                minLength={1}
                                className='single_otp'
                                key={i}
                            />)
                        }
                    </div>
                    <p className='error'>{showErrorMsg}</p>
                    {maskedDetails.context == "mobile" && <p className='c_otp_timer c_otp_voice'> Sending OTP via call in {voiceOtpTimer} secs</p>}
                    {maskedDetails.context == "email" && <p className='c_otp_timer'>{`Didn't receive any OTP? `}<button onClick={otpTimer === 0 ? () => handleOtpClick() : null} className={otpTimer == 0 ? "resendBtn active" : "resendBtn inactive"}>Resend Now</button></p>}
                    {/* <button onClick={handleClick} >Next</button> */}
                </div>
            </div>
        </>
    );
};

OtpComponent.propTypes = {
    loginTab: PropTypes.string,
    otpDetails: PropTypes.object,
    userContext: PropTypes.string,
    loginTabForm: PropTypes.object,
    forgotForm: PropTypes.object,
    forgotDetails: PropTypes.object,
    recieveOtp: PropTypes.number,
    setOtpDetails: PropTypes.func,
    setCounter: PropTypes.func,
    setForgotForm: PropTypes.func,
    setNewId: PropTypes.func,
    otpTimerFlag: PropTypes.bool,
    setFailurePopUpModal: PropTypes.func,
    setFailurePopUpContent: PropTypes.func
}

export default OtpComponent;
