import React from "react";
import "./ProfileCtaContainer.scss";
import RadiantBorder from "../../global/RadiantBorder";
import ReUsablesCta from "../../global/ReUsablesCta/ReUsablesCta";
const ProfileCtaContainer = ({ children }) => {
  return (
    <RadiantBorder className="profile_cta_container">{children}</RadiantBorder>
  );
};

export default ProfileCtaContainer;
