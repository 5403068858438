import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const updateClientProfile = (data) => {
    // let requestJson = {
    //     "header": {
    //         "authToken": ""
    //     },
    //     "body": {
    //         "transactionId": "",
    //         "transactionStatus": "COMPLETED"
    //     }
    // }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/las-esb/update-client-profile/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-esb/update-client-profile/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default updateClientProfile;