import axios from "axios"
import stalwartApiCall from "../../utilities/stalwartCommonApi";

export const saveCollateralRelease = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "depledgingDetails": [
                {
                    "pledgeType": "Lien",
                    "amfi": "102594",
                    "loanAccount": "22",
                    "isin": "INF109K01AF8",
                    "orderNo": "",
                    "pledgeeDpid": "",
                    "pledgeeClientId": "",
                    "releaseQuantity": "345"
                },
                {
                    "pledgeType": "Demat",
                    "amfi": "",
                    "loanAccount": "22",
                    "isin": "INE501G01024",
                    "orderNo": "10000000000353",
                    "pledgeeDpid": "IN303542",
                    "pledgeeClientId": "IN301688",
                    "releaseQuantity": "45"
                }
            ]
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/las-esb/save-collateral-release/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-esb/save-collateral-release/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}