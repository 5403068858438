import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const generateSoaReport = (data) => {

    let requestJson = {
        "header": {
            "authToken": "",
            "company": "CFAB"
        },
        "body": {
            "companyCode": "7000",
            "programId": "ZTCL_PL",
            "partner": {
                "low": "0082000002",
            },
            "period": {
                "low": "202101",
                "high": "202207"
            }


        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/ccod/create-soa-report/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/ccod/create-soa-report/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default generateSoaReport;