import React, { useRef, useState, useContext, useEffect } from 'react'
import "./forgotcredential.scss";
import Input from '../../FormFields/Input';
import Button from '../../FormFields/Button/Button';
import { generateOtp } from '../../services/generateOtp';
import LoginServiceObject from '../../../../Retail/src/services/Login';
import { getLastestFieldValFromField } from '../../services/getLastestFieldValFromField';
import { handleErrorMessage } from '../../services/handleErrorMessageForm';
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import DataMapping from '../../../../Shared/context/data-mapping';
import PropTypes from 'prop-types';



const ForgotCredential = ({
    loginTab,
    setLoginTab,
    setCounter,
    setOtpDetails,
    forgotForm,
    setForgotForm,
    setForgotDetails,
    setOtpTimerFlag,
    setFailurePopUpModal,
    setFailurePopUpContent
}) => {

    const [showError, setShowError] = useState(false);
    const [, dispathData] = useContext(DataMapping)
    const [apiValidationError, setApiValidationError] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        setForgotForm((prev) => {
            let newState = { ...prev }
            newState.field.value = '';
            return newState;
        })
    }, [])

    const handleChange = (e) => {
        setShowError(false)
        setApiValidationError(() => false);
        setForgotForm((prev) => {
            let newState = { ...prev }
            newState.field.value = e.target.value
            return newState
        })
        handleValidation(e.target.value)
    }

    const handleValidation = (value) => {
        let numberRegex = /^\d+$/;
        let validMobileRegex = /^[789]\d{9}$/;
        let newEmailRegex = /\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}/g;
        if (value == "") {
            setForgotForm((prev) => {
                let newState = { ...prev }
                newState.field.error = "Value is required"
                return newState
            })
            return
        } else {
            setForgotForm((prev) => {
                let newState = { ...prev }
                newState.field.error = ""
                return newState
            })

        }

        if (loginTab !== "forgotPass") {
            let error = "";
            if (numberRegex.test(value)) {
                if (!validMobileRegex.test(value)) {
                    error = "Invalid Number"
                }
            } else if (!newEmailRegex.test(value)) {
                error = "Invalid Email"
            }

            setForgotForm((prev) => {
                let newState = { ...prev }
                newState.field.error = error
                return newState
            })
        }
    }

    const handleNavigate = (e) => {
        e.preventDefault();
        setShowError(true)
        let componentType;
        if (forgotForm.field.value === '') {
            setForgotForm((prev) => {
                let newState = { ...prev }
                newState.field.error = "Value is required"
                return newState
            })
            return
        }
        let formDetails = {
            value: { userId: forgotForm.field.value, },
            valid: forgotForm.field?.error == "" ? true : false
        }

        if (forgotForm.field.value !== "" && forgotForm.field.error === "") {
            dispathData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            switch (loginTab) {
                case "forgotId":
                    if (forgotForm.field.error == "" && forgotForm.field.value != "") {
                        componentType = forgotForm.field.value.includes("@") ? "emailId" : "mobNum";
                        generateOtp(componentType, forgotForm.field.value, "", "", "forgotJourney").then((res) => {
                            if (res.body.retStatus == "SUCCESS") {
                                setOtpDetails(() => {
                                    return {
                                        otpRef: res.body.otpRefNo,
                                        otpAuthToken: res.header.authToken,
                                    };
                                })
                                setCounter((prev) => prev + 1)
                                setOtpTimerFlag(true)
                            } else {
                                setApiValidationError(() => true);
                            }
                        }).catch(() => {
                            setApiValidationError(() => true);
                        }).finally(() => {
                            dispathData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA
                            })
                        })
                    }
                    break;
                case "forgotPass":
                    LoginServiceObject.getUserContactService(formDetails).then((response) => {
                        if (response.header.status == "SUCCESS") {
                            let email = getLastestFieldValFromField(response.body.emails)
                            let mobile = getLastestFieldValFromField(response.body.mobiles)
                            setForgotDetails({
                                mobile: mobile,
                                email: email
                            })
                            setCounter((prev) => prev + 1);

                        } else {
                            setApiValidationError(() => true);
                        }
                    }).catch((error) => {
                        if (error === "FAILURE") {
                            setApiValidationError(() => true);
                        } else {
                            setFailurePopUpModal(() => true);
                            setFailurePopUpContent(() => ({
                                apiName: "",
                                message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                                errorCode: error
                            }));
                        }
                    }).finally(() => {
                        dispathData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })

                    })

                    break;
                default:
                    break;
            }
        }
    }

    return (
        <>
            {
                loginTab === "forgotId" ? (<div className='c_credential_wrap'>
                    <div>
                        <p className='c_back' onClick={() => {
                            setLoginTab("helpUs")
                            setForgotForm({ field: { value: "", error: "Value is Required" } })
                        }}><img className='c_backbtn' src='assets/images/arrow_back.svg' alt='' />Back</p>
                        <p className='c_title'>Forgot User ID?</p>
                        <p className='c_description'>Please Provide your registered mobile number/ Email ID</p>
                    </div>
                    <div className='c_credential_footer'>
                        <form >

                            <Input
                                type='text'
                                placeholder="Registered Mobile Number/ Email ID"
                                name='field'
                                value={forgotForm.field.value}
                                onChange={handleChange}
                                inputRef={inputRef}
                            />
                            <span className='error'>{showError && handleErrorMessage(forgotForm, 'field', loginTab, apiValidationError)}</span>
                            <Button type='submit' className="login" onClick={(e) => handleNavigate(e)}>Next
                                <img
                                    className="c_next_arrow"
                                    src="assets/images/right-arrow-white.svg"
                                    alt=""
                                />
                            </Button>
                        </form>
                    </div>
                </div>) :
                    (<div className='c_credential_wrap'>
                        <div>
                            <p className='c_back' onClick={() => {
                                setLoginTab("helpUs");
                                setForgotForm({ field: { value: "", error: "Value is Required" } })
                            }}><img className='c_backbtn' src='assets/images/arrow_back.svg' alt='' />Back</p>
                            <p className='c_title'>Forgot Password?</p>
                            <p className='c_description'>No worries, enter your User ID and verify your identity via your regsitered Mobile Number or E-Mail ID</p>
                        </div>
                        <div className='c_credential_footer'>
                            <form >
                                <Input type='text'
                                    placeholder="Enter your User ID"
                                    name='field'
                                    value={forgotForm.field.value}
                                    onChange={handleChange}
                                    inputRef={inputRef}
                                />
                                <span className='error'>{showError && handleErrorMessage(forgotForm, 'field', loginTab, apiValidationError)}</span>
                                <Button type='submit' className="login" onClick={(e) => handleNavigate(e)}>Next
                                    <img
                                        className="c_next_arrow"
                                        src="assets/images/right-arrow-white.svg"
                                        alt=""
                                    />
                                </Button>

                            </form>
                        </div>
                    </div>)

            }
        </>
    );
};

ForgotCredential.propTypes = {
    loginTab: PropTypes.string,
    setLoginTab: PropTypes.func,
    setCounter: PropTypes.func,
    setOtpDetails: PropTypes.func,
    forgotForm: PropTypes.object,
    setForgotForm: PropTypes.func,
    setForgotDetails: PropTypes.func,
    setOtpTimerFlag: PropTypes.func,
    setFailurePopUpModal: PropTypes.func,
    setFailurePopUpContent: PropTypes.func
}

export default ForgotCredential;
