import { productDetailsMaster } from "../constants/productDetailsMaster"

export const getProductContent = (productName) => {
    let data = {}
    Object.keys(productDetailsMaster).forEach(element => {
        if (productDetailsMaster[element].productsArr.includes(productName.toLowerCase())) {
            data["label"] = element;
            data["icon"] = productDetailsMaster[element].icon;
        }
    })
    return data
}