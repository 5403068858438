import changePassword from "../../apis/change-password"
import { getCompanyList } from "../../apis/get-company-list/get-company-list"
import getCustomerBankingDetails from "../../apis/get-customer-banking-details/get-customer-banking-details"
import getDisbursalDetails from "../../apis/get-disbursal-details/get-disbursal-details"
import updateClientProfile from "../../apis/updateClientProfile"
import uploadProfilePic from "../../apis/upload-profile-pic/upload-profile-pic"
import commonFunctionObject from "../../common-function"
import AesUtil from "../../utilities/AesUtil"
import jsHelper from "../../utilities/jsHelper"


const ProfileServiceObject = {
}

const changePasswordService = (reqObj) => {

    return new Promise((resolve, reject) => {
        changePassword(reqObj).then((changePasswordResponse) => {
            if (changePasswordResponse.header.status == "SUCCESS") {
                if (changePasswordResponse.body.status === true) {
                    resolve(changePasswordResponse)
                } else if (changePasswordResponse.body.status === false) {
                    reject("82")
                }
            } else {
                if (changePasswordResponse.errorBody.message.toLowerCase() === "current password is incorrect") {
                    reject("current password is incorrect");
                } else {
                    reject("59")
                }
            }
        }).catch((error) => {
            reject("58")
        })
    })
}

ProfileServiceObject.changePasswordService = changePasswordService

const upDateClientProfileService = (requestObj) => {
    return new Promise((resolve, reject) => {
        updateClientProfile(requestObj).then((response) => {
            resolve(response)
            if (response.header.status === 'SUCCESS') {
                if (response.body.retStatus === "SUCCESS") {
                    resolve()
                } else if (response.retStatus === "FAILURE") {
                    resolve("L-60")
                }
            } else {
                resolve("L-59")
            }
        }).catch((error) => {
            reject("L-58")
        })
    })


}
ProfileServiceObject.upDateClientProfileService = upDateClientProfileService

const getBankingDetailService = (contractNumber, currentContract, authToken) => {

    return new Promise((resolve, reject) => {
        const requestObject = {
            "header": {
                "authToken": authToken,
            },
            "body": {
                "contractNumber": contractNumber
            }
        }
        getCustomerBankingDetails(requestObject).then((response) => {

            if (response.header.status === 'SUCCESS') {
                if (response.body.retStatus === 'SUCCESS') {
                    var accDetail = response.body.CustomerBankingDtls;
                    var account = {};

                    account['contractId'] = (jsHelper.isDef(currentContract.body.getLoanAccountsReturn.accountNumber) && !jsHelper.isObj(currentContract.body.getLoanAccountsReturn.accountNumber) ? currentContract.body.getLoanAccountsReturn.accountNumber : "-");
                    account['bankName'] = jsHelper.isDef(accDetail[0].Bank_Name) ? accDetail[0].Bank_Name : "-";
                    account['accountNumber'] = jsHelper.isDef(accDetail[0].Account_Number) ? accDetail[0].Account_Number : "-";
                    account['ifscCode'] = jsHelper.isDef(accDetail[0].IFSC_Code) ? accDetail[0].IFSC_Code : "-";
                    account['dateOfDebit'] = jsHelper.isDef(currentContract.body.getLoanPositionDtlsReturn.nextEmiDate) && !jsHelper.isObj(currentContract.body.getLoanPositionDtlsReturn.nextEmiDate) ? currentContract.body.getLoanPositionDtlsReturn.nextEmiDate : "-";
                    account['emistartDate'] = jsHelper.isDef(currentContract.body.getLoanPositionDtlsReturn.emistartDate) && !jsHelper.isObj(currentContract.body.getLoanPositionDtlsReturn.emistartDate) ? currentContract.body.getLoanPositionDtlsReturn.emistartDate : "-";
                    account['emiendDate'] = jsHelper.isDef(currentContract.body.getLoanPositionDtlsReturn.emiendDate) && !jsHelper.isObj(currentContract.body.getLoanPositionDtlsReturn.emiendDate) ? currentContract.body.getLoanPositionDtlsReturn.emiendDate : "-";
                    account['paymentmode'] = jsHelper.isDef(currentContract.body.getLoanPositionDtlsReturn.paymentmode) && !jsHelper.isObj(currentContract.body.getLoanPositionDtlsReturn.paymentmode) ? currentContract.body.getLoanPositionDtlsReturn.paymentmode : "-";
                    account['paymentStatus'] = jsHelper.isDef(currentContract.body.getLoanPositionDtlsReturn.paymentStatus) && !jsHelper.isObj(currentContract.body.getLoanPositionDtlsReturn.paymentStatus) ? currentContract.body.getLoanPositionDtlsReturn.paymentStatus : "-";
                    account['coApplicantName'] = jsHelper.isDef(currentContract.body.getLoanPositionDtlsReturn.coApplicantName) && !jsHelper.isObj(currentContract.body.getLoanPositionDtlsReturn.coApplicantName) ? currentContract.body.getLoanPositionDtlsReturn.coApplicantName : "-";
                    account['branchName'] = jsHelper.isDef(currentContract.body.getLoanPositionDtlsReturn.branchName) && !jsHelper.isObj(currentContract.body.getLoanPositionDtlsReturn.branchName) ? currentContract.body.getLoanPositionDtlsReturn.branchName : "-";
                    account['branchCode'] = jsHelper.isDef(currentContract.body.getLoanPositionDtlsReturn.branchCode) && !jsHelper.isObj(currentContract.body.getLoanPositionDtlsReturn.branchCode) ? currentContract.body.getLoanPositionDtlsReturn.branchCode : "-";
                    account['lastAmountPaid'] = jsHelper.isDef(currentContract.body.getLoanPositionDtlsReturn.lastAmountPaid) && !jsHelper.isObj(currentContract.body.getLoanPositionDtlsReturn.lastAmountPaid) ? "₹ " + currentContract.body.getLoanPositionDtlsReturn.lastAmountPaid : "₹0";
                    account["SYSTEM"] = "retail"
                    console.log(account);
                    resolve(account);
                }
            }
            else {
                if (response.errorBody !== undefined) {
                    if (response.errorBody.statusCode == "-1") {
                        // serviceError = {
                        //     "type": "api-failure: assets-details",
                        //     "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
                        //     "code": "E-6001-B",
                        //     "errorId": "56"
                        // }
                        // displayErrorModal(serviceError);
                        // $("body").removeClass("loader");
                        reject("56")
                    } else {
                        // serviceError = {
                        //     "type": "api-failure: assets-details",
                        //     "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
                        //     "code": "E-6001-C",
                        //     "errorId": "57"
                        // }
                        // displayErrorModal(serviceError);
                        // $("body").removeClass("loader");
                        reject("57")

                    }
                } else {
                    // serviceError = {
                    //     "type": "api-failure: assets-details",
                    //     "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
                    //     "code": "E-6001-C",
                    //     "errorId": "56"
                    // }
                    // displayErrorModal(serviceError);
                    // $("body").removeClass("loader");
                    reject("56")
                }
            }
        }).catch((error) => {
            // serviceError = {
            //     "type": "api-failure: get-customer-banking-details/v1",
            //     "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            //     "code": "E-6001-A",
            //     "errorId": "55"

            // }
            reject("55")
        })
    })
}

ProfileServiceObject.getBankingDetailService = getBankingDetailService;


const getDisbursalDetailsApi = (accountNumber, currentContract, authToken) => {

    return new Promise((resolve, reject) => {
        const requestObj = {
            "header": {
                "authToken": authToken,
                "company": "CFAB"
            },
            "body": {
                "pName": currentContract.sapContract.PROGRAM_NAME,
                "groupKey": currentContract.sapContract.DEALER_GROUP_KEY,
                "manuCoCode": currentContract.sapContract.MANU_CO_CODE,
                "budatFrom": "",
                "budatTo": "",
                "dateFrom": "",
                "dateTo": "",
                "mccdFrom": "",
                "mccdTo": ""
            }
        }
        getDisbursalDetails(requestObj).then((response) => {

            getDisbursalDetailsApiResponseHandler(response, accountNumber, resolve, reject)

        }).catch((error) => {
            // console.error("Error !!!", error);
            //         serviceError = {
            //             type: 'api-failure:disbursalStatus ',
            //             message: 'We Are Experiencing Technical Difficulty. Please Try After Sometime.',
            //             code: 'E-2023-DS',
            //             errorId: 'C-09',
            //         };
            //         displayErrorModal(serviceError);
            //         $('body').removeClass('loader');
            reject("C-09")
        })
    })
}


ProfileServiceObject.getDisbursalDetailsApi = getDisbursalDetailsApi


function getDisbursalDetailsApiResponseHandler(response, accNo, resolve, reject) {

    var vendorAndCreateSoaObj = {}
    if (response) {
        if (response.header.status.toUpperCase() === "SUCCESS") {
            var disbursalStatusArr = response.body.I_ZTW_DISB_NONTML_LOG.ITEM;
            var disbursalDetails = {}
            var accountNumberArray = [];
            if (Array.isArray(disbursalStatusArr)) {
                vendorAndCreateSoaObj["BELNR"] = disbursalStatusArr[0].BELNR ? disbursalStatusArr[0].BELNR : '-';
                vendorAndCreateSoaObj["BANKA"] = disbursalStatusArr[0].BANKA ? disbursalStatusArr[0].BANKA : '-';
                disbursalStatusArr.forEach(function (element) {
                    accountNumberArray.push(element.BANK_ACC_NO);
                })
                vendorAndCreateSoaObj["BANK_ACC_NO"] = accountNumberArray;
                vendorAndCreateSoaObj["BANK_NO"] = disbursalStatusArr[0].BANK_NO ? disbursalStatusArr[0].BANK_NO : '-';
                vendorAndCreateSoaObj["BRANCH"] = disbursalStatusArr[0].BRANCH ? disbursalStatusArr[0].BRANCH : '-';
                vendorAndCreateSoaObj["SYSTEM"] = "ccod"
                disbursalDetails = vendorAndCreateSoaObj;
            } else {
                vendorAndCreateSoaObj["BELNR"] = disbursalStatusArr.BELNR ? disbursalStatusArr.BELNR : '-';
                vendorAndCreateSoaObj["BANKA"] = disbursalStatusArr.BANKA ? disbursalStatusArr.BANKA : '-';
                vendorAndCreateSoaObj["BANK_ACC_NO"] = disbursalStatusArr.BANK_ACC_NO ? disbursalStatusArr.BANK_ACC_NO : '-';
                vendorAndCreateSoaObj["BANK_NO"] = disbursalStatusArr.BANK_NO ? disbursalStatusArr.BANK_NO : '-';
                vendorAndCreateSoaObj["BRANCH"] = disbursalStatusArr.BRANCH ? disbursalStatusArr.BRANCH : '-';
                vendorAndCreateSoaObj["SYSTEM"] = "ccod"
                disbursalDetails = vendorAndCreateSoaObj;
            }

            resolve(disbursalDetails)

        } else {
            // serviceError = {
            //     type: 'api-failure:disbursalStatus ',
            //     message: 'We Are Experiencing Technical Difficulty. Please Try After Sometime.',
            //     code: 'E-2021-DS',
            //     errorId: 'C-07',
            // };
            // $('body').removeClass('loader');
            // displayErrorModal(serviceError);
            reject("C-07")
        }
    } else {
        // serviceError = {
        //     type: 'api-failure:disbursalStatus ',
        //     message: 'We Are Experiencing Technical Difficulty. Please Try After Sometime.',
        //     code: 'E-2022-OD',
        //     errorId: 'C-08',
        // };
        // $('body').removeClass('loader');
        // displayErrorModal(serviceError);
        reject("C-08")
    }
}

const getCompanyListService = (authToken) => {
    return new Promise((resolve, reject) => {
        const requestObj = {
            "header": {
                "authToken": authToken
            },
            "body": {}
        }
        getCompanyList(requestObj).then((value) => {
            resolve(value.body.companyDetails)
        }).catch(() => {
            // console.log("Failure response: ", response);
            // serviceError = {
            //     "type": "api-failure: change-password/v1",
            //     "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            //     "code": "E-6005-A",
            //     "errorId": "L-64"
            // }

            // displayErrorModal(serviceError);
            reject("L-64")
        });
    })


}

ProfileServiceObject.getCompanyListService = getCompanyListService


const upLoadProfilePicService = (image, GCID, authToken) => {
    return new Promise((resolve, reject) => {
        commonFunctionObject.convertToBase64([image], true).then((base64Image) => {
            let RequestObject = {
                "header": {
                    "authToken": authToken
                },
                "body": {
                    "profilePic": base64Image[0],
                    "gcid": GCID
                }
            }
            uploadProfilePic(RequestObject).then((response) => {
                if (response.header.status == "SUCCESS") {
                    resolve({ "image": base64Image })

                } else if (response.header.status == "FAILURE") {
                    resolve({ "apiFailure": "header statu failure" })
                }

            }).catch((error) => {
                reject("60")

            })
        })


    })

}

ProfileServiceObject.upLoadProfilePicService = upLoadProfilePicService




export default ProfileServiceObject
