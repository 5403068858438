import objKeys from './objectKeys';

export default function defineReadOnlyObjectProperties(propContext, propsObj) {
    var propertiesObj = {};
    objKeys(propsObj).forEach(function (key) {
        propertiesObj[key] = {
            get: function () {
                return propsObj[key];
            }
        }
    });
    return Object.defineProperties(propContext, propertiesObj);
};