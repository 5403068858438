export const bankDetailsArray = {
    "bankDetails": [
        {
            "label": "Bank Name",
            "status": ["active", "closed"],
            "apiKey": "bankName",
        },
        {
            "label": "Account Number",
            "status": ["active", "closed"],
            "apiKey": "accountNumber",
        },
        {
            "label": "IFSC Code",
            "status": ["active", "closed"],
            "apiKey": "ifscCode",
        },
        {
            "label": "Payment Method",
            "status": ["active", "closed"],
            "apiKey": "paymentmode",
        },
        {
            "label": "Co-Applicant Name",
            "status": ["active", "closed"],
            "apiKey": "coApplicantName",
        }
    ],
    "interestDetails": [
        {
            "label": "Next EMI Date",
            "status": ["active", "closed"],
            "apiKey": "dateOfDebit",
        },
        {
            "label": "EMI Start Date",
            "status": ["active", "closed"],
            "apiKey": "emistartDate",
        },
        {
            "label": "EMI End Date",
            "status": ["active", "closed"],
            "apiKey": "emiendDate",
        },
        {
            "label": "Branch Name",
            "status": ["active", "closed"],
            "apiKey": "branchName",
        },
        {
            "label": "Last Paid Amount",
            "status": ["active", "closed"],
            "apiKey": "lastAmountPaid",
        }
        
    ]

}


