import React, { useEffect, useState, useRef } from "react";
import "./CheckCreditScore.scss";
import RadiantBorder from "../../global/RadiantBorder";
import { useNavigate } from "react-router-dom";
import Button from "../../global/Button";
import Modal from "../../global/Modal";
import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import ModernDatepicker from '../Forms/ReactDatepicker'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import Select, { makeAnimated } from 'react-select'

import Select from "../../global/Select/Select";
import DataMapping from "../../../../Shared/context/data-mapping";
import useSessionAlive from "../../customHooks/useSessionAlive";
import CreditScoreServiceObject from "../../services/CreditScoreService";
import Input from "../../global/Input";
import CustomDropDown from "../../global/CustomDropDown";
import commonFunctionObject from "../../common-function";
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/en_US';
import ConfigProvider from "antd/es/config-provider";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../../../Shared/context/ThemeContext/store/PageNameContext";
import PostOtp from "../../global/PostOtp";
import CreditScoreReadMorePopUp from "../CreditScoreReadMorePopUp";
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import PropTypes from 'prop-types';

const CheckCreditScore = ({ postOtpFlag, setPostOtpFlag,portalType }) => {

  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    "apiName": "",
    "message": "",
    "errorCode": ""
  })
  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");
  // const [themeState] = useContext(ThemeContext)
  const [themeState, dispatch] = useContext(ThemeContext);
  const [data, dispatchData] = useContext(DataMapping);
  const { isUserSessionAlive } = useSessionAlive();
  const [playVideo, setPlayVideo] = useState(false);
  const [showMoreFlag, setShowMoreFlag] = useState(false);
  const { analytics } = useContext(AnalyticsContextProvider);
  const handlePlay = () => {
    setPlayVideo(!playVideo);
    console.log("playyyyy", playVideo);
  };

  const genderArray = [
    {
      value: "Male"
    },
    {
      value: "Female"
    }
  ]
  const [selectedGenderOption, setSelectedGenderOption] = useState("Male");
  const [optionGenderState, setOptionGenderState] = useState(false);

  const typeOfEmploymentArray = [
    {
      value: "Salaried"
    },
    {
      value: "Self-Employed"
    },
    {
      value: "Others"
    },
  ]
  const { pageName, setPageName } = useContext(pageNameContext);
  const [selectedEmploymentOption, setSelectedEmploymentOption] = useState("Salaried");
  const [optionEmploymentState, setOptionEmploymentState] = useState(false);

  const [stateArray, setStateArray] = useState([])
  const [stateInputChange, setStateInputChange] = useState("")
  const [stateDropDownState, setStateDropDownState] = useState(false)
  const [pincodeMasterForStateResponse, setPincodeMasterForStateResponse] = useState([])
  const handleStateDropDownInputChange = (e) => {
    setStateInputChange(e.target.value)
    setStateDropDownState(true)
    inputPopulation("state", e)
  }
  const setStateDropDownValue = (val) => {
    setStateInputChange(val)
    setStateDropDownState(false)
    getCityName(val)
    setCreditScoreFormObject({ ...creditScoreFormObject, state: val })
  }

  const [cityArray, setCityArray] = useState([])
  const [cityInputChange, setCityInputChange] = useState("")
  const [cityDropDownState, setCityDropDownState] = useState(false)
  const handleCityDropDownInputChange = (e) => {
    setCityInputChange(e.target.value);
    setCityDropDownState(true)
    inputPopulation("city", e)
  }
  const setCityDropDownValue = (val) => {
    setCityInputChange(val)
    setCityDropDownState(false)
    setCreditScoreFormObject({ ...creditScoreFormObject, city: val })
    getPincode(val)
  }
  const [pincodeArray, setPincodeArray] = useState([])
  const [pincodeInputChange, setPincodeInputChange] = useState("")
  const [pincodeDropDownState, setPincodeDropDownState] = useState(false)
  const handlePincodeDropDownInputChange = (e) => {
    setPincodeInputChange(e.target.value);
    setPincodeDropDownState(true)
    inputPopulation("pincode", e)
  }
  const setPincodeDropDownValue = (val) => {
    setPincodeInputChange(val)
    setPincodeDropDownState(false)
    setCreditScoreFormObject({ ...creditScoreFormObject, pincode: val })
  }

  const [modalState, setModalState] = useState(false);
  let navigate = useNavigate();


  //Regex Patterns
  const inputRef = useRef()
  console.log(inputRef)

  const [refNo, setRefNo] = useState()

  const [creditScoreFormObject, setCreditScoreFormObject] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    panNumber: "",
    email: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    aadharcardnumber: "",
    linkCheckbox: false,
    detailsCheckbox: false,
    appointCheckbox: false,
    errorCheckbox: ""
  })

  const [formErrorObject, setFormErrorObject] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    panNumber: "",
    email: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    aadharcardnumber: "",
    errorCheckbox: ""
  })

  const [editIconFlag, setEditIconFlag] = useState({
    firstName: true,
    lastName: true,
    panNumber: true,
    email: true,
    mobile: true,
    address: true,
    aadharcardnumber: true
  })

  const [singleActionAPIRequest, setSingleActionAPIRequest] = useState({})

  useEffect(() => {
    setPageName("Credit Score");
    isUserSessionAlive(data?.authToken);
    pincodemasterapicall()
    let finalPopulationObject = CreditScoreServiceObject.prePopulateInputFields(data);
    finalPopulationObject.gender = selectedGenderOption
    finalPopulationObject.typeOfEmployment = selectedEmploymentOption
    setCreditScoreFormObject({ ...creditScoreFormObject, ...finalPopulationObject })
    setStateInputChange(finalPopulationObject.state)
    commonFunctionObject.scrollToTop()
  }, []);

  const pincodemasterapicall = () => {

    var pincodeMasterObj = {
      "header": {
        "authToken": data.authToken,
        "filter": "experianCountryCode"
      },
      "body": {
        "value": "12501"
      }
    }
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA
    })
    CreditScoreServiceObject.pincodeMasterService(pincodeMasterObj).then(pincodeMasterStates => {
      pincodeMasterStates.sort(function (a, b) {
        if (a["STATE_NAME"] < b["STATE_NAME"]) return -1;
        else if (a["STATE_NAME"] > b["STATE_NAME"]) return 1;
        return 0;
      });

      let stateNamesArray = []
      pincodeMasterStates.forEach((value) => {
        stateNamesArray.push(value.STATE_NAME)
      })
      setPincodeMasterForStateResponse(pincodeMasterStates)
      setStateArray(stateNamesArray)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    }).catch((error) => {
      setFailurePopUpContent({
        apiName: "",
        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
        errorCode: error
      })
      setFailurePopUpModal(true)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    })
  }

  const getCityName = (stateName) => {
    let stateObject = pincodeMasterForStateResponse.filter((value) => {
      return stateName.toLowerCase() === value.STATE_NAME.toLowerCase()
    })

    var pincodeMasterCityRequest = {
      "header": {
        "authToken": data.authToken,
        "filter": "stateCode"
      },
      "body": {
        "value": stateObject[0].STATE_CODE
      }
    }
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA
    })
    CreditScoreServiceObject.pincodeMasterService(pincodeMasterCityRequest).then((pincodeMasterCities) => {
      pincodeMasterCities.sort(function (a, b) {
        if (a["CITY_NAME"] < b["CITY_NAME"]) return -1;
        else if (a["CITY_NAME"] > b["CITY_NAME"]) return 1;
        return 0;
      });

      let cityNameArray = []
      pincodeMasterCities.forEach((value) => {
        cityNameArray.push(value.CITY_NAME)
      })
      setCityArray(cityNameArray)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    }).catch((error) => {
      setFailurePopUpContent({
        apiName: "",
        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
        errorCode: error
      })
      setFailurePopUpModal(true)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    })
  }


  const getPincode = (cityName) => {

    var pincodeMasterCityRequest = {
      "header": {
        "authToken": data.authToken,
        "filter": "experianCityName"
      },
      "body": {
        "value": cityName
      }
    }
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA
    })

    CreditScoreServiceObject.pincodeMasterService(pincodeMasterCityRequest).then((cityPincodes) => {
      let getPincodeArray = [];
      cityPincodes.forEach((value) => {
        getPincodeArray.push(value.ZIP_CODE)
      })
      setPincodeArray(getPincodeArray)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    }).catch((error) => {
      setFailurePopUpContent({
        apiName: "",
        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
        errorCode: error
      })
      setFailurePopUpModal(true)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    })
  }

  const inputPopulation = (fieldName, event) => {
    let value = event.target.value
    setCreditScoreFormObject({ ...creditScoreFormObject, [fieldName]: value })
    let finalErrorObject = CreditScoreServiceObject.creditScoreInputValidationCheck(fieldName, value)
    setFormErrorObject({ ...formErrorObject, ...finalErrorObject })
  }


  const generateCreditScore = () => {
    var accountNumber = data?.currentActiveData.accountNumber;
    var currentUserData = data?.parsedUserData[accountNumber];
    var gcid = currentUserData.GCID;
    let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: gcid,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "",
        componentName: "",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "",
        eventName: "DC: Credit_Score_Submit"
      }
    }
    if(portalType !== "collection"){
      analytics.trackData("creditscoreSubmit", analytics_data);
    }
    var finalErrorObject = CreditScoreServiceObject.creditScoreFormValidation(creditScoreFormObject)
    setFormErrorObject({ ...formErrorObject, ...finalErrorObject })
    if (validation(finalErrorObject)) {
      if (stateArray.includes(creditScoreFormObject.state)) {
        if (cityArray.includes(creditScoreFormObject.city)) {
          if (pincodeArray.includes(creditScoreFormObject.pincode)) {
            var addressArr = CreditScoreServiceObject.getAddressLines(3, creditScoreFormObject.address, [",", " "], 0, 40);
            var apiDateSend = commonFunctionObject.convertDateToDDMMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(creditScoreFormObject.dob)));
            var getStateObject = pincodeMasterForStateResponse.filter((value) => {
              return value.STATE_NAME.toLowerCase() === creditScoreFormObject.state.toLowerCase()
            })

            var singleActionRequestJson = {
              "firstName": creditScoreFormObject.firstName,
              "lastName": creditScoreFormObject.lastName,
              "dob": apiDateSend,
              "gender": selectedGenderOption === "Male" ? "2" : "1",
              "mobileNumber": creditScoreFormObject.mobile,
              "emailId": creditScoreFormObject.email,
              "flatNo": addressArr[0],
              "buildingName": addressArr[1],
              "roadName": addressArr[2],
              "city": creditScoreFormObject.city,
              "state": getStateObject[0].EXPERIAN_STATE_CODE,
              "pincode": creditScoreFormObject.pincode,
              "typeOfEmployment": selectedEmploymentOption,
              "middleName": "",
              "telephoneNo": "",
              "telephoneType": "",
            }
            if (creditScoreFormObject?.panNumber) {
              singleActionRequestJson["pan"] = creditScoreFormObject.panNumber.toUpperCase();
            }
            var docType = creditScoreFormObject.aadharcardnumber.toLowerCase();
            singleActionRequestJson["aadhaarNo"] = docType;
            let generateMobileOtpRequest = {
              "header": {
                "grantType": "otp",
                "serviceType": "mobile",
                "authToken": data.authToken,
                "journeyType": "postLogin"
              },
              "body": {
                "mobileNo": creditScoreFormObject.mobile
              }
            }
            dispatchData({
              name: "loaderFlag",
              type: STOREDATA,
              payload: true
            })

            CreditScoreServiceObject.generateMobileOtpService(generateMobileOtpRequest).then((generateMobileOtpRefID) => {
              setRefNo(generateMobileOtpRefID)
              dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false
              })
              setSingleActionAPIRequest(singleActionRequestJson)
              setPostOtpFlag(true)
              // navigate("/otpPage", { state: { postlogin: true, userInput: creditScoreFormObject.mobile, page: "credit-score-generation", refID: generateMobileOtpRefID, postOnCallFlag: true, singleActionRequestJson: singleActionRequestJson } });
            }).catch((error) => {
              if (error.toLowerCase() === "number of attempts exceeded") {
                setFailurePopUpContent({
                  apiName: "",
                  message: "Please login with User ID and Password.",
                  errorCode: ""
                })
                setFailurePopUpModal(true)
                dispatchData({
                  name: "loaderFlag",
                  payload: false,
                  type: STOREDATA
                })
              } else {
                dispatchData({
                  name: "loaderFlag",
                  payload: false,
                  type: STOREDATA
                })
                setFailurePopUpContent({
                  apiName: "",
                  message: "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
                  errorCode: error
                })
                setFailurePopUpModal(true)
              }
            })
          }
          else {
            setFormErrorObject({ ...formErrorObject, pincode: "Please Select Pincode from the List" })
          }
        } else {
          setFormErrorObject({ ...formErrorObject, city: "Please Select City from the List" })
        }
      } else {
        setFormErrorObject({ ...formErrorObject, state: "Please Select State from the List" })
      }
    }

  }

  const validation = (finalErrorObject) => {
    var count = 0
    Object.keys(creditScoreFormObject).map((keyName) => {
      if (finalErrorObject[keyName] && finalErrorObject[keyName] !== "") {
        count += 1
      }
    })

    if (count > 0) {
      return false
    } else {
      return true
    }
  }


  const checkboxCheck = (checkBoxName, event) => {
    var checkedFlag = event.target.checked
    var newCheckBoxObj = creditScoreFormObject
    if (checkedFlag) {
      newCheckBoxObj[checkBoxName] = true
      setCreditScoreFormObject({ ...creditScoreFormObject, newCheckBoxObj })
    } else {
      newCheckBoxObj[checkBoxName] = false
      setCreditScoreFormObject({ ...creditScoreFormObject, newCheckBoxObj })
      setFormErrorObject({ ...formErrorObject, errorCheckbox: "Please Check all the Terms & Conditions" })
    }

    if (newCheckBoxObj.linkCheckbox === true && newCheckBoxObj.detailsCheckbox === true && newCheckBoxObj.appointCheckbox === true) {
      setFormErrorObject({ ...formErrorObject, errorCheckbox: "" })
    }
  }
  return (
    <>
      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
      {successPopUpModal ? <SuccessPopUp
        onClick={() => setSuccessPopUpModal(false)}
        visible={successPopUpModal}
        setSuccessModalStat={setSuccessPopUpModal}
        successPopUpContent={successPopUpContent}
      /> : null}
      {modalState ? (
        <CreditScoreReadMorePopUp setModalState={setModalState} />
      ) : null}
      {postOtpFlag ? <RadiantBorder className="otp-post-login-container">
        <div className='otpDivPostLogin' >
          <PostOtp userInput={creditScoreFormObject.mobile} pageName="credit-score-generation" refID={refNo} postOnCallFlag={true} singleActionRequestJson={singleActionAPIRequest} setPostOtpFlag={setPostOtpFlag} />
        </div>
      </RadiantBorder> :
        <div className="main-container wrap_Div">
          {/* <div className="bg-01-container"></div>
          <div className="bg-02-container"></div> */}
          <div className="personal_main_div">
            <RadiantBorder className="radian_Border">
              <div className="personal_details_child_div">
                <div className="personalDTopLeftTextDiv">
                  <p className="personaldetails_Text">
                    Your credit score is extremely important. A higher score can
                    help you get the most affordable loans in the market.
                  </p>
                  <p className="personaldetails_Text">
                    A Credit Score is calculated on your credit history: number of
                    open accounts, total levels of debt, repayment history, and
                    other factors. This is then used by financers to evaluate the
                    probability an individual will be able to repay loans in a
                    timely manner.
                  </p>
                  <p className="personaldetails_Text">
                    Want to know more? Watch this video.
                  </p>
                  {playVideo ? (
                    <iframe
                      className="credit_Score_Video"
                      allowFullScreen={true}
                      // src="https://www.youtube.com/embed/hbW__a2IOR4"
                      src="https://www.youtube.com/embed/GuGZgwQlnuE"
                    />
                  ) : (
                    <div className="creditScore_Img_Div">
                      <img
                        // src="assets/images/personalDetailImg.svg"
                        src="assets/images/Thumbnail_Final.svg"
                        className="check_CreditScore_Img"
                        alt="..."
                      />
                      <img
                        src="assets/images/play_Video_Btn.svg"
                        className="play_Video_Btn"
                        alt="..."
                        onClick={() => {
                          handlePlay();
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </RadiantBorder>
            <RadiantBorder className="radian_Border">
              <div className="personaldetails_Head_Text_Div">
                <p className="personaldetails_Head_Text">Personal Details</p>
              </div>
              <div className="credit-score-form">
                <form className="creditScoreForm">
                  <div className="creditscore-field-container">
                    <div className="credit-form-div">
                      <div className="input-container">
                        <label htmlFor="credit-score-field">First Name</label>
                        <Input
                          placeholder="Enter First Name"
                          value={creditScoreFormObject.firstName}
                          disabled={editIconFlag.firstName}
                          id="first-name"
                          editIcon={true}
                          errorSpace={true}
                          error={formErrorObject.firstName}
                          onChange={(event) => {
                            inputPopulation("firstName", event)
                          }}
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="credit-score-field">Last Name</label>
                        <Input
                          placeholder="Enter Last Name"
                          value={creditScoreFormObject.lastName}
                          disabled={editIconFlag.lastName}
                          errorSpace={true}
                          error={formErrorObject.lastName}
                          id="last-name"
                          editIcon={true}
                          onChange={(event) => {
                            inputPopulation("lastName", event)
                          }}
                        />
                      </div>
                    </div>
                    <div className="credit-form-div">
                      <div className="input-container">
                        <label htmlFor="credit-score-field">Date of Birth</label>
                        <ModernDatepicker dateLimit={true} placeholder='Enter Date Of Birth' errorSpace={true} value={creditScoreFormObject.dob !== "" ? moment(new Date(commonFunctionObject.replaceHyphenDate(creditScoreFormObject.dob))) : ""} format="DD-MM-YYYY" onChange={(date, event) => { setFormErrorObject({ ...formErrorObject, dob: "" }); setCreditScoreFormObject({ ...creditScoreFormObject, dob: new Date(commonFunctionObject.replaceHyphenDate(date)) }) }} error={formErrorObject.dob} />
                      </div>
                      <div className="input-container">
                        <label htmlFor="credit-score-field">Gender</label>
                        <CustomDropDown
                          optionArray={genderArray}
                          optionState={optionGenderState}
                          selectedOption={selectedGenderOption}
                          setSelectedOption={setSelectedGenderOption}
                          setOptionState={setOptionGenderState}
                          className={"credit-score-drop-down"}
                          //labelClass={"account-details-container-label "}
                          errorSpace={true}
                        />
                      </div>
                    </div>
                    <div className="credit-form-div">
                      <div className="input-container">
                        <label htmlFor="credit-score-field">PAN Number</label>
                        <Input
                          placeholder="Enter PAN Number"
                          value={creditScoreFormObject.panNumber}
                          disabled={editIconFlag.panNumber}
                          errorSpace={true}
                          id="pan-number"
                          editIcon={true}
                          error={formErrorObject.panNumber}
                          onChange={(event) => {
                            inputPopulation("panNumber", event)
                          }}
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="credit-score-field">Email</label>
                        <Input
                          placeholder="Enter Email ID"
                          value={creditScoreFormObject.email}
                          disabled={editIconFlag.email}
                          errorSpace={true}
                          id="email"
                          editIcon={true}
                          error={formErrorObject.email}
                          onChange={(event) => {
                            inputPopulation("email", event)
                          }}
                        />
                      </div>
                    </div>

                    <div className="credit-form-div">
                      <div className="input-container">
                        <label htmlFor="credit-score-field">Aadhar Card Number</label>
                        <Input
                          placeholder="Enter Aadharcard Number"
                          value={creditScoreFormObject.aadharcardnumber}
                          disabled={editIconFlag.aadharcardnumber}
                          errorSpace={true}
                          id="aadhar-number"
                          editIcon={true}
                          error={formErrorObject.aadharcardnumber}
                          onChange={(event) => {
                            inputPopulation("aadharcardnumber", event)
                          }}
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="credit-score-field">Type of Employment</label>
                        <CustomDropDown
                          optionArray={typeOfEmploymentArray}
                          optionState={optionEmploymentState}
                          selectedOption={selectedEmploymentOption}
                          setSelectedOption={setSelectedEmploymentOption}
                          setOptionState={setOptionEmploymentState}
                          className={"credit-score-drop-down"}
                          //labelClass={"account-details-container-label "}
                          errorSpace={true}
                        />
                      </div>
                    </div>
                    <div className="credit-form-div">
                      <div className="input-container">
                        <label htmlFor="credit-score-field">Mobile Number</label>
                        <Input
                          placeholder="Enter Mobile Number"
                          value={creditScoreFormObject.mobile}
                          disabled={editIconFlag.mobile}
                          errorSpace={true}
                          error={formErrorObject.mobile}
                          id="mobile-number"
                          editIcon={true}
                          maxLength={10}
                          onChange={(event) => {
                            inputPopulation("mobile", event)
                          }}
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="credit-score-field">Address</label>
                        <Input
                          placeholder="Enter Address"
                          value={creditScoreFormObject.address}
                          disabled={editIconFlag.address}
                          errorSpace={true}
                          id="address"
                          editIcon={true}
                          error={formErrorObject.address}
                          onChange={(event) => {
                            inputPopulation("address", event)
                          }}
                        />
                      </div>
                    </div>
                    <div className="credit-form-div">
                      <div className="input-container">
                        <label htmlFor="credit-score-field">State</label>
                        <input onChange={handleStateDropDownInputChange} placeholder="Select State" value={stateInputChange} />
                        {
                          stateInputChange && stateDropDownState ? <div className="pincode-master-dropDown">
                            {
                              stateArray.filter(val => val.toLowerCase().includes(stateInputChange.toLowerCase())).map(val => <p onClick={() => setStateDropDownValue(val)}>{val}</p>)
                            }
                          </div> : ""
                        }
                        <div className="error-span">
                          <span>{formErrorObject.state}</span>
                        </div>
                      </div>
                      <div className="input-container">
                        <label htmlFor="credit-score-field">City</label>
                        <input onChange={handleCityDropDownInputChange} placeholder="Select City" value={cityInputChange} />
                        {
                          cityInputChange && cityDropDownState ? <div className="pincode-master-dropDown">
                            {
                              cityArray.filter(val => val.toLowerCase().includes(cityInputChange.toLowerCase())).map(val => <p onClick={() => setCityDropDownValue(val)}>{val}</p>)
                            }
                          </div> : ""
                        }
                        <div className="error-span">
                          <span>{formErrorObject.city}</span>
                        </div>
                      </div>
                    </div>
                    <div className="credit-form-div">
                      <div className="input-container">
                        <label htmlFor="credit-score-field">Pincode</label>
                        <input onChange={handlePincodeDropDownInputChange} placeholder="Select Pincode" value={pincodeInputChange} />
                        {
                          pincodeInputChange && pincodeDropDownState ? <div className="pincode-master-dropDown">
                            {
                              pincodeArray.filter(val => val.includes(pincodeInputChange)).map(val => <p onClick={() => setPincodeDropDownValue(val)}>{val}</p>)
                            }
                          </div> : ""
                        }
                        <div className="error-span">
                          <span>{formErrorObject.pincode}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </RadiantBorder>
          </div>

          <div className="personal_main_div">
            <RadiantBorder className="radian_Border">
              <div className="personalDLeftBottumDiv">
                <div className="personalD_head_Text_Parent_Div">
                  <div>
                    {themeState === THEME_DARK ? (
                      <img
                        src="assets/images/info-yellow.svg"
                        className="info_Img"
                      />
                    ) : (
                      <img src="assets/images/info.svg" className="info_Img" />
                    )}
                  </div>
                  <div className="checkbox_Text_Div">
                    <p className="personaldetailsLeftButtomDiv_HeadText">
                      Importance of Your Credit Report
                    </p>
                  </div>
                </div>
                <p className="personaldetailsLeftBottomDiv_Text">
                  Your credit report helps to determine your eligibility to apply
                  for a loan, Banks and lenders use it to determine the risk they
                  are taking by lending to you. A high score indicates low risk
                  and increases your chances of getting loan, preferred interest
                  rate etc. Maintaining good score helps you to avail various
                  benefits applicable by financial institutions.
                </p>
              </div>
            </RadiantBorder>
            <RadiantBorder className="radian_Border">
              <div className="personalDRightBottumDiv">
                <div className="checkbox_Flex_Div">
                  <div>
                    <input type="checkbox" className="checkBox" checked={creditScoreFormObject.linkCheckbox} onClick={(event) => { checkboxCheck("linkCheckbox", event) }} onChange={e => { }} />
                  </div>

                  <div className="checkbox_Text_Div">
                    <p className="checkbox_Text">
                      By clicking this link, you authorize Tata Capital Financial
                      Service Limited and Tata Capital Housing Finance Limited to
                      approach Experian on your behalf for procuring your Credit
                      Information Report and Credit Score for deriving
                      personalized pre-approved offers.
                      <span
                        className="read-show-more"
                        onClick={() => {
                          setModalState(true);
                        }}
                      >
                        Read More
                      </span>
                    </p>
                  </div>
                </div>
                <div className="checkbox_Flex_Div">
                  <div>
                    <input type="checkbox" className="checkBox" checked={creditScoreFormObject.detailsCheckbox} onClick={(event) => { checkboxCheck("detailsCheckbox", event) }} onChange={e => { }} />
                  </div>
                  <div className="checkbox_Text_Div">
                    <p className="checkbox_Text">
                      I confirm all details mentioned above are correct.
                    </p>
                  </div>
                </div>
                <div className="checkbox_Flex_Div">
                  <div>
                    <input type="checkbox" className="checkBox" checked={creditScoreFormObject.appointCheckbox} onClick={(event) => { checkboxCheck("appointCheckbox", event) }} onChange={e => { }} />
                  </div>
                  <div className="checkbox_Text_Div">
                    <p className="checkbox_Text">
                      I hereby appoint Tata Capital Financial Service Limited and
                      Tata Capital Housing Finance Limited as my authorized
                      representative to receive my credit information from
                      Experian.{" "}
                      {!showMoreFlag && <span
                        className="read-show-more"
                        onClick={() => {
                          setShowMoreFlag(true);
                        }}
                      >
                        Show More
                      </span>}

                    </p>
                    {showMoreFlag && <p className="checkbox_Text">
                      I hereby unconditionally consent to and instruct Experian
                      to provide my credit information to me and Tata Capital Financial Service Limited and
                      Tata Capital Housing Finance Limited on an agreed frequency for a period of 12 months.
                      I understand that I can opt out / unsubscribe from the service.
                    </p>}

                  </div>
                </div>
                <div className="errorMsg">
                  <p>{formErrorObject.errorCheckbox}</p>
                </div>
              </div>
            </RadiantBorder>
          </div>
          <div className="getScoreBtn_Div">
            <Button
              type="submit"
              onClick={() => {
                generateCreditScore();
              }}
              className="getScoreBtn"
            >
              <p className="getScoreBtnText">Get Free Credit Score</p>
              <img src="assets/images/arrow-right.svg" />
            </Button>
          </div>
        </div>
      }

    </>
  );
}

CheckCreditScore.propTypes = {
  postOtpFlag: PropTypes.bool,
  setPostOtpFlag: PropTypes.func
}


export default CheckCreditScore;
