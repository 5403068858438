import React from "react";
import './KnowMore.scss';
import Button from '../../FormFields/Button/Button';

const KnowMore = () => {
    return (
        <div className="c_know_more">
            <div className="c_know_more_icon_div">
                <img className="c_know_more_icon" src="assets/images/KnowMoreIcon.svg" alt="Know More Icon" />
            </div>
            <div className="c_know_more_btn_div">
                <Button className='c_know_more_btn'>
                    <div className="c_know_more_btn_content">
                        <p className="c_know_more_text">Know More</p>
                        <img className="c_vector_icon" src="assets/images/vector.svg" alt="Right-arrow Icon" />
                    </div>
                </Button>
            </div>
        </div>
    )
}
export default KnowMore;