import React from 'react';
import { useState, useEffect } from 'react';
import PageWrapper from '../../container/PageWrapper';
import CustomDropDown from '../../global/CustomDropDown/CustomDropDown'
import Accordion from '../Accordion/Accordion';
import Searchbar from '../Searchbar';
import CustomerServicePopUp from "../CustomerServicePopUp";

// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from '../../../../Shared/context/ThemeContext/store/ThemeContext';
//styles
import './ListOfServiceRequest-style.scss'
import UpdateEmailID from '../UpdateEmailID';
import ChangeMobileNumber from '../ChangeMobileNumber';
import UpdateCommunicationAddress from '../UpdateCommunicationAddress';
import UpdatePan from '../UpdatePan';
import UpdateTan from '../UpdateTan';
import UpdateGSTNumber from '../UpdateGSTNumber';
import EmiDeduction from '../EmiDeduction/EmiDeduction';
import ServiceRequest from '../ServiceRequest/ServiceRequest';
import StopCancelMandate from '../StopCancelMandate';
import SubsequentDisbursement from '../SubsequentDisbursement';
import { useContext } from "react";

// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from '../../../../Shared/context/ThemeContext/store/PageNameContext';
import UpdateDOB from '../UpdateDOB/UpdateDOB';
import GreetingsModal from '../GreetingsModal';
import RcUpdation from '../RcUpdation';
import Others from '../Others';
import ServiceRelatedRequest from '../ServiceRelatedRequest';
import ServiceNOCpopup from '../ServiceNOCpopup/ServiceNOCpopup';
import ServiceRequestpopup from '../ServiceRequestpopup/ServiceRequestpopup';
import LoanClosureLetter from '../LoanClosureLetter/LoanClosureLetter';
import ListOfDocuments from '../ListOfDocuments';
import LoanClosureAndListOfDocs from '../LoanClosureAndListOfDocs';
import ForeclosureStatement from '../ForeclosureStatement/ForeclosureStatement';
import Data from './Data'
import dataObj from '../../../../Shared/screens/Retail-Account-Details/Data';
import commonFunctionObject from '../../common-function';
import CustomerServiceObject from '../../services/CustomerService';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../global/Modal';
import extractDoc from '../../apis/extract-doc/extract-doc';
import DataMapping from '../../../../Shared/context/data-mapping';
import { STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type';
import FailurePopUp from '../../global/FailurePopUp';
import { createContext } from 'react';
import AccountNumberDropDown from '../../global/AccountNumberDropDown/AccountNumberDropDown';
import jsHelper from '../../utilities/jsHelper';
import AnalyticsContextProvider from '../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider';
import PropTypes from 'prop-types';



var serviceRequestObjs = [
    {
        category: "Foreclosure/List Of Documents",
        srList: {
            "Loan closure letter": {
                "product_family": ["TCHFL"],
                "product": [],
                "contract-status": ["active"],
                "type": "Request",
                "tabName": "loan-closure-letter",
                "system": ["retail"]
            },
            "List of Documents": {
                "product_family": ["TCHFL"],
                "product": [],
                "contract-status": ["active"],
                "type": "Request",
                "tabName": "list-of-documents",
                "system": ["retail"]
            },
            "Loan closure letter and list of Documents": {
                "product_family": ["TCHFL"],
                "product": [],
                "contract-status": ["active"],
                "type": "Request",
                "tabName": "loan-closure-letter-and-list-of-documents",
                "system": ["retail"]
            },
        }
    },
    {
        category: "Charges Related",
        srList: {
            "Unpaid charges applied on my loan account": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-charges-applied",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            "Why are legal charges applied to my loan account?": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "faq",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "id": "charges-faq-1",
                "system": ["retail"]

            },

            "I want to understand different entries in my statement of account": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Request",
                "tabName": "understand-different-entries-in-my-soa",
                "contract-status": ["active"],
                "system": ["retail"]

            }
            //Why are legal charges applied to my loan account?

            /* "late payment charges applied on my loan account": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "faq",
                "tabName": "",
                "contract-status": ["active", "close"],
                "url": "https://tclu.tatacapital.com/hosting-mutiple-sr-faq.html#charges-faq-1"
            } */
        }
    },
    {
        category: "EMI & Mandate Related",
        srList: {
            "Want to know my current month EMI clearance Status": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Query",
                "action": "popup",
                "tabName": "list-of-sr",
                "contract-status": ["active"],
                "src": "website",
                "sub-src": "query-emi-clearance-status",
                "resource": "accountdetails",
                "system": ["retail"],
                "popUpId": "emi-clearance-status"
            },
            "Know your EMI Amount": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-emi-amt",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            // "EMI not debited from Account": {
            //     "product_family": ["CFAB"],
            //     "product": [],
            //     "type": "Query",
            //     "tabName": "",
            //     "system": ["retail"],
            //     "contract-status": ["active", "close"],
            //     "src": "website",
            //     "sub-src": "query-emi-not-debited-from-account",
            //     "resource": "overview"
            // },
            // "Account debited for an amount different from EMI": {
            //     "product_family": ["CFAB"],
            //     "product": [],
            //     "type": "Query",
            //     "tabName": "",
            //     "contract-status": ["active", "close"],
            //     "src": "website",
            //     "sub-src": "query-account-debited-different-from-emi",
            //     "resource": "overview",
            //     "system": ["retail"]
            // },
            "Know your EMI Due date": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-emi-due-date",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            "Stop/Cancel Mandate": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Request",
                "tabName": "stop-cancel-mandate",
                "contract-status": ["active", "closed"],
                "system": ["retail"]
            },
            "What is the process to change the EMI Due Date?": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "faq",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "id": "emi-faq-1",
                "system": ["retail"]
            }/* ,
            "Account debited for an amount different from EMI": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "query-faq",
                "tabName": "",
                "contract-status": ["active", "close"],
            }, */,
            // "Reason for EMI bounce": {
            //     "product_family": ["CFAB"],
            //     "product": [],
            //     "type": "Query",
            //     "tabName": "",
            //     "contract-status": ["active", "close"],
            //     "src": "website",
            //     "sub-src": "query-emi-bounce-reason",
            //     "resource": "overview",
            //     "system": ["retail"]
            // },

            // "Request to Change EMI Due date": {
            //     "product_family": ["CFAB"],
            //     "product": ["personal loan".toLowerCase(), "bussiness loan".toLowerCase(), "two wheeler".toLowerCase(), "used car".toLowerCase(), "home equity".toLowerCase(), "consumer durable".toLowerCase()],
            //     "type": "Query",
            //     "tabName": "",s
            //     "contract-status": ["active", "close"],
            //     "src": "website",
            //     "sub-src": "query-change-emi-due-date",
            //     "resource": "accountdetails",
            //     "system": ["retail"]
            // }
        }
    },
    {
        category: "Loan Account Related",
        srList: {
            "What is the current outstanding for my loan account?": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active"],
                "src": "website",
                "sub-src": "query-outstanding-amt",
                "resource": "overview",
                "system": ["retail"]
            },
            "What is the rate of interest on my loan account?": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-rate-of-interest",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            "What is the balance tenure of my loan account?": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-balance-tenure",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            "What is the total tenure of my loan account?": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-total-tenure",
                "resource": "accountdetails",
                "system": ["retail"]
            }
        }
    },
    {
        category: "NOC Related",
        srList: {
            "NOC for Personal Loan Account": {
                "product_family": ["CFAB"],
                "product": ["PERSONAL LOAN".toLowerCase()],
                "type": "Query",
                "tabName": "",
                "contract-status": ["closed"],
                "src": "website",
                "sub-src": "query-noc-pl",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            "NOC for Business Loan Account": {
                "product_family": ["CFAB"],
                "product": ['BUSINESS LOAN'.toLowerCase()],
                "type": "Query",
                "tabName": "",
                "contract-status": ["closed"],
                "src": "website",
                "sub-src": "query-noc-bl",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            "NOC for Consumer Durable Loan Account": {
                "product_family": ["CFAB"],
                "product": ['CONSUMER DURABLES'.toLowerCase(), 'CONSUMER DURABLE'.toLowerCase()],
                "type": "Query",
                "tabName": "",
                "contract-status": ["closed"],
                "src": "website",
                "sub-src": "query-noc-cd",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            "What is the status of my NOC?": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Query",
                "tabName": "list-of-sr",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-noc-status",
                "resource": "accountdetails",
                "system": ["retail"],
                "popUpId": "status-of-NOC"
            },

        }
    },
    {
        category: "Online Payment Related",
        srList: {
            /* "How to make Overdue Payments": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "close"],
                "src": "website",
                "sub-src": "",
                "resource": ""
            },
            "How to transfer amount from Flexi OD account": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "close"],
                "src": "website",
                "sub-src": "",
                "resource": ""
            }, */
            "How do I transfer amount from my Flexi OD account to my bank account?": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "faq",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "id": "online-faq-1",
                "system": ["retail"]
            },
            "What are  the different ways to pay EMI amount online?": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "faq",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "id": "online-faq-2",
                "system": ["retail"]
            },
            // " Want to know the status of my online payment": {
            //     "product_family": ["CFAB"],
            //     "product": [],
            //     "type": "Query",
            //     "tabName": "onlinePayment-tab",
            //     "contract-status": ["active", "close"],
            //     "src": "website",
            //     "sub-src": "query-online-payment-status",
            //     "resource": "customerservice",
            //     "system": ["retail"]
            // }
        }
    },
    {
        category: "Part Payment & Foreclosure Related",
        srList: {
            /* "How to make part payment": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "close"],
                "src": "website",
                "sub-src": "",
                "resource": ""
            }, */
            "What is the process for making part payment?": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "faq",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "id": "payment-faq-1",
                "system": ["retail"]
            },
            "How do I foreclose my loan?": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "faq",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "id": "payment-faq-2",
                "system": ["retail"]
            },
            "What is the Foreclosure charge applicable to my loan?": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Query",
                "tabName": "list-of-sr",
                "contract-status": ["active"],
                "src": "website",
                "sub-src": "sr-foreclosure-charges",
                "resource": "overview",
                "system": ["retail"],
                "popUpId": "foreclosure-charge-applicable-to-loan"
            }
        }
    },
    {
        category: "Contact Details Related",
        srList: {
            "Update Email ID": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "contract-status": ["active"],
                "type": "Request",
                "tabName": "change-email-id",
                "system": ["retail", "ccod"]
            },
            "Update PAN": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Request",
                "contract-status": ["active"],
                "tabName": "change-pan-number",
                "system": ["retail", "ccod"]
            },
            "Update Date of Birth": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Request",
                "contract-status": ["active"],
                "tabName": "change-dob",
                "system": ["retail"]
            },
            "Update Communication Address": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Request",
                "tabName": "change-address",
                "contract-status": ["active"],
                "system": ["retail", "ccod"]
            },
            "Update Mobile number": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Request",
                "tabName": "change-mobile-number",
                "contract-status": ["active"],
                "system": ["retail", "ccod"]
            },
            "Update TAN": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Request",
                "tabName": "change-tan-number",
                "contract-status": ["active"],
                "system": ["retail"]
            },
            "RC Updation": {
                "product_family": ["CFAB"],
                "product": ["TWO WHEELER".toLowerCase(), "USED CAR".toLowerCase()],
                "type": "Request",
                "tabName": "rc-updation",
                "contract-status": ["active", "closed"],
                "system": ["retail"]
            },
            "Update GST Number": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Request",
                "tabName": "change-gst-number",
                "contract-status": ["active"],
                "system": ["ccod"]
            },
        }
    },
    {
        category: "Statement and Certificate Related",
        srList: {
            // "Request for Sanction letter": {
            //     "product_family": ["CFAB"],
            //     "product": [],
            //     "type": "Query",
            //     "tabName": "",
            //     "contract-status": ["active"],
            //     "src": "website",
            //     "sub-src": "query-sanction-letter",
            //     "resource": "overview",
            //     "system": ["retail"]
            // },
            "Request for Statement of Account": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-statement-of-account",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            "Request for Amortization statement": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-amortization-statment",
                "resource": "accountdetails",
                "system": ["retail"]
            },
            "Request for Final Interest certificate": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "closed"],
                "src": "website",
                "sub-src": "query-final-it-cetificate",
                "resource": "overview",
                "system": ["retail"]
            },
            "Request for Welcome Letter": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active"],
                "src": "website",
                "sub-src": "query-welcome-letter",
                "resource": "overview",
                "system": ["retail"]
            },
            "Foreclosure Statement": {
                "product_family": ["CFAB"],
                "product": ["personal loan", "used car", "car loan", "business loan"],
                "type": "request",
                "tabName": "foreclosure-statement",
                "contract-status": ["active"],
                "system": ["retail"]
            }
        }
    },
    /* {
        category: "Loan Termination Related",
        srList: {
            "know your loan termination status": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Query",
                "tabName": "",
                "contract-status": ["active", "close"],
                "src": "website",
                "sub-src": "",
                "resource": ""
            }
        }
    }, */
    {
        category: "Disbursal Related",
        srList: {
            "Subsequent Disbursement": {
                "product_family": ["TCHFL"],
                "product": ["home loan"],
                "type": "request",
                "tabName": "subsequent-disbursement",
                "contract-status": ["active"],
                "system": ["retail"]
            }
        }
    },
    // {
    //     category: "Disbursement",
    //     srList: {
    //         "I have not received credit of my newly disbursed loan amount in my bank account": {
    //             "product_family": ["CFAB"],
    //             "product": [],
    //             "contract-status": ["active"],
    //             "type": "Request",
    //             "tabName": "credit-not-received-req-tab",
    //             "system": ["retail"]
    //         }
    //     }
    // },
    // {
    //     category: "Restructuring Related",
    //     srList: {
    //         /* "Apply for Loan Restructuring": {
    //             "product_family": ["CFAB"],
    //             "product": [],
    //             "type": "request",
    //             "tabName": "reconstruction-api-tab",
    //             "contract-status": ["active"]
    //         }, */
    //         "Grievance of Ex-Gratia": {
    //             "product_family": ["CFAB", "TCHFL"],
    //             "product": [],
    //             "type": "Request",
    //             "tabName": "ex-exGratiaGravience-tab",
    //             "contract-status": ["active"],
    //             "system": ["retail"]
    //         }
    //     }
    // },
    {
        category: "Change your Bank Account for EMI Payment",
        srList: {
            "Change your Bank Account for EMI deduction": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Request",
                "tabName": "change-bank-account-for-emi-deduction",
                "contract-status": ["active"],
                "system": ["retail"]
            }

        }
    },

    {
        category: "Others",
        srList: {
            "Others": {
                "product_family": ["CFAB", "TCHFL"],
                "product": [],
                "type": "request",
                "tabName": "others",
                "contract-status": ["active"],
                "system": ["retail"]
            }
        }
    },
    // {
    //     category: "Track your Service Request",
    //     srList: {
    //         "What is the status of my service request": {
    //             "product_family": ["CFAB"],
    //             "product": [],
    //             "type": "Query",
    //             "tabName": "",
    //             "contract-status": ["active"],
    //             "src": "website",
    //             "sub-src": "query-service-request-status",
    //             "resource": "customerservice",
    //             "system": ["retail"]
    //         }
    //     }
    // },
    {
        category: "Overdraft Loan",
        srList: {
            "I want to extend the drop line date of my flexi loan": {
                "product_family": ["CFAB"],
                "product": [],
                "type": "Request",
                "tabName": "extend-drop-line-flexi-loan",
                "system": ["ccod"],
                "contract-status": ["active"],
            },

        },
    }


];


const ListOfServiceRequest = ({ getDigiUserDocFunc, currentPage, screenName }) => {
    var subsequentAllServiceCall = [];
    var subsequentAccountNumber = [];
    const location = useLocation()
    const query = new URLSearchParams(location.search);
    const [requestRaisedModal, setRequestRaisedModal] = useState(false);
    const [content, setContent] = useState("");
    const { customerServicePageName, setCustomerServicePageName, pageName, setPageName } = useContext(pageNameContext)
    const [themeState] = useContext(ThemeContext);
    const [optionState, setOptionState] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [accordionOpen, setaccordionOpen] = useState(null)

    const [isSearchListOfSr, setIsSearchListOfSr] = useState(false);
    const [newStringListOfSr, setNewStringListOfSr] = useState("");
    const [searchListOfSr, setSearchListOfSr] = useState([])
    let screenState = screenName ? screenName : "list-of-sr"
    const [screen, setScreenState] = useState(screenState);
    const [srPopUp, setSrPopUp] = useState("");
    const [accountNoDropDown, setaccountNoDropDown] = useState([]);
    const [systemFlag, setsystemFlag] = useState("");
    const [activeCurrentContract, setActiveCurrentContract] = useState({})
    const [successSrPop, setSuccessSrPop] = useState(false)
    const [trackReqCom, setTrackReqComp] = useState(false);
    const [disablebutton, setdisablebutton] = useState(false);
    // emi clearnece state
    const [queryModalEmi, setQueryModalEmi] = useState(false)
    const [clickHere, setClickHere] = useState(false)
    var requestObj;

    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    })

    //status of noc state

    const [statusOfNoc, setStatusOfNoc] = useState();
    const [statusOfForclosureLoan, setStatusOfForclosureLoan] = useState();
    const { analytics } = useContext(AnalyticsContextProvider);


    const [pdfData, setPdfData] = useState();
    const [statusOfNocClosed, setatusOfNocClosed] = useState()
    const [data, dispatchData] = useContext(DataMapping);
    const [successpop, setSuccesspop] = useState(false);
    const [querData, setQueryData] = useState({})
    const [email, setEmail] = useState()
    const navigate = useNavigate();

    // let currentContract = dataObj.userData["userData"].TCFPL0485000010002583;
    var currentcontract = data?.currentActiveData.accountNumber

    //var currentUserData = data?.parsedUserData[currentcontract]
    var onlineTranchFlag = false;
    // let email = currentUserData.EMAIL && commonFunctionObject.getLastestFieldValFromField(currentUserData.EMAIL);

    const queryObject = {
        "query-charges-applied": "query-charges-applied",
        "query-welcome-letter": "query-welcome-letter",
        "query-late-payment-charges": "query-late-payment-charges",
        "query-emi-clearance-status": "query-emi-clearance-status",
        "sr-foreclosure-charges": "sr-foreclosure-charges",
        "query-noc-status": "query-noc-status",
        "query-account-debited-different-from-emi": "query-account-debited-different-from-emi",
        "query-emi-not-debited-from-account": "query-emi-not-debited-from-account",
        "sr-understand-different-entries-in-my-soa": "sr-understand-different-entries-in-my-soa",
        "query-outstanding-amt": "query-outstanding-amt",
        "query-charges-applied": "query-charges-applied",
        "query-final-it-cetificate": "query-final-it-cetificate",
        "query-amortization-statment": "query-amortization-statment",
        "query-statement-of-account": "query-statement-of-account",
        "query-sanction-letter": "query-sanction-letter",
        "query-total-tenure": "query-total-tenure",
        "query-balance-tenure": "query-balance-tenure",
        "query-rate-of-interest": "query-rate-of-interest",
        "query-emi-due-date": "query-emi-due-date",
        "query-emi-amt": "query-emi-amt",

    }

    useEffect(() => {

        var currentcontract = data?.currentActiveData.accountNumber
        var currentUserData = data?.parsedUserData[currentcontract]
        setEmail(currentUserData.EMAIL && commonFunctionObject.getLastestFieldValFromField(currentUserData.EMAIL))
        createAccountNoDropDown();
        getOnlineTrancheDisbursement();
        Promise.all(subsequentAllServiceCall).then((trenchDisbursementResponse) => {
            console.log("trenchresponse", trenchDisbursementResponse)
            getOnlineTrancheHandler(trenchDisbursementResponse)
        }).catch((error) => {

        })
    }, [])

    useEffect(() => {
        if (currentPage) {
            if (queryObject.hasOwnProperty(currentPage)) {
                querySwitchCases(queryObject[currentPage], data?.parsedUserData[data.currentActiveData.accountNumber])
            } else {
                setScreenState(currentPage)
            }

        }
    }, [currentPage])


    const handleQueryPopClick = (date) => {

        navigate("/account-details", { state: { redirectionFrom: "Documents" } });
    }

    const serviceRequestFn = (reqestObj, shafterrorObj) => {
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        });

        CustomerServiceObject.getServiceRequest(reqestObj, shafterrorObj, activeCurrentContract).then((value) => {
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            });
            if (value.status.toLowerCase() === "success") {
                var accountNumber = data?.currentActiveData.accountNumber
                var currentUserData = data?.parsedUserData[accountNumber]
                let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
                var gcid = currentUserData.GCID;
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: value.srData.type,
                        componentName: "ListOfServiceRequest",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: "",
                        status: "",
                        ticketNo: value.srData.id,
                        eventName: value.srData.type,
                        serviceName: value.srData.type,
                        requestStatus: value.srData.status
                    }
                }
                analytics.trackData("newserviceRequest", analytics_data);

                const requestObj = {
                    "header": {
                        "authToken": data?.authToken
                    },
                    "body": {
                        "contractNumber": reqestObj?.body?.accountNumber
                    }
                }
                CustomerServiceObject.viewRequestService(requestObj, []).then((response) => {

                    let oldData = data?.viewServiceData

                    let newData = response?.body?.records.sort(function (a, b) {
                        return new Date(b.CreatedDate) - new Date(a.CreatedDate);
                    });
                    oldData.unshift(newData[0])
                    dispatchData({
                        name: "viewServiceData",
                        payload: oldData,
                        type: STOREDATA
                    })
                    navigate('/trackRequest', { state: { respData: value.srData } })
                }).catch(error => {
                    setFailurePopUpContent({
                        apiName: "API Failure-servelet error",
                        message: "We are experiencing technical difficulty please try after sometime",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    });
                })
            } else {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                });
                setRequestRaisedModal(true)
                setContent(value.massege)
            }
        }).catch((error) => {
            setFailurePopUpContent({
                apiName: "service-request/v1",
                message: error.massege,
                errorCode: ""
            })
            setFailurePopUpModal(true)
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            });
        })
    }



    const listSrInputChange = (event) => {
        const value = event.target.value
        // setNewStringListOfSr(value)

        // setSearchListOfSr(serviceRequestObjs.filter(obj => {
        //     obj.category.toLowerCase().includes(value.toLowerCase())
        // }))

        setSearchListOfSr(serviceRequestObjs.filter((obj, index) => {
            let category = obj.category;
            var systemName = systemFlag
            var productFamily = commonFunctionObject.getProductFamily(activeCurrentContract)
            var contractStatusCheck = data?.getLoanPositionDTLS ? data?.getLoanPositionDTLS[data?.currentActiveData.accountNumber] ? commonFunctionObject.checkActiveStatus(data?.getLoanPositionDTLS[data?.currentActiveData.accountNumber]) : "active" : "active";
            var productCheck = [];
            var productName
            if (activeCurrentContract.cdiContract) {
                productName = ""
                productCheck = ["ccod"]
            } else {
                productName = commonFunctionObject.getProductName(activeCurrentContract);
                productCheck = Object.keys(obj.srList).filter(function (srName) {
                    return jsHelper.arrIncludes(obj.srList[srName].product, productName.toLowerCase()) || obj.srList[srName].product.length == 0;
                });
            }

            let sortedArray = Object.keys(obj.srList).filter((val) => {
                return obj.srList[val].system.includes(systemName) && obj.srList[val].product_family.includes(productFamily) && productCheck.length > 0 && obj.srList[val]["contract-status"].includes(contractStatusCheck) ? val : null
            })

            let srListKeys = sortedArray
            let searchString = category;
            srListKeys.forEach(key => searchString += key)
            if (searchString.toLowerCase().includes(value.toLowerCase())) {
                return obj
            }
        }))
        if (value == "") {
            setIsSearchListOfSr(false)
            setaccordionOpen(null)
        }
        else {
            setIsSearchListOfSr(true)
            setaccordionOpen(1)
        }

        // setSearchListOfSr((prevData) => {

        //     let newData = prevData.filter((obj) => {
        //         let category = obj.category;
        //         let srListKeys = Object.keys(obj.srList);
        //         let searchString = category;
        //         srListKeys.forEach(key => searchString += key)
        //         if (searchString.toLowerCase().includes(value.toLowerCase())) {
        //             return obj
        //         }
        //     })
        //     return newData
        // })



        // console.log("searchListOfSr", searchListOfSr);
        // if (searchListOfSr?.length) {
        //     setaccordionOpen(1)
        // } else {
        //     setaccordionOpen(null)
        // }



    }

    const createAccountNoDropDown = () => {
        //setaccountNoDropDown(removeEmptyValue)
        let accountNumber = data?.currentActiveData.accountNumber
        let productName = data?.currentActiveData.system === "retail" ? commonFunctionObject.getProductName(data.parsedUserData[accountNumber]) : ""
        let shortName = data?.currentActiveData.system === "retail" ? commonFunctionObject.getProductShortName(productName) : data?.currentActiveData.system === "ccod" ? "CCOD" : "LAS"
        setSelectedOption(accountNumber + " (" + shortName + ")");
        setsystemFlag(data?.currentActiveData.system)
        setActiveCurrentContract(data?.parsedUserData[data.currentActiveData.accountNumber])

    }

    const storeSelectAccNumberData = (accountNumber) => {
        setsystemFlag(data?.parsedUserData[accountNumber.split(" ")[0]].system)
        setActiveCurrentContract(data?.parsedUserData[accountNumber.split(" ")[0]]);
    }


    const checkSubCategories = (srList, srObject) => {
        var getLoanPositionDtlsData
        let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
        var systemName = systemFlag;
        if (currentContract.cdiContract) {
            var company = currentContract.cdiContract.COMPANY;
            var companyName = "";
            (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
            var productFamily = companyName;
        } else {
            var productFamily = commonFunctionObject.getProductFamily(currentContract);
            getLoanPositionDtlsData = data?.getLoanPositionDTLS[data.currentActiveData.accountNumber]
        }
        let flag = false;
        Object.keys(srList).forEach(value => {
            var contractStatusCheck = getLoanPositionDtlsData && Object.keys(getLoanPositionDtlsData).length !== 0 ? commonFunctionObject.checkActiveStatus(getLoanPositionDtlsData) : "active";
            var productName
            var productCheck = []
            if (currentContract.cdiContract) {
                productName = ""
                productCheck = ["ccod"]
            } else {
                productName = commonFunctionObject.getProductName(currentContract)
                productCheck = Object.keys(srList).filter(function (srName) {
                    return jsHelper.arrIncludes(srList[value].product, productName.toLowerCase()) || srList[value].product.length == 0;
                });
            }

            if (srList[value].system.includes(systemName) && srList[value].product_family.includes(productFamily) && srList[value]["contract-status"].includes(contractStatusCheck) && productCheck.length > 0) {
                switch (value) {
                    case 'Subsequent Disbursement':
                        var checkSubsequentFlag = commonFunctionObject.checkSubsequent(currentContract, data?.subsequentContracts);
                        if (checkSubsequentFlag) {
                            return flag = true
                        } else {
                            return flag = false
                        }
                        break;
                    default: flag = true

                }
                // flag = true
            }
        });
        return flag;
    }

    const popUpSwitchCase = () => {
        switch (srPopUp) {
            case "emi-clearance-status":
                return <GreetingsModal setScreenState={setScreenState} setSrPopUp={setSrPopUp} />
            case "status-of-NOC":
                return <ServiceNOCpopup setScreenState={setScreenState} setSrPopUp={setSrPopUp} />
            case "foreclosure-charge-applicable-to-loan":
                return <ServiceRequestpopup setScreenState={setScreenState} setSrPopUp={setSrPopUp} />
            default:
                return ""
        }
    }

    const querySwitchCases = (category, activeContract) => {

        let currentContract = activeContract ? activeContract : activeCurrentContract
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        var redirectObj
        requestObj = {
            "header": {
                "authToken": data.authToken,
                "serviceRequestType": "",
                "productFamily": commonFunctionObject.getProductFamily(currentContract)
            },
            "body": {
                "gcid": currentContract.GCID,
                "accountNumber": data?.currentActiveData.accountNumber,
                "description": "",
                "webtopId": currentContract.WEBTOP_NO
            }
        }

        switch (category) {
            case "query-emi-clearance-status":
                requestObj["header"]["serviceRequestType"] = "emiClearanceStatus";
                requestObj["header"]["systemId"] = "1";
                emiMonthClrearence(currentContract, requestObj)
                break
            case "query-noc-status": showStatusOfNoc(currentContract, requestObj)
                break
            case "query-emi-amt":
                requestObj["header"]["serviceRequestType"] = "knowYourEmiAmount"
                redirectObj = {
                    pageName: "/account-details",
                    tabName: "Loan Details",
                    //accountNumber: activeCurrentContract.CONTRACT_NUMBER,
                    accountNumber: data?.currentActiveData.accountNumber,
                    redirectFor: "currentInstallment"
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false)
                break
            case "query-emi-due-date":
                requestObj["header"]["serviceRequestType"] = "knowYourEmiDueDate"
                redirectObj = {
                    pageName: "/account-details",
                    tabName: "Loan Details",
                    accountNumber: data?.currentActiveData.accountNumber,
                    redirectFor: "emiduedate"
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false)
                break;
            case "query-outstanding-amt":
                requestObj["header"]["serviceRequestType"] = "outStandingForLoanAccount"
                redirectObj = {
                    pageName: "/overview",
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false)
                break;
            case "query-rate-of-interest":
                requestObj["header"]["serviceRequestType"] = "rateOfInterestOnLoanAccount"
                redirectObj = {
                    pageName: "/account-details",
                    tabName: "Loan Details",
                    accountNumber: data?.currentActiveData.accountNumber,
                    redirectFor: "rateofinterestloanaccount"
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false);
                break;
            case "query-balance-tenure":
                requestObj["header"]["serviceRequestType"] = "balanceTenureOnLoanAccount"
                redirectObj = {
                    pageName: "/account-details",
                    tabName: "Loan Details",
                    accountNumber: data?.currentActiveData.accountNumber,
                    redirectFor: "balancetenureonloanaccount"
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false)
                break;
            case "query-total-tenure":
                requestObj["header"]["serviceRequestType"] = "totalTenureOfLoanAccount"
                redirectObj = {
                    pageName: "/account-details",
                    tabName: "Loan Details",
                    accountNumber: data?.currentActiveData.accountNumber,
                    redirectFor: "totaltenureofloanaccount"
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false)
                break;
            case "query-sanction-letter":
                requestObj["header"]["serviceRequestType"] = "requestForSanctionLetter"
                redirectObj = {
                    pageName: "/overview",
                    redirectFor: "Sanction Letter"
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false)
                break;
            case "query-statement-of-account":

                requestObj["header"]["serviceRequestType"] = "statementOfAccount"
                redirectObj = {
                    pageName: "/account-details",
                    tabName: "Documents",
                    accountNumber: data?.currentActiveData.accountNumber,
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false)
                break;
            case "query-amortization-statment":
                requestObj["header"]["serviceRequestType"] = "amortizationSchedule"
                redirectObj = {
                    pageName: "/account-details",
                    tabName: "Documents",
                    accountNumber: data?.currentActiveData.accountNumber,
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false)
                break;
            case "query-final-it-cetificate":
                requestObj["header"]["serviceRequestType"] = "finalInterestCertificate"
                redirectObj = {
                    pageName: "/overview",
                    redirectFor: "Final IT Certificate"
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false);
                break;
            case "query-welcome-letter":
                requestObj["header"]["serviceRequestType"] = "welcome"
                redirectObj = {
                    pageName: "/overview",
                    redirectFor: "Welcome Letter"
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false);
                break;
            case "query-charges-applied":
                requestObj["header"]["serviceRequestType"] = "unpaidChargesForLoanAccount"
                redirectObj = {
                    pageName: "/account-details",
                    tabName: "split-charges",
                    accountNumber: data?.currentActiveData.accountNumber,
                }
                srApiCallOrRedirection(requestObj, true, redirectObj, false)
                break;

            case "sr-foreclosure-charges":
                requestObj["header"]["serviceRequestType"] = "foreclosureCharges";
                requestObj["header"]["subServiceRequestType"] = "sanctionLetterAvailable";
                requestObj["header"]["systemId"] = "1";

                initSanctionLtrFn(currentContract, requestObj,);
                break;
            case "query-noc-pl":
            case "query-noc-bl":
            case "query-noc-cd":
                navigate("/account-details", { state: { redirectionFrom: "Documents" } });
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                break
        }

    }

    function showStatusOfNoc(currentContract, requestObj) {
        // 

        var getLoanData = data?.getLoanPositionDTLS[commonFunctionObject.appendZeros(currentContract.CONTRACT_NUMBER)].body.getLoanPositionDtlsReturn.loanStatus
        var plBlCdArr = ["personal loan", "bussinesscurrentContract loan", "consumer durable"];
        var productName = commonFunctionObject.getProductName(currentContract);
        var productFamily = commonFunctionObject.getProductFamily(currentContract)
        var byteArr, fileName, mimeType;
        // var pTag = document.getElementById('PopPara');
        var contractStatus = commonFunctionObject.checkContractStatus(getLoanData)
        if (!contractStatus && plBlCdArr.includes(productName.toLowerCase())) {
            var subServiceRequestType = ""
            if (productName.toUpperCase() === "PERSONAL LOAN") {
                subServiceRequestType = "Personal Loan"
            } else if (productName.toUpperCase() === "BUSINESS LOAN") {
                subServiceRequestType = "Business Loan"
            } else {
                subServiceRequestType = "Consumer Durable"
            }
            requestObj["header"]["serviceRequestType"] = "nocStatusForTerminatedContracts";
            requestObj["header"]["subServiceRequestType"] = subServiceRequestType;
            requestObj["header"]["systemId"] = "1";
            if (currentContract.WEBTOP_NO) {
                let reqObj = {
                    "header": {
                        "authToken": data.authToken,
                        "productFamily": productFamily,
                        "emailSent": true,
                    },
                    "body": {
                        "webtopId": currentContract.WEBTOP_NO,
                        "documentUploadType": "NDC",
                        "loanAccountNumber": currentContract.CONTRACT_NUMBER,
                    }
                }
                extractDoc(reqObj).then(function (response) {
                    if (response) {
                        if (response?.header.status == "SUCCESS") {
                            if (response?.body.retStatus.toUpperCase() == "SUCCESS") {
                                if (response?.body.dmsResponse) {
                                    var pdf = response.body.dmsResponse.DMSRESPONSE;
                                    byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
                                    fileName = "NOC.pdf";
                                    mimeType = "application/pdf";
                                    let pfdData = {
                                        byteArr,
                                        fileName,
                                        mimeType
                                    }
                                    setPdfData(pfdData)
                                    setatusOfNocClosed(true)
                                    //   pTag.innerHTML = 'To download your NDC (No Dues Certificate)  Please click <span id="clickhere">here</span>.'
                                    // setTimeout(function () {
                                    //     commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
                                    //     var data = {
                                    //         "documentName": "NOC",
                                    // "productFamily": "cfab",
                                    // "decscription": "Generate NOC letter for " + currentContract.accountNumber + " loan account.",
                                    // "sebServiceRequestType": "nocLetter",
                                    // "webtopId": currentContract.productValues.WEBTOP_NO,
                                    // }
                                    // serviceDetails.erroCode = "256";
                                    //   showTriggerEmailPopUp(data, response.body.isEmailAvailable, currentContract.accountNumber, getLastestFieldValFromField(currentContract.productValues.EMAIL))

                                    //   byteArr = []
                                    //   $('#multiple-query-pop').modal('hide');

                                    // });
                                } else {
                                    setFailurePopUpContent({
                                        apiName: "service-request/v1",
                                        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                                        errorCode: ""
                                    })
                                    setFailurePopUpModal(true)
                                    dispatchData({
                                        name: "loaderFlag",
                                        payload: false,
                                        type: STOREDATA
                                    });
                                }
                            } else {
                                setFailurePopUpContent({
                                    apiName: "service-request/v1",
                                    message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                                    errorCode: ""
                                })
                                setFailurePopUpModal(true)
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA
                                });
                            }
                        } else {
                            if (response?.errorBody) {
                                if (response?.errorBody?.message) {
                                    var jsonParsed = JSON.parse(response.errorBody.message);
                                    if (jsonParsed?.retStatus) {
                                        if (jsonParsed?.retStatus === "ERROR") {
                                            setFailurePopUpContent({
                                                apiName: "service-request/v1",
                                                message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                                                errorCode: "22"
                                            })
                                            setFailurePopUpModal(true)
                                            dispatchData({
                                                name: "loaderFlag",
                                                payload: false,
                                                type: STOREDATA
                                            });
                                        } else {
                                            setFailurePopUpContent({
                                                apiName: "service-request/v1",
                                                message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                                                errorCode: ""
                                            })
                                            setFailurePopUpModal(true)
                                            dispatchData({
                                                name: "loaderFlag",
                                                payload: false,
                                                type: STOREDATA
                                            });
                                        }
                                    } else {
                                        setFailurePopUpContent({
                                            apiName: "service-request/v1",
                                            message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                                            errorCode: ""
                                        })
                                        setFailurePopUpModal(true)
                                        dispatchData({
                                            name: "loaderFlag",
                                            payload: false,
                                            type: STOREDATA
                                        });
                                    }
                                }
                            }

                            //   $("body").removeClass("loader");
                        }
                    } else {
                        console.warn("response not found");
                    }
                    // }).catch(function (error) {
                    //   console.warn("error: ", error);
                    //   $("body").removeClass("loader");
                    //   serviceError = {
                    //     "type": "api-failure: get-bo-url",
                    //     "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    //     "code": "E-2005-A",
                    //     "errorId": "17"
                    //   }
                    //   setAnalyticsErrorLog();
                    //   displayErrorModal(serviceError);
                })
            } else {
                // $("body").removeClass("loader");
                // serviceError = {
                //   "type": "",
                //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                //   "code": "",
                //   "errorId": ""
                // }
                // displayErrorModal(serviceError);
            }
        } else {

            //   pTag.innerHTML = 'NOC is issued only after the loan account is closed / terminated with no dues. Your loan account is still “Active”. NOC is not applicable. To check you loan account details and status, please click <span id="clickhere">here</span>'
            //   setTimeout(function () {
            //     var button = document.getElementById('clickhere');
            //     button.addEventListener('click', function () {
            //       var redirectURL = "account-details.html";
            //       dataStore.set("changeRequestTab", subSrc);
            //       dataStore.set("changeAccountNumber", accountNumber);
            //       dataStore.set("redirectFor", accountNumber + "-emi-query-status");
            //       dataStore.set("redirectAccount", accountNumber);
            //     //   location.href = redirectURL;
            //     })
            //   }, );
            //   requestObj["service-request/v1"]["header"]["serviceRequestType"] = "nocStatusForActiveContracts";
            //   srApiCallOrRedirection(requestObj, false, "", true, "multiple-query-pop")
            // $('#multiple-query-pop').modal('show');
            //   setStatusOfNoc(true)
            requestObj["header"]["serviceRequestType"] = "nocStatusForActiveContracts";
            srApiCallOrRedirection(requestObj, false, "", setStatusOfNoc)

        }
        // $('#multiple-sr-pop').modal('hide');
    }

    const emiMonthClrearence = (currentContract, requestObj) => {
        // 
        if (commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[currentContract.CONTRACT_NUMBER].body.getLoanPositionDtlsReturn.nextEmiDate, "amount") !== "₹ 0") {
            var today, finalemiDate;
            today = new Date();
            var emiDate = data?.getLoanPositionDTLS[currentContract.CONTRACT_NUMBER].body.getLoanPositionDtlsReturn.nextEmiDate
            var splitEmiDate = (emiDate).split('/');
            finalemiDate = new Date();
            finalemiDate.setFullYear(splitEmiDate[2], splitEmiDate[1] - 1, splitEmiDate[0]);
            let popText = ""
            if (finalemiDate > today) {
                setClickHere(true)
                srApiCallOrRedirection(requestObj, false, "", setQueryModalEmi)
            } else {
                setClickHere(false)
                srApiCallOrRedirection(requestObj, false, "", setQueryModalEmi)
            }
        } else {
            console.log("emi date not found");
        }
    }

    function srApiCallOrRedirection(requestObj, refreshPage, redirectionObj, popUpFlag) {

        CustomerServiceObject.serviceRequestService(requestObj).then(function (response) {

            //   $("body").removeClass("loader");
            // 
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            if (refreshPage) {
                // navigate("/account-details", { state: { tabName: "Loan Details", loanAccNumber: activeCurrentContract.CONTRACT_NUMBER, redirectFor: "statusOfnoc" } })
                navigate(redirectionObj.pageName, { state: { tabName: redirectionObj?.tabName, loanAccNumber: redirectionObj?.accountNumber, redirectFor: redirectionObj?.redirectFor } })
                // location.href = redirectURL;
            } else {
                // TODO
            }
            if (popUpFlag) {
                // $('#' + popUp + '').modal('show');
                popUpFlag(true)
            }
        }).catch(function (error) {
            //   console.error("ERROR!!!..", error);
        })
    }

    function initSanctionLtrFn(currentContract, requestObj, redirectObj) {

        var productFamily = commonFunctionObject.getProductFamily(currentContract)
        var byteArr, fileName, mimeType;
        if (currentContract.WEBTOP_NO) {
            if (productFamily === "CFAB") {
                let reqObj = {
                    "header": {
                        "authToken": data.authToken,
                        "productFamily": productFamily,
                        "emailSent": true,
                    },
                    "body": {
                        "webtopId": currentContract.WEBTOP_NO,
                        "documentUploadType": "SANCTION",
                        "loanAccountNumber": currentContract.CONTRACT_NUMBER,
                    }
                }
                extractDoc(reqObj).then(function (response) {
                    if (response) {
                        if (response?.header.status == "SUCCESS") {
                            if (response?.body.retStatus.toUpperCase() == "SUCCESS") {
                                if (response?.body.dmsResponse) {
                                    var pdf = response.body.dmsResponse.DMSRESPONSE;
                                    byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
                                    fileName = "Sanction Letter.pdf";

                                    let queryStoreObj = {
                                        fileData: {
                                            fileName: "Sanction Letter.pdf",
                                            mimeType: "application/pdf",
                                            byteArr: byteArr
                                        }
                                    }

                                    setQueryData(queryStoreObj)

                                    setStatusOfForclosureLoan(true)
                                    setSuccesspop(true);

                                } else {
                                    alert("We Are Experiencing Technical Difficulty. Please Try After Sometime.")
                                }
                            } else {
                                requestObj["header"]["subServiceRequestType"] = "sanctionLetterNotAvailable";
                                let queryStoreObj = {
                                    reqObj: requestObj,
                                    redirectObj: redirectObj
                                }
                                setQueryData(queryStoreObj)
                                setStatusOfForclosureLoan(true);
                                setSuccesspop(false);
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA
                                })
                            }
                        } else {
                            if (response?.errorBody) {
                                requestObj["header"]["subServiceRequestType"] = "sanctionLetterNotAvailable";
                                let queryStoreObj = {
                                    reqObj: requestObj,
                                    redirectObj: redirectObj
                                }
                                setQueryData(queryStoreObj)
                                if (response?.errorBody?.message) {
                                    var jsonParsed = JSON.parse(response.errorBody.message);
                                    if (jsonParsed?.retStatus) {
                                        if (jsonParsed?.retStatus === "ERROR") {
                                            setStatusOfForclosureLoan(true);
                                            setSuccesspop(false);
                                            dispatchData({
                                                name: "loaderFlag",
                                                payload: false,
                                                type: STOREDATA
                                            })
                                        } else {
                                            setStatusOfForclosureLoan(true);
                                            setSuccesspop(false);
                                            dispatchData({
                                                name: "loaderFlag",
                                                payload: false,
                                                type: STOREDATA
                                            })
                                        }
                                    } else {
                                        setStatusOfForclosureLoan(true);
                                        setSuccesspop(false);
                                        dispatchData({
                                            name: "loaderFlag",
                                            payload: false,
                                            type: STOREDATA
                                        })
                                    }
                                }
                            }
                        }
                    } else {
                        console.warn("response not found");
                    }
                })

            }
        } else {
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            setFailurePopUpContent({
                apiName: "",
                message: "We are experiencing technical difficulty please try after sometime",
                errorCode: ""
            })
            setFailurePopUpModal(true);
        }

    }

    const screenSwitchCase = () => {
        switch (screen) {
            case "list-of-sr":
                return (
                    <div className='list-of-sr-section'>
                        <div className='sr-acc-search-section'>
                            <AccountNumberDropDown
                                optionArray={data?.accountNumberDropDownArray}
                                optionState={optionState}
                                setOptionState={setOptionState}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                labelFlag={true}
                                labelText="Account Number"
                                storeSelectAccNumberData={storeSelectAccNumberData}
                                bankLas={true}

                            />
                            <Searchbar button={true} className="sr-seacrhbar" placeholder="Search for Services" forComponent="searchListOfSr" onListSrInputChange={listSrInputChange} />
                        </div>
                        <div className='list-of-sr-content'>
                            <p className='list-sr-title'>Service Requests</p>
                            <div className='sr-content-parent'>
                                <div className='sr-content-scroll'>
                                    {/* {
                                        isSearchListOfSr ? searchListOfSr.map((obj, index) => <Accordion serviceRequestOption={serviceRequestOption[index]?.options} accordionTitle={obj.accordionTitle} open={accordionOpen} toggleOpen={setaccordionOpen} accordionType={"Servicerequest"} id={index + 1} key={index} className={'sr-list-accordion'} setScreenState={setScreenState} setSrPopUp={setSrPopUp} />) :
                                            listOfSrArray.map((obj, index) => <Accordion serviceRequestOption={serviceRequestOption[index]?.options} accordionTitle={obj.accordionTitle} open={accordionOpen} toggleOpen={setaccordionOpen} accordionType={"Servicerequest"} id={index + 1} key={index} className={'sr-list-accordion'} setScreenState={setScreenState} setSrPopUp={setSrPopUp} />)
                                    } */}
                                    {

                                        isSearchListOfSr ? searchListOfSr.filter((obj) => {
                                            if (checkSubCategories(obj.srList, activeCurrentContract, searchListOfSr)) {
                                                return obj
                                            }
                                        }).map((obj, index) => <Accordion serviceRequestOption={obj.srList} serviceRequestObjsCategory={obj.category} open={accordionOpen} toggleOpen={setaccordionOpen} accordionType={"Servicerequest"} id={index + 1} key={index} className={'sr-list-accordion'} setScreenState={setScreenState} setSrPopUp={setSrPopUp} currentContract={activeCurrentContract} systemFlag={systemFlag} querySwitchCases={querySwitchCases} />) :
                                            serviceRequestObjs.map((obj, index) => checkSubCategories(obj.srList, activeCurrentContract, serviceRequestObjs) && <Accordion serviceRequestOption={obj.srList} serviceRequestObjsCategory={obj.category} open={accordionOpen} toggleOpen={setaccordionOpen} accordionType={"Servicerequest"} id={index + 1} key={index} className={'sr-list-accordion'} setScreenState={setScreenState} setSrPopUp={setSrPopUp} currentContract={activeCurrentContract} systemFlag={systemFlag} querySwitchCases={querySwitchCases} />)
                                    }


                                </div>
                            </div>
                        </div>
                        {
                            popUpSwitchCase()
                        }

                    </div>
                )
            case "change-email-id":
                return <UpdateEmailID setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "change-mobile-number":
                return <ChangeMobileNumber setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "change-pan-number":
                return <UpdatePan setScreenState={setScreenState} activeComp={"PAN"} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} getDigiUserDocFunc={getDigiUserDocFunc} />
            case "change-address":
                return <UpdateCommunicationAddress setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeComp={"ADDRESS"} activeCurrentContract={activeCurrentContract} getDigiUserDocFunc={getDigiUserDocFunc} />
            case "change-tan-number":
                return <UpdateTan setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "change-gst-number":
                return <UpdateGSTNumber setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "change-dob":
                return <UpdateDOB setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeComp={"DOB"} activeCurrentContract={activeCurrentContract} getDigiUserDocFunc={getDigiUserDocFunc} />
            case "change-bank-account-for-emi-deduction":
                return <EmiDeduction setScreenState={setScreenState} activeCurrentContract={activeCurrentContract} />
            // case "flexi-od-account":
            //     return <ServiceRequest setScreenState={setScreenState} />
            case "stop-cancel-mandate":
                return <StopCancelMandate setScreenState={setScreenState} />
            case "subsequent-disbursement":
                return <SubsequentDisbursement setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} />
            case "rc-updation":
                return <RcUpdation setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "others":
                return <Others setScreenState={setScreenState} currentContract={activeCurrentContract} serviceRequestFn={serviceRequestFn} />
            case "understand-different-entries-in-my-soa":
                return <ServiceRequest setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "extend-drop-line-flexi-loan":
                return <ServiceRelatedRequest setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "loan-closure-letter":
                return <LoanClosureLetter setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "list-of-documents":
                return <ListOfDocuments setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "loan-closure-letter-and-list-of-documents":
                return <LoanClosureAndListOfDocs setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            case "foreclosure-statement":
                return <ForeclosureStatement setScreenState={setScreenState} serviceRequestFn={serviceRequestFn} activeCurrentContract={activeCurrentContract} />
            default:
                return (
                    <div className='list-of-sr-section'>
                        <div className='sr-acc-search-section'>
                            <AccountNumberDropDown
                                optionArray={data?.accountNumberDropDownArray}
                                optionState={optionState}
                                setOptionState={setOptionState}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                labelFlag={true}
                                labelText="Account Number"
                                storeSelectAccNumberData={storeSelectAccNumberData}

                            />
                            <Searchbar button={true} className="sr-seacrhbar" placeholder="Search for Services" forComponent="searchListOfSr" onListSrInputChange={listSrInputChange} />
                        </div>
                        <div className='list-of-sr-content'>
                            <p className='list-sr-title'>Service Requests</p>
                            <div className='sr-content-scroll'>
                                {/* {
                                    isSearchListOfSr ? searchListOfSr.map((obj, index) => <Accordion serviceRequestOption={serviceRequestOption[index]?.options} accordionTitle={obj.accordionTitle} open={accordionOpen} toggleOpen={setaccordionOpen} accordionType={"Servicerequest"} id={index + 1} key={index} className={'sr-list-accordion'} setScreenState={setScreenState} setSrPopUp={setSrPopUp} />) :
                                        listOfSrArray.map((obj, index) => <Accordion serviceRequestOption={serviceRequestOption[index]?.options} accordionTitle={obj.accordionTitle} open={accordionOpen} toggleOpen={setaccordionOpen} accordionType={"Servicerequest"} id={index + 1} key={index} className={'sr-list-accordion'} setScreenState={setScreenState} setSrPopUp={setSrPopUp} />)
                                } */}
                                {

                                    isSearchListOfSr ? searchListOfSr.map((obj, index) => checkSubCategories(obj.srList, activeCurrentContract, searchListOfSr) && <Accordion serviceRequestOption={obj.srList} serviceRequestObjsCategory={obj.category} open={accordionOpen} toggleOpen={setaccordionOpen} accordionType={"Servicerequest"} id={index + 1} key={index} className={'sr-list-accordion'} setScreenState={setScreenState} setSrPopUp={setSrPopUp} currentContract={activeCurrentContract} systemFlag={systemFlag} />) :
                                        serviceRequestObjs.map((obj, index) => checkSubCategories(obj.srList, activeCurrentContract, serviceRequestObjs) && <Accordion serviceRequestOption={obj.srList} serviceRequestObjsCategory={obj.category} open={accordionOpen} toggleOpen={setaccordionOpen} accordionType={"Servicerequest"} id={index + 1} key={index} className={'sr-list-accordion'} setScreenState={setScreenState} setSrPopUp={setSrPopUp} currentContract={activeCurrentContract} systemFlag={systemFlag} />)
                                }


                            </div>
                        </div>
                        {
                            popUpSwitchCase()
                        }

                    </div>
                )
        }
    }


    const statusOfNocClickHandle = () => {
        navigate("/account-details", { state: { tabName: "Loan Details", loanAccNumber: activeCurrentContract.CONTRACT_NUMBER, redirectFor: "overdueChargeAmount" } })
    }

    const onClosePopUp = () => {

        setRequestRaisedModal(false);
        setCustomerServicePageName('banner');
        navigate("/customer-service")
    }

    const statusOfNocClosedHandle = () => {

        let pdf = pdfData.pfdData
        commonFunctionObject.saveByteArray(pdf.fileName, pdf.byteArr, pdf.mimeType);
        navigate("/customer-service")
    }

    const getOnlineTrancheDisbursement = () => {
        if (data.getLoanPositionDTLS) {
            if (data?.openContracts) {
                Object.keys(data?.openContracts).forEach(function (val) {
                    let value = data?.parsedUserData[val]
                    var productName = commonFunctionObject.getProductName(value);
                    var productFamily = commonFunctionObject.getProductFamily(value)
                    console.log(productName)
                    console.log(productFamily)
                    var dataAccNo = '';
                    if ((productFamily) && productFamily.toUpperCase() === 'TCHFL') {
                        dataAccNo = commonFunctionObject.appendZeros(value.CONTRACT_NUMBER);
                    } else if ((value.productInfo) && value.productInfo.PRODUCT_CODE === '7201') {
                        dataAccNo = commonFunctionObject.appendZeros(value.CONTRACT_NUMBER);
                    } else if (value.PRODUCT_CODE === '7201') {
                        dataAccNo = commonFunctionObject.appendZeros(value.CONTRACT_NUMBER);
                    } else {
                        dataAccNo = value.CONTRACT_NUMBER;
                    }
                    if (productName.toUpperCase() === "HOME LOAN") {

                        onlineTranchFlag = true;
                        var reqObj = {
                            "header": {
                                "authToken": data?.authToken,
                            },
                            "body": {
                                "contractNo": value.CONTRACT_NUMBER,
                            }
                        }
                        subsequentAccountNumber.push(dataAccNo);
                        dispatchData({
                            name: "loaderFlag",
                            payload: true,
                            type: STOREDATA
                        })
                        CustomerServiceObject.getTrancheDisbursementService(reqObj, subsequentAllServiceCall).then(function (response) {
                            // dispatchData({
                            //     name: "loaderFlag",
                            //     payload: true,
                            //     type: STOREDATA
                            // })
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA
                            })

                        }).catch(function (error) {
                            // dispatchData({
                            //     name: "loaderFlag",
                            //     payload: false,
                            //     type: STOREDATA
                            // })
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA
                            })

                        })


                    }
                })
            }

        }
    }

    const getOnlineTrancheHandler = (trenchDisbursementResponse) => {

        var subsequentContracts = {};
        var totalAmountDisbursed;
        trenchDisbursementResponse.forEach(function (response, index) {
            if (response) {
                if (response.header.status.toUpperCase() == "SUCCESS") {
                    console.log("trenchres", response)
                    if (response.body.retStatus.toUpperCase() == "SUCCESS") {
                        var loanpurposeArray = ["Home Builder Purchase(Under construction)".toLowerCase(), "HL Builder Purchase (UC)".toLowerCase(), "HL Builder Purchase (ADF)".toLowerCase()
                            , "HL Plot Purchase and Constn".toLowerCase(), "HL Self Construction".toLowerCase()
                            , "Home Construction".toLowerCase(), "HL Plot And Self Construction".toLowerCase(),
                        "Home Plot Purchase and Const.".toLowerCase(), "Home Construction".toLowerCase(), "HL Plot Purchase and Construction".toLowerCase(), "HL Self Const.".toLowerCase()];
                        var collateralSubtypeArray = ["Builder Property under Construction".toLowerCase()];
                        var checkLoanpurposeArray = loanpurposeArray.includes(response.body.trancheDisbursementDetails[0].LOAN_PURPOSE.trim().toLowerCase());
                        var checkCollateralSubtypeArray = collateralSubtypeArray.includes(response.body.trancheDisbursementDetails[0].COLLATERAL_SUBTYPE.trim().toLowerCase());
                        // 
                        if (checkLoanpurposeArray || checkCollateralSubtypeArray) {
                            var totalSanctionedAmt = response.body.trancheDisbursementDetails[0].Total_Sanctioned_Amt;
                            if (response.body.trancheDisbursementDetails[0].Total_Disbursed_Amt.trim() !== "") {
                                totalAmountDisbursed = response.body.trancheDisbursementDetails[0].Total_Disbursed_Amt;
                            } else {
                                totalAmountDisbursed = 0;
                            }
                            var disbursalAmountCheck = totalSanctionedAmt - totalAmountDisbursed;
                            subsequentContracts[subsequentAccountNumber[index]] = {
                                "BUILDER_NAME": response.body.trancheDisbursementDetails[0].BUILDER_NAME,
                                "BUILDER_CATEGORY": response.body.trancheDisbursementDetails[0].BUILDER_CATEGORY,
                                "PROJECT_NAME": response.body.trancheDisbursementDetails[0].PROJECT_NAME,
                                "CITY": response.body.trancheDisbursementDetails[0].CITY,
                                "LOAN_PURPOSE": response.body.trancheDisbursementDetails[0].LOAN_PURPOSE,
                                "COLLATERAL_SUBTYPE": response.body.trancheDisbursementDetails[0].COLLATERAL_SUBTYPE,
                                "Total_Sanctioned_Amt": response.body.trancheDisbursementDetails[0].Total_Sanctioned_Amt,
                                "TOTAL_AMOUNT_Disbursed": disbursalAmountCheck,
                                "Total_Disbursed_amt": response.body.trancheDisbursementDetails[0].Total_Disbursed_amt,
                                "IS_ABLE": true
                            }
                            console.log("subsequentContracts : ", subsequentContracts);
                            dispatchData({
                                type: STOREDATA,
                                name: "subsequentContracts",
                                payload: subsequentContracts

                            })
                        }
                    }
                } else if (response.header.status == "FAILURE") {
                    if (response.errorBody) {
                        if (response.errorBody.message) {
                            var jsonParsed = JSON.parse(response.errorBody.message);
                            if (response.errorBody.statusCode == "-1") {
                                setFailurePopUpContent({
                                    apiName: "get-trench-disbursement/v1",
                                    message: "We are experiencing technical difficulty please try after sometime",
                                    errorCode: "166"
                                })
                                setFailurePopUpModal(true)

                            } else if (jsonParsed.retStatus.toLowerCase() !== "error" && jsonParsed.errorMessage.toLowerCase() !== "no data found") {
                                setFailurePopUpContent({
                                    apiName: "get-trench-disbursement/v1",
                                    message: "We are experiencing technical difficulty please try after sometime",
                                    errorCode: "167"
                                })
                                setFailurePopUpModal(true)
                            }
                        }


                    } else {

                    }

                }
                else { }

            } else {

            }

            // dataStore.set('subsequentContracts', subsequentContracts);

        })
    }

    const ForclosureLoan = () => {
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        setStatusOfForclosureLoan(false)

        let querdata = querData;
        CustomerServiceObject.sanctionNotAvailableSr(querData.reqObj).then((value) => {
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            });
            setRequestRaisedModal(true)
            setContent(value.massege)
        }).catch((err) => {
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            });
            setRequestRaisedModal(true)
            setContent(err.massege)
        })

    }

    const downloadSanctionletter = (pdf, byteArr) => {
        setStatusOfForclosureLoan(false)
        commonFunctionObject.saveByteArray(querData.fileData.fileName, querData.fileData.byteArr, querData.fileData.mimeType)
    }

    const redirectforclosure = () => {
        setStatusOfForclosureLoan(false)
        setScreenState("change-email-id")
    }

    return (
        <>
            {screenSwitchCase()}
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {requestRaisedModal ? <CustomerServicePopUp
                onClick={() => onClosePopUp()}
                visible={requestRaisedModal}
                setModalStat={setRequestRaisedModal}
                Content={content}

            /> : null}

            {
                queryModalEmi ?
                    <GreetingsModal setScreenState={setScreenState} setSrPopUp={setSrPopUp} clickHere={clickHere} activeCurrentContract={activeCurrentContract} handleQueryPopClick={handleQueryPopClick} setQueryModalEmi={setQueryModalEmi} /> : null
            }

            {/* status of noc pop */}

            {
                statusOfNoc ? <Modal>
                    <div className='status-of-noc-modal-popup' style={{
                        background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
                    }}>
                        <div>
                            <div className='close-btn' onClick={() => setStatusOfNoc(false)}>
                                {/* <img src="assets/images/crossImg.svg" alt="close"  /> */}
                                <img src={"assets/images/crossImg.svg"} alt="close" />
                            </div>
                            <h1 style={{
                                color: `${themeState === "THEME_DARK" ? "#ffffff" : ""}`,
                            }} className="status-of-noc-modal-popup-heading">Greetings from Tata Capital!</h1>
                        </div>

                        <div className='description' style={{
                            color: `${themeState === "THEME_DARK" ? "#ffffff" : ""}`,
                        }}>
                            <p>
                                NOC is issued only after the loan account is closed / terminated with no dues. Your loan account is still “Active”. NOC is not applicable. To check you loan account details and status, please click <span onClick={statusOfNocClickHandle}> here</span>
                            </p>
                        </div>
                        {/* <div className='proceed'>
                            <a href='https://www.godigit.com/illness-group-insurance?ts_v=202107307190837&api_key=TSNRYIWTZUCZ2BD5SCCCJTLUN1QPDNYK&imd_code=1045466&utm_source=Partner&utm_medium=TATA1_HQ&utm_campaign=corona&utm_content=1045466' target='_blank'>Proceed</a>
                        </div> */}
                    </div>
                </Modal> : null
            }

            {
                statusOfNocClosed ? <Modal>
                    <div className='status-of-noc-modal-popup'>
                        <div className='close-btn' onClick={() => setatusOfNocClosed(false)}>
                            {/* <img src="assets/images/crossImg.svg" alt="close"  /> */}
                            <img src={"assets/images/crossImg.svg"} alt="close" />
                        </div>
                        <div className='description'>
                            <p>
                                To download your NDC (No Dues Certificate)  Please click <span style={{ color: "blue" }} onClick={statusOfNocClosedHandle}>here</span>
                            </p>
                        </div>
                        {/* <div className='proceed'>
                            <a href='https://www.godigit.com/illness-group-insurance?ts_v=202107307190837&api_key=TSNRYIWTZUCZ2BD5SCCCJTLUN1QPDNYK&imd_code=1045466&utm_source=Partner&utm_medium=TATA1_HQ&utm_campaign=corona&utm_content=1045466' target='_blank'>Proceed</a>
                        </div> */}
                    </div>
                </Modal> : null
            }
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}

            {
                statusOfForclosureLoan ? <Modal>
                    <div className='status-of-forclosure-modal-popup' style={{
                        background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
                    }}>
                        <div className='close-btn' onClick={() => setStatusOfForclosureLoan(false)}>
                            <img src={"assets/images/crossImg.svg"} alt="close" />
                        </div>
                        <div style={{
                            color: `${themeState === "THEME_DARK" ? "#ffffff" : ""}`,
                        }}>
                            <h1 style={{
                                color: `${themeState === "THEME_DARK" ? "#ffffff" : ""}`,
                            }}>Greetings from Tata Capital!</h1>
                            {
                                successpop ? <p className='description'> You can check the foreclosure charges that is applicable to your loan account by referring the ‘Sanction Letter’. The foreclosure charges (if any) will be levied once you opt to foreclose your loan contract based on the terms & condition mentioned in the sanction letter. To download the sanction letter, please <a onClick={downloadSanctionletter}>click here</a>.</p> :
                                    <><p className='description'>
                                        You can check the foreclosure charges that is applicable to your loan account by referring the ‘Sanction Letter’. The foreclosure charges (if any) will be levied once you opt to foreclose your loan contract based on the terms & condition mentioned in the sanction letter. Please click on the ‘Submit’ button to request for your sanction letter. We will share the “Sanction Letter’ on your registered email id with us.
                                    </p>

                                        <p className="mail-id">Registered Email Id:</p>
                                        <div className="email-value-container">
                                            <div className="email-value">
                                                <input disabled value={email} />
                                            </div>
                                            <img style={{
                                                filter: `${themeState === "THEME_DARK" ? "invert(1)" : ""}`,
                                            }} src="assets/images/editIcon.svg"
                                                alt="edit-icon" onClick={redirectforclosure} />
                                        </div>
                                        {
                                            email === "" ? <>
                                                <p className='errmsg'>Please enter a valid email id</p>
                                                <div className="forclosuresubmitdisablebtn">
                                                    <button disabled={true}>Submit</button>
                                                </div>

                                            </> :
                                                <>
                                                    <div className="forclosuresubmitbtn">
                                                        <button onClick={() => { ForclosureLoan() }} >Submit</button>
                                                    </div>

                                                </>
                                        }
                                    </>
                            }
                        </div>


                        <div>


                        </div>
                    </div>
                </Modal> : null
            }

        </>
    )
}

ListOfServiceRequest.propTypes = {
    getDigiUserDocFunc: PropTypes.func,
    currentPage: PropTypes.string,
    screenName: PropTypes.string
}

export default ListOfServiceRequest