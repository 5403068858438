import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getGenerateRepaySchedule = (data) => {
    let requestJson = {
        "header": {
            "authToken": "",
            "productFamily": "CFAB"
        },
        "body": {
            "accountNumber": "TCFPL0485000010002583",
            "fromDate": "01/01/2022",
            "toDate": "15/07/2022"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/finnone/generate-repay-schedule/v1',data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/finnone/generate-repay-schedule/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getGenerateRepaySchedule;