import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const userLoginType = (data) => {

    let requestJson = data;
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/user/user-login-type/v1', requestJson).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/user/user-login-type/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default userLoginType;