import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const singleActionApi = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "firstName": "CAD STITCHES PRIVATE LIMITED",
            "lastName": "abc",
            "dob": "14-Jul-2021",
            "gender": "1",
            "mobileNumber": "9594779789",
            "emailId": "karan.sanghavi@teknopoint.in",
            "flatNo": "C-1 105, Jagruti, \"Sinhgad rd\"",
            "buildingName": "",
            "roadName": "",
            "city": "Pune",
            "state": "27",
            "pincode": "411046",
            "typeOfEmployment": "salaried",
            "middleName": "",
            "telephoneNo": "",
            "telephoneType": "",
            "pan": "BQGPM7026M",
            "aadhaarNo": "",
            "primaryGcid": "242475666"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/experian/single-action-api/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/experian/single-action-api/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default singleActionApi;