import React from 'react';
import Button from '../../FormFields/Button/Button';
import './recoverCredential.scss';
import PropTypes from 'prop-types';

const RecoverCredential = ({ setLoginTab }) => {
  return (
    <div className='c_recovery_wrap'>
      <div className='c_back_link' onClick={()=>setLoginTab("userId")}><img src='assets/images/arrow_back.svg' className='c_back' /><p>Back</p></div>
      <div className='c_recovery_body'>
        <h2 className='c_recovery_title'>Let Us Help You</h2>
        <p className='c_recovery_desc'>What credential you want to recover?</p>
        <div className="c_recovery_btn_wrap">
          <Button className="login" onClick={() => setLoginTab("forgotId")}>
            I Forgot my User ID
          </Button>
          <Button className="login" onClick={() => setLoginTab("forgotPass")}>
            I Forgot my Password
          </Button>
        </div>
      </div>
    </div>
  )
}

RecoverCredential.propTypes = {
  setLoginTab : PropTypes.func.isRequired,
}

export default RecoverCredential