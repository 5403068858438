const Data = {
    retailbankingDetails: []
}

const retailProfileArray = {
    "othersData": [
        {
            "apiKey": "FIRSTNAME",
            "label": "First Name",
            "edite": false
        }, {
            "apiKey": "LASTNAME",
            "label": "Last Name",
            "edite": false
        }, {
            "apiKey": "PAN_NBR",
            "label": "PAN Number",
            "edite": true,
            "edit-redirect":"change-pan-number"

        }, {
            "apiKey": "MOBILENO",
            "label": "Mobile Number",
            "edite": true,
            "edit-redirect":"change-mobile-number"
        }, {
            "apiKey": "EMAIL",
            "label": "Email ID",
            "edite": true,
            "edit-redirect":"change-email-id"
        }, {
            "apiKey": "DOB",
            "label": "Date of Birth / Incorporation",
            "edite": true,
            "edit-redirect":"change-dob"
        },
        {
            "apiKey": ["ADDRESS_LINE1", "ADDRESS_LINE2", "ADDRESS_LINE3", "CITY", "PINCODE", "STATE"],
            "label": "Address details",
            "edite": true,
            "edit-redirect":"change-address"
        },
    ]
}
Data.retailProfileArray = retailProfileArray;

const ccodProfileDetails = {
    "othersData": [
        {
            "apiKey": "CUSTOMER_NAME",
            "label": "First Name",
            "edite": false
        },
        {
            "apiKey": "LASTNAME",
            "label": "Last Name",
            "edite": false
        },
        {
            "apiKey": "PAN",
            "label": "PAN Number",
            "edite": true,
            "edit-redirect":"change-pan-number"
        }, {
            "apiKey": "MOBILE",
            "label": "Mobile Number",
            "edite": true,
            "edit-redirect":"change-mobile-number"
        }, {
            "apiKey": "EMAIL",
            "label": "Email ID",
            "edite": true,
            "edit-redirect":"change-email-id"
        }, {
            "apiKey": "DOB",
            "label": "Date of Birth / Incorporation",
            "edite": false
        },
        {
            "apiKey": ["ADDRESSLINE1", "ADDRESSLINE2", "ADDRESSLINE3", "CITY", "ZIP", "STATE"],
            "label": "Address details",
            "edite": true,
            "edit-redirect":"change-address"
        }

    ]

}
Data.ccodProfileDetails = ccodProfileDetails


const lasProfileDetails = {
    othersData: [
        {
            "label": "First Name",
            "apiKey": "FIRST_NAME",
            "edite": false
        },
        {
            "label": "Last Name",
            "apiKey": "LAST_NAME",
            "edite": false
        },
        {
            "label": "Email ID",
            "apiKey": "EMAIL_ID",
            "edite": false
        },
        {
            "label": "Mobile Number",
            "apiKey": "MOBILE_NO",
            "edite": false
        },
    ]

}

Data.lasProfileDetails = lasProfileDetails


const retailbankingDetails = {
    othersData: [
        {
            "apiKey": "bankName",
            "label": "Bank Name",
            "edite": false
        }, {
            "apiKey": "accountNumber",
            "label": "Account Number",
            "edite": false
        }, {
            "apiKey": "ifscCode",
            "label": "IFSC Code",
            "edite": false
        }, {
            "apiKey": "dateOfDebit",
            "label": "Next EMI Date",
            "edite": false
        }, {
            "apiKey": "emistartDate",
            "label": "EMI Start Date",
            "edite": false
        }, {
            "apiKey": "emiendDate",
            "label": "EMI End Date",
            "edite": false
        }, {
            "apiKey": "paymentmode",
            "label": "Payment Method",
            "edite": false
        }, {
            "apiKey": "coApplicantName",
            "label": "Co-Applicant Name",
            "edite": false
        }, {
            "apiKey": "branchName",
            "label": "Branch Name",
            "edite": false
        }, {
            "apiKey": "branchCode",
            "label": "Branch Code",
            "edite": false
        }

    ]
}


const cccodbankingDetails = {
    othersData: [
        {
            "apiKey": "BANKA",
            "label": "Name of the bank",
            "edite": false
        }, {
            "apiKey": "BANK_NO",
            "label": "IFSC Code",
            "edite": false
        }, {
            "apiKey": "BRANCH",
            "label": "Branch Name",
            "edite": false
        }

    ],
    accountNumberArr : [
        {
            "apiKey": "BANK_ACC_NO",
            "label": "Account number",
            "edite": false
        }
    ]
}

Data.cccodbankingDetails = cccodbankingDetails

Data.retailbankingDetails = retailbankingDetails

const retailBankArray = [{
    "apiKey": "bankName",
}, {
    "apiKey": "accountNumber"
}, {
    "apiKey": "ifscCode"
}, {
    "apiKey": "dateOfDebit"
}, {
    "apiKey": "emistartDate"
}, {
    "apiKey": "emiendDate"
}, {
    "apiKey": "paymentmode"
}, {
    "apiKey": "coApplicantName"
}, {
    "apiKey": "branchName"
}, {
    "apiKey": "branchCode"
}]
Data.retailBankArray = retailBankArray;

export default Data 