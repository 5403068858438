import commonFunctionObject from "../../common-function";

const OverDueSummaryServiceobject = {}

const sapAndVdParser = (userData, vdData) => {
    return new Promise((resolve,reject) => {
        resolve(userdataAndVdParser(userData, vdData)) 
    })  
}

OverDueSummaryServiceobject.sapAndVdParser = sapAndVdParser;
const userdataAndVdParser =(userDetail, vdData, trench) =>{
    var newObj = {}
    newObj["CUSTOMER_FIRST_NM"] = userDetail.CUSTOMER_FIRST_NM !== undefined ? userDetail.CUSTOMER_FIRST_NM : '-';
    newObj["CUSTOMER_NO"] = userDetail.CUSTOMER_NO !== undefined ? userDetail.CUSTOMER_NO : '-';
    newObj["PRODUCT"] = vdData.PRODUCT !== undefined ? vdData.PRODUCT : '-';
    newObj["AGG_EXP_DT"] = vdData.AGG_EXP_DT !== undefined ? commonFunctionObject.datechange(vdData.AGG_EXP_DT) : '-';
    newObj["TOTAL_REV_DT"] = vdData.TOTAL_REV_DT !== undefined ?commonFunctionObject.replaceHyphenDate(vdData.TOTAL_REV_DT) : '-';
    newObj["MAX_DAYS"] = vdData.MAX_DAYS !== undefined ? vdData.MAX_DAYS : '-';
    newObj["TOT_OD_AMT"] = vdData.TOT_OD_AMT !== undefined ? "₹" + parseFloat(vdData.TOT_OD_AMT).toLocaleString("en-IN") : '₹0';
    newObj["Z0_40"] = vdData.Z0_40 !== undefined ? vdData.Z0_40 : '0';
    newObj["Z41_45"] = vdData.Z41_45 !== undefined ? vdData.Z41_45 : '0';
    newObj["Z46_60"] = vdData.Z46_60 !== undefined ? vdData.Z46_60 : '0';
    newObj["Z61_75"] = vdData.Z61_75 !== undefined ? vdData.Z61_75 : '0';
    newObj["Z76_90"] = vdData.Z76_90 !== undefined ? vdData.Z76_90 : '0';
    newObj["Z91_120"] = vdData.Z91_120 !== undefined ? vdData.Z91_120 : '0';
    newObj["Z121_150"] = vdData.Z121_150 !== undefined ? vdData.Z121_150 : '0';
    newObj["Z151_180"] = vdData.Z151_180 !== undefined ? vdData.Z151_180 : '0';
    newObj["Z181"] = vdData.Z181 !== undefined ? vdData.Z181 : '0';
    return newObj;
}

export default OverDueSummaryServiceobject;