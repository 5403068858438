import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const payOnline = (data) => {
    let requestJson = {
        "header": {
            "authToken": "",
            "company": "CFAB"
        },
        "body": {
            "contractNo": "TCFCD75859079",
            "userId": "479838",
            "primaryGcid": "848759",
            "customerName": "abc"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/ccod-pgs/pay-online/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/ccod-pgs/pay-online/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export default payOnline;