const apiConfigObj = {

}


var appConfig = {

    "env": "DEVELOPMENT",
    // "env": process.env.REACT_APP_ENV.trim()

};

apiConfigObj.appConfig = appConfig;


export default apiConfigObj;