import React, { useState } from "react";
import "./ProfileDetailsComponent.scss";
import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import DataMapping from "../../../../Shared/context/data-mapping";
import PropTypes from 'prop-types';

const ProfileDetailsComponent = ({
  portalType,
  label,
  detail,
  img,
  lastChild,
  bankStyle,
  employmentType,
  setEmployementType,
  optionArray,
  ccodAccount,
  redirectTo,
  // lasOptionArray,
  customeSelectOption,
  mobileColumn,
  closeAcc,
  setOccupationType
}) => {
  const [themeState] = useContext(ThemeContext);
  const [optionState, setOptionState] = useState(false);
  const [data, dispatchData] = useContext(DataMapping)
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState(customeSelectOption);
  // const optionArray=[
  //   {value:"salaried"},
  //   {value:"self"}
  // ]

  const redirectToPage = (redirectTo) => {
    navigate("/customer-service", { state: { screenName: redirectTo } })
  }

  useEffect(() => {
    if (data.currentActiveData.system === "las") {
      if (setOccupationType) {
        setOccupationType(selectedOption)
      }
    }
  }, [selectedOption])

  return (
    <div
      className={`profile-details-component-container ${mobileColumn ? "mobile-column-class" : ""}`}
      style={{
        border: `${lastChild ? "none" : ""}`,
        // height: bankStyle ? "7rem" : "",
        padding: bankStyle ? "20px 0px" : "",
        alignItems: bankStyle ? "flex-start" : "",
        // minHeight: bankStyle ? "7rem" : "",
      }}
    >
      <p className="profile-details-component-container-label">{label}</p>

      {/* <p className="profile-details-component-container-detail">{detail}</p> */}

      {
        Array.isArray(detail) ? <CustomDropDown optionArray={optionArray}
          optionState={optionState}
          setOptionState={setOptionState}
          selectedOption={selectedOption}
          employmentType={employmentType}
          setEmployementType={setEmployementType}
          // optionArray={optionArray}
          ccodAccount={ccodAccount}
          setSelectedOption={setSelectedOption} labelFlag={false} className="las-dropdown" /> : <div className="profile-content-div"><p className="profile-details-component-container-detail ccod-dropdown">{detail}</p></div>
      }
      {data?.currentActiveData?.system === "ccod" && img || img && closeAcc && data?.currentActiveData?.system === "retail" && portalType !== 'collection' ? (
        <img
          style={{ filter: `${themeState === THEME_DARK ? "invert(1)" : ""}` }}
          src="assets/images/editIcon.svg"
          alt="edit-icon"
          className="profile-details-edit-icon"
          onClick={() => { redirectToPage(redirectTo) }}
        />
      ) : null}

    </div>
  );
};

ProfileDetailsComponent.prototypes = {
  label: PropTypes.string,
  detail: PropTypes.string,
  img: PropTypes.string,
  lastChild: PropTypes.bool,
  bankStyle: PropTypes.bool,
  employmentType: PropTypes.string,
  setEmployementType: PropTypes.func,
  optionArray: PropTypes.array,
  ccodAccount: PropTypes.bool,
  redirectTo: PropTypes.string,
  customeSelectOption: PropTypes.string,
  mobileColumn: PropTypes.bool,
  closeAcc: PropTypes.bool,
  setOccupationType: PropTypes.func
}
export default ProfileDetailsComponent;
