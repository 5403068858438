import React from "react";
import "./PageWrapper.scss";
import PropTypes from 'prop-types';

const PageWrapper = ({ children, className }) => {
  return (
    <div className={`page-wrapper-container ${className ? className : ""}`}>
      {children}
    </div>
  );
};

PageWrapper.propTypes = { 
  children : PropTypes.node , 
  className : PropTypes.string,
}

export default PageWrapper;
