import React, { useState} from "react";
import './Input.scss';
import PropTypes from 'prop-types'

const Input = React.forwardRef(({
  isActive,
  icon = "",
  name = "", 
  placeholder = "",
  id = "",
  type = "",
  className = "",
  label, 
  unit = "", 
  inputType = "default", 
  ...inputProps 
},ref) =>
 {
  const [inputTypePass, setInputTypePass] = useState(true);
  const [,setErrorFlag] = useState(false);

  useState(()=>{
    setErrorFlag((prev)=>  !prev)
  },[className])

  return (
    <>
      {inputType == "default" && (
        <div className="c_input_container">
          {label && <label htmlFor={id}>{label}</label>}
          <input
            type={type}
            placeholder={placeholder}
            id={id}
            className={className}
            ref={ref}
            name={name}
            autoComplete="off"
            {...inputProps}
          />
        </div>
      )}
      {inputType == "withUnit" && (
        <div className="c_input_container withUnit">
          {label && (
            <label className={isActive ? "activeLabel" : null} htmlFor={id}>
              {label}
            </label>
          )}
          <input
            type={type}
            placeholder={placeholder}
            id={id}
            className={className}
            ref={ref}
            name={name}
            autoComplete="off"
            {...inputProps}
          />
          <span className="c_input_unit">{unit}</span>
        </div>
      )}
      {inputType == "withCountryCode" && (
        <div className="c_input_container withCountryCode">
          {label && <label htmlFor={id}>{label}</label>}
          <input
            type={type}
            placeholder={placeholder}
            id={id}
            className={className}
            ref={ref}
            name={name}
            autoComplete="off"
            {...inputProps}
          />
          <span className="c_input_unit">{unit}</span>
          <div className="code-line"></div>
        </div>
      )}
      {inputType == "withIcon" && (
        <div className="c_input_container withIcon">
          {label && <label htmlFor={id}>{label}</label>}
          <input
            type={inputTypePass ? type : "text"}
            placeholder={placeholder}
            id={id}
            className={className}
            ref={ref}
            name={name}
            autoComplete="off"
            {...inputProps}
          />
          <img
            src={inputTypePass ? icon : "assets/images/visibility_on.svg"}
            className="c_input_icon"
            onClick={() => setInputTypePass((prev) => !prev)}
          ></img>
        </div>
      )}
    </>
  );
}
);

Input.propTypes = {
  isActive: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  unit: PropTypes.string,
  inputType: PropTypes.string,
}


Input.displayName = 'MyApp';

export default Input;
