const dataObj = {

}

const userData = {
    "userData": {
        "TCFPL0485000010002583": {
            "ID": "152",
            "PRIMARY_GCID": "242475666",
            "PORTAL_CREATED_DATE": "2019-05-27 17:46:05",
            "PORTAL_LAST_MODIFIED": "2022-09-29 11:03:24",
            "TITLE": "Mr",
            "FIRSTNAME": "DIPANKAR",
            "LASTNAME": "DIPANKAR",
            "DOB": "07/14/2021 00:00:00",
            "CITY": "Nadia",
            "EMAIL": "vaidehi.gavade@teknopoint.in",
            "COUNTRY": "IN",
            "MOBILENO": "9594779789,7676996464,7078971952,7977248660,9999094583,8291001580",
            "GCID": "242475709",
            "BUSINESSCATEGORY": "CFAB",
            "PAN_NBR": "",
            "CONTRACT_NUMBER": "TCFPL0485000010002583",
            "USER_CREATED_IN_OPEN_DS": "L",
            "SOURCE_SYSTEM": "FINONE",
            "ADDRESS_LINE1": "GOKULPUR CALONY WARD NO 10 KALYANI NADIA",
            "ADDRESS_LINE3": "9830685550",
            "ADDRESS_LINE2": "KALYANI NEAR GOKUL PURKALI BARI",
            "CREATED_DATE": "06/17/2017",
            "PINCODE": "741250",
            "STATE": "WEST BENGAL",
            "DISBURSAL_DATE": "2020.01.01",
            "COMPANY": "TCFSL",
            "TELEPHONE_NO": "8080338372",
            "PRODUCT": "Personal Loan Unsecured",
            "SUB_PRODUCT": "Personal Loan Non Supervisory profiles ",
            "CUSTOMER_CATEGORY": "1",
            "PRODUCT_CODE": "PL",
            "UCIC": "",
            "NEW_USER": "N",
            "CUSTOMER_COUNT_ID": "BQGPM4074M",
            "WEBTOP_NO": "291PZ0004988",
            "SUB_PRODUCT_CODE": "PL_NSP",
            "CUSTOMER_COUNT_ID_TYPE": "",
            "productInfo": {
                "PRODUCT_CODE": "PL",
                "SUB_PRODUCT_CODE": "PL_NSP",
                "PRODUCT_NAME": "Personal Loan Unsecured",
                "SUB_PRODUCT_NAME": "Personal Loan Non Supervisory profiles",
                "PRODUCT": "PERSONAL LOAN",
                "SUB_PRODUCT": "PERSONAL LOAN",
                "SOURCE_SYSTEM": "FINONE",
                "BUSINESS_SEGMENT": "",
                "DISPLAY_NAME": "Personal Loan",
                "PRODUCT_FAMILY": "CFAB",
                "IS_RETENTION": "Y",
                "FORECLOSURE_CHARGES": "NA"
            },
            "SYSTEM_NAME": "retail",
            "SHORT_NAME": "PL",
        },
        "TCFUC0632000010776218": {
            "ID": "1106098",
            "PRIMARY_GCID": "242475666",
            "PORTAL_CREATED_DATE": "2021-05-26 05:51:14",
            "PORTAL_LAST_MODIFIED": "2021-08-09 10:05:17",
            "TITLE": "Mr",
            "FIRSTNAME": "SAMEER",
            "LASTNAME": "MEHTA",
            "DOB": "03/18/1971 00:00:00",
            "CITY": "",
            "EMAIL": "",
            "COUNTRY": "",
            "MOBILENO": "9920102922",
            "GCID": "243003654",
            "BUSINESSCATEGORY": "CFAB",
            "PAN_NBR": "BQGPM7026M",
            "CONTRACT_NUMBER": "TCFUC0632000010776218",
            "USER_CREATED_IN_OPEN_DS": "Y",
            "SOURCE_SYSTEM": "FINONE",
            "ADDRESS_LINE1": "",
            "ADDRESS_LINE3": "",
            "ADDRESS_LINE2": "",
            "CREATED_DATE": "05/24/2021",
            "PINCODE": "",
            "STATE": "",
            "DISBURSAL_DATE": "2020.01.20",
            "COMPANY": "TCFSL",
            "TELEPHONE_NO": "",
            "PRODUCT": "Used Car Refinance",
            "SUB_PRODUCT": "UCCNCMUL",
            "CUSTOMER_CATEGORY": "1",
            "PRODUCT_CODE": "UCLR",
            "UCIC": "",
            "NEW_USER": "N",
            "CUSTOMER_COUNT_ID": "APEPM0921R",
            "WEBTOP_NO": "291TZ0000094",
            "SUB_PRODUCT_CODE": "UCCNCMUL",
            "CUSTOMER_COUNT_ID_TYPE": "PAN",
            "productInfo": {
                "PRODUCT_CODE": "UCLR",
                "SUB_PRODUCT_CODE": "UCCNCMUL",
                "PRODUCT_NAME": "Used Car Refinance",
                "SUB_PRODUCT_NAME": "Used Car Refinance",
                "PRODUCT": "Used Car Refinance",
                "SUB_PRODUCT": "Used Car Refinance",
                "SOURCE_SYSTEM": "FINONE",
                "BUSINESS_SEGMENT": "",
                "DISPLAY_NAME": "Used Car",
                "PRODUCT_FAMILY": "CFAB",
                "IS_RETENTION": "Y",
                "FORECLOSURE_CHARGES": "NA"
            },
            "SYSTEM_NAME": "retail",
            "SHORT_NAME": "UC",
        },
        "0082000002": {
            "cdiContract": {
                "ID": "45",
                "USER_ID": "121",
                "PORTAL_CREATED_DATE": "2022-05-10 10:25:27",
                "PORTAL_LAST_MODIFIED": "2022-08-17 13:21:32",
                "CUSTOMER_NAME": "MD FAIYAZ AHMAD ANSARI",
                "DOB": "06/08/1963 00:00:00",
                "CITY": "MUMBAI",
                "EMAIL": "SIVASPACKAGE@GMAIL.COM",
                "COUNTRY": "IN",
                "MOBILE": "7042621573",
                "GCID": "33139998",
                "PAN": "BAIPK5040D",
                "USER_TYPE": "CCOD",
                "CUSTOMER_NO": "0082000002",
                "ORIGINAL_CUSTOMER_NO": "82000002",
                "CUST_TAX_PAN": "",
                "MAPPING_NM1": "",
                "ADDRESSLINE1": "BLDG NO 4 B 709 SAGBAUG SNEHA SAGAR CHS",
                "ADDRESSLINE2": "ANDHERI KURLA ROAD ANDHERI E",
                "ADDRESSLINE3": "",
                "ADDRESSLINE4": "",
                "CDI_CREATE_DT": "20-09-2020",
                "CDI_UPDATE_DT": "01-02-2022",
                "ZIP": "400059",
                "STATE": "MH",
                "CONTACT_NUMBER1": "7042621573",
                "CONTACT_NUMBER2": "",
                "FAX": "",
                "GLOBAL_ID_SAP": "BAIPK5040D",
                "GLOBAL_ID": "33139998",
                "COMPANY": "TCFSL",
                "PROGRAM_NAME": "ZTCL_PL",
                "CONTRACT_NUMBER": "0008970116",
                "EMAIL1": "",
                "MAPPING_NM": "PROC_LOAD_USER_DETAILS",
                "CREATED_TS": "19-09-2020",
                "UPDATED_TS": "01-02-2022",
                "STATUS": "Active",
                "DEALER_CODE": "",
                "COMPANY_PAN_NO": "",
                "SRC_NM": ""
            },
            "sapContract": {
                "ID": "31",
                "USER_ID": "121",
                "PORTAL_CREATED_DATE": "2022-05-10 14:23:59",
                "PORTAL_LAST_MODIFIED": "2022-09-01 13:19:27",
                "BUKRS": "7000",
                "PROGRAM_NAME": "ZTCL_PL",
                "GLOBAL_ID": "BAIPK5040D",
                "PAN": "BAIPK5040D",
                "MOBILE": "8291617246",
                "EMAIL": "SUJEETRKL@GMAIL.COM",
                "PRODUCT": "PL",
                "CUSTOMER_NO": "0082000002",
                "CUSTOMER_FIRST_NM": "MD FAIYAZ AHMAD ANSARI",
                "ADDRESSLINE1": "BLDG NO 4 B 709 SAGBAUG SNEHA SAGAR CHS",
                "ADDRESSLINE2": "ANDHERI KURLA ROAD ANDHERI E",
                "CITY": "MUMBAI",
                "STATE": "MH",
                "COUNTRY": "IN",
                "ZIP": "400059",
                "CONTACT_NUMBER1": "7042621573",
                "MANUFACTURE_GROUP_ID": "\"KKBK9369",
                "MANUFACTURE_GROUP": "PLTCL00001",
                "MANU_CO_CODE": "PLTCL00001",
                "MANUFACTURE_NAME": "TCFSL CFAB PL A/c",
                "STATUS": "Active",
                "DEALER_GROUP_KEY": "BAIPK5040D",
                "ZBIRTH_DATE": "0000-00-00"
            },
            "SYSTEM_NAME": "ccod",
            "SHORT_NAME": "CCOD"
        },
        "1347": {
            "ID": "49",
            "GCID": "198992",
            "CLIENT_ID": "90000070",
            "FIRST_NAME": "PRANAV",
            "LAST_NAME": "AJGAONKAR",
            "EMAIL_ID": "pranava926@gmail.com",
            "MOBILE_NO": "7718002104",
            "PAN_NO": "AKHPA7171M",
            "SYSTEM_ID": "2",
            "SYSTEM_NAME": "las"
        },

    },
    "summaryData": {
        "1347": {
            "CustomerID": "2256",
            "LoanAccount": [
                {
                    "Account_Number": "1347",
                    "LoanAccountName": "PRANAV DATTATRAY AJGAONKAR",
                    "Product": "LAS",
                    "Primary_Borrower": "PRANAV DATTATRAY AJGAONKAR",
                    "AccountOpening_Date": "7/1/2021 12:00:00 AM",
                    "AccountExpiry_Date": "1/1/2050 12:00:00 AM",
                    "SanctionedLimit": "40000000.00",
                    "DrawingPower": "596121979.53",
                    "PrincipalOutstanding": "0.00",
                    "AmountDue": "118.00",
                    "TotalOutstanding": "118.00",
                    "AmountAvailable_ForDisbursement": "596121861.53",
                    "OutstandingInterest": "0.00",
                    "OutstandingCharges": "118.00",
                    "PrimaryHolder": "PRANAV DATTATRAY AJGAONKAR",
                    "AccountMaturityDate": "1/1/2050 12:00:00 AM",
                    "ProductCategory": "Loan Against Shares _Digital",
                    "Primary_AccountHolder": "PRANAV DATTATRAY AJGAONKAR",
                    "Coborrowers": "",
                    "ECSApplicable": "No",
                    "Address_Correspondence": "303 Sukh Anand CHS LTD Ashish Complex Near Jangid Circle Mira Road East Silver Park Mira Road Mira-Bhayander Thane ",
                    "Address_Office": "                                                                                                                                                                                                                                                          ",
                    "AccountStatus": "Active",
                    "PendingTDS": "0.00",
                    "TotalPortfolioValue": "1495467466.65",
                    "BankDetailsNonPOA": "",
                    "DPDetailsNonPOA": "",
                    "ScheduleDetails": [
                        {
                            "LoanNumber": "3337",
                            "LoanType": "Term Loan",
                            "SubType": "Short Term",
                            "Purpose_of_Loan": "Personal",
                            "LoanLimit": "40000000.00",
                            "LoanAmount": "0.00",
                            "InterestRate": "12.00",
                            "LoanMaturityDate": "7/1/2023 12:00:00 AM",
                            "Interest_Outstanding": "0.00",
                            "ChargesOutstanding": "118.00",
                            "ReviewPeriod": "0 ",
                            "InterestType": "Floating",
                            "GracePeriod_Interest": "0",
                            "Grace_Period_Principal": "7",
                            "OverdueInterest": "Active",
                            "OverduePrincipal": "InActive",
                            "PrincipalFrequency": "Monthly",
                            "Interest_Frequency": "Monthly",
                            "ChargesPosting": "Daily",
                            "PenalInterest": "0.00",
                            "PenalRatePrincipal": "0.00",
                            "PenalRateInterest": "25.00",
                            "AmountDue_ForCustomer": "118.00",
                            "AmountOverdue_ForCustomer": "118.00",
                            "InterestAccruedNotDue": "0.00",
                            "PenalInterestAccruedNotDue": "0.00"
                        }
                    ],
                    "CollateralEquity": [
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ASIAN GRANITO INDIA LTD",
                            "PledgeOrderNo": "10002114",
                            "Quantity": "64000.0000",
                            "PortfolioValue": "17916800.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE022I01019",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "BHARAT PETROLEUM CORPORATION LTD",
                            "PledgeOrderNo": "10002115",
                            "Quantity": "1.0000",
                            "PortfolioValue": "377.40",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE029A01011",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ASIAN GRANITO INDIA LTD",
                            "PledgeOrderNo": "10002200",
                            "Quantity": "5.0000",
                            "PortfolioValue": "1399.75",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE022I01019",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ASSOCIATED ALCOHOLS & BREWERIES LTD",
                            "PledgeOrderNo": "10002201",
                            "Quantity": "4.0000",
                            "PortfolioValue": "1193.40",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE073G01016",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "CRISIL LTD",
                            "PledgeOrderNo": "100000012",
                            "Quantity": "2999.0000",
                            "PortfolioValue": "5684604.50",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE007A01025",
                            "ScripCategory": "C",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HINDUSTAN UNILEVER LTD",
                            "PledgeOrderNo": "10000002345.",
                            "Quantity": "1000.0000",
                            "PortfolioValue": "2399950.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE030A01027",
                            "ScripCategory": "S",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "STATE BANK OF INDIA",
                            "PledgeOrderNo": "10002112.",
                            "Quantity": "9999.0000",
                            "PortfolioValue": "2667233.25",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE062A01020",
                            "ScripCategory": "S",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "SUN PHARMACEUTICAL INDUSTRIES LTD",
                            "PledgeOrderNo": "10000000001038",
                            "Quantity": "100.0000",
                            "PortfolioValue": "58985.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE044A01036",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "WATERBASE LTD",
                            "PledgeOrderNo": "10000000001039",
                            "Quantity": "500.0000",
                            "PortfolioValue": "65825.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE054C01015",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HAPPIEST MINDS TECHNOLOGIES LTD",
                            "PledgeOrderNo": "100098781",
                            "Quantity": "1000.0000",
                            "PortfolioValue": "347050.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE419U01012",
                            "ScripCategory": "B",
                            "InTransitReleaseQty": "2.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "BHARAT PETROLEUM CORPORATION LTD",
                            "PledgeOrderNo": "10000000001067",
                            "Quantity": "999.0000",
                            "PortfolioValue": "377022.60",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE029A01011",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "IDFC LIMITED",
                            "PledgeOrderNo": "10000000001068",
                            "Quantity": "999.0000",
                            "PortfolioValue": "37262.70",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE043D01016",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "BHARAT PETROLEUM CORPORATION LTD",
                            "PledgeOrderNo": "10000000001069",
                            "Quantity": "1001.0000",
                            "PortfolioValue": "377777.40",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE029A01011",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "IDFC LIMITED",
                            "PledgeOrderNo": "10000000001070",
                            "Quantity": "1001.0000",
                            "PortfolioValue": "37337.30",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE043D01016",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDB FINANCIAL SERVICES",
                            "PledgeOrderNo": "1000987",
                            "Quantity": "1000.0000",
                            "PortfolioValue": "700000.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE756I01012",
                            "ScripCategory": "U",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "DISH TV INDIA LTD",
                            "PledgeOrderNo": "1234567",
                            "Quantity": "1000.0000",
                            "PortfolioValue": "14750.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE836F01026",
                            "ScripCategory": "U",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "PledgeOrderNo": "13456790",
                            "Quantity": "1000.0000",
                            "PortfolioValue": "1160734.50",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ScripCategory": "Z",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ACRYSIL LTD",
                            "PledgeOrderNo": "10000009090",
                            "Quantity": "100.0000",
                            "PortfolioValue": "17645.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE482D01024",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HAPPIEST MINDS TECHNOLOGIES LTD",
                            "PledgeOrderNo": "1000010023",
                            "Quantity": "100.0000",
                            "PortfolioValue": "34705.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE419U01012",
                            "ScripCategory": "B",
                            "InTransitReleaseQty": "2.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "PledgeOrderNo": "1000987",
                            "Quantity": "100.0000",
                            "PortfolioValue": "116073.45",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ScripCategory": "Z",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ASIAN GRANITO INDIA LTD",
                            "PledgeOrderNo": "10001847",
                            "Quantity": "3028.0000",
                            "PortfolioValue": "847688.60",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE022I01019",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "BHARAT PETROLEUM CORPORATION LTD",
                            "PledgeOrderNo": "10001848",
                            "Quantity": "5000.0000",
                            "PortfolioValue": "1887000.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE029A01011",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "IDFC LIMITED",
                            "PledgeOrderNo": "10001849",
                            "Quantity": "10000.0000",
                            "PortfolioValue": "373000.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE043D01016",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "FORTIS HEALTHCARE LIMITED",
                            "PledgeOrderNo": "10001850",
                            "Quantity": "1000.0000",
                            "PortfolioValue": "154050.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE061F01013",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "INSECTICIDES (INDIA) LTD",
                            "PledgeOrderNo": "10001851",
                            "Quantity": "2282.0000",
                            "PortfolioValue": "1051203.30",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE070I01018",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ASIAN GRANITO INDIA LTD",
                            "PledgeOrderNo": "10001890",
                            "Quantity": "1879.0000",
                            "PortfolioValue": "526026.05",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE022I01019",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "Sanofi India Ltd",
                            "PledgeOrderNo": "10001891",
                            "Quantity": "10.0000",
                            "PortfolioValue": "81556.50",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE058A01010",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "STATE BANK OF INDIA",
                            "PledgeOrderNo": "10001892",
                            "Quantity": "100.0000",
                            "PortfolioValue": "26675.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE062A01020",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "INSECTICIDES (INDIA) LTD",
                            "PledgeOrderNo": "10001893",
                            "Quantity": "1951.0000",
                            "PortfolioValue": "898728.15",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE070I01018",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ASIAN GRANITO INDIA LTD",
                            "PledgeOrderNo": "10001903",
                            "Quantity": "4902.0000",
                            "PortfolioValue": "1372314.90",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE022I01019",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDFC BANK LTD",
                            "PledgeOrderNo": "10001904",
                            "Quantity": "1000.0000",
                            "PortfolioValue": "1396900.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE040A01034",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "INSECTICIDES (INDIA) LTD",
                            "PledgeOrderNo": "10001905",
                            "Quantity": "3696.0000",
                            "PortfolioValue": "1702562.40",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE070I01018",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "Himadri Speciality Chemical Ltd",
                            "PledgeOrderNo": "10001923",
                            "Quantity": "500.0000",
                            "PortfolioValue": "20950.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE019C01026",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ASIAN GRANITO INDIA LTD",
                            "PledgeOrderNo": "10001924",
                            "Quantity": "5040.0000",
                            "PortfolioValue": "1410948.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE022I01019",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDFC BANK LTD",
                            "PledgeOrderNo": "10001925",
                            "Quantity": "1020.0000",
                            "PortfolioValue": "1424838.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE040A01034",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "SUN PHARMACEUTICAL INDUSTRIES LTD",
                            "PledgeOrderNo": "10001926",
                            "Quantity": "200.0000",
                            "PortfolioValue": "117970.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE044A01036",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "INSECTICIDES (INDIA) LTD",
                            "PledgeOrderNo": "10001927",
                            "Quantity": "3977.0000",
                            "PortfolioValue": "1832005.05",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE070I01018",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDFC BANK LTD",
                            "PledgeOrderNo": "10002089",
                            "Quantity": "100.0000",
                            "PortfolioValue": "139690.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE040A01034",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "FORTIS HEALTHCARE LIMITED",
                            "PledgeOrderNo": "10002090",
                            "Quantity": "50000.0000",
                            "PortfolioValue": "7702500.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE061F01013",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "INSECTICIDES (INDIA) LTD",
                            "PledgeOrderNo": "10002091",
                            "Quantity": "10000.0000",
                            "PortfolioValue": "4606500.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE070I01018",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDFC BANK LTD",
                            "PledgeOrderNo": "10002098",
                            "Quantity": "3000.0000",
                            "PortfolioValue": "4190700.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE040A01034",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDFC BANK LTD",
                            "PledgeOrderNo": "10002099",
                            "Quantity": "5000.0000",
                            "PortfolioValue": "6984500.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE040A01034",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "Sanofi India Ltd",
                            "PledgeOrderNo": "10002100",
                            "Quantity": "1000.0000",
                            "PortfolioValue": "8155650.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE058A01010",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ASIAN GRANITO INDIA LTD",
                            "PledgeOrderNo": "10002101",
                            "Quantity": "20000.0000",
                            "PortfolioValue": "5599000.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE022I01019",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "FORTIS HEALTHCARE LIMITED",
                            "PledgeOrderNo": "10002102",
                            "Quantity": "20000.0000",
                            "PortfolioValue": "3081000.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE061F01013",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "Himadri Speciality Chemical Ltd",
                            "PledgeOrderNo": "10002103",
                            "Quantity": "99290.0000",
                            "PortfolioValue": "4160251.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE019C01026",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "ASIAN GRANITO INDIA LTD",
                            "PledgeOrderNo": "10002104",
                            "Quantity": "1.0000",
                            "PortfolioValue": "279.95",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE022I01019",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDFC BANK LTD",
                            "PledgeOrderNo": "15653555",
                            "Quantity": "100.0000",
                            "PortfolioValue": "139690.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE040A01034",
                            "ScripCategory": "S",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDFC BANK LTD",
                            "PledgeOrderNo": "10002105",
                            "Quantity": "80000.0000",
                            "PortfolioValue": "111752000.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE040A01034",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDFC BANK LTD",
                            "PledgeOrderNo": "10002106",
                            "Quantity": "1.0000",
                            "PortfolioValue": "1396.90",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE040A01034",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "Sanofi India Ltd",
                            "PledgeOrderNo": "10002107",
                            "Quantity": "9500.0000",
                            "PortfolioValue": "77478675.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE058A01010",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "IDFC LIMITED",
                            "PledgeOrderNo": "10002108",
                            "Quantity": "30000.0000",
                            "PortfolioValue": "1119000.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE043D01016",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "SUN PHARMACEUTICAL INDUSTRIES LTD",
                            "PledgeOrderNo": "10002109",
                            "Quantity": "1.0000",
                            "PortfolioValue": "589.85",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE044A01036",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "HDFC BANK LTD",
                            "PledgeOrderNo": "15653556",
                            "Quantity": "1.0000",
                            "PortfolioValue": "1396.90",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE040A01034",
                            "ScripCategory": "S",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "FORTIS HEALTHCARE LIMITED",
                            "PledgeOrderNo": "10002110",
                            "Quantity": "19001.0000",
                            "PortfolioValue": "2927104.05",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE061F01013",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "BHARAT PETROLEUM CORPORATION LTD",
                            "PledgeOrderNo": "10002111",
                            "Quantity": "10000.0000",
                            "PortfolioValue": "3774000.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE029A01011",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "STATE BANK OF INDIA",
                            "PledgeOrderNo": "10002112",
                            "Quantity": "1.0000",
                            "PortfolioValue": "266.75",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE062A01020",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "TEST_NBFC TCFSL - IN006164 - 10003891",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "INSECTICIDES (INDIA) LTD",
                            "PledgeOrderNo": "10002113",
                            "Quantity": "30000.0000",
                            "PortfolioValue": "13819500.00",
                            "PledgeeDP_ID": "IN006164",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE070I01018",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "MANAPPURAM FINANCE LTD",
                            "PledgeOrderNo": "123456",
                            "Quantity": "101.0000",
                            "PortfolioValue": "16569.05",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE522D01027",
                            "ScripCategory": "B",
                            "InTransitReleaseQty": "0.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "P I INDUSTRIES LTD",
                            "PledgeOrderNo": "123457",
                            "Quantity": "95.0000",
                            "PortfolioValue": "211565.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE603J01030",
                            "ScripCategory": "B",
                            "InTransitReleaseQty": "5.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "TATA CONSULTANCY SERVICES LTD",
                            "PledgeOrderNo": "9876544",
                            "Quantity": "50000.0000",
                            "PortfolioValue": "145422500.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE467B01029",
                            "ScripCategory": "S",
                            "InTransitReleaseQty": "202.00"
                        },
                        {
                            "PledgeeDP": "STOCK HLDG CORP OF I LTD - IN301330 - 21714715",
                            "Pledger": "Holder",
                            "NameOf_RespectivePledger": "PRANAV",
                            "Security": "TATA CONSULTANCY SERVICES LTD",
                            "PledgeOrderNo": "9877765",
                            "Quantity": "360000.0000",
                            "PortfolioValue": "1047042000.00",
                            "PledgeSlipNo": "0",
                            "PledgeeDP_ID": "IN301330",
                            "PledgerClient_ID": "IN879515",
                            "ISIN": "INE467B01029",
                            "ScripCategory": "S",
                            "InTransitReleaseQty": "202.00"
                        }
                    ],
                    "CollateralMutualFund": ""
                }
            ],
            "BankDetails": [
                {
                    "BeneficiaryName": "Rama shankar darivemula",
                    "BankName": "AXIS BANK",
                    "DefaultAccount": "Yes",
                    "BankAccountNumber": "918010092763141",
                    "Branch": "HYDERABAD",
                    "IFSCCode": "UTIB0000008",
                    "POAAccount": "No",
                    "AccountType": "Savings"
                }
            ],
            "RMName": "Jalindar Avhad",
            "PhoneNo": "022 61162910",
            "MobileNo": "9619921065",
            "EmailID": "yuvraj.jare@tatacapital.com",
            "ServiceBranch": "0386 - DELHI  RAJENDRA PLACE",
            "HeadofFamily": "No",
            "Address": "303 Sukh Anand CHS LTD Ashish Complex Near Jangid Circle Mira Road East Silver Park Mira Road Mira-Bhayander Thane ",
            "City": "Thane",
            "State": "MAHARASHTRA",
            "Country": "INDIA",
            "MobileNumber": "7718002104          ",
            "mailID": "pranava926@gmail.com",
            "Office_Address": "                                                                                                                                                                                                                                                          ",
            "Office_City": "                         ",
            "Office_Country": "                         ",
            "Office_Telephone": "                    ",
            "Office_Mobile_No": "               ",
            "Office_Fax_No": "                    ",
            "DefaultMailing": "Home",
            "OccupationType": "                                                  ",
            "FamilyName": "DIGITAL",
            "FamilyLoanAccounts": [
                {
                    "LoanAccountNo": "1307",
                    "LoanAccountName": "Neha3 Harish Tiwari"
                },
                {
                    "LoanAccountNo": "1308",
                    "LoanAccountName": "RAVINDRA VILAS MANGALE"
                },
                {
                    "LoanAccountNo": "1309",
                    "LoanAccountName": "amod Suresh Ranadive"
                },
                {
                    "LoanAccountNo": "1310",
                    "LoanAccountName": "SAGAR RAJKUMAR DAVE"
                },
                {
                    "LoanAccountNo": "1311",
                    "LoanAccountName": "SAGAR RAJKUMAR DAVE"
                },
                {
                    "LoanAccountNo": "1312",
                    "LoanAccountName": "SAGAR RAJ DAVE"
                },
                {
                    "LoanAccountNo": "1313",
                    "LoanAccountName": "SAGAR RAJKUMAR DAVE"
                },
                {
                    "LoanAccountNo": "1314",
                    "LoanAccountName": "SAGAR RAJKUMAR DAVE"
                },
                {
                    "LoanAccountNo": "1315",
                    "LoanAccountName": "HARSH K MEHTA"
                },
                {
                    "LoanAccountNo": "1324",
                    "LoanAccountName": "HARSH K MEHT"
                },
                {
                    "LoanAccountNo": "1325",
                    "LoanAccountName": "HARSH K MEHT"
                },
                {
                    "LoanAccountNo": "1326",
                    "LoanAccountName": "tejal prakash chavan"
                },
                {
                    "LoanAccountNo": "1327",
                    "LoanAccountName": "NAVINKUMARSINHA sinha KUMAR"
                },
                {
                    "LoanAccountNo": "1342",
                    "LoanAccountName": "SAMIR PRAKASH DESAI"
                },
                {
                    "LoanAccountNo": "1344",
                    "LoanAccountName": "Jyot Navin Kumar"
                },
                {
                    "LoanAccountNo": "1345",
                    "LoanAccountName": "Alex A  Vincent B"
                },
                {
                    "LoanAccountNo": "1346",
                    "LoanAccountName": "Alexander  Vincent"
                },
                {
                    "LoanAccountNo": "1347",
                    "LoanAccountName": "PRANAV DATTATRAY AJGAONKAR"
                },
                {
                    "LoanAccountNo": "1348",
                    "LoanAccountName": "HARSHA A MEH"
                },
                {
                    "LoanAccountNo": "1349",
                    "LoanAccountName": "Navin  Kumar"
                },
                {
                    "LoanAccountNo": "1350",
                    "LoanAccountName": "Navin  Kumar"
                },
                {
                    "LoanAccountNo": "1351",
                    "LoanAccountName": "Navin  Kumar"
                },
                {
                    "LoanAccountNo": "1352",
                    "LoanAccountName": "Akansha  Jotshi"
                },
                {
                    "LoanAccountNo": "1353",
                    "LoanAccountName": "Sasikumar  Sethuramalingam"
                },
                {
                    "LoanAccountNo": "1354",
                    "LoanAccountName": "Edwin James Peranickal"
                },
                {
                    "LoanAccountNo": "1356",
                    "LoanAccountName": "RUDRA YUVRAJ JARE"
                },
                {
                    "LoanAccountNo": "1358",
                    "LoanAccountName": "PRANAV ROHITKUMAR SHAH"
                },
                {
                    "LoanAccountNo": "1359",
                    "LoanAccountName": "PRANAV ROHITKUMAR SHAH"
                }
            ],
            "SYSTEM_NAME": "las",
            "SHORT_NAME": "LAS",

        }
    },
    "getLoanPositionDtls": {
        "TCFPL0485000010002583": {
            "getLoanPositionDtlsReturn": {
                "EMIPaymentStatus": "B,A",
                "occupation": "SALARIED",
                "outstandingAmount": 0.0,
                "loanAvailDate": "27/06/2017",
                "unpaidPrincipleBalance": 0.0,
                "coApplicantName": "",
                "last3MonthsEMIPaidDate": "09-01-22 00:00:00,09-12-21 00:00:00,10-11-21 00:00:00",
                "terminationDate": {
                    "xsi:nil": true
                },
                "last3MonthsEMIDueDate": "09-02-22 00:00:00,09-01-22 00:00:00,09-12-21 00:00:00",
                "unpaidInterest": 0.0,
                "paymentmode": "",
                "balanceTenure": 0.0,
                "beneficiaryBankName": {
                    "xsi:nil": true
                },
                "loanApprovedDate": "19/06/2017",
                "loanStatus": "Active",
                "overdueAmount": 75.0,
                "IFSCCode": {
                    "xsi:nil": true
                },
                "accountType": {
                    "xsi:nil": true
                },
                "emistartDate": "09/08/2017",
                "mandateExpiryDate": {
                    "xsi:nil": true
                },
                "branchCode": 485.0,
                "loanAccountNo": {
                    "xsi:nil": true
                },
                "subProductName": "",
                "applicationId": "APPL10007015",
                "interestAdjustment": "",
                "outstandingEssAmount": "",
                "status": {
                    "xsi:nil": true
                },
                "arrearsInterest": "",
                "lastDateOfAmountPaid": "06/10/2022",
                "EMIBounceDescription": "N10002583/55",
                "asOnDate": "06/10/2022",
                "accountName": "",
                "penalInterestRate": "",
                "gcid": "",
                "loanAvailAmt": 307091.0,
                "rateOfInterest": 14.0,
                "nextEmiDate": "06/10/2022",
                "noofAdvance": "",
                "lastamountpaidDate": "09/02/2022",
                "tenor": 19.0,
                "unpaidChargeBalance": 9953.0,
                "errorOccured": false,
                "sanctionAmount": 307091.0,
                "beneficiaryBankAccountNo": {
                    "xsi:nil": true
                },
                "disbursedAmount": 307091.0,
                "paymentStatus": "",
                "product": "Personal Loan Unsecured",
                "RCUpdatestatus": "",
                "lastPaidOverdueAmount": 8200.0,
                "contractno": "",
                "branchName": "0485 - BANGALORE RICHMOND ROAD -PRESTIGE CORNICHE",
                "repaymentInstallmentBalance": 0.0,
                "emiendDate": {
                    "xsi:nil": true
                },
                "errorMsg": "",
                "overdueChargeAmount": 12671.0,
                "dueAmount": 0.0,
                "currentInstallment": "",
                "lastAmountPaid": 441.0,
                "loanPeriod": 55.0,
                "remarks": "",
                "overdueprincipleAmount": 0.0,
                "totalNoofEmis": 55.0
            },
            "getLoanAccountsReturn": {
                "emiAmount": 0.0,
                "loanType": "",
                "gcid": "",
                "errorOccured": false,
                "subProductName": "Personal Loan Non Supervisory profiles",
                "sanctionamt": 307091.0,
                "accountNumber": "TCFPL0485000010002583",
                "productName": "Personal Loan Unsecured",
                "errorMsg": ""
            }
        },
        "TCFBL0284000010009410": {
            "getLoanPositionDtlsReturn": {
                "EMIPaymentStatus": "B,A",
                "occupation": {
                    "xsi:nil": true
                },
                "outstandingAmount": 0.0,
                "loanAvailDate": "08/09/2017",
                "unpaidPrincipleBalance": 503777.0,
                "coApplicantName": "",
                "last3MonthsEMIPaidDate": "31-12-18 00:00:00,31-12-18 00:00:00,30-12-18 00:00:00",
                "terminationDate": {
                    "xsi:nil": true
                },
                "last3MonthsEMIDueDate": "03-09-20 00:00:00,03-08-20 00:00:00,03-07-20 00:00:00",
                "unpaidInterest": 93555.0,
                "paymentmode": "",
                "balanceTenure": 0.0,
                "beneficiaryBankName": {
                    "xsi:nil": true
                },
                "loanApprovedDate": "07/09/2017",
                "loanStatus": "Active",
                "overdueAmount": 5000.0,
                "IFSCCode": {
                    "xsi:nil": true
                },
                "accountType": {
                    "xsi:nil": true
                },
                "emistartDate": "03/10/2017",
                "mandateExpiryDate": {
                    "xsi:nil": true
                },
                "branchCode": 284.0,
                "loanAccountNo": {
                    "xsi:nil": true
                },
                "subProductName": "",
                "applicationId": "APPL10022404",
                "interestAdjustment": "",
                "outstandingEssAmount": "",
                "status": {
                    "xsi:nil": true
                },
                "arrearsInterest": "",
                "lastDateOfAmountPaid": "06/10/2022",
                "EMIBounceDescription": "ACCOUNT BLOCKED/FROZEN N10009410/36",
                "asOnDate": "06/10/2022",
                "accountName": "",
                "penalInterestRate": "",
                "gcid": "",
                "loanAvailAmt": 750000.0,
                "rateOfInterest": 18.5,
                "nextEmiDate": {
                    "xsi:nil": true
                },
                "noofAdvance": "",
                "lastamountpaidDate": "03/09/2020",
                "tenor": {
                    "xsi:nil": true
                },
                "unpaidChargeBalance": 15350.0,
                "errorOccured": false,
                "sanctionAmount": 750000.0,
                "beneficiaryBankAccountNo": {
                    "xsi:nil": true
                },
                "disbursedAmount": 750000.0,
                "paymentStatus": "",
                "product": "Business Loan Unsecured",
                "RCUpdatestatus": "",
                "lastPaidOverdueAmount": 100.0,
                "contractno": "",
                "branchName": "0284 - INDORE TOGANJ",
                "repaymentInstallmentBalance": 0.0,
                "emiendDate": {
                    "xsi:nil": true
                },
                "errorMsg": "",
                "overdueChargeAmount": 494882.0,
                "dueAmount": 0.0,
                "currentInstallment": "",
                "lastAmountPaid": 4310.0,
                "loanPeriod": 36.0,
                "remarks": "",
                "overdueprincipleAmount": 597332.0,
                "totalNoofEmis": 36.0
            },
            "getLoanAccountsReturn": {
                "emiAmount": 0.0,
                "loanType": "",
                "gcid": "",
                "errorOccured": false,
                "subProductName": "Business Loan Verified Income Program",
                "sanctionamt": 750000.0,
                "accountNumber": "TCFBL0284000010009410",
                "productName": "Business Loan Unsecured",
                "errorMsg": ""
            },
        },
        "TCFUC0632000010776218": {
            "getLoanPositionDtlsReturn": {
                "EMIPaymentStatus": "B,A",
                "occupation": "SELF EMPLOYED PROFESSIONAL",
                "outstandingAmount": 991693.0,
                "loanAvailDate": "20/01/2020",
                "unpaidPrincipleBalance": 21912.0,
                "coApplicantName": "",
                "last3MonthsEMIPaidDate": "04-01-22 00:00:00,04-01-22 00:00:00,07-12-21 00:00:00",
                "terminationDate": {
                    "xsi:nil": true
                },
                "last3MonthsEMIDueDate": "03-02-22 00:00:00,03-01-22 00:00:00,03-12-21 00:00:00",
                "unpaidInterest": 11079.0,
                "paymentmode": "",
                "balanceTenure": 31.0,
                "beneficiaryBankName": {
                    "xsi:nil": true
                },
                "loanApprovedDate": "17/01/2020",
                "loanStatus": "Active",
                "overdueAmount": 2124.0,
                "IFSCCode": {
                    "xsi:nil": true
                },
                "accountType": {
                    "xsi:nil": true
                },
                "emistartDate": "03/03/2020",
                "mandateExpiryDate": {
                    "xsi:nil": true
                },
                "branchCode": 632.0,
                "loanAccountNo": {
                    "xsi:nil": true
                },
                "subProductName": "",
                "applicationId": "APPL11175223",
                "interestAdjustment": "",
                "outstandingEssAmount": "",
                "status": {
                    "xsi:nil": true
                },
                "arrearsInterest": "",
                "lastDateOfAmountPaid": "06/10/2022",
                "EMIBounceDescription": "M3223480",
                "asOnDate": "06/10/2022",
                "accountName": "",
                "penalInterestRate": "",
                "gcid": "",
                "loanAvailAmt": 1455268.0,
                "rateOfInterest": 15.5,
                "nextEmiDate": "03/04/2022",
                "noofAdvance": "",
                "lastamountpaidDate": "19/03/2022",
                "tenor": {
                    "xsi:nil": true
                },
                "unpaidChargeBalance": 7050.0,
                "errorOccured": false,
                "sanctionAmount": 1455268.0,
                "beneficiaryBankAccountNo": {
                    "xsi:nil": true
                },
                "disbursedAmount": 1455268.0,
                "paymentStatus": "",
                "product": "Used Car Refinance",
                "RCUpdatestatus": "",
                "lastPaidOverdueAmount": 1200.0,
                "contractno": "",
                "branchName": "0632 - MALAD MUMBAI",
                "repaymentInstallmentBalance": 31.0,
                "emiendDate": {
                    "xsi:nil": true
                },
                "errorMsg": "",
                "overdueChargeAmount": 7735.0,
                "dueAmount": 35004.0,
                "currentInstallment": "",
                "lastAmountPaid": 35004.0,
                "loanPeriod": 60.0,
                "remarks": "",
                "overdueprincipleAmount": 32991.0,
                "totalNoofEmis": 60.0
            },
            "getLoanAccountsReturn": {
                "emiAmount": 35004.0,
                "loanType": "",
                "gcid": "",
                "errorOccured": false,
                "subProductName": "Car n Cash Multiplier 2019",
                "sanctionamt": 1455268.0,
                "accountNumber": "TCFUC0632000010776218",
                "productName": "Used Car Refinance",
                "errorMsg": ""
            }
        }
    },
    "get-vd-summary-dtls": {
        "0082000002": {
            "TOT_AMT": "1500000",
            "Z121_150": "0",
            "MOD_AVAIL_LIMIT": "-",
            "ADHOC_LIMIT": "-",
            "B_IND": "-",
            "PROCESS_TIME": "01:00:37",
            "MANDT": "100",
            "MOD_PROCESS_DATE": "0000-00-00",
            "PRODUCT": "PL",
            "Z151_180": "0",
            "ACT_AVAIL_LIMIT": "",
            "ZZONE": "WEST",
            "BEZEI": "Maharashtra",
            "DISB_AMT": "-",
            "AGG_START_DT": "0000-00-00",
            "Z1_5": "3950",
            "SANC_LIMIT": "1500000",
            "INVOICE_AGE": "-",
            "MOD_PROCESS_TIME": "00:00:00",
            "Z121_180": "-",
            "Z6_10": "-",
            "AVAIL_LIMIT": "₹7,79,117.94",
            "PROCESS_DATE": "2022-10-07",
            "OUT_BAL": "₹7,20,882.06",
            "ZUSER": "ZBACKGROUND",
            "MOD_USER": "-",
            "LIFNR": "0082000002",
            "MANU_NAME": "TCFSL CFAB PL A/c",
            "LOCATION": "-",
            "PEAK_REV_DT": "0000-00-00",
            "TOTAL_REV_DT": "03-02-2023",
            "DOWNLOAD_IND": "X",
            "MAX_DAYS": "1460",
            "TOT_OD_AMT": "₹0.00",
            "PEAK_LIMIT": "-",
            "UTIL_LIMIT": "₹0.00",
            "Z0_40": "702432.06",
            "BUKRS": "7000",
            "REP_TIME": "09:34:12",
            "TOTAL_LIMIT": "₹15,00,000.00",
            "OPEN_BAL": "720882.06",
            "Z41_45": "0",
            "KONZS": "BAIPK5040D",
            "Z76_90": "0",
            "Z61_75": "0",
            "SANC_REV_DT": "2023-02-03",
            "MANU_CO_CODE": "PLTCL00001",
            "NAME1": "SUJIT KUMAR",
            "AGG_EXP_DT": "2023-02-04",
            "Z91_120": "0",
            "PROGRAMNAME": "ZTCL_PL",
            "Z46_60": "0",
            "Z181": "0",
            "ADHOC_REVDT": "0000-00-00",
            "TMLLIFNR": "-"
        }
    },
    "get-trench-report": {
        "0082000002": [
            {
                "LOCATION": "",
                "DUE_DATE": "2022-10-07",
                "KONZS": "BAIPK5040D",
                "BLDAT": "2022-09-05",
                "INVOICE_NO": "-",
                "NAME1": "SUJIT KUMAR",
                "BELNR": 8704000040,
                "RO_DIFF": 32,
                "MANU_CO_CODE": "PLTCL00001",
                "PROD": "PL",
                "CALC1": 1450,
                "STATUS": "Due",
                "DMBTR": 2360,
                "M_DAYS": 0,
                "ZZONE": "WEST",
                "TOT": 0,
                "M_NAME": "TCFSL CFAB PL A/c",
                "LIFNR": "0082000002",
                "BLART": "C4"
            },
            {
                "LOCATION": "",
                "DUE_DATE": "2022-10-07",
                "KONZS": "BAIPK5040D",
                "BLDAT": "2020-02-11",
                "INVOICE_NO": "",
                "NAME1": "SUJIT KUMAR",
                "BELNR": 8704000083,
                "RO_DIFF": 969,
                "MANU_CO_CODE": "PLTCL00001",
                "PROD": "PL",
                "CALC1": 2050,
                "STATUS": "Due",
                "DMBTR": 3000,
                "M_DAYS": 0,
                "ZZONE": "WEST",
                "TOT": 0,
                "M_NAME": "TCFSL CFAB PL A/c",
                "LIFNR": "0082000002",
                "BLART": "C4"
            },
            {
                "LOCATION": "",
                "DUE_DATE": "2022-10-07",
                "KONZS": "BAIPK5040D",
                "BLDAT": "2020-02-13",
                "INVOICE_NO": "",
                "NAME1": "SUJIT KUMAR",
                "BELNR": 8704000089,
                "RO_DIFF": 967,
                "MANU_CO_CODE": "PLTCL00001",
                "PROD": "PL",
                "CALC1": 450,
                "STATUS": "Due",
                "DMBTR": 450,
                "M_DAYS": 0,
                "ZZONE": "WEST",
                "TOT": 0,
                "M_NAME": "TCFSL CFAB PL A/c",
                "LIFNR": "0082000002",
                "BLART": "C4"
            },
            {
                "LOCATION": "",
                "DUE_DATE": "2023-02-06",
                "KONZS": "BAIPK5040D",
                "BLDAT": "2019-02-07",
                "INVOICE_NO": "",
                "NAME1": "SUJIT KUMAR",
                "BELNR": 8706000097,
                "RO_DIFF": 1338,
                "MANU_CO_CODE": "PLTCL00001",
                "PROD": "PL",
                "CALC1": 652432.06,
                "STATUS": "Normal",
                "DMBTR": 1450000,
                "M_DAYS": 1460,
                "ZZONE": "WEST",
                "TOT": 706382.06,
                "M_NAME": "TCFSL CFAB PL A/c",
                "LIFNR": "0082000002",
                "BLART": "C6"
            },
            {
                "LOCATION": "",
                "DUE_DATE": "2023-02-28",
                "KONZS": "BAIPK5040D",
                "BLDAT": "2019-03-01",
                "INVOICE_NO": "",
                "NAME1": "SUJIT KUMAR",
                "BELNR": 8706000101,
                "RO_DIFF": 1316,
                "MANU_CO_CODE": "PLTCL00001",
                "PROD": "PL",
                "CALC1": 50000,
                "STATUS": "Normal",
                "DMBTR": 50000,
                "M_DAYS": 1460,
                "ZZONE": "WEST",
                "TOT": 0,
                "M_NAME": "TCFSL CFAB PL A/c",
                "LIFNR": "0082000002",
                "BLART": "C6"
            }
        ]
    }


}

dataObj.userData = userData





export default dataObj