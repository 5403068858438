import React from "react";
import Button from "../../global/Button";
import FrequentlyAskQuestion from "../FrequentlyAskQuestion";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import { useContext } from "react";
import PropTypes from 'prop-types';
//styles
import "./Searchbar-style.scss";
import { createRef } from "react";

const Searchbar = ({
  button,
  placeholder,
  className,
  forComponent,
  onInputChange,
  onFaqAccordionInputChange,
  onListSrInputChange,
  type,
  errorMessage,
  onChange,
  onSearch,
  inputValue,
}) => {

  let ref = createRef();
  let searchClick = () => {

    console.log(ref.current.value);
    if (onSearch) {
      onSearch(ref.current.value);
    }
  }
  return (
    <div className={`searchbar-section ${className ? className : ""}`}>
      <form onSubmit={searchClick}>
        <div className="search-container">
          <input
            style={{ border: errorMessage ? "1px solid red" : "" }}
            type={type}
            ref={ref}
            value={inputValue}
            className="seacrbar-input"
            onChange={(val) => {
              forComponent === "searchFaq"
                ? onInputChange(val)
                : forComponent === "searchAccordionFaq"
                  ? onFaqAccordionInputChange(val)
                  : forComponent = "searchListOfSr"
                    ? onListSrInputChange(val)
                    : onChange(val);
            }}
            placeholder={placeholder}
          />
          {button && (
            <Button type={"submit"} className="search-bar-button" btnType="searchIcon" onClick={searchClick}>
              <img src="assets/images/search-icon.svg" />
            </Button>
          )}
        </div>
        <p className="search-bar-error-message" style={{ color: "red" }}>
          {errorMessage}
        </p>
      </form>

    </div>
  );
};

Searchbar.propTypes = {
  button : PropTypes.bool,
  placeholder :PropTypes.string,
  className :PropTypes.string,
  forComponent :PropTypes.string,
  onInputChange :PropTypes.func,
  onFaqAccordionInputChange :PropTypes.func,
  onListSrInputChange : PropTypes.func,
  type :PropTypes.string,
  errorMessage :PropTypes.string,
  onChange : PropTypes.func,
  onSearch : PropTypes.func,
  inputValue : PropTypes.string
}

export default Searchbar;
