import React, { useEffect } from "react";
import "./AvailOfferModal.scss";
import Button from "../../FormFields/Button/Button";
import { useContext } from "react";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";

function AvailOfferModal({
  handleClickForPaymentOpt,
  setAvailOfferModal,
  setPreapprovedOfferFlag,
  setPopOverFlag,
  setPayNowFlag,
  setIsChecked,
  setJourneyType,
  setFailurePopUpModal,
  setFailurePopUpContent,
  setInvalidUserModal
}) {
  const [data, dipatcher] = useContext(DataMapping);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });

  const handleCrossClick = () => {
    let lanDetails =
      data?.getLoanPositionDTLS?.[data?.currentActiveData?.accountNumber];
    let lanData = lanDetails?.body?.getLoanPositionDtlsReturn;
    let emiOverdueAmount =
      parseInt(lanData?.unpaidInterest) +
      parseInt(lanData?.unpaidPrincipleBalance);

    let chargesOverdueAmount =
      parseInt(lanData?.unpaidChargeBalance) +
      parseInt(lanData?.overdueChargeAmount);
    if (chargesOverdueAmount > 0 || emiOverdueAmount > 0) {
      handleClickForPaymentOpt(
        "offerAvailClick",
        "",
        "N",
        "",
        data?.currentActiveData?.accountNumber
      );
      handleClickForPaymentOpt(
        "paymentoptclicked",
        "Total Overdue Amount",
        "",
        data?.currentActiveData?.accountNumber
      );
      setPreapprovedOfferFlag(false);
      setPopOverFlag(false);
      setIsChecked(false);
      setPayNowFlag(false);
      setJourneyType("non-offer");

      dipatcher({
        type: STOREDATA,
        payload: true,
        name: "callpgs",
      });

      dipatcher({
        name: "offerModaLFlag",
        payload: {
          modalFlag: false,
          accountType: "availOfferModal",
        },
        type: STOREDATA,
      });
    } else {
      setPreapprovedOfferFlag(false);
      setPopOverFlag(false);
      setIsChecked(false);
      setPayNowFlag(true);
      setJourneyType("non-offer");

      dipatcher({
        type: STOREDATA,
        payload: true,
        name: "callpgs",
      });

      dipatcher({
        name: "offerModaLFlag",
        payload: {
          modalFlag: false,
          accountType: "availOfferModal",
        },
        type: STOREDATA,
      });
      setFailurePopUpModal(true);
      setFailurePopUpContent({
        apiName: "",
        message: (
          <>
            Sorry, currently this service is not available. Please login to{" "}
            <a
              href="https://retailonline.tatacapital.com/#/login"
              target="_blank"
            >
              retailonline.tatacapital.com
            </a>
          </>
        ),
      });
      setInvalidUserModal(true);
      dipatcher({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA,
      });
    }
  };

  const handleAvailOffer = () => {
    // dipatcher({ name: "attempt", payload: 3, type: STOREDATA })
    // handleCrossClick()
    handleClickForPaymentOpt(
      "offerAvailClick",
      "",
      "Y",
      "",
      data?.currentActiveData?.accountNumber
    );
    setPopOverFlag(false);
    setIsChecked(false);
    setPayNowFlag(false);
    setPreapprovedOfferFlag(true);
    dipatcher({
      type: STOREDATA,
      payload: false,
      name: "callpgs",
    });
    dipatcher({
      name: "offerModaLFlag",
      payload: {
        modalFlag: false,
        accountType: "availOfferModal",
      },
      type: STOREDATA,
    });
  };

  return (
    <div className="c_avail_offer_modal_wrp">
      <div className="c_avail_offer_modal">
        <img
          src="assets/images/gray-cross.svg"
          alt="gray-cross"
          className="c_avail_offer_modal_close"
          onClick={handleCrossClick}
        />
        <div className="c_avail_offer_modal_img_wrp">
          <img
            src="assets/gifs/offer_animation_popup_img.gif"
            alt="c-avail-offer"
            width="289px"
            height="217px"
          />
        </div>
        <h2 className="c_avail_offer_modal_titlehead">Exclusive Offer!</h2>
        <h3 className="c_avail_offer_modal_title">
          Avail personalized settlement offer on your loan account instantly.
        </h3>
        {/* <p className="c_avail_offer_modal_desc">
          Limited period Settlement offer. Grab it before it expires!
        </p> */}

        <Button className="c_avail_offer_modal_btn" onClick={handleCrossClick}>
          Maybe Later
        </Button>
        <Button
          className="c_avail_offer_modal_avail_btn avail-offer-css"
          onClick={handleAvailOffer}
        >
          Avail Offer
        </Button>
      </div>
    </div>
  );
}

export default AvailOfferModal;
