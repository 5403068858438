import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const clientStatement = (data) => {

  let requestJson = {
    "header":
    {
      "authToken": ""
    },
    "body":
    {
      "loanAccountNo": "",
      "format": "2",
      "fromDate": "2022-03-31",
      "reportType": "pdf",
      "userName": "Adminiaf"
    }
  }
  return new Promise((resolve, reject) => {
    // axios.post('/shaft/api/las-esb/client-statement/v1', data).then(response => {
    stalwartApiCall('POST', data, '/shaft/api/las-esb/client-statement/v1').then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
}

export default clientStatement;