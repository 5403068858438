import React, { useContext, useEffect, useState } from "react";
import "./PaymentPlanOfModal.scss";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import DataMapping from "../../../../Shared/context/data-mapping";
import Modal from "../../components/Modal";
import commonFunctionObject from "../../../../Retail/src/common-function";
import LoginServiceObject from "../../../../Retail/src/services/Login";
import { pgsApiCallFn } from "../../services/pgsApiCall";

const PaymentPlanOfModal = ({
  setFailurePopUpContent,
  setFailurePopUpModal,
  currentDate,
  nextMonthDate,
  activeLoan,
  noOfTranches,
  dashPaymentData,
  paymentSummeryFlag,
  setSuccessFailureFlag,
  isTrancheOpted,
  setIsTrancheOpted,
  noOfTranchesOpted,
  setNoOfTranchesOpted,
  paymentModalDetails,
  setPaymentModalDetails,
  trancheData,
  setTrancheData,
  paymentForm,
  setAttemptFlag,
  setPaymentSummeryFlag,
  handleClickForPaymentOpt,
  setFinalPayOptClickObj,
}) => {
  const [contextData, dispatchData] = useContext(DataMapping);
  // console.log(contextData?.dashPaymentData?.offerData, "qwqwqwqw");
  const [active, setActive] = useState(paymentSummeryFlag);
  const [paymentDates, setPaymentDates] = useState({
    currentMonthDate: "",
    nextMonthDate: "",
  });

  const [selectedOption, setSelectedOption] = useState("option1");
  const [selectActive, setSelectActive] = useState(false);
  // const [installmentText, setInstallmentText] = useState("Installments");
  // const [showOptions, setShowOptions] = useState(false);
  // const [showInstallmentFlag, setShowInstallmentFlag] = useState(false);
  const [updatedOffer, setUpdatedOffer] = useState(null);
  const [showTranches, setShowTranches] = useState(false);
  const [showApprovalFlag, setShowApprovalFlag] = useState(false);
  const [showApprovalDate, setShowApprovalDate] = useState("");

  useEffect(() => {
    if (active) {
      if (selectedOption === "option1") {
        setIsTrancheOpted("N");
        setNoOfTranchesOpted("1");
        setPaymentModalDetails({
          offerType: getofferKey(dashPaymentData?.offerData),
          trancheNumber: 1,
        });
      } else if (selectedOption === "option2") {
        if (showTranches) {
          setIsTrancheOpted("Y");
          setPaymentModalDetails({
            offerType: getofferKey(dashPaymentData?.offerData),
            trancheNumber: 1,
          });
        } else {
          setIsTrancheOpted("N");
          setPaymentModalDetails({
            offerType: getofferKey(dashPaymentData?.offerData),
            trancheNumber: 1,
          });
        }
      }
    }
  }, [selectedOption]);

  const fetchData = async () => {
    const retailContractNumbers = [];
    // Extracting retail contract numbers from user data
    contextData.getUserDataV3?.body?.contracts.forEach((item) => {
      if (item?.system === "retail") {
        retailContractNumbers.push(item.CONTRACT_NUMBER);
      }
    });

    try {
      const offerDataResponse = await LoginServiceObject.offerCollectionDetails(
        contextData.authToken,
        retailContractNumbers
      );
      // console.log(offerDataResponse, "offerDataResponse");
      dispatchData({
        type: STOREDATA,
        name: "offers",
        payload: offerDataResponse,
      });
      return offerDataResponse;
    } catch (error) {
      console.error("Error fetching offer data:", error);
    }
  };

  const getofferKey = (loan) => {
    const keysToCheck = [
      "SETTLEMENT_CAMPAIGN",
      "EMI_OVERDUE_CAMPAIGN",
      "ODBC_CAMPAIGN",
      "LOS_POOL_CAMPAIGN",
      "ALREADY_SETTLED_POOL_CAMPAIGN",
    ];

    for (const key of keysToCheck) {
      if (loan[key] === "Y") {
        return key;
      }
    }

    return null;
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });

  const formatCurrentDate = (inputDate) => {
    // console.log(inputDate, "formatCurrentDate");
    // let inputDateData = "Wed Jul 31 2024 13:59:41 GMT+0530 (India Standard Time)"
    const currentDateDetails = new Date(inputDate);
    // console.log(currentDateDetails, "currentDateDetailsssssssssss");
    // Format current date
    let todayDate = currentDateDetails.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    const endOfMonthDate = new Date(
      currentDateDetails.getFullYear(),
      currentDateDetails.getMonth() + 1,
      0
    );
    let endOfMonthFormatted = endOfMonthDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    // console.log(endOfMonthFormatted,"111111");
    setPaymentDates((prevState) => {
      return {
        ...prevState,
        currentMonthDate: endOfMonthFormatted,
      };
    });

    dispatchData({
      name: "TrancheData",
      payload: true,
      type: STOREDATA,
    });
    if (active) {
      setTrancheData((prevState) => {
        return {
          ...prevState,
          tranche1Date: endOfMonthFormatted,
        };
      });
    }
    return endOfMonthFormatted;
  };

  const calculateNextMonthDate = (inputDate) => {
    // console.log(inputDate, "calculateNextMonthDate");
    // let inputDateData = "Wed Jul 31 2024 13:59:41 GMT+0530 (India Standard Time)"

    const currentDateDetails = new Date(inputDate);

    // Calculate the next month end date
    const endOfNextMonthDate = new Date(
      currentDateDetails.getFullYear(),
      currentDateDetails.getMonth() + 2, // This should be currentDateDetails.getMonth() + 1
      0
    );

    let endOfNextMonthFormatted = endOfNextMonthDate.toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "long",
        year: "numeric",
      }
    );
    // console.log(endOfNextMonthFormatted,"111111");

    setPaymentDates((prevState) => {
      return {
        ...prevState,
        nextMonthDate: endOfNextMonthFormatted,
      };
    });

    dispatchData({
      name: "TrancheData",
      payload: true,
      type: STOREDATA,
    });

    if (active) {
      setTrancheData((prevState) => {
        return {
          ...prevState,
          tranche2Date: endOfNextMonthFormatted,
        };
      });
    }

    return endOfNextMonthFormatted;
  };

  const postFormatCurrentDate = (inputDate) => {
    // console.log(inputDate, "formatCurrentDate");
    const currentDateDetails = new Date(inputDate);
    // console.log(currentDateDetails, "currentDateDetailsssssssssss");
    // Format current date
    let todayDate = currentDateDetails.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    const endOfMonthDate = new Date(
      currentDateDetails.getFullYear(),
      currentDateDetails.getMonth() + 1,
      0
    );
    let endOfMonthFormatted = endOfMonthDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    return endOfMonthFormatted;
  };

  const postCalculateNextMonthDate = (inputDate) => {
    // console.log(isnputDate, "inputDate");
    const currentDateDetails = new Date(inputDate);

    // Get the current date
    const day = currentDateDetails.getDate();
    const month = currentDateDetails.getMonth();
    const year = currentDateDetails.getFullYear();

    // Calculate the end of the next month
    let newMonth = month + 2; // Increment by 2 to get the next month's end
    let newYear = year;
    if (newMonth > 11) {
      newMonth -= 12;
      newYear += 1;
    }

    // Create a new date object at the end of the calculated month
    const endOfNextMonthDate = new Date(newYear, newMonth, 0); // 0 gives us the last day of the previous month

    // Format the new date
    const formattedNextDate = endOfNextMonthDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    return formattedNextDate;
  };

  const calculateTranch1 = (amount, percentage) => {
    // console.log(amount, percentage, "----------");
    let percentageData =
      contextData?.dashPaymentData?.offerData?.NO_OF_TRANCHES == "1"
        ? "100%"
        : percentage;
    // Parse the percentage string and convert it to a decimal
    const percentageDecimal = parseFloat(percentageData) / 100;

    // Calculate the result by multiplying the amount with the percentage
    const result = amount * percentageDecimal;
    // console.log(result, "calculateTranch1");

    const roundedResult = result.toFixed(2);

    // Format the rounded result
    const newResult = parseFloat(roundedResult).toLocaleString("en-IN");

    return newResult;
  };

  const calculateTranch2 = (amount, percentage) => {
    // Parse the percentage string and convert it to a decimal
    const percentageDecimal =
      percentage === "" ? 0 : parseFloat(percentage) / 100;

    // Calculate the result by multiplying the amount with the percentage
    const result = amount * percentageDecimal;

    const roundedResult = result.toFixed(2);

    // Format the rounded result
    const newResult = parseFloat(roundedResult).toLocaleString("en-IN");
    return newResult;
  };

  const calculateFullTranchePayment = (tranch1Amount, tranch2Amount) => {
    let fullApprovedAmount =
      contextData?.approvedLoanAmounts?.approvedLoanData[
        contextData.currentActiveData.accountNumber
      ]?.approvedAmountValue;

    let amount1 = tranch1Amount.replace(/,/g, "");
    let amount2 = tranch2Amount.replace(/,/g, "");

    // console.log(amount1, amount2, "amountsss");
    if (dashPaymentData.offerData["NO_OF_TRANCHES"] == 1) {
      let newAmount = Number(fullApprovedAmount);
      const newResult = newAmount.toLocaleString("en-IN");
      // console.log(newResult, "one");
      return newResult;
    } else if (dashPaymentData.offerData["NO_OF_TRANCHES"] == 2) {
      if (selectedOption == "option1") {
        let newAmount = Number(fullApprovedAmount);
        const newResult = newAmount.toLocaleString("en-IN");
        // console.log(newResult, "one");
        return newResult;
      } else if (selectedOption == "option2") {
        let newAmount = Number(amount1) + Number(amount2);
        const roundedResult = newAmount.toFixed(2);
        const newResult = parseFloat(roundedResult).toLocaleString("en-IN");
        // console.log(newResult,"two");
        return newResult;
      }
    }
  };

  const postCalculateFullTranchePayment = (tranch1Amount, tranch2Amount) => {
    let fullApprovedAmount =
      contextData?.approvedLoanAmounts?.approvedLoanData[
        contextData.currentActiveData.accountNumber
      ]?.approvedAmountValue;

    let amount1 = tranch1Amount.replace(/,/g, "");
    let amount2 = tranch2Amount.replace(/,/g, "");

    // console.log(amount1, amount2, "amountsss");
    if (dashPaymentData.offerData["NO_OF_TRANCHES"] == 1) {
      let newAmount = Number(fullApprovedAmount);
      const newResult = newAmount.toLocaleString("en-IN");
      // console.log(newResult, "one");
      return newResult;
    } else if (dashPaymentData.offerData["NO_OF_TRANCHES"] == 2) {
      if (selectedOption == "option1") {
        let newAmount = Number(fullApprovedAmount);
        const newResult = newAmount.toLocaleString("en-IN");
        // console.log(newResult, "one");
        return newResult;
      } else if (selectedOption == "option2") {
        let newAmount = Number(amount1) + Number(amount2);
        const roundedResult = newAmount.toFixed(2);
        const newResult = parseFloat(roundedResult).toLocaleString("en-IN");
        // console.log(newResult,"two");
        return newResult;
      }
    }
  };

  useEffect(() => {
    if (active) {
      if (
        !dashPaymentData?.offerData["OFFER_APPROVED_DATE"] &&
        !dashPaymentData?.offerData["OFFER_END_DATE"]
      ) {
        dispatchData({
          name: "loaderFlag",
          payload: true,
          type: STOREDATA,
        });

        let getServerTimeRequest = {
          header: {
            authToken: contextData.authToken,
          },
          body: {},
        };

        commonFunctionObject
          .getServerTimeService(getServerTimeRequest)
          .then((currentServerDateTime) => {
            var serverTime = new Date(
              commonFunctionObject.replaceHyphenDate(currentServerDateTime)
            );
            // console.log(serverTime, "serverTime");

            formatCurrentDate(serverTime);
            calculateNextMonthDate(serverTime);

            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA,
            });
          });
      }
    }
  }, []);

  // console.log(selectedOption, "selectedOption");

  const checkedRadioButton = (value) => {
    setSelectedOption(value);

    if (value == "option1") {
      setSelectActive(() => false);
    } else {
      setSelectActive((prevValue) => !prevValue);
    }
  };

  const handleCrossClick = (modalType, flag) => {
    // const fetchData = async () => {
    //   const retailContractNumbers = [];
    //   // Extracting retail contract numbers from user data
    //   contextData.getUserDataV3?.body?.contracts.forEach((item) => {
    //     if (item?.system === "retail") {
    //       retailContractNumbers.push(item.CONTRACT_NUMBER);
    //     }
    //   });

    //   try {
    //     const offerDataResponse =
    //       await LoginServiceObject.offerCollectionDetails(
    //         contextData.authToken,
    //         retailContractNumbers
    //       );
    //     // console.log(offerDataResponse, "offerDataResponse");
    //     // return offerDataResponse;
    //     dispatchData({
    //       type: STOREDATA,
    //       name: "offers",
    //       payload: offerDataResponse,
    //     });

    //     setAttemptFlag(true);
    //     setPaymentSummeryFlag(false);
    //     // setPaymentFlag(true);
    //   } catch (error) {
    //     console.error("Error fetching offer data:", error);
    //   }
    // };

    // fetchData();

    // if (active) {
    //   sessionStorage.setItem("settlement-mid-journey", "true");
    // }

    // setPopOverFlag(false);
    // setPayNowFlag(false);
    sessionStorage.setItem("paymentplan-reject-flag", true);
    if (active == false) {
      sessionStorage.setItem("paymentmodal-background-amount-flag", true);
    }

    dispatchData({
      name: "offerModaLFlag",
      payload: {
        modalFlag: false,
        accountType: "paymentPlanModal",
      },
      type: STOREDATA,
    });
  };

  const convertDateFormat = (dateStr) => {
    const [day, month, year] = dateStr.split(" ");
    const months = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    const monthNum = months[month];
    const formattedDate = `${year}-${monthNum}-${day.padStart(2, "0")}`;

    return formattedDate;
  };

  const continueFunc = () => {
    if (active) {
      let availedTranches =
        selectedOption == "option1" ? 1 : selectedOption == "option2" ? 2 : "";
      // console.log(availedTranches);

      dispatchData({
        name: "paymentTrancheNumberData",
        payload: 1,
        type: STOREDATA,
      });

      handleClickForPaymentOpt(
        "handleAvailedTranches",
        "",
        availedTranches,
        "",
        contextData.currentActiveData.accountNumber
      );

      dispatchData({
        name: "loaderFlag",
        payload: true,
        type: STOREDATA,
      });

      let isTrancheOptedDetails = isTrancheOpted;
      let noOfTranchesOptedDetails = noOfTranchesOpted;
      let settlementApprovedAmount =
        contextData?.approvedLoanAmounts?.approvedLoanData[
          contextData.currentActiveData.accountNumber
        ]?.approvedAmountValue;

      LoginServiceObject.setUpdatedSettlementDetails(
        contextData,
        dashPaymentData.offerData,
        isTrancheOptedDetails,
        noOfTranchesOptedDetails,
        settlementApprovedAmount
      )
        .then(async (getDataResponse) => {
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
          // console.log(getDataResponse, "getDataResponse");
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      let percentage1 = dashPaymentData.offerData["TRANCHE_1"]
        ? dashPaymentData.offerData["TRANCHE_1"]
        : dashPaymentData.offerData["NO_OF_TRANCHES"] == 1
        ? "100%"
        : "";
      let percentage2 = dashPaymentData.offerData["TRANCHE_2"];
      const percentageDecimal1 = parseFloat(percentage1) / 100;
      const percentageDecimal2 = parseFloat(percentage2) / 100;

      const result1 =
        contextData?.approvedLoanAmounts?.approvedLoanData[
          contextData.currentActiveData.accountNumber
        ]?.approvedAmountValue * percentageDecimal1;
      const result2 =
        dashPaymentData.offerData["TRANCHE_2"] ? dashPaymentData.offerData["TRANCHE_2"] == ""
          ? 0
          : contextData?.approvedLoanAmounts?.approvedLoanData[
              contextData.currentActiveData.accountNumber
            ]?.approvedAmountValue * percentageDecimal2 : 0;
      
      console.log(result1,result2,"hereee");
      

      if (selectedOption === "option2") {
        if (dashPaymentData.offerData["TRANCHE1_PAYMENT_STATUS"]) {
          if (
            dashPaymentData.offerData["TRANCHE1_PAYMENT_STATUS"] === "Success"
          ) {
            // dispatchData({
            //   name: "approvedAmount",
            //   payload: result2,
            //   type: STOREDATA,
            // });
            const currentLoanApprovedAmount =
              contextData?.approvedLoanAmounts?.approvedLoanData || {};

            if (
              currentLoanApprovedAmount[
                contextData.currentActiveData.accountNumber
              ]
            ) {
              const updatedLoanApprovalData = {
                ...currentLoanApprovedAmount,
                [contextData.currentActiveData.accountNumber]: {
                  approvedAmountValue: result2,
                },
              };

              dispatchData({
                name: "approvedLoanAmounts",
                payload: {
                  approvedLoanData: updatedLoanApprovalData,
                },
                type: STOREDATA,
              });
            } else {
              const updatedLoanApprovalData = {
                ...currentLoanApprovedAmount,
                [contextData.currentActiveData.accountNumber]: {
                  approvedAmountValue: result2,
                },
              };

              dispatchData({
                name: "approvedLoanAmounts",
                payload: {
                  approvedLoanData: updatedLoanApprovalData,
                },
                type: STOREDATA,
              });
            }
          } else {
            // dispatchData({
            //   name: "approvedAmount",
            //   payload: result1,
            //   type: STOREDATA,
            // });

            const currentLoanApprovedAmount =
              contextData?.approvedLoanAmounts?.approvedLoanData || {};

            if (
              currentLoanApprovedAmount[
                contextData.currentActiveData.accountNumber
              ]
            ) {
              const updatedLoanApprovalData = {
                ...currentLoanApprovedAmount,
                [contextData.currentActiveData.accountNumber]: {
                  approvedAmountValue: result1,
                },
              };

              dispatchData({
                name: "approvedLoanAmounts",
                payload: {
                  approvedLoanData: updatedLoanApprovalData,
                },
                type: STOREDATA,
              });
            } else {
              const updatedLoanApprovalData = {
                ...currentLoanApprovedAmount,
                [contextData.currentActiveData.accountNumber]: {
                  approvedAmountValue: result1,
                },
              };

              dispatchData({
                name: "approvedLoanAmounts",
                payload: {
                  approvedLoanData: updatedLoanApprovalData,
                },
                type: STOREDATA,
              });
            }
          }
        } else {
          // dispatchData({
          //   name: "approvedAmount",
          //   payload: result1,
          //   type: STOREDATA,
          // });
          const currentLoanApprovedAmount =
            contextData?.approvedLoanAmounts?.approvedLoanData || {};

          if (
            currentLoanApprovedAmount[
              contextData.currentActiveData.accountNumber
            ]
          ) {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: result1,
                finalApprovedAmountValue: result1 + result2,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          } else {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: result1,
                finalApprovedAmountValue: result1 + result2,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          }
        }

        let convertedTrancheDate1 = convertDateFormat(
          trancheData?.tranche1Date
        );
        let convertedTrancheDate2 = convertDateFormat(
          trancheData?.tranche2Date
        );

        let trancheDateAmountDetails = {
          noOfTrancheOpted: 2,
          eventTranche1Amount: result1.toFixed(2),
          eventTranche1Date: convertedTrancheDate1,
          eventTranche2Amount: result2.toFixed(2),
          eventTranche2Date: convertedTrancheDate2,
        };

        dispatchData({
          name: "eventTrancheDetails",
          payload: trancheDateAmountDetails,
          type: STOREDATA,
        });
      } else if (selectedOption === "option1") {
        const currentLoanApprovedAmount =
          contextData?.approvedLoanAmounts?.approvedLoanData || {};

        const accountNumber = contextData.currentActiveData.accountNumber;

        if (currentLoanApprovedAmount[accountNumber]) {
          const updatedLoanApprovalData = {
            ...currentLoanApprovedAmount,
            [accountNumber]: {
              ...currentLoanApprovedAmount[accountNumber],
              finalApprovedAmountValue: result1 + result2,
            },
          };

          dispatchData({
            name: "approvedLoanAmounts",
            payload: {
              approvedLoanData: updatedLoanApprovalData,
            },
            type: STOREDATA,
          });
        } else {
          const updatedLoanApprovalData = {
            ...currentLoanApprovedAmount,
            [accountNumber]: {
              ...currentLoanApprovedAmount[accountNumber],
              finalApprovedAmountValue: result1 + result2,
            },
          };

          dispatchData({
            name: "approvedLoanAmounts",
            payload: {
              approvedLoanData: updatedLoanApprovalData,
            },
            type: STOREDATA,
          });
        }

        let convertedTrancheDate1 = convertDateFormat(
          trancheData?.tranche1Date
        );

        let trancheDateAmountDetails = {
          noOfTrancheOpted: 1,
          eventTranche1Amount: (result1 + result2).toFixed(2),
          eventTranche1Date: convertedTrancheDate1,
          eventTranche2Amount: 0,
          eventTranche2Date: "",
        };

        dispatchData({
          name: "eventTrancheDetails",
          payload: trancheDateAmountDetails,
          type: STOREDATA,
        });

        dispatchData({
          name: "offerModaLFlag",
          payload: {
            modalFlag: true,
            accountType: "congratsModal",
          },
          type: STOREDATA,
        });
      }
      dispatchData({
        name: "offerModaLFlag",
        payload: {
          modalFlag: true,
          accountType: "congratsModal",
        },
        type: STOREDATA,
      });
      setSuccessFailureFlag(true);
    } else if (active == false) {
      let totalOverdueAmountPgs =
        dashPaymentData?.loanDetails[3]?.value +
        dashPaymentData?.loanDetails[4]?.value;
      // console.log(totalOverdueAmountPgs,"totalOverdueAmountPgs");

      const getofferKey = (loan) => {
        // console.log(loan,"iuhuyfcfdx");
        const keysToCheck = [
          "SETTLEMENT_CAMPAIGN",
          "EMI_OVERDUE_CAMPAIGN",
          "ODBC_CAMPAIGN",
          "LOS_POOL_CAMPAIGN",
          "ALREADY_SETTLED_POOL_CAMPAIGN",
        ];

        for (const key of keysToCheck) {
          if (loan[key] === "Y") {
            return key;
          }
        }

        return null;
      };

      const getOfferVal = getofferKey(contextData?.dashPaymentData?.offerData);
      // console.log(getOfferVal, "getOfferVal");

      dispatchData({
        name: "latestPaymentData",
        payload: {
          phase: 2,
          labelName: "",
          amount: "",
        },
      });

      dispatchData({
        name: "paymentRedirectionAccountNumber",
        payload: contextData.currentActiveData.accountNumber,
        type: STOREDATA,
      });

      dispatchData({
        name: "currentOfferPopup",
        payload: getOfferVal,
        type: STOREDATA,
      });

      dispatchData({
        name: "offerModaLFlag",
        payload: {
          modalFlag: false,
          accountType: "paymentPlanModal",
        },
        type: STOREDATA,
      });
      if (
        contextData?.dashPaymentData?.offerData?.["IS_TRANCHE_OPTED"] === "N"
      ) {
        let tranche1PayData = calculateTranch1(
          contextData?.dashPaymentData?.offerData?.["OFFER_APPROVED_AMOUNT"],
          contextData?.dashPaymentData?.offerData?.["TRANCHE_1"]
        );
        let tranche2PayData = calculateTranch2(
          contextData?.dashPaymentData?.offerData?.["OFFER_APPROVED_AMOUNT"],
          contextData?.dashPaymentData?.offerData?.["TRANCHE_2"]
        );
        const num1 = parseInt(tranche1PayData?.replace(/,/g, ""), 10);
        const num2 = parseInt(tranche2PayData?.replace(/,/g, ""), 10);

        const sum = num1 + num2;

        // dispatchData({
        //   name: "approvedAmount",
        //   payload: sum,
        //   type: STOREDATA,
        // });

        const currentLoanApprovedAmount =
          contextData?.approvedLoanAmounts?.approvedLoanData || {};

        if (
          currentLoanApprovedAmount[contextData.currentActiveData.accountNumber]
        ) {
          const updatedLoanApprovalData = {
            ...currentLoanApprovedAmount,
            [contextData.currentActiveData.accountNumber]: {
              approvedAmountValue: sum,
            },
          };

          dispatchData({
            name: "approvedLoanAmounts",
            payload: {
              approvedLoanData: updatedLoanApprovalData,
            },
            type: STOREDATA,
          });
        } else {
          const updatedLoanApprovalData = {
            ...currentLoanApprovedAmount,
            [contextData.currentActiveData.accountNumber]: {
              approvedAmountValue: sum,
            },
          };

          dispatchData({
            name: "approvedLoanAmounts",
            payload: {
              approvedLoanData: updatedLoanApprovalData,
            },
            type: STOREDATA,
          });
        }

        if (
          contextData?.dashPaymentData?.offerData["TRANCHE1_PAYMENT_STATUS"]
        ) {
          if (
            contextData?.dashPaymentData?.offerData[
              "TRANCHE1_PAYMENT_STATUS"
            ] === "Failed" ||
            contextData?.dashPaymentData?.offerData[
              "TRANCHE1_PAYMENT_STATUS"
            ] === "Pending" ||
            contextData?.dashPaymentData?.offerData[
              "TRANCHE1_PAYMENT_STATUS"
            ] === "INITIATED"
          ) {
            dispatchData({
              name: "loaderFlag",
              type: STOREDATA,
              payload: true,
            });

            dispatchData({
              name: "paymentTrancheNumberData",
              payload: 1,
              type: STOREDATA,
            });

            let trancheNumber = 1;
            let data =
              contextData?.pgsPaymentDetails?.[
                contextData.currentActiveData.accountNumber
              ];
            // console.log(data, "data");
            // setTimeout(() => {
            //   console.log("This message is displayed after 3 seconds");
            // }, 3000);
            pgsApiCallFn(
              contextData,
              data?.userobj,
              dispatchData,
              sum,
              "offer",
              contextData?.dashPaymentData?.offerData["IS_TRANCHE_OPTED"],
              contextData?.dashPaymentData?.offerData["NO_OF_TRANCHES_OPTED"],
              getOfferVal,
              trancheNumber,
              dashPaymentData?.loanDetails[3]?.value,
              dashPaymentData?.loanDetails[4]?.value,
              totalOverdueAmountPgs
            )
              .then((uniqueTransactionId) => {
                // console.log(uniqueTransactionId, "uniqueTransactionId");
                handleClickForPaymentOpt(
                  "handlePaymentGridTransactionId",
                  "",
                  uniqueTransactionId,
                  "",
                  contextData.currentActiveData.accountNumber
                );
                dispatchData({
                  name: "uniqueTransactionId",
                  type: STOREDATA,
                  payload: uniqueTransactionId,
                });
                // setPaymentOptClickCount(() => ({}));
                setFinalPayOptClickObj(
                  uniqueTransactionId,
                  "settlementOfferSummaryPopupClick"
                );
                // setTimeout(() => {
                //   console.log("This message is displayed after 3 seconds");
                // }, 3000);
                dispatchData({
                  name: "activePaymentLoanData",
                  payload: {
                    loanAccNumber: contextData.currentActiveData.accountNumber,
                    activePaymentFlag: true,
                    isTrancheOpted:
                      contextData?.dashPaymentData?.offerData[
                        "IS_TRANCHE_OPTED"
                      ],
                  },
                  type: STOREDATA,
                });
                dispatchData({
                  name: "loaderFlag",
                  type: STOREDATA,
                  payload: false,
                });
              })
              .catch((err) => {
                console.log(err);
                dispatchData({
                  name: "loaderFlag",
                  type: STOREDATA,
                  payload: false,
                });

                setFailurePopUpModal(true);
                setFailurePopUpContent({
                  apiName: "",
                  message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
                });
              });
          }
        } else {
          dispatchData({
            name: "loaderFlag",
            type: STOREDATA,
            payload: true,
          });

          dispatchData({
            name: "paymentTrancheNumberData",
            payload: 1,
            type: STOREDATA,
          });

          let trancheNumber = 1;

          let data =
            contextData?.pgsPaymentDetails?.[
              contextData.currentActiveData.accountNumber
            ];
          // console.log(data, "data");

          pgsApiCallFn(
            contextData,
            data?.userobj,
            dispatchData,
            sum,
            "offer",
            contextData?.dashPaymentData?.offerData["IS_TRANCHE_OPTED"],
            contextData?.dashPaymentData?.offerData["NO_OF_TRANCHES_OPTED"],
            getOfferVal,
            trancheNumber,
            dashPaymentData?.loanDetails[3]?.value,
            dashPaymentData?.loanDetails[4]?.value,
            totalOverdueAmountPgs
          )
            .then((uniqueTransactionId) => {
              // console.log(uniqueTransactionId, "uniqueTransactionId");
              handleClickForPaymentOpt(
                "handlePaymentGridTransactionId",
                "",
                uniqueTransactionId,
                "",
                contextData.currentActiveData.accountNumber
              );
              dispatchData({
                name: "uniqueTransactionId",
                type: STOREDATA,
                payload: uniqueTransactionId,
              });
              // setPaymentOptClickCount(() => ({}));
              setFinalPayOptClickObj(
                uniqueTransactionId,
                "settlementOfferSummaryPopupClick"
              );

              dispatchData({
                name: "activePaymentLoanData",
                payload: {
                  loanAccNumber: contextData.currentActiveData.accountNumber,
                  activePaymentFlag: true,
                  isTrancheOpted:
                    contextData?.dashPaymentData?.offerData["IS_TRANCHE_OPTED"],
                },
                type: STOREDATA,
              });
              dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false,
              });
            })
            .catch((err) => {
              console.log(err);
              dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false,
              });

              setFailurePopUpModal(true);
              setFailurePopUpContent({
                apiName: "",
                message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
              });
            });
        }
      } else if (
        contextData?.dashPaymentData?.offerData["IS_TRANCHE_OPTED"] === "Y"
      ) {
        let tranche1PayData = calculateTranch1(
          contextData?.dashPaymentData?.offerData["OFFER_APPROVED_AMOUNT"],
          contextData?.dashPaymentData?.offerData["TRANCHE_1"]
        );
        let tranche2PayData = calculateTranch2(
          contextData?.dashPaymentData?.offerData["OFFER_APPROVED_AMOUNT"],
          contextData?.dashPaymentData?.offerData["TRANCHE_2"]
        );
        const num1 = parseInt(tranche1PayData?.replace(/,/g, ""), 10);
        const num2 = parseInt(tranche2PayData?.replace(/,/g, ""), 10);

        if (
          contextData?.dashPaymentData?.offerData["TRANCHE1_PAYMENT_STATUS"]
        ) {
          if (
            contextData?.dashPaymentData?.offerData[
              "TRANCHE1_PAYMENT_STATUS"
            ] === "Failed" ||
            contextData?.dashPaymentData?.offerData[
              "TRANCHE1_PAYMENT_STATUS"
            ] === "Pending" ||
            contextData?.dashPaymentData?.offerData[
              "TRANCHE1_PAYMENT_STATUS"
            ] === "INITIATED"
          ) {
            dispatchData({
              name: "loaderFlag",
              type: STOREDATA,
              payload: true,
            });

            // dispatchData({
            //   name: "approvedAmount",
            //   payload: num1,
            //   type: STOREDATA,
            // });

            const currentLoanApprovedAmount =
              contextData?.approvedLoanAmounts?.approvedLoanData || {};

            if (
              currentLoanApprovedAmount[
                contextData.currentActiveData.accountNumber
              ]
            ) {
              const updatedLoanApprovalData = {
                ...currentLoanApprovedAmount,
                [contextData.currentActiveData.accountNumber]: {
                  approvedAmountValue: num1,
                },
              };

              dispatchData({
                name: "approvedLoanAmounts",
                payload: {
                  approvedLoanData: updatedLoanApprovalData,
                },
                type: STOREDATA,
              });
            } else {
              const updatedLoanApprovalData = {
                ...currentLoanApprovedAmount,
                [contextData.currentActiveData.accountNumber]: {
                  approvedAmountValue: num1,
                },
              };

              dispatchData({
                name: "approvedLoanAmounts",
                payload: {
                  approvedLoanData: updatedLoanApprovalData,
                },
                type: STOREDATA,
              });
            }

            dispatchData({
              name: "paymentTrancheNumberData",
              payload: 1,
              type: STOREDATA,
            });

            let trancheNumber = 1;

            let data =
              contextData?.pgsPaymentDetails?.[
                contextData.currentActiveData.accountNumber
              ];
            // console.log(data, "data");

            pgsApiCallFn(
              contextData,
              data?.userobj,
              dispatchData,
              num1,
              "offer",
              contextData?.dashPaymentData?.offerData["IS_TRANCHE_OPTED"],
              contextData?.dashPaymentData?.offerData["NO_OF_TRANCHES_OPTED"],
              getOfferVal,
              trancheNumber,
              dashPaymentData?.loanDetails[3]?.value,
              dashPaymentData?.loanDetails[4]?.value,
              totalOverdueAmountPgs
            )
              .then((uniqueTransactionId) => {
                // console.log(uniqueTransactionId, "uniqueTransactionId");
                handleClickForPaymentOpt(
                  "handlePaymentGridTransactionId",
                  "",
                  uniqueTransactionId,
                  "",
                  contextData.currentActiveData.accountNumber
                );
                dispatchData({
                  name: "uniqueTransactionId",
                  type: STOREDATA,
                  payload: uniqueTransactionId,
                });
                // setPaymentOptClickCount(() => ({}));
                setFinalPayOptClickObj(
                  uniqueTransactionId,
                  "settlementOfferSummaryPopupClick"
                );

                dispatchData({
                  name: "activePaymentLoanData",
                  payload: {
                    loanAccNumber: contextData.currentActiveData.accountNumber,
                    activePaymentFlag: true,
                    isTrancheOpted:
                      contextData?.dashPaymentData?.offerData[
                        "IS_TRANCHE_OPTED"
                      ],
                  },
                  type: STOREDATA,
                });
                dispatchData({
                  name: "loaderFlag",
                  type: STOREDATA,
                  payload: false,
                });
              })
              .catch((err) => {
                console.log(err);
                dispatchData({
                  name: "loaderFlag",
                  type: STOREDATA,
                  payload: false,
                });

                setFailurePopUpModal(true);
                setFailurePopUpContent({
                  apiName: "",
                  message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
                });
              });
          } else if (
            contextData?.dashPaymentData?.offerData[
              "TRANCHE1_PAYMENT_STATUS"
            ] === "Success"
          ) {
            dispatchData({
              name: "loaderFlag",
              type: STOREDATA,
              payload: true,
            });

            // dispatchData({
            //   name: "approvedAmount",
            //   payload: num2,
            //   type: STOREDATA,
            // });

            const currentLoanApprovedAmount =
              contextData?.approvedLoanAmounts?.approvedLoanData || {};

            if (
              currentLoanApprovedAmount[
                contextData.currentActiveData.accountNumber
              ]
            ) {
              const updatedLoanApprovalData = {
                ...currentLoanApprovedAmount,
                [contextData.currentActiveData.accountNumber]: {
                  approvedAmountValue: num2,
                },
              };

              dispatchData({
                name: "approvedLoanAmounts",
                payload: {
                  approvedLoanData: updatedLoanApprovalData,
                },
                type: STOREDATA,
              });
            } else {
              const updatedLoanApprovalData = {
                ...currentLoanApprovedAmount,
                [contextData.currentActiveData.accountNumber]: {
                  approvedAmountValue: num2,
                },
              };

              dispatchData({
                name: "approvedLoanAmounts",
                payload: {
                  approvedLoanData: updatedLoanApprovalData,
                },
                type: STOREDATA,
              });
            }

            dispatchData({
              name: "paymentTrancheNumberData",
              payload: 2,
              type: STOREDATA,
            });

            let trancheNumber = 2;

            let data =
              contextData?.pgsPaymentDetails?.[
                contextData.currentActiveData.accountNumber
              ];
            // console.log(data, "data");

            pgsApiCallFn(
              contextData,
              data?.userobj,
              dispatchData,
              num2,
              "offer",
              contextData?.dashPaymentData?.offerData["IS_TRANCHE_OPTED"],
              contextData?.dashPaymentData?.offerData["NO_OF_TRANCHES_OPTED"],
              getOfferVal,
              trancheNumber,
              dashPaymentData?.loanDetails[3]?.value,
              dashPaymentData?.loanDetails[4]?.value,
              totalOverdueAmountPgs
            )
              .then((uniqueTransactionId) => {
                // console.log(uniqueTransactionId, "uniqueTransactionId");
                handleClickForPaymentOpt(
                  "handlePaymentGridTransactionId",
                  "",
                  uniqueTransactionId,
                  "",
                  contextData.currentActiveData.accountNumber
                );
                dispatchData({
                  name: "uniqueTransactionId",
                  type: STOREDATA,
                  payload: uniqueTransactionId,
                });
                // setPaymentOptClickCount(() => ({}));
                setFinalPayOptClickObj(
                  uniqueTransactionId,
                  "settlementOfferSummaryPopupClick"
                );

                dispatchData({
                  name: "activePaymentLoanData",
                  payload: {
                    loanAccNumber: contextData.currentActiveData.accountNumber,
                    activePaymentFlag: true,
                    isTrancheOpted:
                      contextData?.dashPaymentData?.offerData[
                        "IS_TRANCHE_OPTED"
                      ],
                  },
                  type: STOREDATA,
                });
                dispatchData({
                  name: "loaderFlag",
                  type: STOREDATA,
                  payload: false,
                });
              })
              .catch((err) => {
                console.log(err);
                dispatchData({
                  name: "loaderFlag",
                  type: STOREDATA,
                  payload: false,
                });

                setFailurePopUpModal(true);
                setFailurePopUpContent({
                  apiName: "",
                  message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
                });
              });
          }
        } else {
          dispatchData({
            name: "loaderFlag",
            type: STOREDATA,
            payload: true,
          });

          // dispatchData({
          //   name: "approvedAmount",
          //   payload: num1,
          //   type: STOREDATA,
          // });

          const currentLoanApprovedAmount =
            contextData?.approvedLoanAmounts?.approvedLoanData || {};

          if (
            currentLoanApprovedAmount[
              contextData.currentActiveData.accountNumber
            ]
          ) {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: num1,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          } else {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: num1,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          }

          dispatchData({
            name: "paymentTrancheNumberData",
            payload: 1,
            type: STOREDATA,
          });

          let trancheNumber = 1;

          let data =
            contextData?.pgsPaymentDetails?.[
              contextData.currentActiveData.accountNumber
            ];
          // console.log(data, "data");

          pgsApiCallFn(
            contextData,
            data?.userobj,
            dispatchData,
            num1,
            "offer",
            contextData?.dashPaymentData?.offerData["IS_TRANCHE_OPTED"],
            contextData?.dashPaymentData?.offerData["NO_OF_TRANCHES_OPTED"],
            getOfferVal,
            trancheNumber,
            dashPaymentData?.loanDetails[3]?.value,
            dashPaymentData?.loanDetails[4]?.value,
            totalOverdueAmountPgs
          )
            .then((uniqueTransactionId) => {
              // console.log(uniqueTransactionId, "uniqueTransactionId");
              handleClickForPaymentOpt(
                "handlePaymentGridTransactionId",
                "",
                uniqueTransactionId,
                "",
                contextData.currentActiveData.accountNumber
              );
              dispatchData({
                name: "uniqueTransactionId",
                type: STOREDATA,
                payload: uniqueTransactionId,
              });
              // setPaymentOptClickCount(() => ({}));
              setFinalPayOptClickObj(
                uniqueTransactionId,
                "settlementOfferSummaryPopupClick"
              );

              dispatchData({
                name: "activePaymentLoanData",
                payload: {
                  loanAccNumber: contextData.currentActiveData.accountNumber,
                  activePaymentFlag: true,
                  isTrancheOpted:
                    contextData?.dashPaymentData?.offerData["IS_TRANCHE_OPTED"],
                },
                type: STOREDATA,
              });
              dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false,
              });
            })
            .catch((err) => {
              console.log(err);
              dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false,
              });

              setFailurePopUpModal(true);
              setFailurePopUpContent({
                apiName: "",
                message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
              });
            });
        }
      }
    }
  };

  useEffect(() => {
    if (active) {
      if (dashPaymentData.offerData["NO_OF_TRANCHES"] == 1) {
        let tranche1Data = calculateTranch1(
          contextData?.approvedLoanAmounts?.approvedLoanData[
            contextData.currentActiveData.accountNumber
          ]?.approvedAmountValue,
          dashPaymentData.offerData["TRANCHE_1"]
        );
        // console.log(tranche1Data, "tranche1Data");
        if (active) {
          setTrancheData((prevState) => {
            return {
              ...prevState,
              tranche1Data: tranche1Data,
              tranche2Data: "",
            };
          });
        }
      } else if (dashPaymentData.offerData["NO_OF_TRANCHES"] == 2) {
        let tranche1Data = calculateTranch1(
          contextData?.approvedLoanAmounts?.approvedLoanData[
            contextData.currentActiveData.accountNumber
          ]?.approvedAmountValue,
          dashPaymentData.offerData["TRANCHE_1"]
        );
        let tranche2Data = calculateTranch2(
          contextData?.approvedLoanAmounts?.approvedLoanData[
            contextData.currentActiveData.accountNumber
          ]?.approvedAmountValue,
          dashPaymentData.offerData["TRANCHE_2"]
        );
        if (active) {
          setTrancheData((prevState) => {
            return {
              ...prevState,
              tranche1Data: tranche1Data,
              tranche2Data: tranche2Data,
            };
          });
        }
      }
    }

    if (active == false) {
      // fetchData();
      const object = contextData?.offers?.body?.offerDetails?.find(
        (ele) =>
          ele?.LOAN_ACCOUNT_NO === contextData?.currentActiveData?.accountNumber
      );
      // console.log(object, "object");

      function formatNumber(numberStr) {
        // Parse the string as a float
        let num = parseFloat(numberStr);

        // Check if the number is an integer (i.e., no decimal part)
        if (num % 1 === 0) {
          return num; // Return the number without decimals
        } else {
          return num.toString(); // Return the number as a string with the decimal part
        }
      }

      let tranche1PayData = calculateTranch1(
        object?.["OFFER_APPROVED_AMOUNT"],
        object?.["TRANCHE_1"]
      );
      const formattedTranche1PayData = Number(
        tranche1PayData.replace(/,/g, "")
      );

      let tranche2PayData = calculateTranch2(
        object?.["OFFER_APPROVED_AMOUNT"],
        object?.["TRANCHE_2"]
      );

      const formattedTranche2PayData = Number(
        tranche2PayData.replace(/,/g, "")
      );

      let tranche1PaymentDate = object["TRANCHE1_PAYMENT_DATE"]
        ? object["TRANCHE1_PAYMENT_DATE"]
        : "";
      let tranche1PaymentStatus = object["TRANCHE1_PAYMENT_STATUS"]
        ? object["TRANCHE1_PAYMENT_STATUS"]
        : "";

      let tranche2PaymentDate = object["TRANCHE2_PAYMENT_DATE"]
        ? object["TRANCHE2_PAYMENT_DATE"]
        : "";
      let tranche2PaymentStatus = object["TRANCHE2_PAYMENT_STATUS"]
        ? object["TRANCHE2_PAYMENT_STATUS"]
        : "";

      var offerDueDate = postFormatCurrentDate(object?.["OFFER_APPROVED_DATE"]);
      // console.log(offerDueDate, "offerDueDate");

      let dateObj = new Date(offerDueDate);

      let year = dateObj.getFullYear();
      let month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // getMonth() is zero-based
      let day = ("0" + dateObj.getDate()).slice(-2);

      let formattedDueDate = `${year}-${month}-${day}`;

      if (object["IS_TRANCHE_OPTED"] == "N") {
        let lanEventPaymentGridDetails = {
          trancheOpted: 1,
          tranche1_due_date: formattedDueDate,
          tranche1_due_amount: formatNumber(
            formattedTranche1PayData + formattedTranche2PayData
          ),
          tranche1PaymentDate: tranche1PaymentDate,
          tranche1PaymentStatus: tranche1PaymentStatus,
          minimumSettlementAmountVal: object["SETTLEMENT_AMOUNT_MIN"],
          availableTranchesVal: object["NO_OF_TRANCHES"],
          availedTranchesVal: object["NO_OF_TRANCHES_OPTED"],
          offerApprovedAmountVal: object["OFFER_APPROVED_AMOUNT"],
          offer_FC: object["FC"],
          offer_FC_POS: object["FC_POS"],
          offer_FC_INT: object["FC_INT"],
          offer_FC_CHARGES: object["FC_CHARGES"],
        };
        // console.log(lanEventPaymentGridDetails,"lanEventPaymentGridDetails");
        handleClickForPaymentOpt(
          "paymentGridDetails",
          "",
          lanEventPaymentGridDetails,
          "",
          contextData.currentActiveData.accountNumber
        );
      } else if (object["IS_TRANCHE_OPTED"] == "Y") {
        let lanEventPaymentGridDetails = {
          trancheOpted: 2,
          tranche1_due_date_data: formattedDueDate,
          tranche1_due_amount_data: formatNumber(formattedTranche1PayData),
          tranche2_due_date_data: object["OFFER_END_DATE"],
          tranche2_due_amount_data: formatNumber(formattedTranche2PayData),
          tranche1PaymentDateData: tranche1PaymentDate,
          tranche1PaymentStatusData: tranche1PaymentStatus,
          tranche2PaymentDateData: tranche2PaymentDate,
          tranche2PaymentStatusData: tranche2PaymentStatus,
          minimumSettlementAmountVal: object["SETTLEMENT_AMOUNT_MIN"],
          availableTranchesVal: object["NO_OF_TRANCHES"],
          availedTranchesVal: object["NO_OF_TRANCHES_OPTED"],
          offerApprovedAmountVal: object["OFFER_APPROVED_AMOUNT"],
          offer_FC: object["FC"],
          offer_FC_POS: object["FC_POS"],
          offer_FC_INT: object["FC_INT"],
          offer_FC_CHARGES: object["FC_CHARGES"],
        };

        // console.log(lanEventPaymentGridDetails,"lanEventPaymentGridDetails");
        handleClickForPaymentOpt(
          "paymentGridDetails",
          "",
          lanEventPaymentGridDetails,
          "",
          contextData.currentActiveData.accountNumber
        );
      }
      // console.log(formattedDueDate);
      let offerPaymentFinalStatus = "";
      if (object?.["NO_OF_TRANCHES_OPTED"] == "1") {
        if (object?.["TRANCHE1_PAYMENT_STATUS"]) {
          if (object?.["TRANCHE1_PAYMENT_STATUS"] === "Success") {
            offerPaymentFinalStatus = "Paid";
          } else if (object?.["TRANCHE1_PAYMENT_STATUS"] === "Failed") {
            offerPaymentFinalStatus = "Unpaid";
          } else if (object?.["TRANCHE1_PAYMENT_STATUS"] === "INITIATED") {
            offerPaymentFinalStatus = "Unpaid";
          }
        } else {
          offerPaymentFinalStatus = "Unpaid";
        }
      } else if (object?.["NO_OF_TRANCHES_OPTED"] == "2") {
        if (object?.["TRANCHE1_PAYMENT_STATUS"]) {
          if (object?.["TRANCHE1_PAYMENT_STATUS"] === "Success") {
            if (object?.["TRANCHE2_PAYMENT_STATUS"]) {
              if (object?.["TRANCHE2_PAYMENT_STATUS"] === "Success") {
                offerPaymentFinalStatus = "Paid";
              } else if (object?.["TRANCHE1_PAYMENT_STATUS"] === "Failed") {
                offerPaymentFinalStatus = "Partially Paid";
              } else if (object?.["TRANCHE1_PAYMENT_STATUS"] === "INITIATED") {
                offerPaymentFinalStatus = "Partially Paid";
              }
            } else {
              offerPaymentFinalStatus = "Partially Paid";
            }
          } else if (object?.["TRANCHE1_PAYMENT_STATUS"] === "Failed") {
            offerPaymentFinalStatus = "Unpaid";
          } else if (object?.["TRANCHE1_PAYMENT_STATUS"] === "INITIATED") {
            offerPaymentFinalStatus = "Unpaid";
          }
        } else {
          offerPaymentFinalStatus = "Unpaid";
        }
      }
    }
  }, []);

  return (
    <>
      <div className="c_payment_plan">
        <div className="c_overlay">
          <div
            className={
              active
                ? "c_payment_modal"
                : "c_payment_modal c_payment_modal_active"
            }
          >
            <div className="c_mod_close_icon">
              <img
                className="c_cross_icon"
                src="assets/images/crossImg.svg"
                alt="modal-close"
                onClick={() => {
                  handleCrossClick("paymentPlanModal", false);
                }}
              />
            </div>
            <div className="c_pay_modal_img">
              <img
                className="c_calendar_icon"
                src="assets/images/calendar-modal-icon.svg"
                alt="Calendar Icon"
              />
            </div>
            <div className="c_pay_modal_content">
              <h3 className="c_payment_status_text">
                {active ? "Payment Plan" : "Payment Status"}
              </h3>
              <p className="c_payment_status_text_para">
                {active
                  ? "Please confirm how you would like to pay:"
                  : "Below is the current status of your payment"}
              </p>
            </div>
            {active ? (
              <div className="c_one_time_installment">
                <div
                  className="c_one_time"
                  onClick={() => {
                    checkedRadioButton("option1");
                    setPaymentModalDetails({
                      offerType: getofferKey(dashPaymentData?.offerData),
                      trancheNumber: 1,
                    });
                    // setShowInstallmentFlag(false);
                    // setInstallmentText("Installments");
                    setShowTranches(false);
                  }}
                >
                  <input
                    className="c_radio_btn_payment_plan"
                    name="payment_due"
                    id="payment_due_one"
                    type="radio"
                    defaultChecked="checked"
                    checked={selectedOption === "option1"}
                    onChange={() => {}}
                  />
                  <label htmlFor="payment_due_one" className="c_one_time_text">
                    One Installment
                  </label>
                </div>
                <p className="c_or_text">Or</p>
                <div
                  className={`c_one_time ${
                    dashPaymentData?.offerData["NO_OF_TRANCHES"] == 1
                      ? "multiple-installments-disabled"
                      : ""
                  }`}
                  onClick={() => {
                    checkedRadioButton("option2");
                    setNoOfTranchesOpted("2");
                    setShowTranches(true);
                    setPaymentModalDetails({
                      offerType: getofferKey(dashPaymentData?.offerData),
                      trancheNumber: 1,
                    });
                  }}
                >
                  <input
                    className="c_radio_btn_payment_plan"
                    name="payment_due"
                    id="payment_due_two"
                    type="radio"
                    defaultChecked="checked"
                    checked={selectedOption === "option2"}
                    onChange={() => {}}
                  />
                  <label htmlFor="payment_due_two" className="c_one_time_text">
                    Two Installments
                  </label>
                </div>
                {/* <div
                  className={`${
                    dashPaymentData.offerData["NO_OF_TRANCHES"] == 1
                      ? "multiple-installments-disabled"
                      : ""
                  }`}
                >
                  <div
                    className={
                      selectActive
                        ? "c_one_time payment_installment_wrapper active"
                        : "c_one_time payment_installment_wrapper"
                    }
                    onClick={() => {
                      checkedRadioButton("option2");
                      setIsTrancheOpted("Y");
                      setShowOptions(true);
                    }}
                  >
                    <p className="c_one_time_text">{installmentText}</p>
                    <img
                      className="c_one_time_arr"
                      src="./assets/images/arrow-circle-right.svg"
                    />
                    <div className="payment_installment_select">
                      {showOptions && (
                        <p className="payment_installment_option">
                          {" "}
                          {dashPaymentData.offerData["NO_OF_TRANCHES"] == 2 && (
                            <>
                              <p
                                className="payment_installment_option"
                                onClick={() => {
                                  setInstallmentText("Two Installments");
                                  setShowInstallmentFlag(true);
                                  setShowTranches(true);
                                  setNoOfTranchesOpted("2");
                                  setPaymentModalDetails({
                                    offerType: getofferKey(
                                      dashPaymentData.offerData
                                    ),
                                    trancheNumber: 1,
                                  });
                                }}
                              >
                                <option>Two Installments</option>
                              </p>
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
            ) : null}
            <div className="c_pay_modal_table_main_div">
              <table
                className={
                  active
                    ? "c_pay_modal_table"
                    : "c_pay_modal_table c_pay_modal_table_active"
                }
              >
                <tr className="c_payment_date_amount_text">
                  <th className="c_payment_due_text">Pay Before</th>
                  <th className="c_payment_amount_text">Amount</th>
                </tr>

                {active && (
                  <>
                    {dashPaymentData?.offerData["NO_OF_TRANCHES"] == 1 ? (
                      <>
                        <tr className="c_date_amount_div">
                          <td className="c_date">
                            {paymentDates.currentMonthDate}
                          </td>
                          <td className="c_amount">
                            ₹{" "}
                            {calculateFullTranchePayment(
                              calculateTranch1(
                                contextData?.approvedLoanAmounts
                                  ?.approvedLoanData[
                                  contextData.currentActiveData.accountNumber
                                ]?.approvedAmountValue,
                                dashPaymentData?.offerData["TRANCHE_1"]
                              ),
                              calculateTranch2(
                                contextData?.approvedLoanAmounts
                                  ?.approvedLoanData[
                                  contextData.currentActiveData.accountNumber
                                ]?.approvedAmountValue,
                                dashPaymentData?.offerData["TRANCHE_2"]
                              )
                            )}
                          </td>
                        </tr>
                      </>
                    ) : dashPaymentData?.offerData["NO_OF_TRANCHES"] == 2 ? (
                      <>
                        {selectedOption === "option1" ? (
                          <>
                            <tr className="c_date_amount_div">
                              <td className="c_date">
                                {paymentDates.currentMonthDate}
                              </td>
                              <td className="c_amount">
                                ₹{" "}
                                {calculateFullTranchePayment(
                                  calculateTranch1(
                                    contextData?.approvedLoanAmounts
                                      ?.approvedLoanData[
                                      contextData.currentActiveData
                                        .accountNumber
                                    ]?.approvedAmountValue,
                                    dashPaymentData?.offerData["TRANCHE_1"]
                                  ),
                                  calculateTranch2(
                                    contextData?.approvedLoanAmounts
                                      ?.approvedLoanData[
                                      contextData.currentActiveData
                                        .accountNumber
                                    ]?.approvedAmountValue,
                                    dashPaymentData?.offerData["TRANCHE_2"]
                                  )
                                )}
                              </td>
                            </tr>
                          </>
                        ) : selectedOption === "option2" ? (
                          <>
                            {showTranches ? (
                              <>
                                <tr className="c_date_amount_div">
                                  <td className="c_date">
                                    {paymentDates.currentMonthDate}
                                  </td>
                                  <td className="c_amount">
                                    ₹{" "}
                                    {calculateTranch1(
                                      contextData?.approvedLoanAmounts
                                        ?.approvedLoanData[
                                        contextData.currentActiveData
                                          .accountNumber
                                      ]?.approvedAmountValue,
                                      dashPaymentData?.offerData["TRANCHE_1"]
                                    )}
                                  </td>
                                </tr>
                                <tr className="c_date_amount_div2">
                                  <td className="c_date2">
                                    {paymentDates.nextMonthDate}
                                  </td>
                                  <td className="c_amount2">
                                    ₹{" "}
                                    {calculateTranch2(
                                      contextData?.approvedLoanAmounts
                                        ?.approvedLoanData[
                                        contextData.currentActiveData
                                          .accountNumber
                                      ]?.approvedAmountValue,
                                      dashPaymentData?.offerData["TRANCHE_2"]
                                    )}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                <tr className="c_date_amount_div">
                                  <td className="c_date">
                                    {paymentDates.currentMonthDate}
                                  </td>
                                  <td className="c_amount">
                                    ₹{" "}
                                    {calculateFullTranchePayment(
                                      calculateTranch1(
                                        contextData?.approvedLoanAmounts
                                          ?.approvedLoanData[
                                          contextData.currentActiveData
                                            .accountNumber
                                        ]?.approvedAmountValue,
                                        dashPaymentData?.offerData["TRANCHE_1"]
                                      ),
                                      calculateTranch2(
                                        contextData?.approvedLoanAmounts
                                          ?.approvedLoanData[
                                          contextData.currentActiveData
                                            .accountNumber
                                        ]?.approvedAmountValue,
                                        dashPaymentData?.offerData["TRANCHE_2"]
                                      )
                                    )}
                                  </td>
                                </tr>
                              </>
                            )}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}

                {active == false && (
                  <>
                    {contextData?.dashPaymentData?.offerData?.[
                      "IS_TRANCHE_OPTED"
                    ] ? (
                      <>
                        {contextData?.dashPaymentData?.offerData?.[
                          "IS_TRANCHE_OPTED"
                        ] === "N" ? (
                          <>
                            <tr className="c_date_amount_div">
                              <td className="c_date">
                                <>
                                  {/* {updatedOffer?.["TRANCHE1_PAYMENT_STATUS"]
                                    ? updatedOffer?.[
                                        "TRANCHE1_PAYMENT_STATUS"
                                      ] === "Success" ||
                                      updatedOffer?.[
                                        "TRANCHE1_PAYMENT_STATUS"
                                      ] === "Failed"
                                      ? currentFormattedDate(
                                          updatedOffer?.[
                                            "TRANCHE1_PAYMENT_DATE"
                                          ]
                                        )
                                      : currentFormattedDate(
                                          dashPaymentData?.offerData[
                                            "OFFER_APPROVED_DATE"
                                          ]
                                        )
                                    : currentFormattedDate(
                                        dashPaymentData?.offerData[
                                          "OFFER_APPROVED_DATE"
                                        ]
                                      )} */}
                                  {postFormatCurrentDate(
                                    contextData?.dashPaymentData?.offerData?.[
                                      "OFFER_APPROVED_DATE"
                                    ]
                                  )}
                                </>
                              </td>
                              <td className="c_amount">
                                ₹{" "}
                                {postCalculateFullTranchePayment(
                                  calculateTranch1(
                                    contextData?.dashPaymentData?.offerData?.[
                                      "OFFER_APPROVED_AMOUNT"
                                    ],
                                    contextData?.dashPaymentData?.offerData?.[
                                      "TRANCHE_1"
                                    ]
                                  ),
                                  calculateTranch2(
                                    contextData?.dashPaymentData?.offerData?.[
                                      "OFFER_APPROVED_AMOUNT"
                                    ],
                                    contextData?.dashPaymentData?.offerData?.[
                                      "TRANCHE_2"
                                    ]
                                  )
                                )}
                                {contextData?.dashPaymentData?.offerData?.[
                                  "TRANCHE1_PAYMENT_STATUS"
                                ] === "Success" ? (
                                  <img
                                    className="c_right_tick_icon"
                                    src="assets/images/right-tick.svg"
                                    alt="Right Tick Icon"
                                  />
                                ) : contextData?.dashPaymentData?.offerData?.[
                                    "TRANCHE1_PAYMENT_STATUS"
                                  ] === "Failed" ? (
                                  <img
                                    className="c_right_tick_icon"
                                    src="assets/images/Cautions.svg"
                                    alt="Cautions Icon"
                                  />
                                ) : (
                                  <img
                                    className="c_right_tick_icon"
                                    src="assets/images/Cautions.svg"
                                    alt="Cautions Icon"
                                  />
                                )}
                              </td>
                            </tr>
                          </>
                        ) : contextData?.dashPaymentData?.offerData?.[
                            "IS_TRANCHE_OPTED"
                          ] === "Y" ? (
                          <>
                            <tr className="c_date_amount_div">
                              <td className="c_date">
                                {/* {updatedOffer?.["TRANCHE1_PAYMENT_STATUS"]
                                  ? updatedOffer?.[
                                      "TRANCHE1_PAYMENT_STATUS"
                                    ] === "Success" ||
                                    updatedOffer?.[
                                      "TRANCHE1_PAYMENT_STATUS"
                                    ] === "Failed"
                                    ? currentFormattedDate(
                                        updatedOffer?.["TRANCHE1_PAYMENT_DATE"]
                                      )
                                    : currentFormattedDate(
                                        dashPaymentData?.offerData[
                                          "OFFER_APPROVED_DATE"
                                        ]
                                      )
                                  : currentFormattedDate(
                                      dashPaymentData?.offerData[
                                        "OFFER_APPROVED_DATE"
                                      ]
                                    )} */}
                                {postFormatCurrentDate(
                                  contextData?.dashPaymentData?.offerData?.[
                                    "OFFER_APPROVED_DATE"
                                  ]
                                )}
                              </td>
                              <td className="c_amount">
                                <span>
                                  ₹{" "}
                                  {calculateTranch1(
                                    contextData?.dashPaymentData?.offerData?.[
                                      "OFFER_APPROVED_AMOUNT"
                                    ],
                                    contextData?.dashPaymentData?.offerData?.[
                                      "TRANCHE_1"
                                    ]
                                  )}
                                </span>
                                <span>
                                  {contextData?.dashPaymentData?.offerData?.[
                                    "TRANCHE1_PAYMENT_STATUS"
                                  ] ? (
                                    contextData?.dashPaymentData?.offerData?.[
                                      "TRANCHE1_PAYMENT_STATUS"
                                    ] === "Success" ? (
                                      <img
                                        className="c_right_tick_icon"
                                        src="assets/images/right-tick.svg"
                                        alt="Right Tick Icon"
                                      />
                                    ) : contextData?.dashPaymentData
                                        ?.offerData?.[
                                        "TRANCHE1_PAYMENT_STATUS"
                                      ] === "Failed" ? (
                                      <img
                                        className="c_right_tick_icon"
                                        src="assets/images/Cautions.svg"
                                        alt="Cautions Icon"
                                      />
                                    ) : (
                                      <img
                                        className="c_right_tick_icon"
                                        src="assets/images/Cautions.svg"
                                        alt="Cautions Icon"
                                      />
                                    )
                                  ) : (
                                    <img
                                      className="c_right_tick_icon"
                                      src="assets/images/Cautions.svg"
                                      alt="Cautions Icon"
                                    />
                                  )}
                                </span>
                              </td>
                            </tr>
                            <tr className="c_date_amount_div2">
                              <td className="c_date2">
                                {/* {updatedOffer?.["TRANCHE2_PAYMENT_STATUS"]
                              ? updatedOffer?.["TRANCHE2_PAYMENT_STATUS"] ===
                                  "Success" ||
                                updatedOffer?.["TRANCHE2_PAYMENT_STATUS"] ===
                                  "Failed"
                                ? currentFormattedDate(
                                    updatedOffer?.["TRANCHE2_PAYMENT_DATE"]
                                  )
                                : currentFormattedDate(
                                    updatedOffer?.["OFFER_END_DATE"]
                                  )
                              : currentFormattedDate(
                                  updatedOffer?.["OFFER_END_DATE"]
                                )} */}
                                {postCalculateNextMonthDate(
                                  contextData?.dashPaymentData?.offerData?.[
                                    "OFFER_APPROVED_DATE"
                                  ]
                                )}
                              </td>
                              <td className="c_amount2">
                                <span>
                                  ₹{" "}
                                  {calculateTranch2(
                                    contextData?.dashPaymentData?.offerData?.[
                                      "OFFER_APPROVED_AMOUNT"
                                    ],
                                    contextData?.dashPaymentData?.offerData?.[
                                      "TRANCHE_2"
                                    ]
                                  )}
                                </span>
                                <span>
                                  {contextData?.dashPaymentData?.offerData?.[
                                    "TRANCHE2_PAYMENT_STATUS"
                                  ] ? (
                                    contextData?.dashPaymentData?.offerData?.[
                                      "TRANCHE2_PAYMENT_STATUS"
                                    ] === "Success" ? (
                                      <img
                                        className="c_right_tick_icon"
                                        src="assets/images/right-tick.svg"
                                        alt="Right Tick Icon"
                                      />
                                    ) : contextData?.dashPaymentData
                                        ?.offerData?.[
                                        "TRANCHE2_PAYMENT_STATUS"
                                      ] === "Failed" ? (
                                      <img
                                        className="c_right_tick_icon"
                                        src="assets/images/Cautions.svg"
                                        alt="Cautions Icon"
                                      />
                                    ) : (
                                      <img
                                        className="c_right_tick_icon"
                                        src="assets/images/Cautions.svg"
                                        alt="Cautions Icon"
                                      />
                                    )
                                  ) : (
                                    <img
                                      className="c_right_tick_icon"
                                      src="assets/images/Cautions.svg"
                                      alt="Cautions Icon"
                                    />
                                  )}
                                </span>
                              </td>
                            </tr>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <tr className="c_date_amount_div">
                          <td className="c_date">
                            <>
                              {/* {updatedOffer?.["TRANCHE1_PAYMENT_STATUS"]
                                ? updatedOffer?.["TRANCHE1_PAYMENT_STATUS"] ===
                                    "Success" ||
                                  updatedOffer?.["TRANCHE1_PAYMENT_STATUS"] ===
                                    "Failed"
                                  ? currentFormattedDate(
                                      updatedOffer?.["TRANCHE1_PAYMENT_DATE"]
                                    )
                                  : currentFormattedDate(
                                      dashPaymentData?.offerData[
                                        "OFFER_APPROVED_DATE"
                                      ]
                                    )
                                : currentFormattedDate(
                                    dashPaymentData?.offerData[
                                      "OFFER_APPROVED_DATE"
                                    ]
                                  )} */}
                              {postFormatCurrentDate(
                                contextData?.dashPaymentData?.offerData?.[
                                  "OFFER_APPROVED_DATE"
                                ]
                              )}
                            </>
                          </td>
                          <td className="c_amount">
                            ₹{" "}
                            {postCalculateFullTranchePayment(
                              calculateTranch1(
                                contextData?.dashPaymentData?.offerData?.[
                                  "OFFER_APPROVED_AMOUNT"
                                ],
                                contextData?.dashPaymentData?.offerData?.[
                                  "TRANCHE_1"
                                ]
                              ),
                              calculateTranch2(
                                contextData?.dashPaymentData?.offerData?.[
                                  "OFFER_APPROVED_AMOUNT"
                                ],
                                contextData?.dashPaymentData?.offerData?.[
                                  "TRANCHE_2"
                                ]
                              )
                            )}
                            {contextData?.dashPaymentData?.offerData?.[
                              "TRANCHE1_PAYMENT_STATUS"
                            ] === "Success" ? (
                              <img
                                className="c_right_tick_icon"
                                src="assets/images/right-tick.svg"
                                alt="Right Tick Icon"
                              />
                            ) : contextData?.dashPaymentData?.offerData?.[
                                "TRANCHE1_PAYMENT_STATUS"
                              ] === "Failed" ? (
                              <img
                                className="c_right_tick_icon"
                                src="assets/images/Cautions.svg"
                                alt="Cautions Icon"
                              />
                            ) : (
                              <img
                                className="c_right_tick_icon"
                                src="assets/images/Cautions.svg"
                                alt="Cautions Icon"
                              />
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </table>
            </div>
            <div
              className={
                active
                  ? "c_pay_modal_btn"
                  : "c_pay_modal_btn c_pay_modal_btn_active"
              }
            >
              <button className="c-continue_btn" onClick={() => continueFunc()}>
                <span className="c_proceed_payment">
                  {active ? "Continue" : "Pay now"}
                </span>
                <span className="c_icon_img">
                  <img
                    className="c_right_arrow_icon"
                    src="./assets/images/white-right-arrow.svg"
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPlanOfModal;
