import React from "react";
import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import "./AlertPopUp.scss"
import Modal from "../../global/Modal";
import PropTypes from 'prop-types';

const AlertPopUp = ({ onClick, setAlertModalStat, alertPopUpContent }) => {
    const [themeState] = useContext(ThemeContext);
    return (
        <>
            <Modal>
                <div className="alert-container" style={{ background: `${themeState === "THEME_DARK" ? "#35352C" : "#E0DE00"}` }}>
                    <div className="display-alert-popUp">
                        {/* <div className="failure-img">
                            <img src="assets/images/failure-cross.svg" alt="" />
                        </div> */}
                        <div className="alert-popUp-content">
                            <div className="alert-popUp-header" style={{ color: `${themeState === "THEME_DARK" ? "#E0DE00" : "#1C6AB4"}` }}>
                                <p>{alertPopUpContent.title}</p>
                                <div className="alert-close-icon" onClick={onClick}>
                                    <img src={themeState === "THEME_DARK" ? "assets/images/yellow-cross.svg" : "assets/images/blue-cross.svg"} alt="" />
                                </div>
                            </div>
                            <div className="alert-popUp-message" style={{ color: `${themeState === "THEME_DARK" ? "#E0DE00" : "#1C6AB4"}` }}>
                                <p>{alertPopUpContent.message} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

AlertPopUp.propTypes = { 
    onClick:PropTypes.func , 
    setAlertModalStat:PropTypes.func,
    alertPopUpContent:PropTypes.object
}

export default AlertPopUp