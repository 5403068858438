import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const uploadDocumentByWebtop = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "webtopId": "291PZ0004988",
            "docUploadName": "Electricity Bill.pdf",
            "contractName": "",
            "base64": "iVBORw0KGgoAAAANSUhEUgAAAFAAAAAUCAYAAAAa2LrXAAACz0lEQVRYR+2XPUiyURTH/2JWfmRLQaJjGGStgoSZFKVrEYSNNYS4OEiBIkjQUItQmw2NoTa09EGkSNESUQpKS0sFKYhDJEYivtwDRbwUqPd5Hho80yOcc+7//O713Htk9Xq9jra1TEDWBtgyOwpsA+TjJx3A6+tr5HI5XFxcoFqt4uDgAF1dXVAoFCgUCr+WMTAwgPf3d2g0GthsNrDfJpMJY2NjMBqNnOXzh4tyAovFInZ3dxGNRnF7e0sq5XI5LBYLrFYrQZudnSUwarUaIyMjv1ZyeXmJ7u5uvL29IZVKIZ/PI5vN4urqCrVajeImJiYwPz8Pt9vNT6TJDIICDAaDWF9fJwlOp5OKcjgc0Ol0Tcpq3P3l5YU2KhKJENj+/n5sbGxgeXm58SQcnoIAZIL9fj8mJydJvNls5pDEFxqPx7G2toaHhwccHR3RRopp3ACHh4fx9PSE19dXyGQyMbU2lZsBHBwchMfjwfb2dlOxzThzAdzb28PKygr1sr9qbFPv7+8xNDQkikQugOxCYAAXFxdFESdE0oWFBYyOjlKLEcO4AJ6entIl8VeHmUqlApVKhY+PD7r5xTAugEyQ1+tFOBzGyckJZmZmxNDYUs7NzU2srq7i7OwMU1NTLeVoJIgbIFsknU7Tzct2OhAIwOfzQavVNrK+oD6Pj4/Y2trCzs4O9by7uzt6Q4ppggD8FHhzc0O9hv21mc3NzX29BXt7ewWvgwE7Pj7G+fk5YrEY5V9aWkIoFIJerxd8vZ8SCgrw+wLsUbu/v08w2Rj3aePj49SP7HY7nVg2orFvNt79bx0dHTTJPD8/Q6lU0vjH/DKZzJcre3tOT0/D5XLBYDBIAu37IqIB/KmSUqmEw8NDApdMJtHZ2YlyuYxEIvFjk2ew2JjW09NDTyV2ohl8tgl9fX2Sw5L0BP6J6iQQIekJlKAeyZdoA+RE3gbICfAfGIw81N5UGTEAAAAASUVORK5CYII="
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/dms/upload-document-by-webtop/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/dms/upload-document-by-webtop/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default uploadDocumentByWebtop;