import React, { useContext, useEffect, useState } from "react";
import TabContent from "../../components/TabContent/TabContent";
import { TabNavItem } from "../../components/TabNavItem/TabNavItem";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../../../Shared/context/ThemeContext/store/PageNameContext";
import CustomDropDown from "../../global/CustomDropDown/CustomDropDown";
import RadiantBorder from "../../global/RadiantBorder";
import AssestDetails from "../AssestDetails";
import Documents from "../Documents";
import { LoanDetails } from "../LoanDetails/LoanDetails";
import { SplitChargesData } from "../SplitChargesData/SplitChargesData";
import "./AccountTabConatainer.scss";
// import dataObj from "../../screens/Retail-Account-Details/Data";
import dataObj from "../../../../Shared/screens/Retail-Account-Details/Data";
import commonFunctionObject from "../../common-function/commonfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREACCOUNTDATA, STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import AccountDetailsServicObject from "../../services/AccountDetailsService/AccountDetailsService";
import FailurePopUp from "../../global/FailurePopUp";
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import BankDetails from "../BankDetails";
import PropTypes from 'prop-types';

const AccountTabConatainer = ({ activeContract, quickLinksFlag, storeSelectAccNumberData, callSplitChargesApi, activeTab, setActiveTab, callRetailsbankingDetails, setSelectedOptionMobilView, selectedOptionMobileView, portalType }) => {

  const [data, dispatchData] = useContext(DataMapping)
  let splitChargesFlag = commonFunctionObject.checkSplitCharges(data?.parsedUserData[activeContract])


  let bankingDetail = commonFunctionObject.checkActiveStatus(data?.getLoanPositionDTLS?.[activeContract])
  const optionArray = [
    {
      value: "Loan Details",
      id: "Loan Details",
    },
    // ...(true) && {
    //   value: "Split Charges",
    //   id: "split-charges",
    // },  
    {
      value: "Documents",
      id: "Documents",
    },
  ];
  const { analytics } = useContext(AnalyticsContextProvider);

  const accountDetailsTabAnalytics = (eventName) => {
    var accountNumber = data?.currentActiveData.accountNumber
    var currentUserData = data?.parsedUserData[accountNumber]
    let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
    var gcid = currentUserData.GCID;
    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: gcid,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "",
        componentName: "Account Details",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "",
        eventName: eventName,
        tabTitle: eventName
      }
    }
    analytics.trackData("tabInteraction", analytics_data);
  }

  if (splitChargesFlag) {
    optionArray.push({
      value: "Split of Charges",
      id: "split-charges",
    }
    )
  }

  if (bankingDetail === "active") {
    optionArray.push({
      value: "Banking Details",
      id: "bank-details",
    }
    )
  }
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });
  // hooks
  // const [activeTab, setActiveTab] = useState("loan-detail");
  const [optionState, setOptionState] = useState(false);
  const [selectedOption, setSelectedOption] = useState(optionArray[0].value);
  const { pageName, setPageName } = useContext(pageNameContext);
  const uselocation = useLocation();
  const navigate = useNavigate();
  const [heiglighted, setHeiglighted] = useState("")
  ///variables
  const AssetDetails = ["USED CAR REFINANCE", "TWO WHEELER", "USED CAR", "AUTO LOAN", "CAR LOAN", "USED CAR CNC"]
  const useLocationState = uselocation?.state
  const tabName = uselocation?.state?.tabName
  const loanAccNumber = uselocation?.state?.loanAccNumber
  const redirectFor = uselocation?.state?.redirectFor

  useEffect(() => {
    setPageName("Account Details");
    if (quickLinksFlag === "Documents") {
      setActiveTab("Documents")
    } else {
      setActiveTab("Loan Details")
    }
    checkRedirection();
    navigate('/account-details');

  }, []);
  // useEffect(() => {
  //   // setActiveTab("split-charges")
  // },[data?.splitChargesData])

  const checkRedirection = () => {

    if (useLocationState) {
      if (tabName) {
        setActiveTab(tabName)
        if (tabName === "split-charges") {
          setSelectedOptionMobilView("Split of Charges")
        } else if (tabName === "loan-detail") {
          setSelectedOptionMobilView("Loan Details")
        } else {
          //TODO
        }
      }
    }
    if (loanAccNumber) {
      storeSelectAccNumberData(loanAccNumber, tabName)
    }
    if (redirectFor) {
      setHeiglighted(redirectFor)
    }
  }

  const handleDropDownChange = (value) => {

    if (value === "Split of Charges") {
      callSplitChargesApi(activeContract)
    }
    if (value === "Banking Details") {

      callRetailsbankingDetails(activeContract)
    }

  }


  return (
    <div className="account-tab-conatainer">
      {failurePopUpModal ? (
        <FailurePopUp
          onClick={() => setFailurePopUpModal(false)}
          visible={failurePopUpModal}
          setFailureModalStat={setFailurePopUpModal}
          failurePopUpContent={failurePopUpContent}
        />
      ) : null}
      <div className="loan-features">
        <ul className="loan-features-ul">
          <li onClick={e => accountDetailsTabAnalytics("Loan Details")}>
            <TabNavItem
              title="Loan Details"
              id="Loan Details"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </li>
          {
            splitChargesFlag ? <li onClick={() => callSplitChargesApi(activeContract)}>
              <TabNavItem
                title="Split of Charges"
                id="split-charges"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </li> : null
          }

          {
            // data?.assetDetails && data?.assetDetails?.[activeContract] &&
            data?.assetDetails ? data?.assetDetails[activeContract] ? <li onClick={e => accountDetailsTabAnalytics("Asset Details")}>
              <TabNavItem
                title="Asset Details"
                id="asset-detail"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </li> : <></> : null}
          <li onClick={e => accountDetailsTabAnalytics("Documents")}>
            <TabNavItem
              title="Documents"
              id="Documents"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </li>
          {
            bankingDetail === "active" ? <li onClick={e => callRetailsbankingDetails(activeContract)}>
              <TabNavItem
                title="Banking Details"
                id="bank-details"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </li> : null
          }

        </ul>
      </div>
      <div className="loan-feature-mob">
        <CustomDropDown
          optionArray={optionArray}
          optionState={optionState}
          setOptionState={setOptionState}
          selectedOption={selectedOptionMobileView}
          setSelectedOption={setSelectedOptionMobilView}
          setActiveTab={setActiveTab}
          handleDropDownChange={handleDropDownChange}
        // labelFlag={true}
        // labelText="Account Number"
        />
      </div>
      <div className="tab-content-container">
        <TabContent id="Loan Details" activeTab={activeTab}>
          <LoanDetails activeContract={activeContract} heiglighted={heiglighted} />
        </TabContent>
        {
          data?.splitChargesData?.[activeContract] ? <TabContent id="split-charges" activeTab={activeTab}>
            <SplitChargesData splitChargesData={data?.splitChargesData} activeContract={activeContract} />
          </TabContent> : null
        }

        {
          data?.assetDetails?.[activeContract] ? <TabContent id="asset-detail" activeTab={activeTab}>
            <AssestDetails activeContract={activeContract} />
          </TabContent> : null
        }


        <TabContent id="Documents" activeTab={activeTab}>
          <Documents activeContract={activeContract} portalType={portalType}/>
        </TabContent>
        {
          data?.bankingDetail?.[activeContract] ? <TabContent id="bank-details" activeTab={activeTab}>
            <BankDetails activeContract={activeContract} />
          </TabContent> : null
        }

      </div>
    </div>
  );
};

AccountTabConatainer.propTypes = {
  activeContract: PropTypes.string,
  quickLinksFlag: PropTypes.string,
  storeSelectAccNumberData: PropTypes.func,
  callSplitChargesApi: PropTypes.func,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  callRetailsbankingDetails: PropTypes.func,
  setSelectedOptionMobilView: PropTypes.func,
  selectedOptionMobileView: PropTypes.string
}


export default AccountTabConatainer;
