import React, { useState, useEffect } from "react";
import './CreditScoreDiagram.scss';
import PropTypes from "prop-types";
import Button from "../../FormFields/Button/Button";
import KnowMore from "../KnowMore/KnowMore";

const CreditScoreDiagram = ({
    creditScoreText = "",
    getCreditScore = "",
    creditScoreStatus = "",
    isBlur,
    handleScore,
    btnText
}) => {

    const [degree, setDegree] = useState(0);

    useEffect(() => {
        let percentage = (getCreditScore * 100) / 900;
        let decimalDegree = percentage / 100;
        setDegree(180 * decimalDegree);
    }, [getCreditScore]);


    return (
        <>
            <div className="c_credit_credit_score_know_more_main_div">
                <p className="c_credit_score_text">{creditScoreText}</p>
                <div className="c_credit_credit_score_know_more_flex_div">
                    <KnowMore />
                    <div className={`parent-ab ${isBlur ? 'is_blur_parent' : ""}`}>
                        <div className={`c_credit_main_div ${isBlur ? 'isBlur' : ""}`}>
                            {/* <p className="c_credit_score_text">{creditScoreText}</p> */}
                            <div>
                                {/* { */}
                                {/* // !isBlur && */}
                                <>

                                    <div className='c_speedometer_main_div'>

                                        <div id="c_speedometer">
                                            <div className="c_wrapper">
                                                <div className="c_indicator_wrapper">
                                                    <div className={isBlur ? "c_indicator_wrapper_inner_question c_indicator_wrapper_inner" : "c_indicator_wrapper_inner"}>

                                                        <div className={isBlur ? "c_needle" : "c_needle c_needle_set"} style={{ transform: `rotate(${degree}deg` }}>
                                                            <div className='c_polygon'></div>
                                                            <img src='assets/images/Polygon.svg' className='c_polygon_img' />
                                                        </div>
                                                        <div className={isBlur ? "c_score" : "c_score c_score_center"}>
                                                            <p className="c_credit_score_number">{getCreditScore}</p>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="c_bar c_bar_1"></div>
                                                <div className="c_bar c_bar_2"></div>
                                                <div className="c_bar c_bar_3"></div>
                                                <div className="c_bar c_bar_4"></div>
                                            </div>
                                            <div className="c_credit_score_status_text">
                                                <p className='c_excellent_text'>{creditScoreStatus}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="c_btn_center">
                                        <Button type="button" className="c_btn" onClick={() => handleScore()}>
                                            <p className="c_btn_generate_text">{btnText}</p>
                                            <img className="c_vector_icon" src="assets/images/vector.svg" alt="symbol" />
                                        </Button>
                                    </div>

                                </>
                                {/* // } */}

                            </div>
                        </div>
                        {/* {isBlur && <div className="c_btn_blur_parent"><Button onClick={() => handleScore()} className="c_blur_btn"><p className="c_blur_btn_text">Generate Credit Score</p> <img src="assets/images/vector.svg" alt="symbol"></img></Button ></div>} */}
                    </div>
                </div>
            </div >
        </>
    )
}

CreditScoreDiagram.propTypes = {
    creditScoreText: PropTypes.string,
    getCreditScore: PropTypes.string,
    creditScoreStatus: PropTypes.string,
    isBlur: PropTypes.bool,
    handleScore: PropTypes.func,
    btnText: PropTypes.string,
}

export default CreditScoreDiagram;