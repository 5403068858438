import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const pushOnlineDisbursalDetails = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "bukrs": "7000",
            "pName": "ZTCL_PL",
            "manuCoCode": "PLTCL00001",
            "corporateID": "PLTCL00001",
            "zdelVen": "0082000002",
            "zrefNo": "20220715000000",
            "zrefDt": "2022-07-14",
            "budat": "2022-07-14",
            "zreqAmt": "1000",
            "location": "Mumbai",
            "region": "WEST",
            "ztslvenCode": "",
            "ztslSalesORD": ""
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/ccod/push-online-disbursal-details/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/ccod/push-online-disbursal-details/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    })
}

export default pushOnlineDisbursalDetails;