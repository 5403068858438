import React, { useEffect } from "react";
import "./LASLoanDetails.scss";
import RadiantBorder from "../../global/RadiantBorder";
import Button from "../../global/Button/Button";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../../../Shared/context/ThemeContext/store/PageNameContext";
import { useContext } from "react";
import { useState } from "react";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import LasAccountDetailsServiceObject from "../../services/LasAccountDetailsService/LasAccountDetailsService";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
import PropTypes from 'prop-types';
const LASLoanDetails = ({ lasAmountData, outstandingData, activeContract }) => {
  const { pageName, setPageName } = useContext(pageNameContext);
  const [data, dispatchData] = useContext(DataMapping)
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    "apiName": "",
    "message": "",
    "errorCode": ""
  })
  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");


  useEffect(() => {
    setPageName("Loan Against Security Detail");

  }, [lasAmountData, outstandingData]);


  const downloadEligibleDrawingPower = () => {
    var downloadBreRequest = {
      header: {
        authToken: data.authToken,
      },
      body: {
        "breResponseList": data?.pledgingBreResult[activeContract]?.breResponseList
      },
    }

    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA
    })
    LasAccountDetailsServiceObject.downloadBreService(downloadBreRequest).then((downloadBreResponse) => {
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    }).catch((error) => {
      setFailurePopUpContent({
        apiName: "",
        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
        errorCode: error
      })
      setFailurePopUpModal(true)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    })



  }


  return (
    <div className="loan-wrapper">
      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
      {successPopUpModal ? <SuccessPopUp
        onClick={() => setSuccessPopUpModal(false)}
        visible={successPopUpModal}
        setSuccessModalStat={setSuccessPopUpModal}
        successPopUpContent={successPopUpContent}
      /> : null}
      <div className="loan-details-container">
        <div className="loan-div">
          <p className="ccod-divs-heading">Amount Information</p>
          <RadiantBorder
            className={"ccod-prod-radiant-border loan-radiant-border"}
          >
            <div className="ccod-prod-main-container">
              <div className="ccod-prod-divs">
                <div>
                  <ul className="loan-detail-info-ul ccod-li">
                    {
                      lasAmountData && lasAmountData.map((amountData, index) => {
                        return (
                          <li key={index}>
                            <div className="label">{amountData.label}</div>
                            <div className="label-amount">{amountData.value}
                              {amountData.label === "Eligible Drawing Power" && data.checkEquityFlag[activeContract] === true && <p className="downloadEligibleDrawingPower"><a onClick={() => { downloadEligibleDrawingPower() }}><strong>Download DP Details</strong></a></p>}
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </RadiantBorder>
        </div>
        <div className="loan-div">
          <p className="ccod-divs-heading">Outstanding</p>
          <RadiantBorder
            className={"ccod-prod-radiant-border loan-radiant-border"}
          >
            <div className="ccod-prod-main-container">
              <div className="ccod-prod-divs">
                <div>
                  <ul className="loan-detail-info-ul ccod-li loan-li">
                    {outstandingData && outstandingData.map((data, index) => {
                      return (
                        <li key={index}>
                          <div className="label">{data.label} </div>
                          <div className="label-amount">{data.value}</div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </RadiantBorder>
        </div>
      </div>
    </div>
  );
};

LASLoanDetails.propTypes = { 
  lasAmountData : PropTypes.array, 
  outstandingData : PropTypes.array,
  activeContract : PropTypes.string
};

export default LASLoanDetails;
