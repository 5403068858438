const Validators = {
    required: (value) => {
      if (value !== null && (value?.length > 0 || value)) {
        return null;
      } else {
        return { required: "Value is required" };
      }
    },
    pattern: (regEx) => {
      return (value) => {
        return regEx.test(value) ? null : { pattern: "Not valid pattern" };
      };
    },
    name: (value) => {
      let name = /^([a-zA-Z]'?)+ ([a-zA-Z]'?)+ ?([a-zA-Z]'?)+$/;
      if (name.test(value)) {
        const [first, middle, last] = value.toLowerCase().split(" ");
  
        if (first !== middle && first !== last && middle !== last) {
          if (first?.length > 20 || last?.length > 20 || middle?.length > 20) {
            return { name: "Name is not valid" };
          } else {
            return null;
          }
        } else {
          return { name: "Name is not valid" };
        }
      } else {
        return { name: "Name is not valid" };
      }
    },
    mail: (value) => {
      let newEmailRegex = /\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}/g;
      return newEmailRegex.test(value)
        ? null
        : { message : "Entered mail is not valid" };
    },
    min: (minValue) => {
      return (value) => {
        let parsedValue = value ? parseInt(value?.replaceAll(",", "")) : "";
        return parsedValue < minValue
          ? {
            min: `Minimum value is ${minValue}`,
            minValue: minValue,
          }
          : null;
      };
    },
    max: (maxValue) => {
      return (value) => {
        let parsedValue = value ? parseInt(value?.replaceAll(",", "")) : "";
        return parsedValue > maxValue
          ? {
            max: `Maxmium value is ${maxValue}`,
            maxValue: maxValue,
          }
          : null;
      };
    },
    equiLength: (mobileNum,context)=>{
      return(value) => {
          if(value.length < mobileNum  ||  value.length > mobileNum){
              return {
                  message: "Not a Valid "+context
              }
          }else{
             return null 
          }
      }
    }
  };
  
  export default Validators;
  