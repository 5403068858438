import React from "react";
import "./AccountNumberDropDown.scss"
import { useContext, useRef, useEffect } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import PropTypes from 'prop-types';

const AccountNumberDropDown = ({ className,
    optionArray,
    optionState,
    setOptionState,
    selectedOption,
    setSelectedOption,
    labelFlag,
    labelText,
    labelClass,
    setActiveTab,
    storeSelectAccNumberData,
    bankLas,
    closeAcc,
    setEmployementType,
    ccodAccount,
    asterisk,
    callGetDisbursalDetails,
    error,
    errorSpace,
    handleDropDownChange, }) => {

    const [themeState] = useContext(ThemeContext);
    const dropDownRef = useRef()
    const [contextData, dispathData] = useContext(DataMapping)
    const handdleOptionChange = (obj) => {
        setSelectedOption(obj.value);
        if (setActiveTab) {
            setActiveTab(obj.id);
        }
        if (storeSelectAccNumberData) {
            storeSelectAccNumberData(obj.value)
        }
        if (setEmployementType) {
            setEmployementType(obj.value.split(" ")[0])
        }
        if (callGetDisbursalDetails) {
            callGetDisbursalDetails(obj.id)
        }
        if (handleDropDownChange) {
            handleDropDownChange(obj.value.split(" ")[0])
        }
        let newActiveObject = {
            accountNumber: obj.value.split(" ")[0],
            system: obj.system,
        }
        dispathData({
            type: STOREDATA,
            name: "currentActiveData",
            payload: newActiveObject,
        })

    };

    useEffect(() => {
        const onBodyClick = (e) => {
            if (!dropDownRef.current?.contains(e.target)) {
                setOptionState(false)
            }
        }
        document.body.addEventListener('click', onBodyClick);
        return () => {
            document.body.removeEventListener('click', onBodyClick);
        }
    }, [])

    return (
        <>
            <div
                className={`account-drop-down-account-number ${className ? className : ""
                    }`}
            >
                {labelFlag ? (
                    <label htmlFor="account-drop-down-account-details" className={labelClass ? labelClass : ""}>{labelText}{asterisk ? <span className="red-star">*</span> : ""}</label>
                ) : null}

                <div
                    className="select-wrapper "
                    onClick={(event) => {
                        event.stopPropagation()
                        setOptionState(!optionState);
                    }}
                >
                    <p>{selectedOption}</p>
                    <img
                        style={{
                            width: "1.3rem",
                            filter: `${themeState === THEME_DARK ? "invert(1)" : ""}`,
                            transform: `${optionState ? "rotate(180deg)" : ""}`,
                        }}

                        src="assets/images/arrow-down.svg"
                        alt=''
                    />
                    {optionState ? (
                        <div ref={dropDownRef} className={`account-drop-down-options-container  ${ccodAccount ? "ccod-account-number" : ""}`}>
                            <div className="dropdown-inner-container">
                            {Object.keys(optionArray).map((keyName,index) => {
                                return (<div key={index}>
                                    {
                                        bankLas && closeAcc ?
                                            keyName !== "LAS" && keyName !== "Closed Loans" ?
                                                optionArray[keyName].length > 0 ?
                                                    <>
                                                        <p className="activityName">{keyName}</p>
                                                        {optionArray[keyName].map((obj, index) => {
                                                            return (
                                                                <p className="accountNumber" key={index} onClick={() => {
                                                                    handdleOptionChange(obj)
                                                                }}>
                                                                    {obj.value}
                                                                </p>
                                                            )
                                                        })}
                                                    </>
                                                    : null
                                                : null
                                            : bankLas ?
                                                keyName !== "LAS" ?
                                                    optionArray[keyName].length > 0 ?
                                                        <>
                                                            <p className="activityName">{keyName}</p>
                                                            {optionArray[keyName].map((obj, index) => {
                                                                return (
                                                                    <p className="accountNumber" key={index} onClick={() => {
                                                                        handdleOptionChange(obj)
                                                                    }}>
                                                                        {obj.value}
                                                                    </p>
                                                                )
                                                            })}
                                                        </>
                                                        : null
                                                    : null
                                                :
                                                keyName && optionArray[keyName].length > 0 ?
                                                    <>
                                                        <p className="activityName">{keyName}</p>
                                                        {optionArray[keyName].map((obj, index) => {
                                                            return (
                                                                <p className="accountNumber" key={index} onClick={() => {
                                                                    handdleOptionChange(obj)
                                                                }}>
                                                                    {obj.value}
                                                                </p>
                                                            )
                                                        })}
                                                    </>
                                                    : null


                                    }

                                </div>)
                            })}
                            </div>
                            {/* {!bankLas ? optionArray?.map((obj, index) => (
                                <p key={index} onClick={() => {
                                    handdleOptionChange(obj)
                                }}>
                                    {obj.value}
                                </p>
                            )) : optionArray.filter(obj => obj.system !== "las").map((obj, index) => (
                                <p key={index} onClick={() => {
                                    handdleOptionChange(obj)
                                }}>
                                    {obj.value}
                                </p>
                            ))} */}
                        </div>
                    ) : null}

                </div>

            </div>
            {
                errorSpace && (
                    <div className="error-span">
                        <span>{error}</span>
                    </div>
                )
            }
        </>

    );
}

AccountNumberDropDown.propTypes = { 
    className : PropTypes.string , 
    optionArray : PropTypes.object,
    optionState : PropTypes.bool,
    setOptionState : PropTypes.func,
    selectedOption : PropTypes.string,
    setSelectedOption : PropTypes.func,
    labelFlag : PropTypes.bool,
    labelText : PropTypes.string,
    labelClass : PropTypes.string,
    setActiveTab : PropTypes.func,
    storeSelectAccNumberData : PropTypes.func,
    bankLas : PropTypes.bool,
    closeAcc : PropTypes.bool,
    setEmployementType : PropTypes.func,
    ccodAccount : PropTypes.bool,
    asterisk : PropTypes.bool,
    callGetDisbursalDetails : PropTypes.func,
    error : PropTypes.string,
    errorSpace : PropTypes.bool,
    handleDropDownChange : PropTypes.func
}

export default AccountNumberDropDown