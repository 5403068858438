export const handleErrorMessage = (loginTabForm, controlName, componentType, apiValidationError) => {
    let errorMessage = '';
    if (componentType === 'forgotId' || componentType === 'forgotPass') {
        if (apiValidationError) {
            errorMessage = componentType === 'forgotId' ? 'Please Provide Your Registered Mobile Number/E-Mail ID' : 'Please Enter a Valid User ID';
        }
        if (loginTabForm.field.error) {
            errorMessage = componentType === 'forgotId' ? 'Kindly Provide A Valid Mobile Number / Email ID' : 'Please Enter a Valid User ID';
        }
    } else {
        let controlData = loginTabForm.control[controlName]
        if (controlData?.error) {
             errorMessage = "Enter valid details";
            let error = controlData.error;
            if (componentType === 'mobNum') {
                if (('required' in error || 'pattern' in error || 'message' in error ) && (controlName === 'fieldOne')) {
                    errorMessage = 'Please Provide Your Registered Mobile No';
                } else if (controlName === 'fieldTwo') {
                    errorMessage = 'Kindly Provide Captcha';
                }
            } else if (componentType === 'emailId') {
                if (('required' in error ||'pattern' in error || 'message' in error) && (controlName === 'fieldOne')) {
                    errorMessage = 'Please Provide Your Registered E-Mail ID';
                }else if (controlName === 'fieldTwo') {
                    errorMessage = 'Kindly Provide Captcha';
                }
            } else if (componentType === 'userId') {
                if ('required' in error && controlName === 'fieldOne') {
                    errorMessage = 'Please Enter User ID';
                } else if ('required' in error && controlName === 'fieldTwo') {
                    errorMessage = 'Please Enter Password';
                }
            }
        }
    }
    return errorMessage
}