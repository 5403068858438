import commonFunctionObject from "../../../Retail/src/common-function";
import { getProductContent } from "./getProductContent";

export const populateOverviewData = (data, preapprovedOfferFlag) => {
  let { parsedUserData } = data;
  let finalData = [];

  for (let i in parsedUserData) {
    if (parsedUserData[i].system == "ccod") {
      let vdSummaryDetails = data?.vdSummary[i];
      let tenureDays = parseInt(vdSummaryDetails?.MAX_DAYS);
      let tenureYears = tenureDays / 365;
      let tenureMonths = tenureYears * 12;

      if (vdSummaryDetails?.TOT_OD_AMT > 0) {
        obj = {
          system: "ccod",
          displayName: "Dropline Overdraft Loan",
          contractNumber: i,
          icon: "assets/images/light-icon/consumer-durable-light-icon.svg",
          loanDetails: [
            {
              name: "TOTAL APPROVED AMOUNT",
              value: vdSummaryDetails?.TOTAL_LIMIT,
            },
            { name: "AVAILABLE LIMIT", value: vdSummaryDetails?.AVAIL_LIMIT },
            { name: "OUTSTANDING BALANCE", value: vdSummaryDetails?.OUT_BAL },
            {
              name: "OVERDUE AMOUNT",
              value: vdSummaryDetails?.TOT_OD_AMT,
              color_red_class: "color_red",
            },
            {
              name: "Limit Expiry Date",
              value: vdSummaryDetails?.TOTAL_REV_DT,
              type: "date",
            },
            { name: "Tenure (Months)", value: tenureMonths, type: "date" },
            { name: "Foreclosure Amount", value: fcAmt },
            { name: "Foreclosure As on Date", value: fcDate },
          ],
        };
        finalData.push(obj);
      }
    } else {
      let activeStatus = [
        "new account",
        "partially advanced",
        "applied for cancellation",
        "approved",
        "accepted",
        "request for termination",
        "applied for termination",
        "fully advanced",
        "active",
      ];

      let closedStatus = ["closed"];

      if (
        activeStatus.includes(
          data?.getLoanPositionDTLS?.[
            i
          ]?.body?.getLoanPositionDtlsReturn?.loanStatus.toLowerCase()
        )
      ) {
        const offerObj = data?.offers?.body?.offerDetails.find((offer) => {
          return offer["LOAN_ACCOUNT_NO"] === i;
        });
        // console.log(offerObj, "offerObj");
        let getLoanPositionDtlsReturn =
          data?.getLoanPositionDTLS?.[i]?.body?.getLoanPositionDtlsReturn;
        // console.log(getLoanPositionDtlsReturn, "getLoanPositionDtlsReturn");

        let getLoanAccountsReturn =
          data?.getLoanPositionDTLS?.[i]?.body?.getLoanAccountsReturn;
        // console.log(getLoanAccountsReturn, "getLoanAccountsReturn");

        let unpaidInterest =
          typeof getLoanPositionDtlsReturn?.unpaidInterest === "object"
            ? 0
            : getLoanPositionDtlsReturn?.unpaidInterest;
        // console.log(unpaidInterest, "unpaidInterest");

        let unpaidPrincipleBalance =
          typeof getLoanPositionDtlsReturn?.unpaidPrincipleBalance === "object"
            ? 0
            : getLoanPositionDtlsReturn?.unpaidPrincipleBalance;
        // console.log(unpaidPrincipleBalance, "unpaidPrincipleBalance");

        let totalLoanAmount =
          typeof getLoanPositionDtlsReturn?.disbursedAmount === "object"
            ? 0
            : getLoanPositionDtlsReturn?.disbursedAmount;

        let totalPaidAmount =
          typeof getLoanPositionDtlsReturn?.totalAmountPaid === "object"
            ? 0
            : getLoanPositionDtlsReturn?.totalAmountPaid;

        let emiPaidAmount =
          typeof getLoanPositionDtlsReturn?.lastAmountPaid === "object"
            ? 0
            : getLoanPositionDtlsReturn?.lastAmountPaid;

        let emiAmountValue =
          typeof getLoanAccountsReturn?.emiAmount === "object"
            ? 0
            : getLoanAccountsReturn?.emiAmount;

        let lastAmountPaidValue =
          typeof getLoanAccountsReturn?.lastAmountPaid === "object"
            ? 0
            : getLoanAccountsReturn?.lastAmountPaid;

        let totalNoOfEmis =
          typeof getLoanPositionDtlsReturn?.totalNoofEmis === "object"
            ? 0
            : getLoanPositionDtlsReturn?.totalNoofEmis;
        // console.log(totalNoOfEmis, "totalNoOfEmis");

        let totalNoOfEmisPaidVal =
        typeof getLoanPositionDtlsReturn?.totalNoOfEMIsPaid === "object"
          ? 0
          : getLoanPositionDtlsReturn?.totalNoOfEMIsPaid;
      // console.log(totalNoOfEmis, "totalNoOfEmis");

        let numberOfEMIsOverdue =
          typeof getLoanPositionDtlsReturn?.numberOfEMIsOverdue === "object"
            ? 0
            : getLoanPositionDtlsReturn?.numberOfEMIsOverdue;
        // console.log(numberOfEMIsOverdue, "numberOfEMIsOverdue");

        let lastAmountPaidDateValue =
          typeof getLoanPositionDtlsReturn?.lastamountpaidDate === "object"
            ? "-"
            : getLoanPositionDtlsReturn?.lastamountpaidDate;
        let emiOverdueAmount =
          parseInt(unpaidInterest) + parseInt(unpaidPrincipleBalance);
        // console.log(emiOverdueAmount, "emiOverdueAmount");

        let unpaidChargeBalance = 0;
        let overdueChargeAmount = 0;
        if (
          !(getLoanPositionDtlsReturn?.unpaidChargeBalance instanceof Object)
        ) {
          unpaidChargeBalance = getLoanPositionDtlsReturn?.unpaidChargeBalance;
          // console.log(unpaidChargeBalance, "unpaidChargeBalance");
        }
        if (
          !(getLoanPositionDtlsReturn?.overdueChargeAmount instanceof Object)
        ) {
          overdueChargeAmount = getLoanPositionDtlsReturn?.overdueChargeAmount;
          // console.log(overdueChargeAmount, "overdueChargeAmount");
        }

        let foreClosureAmt = "";
        let foreClosureAsOnDate = "";
        let isSettlementFlag = "";

        if (offerObj) {
          foreClosureAmt = offerObj?.FC;
          let data = offerObj?.FC_AS_ON_DATE;
          foreClosureAsOnDate = data;
          isSettlementFlag = offerObj?.["SETTLEMENT_CAMPAIGN"];
        }

        let chargesOverdueAmount =
          parseInt(unpaidChargeBalance) + parseInt(overdueChargeAmount);
        // console.log(chargesOverdueAmount, "chargesOverdueAmount");
        let contentObj = getProductContent(
          parsedUserData[i]?.productInfo?.DISPLAY_NAME
        );

        let obj = {};
        // if (chargesOverdueAmount > 0 || emiOverdueAmount > 0) {
        if (offerObj) {
          //offer contain lan case
          if (preapprovedOfferFlag) {
            obj = {
              system: "retail",
              icon: contentObj?.icon,
              displayName: contentObj?.label,
              contractNumber: i,
              loanStatus: getLoanPositionDtlsReturn.loanStatus,

              loanDetails: [
                {
                  name: "TOTAL LOAN AMOUNT",
                  value: totalLoanAmount,
                },
                {
                  name: "TOTAL EMI PAID AMOUNT",
                  value: totalPaidAmount,
                  text: `Total No Of EMI's - ${totalNoOfEmisPaidVal}`,
                },
                {
                  name: "LAST EMI PAID AMOUNT",
                  value: emiPaidAmount,
                },
                {
                  name: "EMI OVERDUE AMOUNT",
                  value: emiOverdueAmount,
                  text: `No Of EMI's Overdue - ${numberOfEMIsOverdue}`,
                  color_red_class: "color_red",
                },
                {
                  name: "CHARGES OVERDUE AMOUNT",
                  value: chargesOverdueAmount,
                  color_red_class: "color_red",
                },
                {
                  name: "EMI Amount",
                  value: emiAmountValue,
                },
                {
                  name: "Next EMI Amount",
                  // value: getLoanAccountsReturn?.nextEmiAmount,
                  value: emiAmountValue,
                  text: `Next Due on ${commonFunctionObject.checkEmptyData(
                    getLoanPositionDtlsReturn?.nextEmiDate
                  )}`,
                },
                {
                  name:
                    isSettlementFlag === "Y"
                      ? `${"ForeClosure Amount"}`
                      : `${"Last paid EMI"}`,
                  value:
                    isSettlementFlag === "Y"
                      ? `${foreClosureAmt}`
                      : `${lastAmountPaidValue}`,
                  text:
                    isSettlementFlag === "Y"
                      ? `As On Date - ${foreClosureAsOnDate}`
                      : `on ${lastAmountPaidDateValue}`,
                },
              ],
            };
          } else {
            obj = {
              system: "retail",
              icon: contentObj?.icon,
              displayName: contentObj?.label,
              contractNumber: i,
              loanStatus: getLoanPositionDtlsReturn.loanStatus,

              loanDetails: [
                {
                  name: "TOTAL LOAN AMOUNT",
                  value: totalLoanAmount,
                },
                // {
                //   name: "TOTAL PAID AMOUNT",
                //   // value: getLoanPositionDtlsReturn?.totalAmountPaid,
                //   value: 235000,
                // },
                {
                  name: "TOTAL DISBURSED AMOUNT",
                  value: totalLoanAmount,
                },
                {
                  name: "EMI OVERDUE AMOUNT",
                  value: emiOverdueAmount,
                  text: `No Of EMI's Overdue - ${numberOfEMIsOverdue}`,
                  color_red_class: "color_red",
                },
                {
                  name: "EMI Amount",
                  value: emiAmountValue,
                  text: `Next Due on ${commonFunctionObject.checkEmptyData(
                    getLoanPositionDtlsReturn?.nextEmiDate
                  )}`,
                },
                {
                  name: "CHARGES OVERDUE AMOUNT",
                  value: chargesOverdueAmount,
                  color_red_class: "color_red",
                },
                {
                  name: "Last Paid EMI",
                  value: emiPaidAmount,
                  text: `on ${lastAmountPaidDateValue}`,
                },
              ],
            };
          }
          obj["offerFlag"] = "Y";
          finalData.push(obj);
        } else if (!offerObj) {
          // offer is not there (pure phase1 lan account)
          if (chargesOverdueAmount > 0 || emiOverdueAmount > 0) {
            obj = {
              system: "retail",
              icon: contentObj.icon,
              displayName: contentObj.label,
              contractNumber: i,
              loanStatus: getLoanPositionDtlsReturn.loanStatus,

              loanDetails: [
                {
                  name: "TOTAL LOAN AMOUNT",
                  value: totalLoanAmount,
                },
                {
                  name: "TOTAL DISBURSED AMOUNT",
                  value: totalLoanAmount,
                },
                {
                  name: "EMI OVERDUE AMOUNT",
                  value: emiOverdueAmount,
                  color_red_class: "color_red",
                },
                {
                  name: "EMI Amount",
                  value: emiAmountValue,
                  text: `Next Due on ${commonFunctionObject.checkEmptyData(
                    getLoanPositionDtlsReturn?.nextEmiDate
                  )}`,
                },
                {
                  name: "CHARGES OVERDUE AMOUNT",
                  value: chargesOverdueAmount,
                  color_red_class: "color_red",
                },
                {
                  name: "Last Paid EMI",
                  value: emiPaidAmount,
                  text: `on ${lastAmountPaidDateValue}`,
                },
              ],
            };
            obj["offerFlag"] = "N";
            finalData.push(obj);
          }
        }
        // }
      } else if (
        closedStatus.includes(
          data?.getLoanPositionDTLS?.[
            i
          ]?.body?.getLoanPositionDtlsReturn?.loanStatus.toLowerCase()
        )
      ) {
        const offerObj = data?.offers?.body?.offerDetails.find((offer) => {
          return offer["LOAN_ACCOUNT_NO"] === i;
        });
        let getLoanPositionDtlsReturn =
          data?.getLoanPositionDTLS?.[i]?.body?.getLoanPositionDtlsReturn;
        let unpaidInterest =
          typeof getLoanPositionDtlsReturn?.unpaidInterest === "object"
            ? 0
            : getLoanPositionDtlsReturn?.unpaidInterest;
        let unpaidPrincipleBalance =
          typeof getLoanPositionDtlsReturn?.unpaidPrincipleBalance === "object"
            ? 0
            : getLoanPositionDtlsReturn?.unpaidPrincipleBalance;
        let emiOverdueAmount =
          parseInt(unpaidInterest) + parseInt(unpaidPrincipleBalance);
        let getLoanAccountsReturn =
          data?.getLoanPositionDTLS?.[i]?.body?.getLoanAccountsReturn;

        let totalLoanAmount =
          typeof getLoanPositionDtlsReturn?.disbursedAmount === "object"
            ? 0
            : getLoanPositionDtlsReturn?.disbursedAmount;

        let totalPaidAmount =
          typeof getLoanPositionDtlsReturn?.totalAmountPaid === "object"
            ? 0
            : getLoanPositionDtlsReturn?.totalAmountPaid;

        let emiPaidAmount =
          typeof getLoanPositionDtlsReturn?.lastAmountPaid === "object"
            ? 0
            : getLoanPositionDtlsReturn?.lastAmountPaid;

        let totalNoOfEmis =
          typeof getLoanPositionDtlsReturn?.totalNoofEmis === "object"
            ? 0
            : getLoanPositionDtlsReturn?.totalNoofEmis;
        // console.log(totalNoOfEmis, "totalNoOfEmis");

        let unpaidChargeBalance = 0;
        let overdueChargeAmount = 0;
        if (
          !(getLoanPositionDtlsReturn?.unpaidChargeBalance instanceof Object)
        ) {
          unpaidChargeBalance = getLoanPositionDtlsReturn?.unpaidChargeBalance;
        }
        if (
          !(getLoanPositionDtlsReturn?.overdueChargeAmount instanceof Object)
        ) {
          overdueChargeAmount = getLoanPositionDtlsReturn?.overdueChargeAmount;
        }
        let chargesOverdueAmount =
          parseInt(unpaidChargeBalance) + parseInt(overdueChargeAmount);

        let contentObj = getProductContent(
          parsedUserData[i]?.productInfo?.DISPLAY_NAME
        );

        let obj = {
          system: "retail",
          icon: contentObj?.icon,
          displayName: contentObj?.label,
          contractNumber: i,
          loanStatus: getLoanPositionDtlsReturn.loanStatus,
          loanDetails: [
            {
              name: "TOTAL LOAN AMOUNT",
              value: totalLoanAmount,
            },
            {
              name: "TOTAL PAID AMOUNT",
              value: totalPaidAmount,
            },
            {
              name: "EMI PAID AMOUNT",
              value: emiPaidAmount,
            },
            {
              name: "EMI PAID NUMBER",
              value: totalNoOfEmis,
            },
            {
              name: "Total LOS AMOUNT",
              value: "",
            },
            {
              name: "SALE DATE",
              value: "",
            },
          ],
        };
        if (offerObj) {
          obj["offerFlag"] = "Y";
        } else {
          obj["offerFlag"] = "N";
        }
        finalData.push(obj);
      }
    }
  }

  const loanAccountsSort = (a, b) => {
    const statusOrder = { Active: 1, Closed: 2 };
    const statusA = statusOrder[a.loanStatus];
    const statusB = statusOrder[b.loanStatus];
    return statusA - statusB;
  };

  // Sort the array using the custom sort function
  finalData.sort(loanAccountsSort);
  return finalData;
};
