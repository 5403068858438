import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const swapMandate = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "contractNumber": "TCFCE0554000011299519",
            "losId": "APPL280220227",
            "mobileNumber": "8552923165",
            "sourceSystem": "TKP_ENACH_SWAP_PAGE"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/enach/swap-mandate/v1',data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/enach/swap-mandate/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default swapMandate