import React from 'react'
import "./TabNavItem.scss"
import PropTypes from 'prop-types';

export const TabNavItem = ({ id, title, activeTab, setActiveTab, className, callGetDisbursalDetails, setPage = () => { } }) => {
  const handleClick = () => {
    setActiveTab(id)
    setPage(id)
    if (callGetDisbursalDetails) {
      callGetDisbursalDetails(id)
    }
  }
  return (
    <div onClick={handleClick} className={` tab-nav-item ${activeTab === id ? "active" : ""} ${className ? className : ""}`}>
      {title}
    </div>
  )
}

TabNavItem.propTypes = { 
  title:PropTypes.string , 
  id:PropTypes.string,
  activeTab:PropTypes.string,
  className:PropTypes.string,
  setActiveTab:PropTypes.func,
  callGetDisbursalDetails:PropTypes.func,
  setPage:PropTypes.func
}
