import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const saveMandate = (data) => {
    let requestJson = {
        "header": {

            "authToken": ""

        },

        "body": {
            "contractNumber": "TCHHL0500000100003425",
            "sourceSystem": "TKP_D",
            "systemCode": "FINONE",
            "productFamily": "TCHFL",
            "emailId": "",
            "mobileNumber": "9834771349",
            "customerName": "SATYENDRA SHAW"
        }

    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/enach/save-emandate/v1',data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/enach/save-emandate/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default saveMandate;