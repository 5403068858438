import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getHolidayDetails = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "journeyType": "depledging"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/las-direct/get-holiday-details/v1',data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-direct/get-holiday-details/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getHolidayDetails