import and from './andOperator';
import isFunc from './isFunction';

export default function extendFunction(ParentFunc, ChildFunc) {
    if (and(isFunc(ParentFunc), isFunc(ChildFunc))) {
        ChildFunc.prototype = Object.create(ParentFunc.prototype);
        ChildFunc.prototype.constructor = ChildFunc;
        return true;
    }
    return false;
};