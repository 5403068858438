import React, { useState, useContext } from "react";
import Modal from "../../global/Modal";
import "./ItCertificatePopup.scss";
import CustomDropDown from "../../global/CustomDropDown";
import Button from "../../global/Button";
import userData from "../../screens/Overview/Data";
import { useEffect } from "react";
import OverviewServiceObject from "../../services/Overview";
import commonFunctionObject from "../../common-function";
import Loader from "../../global/Loader";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from '../../global/FailurePopUp'
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import jsHelper from "../../utilities/jsHelper";
import PropTypes from 'prop-types';

var base64 = require('base-64');
const ITCertificate = ({
    setFinalCertificatePopUp,
    // yearData,
    emailTrigger,
    setItCertificateDropDownFlag,
    itCertificateDropDownFlag,
    setOkButtonPopUpContent
}) => {
    const [data, dispatchData] = useContext(DataMapping)
    var accountNumber = data?.currentActiveData.accountNumber
    var currentUserData = data?.parsedUserData[accountNumber]
    const sourceSystem = currentUserData.SOURCE_SYSTEM;
    const [yearState, setYearState] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [emailPopup, setemailPopup] = useState(false);
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    });
    const { analytics } = useContext(AnalyticsContextProvider);
    const [themeState] = useContext(ThemeContext);
    const [selectedYear, setSelectedYear] = useState("Select Financial Year");
    const [itCertificatePopUpFlag, setItCertificatePopUpFlag] = useState(false)

    var yearFormat;

    yearFormat = new Date(commonFunctionObject.convertDisbursalDate(currentUserData.DISBURSAL_DATE)).getFullYear();
    var disbursalMonth = new Date(commonFunctionObject.convertDisbursalDate(currentUserData.DISBURSAL_DATE)).getMonth();
    const [yearsArr, setYearsArr] = useState([]);
    var yearPicker = (yearFormat, disbursalMonth, currentDate) => {
        var yearLastTwoDigit =
            jsHelper.isDef(yearFormat) ?
                jsHelper.isDef(disbursalMonth + 1) ?
                    disbursalMonth + 1 < 4 ?
                        jsHelper.toNum(yearFormat) - 1
                        : jsHelper.toNum(yearFormat)
                    : new Error("contractDetail.disbursalMonth is not defined")
                : new Error("contractDetail.years is not defined");
        var todaysYearLastTwoDigit = (Number(new Date(commonFunctionObject.replaceHyphenDate(currentDate)).getMonth()) + 1 < 4) ? Number(new Date(commonFunctionObject.replaceHyphenDate(currentDate)).getFullYear() - 1) : Number(new Date(commonFunctionObject.replaceHyphenDate(currentDate)).getFullYear())
        let newArray = []
        for (
            yearLastTwoDigit;
            yearLastTwoDigit < todaysYearLastTwoDigit;
            yearLastTwoDigit++
        ) {
            var next = 1;
            next += yearLastTwoDigit;
            newArray.push({ value: yearLastTwoDigit + "-" + next });
        }
        dispatchData({
            name: "finalItFirstItem",
            payload: newArray[0],
            type: STOREDATA
        })
        if (newArray.length === 0) {
            setOkButtonPopUpContent("Final Interest Certificate is not applicable for this loan account as loan is disbursed in the current financial year.")
            setItCertificateDropDownFlag(true)
            setItCertificatePopUpFlag(false)
            setFinalCertificatePopUp(false)
        } else {
            setFinalCertificatePopUp(true)
            setItCertificatePopUpFlag(true)
            setYearsArr(newArray.reverse())
        }

    };
    useEffect(() => {
        yearPicker(yearFormat, disbursalMonth, new Date());
    }, []);
    const downloadITCertificate = () => {
        if (selectedYear !== "Select Financial Year") {
            setErrorMsg(false);
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            let requestObj = {};
            var selectedYearSplit = selectedYear.split("-")
            let formData
            var accountNumber = data?.currentActiveData.accountNumber;
            var currentUserData = data?.parsedUserData[accountNumber];
            var webTopNo = currentUserData?.WEBTOP_NO;
            var gcid = currentUserData.GCID;
            if (selectedYear === data?.finalItFirstItem.value) {
                formData = {
                    "startDate": commonFunctionObject.convertDDMMYYYY(new Date(commonFunctionObject.convertDisbursalDate(currentUserData.DISBURSAL_DATE))),
                    "endDate": "30/03/" + selectedYearSplit[1]
                }
            } else {
                formData = {
                    "startDate": "01/04/" + selectedYearSplit[0],
                    "endDate": "30/03/" + selectedYearSplit[1]
                }
            }
            var FiscalYear = "01-APR-" + selectedYearSplit[0] + "to" + "31-MAR-" + selectedYearSplit[1];
            let productName = commonFunctionObject.getProductName(currentUserData);
            if (sourceSystem === "FINONE") {
                var productFam = commonFunctionObject.getProductFamily(currentUserData)
                var gcid = currentUserData.GCID;
                let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
                if (productName.toLowerCase() === "ccod") {
                    var company = currentContract.cdiContract.COMPANY;
                    var companyName = "";
                    (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
                }
                else {
                    var companyName = productFam;
                }

                requestObj = {
                    header: {
                        authToken:
                            data?.authToken,
                        productFamily: productFam,
                        emailSent: true,
                        documentUploadType: "finalInterestCertificate",
                    },
                    body: {
                        accountNumber: accountNumber,
                        fromDate: formData.startDate,
                        toDate: formData.endDate,
                    },
                };
                OverviewServiceObject.InterestCertificateServiceFinone(requestObj, "finalitcertificate")
                    .then((response) => {
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                        var data = {
                            documentName: "finalInterestCertificate",
                            "productFamily": productFam,
                            "decscription": "Generate Final Interest Certificate for " + accountNumber + " loan account.",
                            "webtopId": webTopNo,
                            "sebServiceRequestType": "finalInterestCertificate",
                            "errorCode": "253",
                        };
                        var loanAccNumber =
                            currentUserData.CONTRACT_NUMBER;
                        var firstName = currentUserData.FIRSTNAME;
                        var lastName = currentUserData.LASTNAME;
                        var email = currentUserData.EMAIL;

                        let analytics_data = {
                            user: {
                                loginMethod: "",
                                loginStatus: "",
                                gcid: gcid,
                                loginType: "",
                                loanaccountNo: accountNumber,
                                firstloginDate: ""
                            },
                            products: {
                                productCode: productName,
                            },
                            data: {
                                ctaText: "",
                                componentName: "QuickLinks",
                                bannerTitle: "",
                                menuTitle: "",
                                faqTitle: "",
                                searchTerm: "",
                                lobActivity: companyName + ":" + productName,
                                status: "SUCCESS",
                                eventName: "finalITcertificateDownload"
                            }
                        }
                        analytics.trackData("finalITcertificateDownload", analytics_data);
                        setemailPopup(true)
                        emailTrigger(
                            data,
                            loanAccNumber,
                            response.body.isEmailAvailable,
                            commonFunctionObject.getLastestFieldValFromField(email),
                            firstName,
                            lastName
                        );

                        setItCertificatePopUpFlag(false)
                    })
                    .catch((error) => {
                        // setFinalCertificatePopUp(false)

                        let analytics_data = {
                            user: {
                                loginMethod: "",
                                loginStatus: "",
                                gcid: gcid,
                                loginType: "",
                                loanaccountNo: accountNumber,
                                firstloginDate: ""
                            },
                            products: {
                                productCode: productName,
                            },
                            data: {
                                ctaText: "",
                                componentName: "QuickLinks",
                                bannerTitle: "",
                                menuTitle: "",
                                faqTitle: "",
                                searchTerm: "",
                                lobActivity: companyName + ":" + productName,
                                status: "FAILURE",
                                eventName: "finalITcertificateDownload"
                            }
                        }
                        analytics.trackData("finalITcertificateDownload", analytics_data);
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                        setFailurePopUpContent({
                            apiName: "",
                            message: "We are experiencing technical difficulty please try after sometime",
                            errorCode: error
                        })
                        setFailurePopUpModal(true)

                    });
            }
            if (sourceSystem === "BANCS") {
                requestObj = {
                    header: {
                        authToken: data?.authToken,
                        requestType: "prov-int-cert",
                        productFamily: "TCHFL",
                    },
                    body: {
                        FiscalYear: FiscalYear,
                        Report: "1",
                        AcctNum: base64.encode(accountNumber),
                    },
                };
                OverviewServiceObject.InterestCertificateServiceBancs(
                    requestObj,
                    sourceSystem
                )
                    .then((response) => {
                        let analytics_data = {
                            user: {
                                loginMethod: "",
                                loginStatus: "",
                                gcid: gcid,
                                loginType: "",
                                loanaccountNo: accountNumber,
                                firstloginDate: ""
                            },
                            products: {
                                productCode: productName,
                            },
                            data: {
                                ctaText: "",
                                componentName: "QuickLinks",
                                bannerTitle: "",
                                menuTitle: "",
                                faqTitle: "",
                                searchTerm: "",
                                lobActivity: companyName + ":" + productName,
                                status: "SUCCESS",
                                eventName: "finalITcertificateDownload"
                            }
                        }
                        analytics.trackData("finalITcertificateDownload", analytics_data);
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    })
                    .catch((error) => {
                        let analytics_data = {
                            user: {
                                loginMethod: "",
                                loginStatus: "",
                                gcid: gcid,
                                loginType: "",
                                loanaccountNo: accountNumber,
                                firstloginDate: ""
                            },
                            products: {
                                productCode: productName,
                            },
                            data: {
                                ctaText: "",
                                componentName: "QuickLinks",
                                bannerTitle: "",
                                menuTitle: "",
                                faqTitle: "",
                                searchTerm: "",
                                lobActivity: companyName + ":" + productName,
                                status: "FAILURE",
                                eventName: "finalITcertificateDownload"
                            }
                        }
                        analytics.trackData("finalITcertificateDownload", analytics_data);
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    });
            }
        }
        else {
            setErrorMsg(true);
        }
    };

    return (
        <>
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {!itCertificateDropDownFlag && itCertificatePopUpFlag ?
                <Modal >
                    {/* <div className={`${themeState}`}> */}
                    <div className={`IT-certificate-pop-up  ${themeState}`}>
                        <div className="IT-Certificate-head">
                            <p className="IT-certificate-title"> Final IT Certificate</p>
                            <p className="IT-Certificate-describe"
                            //     style={{
                            //     color: `${themeState === "THEME_DARK" ? "#fff" : "black"}`,
                            // }} 
                            >
                                Please select a financial year
                            </p>
                            <img
                                className="it-certificate-cancel-icon"
                                src="assets/images/cancel.svg"
                                alt="cancel-icon"
                                onClick={() => setItCertificatePopUpFlag(false)}
                            />
                        </div>
                        <div className="IT-Certificate-body">
                            <CustomDropDown
                                optionArray={yearsArr}
                                optionState={yearState}
                                setOptionState={setYearState}
                                selectedOption={selectedYear}
                                setSelectedOption={setSelectedYear}

                                labelFlag={true}
                                labelText=""
                                error={selectedYear === "Select Financial Year" ? "Please Select a Financial Year" : ""}
                                errorSpace={errorMsg}
                            />
                            {/* { errorMsg || selectedYear !== "Select Financial Year" ?
                                <p className="error-span" >Please Select a Financial Year</p> : ""
                            } */}
                        </div>

                        <div className="download-button">
                            <Button
                                className="download-button-container"
                                onClick={downloadITCertificate}
                            >
                                <p>Download</p>
                                <img
                                    src="assets/images/documentDownloadIcon.svg"
                                    alt="download icon"
                                />
                            </Button>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal> : null}
        </>
    );
};

ITCertificate.propTypes = {
    setFinalCertificatePopUp: PropTypes.func,
    emailTrigger: PropTypes.func,
    setItCertificateDropDownFlag: PropTypes.func,
    itCertificateDropDownFlag: PropTypes.bool,
    setOkButtonPopUpContent: PropTypes.func
}
export default ITCertificate;