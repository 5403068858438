const loanPopulationDetailsArray = {}

const productInformationArray = [
    {
        "label": "Product Type",
        "api": "PRODUCT"
    },
    {
        "label": "Total Limit",
        "api": "TOTAL_LIMIT"
    },
    {
        "label": "Available Limit",
        "api": "AVAIL_LIMIT"
    },
    {
        "label": "Limit Expiry Date",
        "api": "TOTAL_REV_DT"
    },
    {
        "label": "Tenure (Months)",
        "api": "tenureMonths"
    },
    {
        "label": "Amount in OD",
        "api": "TOT_OD_AMT"
    },
    {
        "label": "Other Adjustments",
        "api": "otherAdjustments"
    }
]

loanPopulationDetailsArray.productInformationArray = productInformationArray


const amountInformation = [
    {
        "label": "Overdue charges (ODC)",
        "api": "overDueCharges"
    },{
        "label": "Other Charges",
        "api": "otherCharges"
    },{
        "label": "Processing Fee",
        "api": "processingFee"
    },{
        "label": "Interest Amount",
        "api": "interestAmount"
    },{
        "label": "Billable Amount",
        "api": "AVAIL_LIMIT"
    },{
        "label": "Utilized Amount",
        "api": "OUT_BAL"
    },{
        "label": "Principal Outstanding",
        "api": "principalOutstanding"
    }
]
loanPopulationDetailsArray.amountInformation = amountInformation

export default loanPopulationDetailsArray