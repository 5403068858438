import React, { useEffect } from "react";
import "./CustomDropDown.scss";
import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import { useRef } from "react";
import PropTypes from 'prop-types';

const CustomDropDown = ({
  className,
  optionArray,
  optionState,
  setOptionState,
  selectedOption,
  setSelectedOption,
  labelFlag,
  labelText,
  labelClass,
  setActiveTab,
  storeSelectAccNumberData,
  bankLas,
  setEmployementType,
  ccodAccount,
  asterisk,
  callGetDisbursalDetails,
  error,
  errorSpace,
  handleDropDownChange,
}) => {
  const [themeState] = useContext(ThemeContext);
  const dropDownRef = useRef()
  const handdleOptionChange = (obj) => {
    setSelectedOption(obj.value);
    if (setActiveTab) {
      setActiveTab(obj.id);
    }
    if (storeSelectAccNumberData) {
      storeSelectAccNumberData(obj.value)
    }
    if (setEmployementType) {
      setEmployementType(obj.value)
    }
    if (callGetDisbursalDetails) {
      callGetDisbursalDetails(obj.id)
    }
    if (handleDropDownChange) {
      handleDropDownChange(obj.value)
    }

  };

  useEffect(() => {
    const onBodyClick = (e) => {
      if (!dropDownRef.current?.contains(e.target)) {
        setOptionState(false)
      }
    }
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    }
  }, [])

  return (
    <>
      <div
        className={`custom-drop-down-account-number ${className ? className : ""
          }`}
      >
        {labelFlag ? (
          <label htmlFor="custom-drop-down-account-details" className={labelClass ? labelClass : ""}>{labelText}{asterisk ? <span className="red-star">*</span> : ""}</label>
        ) : null}

        <div
          className="select-wrapper "
          onClick={(event) => {
            event.stopPropagation()
            setOptionState(!optionState);
          }}
        >
          <p>{selectedOption}</p>
          <img
            style={{
              width: "1.3rem",
              filter: `${themeState === THEME_DARK ? "invert(1)" : ""}`,
              transform: `${optionState ? "rotate(180deg)" : ""}`,
            }}

            src="assets/images/arrow-down.svg"
            alt=''
          />
          {optionState ? (
            <div ref={dropDownRef} className={`custom-drop-down-options-container  ${ccodAccount ? "ccod-account-number" : ""}`}>
              <div className="custom-dropdown-option-inner-container">
                {!bankLas ? optionArray?.map((obj, index) => (
                  <p key={index} onClick={() => {
                    handdleOptionChange(obj)
                  }}>
                    {obj.value}
                  </p>
                )) : optionArray.filter(obj => obj.system !== "las").map((obj, index) => (
                  <p key={index} onClick={() => {
                    handdleOptionChange(obj)
                  }}>
                    {obj.value}
                  </p>
                ))}
              </div>
            </div>
          ) : null}

        </div>

      </div>
      {
        errorSpace && (
          <div className="error-span">
            <span>{error}</span>
          </div>
        )
      }
    </>

  );
};

 
CustomDropDown.propTypes = { 
  className : PropTypes.string , 
  optionArray : PropTypes.array,
  optionState : PropTypes.bool,
  setOptionState : PropTypes.func,
  selectedOption : PropTypes.string,
  setSelectedOption : PropTypes.func,
  labelFlag : PropTypes.bool,
  labelText : PropTypes.string,
  labelClass : PropTypes.string,
  setActiveTab : PropTypes.func,
  storeSelectAccNumberData : PropTypes.func,
  bankLas : PropTypes.bool,
  setEmployementType : PropTypes.func,
  ccodAccount : PropTypes.bool,
  asterisk : PropTypes.bool,
  callGetDisbursalDetails : PropTypes.func,
  error : PropTypes.string,
  errorSpace : PropTypes.bool,
  handleDropDownChange : PropTypes.func
}

export default CustomDropDown;