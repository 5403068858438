import React, { useContext, useEffect, useState } from "react";
import "./Payment.scss";
import RadiantBorder from "../RadiantBorder/RadiantBorder";
import PaymentCard from "../PaymentCard/PaymentCard";
import PropTypes from "prop-types";
import Input from "../../FormFields/Input/Input";
import Button from "../../FormFields/Button/Button";
import Modal from "../Modal";
import DataMapping from "../../../../Shared/context/data-mapping";
import Validators from "../../hooks/useReactiveForm/validator";
import useReactiveForm from "../../hooks/useReactiveForm/useReactiveForm";
import commonFunctionObject from "../../../../Retail/src/common-function";
import { useLocation } from "react-router-dom";
import { pgsApiCallFn } from "../../services/pgsApiCall";
import { eventLoggingDtlsApiCall } from "../../services/eventLoggingDtlsApiCall";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import LoginServiceObject from "../../../../Retail/src/services/Login";
import { useRef } from "react";
import { ClipboardEvent } from "react";

const Payment = ({
  setFailurePopUpContent,
  setFailurePopUpModal,
  setPaymentOptClickCount,
  handleClickForPaymentOpt,
  paymentOptClickCount = "",
  data = "",
  paymentOptions = "",
  additionalDetails = "",
  additionalNumber = "",
  additionalEmailId = "",
  preapprovedOfferFlag,
  setPreapprovedOfferFlag,
  popOverFlag,
  setPopOverFlag,
  payNowFlag,
  setPayNowFlag,
  ischecked,
  setIsChecked,
  attemptFlag,
  activeOffer,
  dashPaymentData,
  successFailureFlag,
  setSuccessFailureFlag,
  journeyType,
  paymentForm,
  handleForm,
  setPaymentFlag,
  setAttemptFlag,
  validateFlagEmail,
  setValidateFlagEmail,
  validateFlagMobile,
  setValidateFlagMobile,
  setFinalPayOptClickObj,
  paymentCardArr,
  setPaymentCardArr,
  selectedLabel,
  handleActive,
  disableBtn,
  setDisableBtn,
}) => {
  const [contextData, dispatchData] = useContext(DataMapping);
  const [, setBluuActive] = useState([]);
  // const [paymentCardArr, setPaymentCardArr] = useState([
  //   {
  //     id: 1,
  //     labelName: "Total Overdue Amount",
  //     isActive: true,
  //     isInput: false,
  //     amount: "",
  //   },
  //   {
  //     id: 2,
  //     labelName: "EMI Overdue",
  //     isActive: false,
  //     isInput: false,
  //     amount: "0",
  //   },
  //   {
  //     id: 3,
  //     labelName: "Other Amount",
  //     isActive: false,
  //     isInput: true,
  //     amount: "",
  //   },
  // ]);
  const [modalPopUp, setModalPopUp] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [conveyPopUp, setConveyPopUp] = useState(false);
  const [showOdbcWaiverGrid, setShowOdbcWaiverGrid] = useState(false);
  const [modalType, setModalType] = useState("success");
  // const [totalODAmount, setTotalODAmount] = useState(0);

  const [callpgs, setCallpgs] = useState(true);
  const location = useLocation();
  // const [paymentForm, handleForm] = useReactiveForm({
  //   mobile: ["", [Validators.pattern(/^[6789][\d]{9}$/)]],
  //   email: ["", [Validators.mail]],
  // });

  const [modalPopupData] = useState({
    success: {
      gif: "success.gif",
      msg: `Thank you for making payment of ₹ ${
        contextData?.callpgs
          ? contextData?.paymentAmount
          : contextData?.approvedLoanAmounts?.approvedLoanData[
              contextData.currentActiveData.accountNumber
            ]?.approvedAmountValue
          ? contextData?.approvedLoanAmounts?.approvedLoanData[
              contextData.currentActiveData.accountNumber
            ]?.approvedAmountValue.toLocaleString("en-IN")
          : 0
      }/- towards your loan
            account, Amount will reflect in statement in 2 working days. Please
            make timely payment to avoid any additional charge getting levied
            and maintain good credit score.`,
    },
    pendding: {
      gif: "pendding.gif",
      msg: "Please wait...",
    },
    failure: {
      gif: "failure.gif",
      msg: (
        <>
          <p>Please try again and complete your transaction.</p>
          {/* <p>
            But no worries, Please try again and complete your payment journey.
          </p> */}
        </>
      ),
      // msg: <><span className="c_failure_msg"> Your payment of <span className="c_failure_msg_bold">₹ {contextData?.paymentAmount}/-</span> is failed.</span><br />
      //     <span span className="c_failure_msg c_failure_msg_bold">Your transaction could not be processed, please try again after sometime.</span></>
    },
  });

  const [emiOverduePopupData] = useState({
    success: {
      gif: "success.gif",
      msg: `${
        contextData?.emiOverdueOfferRes?.emiOfferCase === "EMI Normalized"
          ? contextData?.emiOverdueOfferRes?.emiOdbcWaiverAmount > 0
            ? `Congratulations...!!! You got a financial benefit on EMI OVERDUE CHARGES. Your current EMI OVERDUE CHARGES are ₹${(contextData?.emiOverdueOfferRes?.emiOdbcWaiverAmount).toLocaleString(
                "en-IN"
              )}`
            : contextData?.emiOverdueOfferRes?.emiOdbcWaiverAmount === 0
            ? `Thank you for making payment of ₹${(contextData?.emiOverdueOfferRes?.emiEnteredAmount).toLocaleString(
                "en-IN"
              )}/- towards your loan account`
            : ""
          : contextData?.emiOverdueOfferRes?.emiOfferCase ===
            "EMI Not Normalized"
          ? `Thank you for making payment of ₹${(contextData?.emiOverdueOfferRes?.emiEnteredAmount).toLocaleString(
              "en-IN"
            )}/- towards your loan account`
          : ""
      }`,
    },
    pendding: {
      gif: "pendding.gif",
      msg: "Please wait...",
    },
    failure: {
      gif: "failure.gif",
      msg: (
        <>
          <p>Please try again and complete your transaction.</p>

          {/* <p>
            But no worries, please try again and complete your payment journey.
          </p> */}
        </>
      ),
    },
  });

  const [odbcPopupData] = useState({
    success: {
      gif: "success.gif",
      msg: `${
        contextData?.odbcOfferRes?.odbcOfferCase === "Offer Approved"
          ? `Thank you for making payment of ₹ ${(
              contextData?.odbcOfferRes?.odbcWaiverAmount ?? 0
            ).toLocaleString(
              "en-IN"
            )}/- towards your loan account. You got a financial benefit on CHARGES OVERDUE AMOUNT. Your got off ₹${(
              contextData?.odbcOfferRes?.odbcEnteredAmount -
                contextData?.odbcOfferRes?.odbcWaiverAmount ?? 0
            ).toLocaleString("en-IN")} towards your loan account`
          : contextData?.odbcOfferRes?.odbcOfferCase === "Offer Rejected"
          ? `Thank you for making payment of ₹ ${(
              contextData?.odbcOfferRes?.odbcEnteredAmount ?? 0
            ).toLocaleString("en-IN")}/- towards your loan account.`
          : ``
      }`,
    },
    pendding: {
      gif: "pendding.gif",
      msg: "Please wait...",
    },
    failure: {
      gif: "failure.gif",
      msg: (
        <>
          <p>Please try again and complete your transaction.</p>
          {/* <p>
            But no worries, Please try again and complete your payment journey.
          </p> */}
        </>
      ),
    },
  });

  const [fcStaticAmount, setFcStaticAmount] = useState("");
  const [fcAmount, setFcAmount] = useState("");
  const [amount, setAmount] = useState(fcAmount);
  const [changeFcSettlemnt, setChangeFcSettlement] = useState("");
  const [remainingAmount, setRemainingAmount] = useState("");
  const [remainAmountFlag, setRemainAmountFlag] = useState(false);
  const [changeRemainAmount, setChangeRemainAmount] = useState(false);
  const [changeInpAmount, setChangeInpAmount] = useState("");
  const [fcToolTip, setFcToolTip] = useState("");
  const [fcValidFlag, setFcValidFlag] = useState(false);
  const [fcZeroValid, setFcZeroValid] = useState(false);
  const [remAmountToolTip, setRemainingAmountToolTip] = useState("");
  let paymentCompContent = {
    SETTLEMENT_CAMPAIGN: {
      label1: "settlement",
      label2: "settlement",
      label3: "Settlement",
    },
    EMI_OVERDUE_CAMPAIGN: {
      label1: "emi overdue",
      label2: "emi overdue",
      label3: "Emi overdue",
    },
    ODBC_CAMPAIGN: {
      label1: "ODBC",
      label2: "ODBC",
      label3: "ODBC",
    },
    LOS_POOL_CAMPAIGN: {
      label1: "loss on sale pool",
      label2: "loss on sale pool",
      label3: "Loss on sale pool",
    },
    ALREADY_SETTLED_POOL_CAMPAIGN: {
      label1: "already settled pool",
      label2: "already settled pool",
      label3: "Already settled pool",
    },
  };

  const offerContent = paymentCompContent[activeOffer];
  const { label1 = "", label2 = "", label3 = "" } = offerContent || {};
  const [selectConveyActive, setSelectConveyActive] = useState(false);
  const [selectedConveyOption, setSelectedConveyOption] = useState("");
  const [isInputClicked, setIsInputClicked] = useState(false);
  const [isImageClicked, setIsImageClicked] = useState(true);
  const [settlementValid, setSettlementValid] = useState(false);
  const [showOdbcPopupAnimation, setShowOdbcPopupAnimation] = useState(false);
  const [showAdditionValid, setShowAdditionValid] = useState(false);
  const [phoneValidFlag, setPhoneValidFlag] = useState(false);
  const [toolTipChecked, setToolTipChecked] = useState(false);
  const inputRef = useRef(null);
  // console.log(paymentCardArr,"paymentCardArr");

  // Function to handle input click
  const handleInputClick = () => {
    setIsInputClicked(true);
    setIsImageClicked(false);
    setSettlementValid(false);
  };

  // Function to handle image click
  const handleImageClick = () => {
    setIsInputClicked(true);
    setIsImageClicked(true);
    setSettlementValid(false);
    inputRef.current.focus();
  };

  // Function to handle click outside the input and image
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      !event.target.classList.contains("edit-pencil-icon")
    ) {
      setIsImageClicked(false);
      setIsInputClicked(false);
    }
  };

  // Register click outside the input and image
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // console.log(dashPaymentData, "dashPaymentData");
    if (dashPaymentData) {
      if (contextData.currentOfferPopup == "SETTLEMENT_CAMPAIGN") {
        let amountVal = dashPaymentData?.offerData["FC"].toString();
        let numericAmount = parseFloat(amountVal);
        let formattedAmount = numericAmount.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        setFcStaticAmount(formattedAmount);

        let amountValContext =
          contextData?.approvedLoanAmounts?.approvedLoanData[
            contextData.currentActiveData.accountNumber
          ] &&
          contextData?.approvedLoanAmounts?.approvedLoanData[
            contextData.currentActiveData.accountNumber
          ]?.approvedAmountValue.toString();
        let numericAmountContext = parseFloat(amountValContext);
        let formattedAmountContext = numericAmountContext.toLocaleString(
          "en-IN",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );

        if (
          dashPaymentData?.offerData?.["OFFER_APPROVED_AMOUNT"] &&
          sessionStorage.getItem("paymentmodal-background-amount-flag")
        ) {
          let amountValAPI =
            dashPaymentData?.offerData?.["OFFER_APPROVED_AMOUNT"].toString();
          let numericAmountAPI = parseFloat(amountValAPI);
          let formattedAmountAPI = numericAmountAPI.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          if (sessionStorage.getItem("paymentmodal-background-amount-flag")) {
            setPopOverFlag(false);
            setPayNowFlag(false);
            setDisableBtn(false);
            setAmount(formattedAmountAPI);
            setFcAmount(formattedAmountAPI);
            sessionStorage.removeItem("paymentmodal-background-amount-flag");
          }
        }

        if (sessionStorage.getItem("offer-accept-flag")) {
          setPopOverFlag(false);
          setPayNowFlag(false);
          setDisableBtn(false);
          setAmount(formattedAmountContext);
          // setFcAmount(formattedAmountContext);
          sessionStorage.removeItem("offer-accept-flag");
        }

        if (sessionStorage.getItem("reason-reject-flag")) {
          setAmount(formattedAmount);
          setFcAmount(formattedAmount);
          setPopOverFlag(false);
          setPayNowFlag(false);
          setDisableBtn(false);
          sessionStorage.removeItem("reason-reject-flag");
        }

        if (sessionStorage.getItem("reason-accept-flag")) {
          setPopOverFlag(false);
          setPayNowFlag(false);
          setDisableBtn(false);
          setAmount(formattedAmountContext);
          // setFcAmount(formattedAmountContext);
          sessionStorage.removeItem("reason-accept-flag");
        }

        if (sessionStorage.getItem("paymentplan-reject-flag")) {
          setPopOverFlag(false);
          setPayNowFlag(false);
          setDisableBtn(false);
          setAmount(formattedAmount);
          setFcAmount(formattedAmount);
          sessionStorage.removeItem("paymentplan-reject-flag");
        }
      }
    }
  }, [
    sessionStorage.getItem("offer-accept-flag"),
    sessionStorage.getItem("reason-reject-flag"),
    sessionStorage.getItem("reason-accept-flag"),
    sessionStorage.getItem("paymentplan-reject-flag"),
    sessionStorage.getItem("paymentmodal-background-amount-flag"),
  ]);

  useEffect(() => {
    if (contextData?.currentOfferPopup == "SETTLEMENT_CAMPAIGN") {
      if (contextData?.offerModaLFlag?.accountType == "congratsModal") {
        if (successFailureFlag) {
          let accountNumber = contextData.currentActiveData.accountNumber;
          let finalAmountValContext =
            contextData?.approvedLoanAmounts?.approvedLoanData[accountNumber]
              ?.finalApprovedAmountValue &&
            contextData?.approvedLoanAmounts?.approvedLoanData[
              accountNumber
            ]?.finalApprovedAmountValue.toString();
          let finalNumericAmountContext = parseFloat(finalAmountValContext);
          let finalFormattedAmountContext =
            finalNumericAmountContext.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          // console.log(finalFormattedAmountContext, "finalFormattedAmountContext");
          setAmount(finalFormattedAmountContext);
        } else if (successFailureFlag == false) {
          let rejectAmountValContext =
            contextData?.approvedLoanAmounts?.approvedLoanData[
              contextData.currentActiveData.accountNumber
            ]?.rejectedAmountValue &&
            contextData?.approvedLoanAmounts?.approvedLoanData[
              contextData.currentActiveData.accountNumber
            ]?.rejectedAmountValue.toString();
          let rejectNumericAmountContext = parseFloat(rejectAmountValContext);
          let rejectFormattedAmountContext =
            rejectNumericAmountContext.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          // console.log(rejectFormattedAmountContext,"rejectFormattedAmountContext");

          // setAmount(contextData?.attempt?.loanAttempts[contextData.currentActiveData.accountNumber] ? contextData?.attempt?.loanAttempts[contextData.currentActiveData.accountNumber]?.attempts == 0 ? contextData?.dashPaymentData?offerData["FC"] :rejectFormattedAmountContext);

          let amountVal =
            dashPaymentData?.offerData &&
            dashPaymentData?.offerData["FC"].toString();
          let numericAmount = parseFloat(amountVal);
          let formattedAmount = numericAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          setAmount(
            contextData?.attempt?.loanAttempts[
              contextData.currentActiveData.accountNumber
            ]
              ? contextData?.attempt?.loanAttempts[
                  contextData.currentActiveData.accountNumber
                ]?.attempts == 0
                ? formattedAmount
                : rejectFormattedAmountContext
              : rejectFormattedAmountContext
          );
          setPopOverFlag(false);
          setDisableBtn(false);
          setPayNowFlag(false);
        }
      }
    }
  }, [contextData?.offerModaLFlag?.accountType]);

  useEffect(() => {
    if (amount === fcAmount) {
      setPopOverFlag(false);
      setPayNowFlag(false);
      // setDisableBtn(false);
    }
  }, [payNowFlag, popOverFlag]);

  useEffect(() => {
    getSignOfRemainingValue(remainingAmount);
  }, [changeRemainAmount]);

  useEffect(() => {
    handleActiveOfferData();
  }, [contextData.currentActiveData, preapprovedOfferFlag]);

  useEffect(() => {
    handleForm("mobile", "");
    handleForm("email", "");
    handleClickForPaymentOpt("handleNumEmail", "", "mobile", "");
    handleClickForPaymentOpt("handleNumEmail", "", "email", "");
  }, [contextData.currentActiveData?.accountNumber]);

  useEffect(() => {
    let url = window.location.href;
    let setUrl = url.split("?")[0];
    // console.log(setUrl);
    var parameter = commonFunctionObject.getURLParams(location.search);
    if (parameter.hasOwnProperty("src")) {
      if (parameter["src"] == "payment") {
        if (parameter.hasOwnProperty("subsrc")) {
          if (parameter["subsrc"] == "transaction-success") {
            const object = contextData?.offers?.body?.offerDetails?.find(
              (ele) =>
                ele?.LOAN_ACCOUNT_NO ===
                contextData?.currentActiveData?.accountNumber
            );
            // console.log(object, "objectttttt");
            if (contextData?.currentOfferPopup === "SETTLEMENT_CAMPAIGN") {
              sessionStorage.setItem(
                "paymentmodal-background-amount-flag",
                true
              );
              const PopupsData = ["pay"];
              const hidePopup = JSON.stringify(PopupsData);
              sessionStorage.setItem("hidePopupData", hidePopup);

              let activeLoanOfferDetails =
                contextData?.activePaymentLoanData?.isTrancheOpted;

              // console.log(activeLoanOfferDetails, "activeLoanOfferDetails");

              if (
                activeLoanOfferDetails === "Y" ||
                object?.["IS_TRANCHE_OPTED"] === "Y"
              ) {
                if (object?.["TRANCHE1_PAYMENT_STATUS"]) {
                  if (object?.["TRANCHE1_PAYMENT_STATUS"] === "Success") {
                    sessionStorage.setItem("payment-success", "true");
                  } else if (object?.["TRANCHE1_PAYMENT_STATUS"] === "Failed") {
                    sessionStorage.setItem("payment-failure", "true");
                  }
                } else {
                  sessionStorage.setItem("payment-success", "true");
                }
              } else if (
                activeLoanOfferDetails === "N" ||
                object?.["IS_TRANCHE_OPTED"] === "N"
              ) {
                sessionStorage.setItem("payment-success", "true");
              }
            } else if (
              contextData?.currentOfferPopup === "EMI_OVERDUE_CAMPAIGN"
            ) {
              // console.log(contextData?.currentOfferPopup,"contextData?.currentOfferPopup");
              // if(contextData?.callpgs == true && contextData?.emiOverdueOfferRes?.emiOfferKey == "not-normalised"){
              //   sessionStorage.setItem("emi-payment-edge-case-not-normalised", "true");
              // }

              sessionStorage.setItem("emi-payment-success", "true");
            } else if (contextData?.currentOfferPopup === "ODBC_CAMPAIGN") {
              // console.log(contextData?.currentOfferPopup,"contextData?.currentOfferPopup");
              if (contextData?.odbcOfferRes?.conveyOption === "ForeGo") {
                sessionStorage.setItem("odbc-forego-payment-success", "true");
              } else if (contextData?.odbcOfferRes?.conveyOption === "Yes") {
                if (
                  contextData?.odbcOfferRes?.odbcEnteredAmount ==
                  contextData?.odbcChargesAmount
                ) {
                  sessionStorage.setItem("odbc-payment-success", "true");
                } else if (
                  contextData?.odbcOfferRes?.odbcEnteredAmount <
                  contextData?.odbcChargesAmount
                ) {
                  sessionStorage.setItem("odbc-payment-failure", "true");
                }
              }
            } else if (contextData?.currentOfferPopup === "NON_OFFER") {
              // console.log(contextData?.currentOfferPopup,"offer_popup");
              sessionStorage.setItem("phase1-payment-success", "true");
              dispatchData({
                type: STOREDATA,
                payload: true,
                name: "callpgs",
              });
              // let getObjectData = contextData?.eventDataPostPayment?.map(
              //   (ele) => {
              //     if (
              //       ele?.accountNumber ===
              //       contextData?.currentActiveData?.accountNumber
              //     ) {
              //       ele.paymentStatus = "Y";
              //       return ele;
              //     } else {
              //       return ele;
              //     }
              //   }
              // );
              // dispatchData({
              //   name: "eventDataPostPayment",
              //   payload: getObjectData,
              //   type: STOREDATA,
              // });
            }

            dispatchData({
              type: STOREDATA,
              payload: true,
              name: "loaderFlag",
            });
            const fetchData = async () => {
              const retailContractNumbers = [];
              // Extracting retail contract numbers from user data
              contextData.getUserDataV3?.body?.contracts.forEach((item) => {
                if (item?.system === "retail") {
                  retailContractNumbers.push(item.CONTRACT_NUMBER);
                }
              });

              try {
                const offerDataResponse =
                  await LoginServiceObject.offerCollectionDetails(
                    contextData.authToken,
                    retailContractNumbers
                  );
                dispatchData({
                  type: STOREDATA,
                  name: "offers",
                  payload: offerDataResponse,
                });
                // console.log(offerDataResponse, "offerDataResponse");
                if (contextData?.currentOfferPopup === "NON_OFFER") {
                  setFinalPayOptClickObj(
                    contextData.uniqueTransactionId,
                    "paymentStatusRedirection",
                    "Y"
                  );
                  dispatchData({
                    name: "latestPaymentData",
                    payload: {
                      phase: 1,
                      labelName: contextData?.phase1PaymentPreDataLabel,
                      amount: contextData?.uniqueTransactionId
                        ? contextData?.approvedLoanAmounts
                          ? contextData?.approvedLoanAmounts?.approvedLoanData[
                              contextData.currentActiveData.accountNumber
                            ] &&
                            contextData?.approvedLoanAmounts?.approvedLoanData[
                              contextData.currentActiveData.accountNumber
                            ]?.approvedAmountValue
                          : contextData?.prePaymentLabelDetails[
                              contextData?.activePhase1PaymentLabel
                            ]?.validAmount
                        : "",
                    },
                    type: STOREDATA,
                  });
                } else if (
                  contextData?.currentOfferPopup === "SETTLEMENT_CAMPAIGN"
                ) {
                  const updatedOfferDetails =
                    offerDataResponse?.body?.offerDetails?.find(
                      (ele) =>
                        ele?.LOAN_ACCOUNT_NO ===
                        contextData?.currentActiveData?.accountNumber
                    );

                  if (updatedOfferDetails?.["IS_TRANCHE_OPTED"] === "Y") {
                    if (contextData?.paymentTrancheNumberData == 1) {
                      if (updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"]) {
                        if (
                          updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                          "Success"
                        ) {
                          setFinalPayOptClickObj(
                            contextData.uniqueTransactionId,
                            "paymentStatusRedirection",
                            "Partially Paid"
                          );
                        } else if (
                          updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                          "Failed"
                        ) {
                          setFinalPayOptClickObj(
                            contextData.uniqueTransactionId,
                            "paymentStatusRedirection",
                            "Unpaid"
                          );
                        } else if (
                          updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                          "INITIATED"
                        ) {
                          setFinalPayOptClickObj(
                            contextData.uniqueTransactionId,
                            "paymentStatusRedirection",
                            "Unpaid"
                          );
                        }
                      } else {
                        setFinalPayOptClickObj(
                          contextData.uniqueTransactionId,
                          "paymentStatusRedirection",
                          "Unpaid"
                        );
                      }
                    } else if (contextData?.paymentTrancheNumberData == 2) {
                      if (updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"]) {
                        if (
                          updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                          "Success"
                        ) {
                          if (
                            updatedOfferDetails?.["TRANCHE2_PAYMENT_STATUS"]
                          ) {
                            if (
                              updatedOfferDetails?.[
                                "TRANCHE2_PAYMENT_STATUS"
                              ] === "INITIATED"
                            ) {
                              setFinalPayOptClickObj(
                                contextData.uniqueTransactionId,
                                "paymentStatusRedirection",
                                "Partially Paid"
                              );
                            } else if (
                              updatedOfferDetails?.[
                                "TRANCHE2_PAYMENT_STATUS"
                              ] === "Success"
                            ) {
                              setFinalPayOptClickObj(
                                contextData.uniqueTransactionId,
                                "paymentStatusRedirection",
                                "Paid"
                              );
                            } else if (
                              updatedOfferDetails?.[
                                "TRANCHE2_PAYMENT_STATUS"
                              ] === "Failed"
                            ) {
                              setFinalPayOptClickObj(
                                contextData.uniqueTransactionId,
                                "paymentStatusRedirection",
                                "Partially Paid"
                              );
                            }
                          } else {
                            setFinalPayOptClickObj(
                              contextData.uniqueTransactionId,
                              "paymentStatusRedirection",
                              "Partially Paid"
                            );
                          }
                        }
                      } else {
                        setFinalPayOptClickObj(
                          contextData.uniqueTransactionId,
                          "paymentStatusRedirection",
                          "Unpaid"
                        );
                      }
                    }
                  } else if (
                    updatedOfferDetails?.["IS_TRANCHE_OPTED"] === "N"
                  ) {
                    if (updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"]) {
                      if (
                        updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                        "Success"
                      ) {
                        setFinalPayOptClickObj(
                          contextData.uniqueTransactionId,
                          "paymentStatusRedirection",
                          "Paid"
                        );
                      } else if (
                        updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                        "Failed"
                      ) {
                        setFinalPayOptClickObj(
                          contextData.uniqueTransactionId,
                          "paymentStatusRedirection",
                          "Unpaid"
                        );
                      } else if (
                        updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                        "INITIATED"
                      ) {
                        setFinalPayOptClickObj(
                          contextData.uniqueTransactionId,
                          "paymentStatusRedirection",
                          "Unpaid"
                        );
                      }
                    } else {
                      setFinalPayOptClickObj(
                        contextData.uniqueTransactionId,
                        "paymentStatusRedirection",
                        "Unpaid"
                      );
                    }
                  }
                  dispatchData({
                    name: "latestPaymentData",
                    payload: {
                      phase: 2,
                      labelName: "",
                      amount: "",
                    },
                    type: STOREDATA,
                  });
                }
              } catch (error) {
                console.error("Error fetching offer data:", error);
              }
            };

            // Fetch offer data
            fetchData();

            // sessionStorage.setItem("pay", true);
            setModalType("success");
            dispatchData({
              type: STOREDATA,
              payload: false,
              name: "loaderFlag",
            });
            setModalPopUp(true);
            setPaymentPopup(true);
            window.history.replaceState({}, "Page", setUrl);
          } else if (parameter["subsrc"] == "transaction-failure") {
            const object = contextData?.offers?.body?.offerDetails?.find(
              (ele) =>
                ele?.LOAN_ACCOUNT_NO ===
                contextData?.currentActiveData?.accountNumber
            );

            // console.log(object, "objectttttt");

            if (contextData?.currentOfferPopup === "SETTLEMENT_CAMPAIGN") {
              const PopupsData = ["pay"];
              const hidePopup = JSON.stringify(PopupsData);
              sessionStorage.setItem("hidePopupData", hidePopup);
              let activeLoanOfferDetails =
                contextData?.activePaymentLoanData?.isTrancheOpted;

              // console.log(activeLoanOfferDetails, "activeLoanOfferDetails");

              if (
                activeLoanOfferDetails === "Y" ||
                object?.["IS_TRANCHE_OPTED"] === "Y"
              ) {
                sessionStorage.setItem("payment-failure", "true");
              } else if (
                activeLoanOfferDetails === "N" ||
                object?.["IS_TRANCHE_OPTED"] === "N"
              ) {
                sessionStorage.setItem("payment-failure", "true");
              }
            } else if (
              contextData?.currentOfferPopup === "EMI_OVERDUE_CAMPAIGN"
            ) {
              //       console.log(contextData?.currentOfferPopup,
              // "contextData?.currentOfferPopup"
              //       );
              sessionStorage.setItem("emi-payment-failure", "true");

              if (
                sessionStorage.getItem("emi-phase1-payment-edge-case-failed")
              ) {
                sessionStorage.setItem("emi-payment-edge-case-failed", "true");
              }
              // if (contextData?.emiOverdueOfferRes?.emiOfferCase === "EMI Normalized") {
              //   if(contextData?.emiOverdueOfferRes?.emiOdbcWaiverAmount === 0){
              //     sessionStorage.setItem("greyed-out", "true");
              //   }else if(contextData?.emiOverdueOfferRes?.emiOdbcWaiverAmount > 0){
              //     sessionStorage.setItem("phase1", "true");
              //   }
              // } else if (contextData?.emiOverdueOfferRes?.emiOfferCase === "EMI Not Normalized") {
              //   sessionStorage.setItem("not-normalised", "true");
              // }
            } else if (contextData?.currentOfferPopup === "ODBC_CAMPAIGN") {
              //       console.log(contextData?.currentOfferPopup,
              // "contextData?.currentOfferPopup"
              //       );
              sessionStorage.setItem("odbc-payment-failure", "true");
            } else if (contextData?.currentOfferPopup === "NON_OFFER") {
              // console.log(contextData?.currentOfferPopup,"offer_popup");
              dispatchData({
                type: STOREDATA,
                payload: true,
                name: "callpgs",
              });
              sessionStorage.setItem("phase1-payment-failure", "true");
              // let getObjectData = contextData?.eventDataPostPayment?.map(
              //   (ele) => {
              //     if (
              //       ele?.accountNumber ===
              //       contextData?.currentActiveData?.accountNumber
              //     ) {
              //       ele.paymentStatus = "N";
              //       return ele;
              //     } else {
              //       return ele;
              //     }
              //   }
              // );
              // dispatchData({
              //   name: "eventDataPostPayment",
              //   payload: getObjectData,
              //   type: STOREDATA,
              // });
            }

            dispatchData({
              type: STOREDATA,
              payload: true,
              name: "loaderFlag",
            });
            const fetchData = async () => {
              const retailContractNumbers = [];
              // Extracting retail contract numbers from user data
              contextData.getUserDataV3?.body?.contracts.forEach((item) => {
                if (item?.system === "retail") {
                  retailContractNumbers.push(item.CONTRACT_NUMBER);
                }
              });

              try {
                const offerDataResponse =
                  await LoginServiceObject.offerCollectionDetails(
                    contextData.authToken,
                    retailContractNumbers
                  );
                // console.log(offerDataResponse, "offerDataResponse");
                dispatchData({
                  type: STOREDATA,
                  name: "offers",
                  payload: offerDataResponse,
                });
                if (contextData?.currentOfferPopup === "NON_OFFER") {
                  setFinalPayOptClickObj(
                    contextData.uniqueTransactionId,
                    "paymentStatusRedirection",
                    "N"
                  );
                  dispatchData({
                    name: "latestPaymentData",
                    payload: {
                      phase: 1,
                      labelName: contextData?.phase1PaymentPreDataLabel,
                      amount: contextData?.uniqueTransactionId
                        ? contextData?.approvedLoanAmounts
                          ? contextData?.approvedLoanAmounts?.approvedLoanData[
                              contextData.currentActiveData.accountNumber
                            ] &&
                            contextData?.approvedLoanAmounts?.approvedLoanData[
                              contextData.currentActiveData.accountNumber
                            ]?.approvedAmountValue
                          : contextData?.prePaymentLabelDetails[
                              contextData?.activePhase1PaymentLabel
                            ]?.validAmount
                        : "",
                    },
                    type: STOREDATA,
                  });
                } else if (
                  contextData?.currentOfferPopup === "SETTLEMENT_CAMPAIGN"
                ) {
                  const updatedOfferDetails =
                    offerDataResponse?.body?.offerDetails?.find(
                      (ele) =>
                        ele?.LOAN_ACCOUNT_NO ===
                        contextData?.currentActiveData?.accountNumber
                    );
                  // console.log(updatedOfferDetails, "updatedOfferDetails");
                  if (updatedOfferDetails?.["IS_TRANCHE_OPTED"] === "Y") {
                    if (contextData?.paymentTrancheNumberData == 1) {
                      if (updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"]) {
                        if (
                          updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                          "Success"
                        ) {
                          setFinalPayOptClickObj(
                            contextData.uniqueTransactionId,
                            "paymentStatusRedirection",
                            "Partially Paid"
                          );
                        } else if (
                          updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                          "Failed"
                        ) {
                          setFinalPayOptClickObj(
                            contextData.uniqueTransactionId,
                            "paymentStatusRedirection",
                            "Unpaid"
                          );
                        } else if (
                          updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                          "INITIATED"
                        ) {
                          setFinalPayOptClickObj(
                            contextData.uniqueTransactionId,
                            "paymentStatusRedirection",
                            "Unpaid"
                          );
                        }
                      } else {
                        setFinalPayOptClickObj(
                          contextData.uniqueTransactionId,
                          "paymentStatusRedirection",
                          "Unpaid"
                        );
                      }
                    } else if (contextData?.paymentTrancheNumberData == 2) {
                      if (updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"]) {
                        if (
                          updatedOfferDetails?.["TRANCHE1_PAYMENT_STATUS"] ===
                          "Success"
                        ) {
                          if (
                            updatedOfferDetails?.["TRANCHE2_PAYMENT_STATUS"]
                          ) {
                            if (
                              updatedOfferDetails?.[
                                "TRANCHE2_PAYMENT_STATUS"
                              ] === "INITIATED"
                            ) {
                              setFinalPayOptClickObj(
                                contextData.uniqueTransactionId,
                                "paymentStatusRedirection",
                                "Partially Paid"
                              );
                            } else if (
                              updatedOfferDetails?.[
                                "TRANCHE2_PAYMENT_STATUS"
                              ] === "Success"
                            ) {
                              setFinalPayOptClickObj(
                                contextData.uniqueTransactionId,
                                "paymentStatusRedirection",
                                "Paid"
                              );
                            } else if (
                              updatedOfferDetails?.[
                                "TRANCHE2_PAYMENT_STATUS"
                              ] === "Failed"
                            ) {
                              setFinalPayOptClickObj(
                                contextData.uniqueTransactionId,
                                "paymentStatusRedirection",
                                "Partially Paid"
                              );
                            }
                          } else {
                            setFinalPayOptClickObj(
                              contextData.uniqueTransactionId,
                              "paymentStatusRedirection",
                              "Partially Paid"
                            );
                          }
                        }
                      } else {
                        setFinalPayOptClickObj(
                          contextData.uniqueTransactionId,
                          "paymentStatusRedirection",
                          "Unpaid"
                        );
                      }
                    }
                  } else if (
                    updatedOfferDetails?.["IS_TRANCHE_OPTED"] === "N"
                  ) {
                    setFinalPayOptClickObj(
                      contextData.uniqueTransactionId,
                      "paymentStatusRedirection",
                      "Unpaid"
                    );
                  }

                  dispatchData({
                    name: "latestPaymentData",
                    payload: {
                      phase: 2,
                      labelName: "",
                      amount: "",
                    },
                    type: STOREDATA,
                  });
                }
              } catch (error) {
                console.error("Error fetching offer data:", error);
              }
            };

            // Fetch offer data
            fetchData();
            // sessionStorage.setItem("pay", true);
            setModalType("failure");
            dispatchData({
              type: STOREDATA,
              payload: false,
              name: "loaderFlag",
            });
            setModalPopUp(true);
            setPaymentPopup(true);
            window.history.replaceState({}, "Page", setUrl);
          } else if (parameter["subsrc"] == "pendding") {
            setModalType("pendding");
            setModalPopUp(true);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (preapprovedOfferFlag) {
      setCallpgs(false);
    } else {
      setCallpgs(true);
    }
  }, [preapprovedOfferFlag, selectedLabel]);

  const handleInput = (event) => {
    setPaymentCardArr((prevState) => {
      return prevState.map((i) => {
        if (i.labelName === "Other Amount") {
          i.amount = event.target.value;
          dispatchData({
            name: "prePaymentLabelDetails",
            payload: {
              ...contextData.prePaymentLabelDetails,
              "Other Amount": {
                ...contextData.prePaymentLabelDetails["Other Amount"],
                validAmount: event.target.value,
              },
            },
            type: "STOREDATA",
          });

          return i;
        } else {
          return i;
        }
      });
    });
  };

  const removeCommas = (str) => {
    // Remove commas from the string
    const result = str.split(",").join("");
    return result;
  };

  const handleAmount = (e) => {
    if (e.target.value.length === 1 && e.target.value === ".") {
      e.preventDefault();
      return;
    }

    // Allow only numbers, one decimal point, and commas
    let value = e.target.value.replace(/[^\d.,]/g, "");

    let removeCommVar = value;

    // Remove leading zeros
    value = value.replace(/^0+(?=\d)/, "");

    // Replace multiple decimals with a single one
    value = value.replace(/(\.[^\d]*)\./g, "$1");

    // Ensure only one decimal point is present
    const parts = value.split(".");
    if (parts.length > 1) {
      value = parts[0] + "." + parts[1].slice(0, 2);
    }

    const modifiedNumber = removeCommas(removeCommVar);

    if (modifiedNumber.length == 0) {
      setPopOverFlag(true);
      setPayNowFlag(true);
    } else if (modifiedNumber.length !== 0) {
      setPopOverFlag(true);
      setPayNowFlag(true);
    }
    setIsChecked(false);
    // console.log(fcAmount, "FCAMOUNT");
    let numericFcVal = fcAmount.replace(/,/g, "");
    let formattedFcVal = value.replace(/,/g, "");
    // console.log(numericFcVal, "numericFcVal");
    // console.log(formattedFcVal, "formattedFcVal");

    let newNumricVal = numericFcVal.replaceAll(",", "");
    let newFormattedFcVal = formattedFcVal.replaceAll(",", "");

    const formattedFcAmountVal = numericFcVal.toLocaleString("en-IN");
    // console.log(formattedFcAmountVal, "formattedFcAmountVal");

    let formattedAmount = value.toLocaleString("en-IN");
    // console.log(formattedAmount, "formattedAmount");

    const parsedValue = parseFloat(formattedAmount.replaceAll(",", ""));

    if (!isNaN(parsedValue)) {
      const updatedFormattedNumber = Number(parsedValue).toLocaleString(
        "en-IN",
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }
      );
      // console.log(updatedFormattedNumber,"updatedFormattedNumber");
      setFcToolTip(fcStaticAmount);
      // setAmount(e.target.value.toLocaleString("en-IN"));
      setChangeInpAmount(updatedFormattedNumber);
      setChangeFcSettlement();
      setAmount(formattedAmount);

      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      // Step 1: Remove commas and parse strings into numbers
      const number1 = parseFloat(newNumricVal.replace(/,/g, ""));

      const number2 = parseFloat(newFormattedFcVal.replace(/,/g, ""));

      // Step 2: Subtract the second number from the first
      const result = number1 - number2;
      // console.log(result, "result");
      // Step 3: Format the result back to a string with commas
      const formattedResult = numberWithCommas(result.toFixed(2));
      let newchangeval = parseFloat(formattedResult.replace(/,/g, ""));
      if (parseFloat(formattedFcVal) === parseFloat(numericFcVal)) {
        setPayNowFlag(false);
        setDisableBtn(false);
      } else {
        setPayNowFlag(true);
        setDisableBtn(true);
      }

      if (!isNaN(newchangeval)) {
        // Format the parsed value and update the state
        const updatedFormattedNumber =
          Number(newchangeval).toLocaleString("en-IN");

        // console.log(updatedFormattedNumber, "updatedFormattedNumber");

        setRemainingAmount(updatedFormattedNumber);
        setRemainingAmountToolTip(updatedFormattedNumber);

        setChangeRemainAmount(!changeRemainAmount);

        getSignOfRemainingValue(result, value);

        // Use a functional update to ensure the state is set correctly
        setPayNowFlag((prevPayNowFlag) => {
          const newPayNowFlag =
            formattedFcVal !== numericFcVal &&
            formattedFcVal > 0 &&
            formattedFcVal <= numericFcVal
              ? false
              : true;
          return newPayNowFlag;
        });

        setDisableBtn((prevDisableBtn) => {
          const newDisableBtn = formattedFcVal === numericFcVal ? false : true;
          return newDisableBtn;
        });

        if (formattedFcVal === "" || formattedFcVal === NaN) {
          setAmount("");
          setPopOverFlag(false);
          setIsChecked(false);
          setFcZeroValid(false);
        }

        if (formattedFcVal == 0) {
          setPopOverFlag(true);
          setPayNowFlag(true);
          setFcZeroValid(true);
        } else {
          setFcZeroValid(false);
        }

        if (formattedFcVal.length == 0) {
          setPayNowFlag(true);
          setDisableBtn(true);
        }

        if (parseFloat(formattedFcVal) !== parseFloat(numericFcVal)) {
          if (formattedFcVal > 0 && formattedFcVal <= numericFcVal) {
            setPopOverFlag(true);
            setPayNowFlag(true);
            setDisableBtn(true);
            setFcZeroValid(false);
          }
        }

        if (parseFloat(formattedFcVal) == parseFloat(numericFcVal)) {
          if (validateFlagEmail || validateFlagMobile) {
            setPayNowFlag(true);
            setDisableBtn(true);
            setFcZeroValid(false);
          } else {
            setPopOverFlag(false);
            setPayNowFlag(false);
            setDisableBtn(false);
            setFcZeroValid(false);
          }
        }
        // console.log(payNowFlag, "popOverFlag");
        // console.log(disableBtn, "disableBtn");
      }
    } else {
      setRemainingAmount("");
      setPopOverFlag(false);
      setPayNowFlag(true);
      setDisableBtn(true);
    }

    // setAmount(value);

    // if (contextData?.currentOfferPopup === "SETTLEMENT_CAMPAIGN") {
    //   setPayNowFlag(true);
    // } else if (contextData?.currentOfferPopup === "EMI_OVERDUE_CAMPAIGN") {
    //   setPayNowFlag(false);
    // }

    // getSignOfRemainingValue(remainingAmount);

    if (e.target.value.length === 0) {
      setAmount("");
      setRemainingAmount("");
      setRemainingAmountToolTip("");
    }

    // }
  };

  const onBlur = (e) => {
    if (typeof e.target.value === "string") {
      // Check if the value is not an empty string
      if (e.target.value.trim() !== "") {
        // Remove commas from the value and parse it as a float
        const parsedValue = parseFloat(e.target.value.replaceAll(",", ""));

        // Check if the parsed value is a valid number
        if (!isNaN(parsedValue)) {
          // Format the parsed value and update the state
          setAmount(
            Number(parsedValue).toLocaleString("en-IN", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
          );
        }
      }
    }
  };

  function getSignOfRemainingValue(remainingAmount, enteredValue) {
    // console.log(remainingAmount, "asasasas");
    // console.log(enteredValue, "enteredValue");
    if (remainingAmount < 0) {
      // setRemainingAmountToolTip(remainingAmount);
      setRemainAmountFlag(true);
      setFcValidFlag(true);
    } else if (remainingAmount > 0) {
      // setRemainingAmountToolTip(remainingAmount);
      setRemainAmountFlag(false);
      setFcValidFlag(false);
    }

    if (remainingAmount == 0) {
      setPopOverFlag(false);
      setPayNowFlag(false);
      setFcValidFlag(false);
    }
  }

  const continueConveyFunc = (option) => {
    setModalPopUp(false);
    setConveyPopUp(false);
    setSelectedConveyOption("");

    if (option == "Yes") {
      let enteredOdbcAmount = contextData?.odbcEnteredAmount;
      // console.log(enteredOdbcAmount, "enteredOdbcAmount");

      const loanObjectKey = Object.keys(contextData?.getLoanPositionDTLS).find(
        (key) => key === contextData?.currentActiveData?.accountNumber
      );

      const loanObject = loanObjectKey
        ? contextData?.getLoanPositionDTLS[loanObjectKey]
        : null;

      if (
        contextData?.odbcEnteredAmount ===
        dashPaymentData?.loanDetails[4]?.value
      ) {
        let currentDate = new Date();
        let formattedDate = currentDate.toLocaleDateString("en-GB");
        // console.log(formattedDate, "formattedDate");

        let loanStatus;
        if (loanObject) {
          let terminateDate =
            loanObject?.body?.getLoanPositionDtlsReturn?.terminationDate;
          // console.log(terminateDate, "terminateDate");

          if (formattedDate < terminateDate) {
            loanStatus = "Live";
          } else {
            loanStatus = "Matured";
          }
        }

        LoginServiceObject.calculateODBCWaiverRes(
          contextData,
          dashPaymentData,
          enteredOdbcAmount,
          loanStatus
        )
          .then(async (getDataResponse) => {
            if (getDataResponse.body.message === "Offer Approved") {
              // console.log(getDataResponse, "getDataResponse1");
              dispatchData({
                name: "odbcOfferRes",
                payload: {
                  odbcOfferCase: getDataResponse.body.message,
                  odbcEnteredAmount: enteredOdbcAmount,
                  odbcWaiverAmount: getDataResponse.body.finalOdbcAmount,
                  conveyOption: "Yes",
                },
                type: STOREDATA,
              });

              //show modal for 10 secconds using setTimeOut function
              setModalPopUp(true);
              setShowOdbcWaiverGrid(true); // Show modal

              // Disable click events

              // After 10 seconds, hide the modal and re-enable click events
              setTimeout(() => {
                setShowOdbcWaiverGrid(false);
                dispatchData({
                  name: "loaderFlag",
                  payload: true,
                  type: STOREDATA,
                });
              }, 10000);

              // dispatchData({
              //   name: "loaderFlag",
              //   payload: true,
              //   type: STOREDATA,
              // });

              // After 10 seconds runs this below code

              let currentOffer = contextData?.currentOfferPopup;

              let totalOverdueAmountPgs =
                dashPaymentData?.loanDetails[3]?.value +
                dashPaymentData?.loanDetails[4]?.value;
              // console.log(totalOverdueAmountPgs, "totalOverdueAmountPgs");
              let userobj = {
                mobile: paymentForm?.value?.mobile,
                email: paymentForm?.value?.email,
              };

              pgsApiCallFn(
                contextData,
                userobj,
                dispatchData,
                getDataResponse.body.finalOdbcAmount,
                journeyType,
                "",
                "",
                currentOffer,
                "",
                dashPaymentData?.loanDetails[3]?.value,
                dashPaymentData?.loanDetails[4]?.value,
                totalOverdueAmountPgs
              )
                .then((uniqueTransactionId) => {
                  // console.log(uniqueTransactionId, "uniqueTransactionId");
                  dispatchData({
                    name: "uniqueTransactionId",
                    type: STOREDATA,
                    payload: uniqueTransactionId,
                  });
                  // setPaymentOptClickCount(() => ({}));
                  // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
                  dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA,
                  });
                })
                .catch((err) => {
                  console.log(err);
                  dispatchData({
                    name: "loaderFlag",
                    type: STOREDATA,
                    payload: false,
                  });

                  setFailurePopUpModal(true);
                  setFailurePopUpContent({
                    apiName: "",
                    message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
                  });
                });
            } else if (getDataResponse.body.message === "Offer Rejected") {
              console.log(getDataResponse, "getDataResponse2");
              console.log("Offer Rejected");

              dispatchData({
                name: "odbcOfferRes",
                payload: {
                  odbcOfferCase: getDataResponse.body.message,
                  odbcEnteredAmount: enteredOdbcAmount,
                  odbcWaiverAmount: getDataResponse.body.finalODBCAmount,
                },
                type: STOREDATA,
              });

              // dispatchData({
              //   name: "odbcOfferRes",
              //   payload: {
              //     odbcOfferCase: getDataResponse.body.message,
              //     odbcEnteredAmount: numericOdbc,
              //     odbcWaiverAmount: getDataResponse.body.finalODBCAmount,
              //   },
              //   type: STOREDATA,
              // });
              // dispatchData({
              //   name: "loaderFlag",
              //   payload: false,
              //   type: STOREDATA,
              // });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else if (
        contextData?.odbcEnteredAmount <
        loanObject?.body?.getLoanPositionDtlsReturn?.overdueChargeAmount
      ) {
        dispatchData({
          name: "odbcOfferRes",
          payload: {
            odbcOfferCase: "Offer Rejected",
            odbcEnteredAmount: enteredOdbcAmount,
            odbcWaiverAmount: 0,
          },
          type: STOREDATA,
        });
        setModalPopUp(false);
        setConveyPopUp(false);
        dispatchData({
          name: "loaderFlag",
          payload: true,
          type: STOREDATA,
        });

        let userobj = {
          amount: enteredOdbcAmount,
          mobile: paymentForm?.value?.mobile,
          email: paymentForm?.value?.email,
        };

        pgsApiCallFn(
          contextData,
          userobj,
          dispatchData,
          enteredOdbcAmount,
          "non-offer",
          "",
          "",
          "",
          "",
          "",
          ""
        )
          .then((uniqueTransactionId) => {
            // console.log(uniqueTransactionId, "uniqueTransactionId");
            dispatchData({
              name: "uniqueTransactionId",
              type: STOREDATA,
              payload: uniqueTransactionId,
            });
            // setPaymentOptClickCount(() => ({}));
            // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA,
            });
          })
          .catch((err) => {
            console.log(err);
            dispatchData({
              name: "loaderFlag",
              type: STOREDATA,
              payload: false,
            });

            setFailurePopUpModal(true);
            setFailurePopUpContent({
              apiName: "",
              message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
            });
          });
      }

      dispatchData({
        name: "activePaymentLoanData",
        payload: {
          loanAccNumber: contextData.currentActiveData.accountNumber,
          activePaymentFlag: true,
        },
        type: STOREDATA,
      });
    } else if (option == "No") {
      setModalPopUp(false);
      setConveyPopUp(false);
      setAmount(fcAmount);
      // setAmount(dashPaymentData.loanDetails[4].value.toString().split(".")[0]);
    } else if (option == "ForeGo") {
      let currentOffer = contextData?.currentOfferPopup;

      let enteredOdbcAmount = contextData?.odbcEnteredAmount;
      // console.log(enteredOdbcAmount, "enteredOdbcAmount");

      let totalOverdueAmountPgs =
        dashPaymentData?.loanDetails[3]?.value +
        dashPaymentData?.loanDetails[4]?.value;
      // console.log(totalOverdueAmountPgs,"totalOverdueAmountPgs");

      dispatchData({
        name: "odbcOfferRes",
        payload: {
          odbcOfferCase: "Offer Rejected",
          odbcEnteredAmount: enteredOdbcAmount,
          odbcWaiverAmount: 0,
          conveyOption: "ForeGo",
        },
        type: STOREDATA,
      });
      setModalPopUp(false);
      setConveyPopUp(false);
      dispatchData({
        name: "loaderFlag",
        payload: true,
        type: STOREDATA,
      });

      let userobj = {
        amount: enteredOdbcAmount,
        mobile: paymentForm?.value?.mobile,
        email: paymentForm?.value?.email,
      };

      pgsApiCallFn(
        contextData,
        userobj,
        dispatchData,
        enteredOdbcAmount,
        "offer",
        "",
        "",
        currentOffer,
        "",
        "",
        dashPaymentData?.loanDetails[4]?.value,
        totalOverdueAmountPgs
      )
        .then((uniqueTransactionId) => {
          // console.log(uniqueTransactionId, "uniqueTransactionId");
          dispatchData({
            name: "uniqueTransactionId",
            type: STOREDATA,
            payload: uniqueTransactionId,
          });
          // setPaymentOptClickCount(() => ({}));
          // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatchData({
            name: "loaderFlag",
            type: STOREDATA,
            payload: false,
          });

          setFailurePopUpModal(true);
          setFailurePopUpContent({
            apiName: "",
            message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
          });
        });
    }
  };

  const handleClick = () => {
    // Ensure paymentForm and its control property exist
    if (!paymentForm || !paymentForm.control) {
      return;
    }

    if (validateFlagMobile && showAdditionValid) {
      return;
    }

    // Check mobile value
    if (paymentForm.control.mobile && paymentForm.control.mobile.value !== "") {
      // setValidateFlagMobile(true);
      if (!paymentForm.control.mobile.valid) {
        return;
      }
    }

    // Check email value
    if (paymentForm.control.email && paymentForm.control.email.value !== "") {
      // setValidateFlagEmail(true);
      if (!paymentForm.control.email.valid) {
        return;
      }
    }

    // if (paymentForm?.control?.mobile?.value != "") {
    //   setValidateFlagMobile(true);
    //   if (!paymentForm?.control?.mobile?.valid) {
    //     return;
    //   }
    // }

    // if (paymentForm?.control?.email?.value != "") {
    //   setValidateFlagEmail(true);
    //   if (!paymentForm?.control?.email?.valid) {
    //     return;
    //   }
    // }

    const getofferKey = (loan) => {
      const keysToCheck = [
        "SETTLEMENT_CAMPAIGN",
        "EMI_OVERDUE_CAMPAIGN",
        "ODBC_CAMPAIGN",
        "LOS_POOL_CAMPAIGN",
        "ALREADY_SETTLED_POOL_CAMPAIGN",
      ];

      for (const key of keysToCheck) {
        if (loan[key] === "Y") {
          return key;
        }
      }
      return null;
    };

    // dispatchData({
    //   name: "eventDataPostPayment",
    //   payload: contextData?.eventUserDetails,
    //   type: STOREDATA,
    // });

    setAmount("");
    if (callpgs) {
      dispatchData({
        name: "paymentRedirectionAccountNumber",
        payload: contextData?.currentActiveData?.accountNumber,
        type: STOREDATA,
      });
      console.log("phase1");
      let selectedPaymentDetails = paymentCardArr.find(
        (i) => i.labelName === selectedLabel
      );
      // console.log(selectedPaymentDetails, "selectedPaymentDetails");

      const totalOverdueAmountPgs = paymentCardArr.find(
        (i) => i.labelName === "Total Overdue Amount"
      );

      //   console.log(totalOverdueAmountPgs?.amount,"totalOverdueAmountPgs");
      let amount = Number(selectedPaymentDetails.amount).toLocaleString(
        "en-IN"
      );

      const numericAmount = selectedPaymentDetails.amount;

      // console.log(dashPaymentData, "dashg");

      //   const emioverdueObject = dashPaymentData.loanDetails.find(
      //     (item) => item.name === "EMI OVERDUE AMOUNT"
      //   );

      //   const chargesoverdueObject = dashPaymentData.loanDetails.find(
      //     (item) => item.name === "CHARGES OVERDUE AMOUNT"
      //   );

      //   const totalOverdueAmountPgs =
      //     emioverdueObject?.value + chargesoverdueObject?.value;

      // console.log(selectedPaymentDetails, "selectedPaymentDetails");
      // console.log(
      //   selectedPaymentDetails.amount,
      //   "selectedPaymentDetails.amount)"
      // );
      // console.log(numericAmount, "numericAmount");
      const object = contextData?.offers?.body?.offerDetails.filter((ele) => {
        return (
          ele?.LOAN_ACCOUNT_NO === contextData?.currentActiveData?.accountNumber
        );
      });

      // console.log(object, "object");
      // console.log(object.length, "object.length");

      // let currentOfferName = getofferKey(object[0]);
      // console.log(currentOfferName, "currentOfferName");
      if (object.length == 0) {
        console.log("1");
        if (selectedPaymentDetails) {
          let userobj = {
            ...selectedPaymentDetails,
            mobile: paymentForm.value.mobile,
            email: paymentForm.value.email,
          };

          dispatchData({
            name: "paymentAmount",
            type: STOREDATA,
            payload: amount,
          });
          // dispatchData({
          //   name: "approvedAmount",
          //   payload: amount,
          //   type: STOREDATA,
          // });

          const currentLoanApprovedAmount =
            contextData?.approvedLoanAmounts?.approvedLoanData || {};

          if (
            currentLoanApprovedAmount[
              contextData.currentActiveData.accountNumber
            ]
          ) {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: amount,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          } else {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: amount,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          }

          if (
            selectedPaymentDetails.amount <= 0 ||
            Number(selectedPaymentDetails.amount) >
              selectedPaymentDetails.validAmount
          ) {
            setFailurePopUpModal(true);
            setFailurePopUpContent({
              apiName: "",
              message: `Please enter amount between ₹ 1/- to ₹ ${selectedPaymentDetails.validAmount?.toLocaleString(
                "en-IN"
              )}/-`,
            });
          } else {
            dispatchData({
              name: "loaderFlag",
              type: STOREDATA,
              payload: true,
            });
            dispatchData({
              name: "activePaymentLoanData",
              payload: {
                loanAccNumber: contextData.currentActiveData.accountNumber,
                activePaymentFlag: true,
                isTrancheOpted: "",
              },
              type: STOREDATA,
            });
            dispatchData({
              name: "currentOfferPopup",
              payload: "NON_OFFER",
              type: STOREDATA,
            });

            dispatchData({
              name: "phase1PaymentPreDataLabel",
              payload: contextData?.activePhase1PaymentLabel,
              type: STOREDATA,
            });

            pgsApiCallFn(
              contextData,
              userobj,
              dispatchData,
              totalOverdueAmountPgs?.amount,
              "non-offer"
            )
              .then((uniqueTransactionId) => {
                handleClickForPaymentOpt(
                  "handlePaymentGridTransactionId",
                  "",
                  uniqueTransactionId,
                  "",
                  contextData.currentActiveData.accountNumber
                );
                dispatchData({
                  name: "uniqueTransactionId",
                  type: STOREDATA,
                  payload: uniqueTransactionId,
                });
                setPaymentOptClickCount(() => ({}));
                setFinalPayOptClickObj(
                  uniqueTransactionId,
                  "paymentOptionClick"
                );
                // dispatchData({
                //     name: "loaderFlag",
                //     type: STOREDATA,
                //     payload: false,
                // });
              })
              .catch((err) => {
                console.log(err);
                dispatchData({
                  name: "loaderFlag",
                  type: STOREDATA,
                  payload: false,
                });
                setFailurePopUpModal(true);
                setFailurePopUpContent({
                  apiName: "",
                  message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
                });
              });
          }
        }
      } else {
        // -------Only phase code for payment redirection:------//

        if (selectedPaymentDetails) {
          let userobj = {
            ...selectedPaymentDetails,
            mobile: paymentForm.value.mobile,
            email: paymentForm.value.email,
          };

          dispatchData({
            name: "paymentAmount",
            type: STOREDATA,
            payload: amount,
          });
          // dispatchData({
          //   name: "approvedAmount",
          //   payload: amount,
          //   type: STOREDATA,
          // });

          const currentLoanApprovedAmount =
            contextData?.approvedLoanAmounts?.approvedLoanData || {};

          if (
            currentLoanApprovedAmount[
              contextData.currentActiveData.accountNumber
            ]
          ) {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: amount,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          } else {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: amount,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          }

          if (
            selectedPaymentDetails.amount <= 0 ||
            Number(selectedPaymentDetails.amount) >
              selectedPaymentDetails.validAmount
          ) {
            setFailurePopUpModal(true);
            setFailurePopUpContent({
              apiName: "",
              message: `Please enter amount between ₹ 1/- to ₹ ${selectedPaymentDetails.validAmount?.toLocaleString(
                "en-IN"
              )}/-`,
            });
          } else {
            dispatchData({
              name: "loaderFlag",
              type: STOREDATA,
              payload: true,
            });
            dispatchData({
              name: "activePaymentLoanData",
              payload: {
                loanAccNumber: contextData.currentActiveData.accountNumber,
                activePaymentFlag: true,
                isTrancheOpted: "",
              },
              type: STOREDATA,
            });
            dispatchData({
              name: "currentOfferPopup",
              payload: "NON_OFFER",
              type: STOREDATA,
            });

            dispatchData({
              name: "phase1PaymentPreDataLabel",
              payload: contextData?.activePhase1PaymentLabel,
              type: STOREDATA,
            });

            pgsApiCallFn(
              contextData,
              userobj,
              dispatchData,
              totalOverdueAmountPgs?.amount,
              "non-offer"
            )
              .then((uniqueTransactionId) => {
                handleClickForPaymentOpt(
                  "handlePaymentGridTransactionId",
                  "",
                  uniqueTransactionId,
                  "",
                  contextData.currentActiveData.accountNumber
                );
                dispatchData({
                  name: "uniqueTransactionId",
                  type: STOREDATA,
                  payload: uniqueTransactionId,
                });
                setPaymentOptClickCount(() => ({}));
                setFinalPayOptClickObj(
                  uniqueTransactionId,
                  "paymentOptionClick"
                );
                // dispatchData({
                //     name: "loaderFlag",
                //     type: STOREDATA,
                //     payload: false,
                // });
              })
              .catch((err) => {
                console.log(err);
                dispatchData({
                  name: "loaderFlag",
                  type: STOREDATA,
                  payload: false,
                });
                setFailurePopUpModal(true);
                setFailurePopUpContent({
                  apiName: "",
                  message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
                });
              });
          }
        }

        //------May be later EMI Overdue Campaign Code -------//

        // let getOfferDetails = object[0];
        // console.log(getOfferDetails["SETTLEMENT_CAMPAIGN"], "object");
        // console.log("2");
        // if (object[0]?.["SETTLEMENT_CAMPAIGN"] == "Y") {
        //   if (object[0]?.["IS_EMI_NORMALIZED"] == "Y") {
        //     if (object[0]?.["IS_PAYMENT_DONE"] == "Y") {
        //       if (selectedPaymentDetails) {
        //         let userobj = {
        //           ...selectedPaymentDetails,
        //           mobile: paymentForm.value.mobile,
        //           email: paymentForm.value.email,
        //         };

        //         dispatchData({
        //           name: "paymentAmount",
        //           type: STOREDATA,
        //           payload: amount,
        //         });
        //         // dispatchData({
        //         //   name: "approvedAmount",
        //         //   payload: amount,
        //         //   type: STOREDATA,
        //         // });

        //         const currentLoanApprovedAmount =
        //           contextData?.approvedLoanAmounts?.approvedLoanData || {};

        //         if (
        //           currentLoanApprovedAmount[
        //             contextData.currentActiveData.accountNumber
        //           ]
        //         ) {
        //           const updatedLoanApprovalData = {
        //             ...currentLoanApprovedAmount,
        //             [contextData.currentActiveData.accountNumber]: {
        //               approvedAmountValue: amount,
        //             },
        //           };

        //           dispatchData({
        //             name: "approvedLoanAmounts",
        //             payload: {
        //               approvedLoanData: updatedLoanApprovalData,
        //             },
        //             type: STOREDATA,
        //           });
        //         } else {
        //           const updatedLoanApprovalData = {
        //             ...currentLoanApprovedAmount,
        //             [contextData.currentActiveData.accountNumber]: {
        //               approvedAmountValue: amount,
        //             },
        //           };

        //           dispatchData({
        //             name: "approvedLoanAmounts",
        //             payload: {
        //               approvedLoanData: updatedLoanApprovalData,
        //             },
        //             type: STOREDATA,
        //           });
        //         }

        //         if (
        //           selectedPaymentDetails.amount <= 0 ||
        //           Number(selectedPaymentDetails.amount) >
        //             selectedPaymentDetails.validAmount
        //         ) {
        //           setFailurePopUpModal(true);
        //           setFailurePopUpContent({
        //             apiName: "",
        //             message: `Please enter amount between ₹ 1/- to ₹ ${selectedPaymentDetails.validAmount?.toLocaleString(
        //               "en-IN"
        //             )}/-`,
        //           });
        //         } else {
        //           dispatchData({
        //             name: "loaderFlag",
        //             type: STOREDATA,
        //             payload: true,
        //           });
        //           dispatchData({
        //             name: "activePaymentLoanData",
        //             payload: {
        //               loanAccNumber:
        //                 contextData.currentActiveData.accountNumber,
        //               activePaymentFlag: true,
        //               isTrancheOpted: "",
        //             },
        //             type: STOREDATA,
        //           });
        //           if (object[0]?.["IS_PAYMENT_DONE"] == "N") {
        //             dispatchData({
        //               name: "currentOfferPopup",
        //               payload: "EMI_OVERDUE_CAMPAIGN",
        //               type: STOREDATA,
        //             });
        //           } else if (object[0]?.["IS_PAYMENT_DONE"] == "Y") {
        //             dispatchData({
        //               name: "currentOfferPopup",
        //               payload: "NON_OFFER",
        //               type: STOREDATA,
        //             });

        //             pgsApiCallFn(
        //               contextData,
        //               userobj,
        //               dispatchData,
        //               totalOverdueAmountPgs,
        //               "non-offer"
        //             )
        //               .then((uniqueTransactionId) => {
        //                 dispatchData({
        //                   name: "uniqueTransactionId",
        //                   type: STOREDATA,
        //                   payload: uniqueTransactionId,
        //                 });
        //                 setPaymentOptClickCount(() => ({}));
        //                 setFinalPayOptClickObj(
        //                   uniqueTransactionId,
        //                   "paymentOptionClick"
        //                 );
        //                 // dispatchData({
        //                 //     name: "loaderFlag",
        //                 //     type: STOREDATA,
        //                 //     payload: false,
        //                 // });
        //               })
        //               .catch((err) => {
        //                 console.log(err);
        //                 dispatchData({
        //                   name: "loaderFlag",
        //                   type: STOREDATA,
        //                   payload: false,
        //                 });
        //                 setFailurePopUpModal(true);
        //                 setFailurePopUpContent({
        //                   apiName: "",
        //                   message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
        //                 });
        //               });
        //           }
        //         }
        //       }
        //     } else if (object[0]?.["IS_PAYMENT_DONE"] == "N") {
        //       dispatchData({
        //         name: "currentOfferPopup",
        //         payload: "EMI_OVERDUE_CAMPAIGN",
        //         type: STOREDATA,
        //       });
        //       if (selectedPaymentDetails?.labelName == "Total Overdue Amount") {
        //         dispatchData({
        //           name: "loaderFlag",
        //           payload: true,
        //           type: STOREDATA,
        //         });
        //         LoginServiceObject.calculateNormaliseEmiOverdueRes(
        //           contextData,
        //           dashPaymentData,
        //           numericAmount
        //         )
        //           .then(async (getDataResponse) => {
        //             if (getDataResponse.body.message === "EMI Normalized") {
        //               console.log(getDataResponse, "getDataResponse1");
        //               if (getDataResponse.body.finalODBCAmount == 0) {
        //                 sessionStorage.setItem("greyed-out", "true");
        //               } else if (getDataResponse.body.finalODBCAmount > 0) {
        //                 sessionStorage.setItem("phase1", "true");
        //               }
        //               sessionStorage.setItem(
        //                 "emi-phase1-payment-edge-case-failed",
        //                 "true"
        //               );
        //               dispatchData({
        //                 name: "emiOverdueOfferRes",
        //                 payload: {
        //                   emiOfferCase: getDataResponse.body.message,
        //                   emiEnteredAmount: numericAmount,
        //                   emiOdbcWaiverAmount:
        //                     getDataResponse.body.finalODBCAmount,
        //                   emiOfferKey:
        //                     getDataResponse.body.finalODBCAmount == 0
        //                       ? "greyed-out"
        //                       : "phase1",
        //                 },
        //                 type: STOREDATA,
        //               });
        //             } else if (
        //               getDataResponse.body.message === "EMI Not Normalized"
        //             ) {
        //               console.log(getDataResponse, "getDataResponse2");
        //               sessionStorage.setItem("not-normalised", "true");
        //               sessionStorage.setItem(
        //                 "emi-payment-edge-case-not-normalised",
        //                 "true"
        //               );
        //               dispatchData({
        //                 name: "emiOverdueOfferRes",
        //                 payload: {
        //                   emiOfferCase: getDataResponse.body.message,
        //                   emiEnteredAmount: numericAmount,
        //                   emiOdbcWaiverAmount:
        //                     getDataResponse.body.finalODBCAmount,
        //                   emiOfferKey: "not-normalised",
        //                 },
        //                 type: STOREDATA,
        //               });
        //             }
        //           })
        //           .catch((error) => {
        //             console.error("Error:", error);
        //           });

        //         let userobj = {
        //           mobile: paymentForm?.value?.mobile,
        //           email: paymentForm?.value?.email,
        //         };

        //         // dispatchData({
        //         //   name: "approvedAmount",
        //         //   payload: numericAmount,
        //         //   type: STOREDATA,
        //         // });

        //         const currentLoanApprovedAmount =
        //           contextData?.approvedLoanAmounts?.approvedLoanData || {};

        //         if (
        //           currentLoanApprovedAmount[
        //             contextData.currentActiveData.accountNumber
        //           ]
        //         ) {
        //           const updatedLoanApprovalData = {
        //             ...currentLoanApprovedAmount,
        //             [contextData.currentActiveData.accountNumber]: {
        //               approvedAmountValue: numericAmount,
        //             },
        //           };

        //           dispatchData({
        //             name: "approvedLoanAmounts",
        //             payload: {
        //               approvedLoanData: updatedLoanApprovalData,
        //             },
        //             type: STOREDATA,
        //           });
        //         } else {
        //           const updatedLoanApprovalData = {
        //             ...currentLoanApprovedAmount,
        //             [contextData.currentActiveData.accountNumber]: {
        //               approvedAmountValue: numericAmount,
        //             },
        //           };

        //           dispatchData({
        //             name: "approvedLoanAmounts",
        //             payload: {
        //               approvedLoanData: updatedLoanApprovalData,
        //             },
        //             type: STOREDATA,
        //           });
        //         }

        //         pgsApiCallFn(
        //           contextData,
        //           userobj,
        //           dispatchData,
        //           numericAmount,
        //           "offer",
        //           "",
        //           "",
        //           "EMI_OVERDUE_CAMPAIGN",
        //           "",
        //           emioverdueObject?.value,
        //           chargesoverdueObject?.value,
        //           totalOverdueAmountPgs
        //         )
        //           .then((uniqueTransactionId) => {
        //             // console.log(uniqueTransactionId, "uniqueTransactionId");
        //             dispatchData({
        //               name: "uniqueTransactionId",
        //               type: STOREDATA,
        //               payload: uniqueTransactionId,
        //             });
        //             // setPaymentOptClickCount(() => ({}));
        //             // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
        //             dispatchData({
        //               name: "loaderFlag",
        //               payload: false,
        //               type: STOREDATA,
        //             });
        //           })
        //           .catch((err) => {
        //             console.log(err);
        //             dispatchData({
        //               name: "loaderFlag",
        //               type: STOREDATA,
        //               payload: false,
        //             });

        //             setFailurePopUpModal(true);
        //             setFailurePopUpContent({
        //               apiName: "",
        //               message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
        //             });
        //           });

        //         dispatchData({
        //           name: "activePaymentLoanData",
        //           payload: {
        //             loanAccNumber: contextData.currentActiveData.accountNumber,
        //             activePaymentFlag: true,
        //           },
        //           type: STOREDATA,
        //         });
        //       } else if (selectedPaymentDetails?.labelName == "EMI Overdue") {
        //         dispatchData({
        //           name: "loaderFlag",
        //           payload: true,
        //           type: STOREDATA,
        //         });
        //         LoginServiceObject.calculateNormaliseEmiOverdueRes(
        //           contextData,
        //           dashPaymentData,
        //           numericAmount
        //         )
        //           .then(async (getDataResponse) => {
        //             if (getDataResponse.body.message === "EMI Normalized") {
        //               console.log(getDataResponse, "getDataResponse1");
        //               if (getDataResponse.body.finalODBCAmount == 0) {
        //                 sessionStorage.setItem("greyed-out", "true");
        //               } else if (getDataResponse.body.finalODBCAmount > 0) {
        //                 sessionStorage.setItem("phase1", "true");
        //               }
        //               sessionStorage.setItem(
        //                 "emi-phase1-payment-edge-case-failed",
        //                 "true"
        //               );
        //               dispatchData({
        //                 name: "emiOverdueOfferRes",
        //                 payload: {
        //                   emiOfferCase: getDataResponse.body.message,
        //                   emiEnteredAmount: numericAmount,
        //                   emiOdbcWaiverAmount:
        //                     getDataResponse.body.finalODBCAmount,
        //                   emiOfferKey:
        //                     getDataResponse.body.finalODBCAmount == 0
        //                       ? "greyed-out"
        //                       : "phase1",
        //                 },
        //                 type: STOREDATA,
        //               });
        //             } else if (
        //               getDataResponse.body.message === "EMI Not Normalized"
        //             ) {
        //               console.log(getDataResponse, "getDataResponse2");
        //               sessionStorage.setItem("not-normalised", "true");
        //               sessionStorage.setItem(
        //                 "emi-payment-edge-case-not-normalised",
        //                 "true"
        //               );
        //               dispatchData({
        //                 name: "emiOverdueOfferRes",
        //                 payload: {
        //                   emiOfferCase: getDataResponse.body.message,
        //                   emiEnteredAmount: numericAmount,
        //                   emiOdbcWaiverAmount:
        //                     getDataResponse.body.finalODBCAmount,
        //                   emiOfferKey: "not-normalised",
        //                 },
        //                 type: STOREDATA,
        //               });
        //             }
        //           })
        //           .catch((error) => {
        //             console.error("Error:", error);
        //           });

        //         let userobj = {
        //           mobile: paymentForm?.value?.mobile,
        //           email: paymentForm?.value?.email,
        //         };

        //         // dispatchData({
        //         //   name: "approvedAmount",
        //         //   payload: numericAmount,
        //         //   type: STOREDATA,
        //         // });

        //         const currentLoanApprovedAmount =
        //           contextData?.approvedLoanAmounts?.approvedLoanData || {};

        //         if (
        //           currentLoanApprovedAmount[
        //             contextData.currentActiveData.accountNumber
        //           ]
        //         ) {
        //           const updatedLoanApprovalData = {
        //             ...currentLoanApprovedAmount,
        //             [contextData.currentActiveData.accountNumber]: {
        //               approvedAmountValue: numericAmount,
        //             },
        //           };

        //           dispatchData({
        //             name: "approvedLoanAmounts",
        //             payload: {
        //               approvedLoanData: updatedLoanApprovalData,
        //             },
        //             type: STOREDATA,
        //           });
        //         } else {
        //           const updatedLoanApprovalData = {
        //             ...currentLoanApprovedAmount,
        //             [contextData.currentActiveData.accountNumber]: {
        //               approvedAmountValue: numericAmount,
        //             },
        //           };

        //           dispatchData({
        //             name: "approvedLoanAmounts",
        //             payload: {
        //               approvedLoanData: updatedLoanApprovalData,
        //             },
        //             type: STOREDATA,
        //           });
        //         }

        //         pgsApiCallFn(
        //           contextData,
        //           userobj,
        //           dispatchData,
        //           numericAmount,
        //           "offer",
        //           "",
        //           "",
        //           "EMI_OVERDUE_CAMPAIGN",
        //           "",
        //           emioverdueObject?.value,
        //           chargesoverdueObject?.value,
        //           totalOverdueAmountPgs
        //         )
        //           .then((uniqueTransactionId) => {
        //             // console.log(uniqueTransactionId, "uniqueTransactionId");
        //             dispatchData({
        //               name: "uniqueTransactionId",
        //               type: STOREDATA,
        //               payload: uniqueTransactionId,
        //             });
        //             // setPaymentOptClickCount(() => ({}));
        //             // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
        //             dispatchData({
        //               name: "loaderFlag",
        //               payload: false,
        //               type: STOREDATA,
        //             });
        //           })
        //           .catch((err) => {
        //             console.log(err);
        //             dispatchData({
        //               name: "loaderFlag",
        //               type: STOREDATA,
        //               payload: false,
        //             });

        //             setFailurePopUpModal(true);
        //             setFailurePopUpContent({
        //               apiName: "",
        //               message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
        //             });
        //           });

        //         dispatchData({
        //           name: "activePaymentLoanData",
        //           payload: {
        //             loanAccNumber: contextData.currentActiveData.accountNumber,
        //             activePaymentFlag: true,
        //           },
        //           type: STOREDATA,
        //         });
        //       } else if (selectedPaymentDetails?.labelName == "Other Amount") {
        //         dispatchData({
        //           name: "loaderFlag",
        //           payload: true,
        //           type: STOREDATA,
        //         });
        //         LoginServiceObject.calculateNormaliseEmiOverdueRes(
        //           contextData,
        //           dashPaymentData,
        //           numericAmount
        //         )
        //           .then(async (getDataResponse) => {
        //             if (getDataResponse.body.message === "EMI Normalized") {
        //               console.log(getDataResponse, "getDataResponse1");
        //               if (getDataResponse.body.finalODBCAmount == 0) {
        //                 sessionStorage.setItem("greyed-out", "true");
        //               } else if (getDataResponse.body.finalODBCAmount > 0) {
        //                 sessionStorage.setItem("phase1", "true");
        //               }
        //               sessionStorage.setItem(
        //                 "emi-phase1-payment-edge-case-failed",
        //                 "true"
        //               );
        //               dispatchData({
        //                 name: "emiOverdueOfferRes",
        //                 payload: {
        //                   emiOfferCase: getDataResponse.body.message,
        //                   emiEnteredAmount: numericAmount,
        //                   emiOdbcWaiverAmount:
        //                     getDataResponse.body.finalODBCAmount,
        //                   emiOfferKey:
        //                     getDataResponse.body.finalODBCAmount == 0
        //                       ? "greyed-out"
        //                       : "phase1",
        //                 },
        //                 type: STOREDATA,
        //               });
        //             } else if (
        //               getDataResponse.body.message === "EMI Not Normalized"
        //             ) {
        //               console.log(getDataResponse, "getDataResponse2");
        //               sessionStorage.setItem("not-normalised", "true");
        //               sessionStorage.setItem(
        //                 "emi-payment-edge-case-not-normalised",
        //                 "true"
        //               );
        //               dispatchData({
        //                 name: "emiOverdueOfferRes",
        //                 payload: {
        //                   emiOfferCase: getDataResponse.body.message,
        //                   emiEnteredAmount: numericAmount,
        //                   emiOdbcWaiverAmount:
        //                     getDataResponse.body.finalODBCAmount,
        //                   emiOfferKey: "not-normalised",
        //                 },
        //                 type: STOREDATA,
        //               });
        //             }
        //           })
        //           .catch((error) => {
        //             console.error("Error:", error);
        //           });

        //         let userobj = {
        //           mobile: paymentForm?.value?.mobile,
        //           email: paymentForm?.value?.email,
        //         };

        //         // dispatchData({
        //         //   name: "approvedAmount",
        //         //   payload: numericAmount,
        //         //   type: STOREDATA,
        //         // });

        //         const currentLoanApprovedAmount =
        //           contextData?.approvedLoanAmounts?.approvedLoanData || {};

        //         if (
        //           currentLoanApprovedAmount[
        //             contextData.currentActiveData.accountNumber
        //           ]
        //         ) {
        //           const updatedLoanApprovalData = {
        //             ...currentLoanApprovedAmount,
        //             [contextData.currentActiveData.accountNumber]: {
        //               approvedAmountValue: numericAmount,
        //             },
        //           };

        //           dispatchData({
        //             name: "approvedLoanAmounts",
        //             payload: {
        //               approvedLoanData: updatedLoanApprovalData,
        //             },
        //             type: STOREDATA,
        //           });
        //         } else {
        //           const updatedLoanApprovalData = {
        //             ...currentLoanApprovedAmount,
        //             [contextData.currentActiveData.accountNumber]: {
        //               approvedAmountValue: numericAmount,
        //             },
        //           };

        //           dispatchData({
        //             name: "approvedLoanAmounts",
        //             payload: {
        //               approvedLoanData: updatedLoanApprovalData,
        //             },
        //             type: STOREDATA,
        //           });
        //         }

        //         pgsApiCallFn(
        //           contextData,
        //           userobj,
        //           dispatchData,
        //           numericAmount,
        //           "offer",
        //           "",
        //           "",
        //           "EMI_OVERDUE_CAMPAIGN",
        //           "",
        //           emioverdueObject?.value,
        //           chargesoverdueObject?.value,
        //           totalOverdueAmountPgs
        //         )
        //           .then((uniqueTransactionId) => {
        //             // console.log(uniqueTransactionId, "uniqueTransactionId");
        //             dispatchData({
        //               name: "uniqueTransactionId",
        //               type: STOREDATA,
        //               payload: uniqueTransactionId,
        //             });
        //             // setPaymentOptClickCount(() => ({}));
        //             // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
        //             dispatchData({
        //               name: "loaderFlag",
        //               payload: false,
        //               type: STOREDATA,
        //             });
        //           })
        //           .catch((err) => {
        //             console.log(err);
        //             dispatchData({
        //               name: "loaderFlag",
        //               type: STOREDATA,
        //               payload: false,
        //             });

        //             setFailurePopUpModal(true);
        //             setFailurePopUpContent({
        //               apiName: "",
        //               message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
        //             });
        //           });

        //         dispatchData({
        //           name: "activePaymentLoanData",
        //           payload: {
        //             loanAccNumber: contextData.currentActiveData.accountNumber,
        //             activePaymentFlag: true,
        //           },
        //           type: STOREDATA,
        //         });
        //       }
        //     }
        //   } else if (object[0]?.["IS_EMI_NORMALIZED"] == "N") {
        //     dispatchData({
        //       name: "currentOfferPopup",
        //       payload: "EMI_OVERDUE_CAMPAIGN",
        //       type: STOREDATA,
        //     });
        //     if (selectedPaymentDetails?.labelName == "Total Overdue Amount") {
        //       dispatchData({
        //         name: "loaderFlag",
        //         payload: true,
        //         type: STOREDATA,
        //       });
        //       LoginServiceObject.calculateNormaliseEmiOverdueRes(
        //         contextData,
        //         dashPaymentData,
        //         numericAmount
        //       )
        //         .then(async (getDataResponse) => {
        //           if (getDataResponse.body.message === "EMI Normalized") {
        //             console.log(getDataResponse, "getDataResponse1");
        //             if (getDataResponse.body.finalODBCAmount == 0) {
        //               sessionStorage.setItem("greyed-out", "true");
        //             } else if (getDataResponse.body.finalODBCAmount > 0) {
        //               sessionStorage.setItem("phase1", "true");
        //             }
        //             sessionStorage.setItem(
        //               "emi-phase1-payment-edge-case-failed",
        //               "true"
        //             );
        //             dispatchData({
        //               name: "emiOverdueOfferRes",
        //               payload: {
        //                 emiOfferCase: getDataResponse.body.message,
        //                 emiEnteredAmount: numericAmount,
        //                 emiOdbcWaiverAmount:
        //                   getDataResponse.body.finalODBCAmount,
        //                 emiOfferKey:
        //                   getDataResponse.body.finalODBCAmount == 0
        //                     ? "greyed-out"
        //                     : "phase1",
        //               },
        //               type: STOREDATA,
        //             });
        //           } else if (
        //             getDataResponse.body.message === "EMI Not Normalized"
        //           ) {
        //             console.log(getDataResponse, "getDataResponse2");
        //             sessionStorage.setItem("not-normalised", "true");
        //             sessionStorage.setItem(
        //               "emi-payment-edge-case-not-normalised",
        //               "true"
        //             );
        //             dispatchData({
        //               name: "emiOverdueOfferRes",
        //               payload: {
        //                 emiOfferCase: getDataResponse.body.message,
        //                 emiEnteredAmount: numericAmount,
        //                 emiOdbcWaiverAmount:
        //                   getDataResponse.body.finalODBCAmount,
        //                 emiOfferKey: "not-normalised",
        //               },
        //               type: STOREDATA,
        //             });
        //           }
        //         })
        //         .catch((error) => {
        //           console.error("Error:", error);
        //         });

        //       let userobj = {
        //         mobile: paymentForm?.value?.mobile,
        //         email: paymentForm?.value?.email,
        //       };

        //       // dispatchData({
        //       //   name: "approvedAmount",
        //       //   payload: numericAmount,
        //       //   type: STOREDATA,
        //       // });

        //       const currentLoanApprovedAmount =
        //         contextData?.approvedLoanAmounts?.approvedLoanData || {};

        //       if (
        //         currentLoanApprovedAmount[
        //           contextData.currentActiveData.accountNumber
        //         ]
        //       ) {
        //         const updatedLoanApprovalData = {
        //           ...currentLoanApprovedAmount,
        //           [contextData.currentActiveData.accountNumber]: {
        //             approvedAmountValue: numericAmount,
        //           },
        //         };

        //         dispatchData({
        //           name: "approvedLoanAmounts",
        //           payload: {
        //             approvedLoanData: updatedLoanApprovalData,
        //           },
        //           type: STOREDATA,
        //         });
        //       } else {
        //         const updatedLoanApprovalData = {
        //           ...currentLoanApprovedAmount,
        //           [contextData.currentActiveData.accountNumber]: {
        //             approvedAmountValue: numericAmount,
        //           },
        //         };

        //         dispatchData({
        //           name: "approvedLoanAmounts",
        //           payload: {
        //             approvedLoanData: updatedLoanApprovalData,
        //           },
        //           type: STOREDATA,
        //         });
        //       }

        //       pgsApiCallFn(
        //         contextData,
        //         userobj,
        //         dispatchData,
        //         numericAmount,
        //         "offer",
        //         "",
        //         "",
        //         "EMI_OVERDUE_CAMPAIGN",
        //         "",
        //         emioverdueObject?.value,
        //         chargesoverdueObject?.value,
        //         totalOverdueAmountPgs
        //       )
        //         .then((uniqueTransactionId) => {
        //           // console.log(uniqueTransactionId, "uniqueTransactionId");
        //           dispatchData({
        //             name: "uniqueTransactionId",
        //             type: STOREDATA,
        //             payload: uniqueTransactionId,
        //           });
        //           // setPaymentOptClickCount(() => ({}));
        //           // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
        //           dispatchData({
        //             name: "loaderFlag",
        //             payload: false,
        //             type: STOREDATA,
        //           });
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //           dispatchData({
        //             name: "loaderFlag",
        //             type: STOREDATA,
        //             payload: false,
        //           });

        //           setFailurePopUpModal(true);
        //           setFailurePopUpContent({
        //             apiName: "",
        //             message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
        //           });
        //         });

        //       dispatchData({
        //         name: "activePaymentLoanData",
        //         payload: {
        //           loanAccNumber: contextData.currentActiveData.accountNumber,
        //           activePaymentFlag: true,
        //         },
        //         type: STOREDATA,
        //       });
        //     } else if (selectedPaymentDetails?.labelName == "EMI Overdue") {
        //       dispatchData({
        //         name: "loaderFlag",
        //         payload: true,
        //         type: STOREDATA,
        //       });
        //       LoginServiceObject.calculateNormaliseEmiOverdueRes(
        //         contextData,
        //         dashPaymentData,
        //         numericAmount
        //       )
        //         .then(async (getDataResponse) => {
        //           if (getDataResponse.body.message === "EMI Normalized") {
        //             console.log(getDataResponse, "getDataResponse1");
        //             if (getDataResponse.body.finalODBCAmount == 0) {
        //               sessionStorage.setItem("greyed-out", "true");
        //             } else if (getDataResponse.body.finalODBCAmount > 0) {
        //               sessionStorage.setItem("phase1", "true");
        //             }
        //             sessionStorage.setItem(
        //               "emi-phase1-payment-edge-case-failed",
        //               "true"
        //             );
        //             dispatchData({
        //               name: "emiOverdueOfferRes",
        //               payload: {
        //                 emiOfferCase: getDataResponse.body.message,
        //                 emiEnteredAmount: numericAmount,
        //                 emiOdbcWaiverAmount:
        //                   getDataResponse.body.finalODBCAmount,
        //                 emiOfferKey:
        //                   getDataResponse.body.finalODBCAmount == 0
        //                     ? "greyed-out"
        //                     : "phase1",
        //               },
        //               type: STOREDATA,
        //             });
        //           } else if (
        //             getDataResponse.body.message === "EMI Not Normalized"
        //           ) {
        //             console.log(getDataResponse, "getDataResponse2");
        //             sessionStorage.setItem("not-normalised", "true");
        //             sessionStorage.setItem(
        //               "emi-payment-edge-case-not-normalised",
        //               "true"
        //             );
        //             dispatchData({
        //               name: "emiOverdueOfferRes",
        //               payload: {
        //                 emiOfferCase: getDataResponse.body.message,
        //                 emiEnteredAmount: numericAmount,
        //                 emiOdbcWaiverAmount:
        //                   getDataResponse.body.finalODBCAmount,
        //                 emiOfferKey: "not-normalised",
        //               },
        //               type: STOREDATA,
        //             });
        //           }
        //         })
        //         .catch((error) => {
        //           console.error("Error:", error);
        //         });

        //       let userobj = {
        //         mobile: paymentForm?.value?.mobile,
        //         email: paymentForm?.value?.email,
        //       };

        //       // dispatchData({
        //       //   name: "approvedAmount",
        //       //   payload: numericAmount,
        //       //   type: STOREDATA,
        //       // });

        //       const currentLoanApprovedAmount =
        //         contextData?.approvedLoanAmounts?.approvedLoanData || {};

        //       if (
        //         currentLoanApprovedAmount[
        //           contextData.currentActiveData.accountNumber
        //         ]
        //       ) {
        //         const updatedLoanApprovalData = {
        //           ...currentLoanApprovedAmount,
        //           [contextData.currentActiveData.accountNumber]: {
        //             approvedAmountValue: numericAmount,
        //           },
        //         };

        //         dispatchData({
        //           name: "approvedLoanAmounts",
        //           payload: {
        //             approvedLoanData: updatedLoanApprovalData,
        //           },
        //           type: STOREDATA,
        //         });
        //       } else {
        //         const updatedLoanApprovalData = {
        //           ...currentLoanApprovedAmount,
        //           [contextData.currentActiveData.accountNumber]: {
        //             approvedAmountValue: numericAmount,
        //           },
        //         };

        //         dispatchData({
        //           name: "approvedLoanAmounts",
        //           payload: {
        //             approvedLoanData: updatedLoanApprovalData,
        //           },
        //           type: STOREDATA,
        //         });
        //       }

        //       pgsApiCallFn(
        //         contextData,
        //         userobj,
        //         dispatchData,
        //         numericAmount,
        //         "offer",
        //         "",
        //         "",
        //         "EMI_OVERDUE_CAMPAIGN",
        //         "",
        //         emioverdueObject?.value,
        //         chargesoverdueObject?.value,
        //         totalOverdueAmountPgs
        //       )
        //         .then((uniqueTransactionId) => {
        //           // console.log(uniqueTransactionId, "uniqueTransactionId");
        //           dispatchData({
        //             name: "uniqueTransactionId",
        //             type: STOREDATA,
        //             payload: uniqueTransactionId,
        //           });
        //           // setPaymentOptClickCount(() => ({}));
        //           // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
        //           dispatchData({
        //             name: "loaderFlag",
        //             payload: false,
        //             type: STOREDATA,
        //           });
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //           dispatchData({
        //             name: "loaderFlag",
        //             type: STOREDATA,
        //             payload: false,
        //           });

        //           setFailurePopUpModal(true);
        //           setFailurePopUpContent({
        //             apiName: "",
        //             message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
        //           });
        //         });

        //       dispatchData({
        //         name: "activePaymentLoanData",
        //         payload: {
        //           loanAccNumber: contextData.currentActiveData.accountNumber,
        //           activePaymentFlag: true,
        //         },
        //         type: STOREDATA,
        //       });
        //     } else if (selectedPaymentDetails?.labelName == "Other Amount") {
        //       dispatchData({
        //         name: "loaderFlag",
        //         payload: true,
        //         type: STOREDATA,
        //       });
        //       LoginServiceObject.calculateNormaliseEmiOverdueRes(
        //         contextData,
        //         dashPaymentData,
        //         numericAmount
        //       )
        //         .then(async (getDataResponse) => {
        //           if (getDataResponse.body.message === "EMI Normalized") {
        //             console.log(getDataResponse, "getDataResponse1");
        //             if (getDataResponse.body.finalODBCAmount == 0) {
        //               sessionStorage.setItem("greyed-out", "true");
        //             } else if (getDataResponse.body.finalODBCAmount > 0) {
        //               sessionStorage.setItem("phase1", "true");
        //             }
        //             sessionStorage.setItem(
        //               "emi-phase1-payment-edge-case-failed",
        //               "true"
        //             );
        //             dispatchData({
        //               name: "emiOverdueOfferRes",
        //               payload: {
        //                 emiOfferCase: getDataResponse.body.message,
        //                 emiEnteredAmount: numericAmount,
        //                 emiOdbcWaiverAmount:
        //                   getDataResponse.body.finalODBCAmount,
        //                 emiOfferKey:
        //                   getDataResponse.body.finalODBCAmount == 0
        //                     ? "greyed-out"
        //                     : "phase1",
        //               },
        //               type: STOREDATA,
        //             });
        //           } else if (
        //             getDataResponse.body.message === "EMI Not Normalized"
        //           ) {
        //             console.log(getDataResponse, "getDataResponse2");
        //             sessionStorage.setItem("not-normalised", "true");
        //             sessionStorage.setItem(
        //               "emi-payment-edge-case-not-normalised",
        //               "true"
        //             );
        //             dispatchData({
        //               name: "emiOverdueOfferRes",
        //               payload: {
        //                 emiOfferCase: getDataResponse.body.message,
        //                 emiEnteredAmount: numericAmount,
        //                 emiOdbcWaiverAmount:
        //                   getDataResponse.body.finalODBCAmount,
        //                 emiOfferKey: "not-normalised",
        //               },
        //               type: STOREDATA,
        //             });
        //           }
        //         })
        //         .catch((error) => {
        //           console.error("Error:", error);
        //         });

        //       let userobj = {
        //         mobile: paymentForm?.value?.mobile,
        //         email: paymentForm?.value?.email,
        //       };

        //       // dispatchData({
        //       //   name: "approvedAmount",
        //       //   payload: numericAmount,
        //       //   type: STOREDATA,
        //       // });

        //       const currentLoanApprovedAmount =
        //         contextData?.approvedLoanAmounts?.approvedLoanData || {};

        //       if (
        //         currentLoanApprovedAmount[
        //           contextData.currentActiveData.accountNumber
        //         ]
        //       ) {
        //         const updatedLoanApprovalData = {
        //           ...currentLoanApprovedAmount,
        //           [contextData.currentActiveData.accountNumber]: {
        //             approvedAmountValue: numericAmount,
        //           },
        //         };

        //         dispatchData({
        //           name: "approvedLoanAmounts",
        //           payload: {
        //             approvedLoanData: updatedLoanApprovalData,
        //           },
        //           type: STOREDATA,
        //         });
        //       } else {
        //         const updatedLoanApprovalData = {
        //           ...currentLoanApprovedAmount,
        //           [contextData.currentActiveData.accountNumber]: {
        //             approvedAmountValue: numericAmount,
        //           },
        //         };

        //         dispatchData({
        //           name: "approvedLoanAmounts",
        //           payload: {
        //             approvedLoanData: updatedLoanApprovalData,
        //           },
        //           type: STOREDATA,
        //         });
        //       }

        //       pgsApiCallFn(
        //         contextData,
        //         userobj,
        //         dispatchData,
        //         numericAmount,
        //         "offer",
        //         "",
        //         "",
        //         "EMI_OVERDUE_CAMPAIGN",
        //         "",
        //         emioverdueObject?.value,
        //         chargesoverdueObject?.value,
        //         totalOverdueAmountPgs
        //       )
        //         .then((uniqueTransactionId) => {
        //           // console.log(uniqueTransactionId, "uniqueTransactionId");
        //           dispatchData({
        //             name: "uniqueTransactionId",
        //             type: STOREDATA,
        //             payload: uniqueTransactionId,
        //           });
        //           // setPaymentOptClickCount(() => ({}));
        //           // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
        //           dispatchData({
        //             name: "loaderFlag",
        //             payload: false,
        //             type: STOREDATA,
        //           });
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //           dispatchData({
        //             name: "loaderFlag",
        //             type: STOREDATA,
        //             payload: false,
        //           });

        //           setFailurePopUpModal(true);
        //           setFailurePopUpContent({
        //             apiName: "",
        //             message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
        //           });
        //         });

        //       dispatchData({
        //         name: "activePaymentLoanData",
        //         payload: {
        //           loanAccNumber: contextData.currentActiveData.accountNumber,
        //           activePaymentFlag: true,
        //         },
        //         type: STOREDATA,
        //       });
        //     }
        //   }
        // } else if (object[0]?.["SETTLEMENT_CAMPAIGN"] == "N") {
        //   console.log("hello");
        //   if (selectedPaymentDetails) {
        //     let userobj = {
        //       ...selectedPaymentDetails,
        //       mobile: paymentForm.value.mobile,
        //       email: paymentForm.value.email,
        //     };

        //     dispatchData({
        //       name: "paymentAmount",
        //       type: STOREDATA,
        //       payload: amount,
        //     });
        //     dispatchData({
        //       name: "approvedAmount",
        //       payload: amount,
        //       type: STOREDATA,
        //     });

        //     if (
        //       selectedPaymentDetails.amount <= 0 ||
        //       Number(selectedPaymentDetails.amount) >
        //         selectedPaymentDetails.validAmount
        //     ) {
        //       setFailurePopUpModal(true);
        //       setFailurePopUpContent({
        //         apiName: "",
        //         message: `Please enter amount between ₹ 1/- to ₹ ${selectedPaymentDetails.validAmount?.toLocaleString(
        //           "en-IN"
        //         )}/-`,
        //       });
        //     } else {
        //       dispatchData({
        //         name: "loaderFlag",
        //         type: STOREDATA,
        //         payload: true,
        //       });
        //       dispatchData({
        //         name: "activePaymentLoanData",
        //         payload: {
        //           loanAccNumber: contextData.currentActiveData.accountNumber,
        //           activePaymentFlag: true,
        //           isTrancheOpted: "",
        //         },
        //         type: STOREDATA,
        //       });
        //       dispatchData({
        //         name: "currentOfferPopup",
        //         payload: "NON_OFFER",
        //         type: STOREDATA,
        //       });

        //       pgsApiCallFn(
        //         contextData,
        //         userobj,
        //         dispatchData,
        //         totalOverdueAmountPgs,
        //         "non-offer"
        //       )
        //         .then((uniqueTransactionId) => {
        //           dispatchData({
        //             name: "uniqueTransactionId",
        //             type: STOREDATA,
        //             payload: uniqueTransactionId,
        //           });
        //           setPaymentOptClickCount(() => ({}));
        //           setFinalPayOptClickObj(
        //             uniqueTransactionId,
        //             "paymentOptionClick"
        //           );
        //           // dispatchData({
        //           //     name: "loaderFlag",
        //           //     type: STOREDATA,
        //           //     payload: false,
        //           // });
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //           dispatchData({
        //             name: "loaderFlag",
        //             type: STOREDATA,
        //             payload: false,
        //           });
        //           setFailurePopUpModal(true);
        //           setFailurePopUpContent({
        //             apiName: "",
        //             message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
        //           });
        //         });
        //     }
        //   }
        // }
      }
    } else {
      const emioverdueData = dashPaymentData.loanDetails.find(
        (item) => item.name === "EMI OVERDUE AMOUNT"
      );

      const chargesoverdueData = dashPaymentData.loanDetails.find(
        (item) => item.name === "CHARGES OVERDUE AMOUNT"
      );

      const totalOverdueAmountPgsData =
        emioverdueData?.value + chargesoverdueData?.value;

      const object = contextData?.offers?.body?.offerDetails.filter((ele) => {
        return (
          ele?.LOAN_ACCOUNT_NO === contextData?.currentActiveData?.accountNumber
        );
      });
      // console.log(object, "object");

      let currentOfferName = getofferKey(object[0]);
      // console.log(currentOfferName, "currentOfferName");
      // setCurrentPopup(currentOfferName);

      dispatchData({
        name: "currentOfferPopup",
        payload: currentOfferName,
        type: STOREDATA,
      });
      const handleSettlementOfferFunctionality = (
        contextData,
        dashPaymentData,
        amount
      ) => {
        const offerApprovedFunc = (
          noOfAttempts,
          approvedAmount,
          minimumSettlementAmount
        ) => {
          let eventObjectDetails = {
            entersettlementAmount: approvedAmount,
            minSettlementAmount: minimumSettlementAmount,
            count: noOfAttempts,
          };

          handleClickForPaymentOpt(
            "handleSoftSettlementAmount",
            "",
            eventObjectDetails,
            "",
            contextData.currentActiveData.accountNumber
          );

          // console.log("approved");
          setPreapprovedOfferFlag(true);
          setSuccessFailureFlag(true);

          const currentLoanAttempts = contextData?.attempt?.loanAttempts || {};

          // Check if the loan number is already present
          if (
            currentLoanAttempts[contextData.currentActiveData.accountNumber]
          ) {
            // console.log("Loan number already present");
            // Loan number is already present, update attempts
            const updatedLoanAttempts = {
              ...currentLoanAttempts,
              [contextData.currentActiveData.accountNumber]: {
                attempts: noOfAttempts,
              },
            };

            dispatchData({
              name: "attempt",
              payload: {
                loanAttempts: updatedLoanAttempts,
              },
              type: STOREDATA,
            });
          } else {
            // console.log("Loan number not present");
            // Loan number is not present, add it to loanAttempts
            const updatedLoanAttempts = {
              ...currentLoanAttempts,
              [contextData.currentActiveData.accountNumber]: {
                attempts: noOfAttempts,
              },
            };

            dispatchData({
              name: "attempt",
              payload: {
                loanAttempts: updatedLoanAttempts,
              },
              type: STOREDATA,
            });
          }

          // dispatchData({
          //   name: "approvedAmount",
          //   payload: approvedAmount,
          //   type: STOREDATA,
          // });
          const currentLoanApprovedAmount =
            contextData?.approvedLoanAmounts?.approvedLoanData || {};

          if (
            currentLoanApprovedAmount[
              contextData.currentActiveData.accountNumber
            ]
          ) {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: approvedAmount,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          } else {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [contextData.currentActiveData.accountNumber]: {
                approvedAmountValue: approvedAmount,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          }
          sessionStorage.setItem("offer-accept-flag", true);
          dispatchData({
            name: "offerModaLFlag",
            payload: {
              modalFlag: true,
              accountType: "optingSettlementModal",
            },
            type: STOREDATA,
          });
        };

        const offerRejectFunc = (
          noOfAttempts,
          approvedAmount,
          minimumSettlementAmount
        ) => {
          console.log("reject");

          if (noOfAttempts > 0 && noOfAttempts <= 2) {
            let eventObjectDetails = {
              entersettlementAmount: approvedAmount,
              minSettlementAmount: minimumSettlementAmount,
              count: noOfAttempts,
              offerApprovalStatus: "",
            };
            handleClickForPaymentOpt(
              "setMinimumSettlementAmount",
              "",
              eventObjectDetails,
              "",
              contextData.currentActiveData.accountNumber
            );
          } else {
            let eventObjectDetails = {
              entersettlementAmount: approvedAmount,
              minSettlementAmount: minimumSettlementAmount,
              count: noOfAttempts,
              offerApprovalStatus: "Rejected",
            };
            handleClickForPaymentOpt(
              "setMinimumSettlementAmount",
              "",
              eventObjectDetails,
              "",
              contextData.currentActiveData.accountNumber
            );
          }

          const currentLoanApprovedAmount =
            contextData?.approvedLoanAmounts?.approvedLoanData || {};

          const accountNumber = contextData.currentActiveData.accountNumber;

          if (currentLoanApprovedAmount[accountNumber]) {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [accountNumber]: {
                ...currentLoanApprovedAmount[accountNumber],
                rejectedAmountValue: approvedAmount,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          } else {
            const updatedLoanApprovalData = {
              ...currentLoanApprovedAmount,
              [accountNumber]: {
                ...currentLoanApprovedAmount[accountNumber],
                rejectedAmountValue: approvedAmount,
              },
            };

            dispatchData({
              name: "approvedLoanAmounts",
              payload: {
                approvedLoanData: updatedLoanApprovalData,
              },
              type: STOREDATA,
            });
          }

          const currentLoanAttempts = contextData?.attempt?.loanAttempts || {};

          if (
            currentLoanAttempts[contextData.currentActiveData.accountNumber]
          ) {
            console.log("Loan number already present");
            const updatedLoanAttempts = {
              ...currentLoanAttempts,
              [contextData.currentActiveData.accountNumber]: {
                attempts: noOfAttempts,
              },
            };

            dispatchData({
              name: "attempt",
              payload: {
                loanAttempts: updatedLoanAttempts,
              },
              type: STOREDATA,
            });
          } else {
            console.log("Loan number not present");
            // Loan number is not present, add it to loanAttempts
            const updatedLoanAttempts = {
              ...currentLoanAttempts,
              [contextData.currentActiveData.accountNumber]: {
                attempts: noOfAttempts,
              },
            };

            dispatchData({
              name: "attempt",
              payload: {
                loanAttempts: updatedLoanAttempts,
              },
              type: STOREDATA,
            });
          }
          setPreapprovedOfferFlag(true);
          setSuccessFailureFlag(false);

          dispatchData({
            name: "offerModaLFlag",
            payload: {
              modalFlag: true,
              accountType: "congratsModal",
            },
            type: STOREDATA,
          });
        };

        const numericFc = parseFloat(amount.replace(/,/g, ""));

        if (numericFc > dashPaymentData?.offerData["FC"]) {
          // console.log("enterrrrr");
          setSettlementValid(true);
          setPopOverFlag(false);
          setPayNowFlag(false);
          setAmount(fcAmount);

          // alert("your entered amount is greater than fc amount");
        } else if (numericFc <= dashPaymentData?.offerData["FC"]) {
          dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA,
          });

          LoginServiceObject.calMinSettlementAmount(
            contextData,
            dashPaymentData.offerData,
            numericFc
          )
            .then(async (getDataResponse) => {
              dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA,
              });

              if (getDataResponse.body.message === "Offer Accepted") {
                let approvalDate = new Date();
                const year = approvalDate.getFullYear();
                const month = String(approvalDate.getMonth() + 1).padStart(
                  2,
                  "0"
                );
                const day = String(approvalDate.getDate()).padStart(2, "0");

                const formattedApprovalDate = `${year}-${month}-${day}`;
                // console.log(formattedApprovalDate,"formattedApprovalDate");

                const currentLoanApprovalDetails =
                  contextData?.offerApprovedData?.loanAccount || {};

                if (
                  currentLoanApprovalDetails[
                    contextData.currentActiveData.accountNumber
                  ]
                ) {
                  const updatedLoanApprovalData = {
                    ...currentLoanApprovalDetails,
                    [contextData.currentActiveData.accountNumber]: {
                      offerApprovalDate: formattedApprovalDate,
                      offerApprovalFlag: true,
                    },
                  };

                  dispatchData({
                    name: "offerApprovedData",
                    payload: {
                      loanAccount: updatedLoanApprovalData,
                    },
                    type: STOREDATA,
                  });
                } else {
                  const updatedLoanApprovalData = {
                    ...currentLoanApprovalDetails,
                    [contextData.currentActiveData.accountNumber]: {
                      offerApprovalDate: formattedApprovalDate,
                      offerApprovalFlag: true,
                    },
                  };

                  dispatchData({
                    name: "offerApprovedData",
                    payload: {
                      loanAccount: updatedLoanApprovalData,
                    },
                    type: STOREDATA,
                  });
                }

                offerApprovedFunc(
                  getDataResponse.body.numberOfAttempts,
                  getDataResponse.body.offerApprovedAmount,
                  getDataResponse.body.minimumSettlementAmount
                );
              } else if (getDataResponse.body.message === "Offer Rejected") {
                offerRejectFunc(
                  getDataResponse.body.numberOfAttempts,
                  getDataResponse.body.offerApprovedAmount,
                  getDataResponse.body.minimumSettlementAmount
                );
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      };

      const handleEMIOfferFunctionality = (
        contextData,
        dashPaymentData,
        amount,
        currentOfferName
      ) => {
        const numericEMIFc = parseFloat(amount.replace(/,/g, ""));

        let totalOverdueAmountPgs =
          dashPaymentData?.loanDetails[3]?.value +
          dashPaymentData?.loanDetails[4]?.value;
        // console.log(totalOverdueAmountPgs, "totalOverdueAmountPgs");

        if (
          numericEMIFc >
          dashPaymentData.loanDetails[3].value +
            dashPaymentData.loanDetails[4].value
        ) {
          setPopOverFlag(true);
          setPayNowFlag(true);
        } else if (
          numericEMIFc <=
          dashPaymentData.loanDetails[3].value +
            dashPaymentData.loanDetails[4].value
        ) {
          dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA,
          });
          LoginServiceObject.calculateNormaliseEmiOverdueRes(
            contextData,
            dashPaymentData,
            numericEMIFc
          )
            .then(async (getDataResponse) => {
              if (getDataResponse.body.message === "EMI Normalized") {
                console.log(getDataResponse, "getDataResponse1");
                if (getDataResponse.body.finalODBCAmount == 0) {
                  sessionStorage.setItem("greyed-out", "true");
                } else if (getDataResponse.body.finalODBCAmount > 0) {
                  sessionStorage.setItem("phase1", "true");
                }
                dispatchData({
                  name: "emiOverdueOfferRes",
                  payload: {
                    emiOfferCase: getDataResponse.body.message,
                    emiEnteredAmount: numericEMIFc,
                    emiOdbcWaiverAmount: getDataResponse.body.finalODBCAmount,
                    emiOfferKey:
                      getDataResponse.body.finalODBCAmount == 0
                        ? "greyed-out"
                        : "phase1",
                  },
                  type: STOREDATA,
                });
              } else if (
                getDataResponse.body.message === "EMI Not Normalized"
              ) {
                console.log(getDataResponse, "getDataResponse2");
                sessionStorage.setItem("not-normalised", "true");
                dispatchData({
                  name: "emiOverdueOfferRes",
                  payload: {
                    emiOfferCase: getDataResponse.body.message,
                    emiEnteredAmount: numericEMIFc,
                    emiOdbcWaiverAmount: getDataResponse.body.finalODBCAmount,
                    emiOfferKey: "not-normalised",
                  },
                  type: STOREDATA,
                });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });

          let userobj = {
            mobile: paymentForm?.value?.mobile,
            email: paymentForm?.value?.email,
          };

          pgsApiCallFn(
            contextData,
            userobj,
            dispatchData,
            numericEMIFc,
            journeyType,
            "",
            "",
            currentOfferName,
            "",
            dashPaymentData?.loanDetails[3]?.value,
            dashPaymentData?.loanDetails[4]?.value,
            totalOverdueAmountPgs
          )
            .then((uniqueTransactionId) => {
              // console.log(uniqueTransactionId, "uniqueTransactionId");
              dispatchData({
                name: "uniqueTransactionId",
                type: STOREDATA,
                payload: uniqueTransactionId,
              });
              // setPaymentOptClickCount(() => ({}));
              // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
              dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA,
              });
            })
            .catch((err) => {
              console.log(err);
              dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false,
              });

              setFailurePopUpModal(true);
              setFailurePopUpContent({
                apiName: "",
                message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
              });
            });

          dispatchData({
            name: "activePaymentLoanData",
            payload: {
              loanAccNumber: contextData.currentActiveData.accountNumber,
              activePaymentFlag: true,
            },
            type: STOREDATA,
          });
        }
      };

      const handleODBCOfferFunctionality = (
        contextData,
        dashPaymentData,
        amount,
        currentOfferName
      ) => {
        dispatchData({
          name: "odbcChargesAmount",
          payload: dashPaymentData.loanDetails[4].value,
          type: STOREDATA,
        });

        const numericOdbc = parseFloat(amount.replace(/,/g, ""));

        if (numericOdbc > dashPaymentData.loanDetails[4].value) {
          setPopOverFlag(true);
          setPayNowFlag(true);
        } else if (numericOdbc == dashPaymentData.loanDetails[4].value) {
          dispatchData({
            name: "odbcEnteredAmount",
            payload: numericOdbc,
            type: STOREDATA,
          });

          setModalPopUp(true);
          setConveyPopUp(true);
        } else if (numericOdbc < dashPaymentData.loanDetails[4].value) {
          dispatchData({
            name: "odbcOfferRes",
            payload: {
              odbcOfferCase: "Offer Rejected",
              odbcEnteredAmount: numericOdbc,
              odbcWaiverAmount: 0,
            },
            type: STOREDATA,
          });
          dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA,
          });

          let userobj = {
            amount: numericOdbc,
            mobile: paymentForm?.value?.mobile,
            email: paymentForm?.value?.email,
          };

          pgsApiCallFn(
            contextData,
            userobj,
            dispatchData,
            totalOverdueAmountPgsData,
            "non-offer",
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          )
            .then((uniqueTransactionId) => {
              // console.log(uniqueTransactionId, "uniqueTransactionId");
              dispatchData({
                name: "uniqueTransactionId",
                type: STOREDATA,
                payload: uniqueTransactionId,
              });
              // setPaymentOptClickCount(() => ({}));
              // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
              dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA,
              });
            })
            .catch((err) => {
              console.log(err);
              dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false,
              });

              setFailurePopUpModal(true);
              setFailurePopUpContent({
                apiName: "",
                message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
              });
            });
        }

        // if (numericOdbc > dashPaymentData.loanDetails[4].value) {
        //   setPopOverFlag(true);
        //   setPayNowFlag(true);
        // } else if (numericOdbc <= dashPaymentData.loanDetails[4].value) {
        // let currentDate = new Date();
        // let formattedDate = currentDate.toLocaleDateString("en-GB");
        // console.log(formattedDate, "formattedDate");
        // const loanObject = Object.keys(
        //   contextData?.getLoanPositionDTLS
        // ).filter((ele) => {
        //   return ele === contextData?.currentActiveData?.accountNumber;
        // });

        // console.log(loanObject, "loanObject");

        // let loanStatus;
        // if (loanObject) {
        //   let terminateDate =
        //     loanObject?.body?.getLoanPositionDtlsReturn?.terminationDate;
        //   console.log(terminateDate, "terminateDate");

        //   if (formattedDate < terminateDate) {
        //     loanStatus = "Live";
        //   } else {
        //     loanStatus = "Matured";
        //   }
        // }

        // dispatchData({
        //   name: "loaderFlag",
        //   payload: true,
        //   type: STOREDATA,
        // });

        // LoginServiceObject.calculateODBCWaiverRes(
        //   contextData,
        //   dashPaymentData,
        //   numericOdbc,
        //   loanStatus
        // )
        //   .then(async (getDataResponse) => {
        //     if (getDataResponse.body.message === "Offer Approved") {
        //       console.log(getDataResponse, "getDataResponse1");
        //       dispatchData({
        //         name: "odbcOfferRes",
        //         payload: {
        //           odbcOfferCase: getDataResponse.body.message,
        //           odbcEnteredAmount: numericOdbc,
        //           odbcWaiverAmount: getDataResponse.body.finalODBCAmount,
        //         },
        //         type: STOREDATA,
        //       });

        //       dispatchData({
        //         name: "loaderFlag",
        //         payload: false,
        //         type: STOREDATA,
        //       });
        //     } else if (getDataResponse.body.message === "Offer Rejected") {
        //       console.log(getDataResponse, "getDataResponse2");

        //       dispatchData({
        //         name: "odbcOfferRes",
        //         payload: {
        //           odbcOfferCase: getDataResponse.body.message,
        //           odbcEnteredAmount: numericOdbc,
        //           odbcWaiverAmount: getDataResponse.body.finalODBCAmount,
        //         },
        //         type: STOREDATA,
        //       });
        //       dispatchData({
        //         name: "loaderFlag",
        //         payload: false,
        //         type: STOREDATA,
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     console.error("Error:", error);
        //   });
        // }

        dispatchData({
          name: "activePaymentLoanData",
          payload: {
            loanAccNumber: contextData.currentActiveData.accountNumber,
            activePaymentFlag: true,
          },
          type: STOREDATA,
        });
      };

      switch (currentOfferName) {
        case "SETTLEMENT_CAMPAIGN":
          handleSettlementOfferFunctionality(
            contextData,
            dashPaymentData,
            amount,
            currentOfferName
          );
          break;
        case "EMI_OVERDUE_CAMPAIGN":
          handleEMIOfferFunctionality(
            contextData,
            dashPaymentData,
            amount,
            currentOfferName
          );
          break;

        case "ODBC_CAMPAIGN":
          handleODBCOfferFunctionality(
            contextData,
            dashPaymentData,
            amount,
            currentOfferName
          );
          break;

        default:
          // Handle default case
          break;
      }
    }
  };

  const handleMobEmailInput = (e) => {
    if (e.target.value.length <= 10) {
      if (
        paymentForm.control.mobile &&
        paymentForm.control.mobile.value !== ""
      ) {
        setValidateFlagMobile(true);
        // if (!paymentForm.control.mobile.valid) {
        //   return;
        // }
      }

      handleForm(e.target.name, e.target.value);
      handleClickForPaymentOpt(
        "handleNumEmail",
        "",
        e.target.name,
        e.target.value
      );
    }

    if (e.target.value.length < 10) {
      setValidateFlagMobile(true);
      setDisableBtn(true);
      setPayNowFlag(true);
      setShowAdditionValid(true);
    }

    if (e.target.value.length === 10) {
      if (/^[6-9](?:(?!([0-9])\1{5,})[0-9]){9}$/.test(e.target.value)) {
        if (ischecked) {
          setValidateFlagMobile(false);
          setDisableBtn(false);
          setPayNowFlag(false);
          setShowAdditionValid(false);
        } else if (ischecked == false && popOverFlag) {
          setValidateFlagMobile(false);
          setDisableBtn(true);
          setPayNowFlag(true);
          setShowAdditionValid(false);
        } else if (ischecked == false && popOverFlag == false) {
          setValidateFlagMobile(false);
          setDisableBtn(false);
          setPayNowFlag(false);
          setShowAdditionValid(false);
        }
        if (validateFlagEmail) {
          setDisableBtn(true);
          setPayNowFlag(true);
        }
      } else {
        setValidateFlagMobile(true);
        setDisableBtn(true);
        setPayNowFlag(true);
        setShowAdditionValid(true);
        // throw new Error('Invalid mobile number'); // Throw an error if the validation fails
      }
    }

    if (e.target.value.length === 0) {
      if (ischecked == true) {
        setValidateFlagMobile(false);
        setDisableBtn(false);
        setPayNowFlag(false);
      } else if (ischecked == false && popOverFlag) {
        setValidateFlagMobile(false);
        setDisableBtn(true);
        setPayNowFlag(true);
      } else if (ischecked == false && popOverFlag == false) {
        setValidateFlagMobile(false);
        setDisableBtn(false);
        setPayNowFlag(false);
      }
    }
    if (validateFlagEmail) {
      setPayNowFlag(true);
      setDisableBtn(true);
    }
  };

  const handleEmailInput = (e) => {
    const emailRegex = /^[a-zA-Z0-9.@]*$/;
    const emailRegaxPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;

    // Validate character input
    if (!emailRegex.test(e.target.value)) {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9.@]/g, "");
      return;
    }

    handleForm(e.target.name, e.target.value);
    // Optionally, perform further actions based on the email input
    handleClickForPaymentOpt(
      "handleNumEmail",
      "",
      e.target.name,
      e.target.value
    );

    if (e.target.value.length == 1) {
      setValidateFlagEmail(true);
      setDisableBtn(true);
      setPayNowFlag(true);
    }

    // Check if email control exists and is not empty
    if (paymentForm.control.email && paymentForm.control.email.value !== "") {
      setValidateFlagEmail(true);
      setDisableBtn(true);
      setPayNowFlag(true);
    }

    // Check if email control is valid
    if (paymentForm.control.email && paymentForm.control.email.valid) {
      if (ischecked) {
        setValidateFlagEmail(false);
        setDisableBtn(false);
        setPayNowFlag(false);
      } else {
        setValidateFlagEmail(false);
        setDisableBtn(true);
        setPayNowFlag(true);
      }

      if (popOverFlag == false) {
        setDisableBtn(false);
        setPayNowFlag(false);
      }
    }

    // Check if email value is empty
    if (e.target.value.length === 0) {
      if (ischecked == false && popOverFlag) {
        setValidateFlagEmail(false);
        setDisableBtn(true);
        setPayNowFlag(true);
      } else if (ischecked == false && popOverFlag == false) {
        setValidateFlagEmail(false);
        setDisableBtn(false);
        setPayNowFlag(false);
      } else if (ischecked == true && popOverFlag) {
        setValidateFlagEmail(false);
        setDisableBtn(false);
        setPayNowFlag(false);
      }
    }

    if (emailRegaxPattern.test(e.target.value)) {
      if (ischecked == false && popOverFlag) {
        setValidateFlagEmail(false);
        setDisableBtn(true);
        setPayNowFlag(true);
      } else if (ischecked == false && popOverFlag == false) {
        setValidateFlagEmail(false);
        setDisableBtn(false);
        setPayNowFlag(false);
      }
    }

    if (validateFlagMobile) {
      setPayNowFlag(true);
      setDisableBtn(true);
    }
  };

  const handleActiveOfferData = () => {
    const { currentActiveData, offers } = contextData;
    // console.log(currentActiveData,"currentActiveData");
    // console.log(offers,"offers");
    // console.log(dashPaymentData,"dashPaymentData");
    if (dashPaymentData) {
      const getofferKey = (loan) => {
        // console.log(loan,"iuhuyfcfdx");
        const keysToCheck = [
          "SETTLEMENT_CAMPAIGN",
          "EMI_OVERDUE_CAMPAIGN",
          "ODBC_CAMPAIGN",
          "LOS_POOL_CAMPAIGN",
          "ALREADY_SETTLED_POOL_CAMPAIGN",
        ];

        for (const key of keysToCheck) {
          if (loan.offerData[key] === "Y") {
            return key;
          }
        }

        return null;
      };

      const getOfferName = getofferKey(dashPaymentData);
      // console.log(
      //   dashPaymentData.offerData["FC"].toString().split(".")[0],
      //   "dashPaymentData.offerData"
      // );
      if (getOfferName === "SETTLEMENT_CAMPAIGN") {
        // console.log(dashPaymentData?.offerData["OFFER_APPROVED_AMOUNT"], "1");
        // console.log(dashPaymentData?.offerData["FC"], "2");

        let amountVal = dashPaymentData?.offerData["FC"].toString();

        // Convert the string to a floating-point number
        let numericAmount = parseFloat(amountVal);

        // Format the numeric amount to include commas and two decimal places
        let formattedAmount = numericAmount.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        setFcStaticAmount(formattedAmount);
        setAmount(formattedAmount);
        setFcAmount(formattedAmount);
        // let amountValContext =
        //   contextData?.approvedLoanAmounts?.approvedLoanData[
        //     contextData.currentActiveData.accountNumber
        //   ] &&
        //   contextData?.approvedLoanAmounts?.approvedLoanData[
        //     contextData.currentActiveData.accountNumber
        //   ]?.approvedAmountValue.toString();

        // let numericAmountContext = parseFloat(amountValContext);

        // // Format the numeric amount to include commas and two decimal places
        // let formattedAmountContext = numericAmountContext.toLocaleString(
        //   "en-IN",
        //   {
        //     minimumFractionDigits: 2,
        //     maximumFractionDigits: 2,
        //   }
        // );

        // if (dashPaymentData?.offerData["OFFER_APPROVED_AMOUNT"]) {
        //   let amountValApproved =
        //     dashPaymentData?.offerData["OFFER_APPROVED_AMOUNT"];

        //   let numericAmountApproved = parseFloat(amountValApproved);

        //   let formattedAmountApproved = numericAmountApproved.toLocaleString(
        //     "en-IN",
        //     {
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2,
        //     }
        //   );
        //   setAmount(formattedAmountApproved);
        //   setFcAmount(formattedAmountApproved);
        // }

        //
        // if (
        //   contextData?.approvedLoanAmounts?.approvedLoanData[
        //     contextData.currentActiveData.accountNumber
        //   ]
        // ) {
        //   setAmount(formattedAmountContext);
        //   // setFcAmount(formattedAmountContext);
        // } else {
        //   setAmount(formattedAmount);
        //   setFcAmount(formattedAmount);
        // }

        // if (sessionStorage.getItem("reason-reject-flag")) {
        //   setAmount(formattedAmount);
        //   setFcAmount(formattedAmount);
        // }

        // if (dashPaymentData?.offerData["IS_OFFER_AVAILED"] == "Y") {
        //   setAmount(formattedAmountApproved);
        //   setFcAmount(formattedAmountApproved);
        // } else if (
        //   dashPaymentData?.offerData["IS_OFFER_AVAILED"] == "N" &&
        //   contextData?.approvedAmount
        // ) {
        //   setAmount(contextData?.approvedAmount);
        //   setFcAmount(contextData?.approvedAmount);
        // } else {
        // setAmount(formattedAmount);
        // setFcAmount(formattedAmount);
        // }
      } else if (getOfferName === "EMI_OVERDUE_CAMPAIGN") {
        setAmount(
          dashPaymentData.loanDetails[3].value.toString().split(".")[0]
        );
        setFcAmount(
          dashPaymentData.loanDetails[3].value.toString().split(".")[0]
        );

        var formattedString = Number(
          dashPaymentData.loanDetails[3].value.toString().split(".")[0]
        ).toLocaleString("en-IN");
        setAmount(formattedString);
        setFcAmount(formattedString);
      } else if (getOfferName === "ODBC_CAMPAIGN") {
        setAmount(
          dashPaymentData.loanDetails[4].value.toString().split(".")[0]
        );
        setFcAmount(
          dashPaymentData.loanDetails[4].value.toString().split(".")[0]
        );

        var formattedString = Number(
          dashPaymentData.loanDetails[4].value.toString().split(".")[0]
        ).toLocaleString("en-IN");
        setAmount(formattedString);
        setFcAmount(formattedString);
      }
    }
  };

  const toggle = () => {
    // console.log(ischecked, "ischecked");
    setIsChecked(!ischecked);
    if (ischecked == false) {
      if (validateFlagEmail || validateFlagMobile) {
        setPayNowFlag(true);
        setDisableBtn(true);
      } else {
        setPayNowFlag(false);
        setDisableBtn(false);
      }
    } else if (ischecked == true) {
      if (popOverFlag) {
        if (validateFlagEmail || validateFlagMobile) {
          setPayNowFlag(true);
          setDisableBtn(true);
        } else {
          setPayNowFlag(true);
          setDisableBtn(true);
        }
      } else {
        setPayNowFlag(true);
        setDisableBtn(true);
      }
    }
  };

  useEffect(() => {
    if (ischecked) {
      if (validateFlagMobile == false && validateFlagEmail == false) {
        setPayNowFlag(false);
        setDisableBtn(false);
      } else if (validateFlagMobile || validateFlagEmail) {
        setPayNowFlag(true);
        setDisableBtn(true);
      }
    } else if (ischecked == false) {
      if (validateFlagMobile || validateFlagEmail) {
        setPayNowFlag(true);
        setDisableBtn(true);
      }
    }
  }, [validateFlagMobile, validateFlagEmail]);

  // useEffect(() => {
  //   if (ischecked === false) {
  //     if (validateFlagMobile || validateFlagEmail) {
  //       setPayNowFlag(true);
  //       setDisableBtn(true);
  //     } else {
  //       setPayNowFlag(true);
  //       // setDisableBtn(true);
  //     }
  //   }

  //   if (ischecked === true) {
  //     if (validateFlagMobile || validateFlagEmail) {
  //       setPayNowFlag(true);
  //       setDisableBtn(true);
  //     } else {
  //       setPayNowFlag(false);
  //       setDisableBtn(false);
  //     }
  //   }
  // }, [ischecked]);

  const stopEnter = (event) => {
    // Allow only numbers, backspace, and one decimal point
    if (
      !/^\d*$/.test(event.key) &&
      event.key !== "." &&
      event.key !== "Backspace"
    ) {
      event.preventDefault();
    }
  };

  const checkedRadioButton = (value) => {
    setSelectedConveyOption(value);

    if (value == "Yes") {
      setSelectConveyActive(() => false);
    } else {
      setSelectConveyActive((prevValue) => !prevValue);
    }
  };

  const handleAnimationEnd = () => {
    const animatedText = document.querySelector(".animated-text");
    animatedText.classList.add("animation-ended");
    setShowOdbcPopupAnimation(true);
  };

  const preventCopyPaste = (e) => {
    e.preventDefault();
    // alert("Copying and pasting is not allowed!");
  };

  return (
    <div className="c_payment">
      {modalPopUp ? (
        <Modal>
          {paymentPopup && (
            <div className="c_thankyou_wrap">
              <img
                className="c_thankyou_close"
                src="assets/images/gray-cross.svg"
                alt="gray-cross"
                onClick={() => {
                  if (
                    contextData?.currentOfferPopup === "SETTLEMENT_CAMPAIGN"
                  ) {
                    setAttemptFlag(true);
                    setModalPopUp(false);
                    setPaymentPopup(false);
                    dispatchData({
                      name: "offerModaLFlag",
                      payload: {
                        modalFlag: true,
                        accountType: "paymentPlanModal",
                      },
                      type: STOREDATA,
                    });
                    sessionStorage.clear("payment-failure");
                    setPaymentFlag(true);
                    sessionStorage.setItem(
                      "paymentmodal-background-amount-flag",
                      true
                    );
                  } else if (
                    contextData?.currentOfferPopup === "EMI_OVERDUE_CAMPAIGN"
                  ) {
                    if (
                      sessionStorage.getItem(
                        "emi-payment-edge-case-not-normalised"
                      )
                    ) {
                      setModalPopUp(false);
                      setPaymentPopup(false);
                      dispatchData({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: true,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                      sessionStorage.clear("emi-payment-failure");
                      sessionStorage.clear(
                        "emi-payment-edge-case-not-normalised"
                      );
                    } else if (
                      sessionStorage.getItem("emi-payment-edge-case-failed")
                    ) {
                      setModalPopUp(false);
                      setPaymentPopup(false);
                      dispatchData({
                        name: "offerModaLFlag",
                        payload: {
                          modalFlag: true,
                          accountType: "availOfferModal",
                        },
                        type: STOREDATA,
                      });
                      sessionStorage.clear("emi-payment-failure");
                      sessionStorage.clear(
                        "emi-payment-edge-case-not-normalised"
                      );
                    } else if (sessionStorage.getItem("emi-payment-failure")) {
                      setModalPopUp(false);
                      setPaymentPopup(false);
                      sessionStorage.clear("emi-payment-failure");
                    } else {
                      setModalPopUp(false);
                      setPaymentPopup(false);
                      sessionStorage.clear("emi-payment-success");
                      sessionStorage.clear("emi-payment-failure");
                    }
                  } else if (
                    contextData?.currentOfferPopup === "ODBC_CAMPAIGN"
                  ) {
                    setPaymentFlag(true);
                    setModalPopUp(false);
                    setPaymentPopup(false);
                  } else if (contextData?.currentOfferPopup === "NON_OFFER") {
                    dispatchData({
                      type: STOREDATA,
                      payload: true,
                      name: "callpgs",
                    });
                    setModalPopUp(false);
                    setPaymentPopup(false);
                  }
                }}
              />
              {modalType == "failure" && (
                <h2 className="c_thankyou_title c_failure_msg_bold">
                  Oops! Transaction Unsuccessful!
                </h2>
              )}
              <img
                className="c_thankyou_icon"
                src={`assets/gifs/${
                  contextData?.currentOfferPopup === "SETTLEMENT_CAMPAIGN"
                    ? modalPopupData[modalType]?.gif || ""
                    : contextData?.currentOfferPopup === "EMI_OVERDUE_CAMPAIGN"
                    ? emiOverduePopupData[modalType]?.gif || ""
                    : contextData?.currentOfferPopup === "ODBC_CAMPAIGN"
                    ? odbcPopupData[modalType]?.gif || ""
                    : contextData?.currentOfferPopup === "NON_OFFER"
                    ? modalPopupData[modalType]?.gif || ""
                    : ""
                }`}
                alt="green-tick"
              />
              <p className="c_thankyou_title">
                {contextData?.currentOfferPopup === "SETTLEMENT_CAMPAIGN"
                  ? modalPopupData[modalType].msg
                  : ""}
                {contextData?.currentOfferPopup === "EMI_OVERDUE_CAMPAIGN"
                  ? emiOverduePopupData[modalType].msg
                  : ""}
                {contextData?.currentOfferPopup === "ODBC_CAMPAIGN"
                  ? odbcPopupData[modalType].msg
                  : ""}
                {contextData?.currentOfferPopup === "NON_OFFER"
                  ? modalPopupData[modalType].msg
                  : ""}
              </p>
            </div>
          )}
          {conveyPopUp && (
            <RadiantBorder className="c_payment_convey_popup_radiant_border">
              <div className="c_convey_wrap">
                <div>
                  <p>Please Select Below Option for Proceeds to Payment</p>
                </div>
                <div className="c_select_convey_option">
                  <div
                    className={
                      selectConveyActive
                        ? "c_convey_opt active"
                        : "c_convey_opt"
                    }
                    onClick={() => {
                      checkedRadioButton("Yes");
                    }}
                  >
                    <input
                      className="c_radio_btn_convey_plan"
                      name="convey_yes"
                      id="convey_one"
                      type="radio"
                      defaultChecked="checked"
                      checked={selectedConveyOption === "Yes"}
                      onChange={() => {}}
                    />
                    <label htmlFor="convey_yes" className="c_convey_text">
                      Yes
                    </label>
                  </div>
                  <div
                    className={
                      selectConveyActive
                        ? "c_convey_opt active"
                        : "c_convey_opt"
                    }
                    onClick={() => {
                      checkedRadioButton("No");
                    }}
                  >
                    <input
                      className="c_radio_btn_convey_plan"
                      name="convey_no"
                      id="convey_two"
                      type="radio"
                      defaultChecked="checked"
                      checked={selectedConveyOption === "No"}
                      onChange={() => {}}
                    />
                    <label htmlFor="convey_no" className="c_convey_text">
                      No
                    </label>
                  </div>
                  <div
                    className={
                      selectConveyActive
                        ? "c_convey_opt active"
                        : "c_convey_opt"
                    }
                    onClick={() => {
                      checkedRadioButton("ForeGo");
                    }}
                  >
                    <input
                      className="c_radio_btn_convey_plan"
                      name="convey_forego"
                      id="convey_three"
                      type="radio"
                      defaultChecked="checked"
                      checked={selectedConveyOption === "ForeGo"}
                      onChange={() => {}}
                    />
                    <label htmlFor="convey_forego" className="c_convey_text">
                      ForeGo
                    </label>
                  </div>
                </div>
                <div
                  className={
                    selectedConveyOption == ""
                      ? "c_convey_modal_btn payment_disabled"
                      : "c_convey_modal_btn "
                  }
                >
                  <button
                    className="c-convey_btn"
                    onClick={() => {
                      if (selectedConveyOption !== "") {
                        continueConveyFunc(selectedConveyOption);
                      }
                    }}
                  >
                    <span className="c_convey_proceed_payment">
                      Proceed to pay
                    </span>
                    <span className="c_icon_img">
                      <img
                        className="c_right_arrow_icon"
                        src="./assets/images/white-right-arrow.svg"
                      />
                    </span>
                  </button>
                </div>
              </div>
            </RadiantBorder>
          )}
          {showOdbcWaiverGrid && (
            <RadiantBorder className="c_payment_convey_popup_radiant_border">
              <div className="c_convey_wrap">
                <div className="waiver-grid">
                  <p className="waiver-grid-head">Congratulations...!!!</p>
                  <p className="waiver-grid-head">
                    {" "}
                    You got a financial benefit on Charges Overdue Amount.
                  </p>
                  {/* <p className="waiver-grid-para">
                  Your got off ₹{" "}
                  {contextData?.odbcOfferRes?.odbcEnteredAmount -
                    contextData?.odbcOfferRes?.odbcWaiverAmount}{" "}
                  towards your loan account
                </p> */}
                  <p className="animated-text-container">
                    <span
                      className="animated-text"
                      onAnimationEnd={handleAnimationEnd}
                    >
                      ₹ {contextData?.odbcOfferRes?.odbcEnteredAmount}/-{" "}
                    </span>
                    <span className="waiver-grid-para">
                      {showOdbcPopupAnimation
                        ? `₹ ${contextData?.odbcOfferRes?.odbcWaiverAmount}/- Amount
                      proceed for payment towards your loan account.`
                        : null}
                    </span>
                  </p>
                </div>
              </div>
            </RadiantBorder>
          )}
        </Modal>
      ) : (
        ""
      )}
      <RadiantBorder
        className={`${
          attemptFlag
            ? "c_payment_radiant_border payment_disabled "
            : "c_payment_radiant_border"
        } ${
          preapprovedOfferFlag
            ? "payment-component-border-phase2"
            : "payment-component-border-phase1"
        }`}
      >
        {preapprovedOfferFlag ? (
          <div className="c_pre_approved_offer_wrapper">
            <div className="c_pre_approved_offer_msg">
              <img src="assets/images/offer-bell.svg" alt="" />
              <div>
                <p>You have a Pre-approved {label1} offer!</p>
                {/* <p>Enter {label2} amount here:</p> */}
              </div>
            </div>
            <div className="c_pre_approved_offer_actions">
              <div className="radio-settlement-input">
                <input
                  type="radio"
                  id="settle_amt"
                  checked={true}
                  onChange={() => {}}
                />
                <label htmlFor="settle_amt" className="payment-input-label">
                  {/* {label3} Amount */}
                  Enter Amount Here:
                </label>
              </div>
              <div className="payment-input-box">
                <Input
                  className="c_offer_amt"
                  inputType="withUnit"
                  unit="₹"
                  placeholder={amount}
                  type="text"
                  name="amount"
                  value={amount}
                  onKeyPress={stopEnter}
                  onChange={handleAmount}
                  onBlur={onBlur}
                  id="settlement-amount-input"
                  defaultChecked="checked"
                  ref={inputRef}
                  style={{ cursor: isImageClicked ? "text" : "default" }}
                  // style={{ cursor: isInputClicked || isImageClicked ? 'text' : 'default' }}
                  onClick={handleInputClick}
                  onCopy={(e) => preventCopyPaste(e)}
                  onPaste={(e) => preventCopyPaste(e)}
                  onCut={(e) => preventCopyPaste(e)}
                />
                {isInputClicked ? null : (
                  <img
                    className="edit-pencil-icon"
                    src="assets/images/editInputIcon.svg"
                    alt="symbol"
                    onClick={handleImageClick}
                  />
                )}
                {/* <img
                    className="edit-pencil-icon"
                    src="assets/images/editInputIcon.svg"
                    alt="symbol"
                  /> */}
              </div>
            </div>
            <div className="c_pre_approved_offer_satus_eg">
              {popOverFlag && (
                <>
                  {contextData?.currentOfferPopup === "SETTLEMENT_CAMPAIGN" ? (
                    <RadiantBorder className="c_radian_border">
                      {fcZeroValid ? (
                        <div className="foreclosure_container1_eg_fc_valid">
                          <p className="foreclosure_text_fc_valid">
                            Please enter an amount which is greater than 0 !
                          </p>
                        </div>
                      ) : (
                        <>
                          {fcValidFlag ? (
                            <div className="foreclosure_container1_eg_fc_valid">
                              <p className="foreclosure_text_fc_valid">
                                You can not enter more than foreclosure amount !
                              </p>
                            </div>
                          ) : (
                            <div className="foreclosure_container1_eg">
                              <div
                                className="foreclosure_container1_checkbox"
                                onClick={toggle}
                              >
                                <input
                                  type="checkbox"
                                  name=""
                                  id="foreclosure_input_field"
                                  checked={ischecked}
                                  onChange={() => {}}
                                />
                              </div>
                              <div id="foreclosure_disclaimer">
                                <p className="foreclosure_text">
                                  {/* Your foreclosure amount is ₹{fcToolTip} and
                                  the settlement amount entered is ₹
                                  {changeInpAmount}, which is{" "}
                                  {remainAmountFlag ? (
                                    <span className="foreclosure_span_text_positive">
                                      ₹{remAmountToolTip} greater.
                                    </span>
                                  ) : (
                                    <span className="foreclosure_span_text_negative">
                                      ₹{remAmountToolTip} lesser.
                                    </span>
                                  )}{" "}
                                  Are you sure you want to move ahead? */}
                                  Entered amount is Rs.{remAmountToolTip} lower
                                  than the Foreclosure Amount. Proceed?
                                </p>
                              </div>
                              <div className="foreclosure_triangle"></div>
                            </div>
                          )}
                        </>
                      )}
                    </RadiantBorder>
                  ) : contextData?.currentOfferPopup ===
                    "EMI_OVERDUE_CAMPAIGN" ? (
                    <RadiantBorder className="c_radian_border">
                      <div className="foreclosure_container1_eg">
                        <div id="foreclosure_disclaimer">
                          <p className="foreclosure_text">
                            Your Enter Amount is greater than Total Overdue
                            Amount, Please entered amount which is equal or less
                            than your Total Overdue Amount
                          </p>
                        </div>
                        <div className="foreclosure_triangle"></div>
                      </div>
                    </RadiantBorder>
                  ) : contextData?.currentOfferPopup === "ODBC_CAMPAIGN" ? (
                    <RadiantBorder className="c_radian_border">
                      <div className="foreclosure_container1_eg">
                        <div id="foreclosure_disclaimer">
                          <p className="foreclosure_text">
                            Your Entered Amount is greater than remaining
                            Overdue Charges Amount, Please entered amount which
                            is equal to your Charges Overdue Amount
                          </p>
                        </div>
                        <div className="foreclosure_triangle"></div>
                      </div>
                    </RadiantBorder>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            {settlementValid && (
              <div className="settlement-validation_text">
                Your entered amount is greater than your foreclosure amount !!!
              </div>
            )}
            <div className="c_pre_approved_offer_satus">
              {popOverFlag && (
                // <div className="foreclosure_container">
                //   <p className="foreclosure_text">
                //     Your foreclosure amount is ₹{excel_foreclosure_amount} and
                //     the settlement amount entered is ₹{amount}, which is{" "}
                //     <span className="foreclosure_span_text">₹30,000</span>{" "}
                //     lesser. Are you sure you want to move ahead?
                //   </p>
                //   {/* <div className="foreclosure_container_btn">
                //     <button className="foreclosure_container_yes">Yes</button>
                //     <button className="foreclosure_container_no">No</button>
                //   </div> */}
                // </div>
                // <div className="foreclosure_container1">
                //   <div className="foreclosure_container1_checkbox" onClick={toggle}>
                //     <input type="checkbox" name="" id="" checked={ischecked} />
                //   </div>
                //   <div>
                //     <p className="foreclosure_text">
                //       Your foreclosure amount is ₹{excel_foreclosure_amount} and the settlement
                //       amount entered is ₹{amount}, which is{" "}
                //       <span className="foreclosure_span_text">₹30,000</span>{" "}
                //       lesser. Are you sure you want to move ahead?
                //     </p>
                //   </div>
                // </div>
                <></>
              )}
              {/* {popOverFlag && (
                <div className="foreclosure_container_btn">
                  <button
                    className="foreclosure_container_yes"
                    onClick={handleDisclaimerYes}
                  >
                    Yes
                  </button>
                  <button
                    className="foreclosure_container_no"
                    onClick={handleDisclaimerNo}
                  >
                    No
                  </button>
                </div>
              )} */}
            </div>
          </div>
        ) : (
          <div>
            <h2 className="c_payment_options">{paymentOptions}</h2>
            <div
              className={
                contextData?.currentActiveData?.system === "ccod"
                  ? "c_payment_card_flex c_ccod_payment"
                  : "c_payment_card_flex"
              }
            >
              {paymentCardArr.map((paymentCard, index) => (
                <PaymentCard
                  key={paymentCard.id}
                  disabled={paymentCard.disabled}
                  isInput={paymentCard.isInput}
                  handleActive={() => handleActive(index, paymentCard)}
                  labelName={paymentCard.labelName}
                  amount={paymentCard?.amount.toString()}
                  isActive={paymentCard.labelName === selectedLabel}
                  index={index}
                  onChange={paymentCard.isInput ? handleInput : null}
                  value={paymentCard.isInput ? paymentCard.amount : ""}
                />
              ))}
            </div>
          </div>
        )}

        <div className="c_payment_additional_details">
          <p className="c_payment_additional_details_text">
            {additionalDetails}
          </p>
          <div className="c_payment_input_parent">
            <div className="c_payment_additional_flex c_payment_additional_margin_purpose">
              <p className="c_payment_additional c_number">
                {additionalNumber}
              </p>
              <div className="c_addition_info_wrapper">
                <Input
                  type="number"
                  name="mobile"
                  className={
                    validateFlagMobile
                      ? "c_payment_default_input error_input"
                      : "c_payment_default_input"
                  }
                  value={paymentForm.value.mobile}
                  onChange={(e) => {
                    handleMobEmailInput(e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  validateFlagMobile
                />
                {/* <span className="c_addition_info">
                  {validateFlagMobile &&
                    paymentForm.control.mobile.value != "" &&
                    // paymentForm.control.mobile.error?.pattern &&
                    "Not a valid number"}
                </span> */}
              </div>
            </div>
            {/* {phoneValid ? (
              <span className="c_addition_info">Invalid Number...!!!</span>
            ) : (
              <></>
            )} */}
            <div className="c_payment_additional_flex">
              <p className="c_payment_additional c_email">
                {additionalEmailId}
              </p>
              <div className="c_addition_info_wrapper">
                <Input
                  className={
                    validateFlagEmail &&
                    paymentForm.control.email.value != "" &&
                    paymentForm.control.email.error?.message
                      ? "c_payment_default_input error_input"
                      : "c_payment_default_input"
                  }
                  name="email"
                  value={paymentForm.value.email}
                  onChange={(e) => {
                    handleEmailInput(e);
                  }}
                  validateFlagEmail
                />
                {/* <span className="c_addition_info">{(validateFlagEmail && paymentForm.control.email.value != "") && paymentForm.control.email.error?.message}</span> */}
              </div>
            </div>
          </div>
        </div>
      </RadiantBorder>
      <div className="c_button_container">
        <div
          className={`${
            attemptFlag
              ? "c_border_line disabled_c_border payment_disabled"
              : "c_border_line"
          } ${
            preapprovedOfferFlag
              ? "payment-component-bottom-button-border2 disabled_c_border2"
              : "payment-component-bottom-button-border1 disabled_c_border1"
          }`}
        >
          <div className="c_bg_button">
            <Button
              type="button"
              className={`${
                payNowFlag
                  ? "disabled-btn payment_disabled"
                  : attemptFlag
                  ? "payment_disabled"
                  : ""
              } c_btn ${disableBtn ? "disabled-btn" : ""}`}
              onClick={handleClick}
            >
              <p className="c_btn_text">Pay Now</p>
              <img src="assets/images/vector.svg" alt="symbol" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Payment.propTypes = {
  paymentOptions: PropTypes.string,
  additionalDetails: PropTypes.string,
  additionalNumber: PropTypes.string,
  additionalEmailId: PropTypes.string,
  data: PropTypes.array,
  setFailurePopUpContent: PropTypes.func,
  setFailurePopUpModal: PropTypes.func,
  setPaymentOptClickCount: PropTypes.func,
  handleClickForPaymentOpt: PropTypes.func,
  paymentOptClickCount: PropTypes.array,
};

export default Payment;
