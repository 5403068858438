import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckCreditScore from "../../../Retail/src/components/CheckCreditScore";
import CreditScoreDisplay from "../../../Retail/src/components/CreditScoreDisplay";
import DataMapping from "../../../Shared/context/data-mapping";

const CreditScore = ({portalType}) => {

    const location = useLocation()
    const creditScoreGenerated = location.state?.creditScore
    const [contextData, dispatchData] = useContext(DataMapping)
    const [creditScoreFormFlag, setCreditScoreFormFlag] = useState(true)
    const [finalCreditScore, setFinalCreditScore] = useState("")
    const navigate = useNavigate();
    const [postOtpFlag, setPostOtpFlag] = useState(false)
    useEffect(() => {
        if (contextData.length === 0) {
            navigate("/login")
        } else {

            checkCreditScorePageCall()
        }

    }, [postOtpFlag])

    const checkCreditScorePageCall = () => {

        var creditscoreredirect = contextData.getUserDataV3.body.CREDIT_SCORE;
        if(contextData.getUserDataV3.body.creditScoreDetails && Object.keys(contextData.getUserDataV3.body.creditScoreDetails).length !==0){
            if ((creditscoreredirect === "") && (creditScoreGenerated === undefined)) {
                setCreditScoreFormFlag(true)
            } else if (creditScoreGenerated !== "") {
                setCreditScoreFormFlag(false)
                setFinalCreditScore(creditScoreGenerated)
            } else {
                setCreditScoreFormFlag(false)
                setFinalCreditScore(creditscoreredirect)
            }
        }else{
            setCreditScoreFormFlag(true)
        }
    }

    return (
        <>
            {contextData.length !== 0  ? creditScoreFormFlag ? <CheckCreditScore postOtpFlag={postOtpFlag} setPostOtpFlag={setPostOtpFlag} portalType={portalType}/> : <CreditScoreDisplay portalType={portalType} creditScore={finalCreditScore}/> : ""}
        </>
    )
}

export default CreditScore