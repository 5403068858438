import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getQualitricsUrl = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "userId": "107",
            "lan": "TCFPL0269000010001685",
            "entity": "TCHFL",
            "accountStatus": "open",
            "customerName": "",
            "emailId": "karan.sanghavi@teknopoint.in",
            "mobileNo": "9594779789"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/qualitrics/get-qualitrics-url/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/qualitrics/get-qualitrics-url/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getQualitricsUrl;