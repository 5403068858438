import React, { useState, useEffect, useContext } from "react";
import './CreditScore.scss';
import RadiantBorder from "../RadiantBorder/RadiantBorder";
import CreditScoreDiagram from "../CreditScoreDiagram/CreditScoreDiagram";
import DidYouKnow from "../DidYouKnow/DidYouKnow";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, useLocation } from "react-router";
import DataMapping from "../../../../Shared/context/data-mapping";
import KnowMore from "../KnowMore/KnowMore";


const CreditScore = () => {

    const [contextData] = useContext(DataMapping)
    const [creditScoreFormFlag, setCreditScoreFormFlag] = useState(true)
    const [finalCreditScore, setFinalCreditScore] = useState("")
    const navigate = useNavigate();
    const location = useLocation();
    const creditScoreGenerated = location.state?.creditScore
    const [postOtpFlag] = useState(false)
    useEffect(() => {
        if (contextData.length === 0) {
            navigate("/login")
        } else {
            checkCreditScorePageCall()
        }

    }, [postOtpFlag])

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    const handleScore = () => {
        navigate('/credit-score')
    }


    const checkCreditScorePageCall = () => {
        var creditscoreredirect = contextData.getUserDataV3.body.CREDIT_SCORE;
        if (contextData.getUserDataV3.body.creditScoreDetails && Object.keys(contextData.getUserDataV3.body.creditScoreDetails).length !== 0) {
            if ((creditscoreredirect === "") && (creditScoreGenerated === undefined)) {
                setCreditScoreFormFlag(true)
            } else if (creditScoreGenerated !== "") {
                setCreditScoreFormFlag(false)
                setFinalCreditScore(creditScoreGenerated)
            } else {
                setCreditScoreFormFlag(false)
                setFinalCreditScore(creditscoreredirect)
            }
        } else {
            setCreditScoreFormFlag(true)
        }



    }


    return (
        <div>
            <RadiantBorder className="c_credit_score_border">
                <div className="c_slider_parent">
                    <Slider {...settings}>
                        {contextData.length !== 0 && (
                            <CreditScoreDiagram
                                handleScore={handleScore}
                                isBlur={creditScoreFormFlag}
                                creditScoreText={creditScoreFormFlag ? "You can bring your credit score up in a matter of months with disciplined credit and repayment behaviour. Check your online credit score now." : "Enhance your creditworthiness by acquiring a secure credit card. With our credit builder product, you can boost your credit score!"}
                                getCreditScore={creditScoreFormFlag ? "?" : finalCreditScore || contextData.getUserDataV3.body.CREDIT_SCORE}
                                creditScoreStatus={creditScoreFormFlag ? "Find your score!" : "Excellent"}
                                btnText={creditScoreFormFlag ? "Generate Credit Score" : "View Report"}
                            />
                        )}
                        <DidYouKnow heading="Did you know?" />
                    </Slider>
                </div>
            </RadiantBorder>
        </div>
    )
}



export default CreditScore;