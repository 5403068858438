import React, { useState } from "react";
import PropTypes from "prop-types";

//Context
import { useContext } from "react";

//CSS
import "./Input.scss";
import Tooltip from "../../components/Tooltip/Tooltip";

const Input = ({
    className,
    error,
    errorSpace = true,
    type,
    ref,
    onFocus,
    dynamicError,
    disabled = false,
    editIcon,
    maxLength,
    id,
    tooltipMessage,
    // ErrorName,
    // c = false,
    // apiErrorName,
    ...otherProps
}) => {
    const [inputDisabled, setInputDisabled] = useState(disabled)
    // console.log(apiError ? ErrorName : "");
    return (
        <>
            <div className="input-div">
                <input
                    id={id}
                    className={`${className} ${dynamicError ? "" : error ? "input-error" : ""}`}
                    maxLength={maxLength}
                    ref={ref}
                    onFocus={onFocus}
                    type={type ? type : "text"}
                    disabled={inputDisabled ? true : false}
                    {...otherProps}
                />
                {
                    editIcon &&
                    <label onClick={() => {

                        setInputDisabled(false)
                    }} htmlFor={id}>
                        <div className="edit_Img">
                            <img
                                src="assets/images/edit-icon.svg"
                            />
                        </div>
                    </label>

                }
                {tooltipMessage && tooltipMessage !== "" ? <Tooltip label={tooltipMessage} /> : null}

            </div>
            {errorSpace && (
                <div className="error-span">
                    <span>{error}</span>
                </div>
            )}
        </>
    );
};

Input.propTypes = {
    className : PropTypes.string,
    error: PropTypes.node,
    errorSpace : PropTypes.bool,
    type: PropTypes.string,
    ref : PropTypes.string,
    onFocus : PropTypes.func,
    dynamicError : PropTypes.bool,
    disabled : PropTypes.bool,
    editIcon : PropTypes.bool,
    maxlength : PropTypes.number,
    id: PropTypes.string,
    tooltipMessage : PropTypes.string
};

export default Input;
