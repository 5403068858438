import React from 'react'
import './LoanInfoComponent.scss'
import PropTypes from 'prop-types';
const LoanInfoComponent = ({children}) => {
    return (
        <>
        {children}
        </>
    )
}

LoanInfoComponent.propTypes = {
    children: PropTypes.any
}

export default LoanInfoComponent
