import getDisbursalDetails from "../../apis/get-disbursal-details/get-disbursal-details"
import commonFunctionObject from "../../common-function"

const DisbursalInformationObjectService = {

}

const checkPageName = (pageName) => {
    if (pageName === "pending-disbursal") {
        return "disbursal-status"
    } else {
        return "tab2"
    }

}
DisbursalInformationObjectService.checkPageName = checkPageName


const getDisbursalDetailsService = (requestObj, activeCCODContract, activeCcodAcc, summaryData, page) => {
    return new Promise((resolve, reject) => {
        getDisbursalDetails(requestObj).then((getDisbursalDetailsResponse) => {
            if (getDisbursalDetailsResponse) {
                if (getDisbursalDetailsResponse.header.status.toUpperCase() === "SUCCESS") {
                    if (Object.keys(getDisbursalDetailsResponse.body).length === 0) {
                        resolve({ "finalData": [], "page": page })
                    } else {
                        var finalData = getDisbursalDetailsApiResponseHandler(getDisbursalDetailsResponse, activeCCODContract, activeCcodAcc, summaryData, page);
                        resolve({ "finalData": finalData, "page": page })
                    }
                } else {
                    reject("C-14")
                }

            } else {
                reject("C-15")
            }
        }).catch((error) => {
            reject("C-16")
        })
    })

}

const getDisbursalDetailsApiResponseHandler = (response, ccodAccountNo, activeCcodAcc, summaryData, page) => {
    var localStringObj = {
        style: "currency",
        currency: "INR",
        decimal: '2'
    }

    var disbursalStatusArr = response.body.I_ZTW_DISB_NONTML_LOG.ITEM;

    var disbursalArray = [];
    if (Array.isArray(disbursalStatusArr)) {
        var filteredDisbursalData = disbursalStatusArr.filter(function (data) {
            return data.HERSE == ccodAccountNo;
        })
        if (filteredDisbursalData.length === 0) {
            var noDataFound = document.getElementById('noDataFound');
            noDataFound.classList.remove('display-none');

            var disbursalStatusTable = document.getElementById('disbursalStatus-tableBody');
            disbursalStatusTable.classList.add('display-none');
        } else {
            disbursalStatusArr.forEach(function (element) {
                var disbursalStatusObj = {};
                disbursalStatusObj["LOCATION"] = (element.LOCATION) ? (element.LOCATION).toString() : '-';
                disbursalStatusObj["ZAUTH_DT"] = (element.ZAUTH_DT) ? (element.ZAUTH_DT).toString() : '-';
                disbursalStatusObj["GJAHR"] = (element.GJAHR) ? (element.GJAHR).toString() : '-';
                disbursalStatusObj["ZREQ_AMT"] = (element.ZREQ_AMT) ? Number((element.ZREQ_AMT).toString()) : '0';
                disbursalStatusObj["ZMAN_VEN_NAME"] = (element.ZMAN_VEN_NAME) ? (element.ZMAN_VEN_NAME).toString() : '-';
                disbursalStatusObj["BANK_REF_NO"] = (element.BANK_REF_NO) ? (element.BANK_REF_NO).toString() : '-';
                disbursalStatusObj["ZCRE_TIME"] = (element.ZCRE_TIME) ? (element.ZCRE_TIME).toString() : '-';
                disbursalStatusObj["BUDAT"] = (element.BUDAT) ? (element.BUDAT).toString() : '-';
                disbursalStatusObj["ZAUTH_STAT"] = (element.ZAUTH_STAT) ? (element.ZAUTH_STAT).toString() : '-';
                disbursalStatusObj["MANU_CO_CD_TCL"] = (element.MANU_CO_CD_TCL) ? (element.MANU_CO_CD_TCL).toString() : '-';
                disbursalStatusObj["BUKRS"] = (element.BUKRS) ? (element.BUKRS).toString() : '-';
                disbursalStatusObj["HERSE"] = (element.HERSE) ? (element.HERSE).toString() : '-';
                disbursalStatusObj["PNAME"] = (element.PNAME) ? (element.PNAME).toString() : '-';
                disbursalStatusObj["ZTSLSALESORD"] = (element.ZTSLSALESORD) ? (element.ZTSLSALESORD).toString() : '-';
                disbursalStatusObj["ZREJ_STAT"] = (element.ZREJ_STAT) ? (element.ZREJ_STAT).toString() : '-';
                disbursalStatusObj["PRODUCT"] = (element.PRODUCT) ? (element.PRODUCT).toString() : '-';
                disbursalStatusObj["ACT_AVAIL_LIMIT"] = (element.ACT_AVAIL_LIMIT) ? (element.ACT_AVAIL_LIMIT).toString() : '-';
                disbursalStatusObj["ZDIS_AMT"] = (element.ZDIS_AMT) ? Number((element.ZDIS_AMT).toString()) : '0';
                disbursalStatusObj["ZAUTH_USER"] = (element.ZAUTH_USER) ? (element.ZAUTH_USER).toString() : '-';
                disbursalStatusObj["ZAUTH_TIME"] = (element.ZAUTH_TIME) ? (element.ZAUTH_TIME).toString() : '-';
                disbursalStatusObj["ZREF_NO"] = (element.ZREF_NO) ? (element.ZREF_NO).toString() : '-';
                disbursalStatusObj["MANU_CO_CODE"] = (element.MANU_CO_CODE) ? (element.MANU_CO_CODE).toString() : '-';
                disbursalStatusObj["BELNR"] = (element.BELNR) ? (element.BELNR).toString() : '-';
                disbursalStatusObj["REGION"] = (element.REGION) ? (element.REGION).toString() : '-';
                disbursalStatusObj["ZTSLVENCODE"] = (element.ZTSLVENCODE) ? (element.ZTSLVENCODE).toString() : '-';
                disbursalStatusObj["ZUSER"] = (element.ZUSER) ? (element.ZUSER).toString() : '-';
                disbursalStatusObj["ZDEL_VEN"] = (element.ZDEL_VEN) ? (element.ZDEL_VEN).toString() : '-';
                disbursalStatusObj["ZDEL_VEN_NAME"] = (element.ZDEL_VEN_NAME) ? (element.ZDEL_VEN_NAME).toString() : '-';
                disbursalStatusObj["ZCRE_DT"] = (element.ZCRE_DT) ? (element.ZCRE_DT).toString() : '-';
                disbursalStatusObj["ZREF_DT"] = (element.ZREF_DT) ? (element.ZREF_DT).toString() : '-';

                disbursalArray.push(disbursalStatusObj);
            })
        }
    } else {
        if (disbursalStatusArr.HERSE === ccodAccountNo) {
            var disbursalStatusObj = {};
            disbursalStatusObj["LOCATION"] = (disbursalStatusArr.LOCATION) ? (disbursalStatusArr.LOCATION).toString() : '-';
            disbursalStatusObj["ZAUTH_DT"] = (disbursalStatusArr.ZAUTH_DT) ? (disbursalStatusArr.ZAUTH_DT).toString() : '-';
            disbursalStatusObj["GJAHR"] = (disbursalStatusArr.GJAHR) ? (disbursalStatusArr.GJAHR).toString() : '-';
            disbursalStatusObj["ZREQ_AMT"] = (disbursalStatusArr.ZREQ_AMT) ? Number((disbursalStatusArr.ZREQ_AMT).toString()) : '0';
            disbursalStatusObj["ZMAN_VEN_NAME"] = (disbursalStatusArr.ZMAN_VEN_NAME) ? (disbursalStatusArr.ZMAN_VEN_NAME).toString() : '-';
            disbursalStatusObj["BANK_REF_NO"] = (disbursalStatusArr.BANK_REF_NO) ? (disbursalStatusArr.BANK_REF_NO).toString() : '-';
            disbursalStatusObj["ZCRE_TIME"] = (disbursalStatusArr.ZCRE_TIME) ? (disbursalStatusArr.ZCRE_TIME).toString() : '-';
            disbursalStatusObj["BUDAT"] = (disbursalStatusArr.BUDAT) ? (disbursalStatusArr.BUDAT).toString() : '-';
            disbursalStatusObj["ZAUTH_STAT"] = (disbursalStatusArr.ZAUTH_STAT) ? (disbursalStatusArr.ZAUTH_STAT).toString() : '-';
            disbursalStatusObj["MANU_CO_CD_TCL"] = (disbursalStatusArr.MANU_CO_CD_TCL) ? (disbursalStatusArr.MANU_CO_CD_TCL).toString() : '-';
            disbursalStatusObj["BUKRS"] = (disbursalStatusArr.BUKRS) ? (disbursalStatusArr.BUKRS).toString() : '-';
            disbursalStatusObj["HERSE"] = (disbursalStatusArr.HERSE) ? (disbursalStatusArr.HERSE).toString() : '-';
            disbursalStatusObj["PNAME"] = (disbursalStatusArr.PNAME) ? (disbursalStatusArr.PNAME).toString() : '-';
            disbursalStatusObj["ZTSLSALESORD"] = (disbursalStatusArr.ZTSLSALESORD) ? (disbursalStatusArr.ZTSLSALESORD).toString() : '-';
            disbursalStatusObj["ZREJ_STAT"] = (disbursalStatusArr.ZREJ_STAT) ? (disbursalStatusArr.ZREJ_STAT).toString() : '-';
            disbursalStatusObj["PRODUCT"] = (disbursalStatusArr.PRODUCT) ? (disbursalStatusArr.PRODUCT).toString() : '-';
            disbursalStatusObj["ACT_AVAIL_LIMIT"] = (disbursalStatusArr.ACT_AVAIL_LIMIT) ? (disbursalStatusArr.ACT_AVAIL_LIMIT).toString() : '-';
            disbursalStatusObj["ZDIS_AMT"] = (disbursalStatusArr.ZDIS_AMT) ? Number((disbursalStatusArr.ZDIS_AMT).toString()) : '0';
            disbursalStatusObj["ZAUTH_USER"] = (disbursalStatusArr.ZAUTH_USER) ? (disbursalStatusArr.ZAUTH_USER).toString() : '-';
            disbursalStatusObj["ZAUTH_TIME"] = (disbursalStatusArr.ZAUTH_TIME) ? (disbursalStatusArr.ZAUTH_TIME).toString() : '-';
            disbursalStatusObj["ZREF_NO"] = (disbursalStatusArr.ZREF_NO) ? (disbursalStatusArr.ZREF_NO).toString() : '-';
            disbursalStatusObj["MANU_CO_CODE"] = (disbursalStatusArr.MANU_CO_CODE) ? (disbursalStatusArr.MANU_CO_CODE).toString() : '-';
            disbursalStatusObj["BELNR"] = (disbursalStatusArr.BELNR) ? (disbursalStatusArr.BELNR).toString() : '-';
            disbursalStatusObj["REGION"] = (disbursalStatusArr.REGION) ? (disbursalStatusArr.REGION).toString() : '-';
            disbursalStatusObj["ZTSLVENCODE"] = (disbursalStatusArr.ZTSLVENCODE) ? (disbursalStatusArr.ZTSLVENCODE).toString() : '-';
            disbursalStatusObj["ZUSER"] = (disbursalStatusArr.ZUSER) ? (disbursalStatusArr.ZUSER).toString() : '-';
            disbursalStatusObj["ZDEL_VEN"] = (disbursalStatusArr.ZDEL_VEN) ? (disbursalStatusArr.ZDEL_VEN).toString() : '-';
            disbursalStatusObj["ZDEL_VEN_NAME"] = (disbursalStatusArr.ZDEL_VEN_NAME) ? (disbursalStatusArr.ZDEL_VEN_NAME).toString() : '-';
            disbursalStatusObj["ZCRE_DT"] = (disbursalStatusArr.ZCRE_DT) ? (disbursalStatusArr.ZCRE_DT).toString() : '-';
            disbursalStatusObj["ZREF_DT"] = (disbursalStatusArr.ZREF_DT) ? (disbursalStatusArr.ZREF_DT).toString() : '-';

            disbursalArray.push(disbursalStatusObj);
        } else {
            var noDataFound = document.getElementById('noDataFound');
            noDataFound.classList.remove('display-none');

            var disbursalStatusTable = document.getElementById('disbursalStatus-tableBody');
            disbursalStatusTable.classList.add('display-none');
        }
    }

    var combinedDisbursalArray = sendDisbursalStatusDataTosummaryData(disbursalArray, ccodAccountNo, activeCcodAcc, summaryData, page);
    var finalData = disbursalStatusTemplate(combinedDisbursalArray, ccodAccountNo, activeCcodAcc, summaryData, page)
    return finalData
    //pendingDisbursalRenderObj.pendingDisbursalMob(combinedDisbursalArray);
}

DisbursalInformationObjectService.getDisbursalDetailsService = getDisbursalDetailsService


const sendDisbursalStatusDataTosummaryData = (disbursalData, ccodAccountNo, activeCcodAcc, summaryData, page) => {
    var tableData = [];
    var product = summaryData.PRODUCT;

    disbursalData.forEach(function (data) {

        var newCCODDisbursalStatusObj = {}

        newCCODDisbursalStatusObj["ZREQ_AMT"] = (data.ZREQ_AMT, true) ? data.ZREQ_AMT : "0";
        newCCODDisbursalStatusObj["BANK_REF_NO"] = (data.BANK_REF_NO) ? data.BANK_REF_NO : "-";
        newCCODDisbursalStatusObj["ZDIS_AMT"] = (data.ZDIS_AMT, true) ? data.ZDIS_AMT : "0";
        newCCODDisbursalStatusObj["ZREF_NO"] = (data.ZREF_NO) ? data.ZREF_NO : "-"
        newCCODDisbursalStatusObj["ZREF_DT"] = (data.ZREF_DT) ? datechange(data.ZREF_DT) : "-";
        newCCODDisbursalStatusObj["BUDAT"] = (data.ZREF_DT) ? datechange(data.BUDAT) : "-";
        newCCODDisbursalStatusObj["BLDAT"] = (data.BLDAT) ? data.BLDAT : "-";
        newCCODDisbursalStatusObj["PRODUCT"] = (product) ? product : "-";
        newCCODDisbursalStatusObj["ZAUTH_STAT"] = (data.ZAUTH_STAT) ? data.ZAUTH_STAT : "-";
        newCCODDisbursalStatusObj["ZREJ_STAT"] = (data.ZREJ_STAT) ? data.ZREJ_STAT : "-";



        tableData.push(newCCODDisbursalStatusObj);

    });
    return tableData;

}

function datechange(date) {
    var changeDate = date.split("-");
    var newDateFormat = changeDate[2] + "-" + changeDate[1] + "-" + changeDate[0];
    return newDateFormat;
}

function disbursalStatusTemplate(tableData, ccodAccountNo, activeCcodAcc, summaryData, page) {
    var tableFinalData = []

    var sortedTableData = tableData.sort(function (a, b) {
        return datechangeRequest(b.ZREF_DT) > datechangeRequest(a.ZREF_DT) ? 1 : -1;
    });
    if (page === "disbursal-status") {
        sortedTableData.forEach(function (element) {
            var disbursalStatus = "-";
            if (element.ZAUTH_STAT) {
                if (element.ZAUTH_STAT === "X") {
                    disbursalStatus = "Success"
                }
            }
            if (element.ZREJ_STAT) {
                if (element.ZREJ_STAT === "X") {
                    disbursalStatus = "Failure"
                }
            }

            var tableObject = {}
            tableObject["requestDate"] = commonFunctionObject.replaceHyphenDate(element.ZREF_DT);
            tableObject["transactionId"] = element.ZREF_NO;
            tableObject["disbursalRequestAmount"] = "₹" + parseFloat(Number(element.ZREQ_AMT)).toLocaleString("en-IN");
            tableObject["disbursedAmount"] = "₹" + parseFloat(Number(element.ZDIS_AMT)).toLocaleString("en-IN");
            tableObject["disbursalDate"] = commonFunctionObject.replaceHyphenDate(element.BUDAT);
            tableObject["bankReferenceNumber"] = element.BANK_REF_NO;
            tableObject["disbursalStatus"] = disbursalStatus;
            tableFinalData.push(tableObject)
        })
    } else {
        sortedTableData.forEach(function (element) {

            var tableObject = {}
            tableObject["transactionId"] = element.ZREF_NO;
            tableObject["productName"] = element.PRODUCT;
            tableObject["disbursalRequestDate"] = commonFunctionObject.replaceHyphenDate(element.ZREF_DT);
            tableObject["disbursalRequestAmount"] = "₹" + parseFloat(Number(element.ZREQ_AMT)).toLocaleString("en-IN");
            tableObject["disbursedAmount"] = "₹" + parseFloat(Number(element.ZDIS_AMT)).toLocaleString("en-IN");
            tableFinalData.push(tableObject)
        })
    }


    return tableFinalData

}


function datechangeRequest(date) {
    var changeDate = date.split("-");
    var newDateFormatRequest = changeDate[2] + "-" + changeDate[1] + "-" + changeDate[0];
    return newDateFormatRequest;
}

export default DisbursalInformationObjectService