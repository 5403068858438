let themeState = "THEME_LIGHT"

export const initialLoadDetails = [
    {
        loanName: "personal_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Personal Loan-dark-icon.svg" : "assets/images/light-icon/Personal Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Personal Loan",
        isActive: false,
        applyRedirection: "https://www.tatacapital.com/online/loans/personal-loans/apply-now-personal-loan?sourceName=Web#!"
    },
    {
        loanName: "business_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Bussiness Loan-dark-icon.svg" : "assets/images/light-icon/Bussiness Loan-light-icon.svg",
        data: [],
        shortName: "BL",
        isSelected: false,
        lable: "Business Loan",
        isActive: false,
        applyRedirection: "https://businessloan.tatacapital.com/online/loans/business-loans/home?sourceName=Web#!"
    },
    {
        loanName: "home_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Home Loan-dark-icon.svg" : "assets/images/light-icon/Home Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Home Loan",
        isActive: false,
        applyRedirection: "https://www.tatacapital.com/online/loans/home-loans/apply-now-home-loan?sourceName=Web#!"
    },
    {
        loanName: "used_car_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Used Car Loan-dark-icon.svg" : "assets/images/light-icon/Used Car Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Used Car",
        isActive: false,
        applyRedirection: "https://www.tatacapital.com/used-car-loan/apply-now-used-car-loan.html?subsource=RetailWeb"
    },
    {
        loanName: "loan_against_property",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Loans Against Property-dark-icon.svg" : "assets/images/light-icon/Loans Against Property-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Loan Against Property",
        isActive: false,
        applyRedirection: "https://www.tatacapital.com/loan-against-property/apply-now-loan-against-property.html?subsource=RetailWeb"
    },
    {
        loanName: "two_wheeler",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Two Wheeler-dark-icon.svg" : "assets/images/light-icon/Two Wheeler-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Two Wheeler",
        isActive: false,
        applyRedirection: "https://www.tatacapital.com/two-wheeler-loan/apply-now-two-wheeler-loan.html?subsource=RetailWeb"
    },
    {
        loanName: "loan_against_security",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Loans Against Security-dark-icon.svg" : "assets/images/light-icon/Loans Against Security-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Loan Against Securities",
        isActive: false,
        applyRedirection: "https://www.tatacapital.com/loan-against-securities/apply-now-loan-against-securities.html?subsource=RetailWeb"
    },
    {
        loanName: "consumer_durable",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/consumer-durable-dark-icon.svg" : "assets/images/light-icon/consumer-durable-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Consumer Durable",
        isActive: false,
        applyRedirection: "https://www.tatacapital.com/consumer-durable-loan.html"
    },
    {
        loanName: "credit_card",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Credit Cards-dark-icon.svg" : "assets/images/light-icon/Credit Cards-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Credit Card",
        isActive: false,
        applyRedirection: "https://www.tatacard.com/tata-eapply/eapply-form.page/apply?path=personal/credit-cards/premium-cards/tata-platinum-card.dcr"
    },
    {
        loanName: "dropline_overdraft_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Overdraft-dark-icon.svg" : "assets/images/light-icon/Overdraft-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Dropline Overdraft Loan",
        isActive: false,
        applyRedirection: "https://www.tatacapital.com/personal-loan/overdraft-facility/eligibility-and-documents.html"
    },

    {
        loanName: "ceq_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/CEQ Loan-dark-icon.svg" : "assets/images/light-icon/CEQ Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "CEQ Loan",
        isActive: false,
        applyRedirection: ""
    },
    {
        loanName: "commercial_vehicle_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Commercial Vehicle Loan-dark-icon.svg" : "assets/images/light-icon/Commercial Vehicle Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Commercial Vehicle Loan",
        isActive: false,
        applyRedirection: ""
    },
    {
        loanName: "project_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Project Loan-dark-icon.svg" : "assets/images/light-icon/Project Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Project Loan",
        isActive: false,
        applyRedirection: ""
    },
    {
        loanName: "agri_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Agri Loan-dark-icon.svg" : "assets/images/light-icon/Agri Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Agri Loan",
        isActive: false,
        applyRedirection: ""
    },
    {
        loanName: "rural_auto_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Rural Auto Loan-dark-icon.svg" : "assets/images/light-icon/Rural Auto Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Rural Auto Loan",
        isActive: false,
        applyRedirection: ""
    },
    {
        loanName: "tractor_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Tractor Loan-dark-icon.svg" : "assets/images/light-icon/Tractor Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Tractor Loan",
        isActive: false,
        applyRedirection: ""
    },
    {
        loanName: "vehicle_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/Vehicle Loan-dark-icon.svg" : "assets/images/light-icon/Vehicle Loan-light-icon.svg",
        data: [],
        shortName: "PL",
        isSelected: false,
        lable: "Vehicle Loan",
        isActive: false,
        applyRedirection: "https://www.tatacapital.com/used-car-loan/apply-now-used-car-loan.html?subsource=RetailWeb"
    },
    {
        loanName: "insurance_loan",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/insurance-loan-dark-icon.svg" : "assets/images/light-icon/insurance-loan-light-icon.svg",
        data: [],
        shortName: "",
        isSelected: false,
        lable: "Insurance Loan",
        isActive: false,
        applyRedirection: ""
    },
    {
        loanName: "closed_account",
        icon: themeState === "THEME_DARK" ? "assets/images/dark-icon/closed-account-dark-icon.svg" : "assets/images/light-icon/closed-account-light-icon.svg",
        data: [],
        shortName: "",
        isSelected: false,
        lable: "Closed Account",
        isActive: false,
        applyRedirection: ""
    },
]