import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getSplitCharges = (reqObj) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "loanAcctNo": "TCHHL0404000100000007"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/dms/get-split-charges/v1', reqObj).then(response => {
        stalwartApiCall('POST', reqObj, '/shaft/api/dms/get-split-charges/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getSplitCharges;