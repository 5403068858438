import React from "react";
import App from "./src/app";
import { HashRouter } from "react-router-dom";
import PageNameProvider from "../Shared/context/ThemeContext/store/PageNameProvider";
import './index.scss';

export default () => (
    <PageNameProvider>
    <HashRouter>
        <App />
    </HashRouter>
    </PageNameProvider>
);