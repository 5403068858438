import React, { useContext } from "react";
import commonFunctionObject from "../../common-function";
import LoanInfoComponent from "../../components/LoanInfoComponent";
import DataMapping from "../../../../Shared/context/data-mapping";
import RadiantBorder from "../../global/RadiantBorder";
import "./BankDetails.scss";
import { bankDetailsArray } from "./bankDetailsArray";
import PropTypes from "prop-types";

const BankDetails = ({ activeContract }) => {
  const [contextData, dispatchData] = useContext(DataMapping);
  return (
    <div>
      <div className="bank-details">
        <div className="bank-data">
          <h2>Banking Details</h2>
          <RadiantBorder className="account-information-container">
            <div className="account-information-container-inner">
              <LoanInfoComponent>
                <ul className="loan-detail-info-ul">
                  {bankDetailsArray.bankDetails.map((value, index) => {
                    return (
                      <li key={index}>
                        <div className="label">{value.label}</div>
                        <div className="label-amount">
                          {
                            contextData?.bankingDetail[activeContract][
                              value.apiKey
                            ]
                          }
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </LoanInfoComponent>
            </div>
          </RadiantBorder>
        </div>
        <div className="bank-data-loan-term">
          <div className="bank-data">
            <h2>EMI and Payment Details</h2>
            <RadiantBorder className="account-information-container">
              <div className="account-information-container-inner">
                <LoanInfoComponent>
                  <ul className="loan-detail-info-ul">
                    {bankDetailsArray.interestDetails.map((value, index) => {
                      let lastAmountPaid =
                        contextData?.bankingDetail[activeContract][
                          value.apiKey
                        ].split(" ")[1];
                      //   console.log(lastAmountPaid, "lastAmountPaid");
                      // console.log(contextData?.bankingDetail[activeContract],"assdhebchedg");
                      const numericAmount = parseFloat(
                        lastAmountPaid?.replace(/,/g, "")
                      );
                      const formattedAmount =
                        numericAmount.toLocaleString("en-IN");
                      console.log(contextData, "contextData");
                      console.log(
                        contextData?.bankingDetail[activeContract][
                          value.apiKey
                        ],
                        "hcdhchjd"
                      );
                      return (
                        <li key={index}>
                          <div className="label">{value.label}</div>
                          {value.apiKey === "lastAmountPaid" ? (
                            <div className="label-amount">
                              ₹ {formattedAmount}
                            </div>
                          ) : (
                            <div className="label-amount">
                              {
                                contextData?.bankingDetail[activeContract][
                                  value.apiKey
                                ]
                              }
                            </div>
                          )}
                          <div className="label-amount"></div>
                        </li>
                      );
                    })}
                  </ul>
                </LoanInfoComponent>
              </div>
            </RadiantBorder>
          </div>
        </div>
      </div>
    </div>
  );
};

BankDetails.propTypes = {
  activeContract: PropTypes.string,
};

export default BankDetails;
