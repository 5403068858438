import React from 'react';
import './Agreement.scss';
import Modal from '../../global/Modal'
import RadiantBorder from '../../global/RadiantBorder';
import Label from '../Forms/Label'
import Button from '../../global/Button'
import OverviewServiceObject from '../../services/Overview';
import commonFunctionObject from '../../common-function';
import { useState, useContext } from 'react';
// import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import ThemeContext from '../../../../Shared/context/ThemeContext/store/ThemeContext';
import DataMapping from '../../../../Shared/context/data-mapping';
import FailurePopUp from '../../global/FailurePopUp';
import { useNavigate } from 'react-router';
import CustomerServicePopUp from '../CustomerServicePopUp';
import PropTypes from 'prop-types';
import Input from '../../global/Input';
import { STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type';



const Agreement = ({ setAgreementPopUp, setPopUpContent, setserviceRequestPopup, setLetterPopup, setFailurePopUpModal, setFailurePopUpContent, failurePopUpModal, failurePopUpContent, requestRaisedModal, setRequestRaisedModal, content, setContent }) => {
    const [themeState] = useContext(ThemeContext);
    const [data, dispatchData] = useContext(DataMapping);
    const [emailError, setEmailError] = useState("")
    const [confirmEmailError, setConfirmEmailError] = useState("")
    const [hideShowConfirmEmail, setHideShowConfirmEmail] = useState(false)
    var emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var accountNumber = data?.currentActiveData.accountNumber
    var currentUserData = data?.parsedUserData[accountNumber]
    const [email, setEmail] = useState(commonFunctionObject.getLastestFieldValFromField(currentUserData.EMAIL))
    const [confirmEmail, setConfirmEmail] = useState(commonFunctionObject.getLastestFieldValFromField(currentUserData.EMAIL))
    const navigate = useNavigate()
    console.log(currentUserData);
    const agreementServiceRequest = () => {
        
        if (checkvalidation()) {
            setAgreementPopUp(false)
            let product_family = commonFunctionObject.getProductFamily(currentUserData);
            let reqObj = {
                "header": {
                    "authToken": data.authToken,
                    "serviceRequestType": "agreementAndAnnexure",
                    "productFamily": product_family,
                },
                "body": {
                    "gcid": currentUserData.GCID,
                    "accountNumber": currentUserData.CONTRACT_NUMBER,
                    "description": "Email on:" + confirmEmail,
                }
            }
            var serviceDetails = {
                "name": 'Agreement & Annexure',
                "response": "",
                "dataValue": "",
                'erroCode': {
                    "shaftService": 'E-2009-B',
                    "otherService": "E-2009-C"
                },
                "errorId": {
                    "shaftService": "192",
                    "otherService": "193"
                }
            }

            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            callServiceRequest(reqObj, serviceDetails)
        } else {
            if (email === "") {
                setEmailError("Your email ID is blank, please enter your email ID that you would like to receive your agreement copy on")
            }
            if (confirmEmail === "") {
                hideShowConfirmEmail && setConfirmEmailError("Please Enter Confirm Valid Email Id")
            }
        }
    }


    const callServiceRequest = (reqObj, serviceDetails) => {
        OverviewServiceObject.serviceRequestService(reqObj, serviceDetails).then((serviceDetails) => {
            var response = serviceDetails.response
            if (response.header.status.toUpperCase() == "SUCCESS") {
                if (response.body.success.toLowerCase() == "true") {
                    if (serviceDetails.name === "Welcome Letter") {
                        setPopUpContent(" Your request for Welcome Letter has been registered. It will be available for download within 4 working days.")
                        setLetterPopup(true)
                    } else if (serviceDetails.name === "Agreement & Annexure") {
                        setPopUpContent("Your request for agreement & annexure has been registered and will be emailed to you in 4 working days, please note your request id " + response.body.Id + " for future ref")
                        setLetterPopup(true)
                    }
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                } else {
                    var checkErrorMessage = response.body.errors[0];
                    var errorServiceRequest;
                    var searchElement = checkErrorMessage.search(":");
                    var lastRequestType = checkErrorMessage.substring(searchElement + 1, searchElement + 9);
                    if (checkErrorMessage.search('QRC Already Exists.') > -1) {

                        var duplicateServiceRequestArr = ["Welcome Letter", "Foreclosure Letter", "Agreement & Annexure", "Loan Statement", "Amortization Schedule"]

                        if (duplicateServiceRequestArr.includes(serviceDetails.name)) {
                            setContent(`Unable to process your request for ${serviceDetails.name} as your earlier request No:${lastRequestType} is currently being processed.`)
                            setRequestRaisedModal(true);

                        } else {
                            setserviceRequestPopup(<div dangerouslySetInnerHTML={{ __html: `For <span data-id="auto-pop">${serviceDetails.name}</span>, Please Visit Nearest Branch` }} />)
                        }

                    } else {
                        setFailurePopUpContent({
                            apiName: "service-request/v1" + serviceDetails.name,
                            message: "We are currently unable to process your request for the " + serviceDetails.name + " Please try after sometime",
                            errorCode: serviceDetails.errorId.otherService
                        })
                        setFailurePopUpModal(true)
                    }
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                }
            } else {
                setFailurePopUpContent({
                    apiName: "service-request/v1" + serviceDetails.name,
                    message: "We are currently unable to process your request for the " + serviceDetails.name + " Please try after sometime",
                    errorCode: serviceDetails.errorId.shaftService
                })
                setFailurePopUpModal(true)
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }
            //OverviewServiceObject.serviceRequestResponseHandler(serviceDetails)

            // if (serviceRequestResponse.status === "TRUE") {
            //     if (serviceRequestResponse.serviceName === "Welcome Letter") {
            //         setwelcomeLetterPopup(true)
            //     }
            //     else {

            //     }
            // }
            // else if (serviceRequestResponse.status === "FALSE") {
            //     setFailurePopUpContent({
            //         apiName: "service-request/v1" + serviceRequestResponse.serviceName,
            //         message: "We are currently unable to process your request for the " + serviceRequestResponse.serviceName + " Please try after sometime",
            //         errorCode: serviceRequestResponse.data
            //     })
            //     //setFailurePopUpModal(true)
            //     serviceRequestCheck(serviceRequestResponse.response, serviceRequestResponse.serviceName)
            // }
        }).catch((error) => {
            setFailurePopUpContent({
                apiName: "service-request/v1",
                message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                errorCode: error
            })
            setFailurePopUpModal(true)
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
        })
    }


    const handleEmailChange = (value, secondValue) => {
        
        setHideShowConfirmEmail(true)
        setEmail(value)
        if (secondValue) {
            if (value !== secondValue) {
                setConfirmEmailError("Email Id Mismatch")
            } else {
                setConfirmEmailError("")
            }
        }
        if (value !== "") {
            if (!emailPattern.test(value)) {
                setEmailError("Please Enter Valid Email Id")
            } else {
                setEmailError("")

            }
        } else {
            setEmailError("Your email ID is blank, please enter your email ID that you would like to receive your agreement copy on")
        }

    }

    const handleConfirmEmailChange = (value, secondValue) => {
        setConfirmEmail(value)
        if (value !== "") {
            if (secondValue !== value) {
                setConfirmEmailError("Email Id Mismatch")
            } else {
                setConfirmEmailError("")
            }
        } else {
            setConfirmEmailError("Please Confirm your Email Id")
        }
    }

    const checkvalidation = () => {
        let flag = false;
        if (commonFunctionObject.getLastestFieldValFromField(currentUserData.EMAIL) !== "") {
            flag = true
        } else if (confirmEmail !== "" && emailError === "" && confirmEmailError === "") {
            flag = true
        } else {
            flag = false
        }
        return flag
    }


    return (
        <>
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {requestRaisedModal ? <CustomerServicePopUp
                onClick={() => setRequestRaisedModal(false)}
                visible={requestRaisedModal}
                setModalStat={setRequestRaisedModal}
                Content={content}
            /> : null}
            <Modal>
                <RadiantBorder className="agreement-pop-up-border" style={{
                    background: `${themeState === "THEME_DARK" ? "#22232E" : "#FFFFFF"}`,
                    color: `${themeState === "THEME_DARK" ? "#FFFFFF" : "#000000"}`,
                }}>
                    <div className="close-btn" onClick={() => setAgreementPopUp(false)}>
                        <img className="agreement-close" src="assets/images/crossImg.svg" alt="cross image" />
                    </div>
                    <div className='agreement-head'>
                        <div className='agreement-image'>
                            {
                                themeState === "THEME_DARK" ? <img src="assets/images/agreementIconWhite.svg" alt="agreement-icon" /> : <img src="assets/images/agreementIcon.svg" alt="agreement-icon" />
                            }</div>
                        <div className='agreement-title'>Agreement and Annexure</div>
                    </div>
                    <div className='agreement-content'>
                        <div className='agreement-input-box'>
                            <span className='lable-name'>Registered Email ID</span>
                            <div className='input-container'>
                                <Input editIcon={true}
                                    onFocus={true}
                                    id="emai"
                                    disabled={true}
                                    error={emailError}
                                    onChange={(event) => {
                                        handleEmailChange(event.target.value, confirmEmail && confirmEmail)
                                    }}
                                    style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}
                                     />
                            </div>
                        </div>
                        {
                            hideShowConfirmEmail && <div className='agreement-input-box'>
                                <span className='lable-name'>Reconfirm Email ID</span>
                                <div className='input-container'>
                                    <Input editIcon={true}
                                        onFocus={true}
                                        id="confirmEmai"
                                        disabled={true}
                                        error={confirmEmailError}
                                        onChange={(event) => {
                                            handleConfirmEmailChange(event.target.value, email)
                                        }}
                                        style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}
                                         />
                                </div>
                            </div>
                        }



                        {/* <div className='agreement-input' style={{
                            background: `${themeState === "THEME_DARK" ? "#373842" : "#ECF0F5"}`,
                            color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}`
                        }}
                        >
                            <p>{currentUserData.EMAIL}</p>
                            <img onClick={() => { redirectTo("change-email-id") }} style={themeState === "THEME_DARK" ? { filter: "invert(1) " } : null} src="assets/images/editIcon.svg" alt="edit icon" />
                        </div> */}
                    </div>
                    <div className='agreement-note'>
                        <p>NOTE: Please confirm your Email ID, your Agreement Letter will be emailed to you.</p>
                    </div>
                    <div className='agreement-button'>
                        <Button className="agreement-button-component" onClick={() => { agreementServiceRequest() }} >
                            Confirm & Continue
                        </Button>
                    </div>
                </RadiantBorder>
            </Modal>
        </>
    )
}

Agreement.prototypes = {
    setAgreementPopUp: PropTypes.func,
    setPopUpContent: PropTypes.func,
    setserviceRequestPopup: PropTypes.func,
    setLetterPopup: PropTypes.func,
    setFailurePopUpModal: PropTypes.func,
    setFailurePopUpContent: PropTypes.func,
    failurePopUpModal: PropTypes.bool,
    failurePopUpContent: PropTypes.object,
    requestRaisedModal: PropTypes.bool,
    setRequestRaisedModal: PropTypes.func,
    content: PropTypes.string,
    setContent: PropTypes.func
}
export default Agreement;