const LoaderServiceObject={

}

const callApi=(apiArray)=>{
    Promise.all(apiArray).then(()=>{
        console.log("hello")
        return true
    }).catch(()=>{
        console.log("Pending")
    })
}

 LoaderServiceObject.callApi=callApi


export default LoaderServiceObject