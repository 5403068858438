import stalwartApiCall from "../../../Retail/src/utilities/stalwartCommonApi";

export const eventLoggingDtlsApiCall = (body, authToken) => {
  let reqBody = {
      "header": {
        "authToken" : authToken
      },
      body: body
  }
  return new Promise((resolve, reject) => {
    stalwartApiCall(
      "POST",
      reqBody,
      "/shaft/api/collection/get-event-logging-details/v1"
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
