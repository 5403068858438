import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getLoanPositionDtls = (data) => {
  let requestJson = data;
  return new Promise((resolve, reject) => {
    // axios.post('/shaft/api/cdi/get-loan-position-dtls/v1', requestJson).then(response => {
    stalwartApiCall("POST", data, "/shaft/api/cdi/get-loan-position-dtls/v1")
      .then((response) => {
        // response =[
        //     {
        //         "getLoanPositionDtlsReturn": {
        //             "EMIPaymentStatus": "B,A",
        //             "occupation": "SALARIED",
        //             "outstandingAmount": {
        //                 "xsi:nil": true
        //             },
        //             "loanAvailDate": "27/06/2017",
        //             "unpaidPrincipleBalance": {
        //                 "xsi:nil": true
        //             },
        //             "totalAmountPaid": 402143,
        //             "coApplicantName": "",
        //             "last3MonthsEMIPaidDate": "09-01-22 00:00:00,09-12-21 00:00:00,10-11-21 00:00:00",
        //             "terminationDate": {
        //                 "xsi:nil": true
        //             },
        //             "last3MonthsEMIDueDate": "09-02-22 00:00:00,09-01-22 00:00:00,09-12-21 00:00:00",
        //             "unpaidInterest": {
        //                 "xsi:nil": true
        //             },
        //             "paymentmode": "",
        //             "totalNoOfEMIsPaid": 50,
        //             "balanceTenure": 0,
        //             "beneficiaryBankName": {
        //                 "xsi:nil": true
        //             },
        //             "loanApprovedDate": "19/06/2017",
        //             "loanStatus": "Active",
        //             "overdueAmount": 75,
        //             "IFSCCode": {
        //                 "xsi:nil": true
        //             },
        //             "accountType": {
        //                 "xsi:nil": true
        //             },
        //             "emistartDate": "09/08/2017",
        //             "mandateExpiryDate": {
        //                 "xsi:nil": true
        //             },
        //             "branchCode": 485,
        //             "loanAccountNo": {
        //                 "xsi:nil": true
        //             },
        //             "subProductName": "",
        //             "applicationId": "APPL10007015",
        //             "interestAdjustment": "",
        //             "outstandingEssAmount": "",
        //             "status": {
        //                 "xsi:nil": true
        //             },
        //             "arrearsInterest": "",
        //             "lastDateOfAmountPaid": "30/10/2023",
        //             "EMIBounceDescription": "N10002583/55",
        //             "asOnDate": "30/10/2023",
        //             "accountName": "",
        //             "penalInterestRate": "",
        //             "gcid": "",
        //             "loanAvailAmt": 307091,
        //             "rateOfInterest": 14,
        //             "nextEmiDate": {
        //                 "xsi:nil": true
        //             },
        //             "noofAdvance": "",
        //             "principalAmount": {
        //                 "xsi:nil": true
        //             },
        //             "lastamountpaidDate": "09/02/2022",
        //             "tenor": 19,
        //             "unpaidChargeBalance": 9953,
        //             "errorOccured": false,
        //             "sanctionAmount": 307091,
        //             "beneficiaryBankAccountNo": {
        //                 "xsi:nil": true
        //             },
        //             "disbursedAmount": 307091,
        //             "paymentStatus": "",
        //             "product": "Personal Loan Unsecured",
        //             "RCUpdatestatus": "",
        //             "lastPaidOverdueAmount": 8200,
        //             "numberOfEMIsOverdue": 0,
        //             "contractno": "",
        //             "branchName": "0485 - BANGALORE RICHMOND ROAD -PRESTIGE CORNICHE",
        //             "repaymentInstallmentBalance": 0,
        //             "emiendDate": {
        //                 "xsi:nil": true
        //             },
        //             "errorMsg": "",
        //             "overdueChargeAmount": 12671,
        //             "dueAmount": 0,
        //             "currentInstallment": "",
        //             "lastAmountPaid": 441,
        //             "loanPeriod": 55,
        //             "remarks": "",
        //             "overdueprincipleAmount": {
        //                 "xsi:nil": true
        //             },
        //             "totalNoofEmis": 55
        //         },
        //         "getLoanAccountsReturn": {
        //             "emiAmount": 0,
        //             "loanType": "",
        //             "gcid": "",
        //             "errorOccured": false,
        //             "subProductName": "Personal Loan Non Supervisory profiles",
        //             "sanctionamt": 307091,
        //             "accountNumber": "TCFPL0485000010002583",
        //             "productName": "Personal Loan Unsecured",
        //             "errorMsg": ""
        //         }
        //     },
        //     {
        //         "header": {
        //             "status": "SUCCESS",
        //             "requestId": "REQ-1698647462092"
        //         },
        //         "body": {
        //             "getLoanPositionDtlsReturn": {
        //                 "EMIPaymentStatus": "B,A",
        //                 "occupation": {
        //                     "xsi:nil": true
        //                 },
        //                 "outstandingAmount": 0,
        //                 "loanAvailDate": "08/09/2017",
        //                 "unpaidPrincipleBalance": 503777,
        //                 "totalAmountPaid": {
        //                     "xsi:nil": true
        //                 },
        //                 "coApplicantName": "",
        //                 "last3MonthsEMIPaidDate": "31-12-18 00:00:00,31-12-18 00:00:00,30-12-18 00:00:00",
        //                 "terminationDate": {
        //                     "xsi:nil": true
        //                 },
        //                 "last3MonthsEMIDueDate": "03-09-20 00:00:00,03-08-20 00:00:00,03-07-20 00:00:00",
        //                 "unpaidInterest": 93555,
        //                 "paymentmode": "",
        //                 "totalNoOfEMIsPaid": {
        //                     "xsi:nil": true
        //                 },
        //                 "balanceTenure": 0,
        //                 "beneficiaryBankName": {
        //                     "xsi:nil": true
        //                 },
        //                 "loanApprovedDate": "07/09/2017",
        //                 "loanStatus": "Active",
        //                 "overdueAmount": 5000,
        //                 "IFSCCode": {
        //                     "xsi:nil": true
        //                 },
        //                 "accountType": {
        //                     "xsi:nil": true
        //                 },
        //                 "emistartDate": "03/10/2017",
        //                 "mandateExpiryDate": {
        //                     "xsi:nil": true
        //                 },
        //                 "branchCode": 284,
        //                 "loanAccountNo": {
        //                     "xsi:nil": true
        //                 },
        //                 "subProductName": "",
        //                 "applicationId": "APPL10022404",
        //                 "interestAdjustment": "",
        //                 "outstandingEssAmount": "",
        //                 "status": {
        //                     "xsi:nil": true
        //                 },
        //                 "arrearsInterest": "",
        //                 "lastDateOfAmountPaid": "30/10/2023",
        //                 "EMIBounceDescription": "ACCOUNT BLOCKED/FROZEN N10009410/36",
        //                 "asOnDate": "30/10/2023",
        //                 "accountName": "",
        //                 "penalInterestRate": "",
        //                 "gcid": "",
        //                 "loanAvailAmt": 750000,
        //                 "rateOfInterest": 18.5,
        //                 "nextEmiDate": {
        //                     "xsi:nil": true
        //                 },
        //                 "noofAdvance": "",
        //                 "principalAmount": 0,
        //                 "lastamountpaidDate": "03/09/2020",
        //                 "tenor": {
        //                     "xsi:nil": true
        //                 },
        //                 "unpaidChargeBalance": 15350,
        //                 "errorOccured": false,
        //                 "sanctionAmount": 750000,
        //                 "beneficiaryBankAccountNo": {
        //                     "xsi:nil": true
        //                 },
        //                 "disbursedAmount": 750000,
        //                 "paymentStatus": "",
        //                 "product": "Business Loan Unsecured",
        //                 "RCUpdatestatus": "",
        //                 "lastPaidOverdueAmount": 100,
        //                 "numberOfEMIsOverdue": {
        //                     "xsi:nil": true
        //                 },
        //                 "contractno": "",
        //                 "branchName": "0284 - INDORE TOGANJ",
        //                 "repaymentInstallmentBalance": 0,
        //                 "emiendDate": {
        //                     "xsi:nil": true
        //                 },
        //                 "errorMsg": "",
        //                 "overdueChargeAmount": 494882,
        //                 "dueAmount": 0,
        //                 "currentInstallment": "",
        //                 "lastAmountPaid": 4310,
        //                 "loanPeriod": 36,
        //                 "remarks": "",
        //                 "overdueprincipleAmount": 597332,
        //                 "totalNoofEmis": 36
        //             },
        //             "getLoanAccountsReturn": {
        //                 "emiAmount": 0,
        //                 "loanType": "",
        //                 "gcid": "",
        //                 "errorOccured": false,
        //                 "subProductName": "Business Loan Verified Income Program",
        //                 "sanctionamt": 750000,
        //                 "accountNumber": "TCFBL0284000010009410",
        //                 "productName": "Business Loan Unsecured",
        //                 "errorMsg": ""
        //             }
        //         },
        //         "errorBody": {}
        //     },
        //     {
        //         "header": {
        //             "status": "SUCCESS",
        //             "requestId": "REQ-1698647462109"
        //         },
        //         "body": {
        //             "getLoanPositionDtlsReturn": {
        //                 "EMIPaymentStatus": "B,A",
        //                 "occupation": "SELF EMPLOYED PROFESSIONAL",
        //                 "outstandingAmount": 600014,
        //                 "loanAvailDate": "20/01/2020",
        //                 "unpaidPrincipleBalance": {
        //                     "xsi:nil": true
        //                 },
        //                 "totalAmountPaid": 937683,
        //                 "coApplicantName": "",
        //                 "last3MonthsEMIPaidDate": "04-01-22 00:00:00,04-01-22 00:00:00,07-12-21 00:00:00",
        //                 "terminationDate": {
        //                     "xsi:nil": true
        //                 },
        //                 "last3MonthsEMIDueDate": "03-02-22 00:00:00,03-01-22 00:00:00,03-12-21 00:00:00",
        //                 "unpaidInterest": {
        //                     "xsi:nil": true
        //                 },
        //                 "paymentmode": "",
        //                 "totalNoOfEMIsPaid": 26,
        //                 "balanceTenure": 16,
        //                 "beneficiaryBankName": {
        //                     "xsi:nil": true
        //                 },
        //                 "loanApprovedDate": "17/01/2020",
        //                 "loanStatus": "Active",
        //                 "overdueAmount": 2124,
        //                 "IFSCCode": {
        //                     "xsi:nil": true
        //                 },
        //                 "accountType": {
        //                     "xsi:nil": true
        //                 },
        //                 "emistartDate": "03/03/2020",
        //                 "mandateExpiryDate": {
        //                     "xsi:nil": true
        //                 },
        //                 "branchCode": 632,
        //                 "loanAccountNo": {
        //                     "xsi:nil": true
        //                 },
        //                 "subProductName": "",
        //                 "applicationId": "APPL11175223",
        //                 "interestAdjustment": "",
        //                 "outstandingEssAmount": "",
        //                 "status": {
        //                     "xsi:nil": true
        //                 },
        //                 "arrearsInterest": "",
        //                 "lastDateOfAmountPaid": "30/10/2023",
        //                 "EMIBounceDescription": "M3223480",
        //                 "asOnDate": "30/10/2023",
        //                 "accountName": "",
        //                 "penalInterestRate": "",
        //                 "gcid": "",
        //                 "loanAvailAmt": 1455268,
        //                 "rateOfInterest": 15.5,
        //                 "nextEmiDate": "03/07/2022",
        //                 "noofAdvance": "",
        //                 "principalAmount": 600014,
        //                 "lastamountpaidDate": "19/03/2022",
        //                 "tenor": {
        //                     "xsi:nil": true
        //                 },
        //                 "unpaidChargeBalance": 7050,
        //                 "errorOccured": false,
        //                 "sanctionAmount": 1455268,
        //                 "beneficiaryBankAccountNo": {
        //                     "xsi:nil": true
        //                 },
        //                 "disbursedAmount": 1455268,
        //                 "paymentStatus": "",
        //                 "product": "Used Car Refinance",
        //                 "RCUpdatestatus": "",
        //                 "lastPaidOverdueAmount": 1200,
        //                 "numberOfEMIsOverdue": 14,
        //                 "contractno": "",
        //                 "branchName": "0632 - MALAD MUMBAI",
        //                 "repaymentInstallmentBalance": 16,
        //                 "emiendDate": {
        //                     "xsi:nil": true
        //                 },
        //                 "errorMsg": "",
        //                 "overdueChargeAmount": 7735,
        //                 "dueAmount": 35004,
        //                 "currentInstallment": "",
        //                 "lastAmountPaid": 35004,
        //                 "loanPeriod": 60,
        //                 "remarks": "",
        //                 "overdueprincipleAmount": {
        //                     "xsi:nil": true
        //                 },
        //                 "totalNoofEmis": 60
        //             },
        //             "getLoanAccountsReturn": {
        //                 "emiAmount": 35004,
        //                 "loanType": "",
        //                 "gcid": "",
        //                 "errorOccured": false,
        //                 "subProductName": "Car n Cash Multiplier 2019",
        //                 "sanctionamt": 1455268,
        //                 "accountNumber": "TCFUC0632000010776218",
        //                 "productName": "Used Car Refinance",
        //                 "errorMsg": ""
        //             }
        //         },
        //         "errorBody": {}
        //     }, 
        //     {
        //       header: {
        //         status: "SUCCESS",
        //         requestId: "REQ-1698646701833",
        //       },
        //       body: {
        //         getLoanPositionDtlsReturn: {
        //           EMIPaymentStatus: "B,A",
        //           occupation: "SALARIED",
        //           outstandingAmount: {
        //             "xsi:nil": true,
        //           },
        //           loanAvailDate: "27/06/2017",
        //           unpaidPrincipleBalance: {
        //             "xsi:nil": true,
        //           },
        //           totalAmountPaid: 402143,
        //           coApplicantName: "",
        //           last3MonthsEMIPaidDate:
        //             "09-01-22 00:00:00,09-12-21 00:00:00,10-11-21 00:00:00",
        //           terminationDate: {
        //             "xsi:nil": true,
        //           },
        //           last3MonthsEMIDueDate:
        //             "09-02-22 00:00:00,09-01-22 00:00:00,09-12-21 00:00:00",
        //           unpaidInterest: {
        //             "xsi:nil": true,
        //           },
        //           paymentmode: "",
        //           totalNoOfEMIsPaid: 50,
        //           balanceTenure: 0,
        //           beneficiaryBankName: {
        //             "xsi:nil": true,
        //           },
        //           loanApprovedDate: "19/06/2017",
        //           loanStatus: "InActive",
        //           overdueAmount: 75,
        //           IFSCCode: {
        //             "xsi:nil": true,
        //           },
        //           accountType: {
        //             "xsi:nil": true,
        //           },
        //           emistartDate: "09/08/2017",
        //           mandateExpiryDate: {
        //             "xsi:nil": true,
        //           },
        //           branchCode: 485,
        //           loanAccountNo: {
        //             "xsi:nil": true,
        //           },
        //           subProductName: "",
        //           applicationId: "APPL10007015",
        //           interestAdjustment: "",
        //           outstandingEssAmount: "",
        //           status: {
        //             "xsi:nil": true,
        //           },
        //           arrearsInterest: "",
        //           lastDateOfAmountPaid: "30/10/2023",
        //           EMIBounceDescription: "N10002583/55",
        //           asOnDate: "30/10/2023",
        //           accountName: "",
        //           penalInterestRate: "",
        //           gcid: "",
        //           loanAvailAmt: 307091,
        //           rateOfInterest: 14,
        //           nextEmiDate: {
        //             "xsi:nil": true,
        //           },
        //           noofAdvance: "",
        //           principalAmount: {
        //             "xsi:nil": true,
        //           },
        //           lastamountpaidDate: "09/02/2022",
        //           tenor: 19,
        //           unpaidChargeBalance: 9953,
        //           errorOccured: false,
        //           sanctionAmount: 307091,
        //           beneficiaryBankAccountNo: {
        //             "xsi:nil": true,
        //           },
        //           disbursedAmount: 307091,
        //           paymentStatus: "",
        //           product: "Personal Loan Unsecured",
        //           RCUpdatestatus: "",
        //           lastPaidOverdueAmount: 8200,
        //           numberOfEMIsOverdue: 0,
        //           contractno: "",
        //           branchName: "0485 - BANGALORE RICHMOND ROAD -PRESTIGE CORNICHE",
        //           repaymentInstallmentBalance: 0,
        //           emiendDate: {
        //             "xsi:nil": true,
        //           },
        //           errorMsg: "",
        //           overdueChargeAmount: 12671,
        //           dueAmount: 0,
        //           currentInstallment: "",
        //           lastAmountPaid: 441,
        //           loanPeriod: 55,
        //           remarks: "",
        //           overdueprincipleAmount: {
        //             "xsi:nil": true,
        //           },
        //           totalNoofEmis: 55,
        //         },
        //         getLoanAccountsReturn: {
        //           emiAmount: 0,
        //           loanType: "",
        //           gcid: "",
        //           errorOccured: false,
        //           subProductName: "Personal Loan Non Supervisory profiles",
        //           sanctionamt: 307091,
        //           accountNumber: "TCFPL0485000010002584",
        //           productName: "Personal Loan Unsecured",
        //           errorMsg: "",
        //         },
        //       },
        //       errorBody: {},
        //     },
        //     {
        //         header: {
        //           status: "SUCCESS",
        //           requestId: "REQ-1698646701833",
        //         },
        //         body: {
        //           getLoanPositionDtlsReturn: {
        //             EMIPaymentStatus: "B,A",
        //             occupation: "SALARIED",
        //             outstandingAmount: {
        //               "xsi:nil": true,
        //             },
        //             loanAvailDate: "27/06/2017",
        //             unpaidPrincipleBalance: {
        //               "xsi:nil": true,
        //             },
        //             totalAmountPaid: 402143,
        //             coApplicantName: "",
        //             last3MonthsEMIPaidDate:
        //               "09-01-22 00:00:00,09-12-21 00:00:00,10-11-21 00:00:00",
        //             terminationDate: {
        //               "xsi:nil": true,
        //             },
        //             last3MonthsEMIDueDate:
        //               "09-02-22 00:00:00,09-01-22 00:00:00,09-12-21 00:00:00",
        //             unpaidInterest: {
        //               "xsi:nil": true,
        //             },
        //             paymentmode: "",
        //             totalNoOfEMIsPaid: 50,
        //             balanceTenure: 0,
        //             beneficiaryBankName: {
        //               "xsi:nil": true,
        //             },
        //             loanApprovedDate: "19/06/2017",
        //             loanStatus: "Closed",
        //             overdueAmount: 75,
        //             IFSCCode: {
        //               "xsi:nil": true,
        //             },
        //             accountType: {
        //               "xsi:nil": true,
        //             },
        //             emistartDate: "09/08/2017",
        //             mandateExpiryDate: {
        //               "xsi:nil": true,
        //             },
        //             branchCode: 485,
        //             loanAccountNo: {
        //               "xsi:nil": true,
        //             },
        //             subProductName: "",
        //             applicationId: "APPL10007015",
        //             interestAdjustment: "",
        //             outstandingEssAmount: "",
        //             status: {
        //               "xsi:nil": true,
        //             },
        //             arrearsInterest: "",
        //             lastDateOfAmountPaid: "30/10/2023",
        //             EMIBounceDescription: "N10002583/55",
        //             asOnDate: "30/10/2023",
        //             accountName: "",
        //             penalInterestRate: "",
        //             gcid: "",
        //             loanAvailAmt: 307091,
        //             rateOfInterest: 14,
        //             nextEmiDate: {
        //               "xsi:nil": true,
        //             },
        //             noofAdvance: "",
        //             principalAmount: {
        //               "xsi:nil": true,
        //             },
        //             lastamountpaidDate: "09/02/2022",
        //             tenor: 19,
        //             unpaidChargeBalance: 0,
        //             errorOccured: false,
        //             sanctionAmount: 307091,
        //             beneficiaryBankAccountNo: {
        //               "xsi:nil": true,
        //             },
        //             disbursedAmount: 307091,
        //             paymentStatus: "",
        //             product: "Personal Loan Unsecured",
        //             RCUpdatestatus: "",
        //             lastPaidOverdueAmount: 8200,
        //             numberOfEMIsOverdue: 0,
        //             contractno: "",
        //             branchName: "0485 - BANGALORE RICHMOND ROAD -PRESTIGE CORNICHE",
        //             repaymentInstallmentBalance: 0,
        //             emiendDate: {
        //               "xsi:nil": true,
        //             },
        //             errorMsg: "",
        //             overdueChargeAmount: 0,
        //             dueAmount: 0,
        //             currentInstallment: "",
        //             lastAmountPaid: 441,
        //             loanPeriod: 55,
        //             remarks: "",
        //             overdueprincipleAmount: {
        //               "xsi:nil": true,
        //             },
        //             totalNoofEmis: 55,
        //           },
        //           getLoanAccountsReturn: {
        //             emiAmount: 0,
        //             loanType: "",
        //             gcid: "",
        //             errorOccured: false,
        //             subProductName: "Personal Loan Non Supervisory profiles",
        //             sanctionamt: 307091,
        //             accountNumber: "TCFPL0485000010002585",
        //             productName: "Personal Loan Unsecured",
        //             errorMsg: "",
        //           },
        //         },
        //         errorBody: {},
        //     },
        // ] 
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default getLoanPositionDtls;
