import React, { useContext, useEffect, useState } from 'react'
import Button from '../../FormFields/Button/Button';
import Input from '../../FormFields/Input';
import './enterNewPassword.scss'
import ValidationPill from '../ValidationPill/ValidationPill';
import LoginServiceObject from '../../../../Retail/src/services/Login';
import DataMapping from '../../../../Shared/context/data-mapping';
import { STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type';
import PropTypes from 'prop-types';


const validityForPassword = [
    {
        id: 0,
        validFor: "A-Z",
        valid: false,
        regex: /[A-Z]+/g,
    },
    {
        id: 1,
        validFor: "a-z",
        valid: false,
        regex: /[a-z]+/g,
    },
    {
        id: 2,
        validFor: "0-9",
        valid: false,
        regex: /\d+/g,
    },
    {
        id: 3,
        validFor: ".!@#",
        valid: false,
        regex: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g,
    },
]


const EnterNewPassword = ({
    setCounter,
    forgotForm,
    setFailurePopUpContent,
    setFailurePopUpModal
}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordValidity, setPasswordValidity] = useState(validityForPassword);
    const [handlePassInput, setHandlePassInput] = useState('');
    const [rePassword, setRePassword] = useState()
    const [, dispatchData] = useContext(DataMapping);


    const handlePasswordInput = (e) => {
        setHandlePassInput(() => e.target.value);
    }

    const checkValidityForPassword = () => {
        setErrorMessage(() => '')
        setPasswordValidity(prev => {
            let newState = [...prev];
            newState.forEach(password => {
                let regex = password.regex;
                if (handlePassInput.match(regex)) {
                    password.valid = true;
                } else {
                    password.valid = false;
                }
            })
            return newState;
        })
    }

    useEffect(() => {
        checkValidityForPassword();
    }, [handlePassInput])

    

    const handleClick = async (e) => {
        e.preventDefault();

        if (handlePassInput === '') {
            setErrorMessage(() => 'Please Enter a New Password');
            return;
        }

        let patternValid = true;
        passwordValidity.forEach((password) => {
            if (!password.valid) {
                patternValid = false;
            }
        });

        if (!patternValid || handlePassInput.length < 8) {
            setErrorMessage(() => 'Password Pattern is Invalid');
            return;
        }

        if (rePassword !== handlePassInput) {
            setErrorMessage(() => 'Password Must Match With New Password');
            return;
        }

        dispatchData({
            name: 'loaderFlag',
            payload: true,
            type: STOREDATA
        });

        try {
            const response = await LoginServiceObject.changePreLoginPasswordService(forgotForm.field.value, handlePassInput, rePassword);
            if (response.body.status) {
                setCounter((prev) => prev + 1);
                dispatchData({
                    name: 'loaderFlag',
                    payload: false,
                    type: STOREDATA
                });
            }
        } catch (error) {
            if (error === '07') {
                setFailurePopUpContent({
                    apiName: '',
                    message: 'We Are Experiencing Technical Difficulty. Please Try After Sometime.',
                    errorCode: error
                });
                setFailurePopUpModal(true);
            } else if (error === 'Password mismatch') {
                setErrorMessage(() => 'Password must match with new password');
            }
        } finally {
            dispatchData({
                name: 'loaderFlag',
                payload: false,
                type: STOREDATA
            });
        }
    };



    const handleBack = () => {
        setCounter(prev => prev - 2)
    }

    return (
        <div className='c_new_pass_wrap'>
            <a className='c_back_link' onClick={handleBack}><img src='assets/images/arrow_back.svg' className='c_back' />Back</a>
            <div className='c_new_pass_body'>
                <h2 className='c_new_pass_title'>Enter New Password</h2>
                <p className='c_new_pass_desc'>Create a strong password</p>
                <div className="c_new_pass_btn_wrp">
                    <form >
                        <Input type="password" className="input-eye" inputType="withIcon" value={handlePassInput} onChange={handlePasswordInput} placeholder="Enter New Password" icon='assets/images/visibility_off.svg' />
                        <div className="validation-wrp">
                            {
                                passwordValidity.map((password) => <ValidationPill key={password.id} checkValidFor={password.validFor} valid={password.valid} />)
                            }
                        </div>
                        <Input
                            type="password"
                            className="input-eye"
                            inputType="withIcon"
                            placeholder="Re-enter New Password"
                            icon='assets/images/visibility_off.svg'
                            value={rePassword}
                            onChange={(e) => {
                                setRePassword(e.target.value)
                            }}
                        />
                        <span className='error'>{errorMessage}</span>
                        <Button type='submit' className="login" onClick={handleClick}>
                            Submit
                            <img
                                className="c_arrow-right"
                                src="assets/images/right-arrow-white.svg"
                                alt="c_arrow-right"
                            />
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

EnterNewPassword.propTypes = {
    setCounter: PropTypes.func,
    forgotForm: PropTypes.object,
    setFailurePopUpContent: PropTypes.object,
    setFailurePopUpModal: PropTypes.func,


}

export default EnterNewPassword