import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const jocataPreApproved = (data) => {

  let requestJson = {
    "jocata-pre-approved/v1": {
      "header": {
        "authToken": "117737a4e1105e8e04332a04ece539f0e1eceb1788a642bd8c9a7526b2575830",
        "productName": "personalloan"
      },
      "body": {
        "mobileNumber": "9594931586"
      }
    }
  }
  return new Promise((resolve, reject) => {
    // axios.post('/shaft/api/jocata/jocata-pre-approved/v1', data).then(response => {
    stalwartApiCall('POST', data, '/shaft/api/jocata/jocata-pre-approved/v1').then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
}

export default jocataPreApproved;