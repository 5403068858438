import React, { useContext } from 'react';
import { useState } from 'react';
import commonFunctionObject from '../../common-function/commonfunctions';
import LoanInfoComponent from '../../components/LoanInfoComponent';
import DataMapping from '../../../../Shared/context/data-mapping';
import RadiantBorder from '../../global/RadiantBorder';
// import dataObj from '../../screens/Retail-Account-Details/Data';
import dataObj from '../../../../Shared/screens/Retail-Account-Details/Data';
import loanDetailsObject from './loanDetailArr';
import './LoanDetails.scss';
import PropTypes from 'prop-types';

export const LoanDetails = ({ activeContract, heiglighted }) => {

    const [data, dispatchData] = useContext(DataMapping)
    var closedStatus = ["cancelled", "discharged", "closed"];
    var product_family = commonFunctionObject.getProductFamily(data.parsedUserData[activeContract]);

    console.log(loanDetailsObject)
    return (
        <div>
            <div className='loan-detaial'>
                <div className='interest-details'>
                    <h2>
                        Account Information
                    </h2>
                    <RadiantBorder className="account-information-container">
                        <div className='account-information-container-inner'>
                            <LoanInfoComponent>
                                <ul className='loan-detail-info-ul'>
                                    {loanDetailsObject && loanDetailsObject.amountInformation.map((value, index) => {

                                        {
                                            switch (value.label.toLowerCase()) {
                                                case "termination date":
                                                    let getProduct = commonFunctionObject.getProductName(data?.parsedUserData[activeContract])
                                                    if (closedStatus.includes(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn.loanStatus.toLowerCase())) {
                                                        return <li key={index}><div className='label'>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn.terminationDate, "date")}</div></li>
                                                    } else if (value.product.includes(getProduct.toUpperCase())) {
                                                        return <li key={index}><div className='label'>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn.noofAdvance, 'date')}  </div></li>
                                                    }
                                                    break;
                                                case "unpaid principle amount":

                                                    if (value.productFamilay.includes(product_family.toLowerCase())) {
                                                        return <li key={index}><div className='label'>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey], 'amount')}</div></li>
                                                    }
                                                    break;
                                                case "overdue charge amount":
                                                    return <li key={index}><div className={`${heiglighted === "overdueChargeAmount" ? "heighlightactive" : "heighlightinactive"}`}><div className={`label`}>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey], 'amount')}</div></div></li>
                                                case "total overdue amount":
                                                    return <li key={index}><div className={`${heiglighted === "overdueChargeAmount" ? "heighlightactive" : "heighlightinactive"}`}><div className={`label`}>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey], 'amount')}</div></div></li>
                                                case "outstanding amount":

                                                    if (value.productFamilay.includes(product_family.toLowerCase())) {
                                                        return <li key={index}><div className='label'>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey], "amount")}</div></li>
                                                    }
                                                    break;
                                                default:
                                                    return <li key={index}><div className='label'>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey], "amount")}</div></li>

                                            }
                                        }

                                    })}
                                </ul>
                            </LoanInfoComponent>
                        </div>
                    </RadiantBorder>
                </div>
                <div className='interest-details-loan-term'>
                    <div className='interest-details'>
                        <h2>
                            EMI & Interest Details
                        </h2>
                        <RadiantBorder className="account-information-container">
                            <div className='account-information-container-inner'>
                                <LoanInfoComponent>
                                    <ul className='loan-detail-info-ul emi-interest-detail'>
                                        {loanDetailsObject && loanDetailsObject.interestDetails.map((value, index) => {
                                            {
                                                switch (value.label.toLowerCase()) {
                                                    case "rate of interest(%)":
                                                        return <li key={index}><div className={`${heiglighted === "rateofinterestloanaccount" ? "heighlightactive" : "heighlightinactive"}`}><div className='label'>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey], 'amount')}</div></div></li>
                                                    case "current emi amount":
                                                        return <li key={index}><div className={`${heiglighted === "currentInstallment" ? "heighlightactive" : "heighlightinactive"}`}><div className={`label`}>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanAccountsReturn[value.apiKey], 'amount')}</div></div></li>
                                                    case "next emi due date":
                                                        if (closedStatus.includes(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn.loanStatus.toLowerCase())) {
                                                            return <li key={index}><div className={`${heiglighted === "emiduedate" ? "heighlightactive" : "heighlightinactive"}`}><div className='label'>{value.label}</div><div className='label-amount'>NA</div></div></li>
                                                        } else {
                                                            return <li key={index}><div className={`${heiglighted === "emiduedate" ? "heighlightactive" : "heighlightinactive"}`}><div className='label'>{value.label}</div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey], 'date')}</div></div></li>
                                                        }
                                                    case "last paid emi date":
                                                        return <li key={index}><div className='label'>{value.label}</div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey], 'date')}</div></li>

                                                    default:
                                                        return <li key={index}><div className='label'>{value.label} </div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey], 'amount')}</div></li>
                                                }
                                            }

                                        })}

                                    </ul>
                                </LoanInfoComponent>
                            </div>
                        </RadiantBorder>
                    </div>
                    <div className='loan-term'>
                        <h2>
                            Loan Term
                        </h2>
                        <RadiantBorder className="account-information-container">
                            <div className='account-information-container-inner'>
                                <LoanInfoComponent>
                                    <ul className='loan-detail-info-ul'>
                                        {loanDetailsObject && loanDetailsObject.loanTerm.map((value, index) => {
                                            {
                                                switch (value.label.toLowerCase()) {
                                                    case "balance tenure (months)":
                                                        if (closedStatus.includes(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn.loanStatus.toLowerCase())) {
                                                            return <li key={index}><div className={`${heiglighted === "balancetenureonloanaccount" ? "heighlightactive" : "heighlightinactive"}`}><div className='label'>{value.label}</div><div className='label-amount'>0</div></div></li>
                                                        } else {
                                                            return <li key={index} ><div className={`${heiglighted === "balancetenureonloanaccount" ? "heighlightactive" : "heighlightinactive"}`}><div className='label'>{value.label}</div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey])}</div></div></li>
                                                        }

                                                    case "total tenure (months)":
                                                        return <li key={index}><div className={`${heiglighted === "totaltenureofloanaccount" ? "heighlightactive" : "heighlightinactive"}`}><div className='label'>{value.label}</div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey])}</div></div></li>
                                                    case "loan maturity date":
                                                        return <li key={index}><div className='label'>{value.label}</div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey])}</div></li>
                                                    default:
                                                        return (
                                                            <li key={index}><div className='label'>{value.label}</div><div className='label-amount'>{commonFunctionObject.checkEmptyData(data?.getLoanPositionDTLS[activeContract].body.getLoanPositionDtlsReturn[value.apiKey])}</div></li>
                                                        )
                                                }
                                            }
                                        })}
                                    </ul>
                                </LoanInfoComponent>
                            </div>
                        </RadiantBorder>
                    </div>
                </div>
            </div>
        </div>
    )
}

LoanDetails.propTypes = {
    activeContract: PropTypes.string,
    heiglighted: PropTypes.string
}
