import React, { useContext, useEffect } from 'react'
import "./registercomponent.scss";
import Input from '../../FormFields/Input';
import Button from '../../FormFields/Button/Button';
import { useNavigate } from 'react-router';
import LoginServiceObject from '../../../../Retail/src/services/Login';
import AesUtil from '../../../../Retail/src/utilities/AesUtil';
import { generateOtp } from '../../services/generateOtp';
import { handleErrorMessage } from '../../services/handleErrorMessageForm';
import { useCookies } from 'react-cookie';
import { checkUserValid } from '../../services/checkUserValid';
import { dispacthUserData } from '../../services/dispatchUserData';
import DataMapping from '../../../../Shared/context/data-mapping';
import { STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type';
import Validators from '../../hooks/useReactiveForm/validator';
import PropTypes from "prop-types";
import commonFunctionObject from '../../../../Retail/src/common-function';

const RegisterComponent = ({
    captcha,
    componentType = "mobNum",
    setCounter,
    captchaImg = "",
    setLoginTab,
    captchaApiCall,
    setOtpDetails,
    loginTabForm,
    handleForm,
    showError,
    setShowError,
    setOtpTimerFlag,
    apiErrorMsg,
    setApiErrorMsg,
    setFailurePopUpModal,
    setFailurePopUpContent,
    captchaApiErrMsg,
    setCaptchaApiErrMsg

}) => {
    const navigate = useNavigate();
    const [, setCookie] = useCookies();
    const [, dispatchData] = useContext(DataMapping);
    let { fieldOne, fieldTwo } = loginTabForm.value

    const obj = {
        mobNum: {
            title: 'Enter your registered Mobile Number',
            placeholder: 'Enter your mobile number',
            inputType: 'withCountryCode',
            captcha: 'Enter Captcha',
        },
        emailId: {
            title: 'Enter your registered Email ID',
            placeholder: 'Enter your email ID',
            inputType: 'default',
            captcha: 'Enter Captcha',
        },
        userId: {
            title: 'Enter your User ID',
            placeholder: 'Enter your User ID',
            passplaceholder: 'Enter your Password ',
            passinputType: 'withIcon',
            inputType: 'default',
            icon: "assets/images/visibility_off.svg",
            forgotId: "Forgot User ID or Password",
        }
    }

    const onFormChange = (event) => {
        setShowError(false)
        setApiErrorMsg('');
        setCaptchaApiErrMsg('');
        handleForm(event.target.name, event.target.value);
    };

    useEffect(() => {
        setApiErrorMsg(() => '');
        setCaptchaApiErrMsg('');
        if (componentType == "mobNum") {
            loginTabForm.updateForm({
                "fieldOne": ["", [Validators.required, Validators.pattern(/^[6789][\d]{9}$/), Validators.equiLength(10, "Mobile Number")]],
                "fieldTwo": ["", [Validators.required]]
            })
        } else if (componentType == "emailId") {
            loginTabForm.updateForm({
                "fieldOne": ["", [Validators.required, Validators.mail]],
                "fieldTwo": ["", [Validators.required]]
            })
        } else {
            loginTabForm.updateForm({
                "fieldOne": ["", [Validators.required]],
                "fieldTwo": ["", [Validators.required]]
            })
        }
    }, [componentType])


    const loginWithIdApiCall = async () => {

        let response = await commonFunctionObject.getDeviceAndBrowserInfo();

        let requestObj = {
            "header": {
                "grantType": "password",
                "context": "login",
                "source": "COLLECTION",
                deviceType: response["deviceType"],
                browser: response["browser"]

            },
            "body": {
                "username": loginTabForm.value.fieldOne,
                "password": AesUtil.encryptData(loginTabForm.value.fieldTwo)
            }
        }
        LoginServiceObject.withPasswordService(requestObj)
            .then((response) => {
                const { status, authToken } = response?.header

                if (status !== "SUCCESS") {
                    setApiErrorMsg(() => "User ID and Password doesn't match")
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                    return
                }

                setCookie('Authorization', authToken, { path: "/" });
                dispatchData({
                    name: "authToken",
                    payload: authToken,
                    type: STOREDATA
                })
                LoginServiceObject.getUserDataV3Service(authToken).then(async(userDataResponse) => {
                    const { header, body } = userDataResponse;
                    if (header.status !== "SUCCESS") {
                        console.error("Not a valid user");
                        return
                    }
                    
                    const checkCcod = userDataResponse.body.otherSystemDetails.some(({ SYSTEM_NAME, ccodContractDetails = [] }) => {
                        return SYSTEM_NAME.toLowerCase() === "ccod" && ccodContractDetails.length > 0;
                    });

                    if (body.contracts.length > 0 || checkCcod) {
                        if (checkUserValid(userDataResponse)) {
                            let getFinalData = LoginServiceObject.commonLoginAfterUserData(userDataResponse, "withPassword", "", "collection");
                            const retailContractNumbers = [];
                            // Collect retail contract numbers
                            userDataResponse?.body?.contracts.forEach((item) => {
                              if (item?.system === "retail") {
                                retailContractNumbers.push(item.CONTRACT_NUMBER);
                              }
                            });

                            let getOfferData =await LoginServiceObject.offerCollectionDetails(authToken, retailContractNumbers).then((offerDataResponse)=>{
                                // console.log(offerDataResponse,"offerDataResponse");
                                return offerDataResponse
                            })
                            dispacthUserData(dispatchData, userDataResponse, getFinalData, getOfferData)
                            LoginServiceObject.userLoginTypeService(authToken).then((resp) => {
                                if (resp.header.status === "SUCCESS") {
                                    dispatchData({
                                        name: "setLoginType",
                                        payload: resp.body.loginType,
                                        type: STOREDATA
                                    })
                                }
                            })
                            navigate("/overview");
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA
                            })
                        } else {
                            console.error("Not a valid user");
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA
                            })
                        }
                    } else {
                        setFailurePopUpContent({
                            apiName: "",
                            message: <>Sorry, currently this service is not available. Please login to <a href='https://retailonline.tatacapital.com/#/login' target='_blank'>retailonline.tatacapital.com</a></>
                        })
                        setFailurePopUpModal(true);
                        navigate('/login')
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    }
                }).catch(err => {
                    console.log(err);
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                })

            }).catch((err => {
                setFailurePopUpModal(() => true);
                setFailurePopUpContent(() => {
                    return {
                        apiName: "",
                        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        errorCode: err
                    }
                })
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }))
    }

    const generateOtpAndUpdateState = () => {
        generateOtp(componentType, fieldOne, fieldTwo, captcha).then((res) => {
            if (res.header.status === 'SUCCESS') {
                setOtpDetails(() => {
                    return {
                        otpRef: res.body.otpRefNo,
                        otpAuthToken: res.header.authToken,
                    };
                });
                setCounter(prevState => prevState + 1);
                setOtpTimerFlag(true);
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }
        }).catch((err) => {
            if (err === 'Please Provide Your Registered Mobile No/E-Mail ID') {
                if (componentType === 'mobNum') {
                    setApiErrorMsg(() => 'Please Provide Your Registered Mobile Number')
                } else {
                    setApiErrorMsg(() => 'Please Provide Your Registered E-Mail ID')
                }
            } else if (err === '02') {
                setFailurePopUpModal(() => true);
                setFailurePopUpContent(() => {
                    return {
                        apiName: "",
                        message: "We are currently facing error in OTP services. Please try again later.",
                        errorCode: err
                    }
                })
            } else {
                if (err.toLowerCase() === 'invalid captcha') {
                    setCaptchaApiErrMsg(() => err);
                } else if (err.toLowerCase() === 'captcha expired') {
                    setCaptchaApiErrMsg(() => 'Captcha Expired, Kindly Enter New Captcha');
                } else if (err.toLowerCase() === "captcha error") {
                    // call api and set captcha value empty
                } else if (err.toLowerCase() === "number of attempts exceeded") {
                    // 
                }
            }
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
        });
    }

    const handleClick = (e) => {
        e.preventDefault();
        setShowError(true);

        if (loginTabForm.valid) {
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            if (componentType === 'mobNum' || componentType === 'emailId') {
                generateOtpAndUpdateState();

            } else {
                loginWithIdApiCall()
            }
        }
    }


    const errorMessage = apiErrorMsg || captchaApiErrMsg;
    const errorToRender = errorMessage ? (componentType === 'userId' ? apiErrorMsg : captchaApiErrMsg) : (showError && handleErrorMessage(loginTabForm, 'fieldTwo', componentType));

    return (
        <>
            <div className="c_login_form_body">
                <p className='c_login_form_title'>{obj[componentType]?.title}</p>
                <form >

                    <Input
                        type={componentType === 'emailId' ? 'text' : 'number'}
                        inputType={obj[componentType]?.inputType}
                        placeholder={obj[componentType]?.placeholder}
                        unit="+91"
                        value={fieldOne}
                        name="fieldOne"
                        onChange={onFormChange}
                    />
                    <span className='error'>{apiErrorMsg ? componentType != 'userId' && apiErrorMsg : showError && handleErrorMessage(loginTabForm, 'fieldOne', componentType)}</span>

                    {
                        obj[componentType]?.passinputType &&

                        <Input
                            type="password"
                            value={fieldTwo}
                            inputType={obj[componentType]?.passinputType}
                            placeholder={obj[componentType]?.passplaceholder}
                            icon={obj[componentType]?.icon}
                            name='fieldTwo'
                            onChange={onFormChange}
                            className="input-eye"
                        />

                    }
                    {
                        obj[componentType]?.captcha &&
                        <>
                            <div>
                                <div className="captcha-container">
                                    <div className="catcha-img">
                                        <img
                                            className="catcha-img-tg"
                                            src={captchaImg}
                                        />
                                    </div>
                                    <div className="refresh-img" onClick={() => {
                                        // handleForm('fieldOne', '')
                                        handleForm('fieldTwo', '')
                                        captchaApiCall()
                                    }}>
                                        <img src="assets/images/autorenew.svg" />
                                    </div>
                                </div>
                            </div>
                            <Input
                                type="text"
                                inputType="default"
                                name='fieldTwo'
                                value={fieldTwo}
                                placeholder="Enter Captcha"
                                error={loginTabForm.control.fieldTwo.dirty && loginTabForm.control.fieldTwo.error?.message}
                                onChange={onFormChange} />

                        </>
                    }
                    <span className='error'>{errorToRender}</span>
                    <Button type='submit' className="login" onClick={handleClick}>
                        Next
                        <img
                            className="c_arrow-right"
                            src="assets/images/right-arrow-white.svg"
                            alt=""
                        />
                    </Button>
                    {
                        obj[componentType]?.forgotId &&
                        <p className='c_forgot_link' onClick={() => setLoginTab("helpUs")}>{obj[componentType]?.forgotId}</p>
                    }
                </form>
            </div>
        </>
    )
}

RegisterComponent.propTypes = {
    captcha: PropTypes.string,
    componentType: PropTypes.string,
    setCounter: PropTypes.func,
    captchaImg: PropTypes.string,
    setLoginTab: PropTypes.func,
    captchaApiCall: PropTypes.func,
    otpDetails: PropTypes.object,
    setOtpDetails: PropTypes.func,
    loginTabForm: PropTypes.object,
    handleForm: PropTypes.func,
    showError: PropTypes.bool,
    setShowError: PropTypes.func,
    otpTimerFlag: PropTypes.bool,
    setOtpTimerFlag: PropTypes.func,
    apiErrorMsg: PropTypes.string,
    setApiErrorMsg: PropTypes.func,
    setFailurePopUpModal: PropTypes.func,
    setFailurePopUpContent: PropTypes.func,
    captchaApiErrMsg: PropTypes.string,
    setCaptchaApiErrMsg: PropTypes.func
}

export default RegisterComponent
