import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getAssetDetails = (requestObj) =>{
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "contractNo": "TCFUC0386000010063807"
        }
           
    }
    return new Promise((resolve, reject) => {
        stalwartApiCall('POST',requestObj,'/shaft/api/dms/asset-details/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getAssetDetails;