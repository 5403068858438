import React from "react";
import './RadioButton.scss';
import PropTypes from 'prop-types';


const RadioButton = ({ type = "", id = "", className = "", name = "", value = "", onClick, checked, onChange=()=>{} }) => {
    return (
        <>
            <input type={type} id={id} className={className} name={name} value={value} onClick={onClick} checked={checked} onChange={onChange}/>
        </>
    )
}

RadioButton.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    onClick: PropTypes.func,
    checked: PropTypes.bool,
}

export default RadioButton;