import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import JoyRide from "react-joyride";
import { useCookies } from 'react-cookie';
//Context
// import ThemeContext from "./context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../Shared/context/ThemeContext/store/ThemeContext";
//Components
import Header from "./components/Header";
//CSS
import 'antd/dist/antd.css';
import "./App.scss";
import reducer from "../../Shared/context/data-mapping/reducer/reducer";
import DataMapping from "../../Shared/context/data-mapping";
import PostLoginFooter from "./components/PostLoginFooter";
import Footer from "./container/Footer/Footer.jsx";
import Loader from "./global/Loader";
import Routes from "./Routes";
import Timer from "./components/Timer/Timer";
import { useLocation, useNavigate } from "react-router-dom";
import Logout from "./components/Logout";
import Analytics from "./utilities/Analytics";
// import AnalyticsContextProvider from "./context/AnalyticsContext/AnalyticsContextProvider";
import AnalyticsContextProvider from "../../Shared/context/AnalyticsContext/AnalyticsContextProvider";

function App() {
  const [themeState] =
    useContext(ThemeContext);
  ;
  const analytics = new Analytics();
  const myData = JSON.parse(sessionStorage.getItem("dataMapping"));
  const [data, dispatchData] = useReducer(reducer, myData ? myData : []);
  const navigate = useNavigate()
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const [cookies, setCookie] = useCookies();


  useEffect(() => {
    checkLoginSession()
  }, [])

  function checkLoginSession() {

    console.log(location);
    var Authorization = cookies?.['Authorization']
    if (data?.authToken && Authorization && data.authToken == Authorization) {
    }
    else {
      navigate("/login" + location.search);
    }
  }

  return (
    <>
      <AnalyticsContextProvider.Provider value={{ analytics }}>
        <div className={`${themeState}`} >
          <div className="loader"></div>
          <div className="main-background" >
            {data?.["loaderFlag"] ? <Loader /> : null}
            
            <div className={pathname.toLowerCase() === "login" ? "main-wrapper login-wrapper" : "main-wrapper"}>
              <Header />
              <DataMapping.Provider value={[data, dispatchData]}>
                {data?.authToken ? <Timer /> : null}
                <Routes />
                {data?.["currentActiveData"] ? <PostLoginFooter portalType="retail"/> : null}

              </DataMapping.Provider>
              {data?.authToken ? null : <Footer />}
            </div>
          </div>
        </div>
      </AnalyticsContextProvider.Provider>
    </>
  );
}

export default App;
