import React from "react";
import "./RadiantBorder.scss";
import PropTypes from 'prop-types';


const RadiantBorder = ({ children, style, className, dynamicBorder }) => {
    return (
        <div
            style={style}
            className={`${dynamicBorder == "yes" ? "radiant-no-border-container" : " "
                } ${className ? className : ""} c_radian_border_container`}
        >
            {children}
         
        </div>
    );
};

RadiantBorder.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    dynamicBorder: PropTypes.string
}

export default RadiantBorder;
