import React, { useContext, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Offers.scss";
import Button from "../../global/Button";
import RadiantBorder from "../../global/RadiantBorder";
import useSessionAlive from "../../customHooks/useSessionAlive";
import DataMapping from "../../../../Shared/context/data-mapping";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import OverviewServiceObject from "../../services/Overview";
import Modal from "../../global/Modal";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../../../Shared/context/ThemeContext/store/PageNameContext";
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import jsHelper from "../../utilities/jsHelper";

function Offers() {
  const [data, dispatchData] = useContext(DataMapping);
  const { isUserSessionAlive } = useSessionAlive();
  const { setPageName } = useContext(pageNameContext);
  const { analytics } = useContext(AnalyticsContextProvider);

  useEffect(() => {
    isUserSessionAlive(data?.authToken);
    setPageName('Offers');
    let analytics_data = {
      page: {
        name: "offers",
        siteSection: "offers",
        pageType: "",
        siteSubSection: "",
        pageURL: window.location.href,
        pathName: window.location.pathname
      },
      user: {
        loginMethod: jsHelper.toStr(data?.userLoginType?.body?.loginType),
        loginStatus: "",
        gcid: jsHelper.toStr(data?.getUserDataV3?.body?.primarySystem?.GCID),
        lobMembership: "",
        lastloginDate: jsHelper.toStr(data?.getUserDataV3?.body?.LAST_LOGGED_IN)
      },
      products: {
        productCode: ""
      }
    }
    analytics.trackData("pageInitialization", analytics_data);
  }, []);


  const [themeState] = useContext(ThemeContext);
  // console.log(Overview.preApprovedOffer);
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    "apiName": "",
    "message": "",
    "errorCode": ""
  })
  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");
  const [offerpopopen, setofferpopupopen] = useState(false);


  function applyNowFunction(types) {
    var offer = types;
    if (offer.serviceChange) {
      console.log("try");
      let requestJson = {
        "header": {
          "authToken": data.authToken,
        },
        "body": {
          "OfferId": offer.OfferId,
          "Product": offer.productName,
        }
      }
      dispatchData({
        type: STOREDATA,
        name: "loaderFlag",
        payload: true
      })
      OverviewServiceObject.offerToOpportunityService(requestJson).then((response) => {
        dispatchData({
          type: STOREDATA,
          name: "loaderFlag",
          payload: false
        })
      }).catch((error) => {
        console.log(error);
      });

    }
    else {
      if (offer.jocataRedirection) {
        let requestObj = {
          "header": {
            "authToken": data.authToken
          },
          "body": {
            "mobileNo": data.loginMobileNo
          }

        }
        dispatchData({
          type: STOREDATA,
          name: "loaderFlag",
          payload: true
        })
        OverviewServiceObject.getOffersService(requestObj).then((getOffersResponse) => {
          console.log(getOffersResponse)
          dispatchData({
            type: STOREDATA,
            name: "loaderFlag",
            payload: false
          })
        }).catch((error) => {
          dispatchData({
            type: STOREDATA,
            name: "loaderFlag",
            payload: false
          })
        })
        window.open(offer.applyNowBtn, '_self');
      } else {
        window.open(offer.applyNowBtn, '_blank');
      }
    }

  }

  function closepopup() {
    setofferpopupopen(false);
    dispatchData({
      name: "loaderFlag",
      type: STOREDATA,
      payload: false
    })
  }

  console.log(data.getOfferDetails);

  var profileoffer = data.getOfferDetails.response.slice(0, 3);
  console.log(profileoffer);

  return (
    <>

      {offerpopopen ?
        <Modal>
          <div className='offer-modal-popup'>
            <div className='description'>
              <h2 className="heading" style={{
                color: `${themeState === "THEME_DARK" ? "#ffffff" : ""
                  }`,
              }}>Thank you for reaching out</h2>
              <p style={{
                color: `${themeState === "THEME_DARK" ? "#ffffff" : ""
                  }`,
              }}>Thank You for applying to Tata Capital. Your loan application has been received and is
                being processed. Our Customer Service Representatives will contact you shortly.</p>
            </div>
            <div className='offer-popup-btn' onClick={closepopup}>
              <a>OK</a>
            </div>
          </div>
        </Modal>
        : null}

      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
      {successPopUpModal ? <SuccessPopUp
        onClick={() => setSuccessPopUpModal(false)}
        visible={successPopUpModal}
        setSuccessModalStat={setSuccessPopUpModal}
        successPopUpContent={successPopUpContent}
      /> : null}

      <div className="wrap_Div">
        {/* <div className="bg-01-container"></div>
        <div className="bg-02-container"></div> */}
        <div className="offersHeadingDiv">
          <p className="offersHeadingText main-container">Personalised Offers</p>
        </div>

        <Carousel
          className="desktopCarousel"
          showThumbs={false}
          showIndicators={false}
          showArrows="true"
          centerMode={true}
          selectedItem={0}
          centerSlidePercentage={45}
          infiniteLoop={true}
          showStatus={false}
        >
          {
            profileoffer && profileoffer.map((types, index) => (
              <div className="cCard" key={index}>
                <div className="offers_Card_TextDiv">
                  <div className="offer-card-title">
                    <p className="offers_BlueCardHeadText">{types.title}</p>
                  </div>
                  <div className="offers_BlueCardTextDiv">
                    <div className="offers_BlueCardTextFlexDiv">
                      <img src={`${types.serviceChange || types.jocataRedirection ? "assets/images/starTic.svg" : ""}`} style={{ width: 20 }} className={`${types.serviceChange || types.jocataRedirection ? "star_TicImg" : null} `} />
                      <p className="offers_BlueCardText">{types.description}</p>
                    </div>

                    {
                      types.serviceChange || types.jocataRedirection ?
                        <Button className='avail_Now_Btn'>
                          <p className='availNowBtnText' onClick={() => { applyNowFunction(types) }
                          }>Avail Now</p>
                          <img src='assets/images/arrow-right.svg' className='avail_Now_Arrow_Right' />
                        </Button> : <Button className='knowMore_Btn'>
                          <p className='knowMore_BtnText' onClick={() => {
                            // window.location.href = `${types.knowMore}`
                            window.open(types.knowMore, "_blank");
                          }}>Know more</p>
                          <img src='assets/images/arrow-right-blue.svg' className='avail_Now_Arrow_Right' style={themeState === "THEME_DARK" ? { filter: "brightness(0) invert(1)" } : null} />
                        </Button>
                    }

                  </div>
                </div>
                <div>
                  <img src="assets/images/offersblueCard.svg" className="offersBlueCard" />
                  <img src="assets/images/offersyellowCard.svg" className="offersYellowCard" />
                  <img src="assets/images/offersGirl.svg" className="offersGirl" />
                </div>
              </div>
            ))
          }

        </Carousel>
        <Carousel
          className="mobileTabCarousel"
          showThumbs={false}
          showIndicators={false}
          showArrows="true"
          centerMode={true}
          selectedItem={0}
          centerSlidePercentage={100}
          infiniteLoop={true}
          showStatus={false}
        >

          {
            profileoffer && profileoffer.map((types, index) => (
              <div className="cCard" key={index}>
                <div className="offers_Card_TextDiv">
                  <div className="offer-card-title">
                    <p className="offers_BlueCardHeadText">{types.title}</p>
                  </div>
                  <div className="offers_BlueCardTextDiv">
                    <div className="offers_BlueCardTextFlexDiv">
                      <img src={`${types.serviceChange || types.jocataRedirection ? "assets/images/starTic.svg" : ""}`} style={{ width: 20 }} className={`${types.serviceChange || types.jocataRedirection ? "star_TicImg" : null} `} />
                      <p className="offers_BlueCardText">{types.description}</p>
                    </div>

                    {
                      types.serviceChange || types.jocataRedirection ?
                        <Button className='avail_Now_Btn offer-btn'>
                          <p className='availNowBtnText' onClick={() => { applyNowFunction(types) }
                          }>Avail Now</p>
                          <img src='assets/images/arrow-right.svg' className='avail_Now_Arrow_Right' />
                        </Button> : <Button className='knowMore_Btn offer-btn'>
                          <p className='knowMore_BtnText' onClick={() => {
                            // window.location.href = `${types.knowMore}`
                            window.open(types.knowMore, "_blank");
                          }}>Know more</p>
                          <img src='assets/images/arrow-right-blue.svg' className='avail_Now_Arrow_Right' style={themeState === "THEME_DARK" ? { filter: "brightness(0) invert(1)" } : null} />
                        </Button>
                    }

                  </div>
                </div>
                <div>
                  <img src="assets/images/offersblueCard.svg" className="offersBlueCard" />
                  <img src="assets/images/offersyellowCard.svg" className="offersYellowCard" />
                  <img src="assets/images/offersGirl.svg" className="offersGirl" />
                </div>
              </div>
            ))
          }

        </Carousel>

        <div className="offersHeadingDiv main-container">
          <p className="offersHeadingText">Other Offers</p>
        </div>
        <RadiantBorder className="radiantBorderWidth main-container">
          <div className="otherOfferCardDiv">
            <div className="otherOfferCard">
              <img src="assets/images/card4.svg" className="otherOfferCard4Img" />
              <img
                src="assets/images/card4.1.svg"
                className="otherOfferSpringImg"
              />
            </div>

            <div className="otherOffersCenterContainer">
              <p className="otherOfferCardHeadText">Business Loan</p>
              <p className="otherOfferCardHeadBoldText">
                Avail loans to suit your business requirements
              </p>
              <div className="otherOfferCardFlexDiv">
                <div className="otherOfferCardJustifyDiv">
                  <div className="otherOfferCardTextDiv">
                    <img src="assets/images/tick1.svg" className="tick1_Img" />
                    <div className="center_Card_Text_Div">
                      <p className="otherOfferCardText">
                        Multiple Product Offering
                      </p>
                    </div>
                  </div>
                  <div className="otherOfferCardTextDiv">
                    <img src="assets/images/tick1.svg" className="tick1_Img" />
                    <div className="center_Card_Text_Div">
                      <p className="otherOfferCardText">High Eligibility</p>
                    </div>
                  </div>
                </div>
                <div className="otherOfferCardJustifyDiv">
                  <div className="otherOfferCardTextDiv2">
                    <img src="assets/images/tick1.svg" className="tick1_Img" />
                    <div className="center_Card_Text_Div">
                      <p className="otherOfferCardText">Structured EMI option</p>
                    </div>
                  </div>
                  <div className="otherOfferCardTextDiv2">
                    <img src="assets/images/tick1.svg" className="tick1_Img" />
                    <div className="center_Card_Text_Div">
                      <p className="otherOfferCardText">No Security </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="otherOfferButtonDiv">
              <Button className="avail_Now_Btn">
                <p className="availNowBtnText">Avail Now</p>
                <img
                  src="assets/images/arrow-right.svg"
                  className="avail_Now_Arrow_Right"
                />
              </Button>
            </div>
          </div>
        </RadiantBorder>

        <RadiantBorder className="radiantBorderWidth main-container">
          <div className="otherOfferCardDiv">
            <div className="otherOfferCard">
              <img src="assets/images/card4.svg" className="otherOfferCard4Img" />
              <img
                src="assets/images/card4.1.svg"
                className="otherOfferSpringImg"
              />
            </div>

            <div className="otherOffersCenterContainer">
              <p className="otherOfferCardHeadText">Business Loan</p>
              <p className="otherOfferCardHeadBoldText">
                Avail loans to suit your business requirements
              </p>
              <div className="otherOfferCardFlexDiv">
                <div className="otherOfferCardJustifyDiv">
                  <div className="otherOfferCardTextDiv">
                    <img src="assets/images/tick1.svg" className="tick1_Img" />
                    <div className="center_Card_Text_Div">
                      <p className="otherOfferCardText">
                        Multiple Product Offering
                      </p>
                    </div>
                  </div>
                  <div className="otherOfferCardTextDiv">
                    <img src="assets/images/tick1.svg" className="tick1_Img" />
                    <div className="center_Card_Text_Div">
                      <p className="otherOfferCardText">High Eligibility</p>
                    </div>
                  </div>
                </div>

                <div className="otherOfferCardJustifyDiv">
                  <div className="otherOfferCardTextDiv2">
                    <img src="assets/images/tick1.svg" className="tick1_Img" />
                    <div className="center_Card_Text_Div">
                      <p className="otherOfferCardText">Structured EMI option</p>
                    </div>
                  </div>
                  <div className="otherOfferCardTextDiv2">
                    <img src="assets/images/tick1.svg" className="tick1_Img" />
                    <div className="center_Card_Text_Div">
                      <p className="otherOfferCardText">No Security </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="otherOfferButtonDiv">
              <Button className="avail_Now_Btn">
                <p className="availNowBtnText">Avail Now</p>
                <img
                  src="assets/images/arrow-right.svg"
                  className="avail_Now_Arrow_Right"
                />
              </Button>
            </div>
          </div>
        </RadiantBorder>




      </div>
    </>
  );
}

export default Offers;
