import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const logUiUrl = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "contractNumber": "TCFTW0536000011079219",
            "base64": "dHhuX2lkPUVOMjgwMzA0NzEyNTUxNTIxOSZiYXRjaF9pZD11bmRlZmluZWQmZW1hbmRhdGVfdG9rZW5faWQ9JmVtYW5kYXRlX3N0YXR1cz0wMzk5JmVtYW5kYXRlX2Vycm9yX21lc3NhZ2U9U2VsZWN0ZWQgQmFuayBOb3QgRm91bmQmbW1zX3N0YXR1cz10cnVlJnN0YXR1c19tZXNzYWdlPXN1Y2Nlc3Mmc3RhdHVzPTIwMCZlbWFuZGF0ZV9zdGF0dXNfbWVzc2FnZT1mYWlsdXJlJnRpbWVzdGFtcD0yMDIyLTAzLTA0MDc6MTM6MzI"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/logUrl/log-ui-url/v1',data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/logUrl/log-ui-url/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default logUiUrl