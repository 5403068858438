import { useState } from "react";
import pageNameContext from "./PageNameContext";

const PageNameProvider = ({ children }) => {
  const [pageName, setPageName] = useState("");
  const [customerServicePageName, setCustomerServicePageName] =
    useState("banner");

  return (
    <pageNameContext.Provider
      value={{
        pageName,
        setPageName,
        customerServicePageName,
        setCustomerServicePageName,
      }}
    >
      {children}
    </pageNameContext.Provider>
  );
};
export default PageNameProvider;
