import React, { useContext, useState, useEffect } from "react";
import "./DashboardData.scss";
import RadiantBorder from "../RadiantBorder/RadiantBorder";
import DashboardDataHead from "../DashboardDataHead/DashboardDataHead";
import DashboardInfo from "../DashboardInfo/DashboardInfo";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import PropTypes from "prop-types";
import { off } from "process";

const DashboardData = ({
  handleClickForPaymentOpt,
  data,
  setPreapprovedOfferFlag,
  preapprovedOfferFlag,
  attemptFlag,
  payNowFlag,
  paymentFlag,
  validateFlagEmail,
  setValidateFlagEmail,
  validateFlagMobile,
  setValidateFlagMobile,
  setActiveOffer = () => {},
  setDashPaymentData = () => {},
  setJourneyType = () => {},
  setPaymentSummeryFlag = () => {},
  setAttemptFlag = () => {},
  setPopOverFlag = () => {},
  setPayNowFlag = () => {},
  disableBtn,
  setDisableBtn = () =>{}
}) => {
  const [contextData, dispatchData] = useContext(DataMapping);
  const [activeLoan, setActiveLoan] = useState(
    contextData?.activePaymentLoanData?.activePaymentFlag == true
      ? contextData?.activePaymentLoanData?.loanAccNumber
      : data[0]?.contractNumber
  );

  useEffect(() => {
    if (activeLoan) {      
      let index = data.findIndex((ele) => ele.contractNumber === activeLoan);
      let currentActiveData = data[index];
      // console.log(currentActiveData,"currentActiveData");
      // console.log(disableBtn,"disableBtn");
      
      setPayNowFlag(false);

      if (currentActiveData?.offerFlag) {
        if (currentActiveData?.offerFlag === "Y") {
          if (preapprovedOfferFlag) {
            if (contextData?.phaseDataFlag) {
              // setJourneyType("offer");
              dispatchData({
                type: STOREDATA,
                payload: true,
                name: "callpgs",
              });
            } else {
              setJourneyType("offer");
              dispatchData({
                type: STOREDATA,
                payload: false,
                name: "callpgs",
              });
            }
          } else {
            setJourneyType("non-offer");
            dispatchData({
              type: STOREDATA,
              payload: true,
              name: "callpgs",
            });
          }
        } else {
          handleClickForPaymentOpt("isSettlementOffer","","N","",activeLoan);
          handleClickForPaymentOpt("loancardclicked", "", "", "", activeLoan);
          handleClickForPaymentOpt(
            "paymentoptclicked",
            "Total Overdue Amount",
            "",
            "",
            activeLoan
          );
          setJourneyType("non-offer");
          dispatchData({
            type: STOREDATA,
            payload: true,
            name: "callpgs",
          });
        }
      } else if (!currentActiveData?.offerFlag) {
        setPreapprovedOfferFlag(false);
        handleClickForPaymentOpt("isSettlementOffer","","N","",activeLoan);
        handleClickForPaymentOpt("loancardclicked", "", "", "", activeLoan);
        handleClickForPaymentOpt(
          "paymentoptclicked",
          "Total Overdue Amount",
          "",
          "",
          activeLoan
        );
      }

      const getofferKey = (loan) => {
        const keysToCheck = [
          "SETTLEMENT_CAMPAIGN",
          "EMI_OVERDUE_CAMPAIGN",
          "ODBC_CAMPAIGN",
          "LOS_POOL_CAMPAIGN",
          "ALREADY_SETTLED_POOL_CAMPAIGN",
        ];

        for (const key of keysToCheck) {
          if (loan[key] === "Y") {
            return key;
          }
        }

        return null;
      };

      if (currentActiveData?.offerData) {
        const offerName = getofferKey(currentActiveData?.offerData);
        if (offerName === "SETTLEMENT_CAMPAIGN") {
          setPopOverFlag(false);
          setPayNowFlag(true);
          handleClickForPaymentOpt("isSettlementOffer","","Y","",activeLoan);
          handleClickForPaymentOpt("loancardclicked", "", "", "", activeLoan);
          handleClickForPaymentOpt("setLoanFcDetails", "", currentActiveData?.offerData, "", activeLoan);
        } else if (offerName === "EMI_OVERDUE_CAMPAIGN") {
          setPopOverFlag(false);
          setPayNowFlag(false);
        } else if (offerName === "ODBC_CAMPAIGN") {
          setPopOverFlag(false);
          setPayNowFlag(false);
        }
        if (contextData?.phaseDataFlag == false) {
          if (contextData?.callpgs == false) {
            dispatchData({
              name: "currentOfferPopup",
              payload: offerName,
              type: STOREDATA,
            });
          }
        }
        // console.log(offerName,"offerName");
        setActiveOffer(offerName);
        setDashPaymentData(currentActiveData);
        dispatchData({
          name: "dashPaymentData",
          type: STOREDATA,
          payload: currentActiveData

        })
      }

      dispatchData({
        type: STOREDATA,
        payload: {
          accountNumber: activeLoan,
          system: contextData?.parsedUserData[activeLoan]?.system,
        },
        name: "currentActiveData",
      });
      // handleClickForPaymentOpt("loancardclicked", "", "", "", activeLoan);
      // handleClickForPaymentOpt(
      //   "paymentoptclicked",
      //   "Total Overdue Amount",
      //   "",
      //   "",
      //   activeLoan
      // );
    }
  }, [activeLoan, paymentFlag]);

  useEffect(() => {
    setActiveLoan(activeLoan);
  }, [activeLoan]);

  return (
    <div className="c_dashboard_data">
      <RadiantBorder className="c_radiant_border">
        <DashboardDataHead
          data={data}
          activeLoan={activeLoan}
          setActiveLoan={setActiveLoan}
          setPreapprovedOfferFlag={setPreapprovedOfferFlag}
          attemptFlag={attemptFlag}
          payNowFlag={payNowFlag}
          paymentFlag={paymentFlag}
          setAttemptFlag={setAttemptFlag}
          setPaymentSummeryFlag={setPaymentSummeryFlag}
          setPopOverFlag={setPopOverFlag}
          setPayNowFlag={setPayNowFlag}
          validateFlagEmail={validateFlagEmail}
          setValidateFlagEmail={setValidateFlagEmail}
          validateFlagMobile={validateFlagMobile}
          setValidateFlagMobile={setValidateFlagMobile}
          disableBtn={disableBtn}
          setDisableBtn={setDisableBtn}
        />
        <DashboardInfo
          currentAccountNumber={activeLoan}
          data={data}
          preapprovedOfferFlag={preapprovedOfferFlag}
          textMoreDetails="More Details"
          src="assets/images/chevron-right.png"
          alt="chevron_right_icon"
        />
      </RadiantBorder>
    </div>
  );
};

DashboardData.propTypes = {
  data: PropTypes.array,
  handleClickForPaymentOpt: PropTypes.func,
};
export default DashboardData;
