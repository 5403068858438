import commonFunctionObject from "../../../Retail/src/common-function";
import OverviewServiceObject from "../../../Retail/src/services/Overview";
import jsHelper from "../../../Retail/src/utilities/jsHelper";
import {
  STOREACCOUNTDATA,
  STOREDATA,
} from "../../../Shared/context/data-mapping/action/action.type";

let finalAccountNumberObject = {
  "Active Loans": [],
  "Closed Loans": [],
  LAS: [],
  CCOD: [],
};

const offerData = [
  // {
  //   "Loan Account No": "TCFPL0631000010002673",
  //   "Bkt": "Woff",
  //   "FC": 1000,
  //   "FC AS ON DATE": 2023 / 10 / 12,
  //   "FC POS": 600,
  //   "FC INT": 200,
  //   "FC CHARGES": 100,
  //   "Waived Amount": "NA",
  //   "Settlement Campaign": "N",
  //   "ODBC Campaign": "N",
  //   "EMI Over Due Campaign": "N",
  //   "LOS pool Campaign": "Y",
  //   "Already settled pool Campaign": "N",
  //   "offerFlag": true,
  //   "Settlement amount Min": "NA",
  //   "ODBC amount min": "NA",
  //   "Min LOS Collection": "5000",
  //   "No of Tranches": "NA",
  //   "Tranche-1": "NA",
  //   "Tranche-2": "NA",
  //   "Collection Manager Name": "Mohammad Haseeb",
  //   "Contact no": 9999444909,
  //   "Category": "NA",
  //   "Nudge": ["Nudge 1", "Nudge 2", "Nudge 3", "Nudge 4", "Nudge 5"],
  //   "Loan Status": "Closed",
  // },
  {
    "Loan Account No": "TCFBL0284000010009410",
    "Bkt": "Woff",
    "FC": 12560302,
    "FC AS ON DATE": 2023 / 10 / 25,
    "FC POS": 10000,
    "FC INT": 2300,
    "FC CHARGES": 100,
    "Waived Amount": "NA",
    "Settlement Campaign": "N",
    "ODBC Campaign": "N",
    "EMI Over Due Campaign": "N",
    "LOS pool Campaign": "N",
    "Already settled pool Campaign": "N",
    "offerFlag": false,
    "Settlement amount Min": 6303276.14835048,
    "ODBC amount min": 50000,
    "Min LOS Collection": 0,
    "No of Tranches": 2,
    "Tranche-1": 0.5,
    "Tranche-2": 0.5,
    "Collection Manager Name": "Mohammad Haseeb",
    "Contact no": 9999444909,
    "Category": "A",
    "Nudge": ["Nudge 1", "Nudge 2", "Nudge 3", "Nudge 4", "Nudge 5"],
    "Loan Status": "Active",
  },
  {
    "Loan Account No": "TCFPL0485000010002583",
    "Bkt": "Woff",
    "FC": 10824039,
    "FC AS ON DATE": 2023 / 10 / 12,
    "FC POS": 15000,
    "FC INT": 3500,
    "FC CHARGES": 200,
    "Waived Amount": "NA",
    "Settlement Campaign": "Y",
    "ODBC Campaign": "N",
    "EMI Over Due Campaign": "N",
    "LOS pool Campaign": "N",
    "Already settled pool Campaign": "N",
    "offerFlag": true,
    "Settlement amount Min": 4700336.521108976,
    "ODBC amount min": 40000,
    "Min LOS Collection": 0,
    "No of Tranches": 1,
    "Tranche-1": 1,
    "Tranche-2": 0,
    "Collection Manager Name": "Mohammad Haseeb",
    "Contact no": 9999444909,
    "Category": "C",
    "Nudge": ["Nudge 1", "Nudge 2", "Nudge 3", "Nudge 4", "Nudge 5"],
    "Loan Status": "Active",
  },
  {
    "Loan Account No": "TCFUC0632000010776218",
    "Bkt": "Woff",
    "FC": 500000,
    "FC AS ON DATE": 2023 / 10 / 21,
    "FC POS": 20000,
    "FC INT": 4300,
    "FC CHARGES": 300,
    "Waived Amount": "NA",
    "Settlement Campaign": "Y",
    "ODBC Campaign": "N",
    "EMI Over Due Campaign": "N",
    "LOS pool Campaign": "N",
    "Already settled pool Campaign": "N",
    "offerFlag": true,
    "Settlement amount Min": 450000,
    "ODBC amount min": 70000,
    "Min LOS Collection": 0,
    "No of Tranches": 2,
    "Tranche-1": 0.5,
    "Tranche-2": 0.5,
    "Collection Manager Name": "Mohammad Haseeb",
    "Contact no": 9999444909,
    "Category": "B",
    "Nudge": ["Nudge 1", "Nudge 2", "Nudge 3", "Nudge 4", "Nudge 5"],
    "Loan Status": "Active",
  },
  {
    "Loan Account No": "TCFPL0453000010005313",
    "Bkt": "Woff",
    "FC": 5000,
    "FC AS ON DATE": 2023 / 10 / 21,
    "FC POS": 3000,
    "FC INT": 2000,
    "FC CHARGES": 400,
    "Waived Amount": "NA",
    "Settlement Campaign": "N",
    "ODBC Campaign": "Y",
    "EMI Over Due Campaign": "N",
    "LOS pool Campaign": "N",
    "Already settled pool Campaign": "N",
    "offerFlag": true,
    "Settlement amount Min": 0,
    "ODBC amount min": 5000,
    "Min LOS Collection": 0,
    "No of Tranches": 2,
    "Tranche-1": 0.5,
    "Tranche-2": 0.5,
    "Collection Manager Name": "Mohammad Haseeb",
    "Contact no": 9999444909,
    "Category": "B",
    "Nudge": ["Nudge 1", "Nudge 2", "Nudge 3", "Nudge 4", "Nudge 5"],
    "Loan Status": "Active",
  },
  {
    "Loan Account No": "TCHHL0639000100003064",
    "Bkt": "Woff",
    "FC": 5000,
    "FC AS ON DATE": 2023 / 10 / 21,
    "FC POS": 2000,
    "FC INT": 1000,
    "FC CHARGES": 400,
    "Waived Amount": "NA",
    "Settlement Campaign": "N",
    "ODBC Campaign": "N",
    "EMI Over Due Campaign": "N",
    "LOS pool Campaign": "Y",
    "Already settled pool Campaign": "N",
    "offerFlag": true,
    "Settlement amount Min":0,
    "ODBC amount min": 5000,
    "Min LOS Collection": 0,
    "No of Tranches": 2,
    "Tranche-1": 0.5,
    "Tranche-2": 0.5,
    "Collection Manager Name": "Mohammad Haseeb",
    "Contact no": 9999444909,
    "Category": "B",
    "Nudge": ["Nudge 1", "Nudge 2", "Nudge 3", "Nudge 4", "Nudge 5"],
    "Loan Status": "Active",
  }
];

export const dispacthUserData = (
  dispatchData,
  getUserDataV3Response,
  getFinalData,
  getOfferData
) => {
  // console.log(getUserDataV3Response,"qwqwqwqwqw");
  // console.log(getUserDataV3Response?.body?.contracts[0]?.CONTRACT_NUMBER, "getUserDataV3Response");
  // console.log(getOfferData,"getOfferData");
  let currentActiveData = {};

  if (getUserDataV3Response?.body?.contracts.length > 0) {
    currentActiveData = {
      accountNumber: getUserDataV3Response?.body?.contracts[0]?.CONTRACT_NUMBER,
      system: getUserDataV3Response?.body?.contracts[0]?.system,
    };
  } else {
    if (getUserDataV3Response?.body.otherSystemDetails.length > 0) {
      currentActiveData = {
        accountNumber:
          getUserDataV3Response?.body?.otherSystemDetails[0]
            ?.ccodContractDetails?.[0]?.cdiContract?.CUSTOMER_NO,
        system: getUserDataV3Response?.body?.otherSystemDetails[0]?.SYSTEM_NAME,
      };
    }
  }


  // console.log(currentActiveData,"currentActiveData");

  dispatchData({
    type: STOREDATA,
    name: "currentActiveData",
    payload: currentActiveData,
  });

  dispatchData({
    type: STOREDATA,
    name: "offers",
    payload: getOfferData,
  });

  dispatchData({
    name: "offerModaLFlag",
    payload: {
      modalFlag: false,
      accountType: "availOfferModal",
    },
    type: STOREDATA,
  });

  // dispatchData({
  //   type: STOREDATA,
  //   name: "offerModalType",
  //   payload: {
  //     modalFlag: true,
  //     accountType: "availOfferModal",
  //     accountNumbers: offerData[0]['Loan Account No']
  //   },
  // });

  dispatchData({
    type: STOREDATA,
    name: "loginMobileNo",
    payload: getFinalData.mobileNumber,
  });
  dispatchData({
    type: STOREDATA,
    name: "getUserDataV3",
    payload: getUserDataV3Response,
  });
  dispatchData({
    type: STOREDATA,
    name: "customerName",
    payload: jsHelper.toSentence(getFinalData.customerName),
  });

  dispatchData({
    type: STOREDATA,
    name: "parsedUserData",
    payload: getFinalData.parsedUserData,
  });
  if (
    getUserDataV3Response.body?.OVERLAY_DISPLAY &&
    getUserDataV3Response.body?.OVERLAY_DISPLAY.toUpperCase() === "Y"
  ) {
    dispatchData({
      type: STOREDATA,
      name: "overLayFlag",
      payload: true,
    });
  } else {
    dispatchData({
      type: STOREDATA,
      name: "overLayFlag",
      payload: false,
    });
  }
};

export const dispatchLoanData = (
  dispatchData,
  data,
  preApi,
  loanData,
  setLoanData
) => {
  if (!data?.getLoanPositionDTLS) {
    if (data?.["getUserDataV3"]) {
      data?.["getUserDataV3"].body.contracts.forEach((contractData) => {
        let getLoanRequest = {
          header: {
            authToken: data.authToken,
          },
          body: {
            GCID: contractData.GCID,
            LoanAcctNo: contractData.CONTRACT_NUMBER,
          },
        };
        // console.log(getLoanRequest,"getLoanRequest");

        dispatchData({
          name: "loaderFlag",
          type: STOREDATA,
          payload: true,
        });
        OverviewServiceObject.getLoanPositionDTLSService(getLoanRequest, preApi)
          .then((getLoanPositionDtlsResponse) => {
            // console.log(getLoanPositionDtlsResponse,"getLoanPositionDtlsResponse");
            dispatchData({
              type: STOREACCOUNTDATA,
              payload: {
                response: getLoanPositionDtlsResponse,
                contractNumber: contractData.CONTRACT_NUMBER,
              },
              name: "getLoanPositionDTLS",
            });

            let activeStatus = [
              "new account",
              "partially advanced",
              "applied for cancellation",
              "approved",
              "accepted",
              "request for termination",
              "applied for termination",
              "fully advanced",
              "active",
            ];
            let productName = commonFunctionObject.getProductName(
              data.parsedUserData[contractData.CONTRACT_NUMBER]
            );
            // console.log(productName,"product");
            if (
              activeStatus.includes(
                getLoanPositionDtlsResponse?.body?.getLoanPositionDtlsReturn?.loanStatus.toLowerCase()
              )
            ) {
              dispatchData({
                type: STOREACCOUNTDATA,
                payload: {
                  response: getLoanPositionDtlsResponse,
                  contractNumber: contractData.CONTRACT_NUMBER,
                },
                name: "openContracts",
              });

              let productShortName =
                commonFunctionObject.getProductShortName(productName);

              var accountNumberObject = {
                value:
                  contractData.CONTRACT_NUMBER + " (" + productShortName + ")",
                system: "retail",
              };

              // console.log(accountNumberObject, "accountNumberObject");
              finalAccountNumberObject["Active Loans"].push(
                accountNumberObject
              );

              dispatchData({
                type: STOREDATA,
                payload: finalAccountNumberObject,
                name: "accountNumberDropDownArray",
              });
            } else {
              dispatchData({
                type: STOREACCOUNTDATA,
                payload: {
                  response: getLoanPositionDtlsResponse,
                  contractNumber: contractData.CONTRACT_NUMBER,
                },
                name: "closedContracts",
              });

              let productShortName =
                commonFunctionObject.getProductShortName(productName);

              var accountNumberObject = {
                value:
                  contractData.CONTRACT_NUMBER + " (" + productShortName + ")",
                system: "retail",
              };

              // console.log(accountNumberObject, "accountNumberObject");
              finalAccountNumberObject["Closed Loans"].push(
                accountNumberObject
              );

              dispatchData({
                type: STOREDATA,
                payload: finalAccountNumberObject,
                name: "accountNumberDropDownArray",
              });
            }
          })
          .catch((error) => {
            dispatchData({
              name: "loaderFlag",
              type: STOREDATA,
              payload: false,
            });
            if (error === "12") {
              console.log("popupCode goes here");
            }
          });
      });
    }
  }
};

export const callVdSummaryAPI = (dispatchData, data, preApi) => {
  let ccodData = [];
  data?.getUserDataV3?.body?.otherSystemDetails.forEach((currentLoan) => {
    if (currentLoan.SYSTEM_NAME.toLowerCase() == "ccod") {
      ccodData = currentLoan.ccodContractDetails;
    }
  });

  if (ccodData.length > 0) {
    dispatchData({
      name: "loaderFlag",
      type: STOREDATA,
      payload: true,
    });

    ccodData.forEach((currentLoan) => {
      let contractNumber = currentLoan.cdiContract.CUSTOMER_NO;
      let companyName =
        currentLoan.cdiContract.COMPANY.toUpperCase() === "TCL"
          ? "CFAB"
          : currentLoan.cdiContract.COMPANY.toUpperCase();

      let requestObj = {
        header: {
          authToken: data.authToken,
          company: companyName,
        },
        body: {
          pName: currentLoan?.sapContract.PROGRAM_NAME,
          groupKey: currentLoan?.sapContract.DEALER_GROUP_KEY,
          manuCoCode: currentLoan?.sapContract.MANU_CO_CODE,
          product: currentLoan?.sapContract.PRODUCT,
        },
      };

      if (!data?.vdSummary?.[contractNumber]) {
        OverviewServiceObject.vdSummaryDTLSService(
          requestObj,
          contractNumber,
          preApi
        )
          .then((vdSummaryDtlsResponse) => {
            console.log("vdSummaryDtlsResponse ::", vdSummaryDtlsResponse);
            dispatchData({
              type: STOREACCOUNTDATA,
              payload: {
                response: vdSummaryDtlsResponse,
                contractNumber: contractNumber,
              },
              name: "ccodContracts",
            });
            dispatchData({
              type: STOREACCOUNTDATA,
              payload: {
                response: vdSummaryDtlsResponse,
                contractNumber: contractNumber,
              },
              name: "vdSummary",
            });

            var accountNumberObject = {
              value: contractNumber + " (CCOD)",
              system: "ccod",
            };

            finalAccountNumberObject["CCOD"].push(accountNumberObject);

            dispatchData({
              type: STOREDATA,
              payload: finalAccountNumberObject,
              name: "accountNumberDropDownArray",
            });
          })
          .catch((error) => {
            dispatchData({
              name: "loaderFlag",
              type: STOREDATA,
              payload: false,
            });
          });
      }
    });
  }
};

export function resetAccountNumberArray() {
  finalAccountNumberObject = {
    "Active Loans": [],
    "Closed Loans": [],
    LAS: [],
    CCOD: [],
  };
}
