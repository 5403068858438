import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getNormaliseEmiOverdueRes = (data) => {
  let requestJson = data;
  // console.log(requestJson,"requestJson");
  return new Promise((resolve, reject) => {
    // axios.post('/shaft/api/db/get-user-data-v3/v1', requestJson).then(response => {
    stalwartApiCall("POST", requestJson, "/shaft/api/collection/calculate-emi-waiver-amount/v1")
      .then((response) => {
        resolve(response.data);
        // resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default getNormaliseEmiOverdueRes;
