import React, { useState, useEffect, useContext } from "react";
import Modal from "../../global/Modal";
import "./ProvisionalCertificate.scss";
import CustomDropDown from "../../global/CustomDropDown";
import Button from "../../global/Button";
import userData from "../../screens/Overview/Data";
import OverviewServiceObject from "../../services/Overview";
import commonFunctionObject from "../../common-function";
import Loader from '../../global/Loader'
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type';
import jsHelper from '../../utilities/jsHelper';
import FailurePopUp from "../../global/FailurePopUp";
import PropTypes from 'prop-types';

var base64 = require('base-64');
const ProvisionalCertificate = ({
    setProvisionalCertificatePopUp,
    emailTrigger,
}) => {
    const [yearState, setYearState] = useState(false);
    const [loaderFlag, setLoaderFlag] = useState(false);
    const [emailPopup, setemailPopup] = useState(false);
    const [themeState] = useContext(ThemeContext);
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    })
    const [selectedYear, setSelectedYear] = useState("Select Financial Year");
    const [data, dispatchData] = useContext(DataMapping)
    var accountNumber = data?.currentActiveData.accountNumber
    var currentUserData = data?.parsedUserData[accountNumber]
    const sourceSystem = currentUserData.SOURCE_SYSTEM;
    var disbursalDate = currentUserData.DISBURSAL_DATE;
    var webTopNo = currentUserData.WEBTOP_NO;
    console.log(disbursalDate);
    var yearFormat;
    const datechange = (disbursalDate) => {
        var changeDate = disbursalDate.split("-");
        yearFormat = "20" + changeDate[2];
        console.log(yearFormat);
        return yearFormat;
    };
    datechange(disbursalDate);
    const [yearsArr, setYearsArr] = useState([]);
    var yearPicker = (yearFormat, yearFlag, currentDate) => {
        console.log(currentDate.getFullYear())
        var yearLastTwoDigit = yearFormat
            ? Number(yearFormat.toString())
            : new Error("yearFormat.years is not defined");
        var todaysYearLastTwoDigit = yearFlag
            ? Number(currentDate.getFullYear().toString().slice(-2))
            : Number(currentDate.getMonth()) + 1 < 4
                ? Number(currentDate.getFullYear().toString().slice(-2))
                : Number(currentDate.getFullYear(currentDate).toString().slice(-2)) + 1;
        if (yearFlag) {
            for (
                yearLastTwoDigit;
                yearLastTwoDigit < todaysYearLastTwoDigit;
                yearLastTwoDigit++
            ) {
                var next = 1;
                next += yearLastTwoDigit;
                yearsArr.push({ value: yearLastTwoDigit + "-" + next });
                setYearsArr(yearsArr);
            }
            return yearsArr;
        } else {
            yearsArr.push({ value: ("20" + todaysYearLastTwoDigit - 1) + "-" + ("20" + todaysYearLastTwoDigit) });
            setYearsArr(yearsArr);
            console.log(yearsArr)
            return yearsArr;
        }
    };
    console.log(yearsArr);
    useEffect(() => {
        yearPicker(yearFormat, false, new Date());
    }, []);
    const downloadProvisionalITCertificate = () => {
        var productFam = commonFunctionObject.getProductFamily(currentUserData)

        let getServerTimeRequest = {
            "header": {
                "authToken": data.authToken
            },
            "body": {
            }
        }

        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        commonFunctionObject.getServerTimeService(getServerTimeRequest).then((serverTime) => {
            var currentYear = Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear());
            // var  nextYear = Number(new Date(serverTime).getFullYear() + 1);
            var financialYearFromDate = "01/04/" + +currentYear;
            console.log(financialYearFromDate)
            var financialYearToDate = ((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate()) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate()) : Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getDate())) + "/" + (((Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) < 10) ? "0" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1) : Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getMonth() + 1)) + "/" + Number(new Date(commonFunctionObject.replaceHyphenDate(serverTime)).getFullYear());
            console.log(financialYearToDate)
            var formData = {
                "startDate": financialYearFromDate,
                "endDate": financialYearToDate
            }
            if (sourceSystem === "FINONE") {
                var AccountNo = currentUserData.CONTRACT_NUMBER;
                var requestObj = {
                    header: {
                        authToken:
                            data?.authToken,
                        productFamily: productFam,
                        emailSent: true,
                        documentUploadType: "provisionalCertificate",
                    },
                    body: {
                        accountNumber: accountNumber,
                        fromDate: formData.startDate,
                        toDate: formData.endDate,
                    },
                };
                dispatchData({
                    name: "loaderFlag",
                    payload: true,
                    type: STOREDATA
                })
                OverviewServiceObject.InterestCertificateServiceFinone(requestObj, sourceSystem).then((response) => {
                    var data = {
                        documentName: "provisionalCertificate",
                        "productFamily": productFam,
                        "decscription": "Generate Provisional Certificate for " + accountNumber + " loan account.",
                        "webtopId": webTopNo,
                        "sebServiceRequestType": "provisionalCertificate",
                        "errorCode": "253",

                    };
                    var loanAccNumber =
                        currentUserData.CONTRACT_NUMBER;
                    var firstName = currentUserData.FIRSTNAME;
                    var lastName = currentUserData.LASTNAME;
                    var email = currentUserData.EMAIL;
                    emailTrigger(
                        data,
                        accountNumber,
                        response.body.isEmailAvailable,
                        commonFunctionObject.getLastestFieldValFromField(email),
                        firstName,
                        lastName
                    );
                    setProvisionalCertificatePopUp(false)
                }).catch((error) => {
                    setFailurePopUpContent({
                        apiName: "generate-interest-certificate/v1",
                        message: "We are experiencing technical difficulty please try after sometime",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                })
            }
            if (sourceSystem === "BANCS") {
                requestObj = {
                    "header": {
                        "authToken": data?.authToken,
                        "requestType": "prov-int-cert",
                        "productFamily": productFam
                    },
                    "body": {
                        "FiscalYear": "01-APR-22+to+31-MAR-23",
                        "Report": "1",
                        "AcctNum": base64.encode(accountNumber)
                    }
                };
                OverviewServiceObject.InterestCertificateServiceBancs(requestObj, sourceSystem).then((response) => {
                    setLoaderFlag(false)
                }).catch((error) => {
                    setLoaderFlag(false)
                })
            }
        }).catch((error) => {
            dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false
            })
        })
    }

    return (
        <>
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {(emailPopup ?
                <Modal
                    style={{
                        background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""
                            }`,
                    }}
                >
                    <div
                        className="welcome-letter-emal-popup insurance-modal-popup"
                        style={{
                            background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
                        }}
                    >
                        <div className="close-btn" onClick={() => { setemailPopup(false); }}>
                            {/* <img src="assets/images/crossImg.svg" alt="close"  /> */}
                            <img src={"assets/images/crossImg.svg"} alt="close" />
                        </div>
                        <div className="description">
                            <p
                                style={{
                                    color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                }}
                            >
                                {emailPopup ? <div dangerouslySetInnerHTML={{ __html: data }} /> : ""}
                                {/*  */}
                            </p>
                            {/* {`YOu are ${cust}`} */}
                        </div>
                        <div className="proceed">
                            <Button className="email-popup-button" onClick={() => { setemailPopup(false) }}>OK</Button>
                        </div>
                    </div>
                </Modal> :
                null
               /*  <Modal>
                    <div className="IT-certificate-pop-up">
                        <div className="IT-Certificate-head">
                            <p className="IT-certificate-title">Provisional IT Certificate</p>
                            <p className="IT-Certificate-describe">
                                Please select a financial year
                            </p>
                            <img
                                className="it-certificate-cancel-icon"
                                src="assets/images/cancel.svg"
                                alt="cancel-icon"
                                onClick={() => setProvisionalCertificatePopUp(false)}
                            />
                        </div>
                        <div className="IT-Certificate-body">
                            <CustomDropDown
                                optionArray={yearsArr}
                                optionState={yearState}
                                setOptionState={setYearState}
                                selectedOption={selectedYear}
                                setSelectedOption={setSelectedYear}
                                labelFlag={true}
                                labelText=""
                            />
                        </div>
                        <div className="download-button">
                            <Button
                                className="download-button-container"
                                onClick={downloadProvisionalITCertificate}
                            >
                                <p>Download</p>
                                <img
                                    src="assets/images/documentDownloadIcon.svg"
                                    alt="download icon"
                                />
                            </Button>
                            {loaderFlag ? <Loader /> : null}
                        </div>
                    </div>
                </Modal> */)}
        </>
    );
};

ProvisionalCertificate.propTypes = {
    setProvisionalCertificatePopUp: PropTypes.func,
    emailTrigger: PropTypes.func
}

export default ProvisionalCertificate;