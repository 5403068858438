import React, { useEffect, useState } from "react";
import "./CCODProductInfo.scss";
import RadiantBorder from "../../global/RadiantBorder";
import { TabNavItem } from "../TabNavItem/TabNavItem";
import DatePicker from "react-datepicker";
import Button from "../../global/Button";
import TableComponent from "../TableComponent/TableComponent";
import CustomDropDown from "../../global/CustomDropDown";
import FormDatePicker from "../Forms/FormDatePicker";
import AccountDetailsServicObject from "../../services/AccountDetailsService/AccountDetailsService";
// import dataObj from "../../screens/Retail-Account-Details/Data";
import dataObj from "../../../../Shared/screens/Retail-Account-Details/Data";
import loanPopulationDetailsArray from "./ccodLoanDetailsArray";
import { jsPDF } from "jspdf";
import { useRef } from "react";
import autoTable from 'jspdf-autotable';
import { useContext } from "react";
import DataMapping from "../../../../Shared/context/data-mapping";
import ModernDatepicker from '../../components/Forms/ReactDatepicker'
import CcodSoaPdfDownload from "../CcodSoaPdfDownload/CcodSoaPdfDownload";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../../../Shared/context/ThemeContext/store/PageNameContext";
import moment from "moment";
import html2canvas from "html2canvas"
import commonFunctionObject from "../../common-function";
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import { useNavigate } from "react-router-dom";
import OverviewServiceObject from '../../services/Overview';

import BankDetails from "../../container/BankDetails";
import jsHelper from "../../utilities/jsHelper";
import PropTypes from 'prop-types';
// import DatePicker from "../Forms/DatePicker/DatePicker";
const CCODProductInfo = ({ activeContract, redirectionFrom, activeTab, setActiveTab }) => {

  // const onLoadTab = redirectionFrom.toLowerCase() == "document" ? redirectionFrom : "Account Details";

  // let newVar = onLoadTab.toLowerCase() === "document" ? "Documents" : onLoadTab;
  // const [activeTab, setActiveTab] = useState(newVar);
  // useEffect(() => {
  //   setActiveTab(newVar);
  // }, [newVar]);

  activeTab = activeTab.toLowerCase() === "documents" ? "Documents" : "Account Details";

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [optionState, setOptionState] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Account Details");
  const [data, dispatchData] = useContext(DataMapping)
  const [loanPopulationDetails, setloanPopulationDetails] = useState({})
  const [startDateError, setStartDateError] = useState("")
  const [endError, setSEndError] = useState("")
  const [createSoaData, setCreateSoaData] = useState([])
  const [pdfData, setPdfData] = useState({})
  const customerDetailsRef = useRef(null)
  const transactionDetailsRef = useRef(null)
  const [table, setTable] = useState(false)
  const [company, setCompany] = useState("")
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const { pageName, setPageName } = useContext(pageNameContext);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });
  const { analytics } = useContext(AnalyticsContextProvider);

  const soaTableRef = useRef(null);
  const navigate = useNavigate();

  let sr = 0

  const [productInformationArray, setProductInformationArray] = useState([{
    "label": "Product Type",
    "value": ""
  },
  {
    "label": "Total Limit",
    "value": ""
  },
  {
    "label": "Available Limit",
    "value": ""
  },
  {
    "label": "Limit Expiry Date",
    "value": ""
  },
  {
    "label": "Tenure (Months)",
    "value": ""
  },
  {
    "label": "Amount in OD",
    "value": ""
  },
  {
    "label": "Other Adjustments",
    "value": ""
  }])

  const [amountInformationArray, setAmountInformationArray] = useState([
    {
      "label": "Overdue charges (ODC)",
      "value": ""
    },
    {
      "label": "Other Charges",
      "value": ""
    },
    {
      "label": "Processing Fee",
      "value": ""
    },
    {
      "label": "Interest Amount",
      "value": ""
    },
    {
      "label": "Billable Amount",
      "value": ""

    },
    {
      "label": "Utilized Amount",
      "value": ""
    },
    {
      "label": "Principal Outstanding",
      "value": ""
    }
  ])

  useEffect(() => {

    createCcodLoanDetailsObject()
    setPageName("Dropline Overdraft Loan ");
    /*  if (redirectionFrom.toLowerCase() === "document") {
       setActiveTab("Documents");
       navigate('/account-details');
     } */
  }, []);

  const createCcodLoanDetailsObject = () => {
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    AccountDetailsServicObject.createCcodLoanDetailsObjectService(data?.vdSummary[activeContract], data?.trenchReport[activeContract]).then((ccodLoanDetailsObject) => {
      //setloanPopulationDetails(ccodLoanDetailsObject)
      populateLoanArray(ccodLoanDetailsObject)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA,
      });
    }).catch((error) => {
      setFailurePopUpContent({
        apiName: "",
        message:
          "We are experiencing technical difficulty please try after sometime",
        errorCode: error,
      });
      setFailurePopUpModal(true);
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA,
      });
    })
  }

  const populateLoanArray = (ccodLoanDetailsObject) => {
    var newProductInfoArray = [
      {
        "label": "Product Type",
        "value": ccodLoanDetailsObject.PRODUCT
      },
      {
        "label": "Total Limit",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.TOTAL_LIMIT)).toLocaleString("en-IN")
      },
      {
        "label": "Available Limit",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.AVAIL_LIMIT)).toLocaleString("en-IN")
      },
      {
        "label": "Limit Expiry Date",
        "value": ccodLoanDetailsObject.TOTAL_REV_DT
      },
      {
        "label": "Tenure (Months)",
        "value": ccodLoanDetailsObject.tenureMonths
      },
      {
        "label": "Amount in OD",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.TOT_OD_AMT)).toLocaleString("en-IN")
      },
      {
        "label": "Other Adjustments",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.otherAdjustments)).toLocaleString("en-IN")
      }

    ]


    var newAmountInfoArray = [
      {
        "label": "Overdue charges (ODC)",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.overDueCharges)).toLocaleString("en-IN")
      },
      {
        "label": "Other Charges",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.otherCharges)).toLocaleString("en-IN")
      },
      {
        "label": "Processing Fee",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.processingFee)).toLocaleString("en-IN")
      },
      {
        "label": "Interest Amount",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.interestAmount)).toLocaleString("en-IN")
      },
      {
        "label": "Billable Amount",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.AVAIL_LIMIT)).toLocaleString("en-IN")

      },
      {
        "label": "Utilized Amount",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.OUT_BAL)).toLocaleString("en-IN")
      },
      {
        "label": "Principal Outstanding",
        "value": "₹" + parseFloat(Number(ccodLoanDetailsObject.principalOutstanding)).toLocaleString("en-IN")
      }
    ]
    setProductInformationArray(newProductInfoArray)
    setAmountInformationArray(newAmountInfoArray)
  }

  const optionArray = [
    {
      value: "Account Details",
    },
    {
      value: "Documents",
    },
  ];

  const handleSubmit = (event) => {
    console.log(soaTableRef);
    event.preventDefault();
    const userDetail = data?.parsedUserData[activeContract];
    var accountNumber = userDetail.cdiContract.CUSTOMER_NO;
    // console.log();
    let companyName
    (data.parsedUserData[activeContract].cdiContract.COMPANY.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = data?.parsedUserData[activeContract].cdiContract.COMPANY.toUpperCase()
    setCompany(companyName)

    if (startDate !== "" && endDate !== "" && endError === "") {
      if (new Date(commonFunctionObject.replaceHyphenDate(startDate)).getTime() > new Date(commonFunctionObject.replaceHyphenDate(endDate)).getTime()) {
        setSEndError("Please Enter Valid Date")
      } else {

        let ProgramId = data.parsedUserData[activeContract].sapContract.PROGRAM_NAME
        let fromDate = new Date(commonFunctionObject.replaceHyphenDate(startDate))
        let toDate = new Date(commonFunctionObject.replaceHyphenDate(endDate))
        let fromDateFinal = fromDate.getFullYear() + "-" + (fromDate.getUTCMonth() + 1).toString().padStart(2, '0') + "-" + fromDate.getDate().toString().padStart(2, '0')
        let endDateFinal = toDate.getFullYear() + "-" + (toDate.getUTCMonth() + 1).toString().padStart(2, '0') + "-" + toDate.getDate().toString().padStart(2, '0');

        var currentUserData = data?.parsedUserData[accountNumber]
        var gcid = currentUserData.cdiContract.GCID;

        let currentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
        let productName = "CCOD"
        var company = currentContract.cdiContract.COMPANY;
        companyName = "";
        (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();

        let requestObject = {
          "header": {
            "authToken": data.authToken,
            "company": companyName
          },
          "body": {
            "vendor": activeContract,
            "dateFrom": fromDateFinal,
            "dateTo": endDateFinal
          }
        }
        AccountDetailsServicObject.createSoaService(requestObject).then((value) => {
          // getPdf(startDate, endDate, companyName)
          setTable(true)
          setCreateSoaData(value)
          dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA,
          });
          AccountDetailsServicObject.soaReportApi(new Date(commonFunctionObject.replaceHyphenDate(startDate)), new Date(commonFunctionObject.replaceHyphenDate(endDate)), activeContract, companyName, ProgramId, data.authToken).then((value) => {

            setPdfData(value)
            console.log(value);

            setTimeout(() => {
              dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA,
              });
              pdfGenerator()
              console.log(soaTableRef);
            });
            let analytics_data = {
              user: {
                loginMethod: "",
                loginStatus: "",
                gcid: gcid,
                loginType: "",
                loanaccountNo: accountNumber,
                firstloginDate: ""
              },
              products: {
                productCode: productName,
              },
              data: {
                ctaText: "",
                componentName: "Account Details",
                bannerTitle: "",
                menuTitle: "",
                faqTitle: "",
                searchTerm: "",
                lobActivity: companyName + ":" + productName,
                status: "SUCCESS",
                eventName: "accountstatementDownload"
              }
            }
            analytics.trackData("accountstatementDownload", analytics_data);

            var dataObj = {
              "documentName": "statementOfAccount",
              "productFamily": companyName,
              "description": "Generate statement of account for " + accountNumber + " loan account.",
              "sebServiceRequestType": "statementOfAccount",
              "errorCode": ""
            }
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA,
            });
            //Call SR API for Both TCHFL and CFAB 
            callSrApi(dataObj, accountNumber, activeContract, companyName, ProgramId, data.authToken);


          }).catch((error) => {
            let analytics_data = {
              user: {
                loginMethod: "",
                loginStatus: "",
                gcid: gcid,
                loginType: "",
                loanaccountNo: accountNumber,
                firstloginDate: ""
              },
              products: {
                productCode: productName,
              },
              data: {
                ctaText: "",
                componentName: "Account Details",
                bannerTitle: "",
                menuTitle: "",
                faqTitle: "",
                searchTerm: "",
                lobActivity: companyName + ":" + productName,
                status: "FAILURE",
                eventName: "accountstatementDownload"
              }
            }
            analytics.trackData("accountstatementDownload", analytics_data);
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA,
            });
            setFailurePopUpContent({
              apiName: "",
              message:
                "We are experiencing technical difficulty please try after sometime",
              errorCode: error,
            });
            setFailurePopUpModal(true)
          })
        }).catch((error) => {
          if (typeof error === "object") {
            setFailurePopUpContent({
              apiName: "",
              message: error.responseMessage,
              errorCode: error.error,
            });
            setFailurePopUpModal(true)
          } else if (error === "C-10") {
            setFailurePopUpContent({
              apiName: "",
              message: "There is no record for the selected date range.",
              errorCode: error,
            });
            setFailurePopUpModal(true)
          } else if ("C-11") {
            setFailurePopUpContent({
              apiName: "",
              message: "No Data Found",
              errorCode: error,
            });
            setFailurePopUpModal(true)
          } else {
            setFailurePopUpContent({
              apiName: "",
              message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
              errorCode: error,
            });
            setFailurePopUpModal(true)
          }
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });

        })
      }


    } else if (startDate === "" && endDate === "") {
      setSEndError("Please Select")
      setStartDateError("Please Select")
    } else if (startDate === "") {
      setStartDateError("Please Select")
    } else if (endDate === "") {
      setSEndError("Please Select")
    }

  }

  const callSrApi = (dataObj, accountNumber, activeContract, companyName, ProgramId, authToken) => {
    var currentUserData = data?.parsedUserData[accountNumber];

    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA
    })

    if (jsHelper.isDef(currentUserData.hasOwnProperty("WEBTOP_NO"))) {

      var queryReqObject = {
        "header": {
          "authToken": data?.authToken,
          "serviceRequestType": dataObj.sebServiceRequestType,
          "productFamily": dataObj.productFamily,
          "systemId": 3,
        },
        "body": {
          "accountNumber": accountNumber,
          "webtopId": currentUserData.WEBTOP_NO,
          "description": dataObj.description
        }
      }
    } else {
      var queryReqObject = {
        "header": {
          "authToken": data.authToken,
          "serviceRequestType": dataObj.sebServiceRequestType,
          "productFamily": dataObj.productFamily,
          "systemId": 3,
        },
        "body": {
          "accountNumber": accountNumber,
          "description": dataObj.decscription
        }
      }
    }
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });

    OverviewServiceObject.querySRCall(queryReqObject, dataObj.errorCode)
      .then((queryReqObject) => {

        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
      }).catch((error) => {
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
        setFailurePopUpContent({
          apiName: "holding-statement/v1",
          message:
            "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
          errorCode: error,
        });
        setFailurePopUpModal(true);

      })
  }

  const resetForm = () => {
    setStartDate("");
    setEndDate("")
    setSEndError("")
    setStartDateError("")
  }


  const handleStartDate = (date) => {

    if (date === null || date === "") {
      setStartDateError("Please Select")
    } else {
      setStartDateError("")
    }
  }
  const handleEndDate = (date) => {

    if (date === null || date === "") {
      setSEndError("Please Select")
    } else if (new Date(commonFunctionObject.replaceHyphenDate(startDate)).getTime() > new Date(commonFunctionObject.replaceHyphenDate(date)).getTime()) {
      setSEndError("Please Enter Valid Date")
    } else {
      setSEndError("")
    }
  }

  const pdfGenerator = () => {
    window.html2canvas = html2canvas
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4',
    });

    // doc.autoTable({ html: '#table-1' })
    // doc.autoTable({ html: '#table-2' })

    doc.html(document.getElementById('pdfElement'), {
      margin: [70, 20, 50, 20],
      callback: function (doc) {
        doc.save("Statement Of Account");
      },
      x: 1,
      y: 1,
      autoPaging: 'text',
    });
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  return (
    <>
      <div className="loan-features ccod-nav">
        <ul className="loan-features-ul">
          <li>
            <TabNavItem
              title="Account Details"
              id="Account Details"
              activeTab={activeTab}
              setActiveTab={(id) => { setActiveTab(id); }}
            />
          </li>
          <li>
            <TabNavItem
              title="Documents"
              id="Documents"
              activeTab={activeTab}
              setActiveTab={(id) => { setActiveTab(id); }}
            />
          </li>
        </ul>
      </div>
      <div className="mobile-custom-drop-down">
        <CustomDropDown
          optionArray={optionArray}
          optionState={optionState}
          setOptionState={setOptionState}
          selectedOption={activeTab}
          setSelectedOption={setActiveTab}
          labelFlag={false}
          labelText="Account Number"
          className={"ccod-drop-down"}
          labelClass={"account-details-container-label"}
        />
      </div>
      {activeTab === "Account Details" &&
        <div className="ccod-loan-details">
          <div className="product-information-details">
            <h2>Product Information</h2>
            <div className="ccod-account-information-container radiant-border-container">
              <div className="ccod-account-information-container-inner">
                <ul className="loan-detail-info-ul ccod-li">
                  {
                    productInformationArray.map((data, index) => {
                      return (
                        <li key={index}>
                          <div className="label">{data.label} </div>
                          <div className="label-amount">{data.value}</div>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="amount-information-details">
            <h2>Amount Information</h2>
            <div className="ccod-account-information-container radiant-border-container">
              <div className="ccod-account-information-container-inner">
                <ul className="loan-detail-info-ul ccod-li">
                  {
                    amountInformationArray.map((data, index) => {
                      return (
                        <li key={index}>
                          <div className="label">{data.label} </div>
                          <div className="label-amount">{data.value}</div>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        // <RadiantBorder className={"ccod-prod-radiant-border"}>
        //   <div className="ccod-prod-main-container">
        //     <div className="ccod-prod-divs">
        //       <p className="ccod-divs-heading">Product Information</p>
        //       <div>
        //         <ul className="loan-detail-info-ul ccod-li">
        //           {
        //             productInformationArray.map((data) => {
        //               return (
        //                 <li>
        //                   <div className="label">{data.label} </div>
        //                   <div className="label-amount">{data.value}</div>
        //                 </li>
        //               )
        //             })
        //           }

        //         </ul>
        //       </div>
        //     </div>
        //     <div className="ccod-prod-divs">
        //       <p className="ccod-divs-heading">Amount Information</p>
        //       <div>
        //         <ul className="loan-detail-info-ul ccod-li">
        //           {
        //             amountInformationArray.map((data) => {
        //               return (
        //                 <li>
        //                   <div className="label">{data.label} </div>
        //                   <div className="label-amount">{data.value}</div>
        //                 </li>
        //               )
        //             })
        //           }

        //         </ul>
        //       </div>
        //     </div>
        //   </div>
        // </RadiantBorder>
      }
      {activeTab === "Documents" && <div className="document-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="buttons-main-container">
            <p className="document-component-header">STATEMENT</p>
            <div className="document-date-input-container">
              <div className="document-date-input">

                <ModernDatepicker disabledDate={disabledDate} placeholder='From Date' onChange={(date) => {
                  setStartDate(date);
                  let formatedDate = new Date(commonFunctionObject.replaceHyphenDate(date))
                  handleStartDate(formatedDate)
                }} value={startDate} />
                <p className="date-input-error">{startDateError}</p>
              </div>

              <div className="document-date-input">
                <ModernDatepicker disabledDate={disabledDate} placeholder='To Date' onChange={(date) => {
                  setEndDate(date);
                  let formatedDate = new Date(commonFunctionObject.replaceHyphenDate(date))
                  handleEndDate(formatedDate)
                }} value={endDate} />
                <p className="date-input-error">{endError}</p>
              </div>
            </div>
            <div className="document-button-container">
              <div className="document-button">
                <Button type="submit" className={"document-button-style"}>
                  Submit
                  <img src="assets/images/arrow-right.svg" alt="arrow-right" />
                </Button>
              </div>
              <div className="document-button">
                <Button onClick={resetForm} className={"document-button-style proceed"}>
                  <span>Reset</span>
                  <img src="assets/images/resetIcon.svg" alt="resetIcon" />
                </Button>
              </div>
            </div>
          </div>
        </form>
        {
          table ?
            <RadiantBorder className={"document-table-container"}>
              <div className="document-padding-adjustment">
                <div className="document-table-overflow-container">

                  <table className="soa-table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Request Date</th>
                        <th>Transaction Date</th>
                        <th>Transaction ID</th>
                        <th>Amount</th>
                        <th>Narration</th>
                        <th>Transaction Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        createSoaData && createSoaData.map((value, index) => {

                          sr++
                          return (
                            <tr key={index}>
                              <td>{sr}</td>
                              <td>{commonFunctionObject.replaceHyphenDate(value.PSTNG_DATE)}</td>
                              <td>{commonFunctionObject.replaceHyphenDate(value.DOC_DATE)}</td>
                              <td>{value.REF_DOC}</td>
                              <td>{value.AMOUNT}</td>
                              <td>{value.ITEM_TEXT}</td>
                              <td>{value.DB_CR_IND}</td>
                            </tr>
                          )

                        })
                      }


                    </tbody>
                  </table>

                </div>
              </div>
            </RadiantBorder>
            : null
        }
      </div>
      }

      {/* {
        activeTab === "bank-details" && <BankDetails activeContract={activeContract} />
       } */}
      <div style={{ display: "none" }}>
        {
          Object.keys(pdfData).length > 0 ? <div>
            <CcodSoaPdfDownload startDate={startDate} endDate={endDate} company={company} pdfData={pdfData} soaTableRef={soaTableRef} />
          </div> : null
        }

      </div>
      {failurePopUpModal ? (
        <FailurePopUp
          onClick={() => setFailurePopUpModal(false)}
          visible={failurePopUpModal}
          setFailureModalStat={setFailurePopUpModal}
          failurePopUpContent={failurePopUpContent}
        />
      ) : null}
    </>
  );
};

CCODProductInfo.propTypes = {
  activeContract: PropTypes.string,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
}


export default CCODProductInfo;
