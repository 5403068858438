import React, { useContext } from 'react'
import RadiantBorder from '../../global/RadiantBorder'
import './SplitChargesData.scss'
import Modal from "../../global/Modal";
import { useState } from 'react';
import AccountDetailsServicObject from '../../services/AccountDetailsService/AccountDetailsService';
import TableComponent from '../../components/TableComponent/TableComponent';
// import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import ThemeContext from '../../../../Shared/context/ThemeContext/store/ThemeContext';
import AnalyticsContextProvider from '../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider';
import DataMapping from '../../../../Shared/context/data-mapping';
import commonFunctionObject from '../../common-function';
import PropTypes from 'prop-types';

export const SplitChargesData = ({ splitChargesData, activeContract }) => {
  // const [splitModal, setSplitModal] = useState(true);
  const [openModal, setOpenModal] = useState(false)
  const [popUptype, setpopUptype] = useState("")
  const [themeState] = useContext(ThemeContext);
  const { analytics } = useContext(AnalyticsContextProvider);
  const [data, setData] = useContext(DataMapping);

  const splitChargeClickAnalytics = (eventName) => {
    var accountNumber = data?.currentActiveData.accountNumber
    var currentUserData = data?.parsedUserData[accountNumber]
    let productName = commonFunctionObject.getProductName(currentUserData);
    var gcid = currentUserData.GCID;
    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: gcid,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "",
        componentName: "SplitChargesData",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "",
        eventName: eventName
      }
    }
    analytics.trackData("ctaInteraction", analytics_data);
  }
  const [popuplabel, setpopuplabel] = useState(false)
  return (
    <div className='split-charges'>

      <RadiantBorder className="split-charges-radient-border">
        <div className='split-charges-data-container'>
          <div className='total-section'>
            <div className='total-charges'>
              <p>Total Amount</p>
              <h2>₹ {splitChargesData[activeContract] && parseFloat(AccountDetailsServicObject.getTotalPainAmount(activeContract, splitChargesData) + AccountDetailsServicObject.getTotalOutStandingAmount(activeContract, splitChargesData)).toLocaleString('en-IN')}</h2>
            </div>
            <div className='total-charges'>
              <p>Total Paid Amount</p>
              <h2>₹ {splitChargesData[activeContract] && parseFloat(AccountDetailsServicObject.getTotalPainAmount(activeContract, splitChargesData)).toLocaleString('en-IN')}</h2>
            </div>
            <div className='total-charges'>
              <p>Total Unpaid Amount</p>
              <h2>₹ {splitChargesData[activeContract] && parseFloat(AccountDetailsServicObject.getTotalOutStandingAmount(activeContract, splitChargesData)).toLocaleString('en-IN')}</h2>
            </div>
          </div>
          <div className='charges-section'>
            {splitChargesData[activeContract] && splitChargesData[activeContract]["Instrument Return Charges"].data.length > 0 ?
              <div onClick={(e) => { setOpenModal(true); setpopUptype("Instrument Return Charges"); splitChargeClickAnalytics("Bounce Charges") }} className='charge'>
                <p>Bounce Charges</p>
                <p>Paid Amount: ₹ {parseFloat(splitChargesData[activeContract]["Instrument Return Charges"].totalPaid).toLocaleString('en-IN')}</p>
                <p>Unpaid Amount: ₹ {parseFloat(splitChargesData[activeContract]["Instrument Return Charges"].outStanding).toLocaleString('en-IN')}</p>
                <img src="assets/images/arrow-right-black.svg" alt="arrow" />
              </div>
              : null}
            {
              splitChargesData[activeContract] && splitChargesData[activeContract]["LPP Charges"].data.length > 0 ?
                <div onClick={(e) => { setOpenModal(true); setpopUptype("LPP Charges"); splitChargeClickAnalytics("LPP Charges") }} className='charge'>
                  <p>Late Payment Charges</p>
                  <p>Paid Amount: ₹ {parseFloat(splitChargesData[activeContract]["LPP Charges"].totalPaid).toLocaleString('en-IN')}</p>
                  <p>Unpaid Amount: ₹ {parseFloat(splitChargesData[activeContract]["LPP Charges"].outStanding).toLocaleString('en-IN')}</p>
                  <img src="assets/images/arrow-right-black.svg" alt="arrow" />
                </div>
                : null}
            {splitChargesData[activeContract] && splitChargesData[activeContract]["Other Charges"].data.length > 0 ?
              <div onClick={(e) => { setOpenModal(true); setpopUptype("Other Charges"); splitChargeClickAnalytics("Other Charges") }} className='charge'>
                <p>Other Charges</p>
                <p>Paid Amount: ₹ {parseFloat(splitChargesData[activeContract]["Other Charges"].totalPaid).toLocaleString('en-IN')}</p>
                <p>Unpaid Amount: ₹ {parseFloat(splitChargesData[activeContract]["Other Charges"].outStanding).toLocaleString('en-IN')}</p>
                <img src="assets/images/arrow-right-black.svg" alt="arrow" />
              </div> : null}
          </div>
        </div>
      </RadiantBorder>
      {
        openModal ? <Modal>
          <div className={`split-charges-modal-container ${themeState}`}>
            <div className='split-charges-modal-header'>
              <h2>{popUptype}</h2>
              <img onClick={(e) => setOpenModal(false)} src="assets/images/crossImg.svg" alt="arrow" />
            </div>
            <div className='split-charges-modal-body'>
              <div className='total-section'>
                <div className='total-charges'>
                  <p>Paid Amount</p>
                  <h2>₹ {parseFloat(splitChargesData[activeContract] && splitChargesData[activeContract][popUptype].totalPaid).toLocaleString('en-IN')}</h2>
                </div>
                <div className='total-charges'>
                  <p>Unpaid Charges</p>
                  <h2>₹ {parseFloat(splitChargesData[activeContract] && splitChargesData[activeContract][popUptype].outStanding).toLocaleString('en-IN')}</h2>
                </div>
              </div>
              <div className='charges-section'>
                <div className='tableScroll'>
                  {/* <TableComponent tabelHeading={["Transaction Date","Description", "Charge Amount", "Paid Amount", "Unpaid Charges"]} tableData={splitChargesData[activeContract][popUptype].data}></TableComponent> */}
                  <table className='split-charges-title'>
                    <thead>
                      <tr>
                        <th>Transaction Date</th>
                        <th>Description</th>
                        <th>Charge Amount</th>
                        <th>Paid Amount</th>
                        <th>Unpaid Charges</th>
                      </tr>
                    </thead>
                    <tbody className='split-charges-data'>
                      {
                        splitChargesData[activeContract] && splitChargesData[activeContract][popUptype].data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td> {value.adviceDate}</td>
                              <td>{value.chargeDescription}</td>
                              <td>₹ {parseFloat(value.adviceAmt).toLocaleString('en-IN')}</td>
                              <td>₹ {parseFloat(value.allocatedAmt).toLocaleString('en-IN')}</td>
                              <td>₹ {parseFloat(value.outstanding).toLocaleString('en-IN')}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal> : null
      }
    </div>

  )
}

SplitChargesData.propTypes = {
  splitChargesData: PropTypes.object,
  activeContract: PropTypes.string
}
