import React, { useContext, useEffect, useRef, useState } from "react";
import "./congratsmodal.scss";
import Button from "../../FormFields/Button/Button";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import DataMapping from "../../../../Shared/context/data-mapping";
import LoginServiceObject from "../../../../Retail/src/services/Login";
import { pgsApiCallFn } from "../../services/pgsApiCall";
import commonFunctionObject from "../../../../Retail/src/common-function";
import TCLCollectionLetterDownload from "../../components/TCLCollectionLetterDownload";
import TCHFLCollectionLetterDownload from "../../components/TCHFLCollectionLetterDownload";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const CongratsModal = ({
  setPreapprovedOfferFlag,
  setPopOverFlag,
  setPayNowFlag,
  setIsChecked,
  setAttemptFlag,
  setOfferAccept,
  successFailureFlag,
  dashPaymentData,
  setFailurePopUpContent,
  setFailurePopUpModal,
  paymentForm,
  journeyType,
  isTrancheOpted,
  noOfTranchesOpted,
  paymentModalDetails,
  trancheData,
  setPaymentSummeryFlag,
  handleClickForPaymentOpt,
  setFinalPayOptClickObj,
}) => {
  const [contextData, dispatchData] = useContext(DataMapping);
  const [congFlag, setCongFlag] = useState(successFailureFlag);
  const [settlementLetterData, setSettlementLetterData] = useState({
    financial_year: "",
    header_date: "",
    letter_date: "",
    refdate: "",
  });
  const [pdfData, setPdfData] = useState({});
  const settlementTableRef = useRef("");

  function generateSixDigitRandomNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  const reformatDateString = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const suffix = getDaySuffix(day);
    const formattedDate = date.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    return `${formattedDate.replace(/\d{1,2}/, (match) => match + suffix)}`;
  };

  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  useEffect(() => {
    // dispatchData({
    //   name: "loaderFlag",
    //   payload: true,
    //   type: STOREDATA,
    // });
    // console.log(contextData,"context");
    let currentOfferData = contextData?.offers?.body?.offerDetails.filter(
      (elem) =>
        elem["LOAN_ACCOUNT_NO"] === contextData.currentActiveData.accountNumber
    );
    // console.log(currentOfferData,"currentOfferDataa");

    function formatZeroDecimalNumber(numberStr) {
      // Parse the string as a float
      let result = "";
      if(numberStr == ""){
        result = "";
        return result;
      }else{
        let num = parseFloat(numberStr);
  
        // Check if the number is an integer (i.e., no decimal part)
        if (num % 1 === 0) {
          return num; // Return the number without decimals
        } else {
          return num.toString(); // Return the number as a string with the decimal part
        }
      }

    }

    let offerApprovedData = {
      offerApprovalStatus: congFlag ? "Approved" : "",
      offerApprovedSettlementAmount: congFlag
        ? contextData?.approvedLoanAmounts?.approvedLoanData[
            contextData?.currentActiveData?.accountNumber
          ]?.finalApprovedAmountValue
        : "",
      fcValue: currentOfferData[0].FC,
      fcPosValue: currentOfferData[0].FC_POS,
      fcInterestValue: currentOfferData[0].FC_INT,
      fcChargesValue: currentOfferData[0].FC_CHARGES,
      isSettlementLetterDownloaded: congFlag ? "N" : "",
      tranche1Amount: congFlag
        ? formatZeroDecimalNumber(contextData?.eventTrancheDetails?.eventTranche1Amount)
        : "",
      tranche1DueDate: congFlag
        ? contextData?.eventTrancheDetails?.eventTranche1Date
        : "",
      tranche2Amount: congFlag
        ? formatZeroDecimalNumber(contextData?.eventTrancheDetails?.eventTranche2Amount)
        : "",
      tranche2DueDate: congFlag
        ? contextData?.eventTrancheDetails?.eventTranche2Date
        : "",
    };

    handleClickForPaymentOpt(
      "handleOfferApprovedStatus",
      "",
      offerApprovedData,
      "",
      contextData.currentActiveData.accountNumber
    );

    let getServerTimeRequest = {
      header: {
        authToken: contextData.authToken,
      },
      body: {},
    };

    if (congFlag) {
      const currentLoanAttempts = contextData?.attempt?.loanAttempts || {};

      if (currentLoanAttempts[contextData.currentActiveData.accountNumber]) {
        // console.log("Loan number already present");
        // console.log(currentLoanAttempts[contextData.currentActiveData.accountNumber],"attempts");

        const currentLoanApprovedAmount =
          contextData?.approvedLoanAmounts?.approvedLoanData || {};

        const accountNumber = contextData.currentActiveData.accountNumber;

        let finalOfferApprovedAmount =
          currentLoanApprovedAmount[accountNumber]?.finalApprovedAmountValue;

        if (
          currentLoanAttempts[contextData.currentActiveData.accountNumber]
            .attempts == 3
        ) {
          let finalApprovedAmountDetails = {
            finalOfferApprovedAmount: finalOfferApprovedAmount,
            attempt: 3,
          };
          handleClickForPaymentOpt(
            "setFinalApprovedAmount",
            "",
            finalApprovedAmountDetails,
            "",
            contextData.currentActiveData.accountNumber
          );
        } else if (
          currentLoanAttempts[contextData.currentActiveData.accountNumber]
            .attempts == 2
        ) {
          let finalApprovedAmountDetails = {
            finalOfferApprovedAmount: finalOfferApprovedAmount,
            attempt: 2,
          };
          handleClickForPaymentOpt(
            "setFinalApprovedAmount",
            "",
            finalApprovedAmountDetails,
            "",
            contextData.currentActiveData.accountNumber
          );
        } else if (
          currentLoanAttempts[contextData.currentActiveData.accountNumber]
            .attempts == 1
        ) {
          let finalApprovedAmountDetails = {
            finalOfferApprovedAmount: finalOfferApprovedAmount,
            attempt: 1,
          };
          handleClickForPaymentOpt(
            "setFinalApprovedAmount",
            "",
            finalApprovedAmountDetails,
            "",
            contextData.currentActiveData.accountNumber
          );
        }
      }
    }

    commonFunctionObject
      .getServerTimeService(getServerTimeRequest)
      .then((currentServerDateTime) => {
        var serverTime = new Date(
          commonFunctionObject.replaceHyphenDate(currentServerDateTime)
        );
        // console.log(serverTime, "serverTime");

        // var date = new Date("Wed Feb 28 2024 19:17:35 GMT+0530 (India Standard Time)");
        var date = new Date(serverTime);

        // Check if the date is before March 31
        let financialYearStart = new Date(date.getFullYear(), 2, 31);
        let financialYearEnd = new Date(date.getFullYear() + 1, 2, 31);

        if (date < financialYearStart) {
          financialYearStart = new Date(date.getFullYear() - 1, 2, 31);
          financialYearEnd = new Date(date.getFullYear(), 2, 31);
        }

        let financial_year = `${financialYearStart
          .getFullYear()
          .toString()
          .slice(-2)}-${financialYearEnd.getFullYear().toString().slice(-2)}`;

        // Header Date
        const header_date = reformatDateString(date);

        // Letter Date
        let letter_date = new Intl.DateTimeFormat("en", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(date);

        let parts = letter_date.split("/");
        let corrected_date = parts[1] + "/" + parts[0] + "/" + parts[2];

        // Reference Date
        let refdate = `${date.getFullYear().toString().slice(-2)}${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}${date
          .getDate()
          .toString()
          .padStart(2, "0")}${generateSixDigitRandomNumber()}`;

        // console.log(refdate, "refDate");

        setSettlementLetterData({
          financial_year: financial_year,
          header_date: header_date,
          letter_date: corrected_date,
          refdate: refdate,
        });

        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
      });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });

  const okClick = (e) => {
    setPopOverFlag(false);
    setIsChecked(false);
    setPayNowFlag(false);
    // if (contextData.attempt === 0) {
    //   setAttemptFlag(true);
    //   setPayNowFlag(true);
    //   setPreapprovedOfferFlag(true);
    // }
    if (
      contextData?.attempt?.loanAttempts[
        contextData.currentActiveData.accountNumber
      ].attempts == 0
    ) {
      setAttemptFlag(true);
      setPayNowFlag(true);
      setPreapprovedOfferFlag(true);
    } else {
      setAttemptFlag(false);
      setPayNowFlag(false);
      setPreapprovedOfferFlag(true);
    }

    // dispatchData({
    //   name: "offerModaLFlag",
    //   payload: {
    //     modalFlag: false,
    //     accountType: "congratsModal",
    //   },
    //   type: STOREDATA,
    // });

    dispatchData({
      name: "offerModaLFlag",
      payload: {
        modalFlag: false,
        accountType: "availOfferModal",
      },
      type: STOREDATA,
    });
  };

  const pdfGenerator = () => {
    window.html2canvas = html2canvas;
    const doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
    });

    // doc.autoTable({ html: '#table-1' })
    // doc.autoTable({ html: '#table-2' })

    const activeOfferDetails = contextData?.offers?.body?.offerDetails.find(
      (ele) =>
        ele?.LOAN_ACCOUNT_NO === contextData?.currentActiveData?.accountNumber
    );
    // console.log(activeOfferDetails, "activeOfferData");

    if (activeOfferDetails?.ENTITY === "HFL") {
      doc.html(document.getElementById("tchflCollectionPdfElement"), {
        margin: [70, 20, 50, 20],
        callback: function (doc) {
          doc.save("TCHFL_Settlement_Letter");
        },
        x: 1,
        y: 1,
        autoPaging: "text",
      });
    } else if (activeOfferDetails?.ENTITY === "TCL") {
      doc.html(document.getElementById("tclCollectionPdfElement"), {
        margin: [70, 20, 50, 20],
        callback: function (doc) {
          doc.save("TCL_Settlement_Letter");
        },
        x: 1,
        y: 1,
        autoPaging: "text",
      });
    }
  };

  const handleDownloadLetter = () => {
    handleClickForPaymentOpt(
      "handleIsSettlementClick",
      "",
      "Y",
      "",
      contextData?.currentActiveData?.accountNumber
    );
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    // console.log("hello");
    const userDetail =
      contextData?.parsedUserData[
        contextData?.currentActiveData?.accountNumber
      ];
    let loan_accountnumber = contextData?.currentActiveData?.accountNumber;
    let customer_name = `${userDetail["FIRSTNAME"]} ${userDetail["LASTNAME"]}`;
    let companyName = "";
    userDetail?.productInfo?.PRODUCT_FAMILY.toUpperCase() === "CFAB"
      ? (companyName = "TCL")
      : (companyName = "TCHFL");

    const activeOfferData = contextData?.offers?.body?.offerDetails.find(
      (ele) =>
        ele?.LOAN_ACCOUNT_NO === contextData?.currentActiveData?.accountNumber
    );

    // console.log(activeOfferData,"activeOfferData");

    let approvedAmountData =
      contextData?.approvedLoanAmounts?.approvedLoanData[
        contextData.currentActiveData.accountNumber
      ]?.finalApprovedAmountValue;

    let amount = parseFloat(approvedAmountData);
    let approvedAmount = amount.toLocaleString("en-IN");
    // console.log(approvedAmount,"approvedAmount");

    let pdfDetails = {
      customer_name: customer_name,
      companyName: companyName,
      loan_accountnumber: loan_accountnumber,
      settlementLetterData: settlementLetterData,
      isTrancheOpted: isTrancheOpted,
      noOfTranchesOpted: noOfTranchesOpted,
      trancheData: trancheData,
      product: activeOfferData?.["PRODUCT"],
      address: `${userDetail?.["ADDRESS_LINE1"]}  ${userDetail?.["ADDRESS_LINE2"]} ${userDetail?.["ADDRESS_LINE3"]}`,
      state: userDetail?.["STATE"],
      pincode: userDetail?.["PINCODE"],
      approvedAmount: approvedAmount,
    };
    // console.log(pdfDetails,"pdfDetails");

    setPdfData(pdfDetails);
    setTimeout(() => {
      pdfGenerator();
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA,
      });
    });
  };

  const handleCross = () => {
    const fetchData = async () => {
      const retailContractNumbers = [];
      // Extracting retail contract numbers from user data
      contextData.getUserDataV3?.body?.contracts.forEach((item) => {
        if (item?.system === "retail") {
          retailContractNumbers.push(item.CONTRACT_NUMBER);
        }
      });

      try {
        const offerDataResponse =
          await LoginServiceObject.offerCollectionDetails(
            contextData.authToken,
            retailContractNumbers
          );
        // console.log(offerDataResponse, "offerDataResponse");
        // return offerDataResponse;
        dispatchData({
          type: STOREDATA,
          name: "offers",
          payload: offerDataResponse,
        });
        setPopOverFlag(false);
        setIsChecked(false);
        setPayNowFlag(false);
        setAttemptFlag(true);
        setPaymentSummeryFlag(false);
      } catch (error) {
        console.error("Error fetching offer data:", error);
      }
    };

    fetchData();

    if (congFlag) {
      sessionStorage.setItem("settlement-mid-journey", "true");
    }

    let approvedAmountData =
      contextData?.approvedLoanAmounts?.approvedLoanData[
        contextData.currentActiveData.accountNumber
      ]?.finalApprovedAmountValue;

    handleClickForPaymentOpt(
      "handleCongratulationCrossEvent",
      "",
      approvedAmountData,
      "",
      contextData.currentActiveData.accountNumber
    );

    dispatchData({
      name: "offerModaLFlag",
      payload: {
        modalFlag: false,
        accountType: "congratsModal",
      },
      type: STOREDATA,
    });
  };

  const handleOption = () => {
    setPopOverFlag(false);
    setIsChecked(false);
    setPayNowFlag(false);

    dispatchData({
      name: "latestPaymentData",
      payload: {
        phase: 2,
        labelName: "",
        amount: "",
      },
    });

    dispatchData({
      name: "offerModaLFlag",
      payload: {
        modalFlag: false,
        accountType: "congratsModal",
      },
      type: STOREDATA,
    });
    dispatchData({
      name: "loaderFlag",
      type: STOREDATA,
      payload: true,
    });

    let totalOverdueAmountPgs =
      dashPaymentData?.loanDetails[3]?.value +
      dashPaymentData?.loanDetails[4]?.value;
    // console.log(totalOverdueAmountPgs,"totalOverdueAmountPgs");

    // if(paymentForm){
    const userobj = {
      mobile: paymentForm?.value?.mobile,
      email: paymentForm?.value?.email,
    };
    // }
    let approvedAmount =
      contextData?.approvedLoanAmounts?.approvedLoanData[
        contextData.currentActiveData.accountNumber
      ]?.approvedAmountValue;

    dispatchData({
      name: "pgsPaymentDetails",
      type: STOREDATA,
      payload: {
        [contextData.currentActiveData.accountNumber]: {
          userobj,
          approvedAmount,
          journeyType,
          isTrancheOpted,
          noOfTranchesOpted,
          paymentModalDetails: {
            offerType: paymentModalDetails.offerType,
            trancheNumber: paymentModalDetails.trancheNumber,
          },
        },
      },
    });

    dispatchData({
      name: "paymentRedirectionAccountNumber",
      payload: contextData.currentActiveData.accountNumber,
      type: STOREDATA,
    });

    pgsApiCallFn(
      contextData,
      userobj,
      dispatchData,
      contextData?.approvedLoanAmounts?.approvedLoanData[
        contextData.currentActiveData.accountNumber
      ]?.approvedAmountValue,
      journeyType,
      isTrancheOpted,
      noOfTranchesOpted,
      paymentModalDetails.offerType,
      paymentModalDetails.trancheNumber,
      dashPaymentData?.loanDetails[3]?.value,
      dashPaymentData?.loanDetails[4]?.value,
      totalOverdueAmountPgs
    )
      .then((uniqueTransactionId) => {
        // console.log(uniqueTransactionId, "uniqueTransactionId");
        handleClickForPaymentOpt(
          "handleCongratulationTransactionId",
          "",
          uniqueTransactionId,
          "",
          contextData.currentActiveData.accountNumber
        );

        dispatchData({
          name: "uniqueTransactionId",
          type: STOREDATA,
          payload: uniqueTransactionId,
        });
        // setPaymentOptClickCount(() => ({}));
        // setFinalPayOptClickObj(uniqueTransactionId, "paymentOptionClick");
        setFinalPayOptClickObj(
          uniqueTransactionId,
          "settlementOfferCongragulationPopupClick"
        );

        dispatchData({
          name: "activePaymentLoanData",
          payload: {
            loanAccNumber: contextData.currentActiveData.accountNumber,
            activePaymentFlag: true,
            isTrancheOpted: isTrancheOpted,
          },
          type: STOREDATA,
        });
        dispatchData({
          name: "offerModaLFlag",
          payload: {
            modalFlag: false,
            accountType: "availOfferModal",
          },
          type: STOREDATA,
        });
        dispatchData({
          name: "loaderFlag",
          type: STOREDATA,
          payload: false,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatchData({
          name: "loaderFlag",
          type: STOREDATA,
          payload: false,
        });

        setFailurePopUpModal(true);
        setFailurePopUpContent({
          apiName: "",
          message: `We Are Experiencing Technical Difficulty. Please Try After Sometime.`,
        });
      });
  };

  return (
    <div className="c_congrats_modal_wrapper">
      <div className="c_congrats_modal">
        <img
          className="c_congrats_modal_close"
          src="assets/images/gray-cross.svg"
          alt="gray-cross"
          onClick={() => {
            congFlag ? handleCross() : okClick();
          }}
        />
        {congFlag ? (
          <img src="assets/images/mood.svg" width="61px" height="61px" />
        ) : (
          <img src="assets/images/mood_bad.svg" width="61px" height="61px" />
        )}
        <div
          className={`${
            congFlag
              ? ""
              : contextData?.attempt?.loanAttempts[
                  contextData.currentActiveData.accountNumber
                ].attempts !== 0
              ? "rejection_content_body"
              : ""
          }`}
        >
          {congFlag ? (
            <div className="c_congrats_modal_desc">
              <p>Congratulations!</p>
              <p>Your offer is approved!</p>
            </div>
          ) : contextData?.attempt?.loanAttempts[
              contextData.currentActiveData.accountNumber
            ].attempts === 0 ? (
            <div className="c_congrats_modal_desc c_sorry_modal_desc">
              <p>Sorry, You have exhausted your attempts.</p>
            </div>
          ) : (
            <div className="c_congrats_modal_desc">
              <p>Sorry!</p>
              <p>
                Amount entered is low, Please try again with a higher amount.
              </p>
            </div>
          )}

          {congFlag ? (
            <div
              className="c_congrats_modal_download"
              onClick={() => handleDownloadLetter()}
            >
              <p>Download Settlement Letter</p>
              <img className="download_img" src="assets/images/download.svg" />
            </div>
          ) : (
            <>
              {contextData?.attempt?.loanAttempts[
                contextData.currentActiveData.accountNumber
              ].attempts === 0 ? (
                <div className="c_congrats_modal_desc c_sorry_modal_desc">
                  <p>Our representative will get in touch with you.</p>
                </div>
              ) : (
                <></>
              )}
            </>
          )}

          <div style={{ display: "none" }}>
            {/* {Object.keys(pdfData?.pdfDetails).length > 0 ? ( */}
            <div>
              <TCLCollectionLetterDownload
                pdfData={pdfData}
                // settlementTableRef={settlementTableRef}
              />
            </div>
            {/* ) : null} */}
          </div>

          <div style={{ display: "none" }}>
            {/* {Object.keys(pdfData?.pdfDetails).length > 0 ? ( */}
            <div>
              <TCHFLCollectionLetterDownload
                pdfData={pdfData}
                // settlementTableRef={settlementTableRef}
              />
            </div>
            {/* ) : null} */}
          </div>

          {congFlag ? (
            <div className="c_congrats_modal_btn_wrapper">
              <Button
                type="submit"
                className="c_congrats_modal_btn"
                onClick={() => handleOption()}
              >
                Proceed to pay
                <img src="assets/images/vector.svg" alt="vector" />
              </Button>
            </div>
          ) : (
            <div className="c_congrats_modal_btn_wrapper">
              <Button
                type="submit"
                className="c_congrats_modal_btn"
                onClick={okClick}
              >
                Ok
                <img src="assets/images/vector.svg" alt="vector" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CongratsModal;
