import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const retailUpdatePaymentTransStatus = (data) => {

    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/db/update-payment-transactions-status/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/db/update-payment-transactions-status/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default retailUpdatePaymentTransStatus;