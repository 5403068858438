import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getAgreementAndAnnexure = (data) => {
    // let requestJson = {
    //     "header": {
    //         "authToken": ""
    //     },
    //     "body": {
    //         "webtopId": "540CZ0005920",
    //         "disbursalDate": "01/01/2020"
    //     }

    // }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/dms/get-agreement-and-annexure/v1',data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/dms/get-agreement-and-annexure/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getAgreementAndAnnexure;