import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import TimeOutPopUp from "../TimeOutPopUp/TimeOutPopUp";
import { eventLoggingDtlsApiCall } from "../../../../Collection/src/services/eventLoggingDtlsApiCall";
import DataMapping from "../../../../Shared/context/data-mapping";

const Timer = () => {
  const [timer, setTimer] = useState("15:00");
  const Ref = useRef(null);
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];


  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let eventContext = JSON.parse(sessionStorage.getItem("dataMapping"))
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      let timer =
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds);
      // console.log(timer, "timer");
      if (timer === "05:00") {
        setTimeout(() => {
          setTimer(timer);
        }, 100);
      } else {
        setTimer(timer);
      }
      if (timer === "00:30") {
        console.log("logout api call");
        const eventUserDetails = eventContext?.eventUserDetails || [];
        // console.log(eventUserDetails,"eventUserDetails");

        let newEventId = "";
        let newPaymentTransactionId = "";

        if (eventUserDetails.length > 1) {
          newEventId = "";
          newPaymentTransactionId = "";
        } else if (eventUserDetails.length == 1) {
          if (eventContext?.uniqueTransactionId) {
            newEventId =
              eventContext?.latestPaymentTransactionDetails?.eventIdData;
            newPaymentTransactionId =
              eventContext?.latestPaymentTransactionDetails
                ?.paymentTransactionIdData;
          } else {
            newEventId = "";
            newPaymentTransactionId = "";
          }
        }

        let currentdate = new Date();
        let datetime = `${currentdate.getFullYear()}/${(
          "0" +
          (currentdate.getMonth() + 1)
        ).slice(-2)}/${("0" + currentdate.getDate()).slice(-2)} ${(
          "0" + currentdate.getHours()
        ).slice(-2)}:${("0" + currentdate.getMinutes()).slice(-2)}:${(
          "0" + currentdate.getSeconds()
        ).slice(-2)}`;

        let finalPaymentOptionEntity = "";
        let finalPaymentOptionAmount = "";
        finalPaymentOptionEntity = eventContext?.latestPaymentData
          ? eventContext?.latestPaymentData?.phase == 1
            ? eventContext?.latestPaymentData?.labelName
            : eventContext?.latestPaymentData?.phase == 2
            ? ""
            : ""
          : "";
        finalPaymentOptionAmount = eventContext?.latestPaymentData
          ? eventContext?.latestPaymentData?.phase == 1
            ? eventContext?.latestPaymentData?.amount
            : eventContext?.latestPaymentData?.phase == 2
            ? ""
            : ""
          : "";

        // let temObj = {
        //     // gcid: data?.parsedUserData[activeLoan]?.GCID,
        //     // LoginDate: data.getUserDataV3.body.LAST_LOGGED_IN,
        //     // LoginTime: data.getUserDataV3.body.LAST_LOGGED_IN,
        //     // LoginMethod: data?.setLoginType || null,
        //     // LoanNumber: activeLoan,
        //     // Product: "",
        //     // Bucket: "",
        //     // CIFID: "",
        //     // EntityName: "",
        //     // paymentOptionSelected: "",
        //     selectedTotalOverdueAmtPaymentCnt: 0,
        //     selectedEmiOverdueAmtPaymentCnt: 0,
        //     selectedOtherAmtPaymentCnt: 0,
        //     totalOverdueAmt: "",
        //     emiOverdueAmt: "",
        //     paymentStatus: "",
        //     gcid: "",
        //     accountNumber: "",
        //     bucket: "",
        //     product: "",
        //     additionalMobile: "",
        //     additionalEmail: "",
        //     isSettlementOffer: "",
        //     isOfferAvailed: "",
        //     minimumSettlementAmount: "",
        //     totalForeclosureAmount: "",
        //     foreclosurePrincipalOutstanding: "",
        //     foreclosureInterest: "",
        //     foreclosureCharges: "",
        //     settlementAmountEntered1: "",
        //     settlementAmountEntered2: "",
        //     settlementAmountEntered3: "",
        //     softSettlementAmountEntered1: "",
        //     softSettlementAmountEntered2: "",
        //     softSettlementAmountEntered3: "",
        //     softSettlementAmountEntered4: "",
        //     softSettlementAmountEntered5: "",
        //     settlementOfferReason: "",
        //     settlementOfferReasonOthers: "",
        //     offerApprovalStatus: "",
        //     offerApprovedSettlementAmount: "",
        //     actualWaiverPrincipalOutstanding: "",
        //     actualWaiverInterest: "",
        //     actualWaiverCharges: "",
        //     actualWaiverForeclosure: "",
        //     availableTranches: "",
        //     availedTranches: "",
        //     tranche1DueDate: "",
        //     tranche1Amount: "",
        //     tranche1PaymentDate: "",
        //     tranche1PaymentStatus: "",
        //     tranche2DueDate: "",
        //     tranche2Amount: "",
        //     tranche2PaymentDate: "",
        //     tranche2PaymentStatus: "",
        //     offerPaymentStatus: "",
        //     isSettlementLetterDownloaded: "",
        //   };
        let logoutEventDetails = {
          eventName: "logoutClick",
          eventId: newEventId,
          eventTimestamp: datetime,
          primaryGcid:
            eventContext.parsedUserData[
              eventContext.currentActiveData.accountNumber
            ].PRIMARY_GCID,
          userId: eventContext.getUserDataV3.body.USER_ID,
          token: eventContext.authToken,
          loginDate: eventContext.getUserDataV3.body.LAST_LOGGED_IN,
          loginTime: eventContext.getUserDataV3.body.LAST_LOGGED_IN,
          loginMethod: eventContext?.setLoginType || null,
          paymentTransactionId: newPaymentTransactionId,
          finalAccountNumber: eventContext.currentActiveData.accountNumber,
          finalPaymentOption: finalPaymentOptionEntity,
          finalPaymentAmt: finalPaymentOptionAmount,
          creditScoreChecked:
            eventContext.getUserDataV3.body.CREDIT_SCORE === "" ? "N" : "Y",
          finalAdditionalMobile: "",
          finalAdditionalEmail: "",
          logoutTime: datetime,
          // eventDetails: eventContext?.eventDataPostPayment
          // ? updatedEventDataPostPayment
          // : eventContext?.eventUserDetails
          // ? eventContext?.eventUserDetails
          // : [],
          eventDetails: eventContext?.eventUserDetails
            ? eventContext?.eventUserDetails
            : [],
        };
        console.log(logoutEventDetails, "logoutEventDetails");

        eventLoggingDtlsApiCall(logoutEventDetails, eventContext.authToken);
      }
    }
  };

  //   function startTimer() {
  //     // clearTimer(getDeadTime());
  //     setTimer("01:00");
  //   }

  const memorizedTimer = useCallback(function clearTimer() {
    let e = getDeadTime();
    // console.log("Timer present");

    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      // console.log("eeee", e);
      startTimer(e);
    }, 1000);
    Ref.current = id;
    setTimer("15:00");
  }, []);

  //   function clearTimer() {
  //     let e = getDeadTime();
  //     console.log("Timer present");

  //     // If you adjust it you should also need to
  //     // adjust the Endtime formula we are about
  //     // to code next
  //     setTimer("01:00");

  //     // If you try to remove this line the
  //     // updating of timer Variable will be
  //     // after 1000ms or 1sec
  //     if (Ref.current) clearInterval(Ref.current);
  //     const id = setInterval(() => {
  //       startTimer(e);
  //     }, 1000);
  //     Ref.current = id;
  //   }

  function getDeadTime() {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 900);
    return deadline;
  }

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only

  useEffect(() => {
    // console.log(timer)
    if (timer === "05:00") {
      Object.values(events).forEach((item) => {
        document.removeEventListener(item, memorizedTimer, true);
      });
    }
  }, [timer]);

  useEffect(() => {
    // clearTimer(getDeadTime());
    Object.values(events).forEach((item) => {
      document.addEventListener(item, memorizedTimer, true);
    });
    return () => {
      Object.values(events).forEach((item) => {
        document.removeEventListener(item, memorizedTimer, true);
      });
    };
  }, []);

  //   const removeEvent = () => {
  //   Object.values(events).forEach((item) => {
  //     document.removeEventListener(item, startTimer);
  //   });
  //   };

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    // clearTimer(getDeadTime());
    memorizedTimer();
    Object.values(events).forEach((item) => {
      document.addEventListener(item, memorizedTimer, true);
    });
  };

  return (
    <>
      {timer < "05:00" ? (
        <TimeOutPopUp reset={onClickReset} timer={timer} />
      ) : null}
    </>
  );
};

export default Timer;
