import commonFunctionObject from '../../../common-function';
import isDate from './isDate';
import not from './notOperator';

export default function isValidDate(date) {
    /*if (isDate(date)) {
        return true;
    }
    var parsedDate = new Date(Date.parse(date));
    var parsedDateStr = parsedDate.toDateString();
    if (parsedDateStr === "Invalid Date") {
        return false;
    }
    return true;*/
    return isDate(date) ? true : not(((new Date(commonFunctionObject.replaceHyphenDate(Date.parse(date)))).toDateString() === "Invalid Date"));
};