const rootPath = "assets/images/light-icon"

export const  productDetailsMaster = {
    "Personal Loan": {
        icon: `${rootPath}/Personal Loan-light-icon.svg`,
        productsArr: ["personal loan"]
    },
    "Business Loan": {
        icon: `${rootPath}/Bussiness Loan-light-icon.svg`,
        productsArr: ["business loan"]
    },
    "Home Loan": {
        icon: `${rootPath}/Home Loan-light-icon.svg`,
        productsArr: ["home loan"]
    },
    "Two Wheeler Loan": {
        icon: `${rootPath}/Two Wheeler-light-icon.svg`,
        productsArr: ["two wheeler"]
    },
    "Loan Against Security": {
        icon: `${rootPath}/Loans Against Security-light-icon.svg`,
        productsArr: ["loan against security"]
    },
    "Loan Against Property": {
        icon: `${rootPath}/Loans Against Property-light-icon.svg`,
        productsArr: ["loan against property"]
    },
    "Ceq Loan": {
        icon: `${rootPath}/CEQ Loan-light-icon.svg`,
        productsArr: ["caterpiller new", "caterpiller refinance", "ceq new", "ceq refinance", "ceq top up", "construction equipme", "construction equipment finance"]
    },
    "Used Car Loan": {
        icon: `${rootPath}/Used Car Loan-light-icon.svg`,
        productsArr: ["auto loan", "car loan", "used car cnc", "used car"]
    },
    "Auto Loan": {
        icon: `${rootPath}/Used Car Loan-light-icon.svg`,
        productsArr: ["car loan"]
    },
    "Consumer durable Loan": {
        icon: `${rootPath}/consumer-durable-light-icon.svg`,
        productsArr: ["consumer durable","consumer durables"]
    },
    "Credit Card Loan": {
        icon: `${rootPath}/Credit Cards-light-icon.svg`,
        productsArr: ["credit card"]
    },
    "Dropline Overdraft Loan": {
        icon: `${rootPath}/Overdraft-light-icon.svg`,
        productsArr: ["dropline overdraft loan"]
    },
    "Commercial Vehicle Loan": {
        icon: `${rootPath}/Commercial Vehicle Loan-light-icon.svg`,
        productsArr: ["commercial vehicle"]
    },
    "Project Loan": {
        icon: `${rootPath}/Project Loan-light-icon.svg`,
        productsArr: ["project loan"]
    },
    "Agri Loan": {
        icon: `${rootPath}/Agri Loan-light-icon.svg`,
        productsArr: ["rural - agri allied"]
    },
    "Rural Auto Loan": {
        icon: `${rootPath}/Rural Auto Loan-light-icon.svg`,
        productsArr: ["rural auto"]
    },
    "Vehicle Loan": {
        icon: `${rootPath}/Vehicle Loan-light-icon.svg`,
        productsArr: ["vehicle"]
    },
    "Tractor Loan": {
        icon: `${rootPath}/Tractor Loan-light-icon.svg`,
        productsArr: ["tractor finance"]
    },
    "Closed Account Loan": {
        icon: `${rootPath}/closed-account-light-icon.svg`,
        productsArr: ["closed account"]
    },
    "Insurance Loan": {
        icon: `${rootPath}/insurance-loan-light-icon.svg`,
        productsArr: ["insurance loan"]
    },
}


