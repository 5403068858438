import React from "react";
import PropTypes from 'prop-types';
const TabContent = ({id, activeTab, children}) => {
 return (
   activeTab === id ? <div className="TabContent">
     { children }
   </div>
   : null
 );
};
 
TabContent.propTypes = { 
  id:PropTypes.string , 
  activeTab:PropTypes.string,
  children:PropTypes.object
}
export default TabContent;