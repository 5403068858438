/*******************************************JS Helper - Start******************************************************/
import isIE  from "./functions/isIE";
import not from "./functions/notOperator";
import and from "./functions/andOperator";
import andMany from "./functions/andOperatorMany";
import or from './functions/orOperator';
import orMany from './functions/orOperatorMany';
import orOneWithMany from './functions/orOneWithMany';
import andOneWithMany from './functions/andOneWithMany';
import ternaryFunc from './functions/ternaryOperatorFunction';
import isEqRef from './functions/isEqualByReference';
import isEqVal from './functions/isEqualByValue';
import isEqValMany from './functions/isEqualByValueMany';
import isEqValManyCI from './functions/isEqualByValueManyCI';
import isGt from './functions/isGreaterThan';
import isLt from './functions/isLessThan';
import isDefined from './functions/isDefined';
import isUndefined from './functions/isUndefined';
import isNull from './functions/isNullStrict';
import isNonNull from './functions/isNonNullStrict';
import isTrue from './functions/isBooleanTrue';
import isFalse from './functions/isBooleanFalse';
import isFunc from './functions/isFunction';
import isObj from './functions/isObject';
import isStr from './functions/isString';
import isNum from './functions/isNumber';
import isInt from './functions/isInteger';
import isBool from './functions/isBoolean';
import isArr from './functions/isArray';
import isEmpArr from './functions/isEmptyArray';
import isEmpObj from './functions/isEmptyObject';
import isFormData from './functions/isFormData';
import arrIncludes from './functions/arrayIncludes';
import arrFill from './functions/arrayFill';
import arrOfArrConcat from './functions/arrayOfArrayConcat';
import isDate from './functions/isDate';
import isValidDate from './functions/isValidDate';
import toISTDate from './functions/toISTDate';
import toObj from './functions/toObject';
import toArr from './functions/toArray';
import toStr from './functions/toString';
import toNum from './functions/toNumber';
import objKeys from './functions/objectKeys';
import objVals from './functions/objectValues';
import freezeObj from './functions/freezeObject';
import deepFreezeObj from './functions/deepFreezeObject';
import toCamelCase from './functions/StringToCamelCase';
import toSentence from './functions/StringToSentence';
import cloneObj from './functions/cloneObjectShallowAndDeep';
import defineReadOnlyObjectProperty from './functions/defineReadOnlyObjectProperty';
/* import defineObjectProperty from './functions/defineObjectProperty'; */
import defineReadOnlyObjectProperties from './functions/defineReadOnlyObjectProperties';
import defineObjectProperties from './functions/defineObjectProperties';
import flattenObject from './functions/flattenObject';
import unflattenObject from './functions/unflattenObject';
import mapFlatObj from './functions/updateFlatObjByKeyMap';
import parseJson from './functions/parseJson';
import stringifyJson from './functions/stringifyJson';
import cloneJson from './functions/cloneJson';
import extendFunc from './functions/extendFunction';

/**
 * This module consist all the required helper function .
 * This module is to enhance its implementation. Keep adding commonly used functions in this module and use this module as dependency.
 */
 
 let JSHelper = {};
 
(function (_global) {

    var _jsHelper = (function () {

        var jsHelperObj = {};

        var noopFunc = function(){};
        
        
        jsHelperObj.isIE = jsHelperObj.isIE = isIE;

        jsHelperObj.notOperator = jsHelperObj.not = not;

        jsHelperObj.andOperator = jsHelperObj.and = and;

        jsHelperObj.andOperatorMany = jsHelperObj.andMany = andMany;

        jsHelperObj.orOperator = jsHelperObj.or = or;

        jsHelperObj.orOperatorMany = jsHelperObj.orMany = orMany;

        jsHelperObj.orOneWithMany = jsHelperObj.orOneWithMany = orOneWithMany;

        jsHelperObj.andOneWithMany = jsHelperObj.andOneWithMany = andOneWithMany;

        jsHelperObj.ternaryOperatorFunction = jsHelperObj.ternaryFunc = ternaryFunc;

        jsHelperObj.isEqualByReference = jsHelperObj.isEqRef = isEqRef;

        jsHelperObj.isEqualByValue = jsHelperObj.isEqVal = isEqVal;

        jsHelperObj.isEqualByValueMany = jsHelperObj.isEqValMany = isEqValMany;

        jsHelperObj.isEqualByValueManyCI = jsHelperObj.isEqValManyCI = isEqValManyCI;

        jsHelperObj.isGreaterThan = jsHelperObj.isGt = isGt;

        jsHelperObj.isLessThan = jsHelperObj.isLt = isLt;

        jsHelperObj.isDefined = jsHelperObj.isDef = isDefined;

        jsHelperObj.isUndefined = jsHelperObj.isUndef = isUndefined;

        jsHelperObj.isNull = jsHelperObj.isNull = isNull;

        jsHelperObj.isNonNull = jsHelperObj.isNonNull = isNonNull;

        jsHelperObj.isTrue = isTrue;

        jsHelperObj.isFalse = isFalse;

        jsHelperObj.isFunction = jsHelperObj.isFunc = isFunc;
        
        jsHelperObj.isObject = jsHelperObj.isObj = isObj;

        jsHelperObj.isString = jsHelperObj.isStr = isStr;

        jsHelperObj.isNumber = jsHelperObj.isNum = isNum;

        jsHelperObj.isInteger = jsHelperObj.isInt = isInt;

        jsHelperObj.isBoolean = jsHelperObj.isBool = isBool;
        
        jsHelperObj.isArray = jsHelperObj.isArr = isArr;

        jsHelperObj.isEmpArr = jsHelperObj.isEmptyArray = isEmpArr;

        jsHelperObj.isEmpObj = jsHelperObj.isEmptyObject = isEmpObj;

        jsHelperObj.isFormData = jsHelperObj.isFormData = isFormData;

        jsHelperObj.arrayIncludes = jsHelperObj.arrIncludes = arrIncludes;

        jsHelperObj.arrFill = jsHelperObj.arrayFill = arrFill;

        jsHelperObj.arrOfArrConcat = jsHelperObj.arrayOfArrayConcat = arrOfArrConcat;

        jsHelperObj.isDate = jsHelperObj.isDate = isDate;

        jsHelperObj.isValidDate = jsHelperObj.isValidDate = isValidDate;

        jsHelperObj.toISTDate = jsHelperObj.toISTDate = toISTDate;

        jsHelperObj.toObject = jsHelperObj.toObj = toObj;

        jsHelperObj.toArray = jsHelperObj.toArr = toArr;

        jsHelperObj.toString = jsHelperObj.toStr = toStr;

        jsHelperObj.toNumber = jsHelperObj.toNum = toNum;

        jsHelperObj.objectKeys = jsHelperObj.objKeys = objKeys;

        jsHelperObj.objectValues = jsHelperObj.objVals = objVals;

        jsHelperObj.freezeObject = jsHelperObj.freezeObj = freezeObj;

        jsHelperObj.deepFreezeObject = jsHelperObj.deepFreezeObj = deepFreezeObj;

        jsHelperObj.toCamelCase = jsHelperObj.toCC = toCamelCase;

        jsHelperObj.toSentence = jsHelperObj.toSentc = toSentence;

        jsHelperObj.cloneObject = jsHelperObj.cloneObj = cloneObj;

        jsHelperObj.defineReadOnlyObjectProperty = jsHelperObj.defineReadOnlyObjProp = defineReadOnlyObjectProperty;

        /* jsHelperObj.defineObjectProperty = jsHelperObj.defineObjProp = defineObjectProperty; */

        jsHelperObj.defineReadOnlyObjectProperties = jsHelperObj.defineReadOnlyObjProps = defineReadOnlyObjectProperties;

        jsHelperObj.defineObjectProperties = jsHelperObj.defineObjProps = defineObjectProperties;

        jsHelperObj.flattenObject = jsHelperObj.flattenObj = flattenObject;

        jsHelperObj.unflattenObject = jsHelperObj.unflattenObj = unflattenObject;

        jsHelperObj.mapFlatObject = jsHelperObj.mapFlatObj = mapFlatObj;

        jsHelperObj.parseJson = parseJson;

        jsHelperObj.stringifyJson = stringifyJson;

        jsHelperObj.cloneJson = cloneJson;

        jsHelperObj.extendFunction = jsHelperObj.extendFunc = extendFunc;

        
        return freezeObj(jsHelperObj);
        
    })();

    _jsHelper.defineReadOnlyObjProp(_global, 'jsHelper', _jsHelper);
    // _jsHelper.defineReadOnlyObjProp(_global.jsHelper,'describe',"Description");
    

})(JSHelper);

/* exports.JSHelper = JSHelper.jsHelper; */
export default JSHelper.jsHelper;

/*******************************************JS Helper - End******************************************************/