import React from 'react';
import './header.scss';
import {useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const Header = ({override_class}) => {
 const navigate = useNavigate();
  return (
    <div className='header_wrapper main-container'>
      <div className={`tata_capital_logo main-div ${override_class}`}>
        <img src="assets/images/header-logo.svg" alt="header-logo" onClick={override_class==="c_overview-header" ?()=>{navigate("/overview")}: ()=>{window.open('https://www.tatacapital.com/')} }/>
      </div>
    </div>
  )
}

Header.propTypes = {
  override_class: PropTypes.string
}

export default Header
