import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getGenerateInterestCertificate = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },

        "body": {
            "accountNumber": "TCFPL0386000010000021",
            "fromDate": "01/04/2018",
            "toDate": "30/03/2019"
        }

    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/finnone/generate-interest-certificate/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/finnone/generate-interest-certificate/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getGenerateInterestCertificate