import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getBoGetUrl = (data) => {
    let requestJson = {
        "header": {
            "authToken": "",
            "requestType": "welcome-homeloan"
        },
        "body": {
            "Report": "5",
            "AcctNum": "MDAwMDAwMDAwMDkwODI5Mjg="
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/bo/bo-get-url/v1', data).then(response => {
        stalwartApiCall('POST',data,'/shaft/api/bo/bo-get-url/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })

}

export default getBoGetUrl;