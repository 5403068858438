import { useState, useEffect, useMemo } from "react";

const useReactiveForm = (formInitialize) => {
  const [formData, setFormData] = useState(formInitialize);

  const buildForm = (formDataParam) => {
    let defaulFinalData = {
      control: {},
      value: {},
      valid: true,
      updateForm: () => { }
    };

    let formValidityStatus = true;
    //loop through the givien formgroup
    for (const key in formDataParam) {
      let [value, validations, dirty = false] = formDataParam[key];
      let controlValidityStatus = true;

      //save updated user values in control and value
      defaulFinalData = {
        ...defaulFinalData,
        control: {
          ...defaulFinalData.control,
          [key]: { value: value, dirty: dirty, valid: true },
        },
        value: {
          ...defaulFinalData.value,
          [key]: value,
        },
        updateForm: (newControl) => {
          setFormData((prevData) => {
            prevData = {
              ...prevData,
              ...newControl,
            };

            return { ...prevData };
          });
        },
      };

      if (validations) {
        validations.forEach((validation) => {
          if (validation(value) !== null) {
            //validation status for form and for control to be set false if error found
            formValidityStatus = false;
            controlValidityStatus = false;

            if (!defaulFinalData.control[key].error) {
              //set error type and message
              defaulFinalData.control[key].error = validation(value);
              defaulFinalData.control[key].valid = controlValidityStatus;
            }
          }
        });
      }
    }

    //assign form validity status
    defaulFinalData.valid = formValidityStatus;

    return { ...defaulFinalData };
  };

  const [finalFormData, setFinalFormData] = useState(buildForm(formInitialize));

  const handleChange = (controlName, value) => {
    if (controlName in formData) {
      let updatedFormData = { ...formData };
      updatedFormData[controlName][0] = value;
      updatedFormData[controlName][2] = true;
      setFormData(updatedFormData);
      let resultData = buildForm(formData);
      setFinalFormData(resultData);
    }
  };

  useEffect(() => {
    let resultData = buildForm(formData);
    setFinalFormData(resultData);
  }, [formData]);

  return useMemo(
    () => [finalFormData, handleChange],
    [finalFormData, formData]
  );

};

export default useReactiveForm;
