import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const serviceRequest = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {

        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/sfdc/service-request/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/sfdc/service-request/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default serviceRequest;