// (function (_global) {
//     var bizObj = (function () {
//         var commonFunctionObject = {
//         };

import extractDoc from "../apis/extract-doc";
import { getServerTime } from "../apis/get-server-time/get-server-time";
import jsHelper from "../utilities/jsHelper";

//         return jsHelper.freezeObj(commonFunctionObject);
//     })();
//     _global.jsHelper.defineReadOnlyObjectProperty(_global, "commonFunctionObject", bizObj);
// })(this || document || {});

// const commonFunctionObject = {

// };

const commonFunctionObject = {};
var product_Code = ["9001", "INS_HF", "HFL_LAP", "HL_HFL"];
var activeStatus = [
  "new account",
  "partially advanced",
  "applied for cancellation",
  "approved",
  "accepted",
  "request for termination",
  "applied for termination",
  "fully advanced",
  "active",
];
var closedStatus = ["cancelled", "discharged", "closed"];

const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

commonFunctionObject.base64ToArrayBuffer = base64ToArrayBuffer;

const saveByteArray = (reportName, byte, type) => {
  var blob = new Blob([byte], { type: type });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.target = "_blank";
  var fileName = reportName;
  link.download = fileName;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
};

commonFunctionObject.saveByteArray = saveByteArray;

function getProductFamily(productDetails) {
  let productFamily = "";
  if (
    productDetails?.productInfo &&
    Object.keys(productDetails?.productInfo).length > 0
  ) {
    productFamily = productDetails.productInfo.PRODUCT_FAMILY;
  } else {
    var product_Code = ["9001", "INS_HF", "HFL_LAP", "HL_HFL"];
    if (product_Code.includes(productDetails.PRODUCT_CODE)) {
      productFamily = "TCHFL";
    } else {
      productFamily = "CFAB";
    }
  }
  return productFamily;
}
commonFunctionObject.getProductFamily = getProductFamily;

function getProductName(productDetails) {
  var product_name = "";
  var productNameFetched = "";
  let usedCarLoanArray = ["auto loan", "car loan", "used car cnc"];
  let ceqLoanArray = [
    "caterpiller new",
    "caterpiller refinance",
    "ceq new",
    "ceq refinance",
    "ceq top up",
    "construction equipme",
    "construction equipment finance",
  ];
  if (
    productDetails?.productInfo &&
    Object.keys(productDetails?.productInfo).length > 0
  ) {
    productNameFetched = productDetails.productInfo.DISPLAY_NAME;
  } else {
    productNameFetched = productDetails.PRODUCT;
  }

  if (usedCarLoanArray.includes(productNameFetched.toLowerCase())) {
    product_name = "Used Car";
  } else if (ceqLoanArray.includes(productNameFetched.toLowerCase())) {
    product_name = "CEQ Loan";
  } else if (productNameFetched === "rural - agri allied") {
    product_name = "Agri Loan";
  } else if (productNameFetched === "tractor finance") {
    product_name = "Tractor Loan";
  } else if (productNameFetched === "commercial vehicle") {
    product_name = "Commercial Vehicle Loan";
  } else {
    product_name = productNameFetched;
  }

  return product_name;
}

commonFunctionObject.getProductName = getProductName;

const getLastestFieldValFromField = (params) => {
  var array = params.split("|"),
    m = "";
  array.forEach(function (el) {
    if (el !== "") {
      el.split(",").forEach(function (e) {
        if (e !== "" && m === "") {
          m = e;
        }
      });
    }
  });
  return m;
};

commonFunctionObject.getLastestFieldValFromField = getLastestFieldValFromField;

const getServerTimeService = (requestObj) => {
  return new Promise((resolve, reject) => {
    getServerTime(requestObj)
      .then((getServerTimeResponse) => {
        if (getServerTimeResponse.header.status === "SUCCESS") {
          let currentServerTime =
            getServerTimeResponse.body.currentServerTimeInIST;
          resolve(currentServerTime);
        } else {
          reject("T-01");
        }
      })
      .catch((error) => {
        reject();
      });
  });
};

commonFunctionObject.getServerTimeService = getServerTimeService;

function convertDDMMYYYY(date) {
  var month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  var currentDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return currentDate + "/" + month + "/" + date.getFullYear();
}
commonFunctionObject.convertDDMMYYYY = convertDDMMYYYY;

function convertToBase64(files, skipMetadata) {
  console.log("in convert");
  console.log(files);
  console.log(skipMetadata);
  if (files instanceof FileList) {
    files = Array.from(files);
  } else if (!Array.isArray(files)) {
    return new Promise(function (resolve, reject) {
      reject("Invalid file array");
    });
  }

  function truncateMetadata(base64String) {
    if (skipMetadata === true) {
      var base64StrArr = String(base64String).split("base64,");
      return base64StrArr.length > 1 ? base64StrArr[1] : "";
    }
    return base64String;
  }

  return new Promise(function (resolve, reject) {
    var filesLoadCount = 0;
    var fileArray = new Array(files.length);
    files.forEach(function (file, index) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        fileArray[index] = truncateMetadata(reader.result);
        filesLoadCount++;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
    var intervalId;
    intervalId = setInterval(function () {
      if (filesLoadCount === fileArray.length) {
        resolve(fileArray);
        clearInterval(intervalId);
      }
    }, 500);
  });
}

commonFunctionObject.convertToBase64 = convertToBase64;

const checkContractStatus = (contractStatus) => {
  if (activeStatus.includes(contractStatus.toLowerCase())) {
    return true;
  } else if (closedStatus.includes(contractStatus.toLowerCase())) {
    return false;
  }
};
commonFunctionObject.checkContractStatus = checkContractStatus;

const checkAgreementAnnexureTWL = (disbursalDate) => {
  var disbursalDate = new Date(convertDisbursalDate(disbursalDate));
  var conditionDate = new Date("10/01/2019");
  var utc1 = Date.UTC(
    disbursalDate.getFullYear(),
    disbursalDate.getMonth(),
    disbursalDate.getDate()
  );
  var utc2 = Date.UTC(
    conditionDate.getFullYear(),
    conditionDate.getMonth(),
    conditionDate.getDate()
  );

  if (utc1 > utc2) {
    return true;
  } else {
    return false;
  }
};
commonFunctionObject.checkAgreementAnnexureTWL = checkAgreementAnnexureTWL;

function convertDisbursalDate(date) {
  var splitDate = date.split(".");
  return splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0];
}
commonFunctionObject.convertDisbursalDate = convertDisbursalDate;

function getFirstValue(params) {
  var array = params.split("|"),
    m = "";
  array.forEach(function (el) {
    if (el !== "") {
      el.split(",").forEach(function (e) {
        if (e !== "" && m === "") {
          m = e;
        }
      });
    }
  });
  return m;
}
commonFunctionObject.getFirstValue = getFirstValue;

function convertDateToDDMMMYYYY(date) {
  var month_names = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var day = addZeroInDate(date.getDate());
  var month_index = date.getMonth();
  var year = date.getFullYear();

  return "" + day + "-" + month_names[month_index] + "-" + year;
}

commonFunctionObject.convertDateToDDMMMYYYY = convertDateToDDMMMYYYY;

function addZeroInDate(value) {
  if (value < 10) {
    return "0" + value;
  } else {
    return value;
  }
}

function appendZeros(accountNumber) {
  var dataAccNo = "";
  var accLength = accountNumber.length;
  if (accLength < 17) {
    var zeroLen = 17 - accLength;
    var preString = "";
    for (var i = 0; i < zeroLen; i++) {
      preString += "0";
    }
    dataAccNo = preString + "" + accountNumber;
    console.log(preString + "" + accountNumber);
  } else {
    dataAccNo = accountNumber;
  }
  return dataAccNo;
}
commonFunctionObject.appendZeros = appendZeros;

function checkSubsequent(contract, subsequentContracts) {
  // var onlineTrancheDetails = dataStore.get('subsequentContracts');
  // Object.keys(getDisbursalDetailsResponse.body).length === 0
  if (subsequentContracts && Object.keys(subsequentContracts).length !== 0) {
    var productFamily = getProductFamily(contract);

    var dataAccNo = getAccountNumber(
      productFamily,
      contract,
      contract.CONTRACT_NUMBER
    );

    var onlineTrachActiveLoan = Object.keys(subsequentContracts).filter(
      function (value) {
        return value === dataAccNo;
      }
    );
    if (
      onlineTrachActiveLoan &&
      Object.keys(onlineTrachActiveLoan).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }
}
commonFunctionObject.checkSubsequent = checkSubsequent;

function getAccountNumber(productFamily, productDetails, accountNo) {
  var dataAccNo = "";
  if (productFamily && productFamily === "TCHFL") {
    dataAccNo = appendZeros(accountNo);
  } else if (
    productDetails.productInfo &&
    productDetails.productInfo.PRODUCT_CODE === "7201"
  ) {
    dataAccNo = appendZeros(accountNo);
  } else if (productDetails.PRODUCT_CODE === "7201") {
    dataAccNo = appendZeros(accountNo);
  } else {
    dataAccNo = accountNo;
  }
  return dataAccNo;
}

commonFunctionObject.getAccountNumber = getAccountNumber;

const checkSplitCharges = (userData) => {
  let flag = false;
  if (userData.SOURCE_SYSTEM.toLowerCase() == "finone") {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};

commonFunctionObject.checkSplitCharges = checkSplitCharges;
const checkEmptyData = (data, type) => {
  if (data) {
    if (data instanceof Object) {
      if (type === "amount") {
        return "₹ 0";
      } else {
        return "-";
      }
    } else {
      if (type === "amount") {
        return "₹ " + Number(data).toLocaleString("en-IN");
      } else {
        return data;
      }
    }
  } else if (data == "0") {
    if (type === "amount") {
      return "₹ 0";
    } else {
      return data;
    }
  } else {
    if (type === "amount") {
      return "₹ 0";
    } else {
      return "-";
    }
  }
};

commonFunctionObject.checkEmptyData = checkEmptyData;

const scrollToTop = () => {
  window &&
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
};
commonFunctionObject.scrollToTop = scrollToTop;

const scrollToBottom = () => {
  window && window.scrollTo(0, document.body.scrollHeight);
};
commonFunctionObject.scrollToBottom = scrollToBottom;

function datechange(date) {
  var changeDate = date?.split("-");
  if (changeDate) {
    var newDateFormat =
      changeDate[2] + "/" + changeDate[1] + "/" + changeDate[0];
    return newDateFormat;
  }
}
commonFunctionObject.datechange = datechange;

function getMobileOREmailFromMany(data) {
  var m = "";
  data.split(",").forEach(function (e) {
    if (e !== "" && m === "") {
      m = e;
    }
  });
  return m;
}
commonFunctionObject.getMobileOREmailFromMany = getMobileOREmailFromMany;

function getURLParams(url) {
  var queryParams = {};
  try {
    url = url ? url : window.location.search;
    url
      .split("?")[1]
      .split("&")
      .forEach(function (pair) {
        var key = pair.split("=")[0];
        var val = pair.split("=")[1];
        queryParams[key] = val;
      });
  } catch (err) {
    return "";
  }
  return queryParams;
}
commonFunctionObject.getURLParams = getURLParams;

function getProductShotName(productName) {
  return productName
    .split(" ")
    .map(function (value) {
      return value[0];
    })
    .join("");
}
commonFunctionObject.getProductShotName = getProductShotName;
function getJsonFromString(url) {
  var queryParams = {};
  try {
    url.split("&").forEach(function (pair) {
      var key = pair.split("=")[0];
      var val = pair.split("=")[1];
      queryParams[key] = val;
    });
  } catch (err) {
    return "";
  }
  return queryParams;
}

commonFunctionObject.getJsonFromString = getJsonFromString;

const replaceHyphenDate = (oldDate) => {
  let newDate = String(oldDate).replaceAll("-", "/");
  return newDate;
};

commonFunctionObject.replaceHyphenDate = replaceHyphenDate;

const setIconForClosedAccount = (productName, themeState) => {
  switch (productName.toLowerCase()) {
    case "personal loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Personal Loan-dark-icon.svg"
        : "assets/images/light-icon/Personal Loan-light-icon.svg";
      break;
    case "business loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Bussiness Loan-dark-icon.svg"
        : "assets/images/light-icon/Bussiness Loan-light-icon.svg";
      break;
    case "home loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Home Loan-dark-icon.svg"
        : "assets/images/light-icon/Home Loan-light-icon.svg";
      break;
    case "used car":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Used Car Loan-dark-icon.svg"
        : "assets/images/light-icon/Used Car Loan-light-icon.svg";
      break;
    case "car loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Used Car Loan-dark-icon.svg"
        : "assets/images/light-icon/Used Car Loan-light-icon.svg";
      break;
    case "two wheeler":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Two Wheeler-dark-icon.svg"
        : "assets/images/light-icon/Two Wheeler-light-icon.svg";
      break;
    case "loan against property":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Loans Against Property-dark-icon.svg"
        : "assets/images/light-icon/Loans Against Property-light-icon.svg";
      break;
    case "consumer durable":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/consumer-durable-dark-icon.svg"
        : "assets/images/light-icon/consumer-durable-light-icon.svg";
      break;
    case "credit card":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Credit Cards-dark-icon.svg"
        : "assets/images/light-icon/Credit Cards-light-icon.svg";
      break;
    case "ceq loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/CEQ Loan-dark-icon.svg"
        : "assets/images/light-icon/CEQ Loan-light-icon.svg";
      break;
    case "commercial vehicle loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Commercial Vehicle Loan-dark-icon.svg"
        : "assets/images/light-icon/Commercial Vehicle Loan-light-icon.svg";
      break;
    case "project loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Project Loan-dark-icon.svg"
        : "assets/images/light-icon/Project Loan-light-icon.svg";
      break;
    case "agri loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Agri Loan-dark-icon.svg"
        : "assets/images/light-icon/Agri Loan-light-icon.svg";
      break;
    case "rural auto loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Rural Auto Loan-dark-icon.svg"
        : "assets/images/light-icon/Rural Auto Loan-light-icon.svg";
      break;
    case "vehicle loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Vehicle Loan-dark-icon.svg"
        : "assets/images/light-icon/Vehicle Loan-light-icon.svg";
      break;
    case "tractor loan":
      return themeState === "THEME_DARK"
        ? "assets/images/dark-icon/Tractor Loan-dark-icon.svg"
        : "assets/images/light-icon/Tractor Loan-light-icon.svg";
      break;
  }
};
commonFunctionObject.setIconForClosedAccount = setIconForClosedAccount;

const getProductShortName = (productName) => {
  return productName
    .split(" ")
    .map(function (value) {
      return value[0];
    })
    .join("")
    .toUpperCase();
};
commonFunctionObject.getProductShortName = getProductShortName;

const checkActiveStatus = (getLoanPositionData) => {
  let activeStatus = [
    "new account",
    "partially advanced",
    "applied for cancellation",
    "approved",
    "accepted",
    "request for termination",
    "applied for termination",
    "fully advanced",
    "active",
  ];
  // if()
  if (
    activeStatus.includes(
      getLoanPositionData.body.getLoanPositionDtlsReturn.loanStatus.toLowerCase()
    )
  ) {
    return "active";
  } else {
    return "closed";
  }
};
commonFunctionObject.checkActiveStatus = checkActiveStatus;

const extractDocWithoutSrCall = (reqObj) => {
  return new Promise((resolve, reject) => {
    extractDoc(reqObj)
      .then((response) => {
        if (response.header.status.toUpperCase() === "SUCCESS") {
          if (response.body.retStatus.toUpperCase() == "SUCCESS") {
            if (jsHelper.isDefined(response.body.dmsResponse)) {
              var pdf = response.body.dmsResponse.DMSRESPONSE;
              var byteArr = base64ToArrayBuffer(pdf);
              var fileName = "NOC.pdf";
              var mimeType = "application/pdf";
              saveByteArray(fileName, byteArr, mimeType);
              resolve(response);
            } else {
              reject("");
            }
          } else {
            reject("");
          }
        }
        if (jsHelper.isDef(response.errorBody)) {
          if (jsHelper.isDef(response.errorBody.message)) {
            var jsonParsed = JSON.parse(response.errorBody.message);
            if (jsHelper.isDef(jsonParsed.retStatus)) {
              if (jsonParsed.retStatus === "ERROR") {
                reject("22");
              } else {
                reject("");
              }
            } else {
              reject("");
            }
          }
        }
      })
      .catch(() => {
        reject("");
      });
  });
};
commonFunctionObject.extractDocWithoutSrCall = extractDocWithoutSrCall;

const apiserverErrorAnalytics = (errorType, errorMsg, analyticFn) => {
  let analytics_data = {
    user: {
      loginMethod: "",
      loginStatus: "",
      gcid: "",
      loginType: "",
      loanaccountNo: "",
      firstloginDate: "",
    },
    products: {
      productCode: "",
    },
    data: {
      ctaText: "",
      componentName: "",
      bannerTitle: "",
      menuTitle: "",
      faqTitle: "",
      searchTerm: "",
      lobActivity: "",
      status: "",
      eventName: "api server error",
      errorType: errorType,
      errorMsg: errorMsg,
    },
  };
  analyticFn.trackData("apiserverError", analytics_data);
};

commonFunctionObject.apiserverErrorAnalytics = apiserverErrorAnalytics;

const getDeviceAndBrowserInfo = () => {
  return new Promise((resolve, reject) => {
    const deviceDetailsObj = {};
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isTablet = /iPad/i.test(navigator.userAgent);
    // Get device type
    if (isMobile) {
      deviceDetailsObj["deviceType"] = "Responsive";
    } else if (isTablet) {
      deviceDetailsObj["deviceType"] = "Responsive";
    } else {
      deviceDetailsObj["deviceType"] = "Desktop";
    }
    // Get browser name and version
    const userAgent = navigator.userAgent;
    const browsers = {
      Edge: /Edg\/([\d\.]+)/,
      Chrome: /Chrome\/([\d\.]+)/,
      Firefox: /Firefox\/([\d\.]+)/,
      Safari: /Safari\/([\d\.]+)/,
    };
    const browserMatch = Object.keys(browsers)
      .map((key) => {
        const match = userAgent.match(browsers[key]);
        return match && { name: key, version: match[1] };
      })
      .find(Boolean);
    if (browserMatch) {
      deviceDetailsObj["browser"] = browserMatch.name;
      deviceDetailsObj["browsersVersion"] = browserMatch.version;
    }
    if (window) {
      // Get window width and height
      deviceDetailsObj["windowWidth"] = window.screen.width;
      deviceDetailsObj["browsersHeight"] = window.screen.height; // get landscape or potrait
      const potrait = window.matchMedia("(orientation: potrait)");
      deviceDetailsObj["deviceOrientation"] = potrait ? "potrait" : "landscape";
    }
    resolve(deviceDetailsObj);
  });
};
commonFunctionObject.getDeviceAndBrowserInfo = getDeviceAndBrowserInfo;

export default commonFunctionObject;
