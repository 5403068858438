import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getPledgingUrl = (data) => {

    let requestJson = {
        "header": {
            "authToken": "",
            "pledgingType": "equity"
        },
        "body": {
            "clientId": "22",
            "gcid": "33000547",
            "loanAccountNumber": "24",
            "data": [
                {
                    "isin": "INE203A01020",
                    "quantity": "500",
                    "marketValue": "2115650.00"
                },
                {
                    "isin": "INE100A01010",
                    "quantity": "150",
                    "marketValue": "918142.50"
                }
            ]
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/las-direct/get-pledging-url/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-direct/get-pledging-url/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getPledgingUrl;