import OverviewServiceObject from "../../../Retail/src/services/Overview";
import { STOREACCOUNTDATA, STOREDATA } from "../../../Shared/context/data-mapping/action/action.type";
import { requestDate } from "./requestDate";


export const trenchApiCall = (dispatchData, data, preApi) => {

    let ccodData = []
    data?.getUserDataV3?.body?.otherSystemDetails.forEach((currentLoan) => {
        if (currentLoan.SYSTEM_NAME.toLowerCase() == "ccod") {
            ccodData = currentLoan.ccodContractDetails
        }
    })

    ccodData.forEach((currentLoan) => {
        let contractNumber = currentLoan.cdiContract.CUSTOMER_NO;
        let companyName = currentLoan.cdiContract.COMPANY.toUpperCase() === "TCL" ? "CFAB" : currentLoan.cdiContract.COMPANY.toUpperCase()

        var requestObjTrench = {
            header: {
                authToken: data.authToken,
                company: companyName,
            },
            body: {
                pName: ccodData.PROGRAM_NAME,
                groupKey: ccodData.DEALER_GROUP_KEY,
                manuCoCode: ccodData.MANU_CO_CODE,
                date: requestDate(),
            },
        };

        dispatchData({ name: "loaderFlag", type: STOREDATA, payload: true, });

        if (!data?.trenchReport?.[contractNumber]) {
            OverviewServiceObject.trenchReportApiService(requestObjTrench, contractNumber, preApi)
                .then((trenchReportResponse) => {
                    dispatchData({
                        type: STOREACCOUNTDATA,
                        payload: {
                            response: trenchReportResponse,
                            contractNumber: contractNumber,
                        }, name: "trenchReport",
                    });
                }).catch((err) => {console.log(err)});
        }
    })
};
