import React from "react";
import "./ProfileDetails.scss";
import RadiantBorder from "../../global/RadiantBorder";
import ProfileDetailsComponent from "../../global/ProfileDetailsComponent";
import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import { useState } from "react";
import CustomDropDown from "../../global/CustomDropDown/CustomDropDown";
import Data from "../../../../Shared/screens/Profile/Data";
import { useEffect } from "react";
import Button from "../../global/Button";
import ProfileServiceObject from "../../services/Profile/ProfileService";
import Modal from "../../global/Modal";
import Loader from "../../global/Loader";
import Input from "../../global/Input";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import commonFunctionObject from "../../common-function";
import AccountNumberDropDown from "../../global/AccountNumberDropDown/AccountNumberDropDown";
import FailurePopUp from "../../global/FailurePopUp";
import { Navigate, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

const ProfileDetails = ({
  portalType,
  heading,
  bankStyle,
  isMobile,
  setIsActive,
  optionState,
  setOptionState,
  selectedOption,
  setSelectedOption,
  optionArray,
  storeSelectAccNumberData,
  systemFlag = "",
  currentContract,
  typeFlag,
  bankingDetails,
  bankLas,
  closeAcc,
  companylist
}) => {

  const [data, dispatchData] = useContext(DataMapping)
  const [lasFormSubmitModal, setLasFormSubmitModal] = useState(false)
  const [employmentType, setEmployementType] = useState("")
  const [inputChange, setInputChange] = useState("")
  const [dropdownState, setDropDownState] = useState(false)
  const [themeState] = useContext(ThemeContext);
  const [occupationType, setOccupationType] = useState("")
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    "apiName": "",
    "message": "",
    "errorCode": ""
  })
  const [ccodFirstName, setCcodFirstName] = useState("")
  const [ccodLastName, setCccodLastName] = useState("")
  const [firmCompName, setFirmCompName] = useState("")
  const [familyMemberAccNo, setFamilyMemberAccNo] = useState("");
  const navigate = useNavigate();

  const submitProfileData = () => {

    let requestObj = {
      "header": {
        "authToken": data.authToken
      },
      "body": {
        "commonClientCode": data.parsedUserData[data.currentActiveData.accountNumber].CLIENT_ID,
        "occupationType": occupationType,
        "nameOfEmployer": occupationType.toLowerCase() == "salaried" ? inputChange : firmCompName,
        "loanAccountNoToMapFamily": familyMemberAccNo,
        "panNo": data.parsedUserData[data.currentActiveData.accountNumber].PAN_NO
      }
    }

    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA
    })

    ProfileServiceObject.upDateClientProfileService(requestObj).then((val) => {
      console.log(val);
      setLasFormSubmitModal(true)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    }).catch((error) => {

      setFailurePopUpContent({
        apiName: "",
        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
        errorCode: error
      })
      setFailurePopUpModal(true)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    })
  }
  const handleMobileClick = () => {
    if (!isMobile) {
      return;
    } else {
      setIsActive({
        profile: false,
        bank: false,
        password: false,
        help: false,
        branch: false,
        terms: false,
        about: false,
      });
      navigate("/profile", { state: { redirectionForm: "" } });
    }
  };
  const creatAddressString = (addressArray) => {
    let str = ""
    if (systemFlag === "retail") {
      addressArray.map((val) => {
        if (currentContract[val]) {
          str += " " + currentContract[val]
        }
      })
    } else {
      addressArray.map((val) => {
        str += " " + currentContract.cdiContract[val]
      })
    }
    return str;
  }
  const handleDropDownInputChange = (e) => {
    setInputChange(e.target.value)
    setDropDownState(true)
  }
  const setDropDownValue = (val) => {
    setInputChange(val)
    setDropDownState(false)
  }
  const setFirmCompNameValue = (e) => {
    setFirmCompName(e.target.value)
  }

  const setFamilyMemberAccNoTCFSL = (e) => {
    setFamilyMemberAccNo(e.target.value)
  }

  const lasOptionArray = [
    { value: "Salaried" },
    { value: "Self Employee" }
  ]


  const profilDetailsSwitchCase = () => {
    switch (systemFlag) {
      case "retail":
        return (
          Data.retailProfileArray.othersData.map((value, index) => {
            return (<div key={index} className="stepper-circle-component-container">
              <div
                className={`stepper-circle ${bankStyle ? "bankstyle" : ""}`}
                style={{
                  border: index % 2 !== 0 ? "1px solid #dedc00" : "",
                }}
              ></div>
              <ProfileDetailsComponent
                portalType={portalType}
                img={value.edite}
                redirectTo={value["edit-redirect"]}
                label={value.label}
                detail={value.apiKey === "DOB" ? commonFunctionObject.convertDDMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(currentContract[value.apiKey].split(" ")[0]))) : Array.isArray(value.apiKey) ? creatAddressString(value.apiKey) : currentContract[value.apiKey]}
                lastChild={Data.retailProfileArray.othersData.length - 1 === index ? true : false}
                closeAcc={commonFunctionObject.checkActiveStatus(data?.getLoanPositionDTLS[data.currentActiveData.accountNumber]) === "active" ? true : false}
              />
            </div>)
          })
        )
      case "ccod":
        let firstName = "";
        let lastName = "";
        if (data?.currentActiveData.system.toLowerCase() === "ccod") {
          var name = data.parsedUserData?.[data?.currentActiveData?.accountNumber].cdiContract.CUSTOMER_NAME.split(" ");
          if (name.length === 3) {
            firstName = name[0]
            lastName = name[2]
          } else if (name.length === 2) {
            firstName = name[0];
            lastName = name[1];
          } else {
            firstName = data.parsedUserData?.[data?.currentActiveData?.accountNumber].cdiContract.CUSTOMER_NAME;
            lastName = "";
          }
        }
        return (
          Data.ccodProfileDetails.othersData.map((value, index) => {
            return (<div key={index} className="stepper-circle-component-container">
              <div
                className={`stepper-circle ${bankStyle ? "bankstyle" : ""}`}
                style={{
                  border: index % 2 !== 0 ? "1px solid #dedc00" : "",
                }}
              ></div>
              <ProfileDetailsComponent
                portalType={portalType}
                img={value.edite}
                redirectTo={value["edit-redirect"]}
                label={value.label}
                detail={value.label === "First Name" ? firstName : value.label === "Last Name" ? lastName : value.apiKey === "DOB" ? commonFunctionObject.convertDDMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(currentContract.cdiContract[value.apiKey].split(" ")[0]))) : Array.isArray(value.apiKey) ? creatAddressString(value.apiKey) : currentContract.cdiContract[value.apiKey]}
                lastChild={Data.retailProfileArray.othersData.length - 1 === index ? true : false}
              />
            </div>)
          })
        )
      case "las":
        return (
          <div>
            {Data.lasProfileDetails.othersData.map((obj, index) => (
              <div key={index} className="stepper-circle-component-container">
                <div
                  className={`stepper-circle ${bankStyle ? "bankstyle" : ""}`}
                  style={{
                    border: index % 2 !== 0 ? "1px solid #dedc00" : "",
                  }}
                ></div>
                <ProfileDetailsComponent
                  portalType={portalType}
                  label={obj.label}
                  detail={currentContract[obj.apiKey]}
                  img={obj.edite}
                  bankStyle={bankStyle}
                  lastChild={Data.retailProfileArray.othersData.length - 1 === index ? true : false}

                />
              </div>
            ))}
            <div className="stepper-circle-component-container Family">
              <div
                className={`stepper-circle ${bankStyle ? "bankstyle" : ""}`}
                style={{
                  border: 1 % 2 !== 0 ? "1px solid #dedc00" : "",
                }}
              > </div>
              <div className="input-bank-las-details">
                <label>If Family Member having LAS with TCFSL enter loan account no</label>
                <input onChange={setFamilyMemberAccNoTCFSL} className="las-input" placeholder="" value={familyMemberAccNo} />
              </div>

            </div>
            <div className="stepper-circle-component-container">
              <div
                className={`stepper-circle ${bankStyle ? "bankstyle" : ""}`}
                style={{
                  border: 1 % 2 !== 0 ? "1px solid #dedc00" : "",
                }}
              > </div>
              <ProfileDetailsComponent
                portalType={portalType}
                optionArray={lasOptionArray}
                label={"Salaried/Self employed"}
                detail={[]}
                bankStyle={bankStyle}
                lastChild={true}
                employmentType={employmentType}
                mobileColumn={true}
                setEmployementType={setEmployementType}
                customeSelectOption="Salaried/Self employed"
                setOccupationType={setOccupationType}
              />
            </div>

            {
              employmentType === "Salaried" || employmentType === "Self Employee" ? employmentType === "Salaried" ? <div className="stepper-circle-component-container">
                <div
                  className={`stepper-circle ${bankStyle ? "bankstyle" : ""}`}
                  style={{
                    border: 1 % 2 !== 0 ? "1px solid #dedc00" : "",
                  }}
                > </div>
                <div className="input-bank-las-details salary">
                  <label>Name of Employer</label>
                  <div className="employer-dropdown">
                    <input onChange={handleDropDownInputChange} placeholder="Name of Employer" value={inputChange} />
                    {
                      inputChange && dropdownState ? <div className="salary-dropdown">
                        {
                          companylist.filter(val => val.includes(inputChange)).map(val => <p onClick={() => setDropDownValue(val)}>{val}</p>)
                        }
                      </div> : ""
                    }
                  </div>
                </div>
              </div> : <div className="stepper-circle-component-container">
                <div
                  className={`stepper-circle ${bankStyle ? "bankstyle" : ""}`}
                  style={{
                    border: 1 % 2 !== 0 ? "1px solid #dedc00" : "",
                  }}
                > </div>
                <div className="input-bank-las-details">
                  <label>Firm or Company Name</label>
                  <input onChange={setFirmCompNameValue} placeholder="Firm or Company Name" className="las-input" value={firmCompName} />
                </div>

              </div> : ""
            }

          </div>
        )
        break
      default:
        return ""
    }
  }
  const bankDetaisSwitchCase = () => {
    switch (systemFlag) {
      case "retail":
        return (
          Data?.retailbankingDetails?.othersData.map((value, index) => {
            return (<div key={index} className="stepper-circle-component-container">
              <div
                className={`stepper-circle ${bankStyle ? "bankstyle" : ""} `}
                style={{
                  border: index % 2 !== 0 ? "1px solid #dedc00" : "",
                }}
              ></div>
              <ProfileDetailsComponent
                portalType={portalType}
                img={value.edite}
                label={value.label}
                detail={Array.isArray(value.apiKey) ? creatAddressString(value.apiKey) : bankingDetails && bankingDetails[selectedOption.split(" ")[0]] && bankingDetails[selectedOption.split(" ")[0]][value.apiKey]}
                lastChild={Data.retailbankingDetails.othersData.length - 1 === index ? true : false}
                bankStyle={bankStyle}
              />
            </div>)
          })
        )
      case "ccod":
        return (
          <div>
            {Data.cccodbankingDetails.othersData?.map((value, index) => {
              return (<div key={index} className="stepper-circle-component-container">
                <div
                  className={`stepper-circle ${bankStyle ? "bankstyle" : ""}`}
                  style={{
                    border: index % 2 !== 0 ? "1px solid #dedc00" : "",
                  }}
                ></div>
                <ProfileDetailsComponent
                  portalType={portalType}
                  img={value.edite}
                  label={value.label}
                  detail={Array.isArray(value.apiKey) ? creatAddressString(value.apiKey) : bankingDetails && bankingDetails[selectedOption.split(" ")[0]] && bankingDetails[selectedOption.split(" ")[0]][value.apiKey]}
                  lastChild={bankingDetails && bankingDetails[selectedOption.split(" ")[0]] && bankingDetails[selectedOption.split(" ")[0]][value.apiKey].length - 1 === index ? true : false}
                />
              </div>)
            })}
            <div className="stepper-circle-component-container ccod-bank">
              <div
                className={`stepper-circle ${bankStyle ? "bankstyle" : ""}`}
                style={{
                  border: 1 % 2 !== 0 ? "1px solid #dedc00" : "",
                }}
              > </div>
              <ProfileDetailsComponent
                portalType={portalType}
                optionArray={bankingDetails && bankingDetails[selectedOption.split(" ")[0]] && Array.isArray(bankingDetails[selectedOption.split(" ")[0]].BANK_ACC_NO) ? bankingDetails[selectedOption.split(" ")[0]].BANK_ACC_NO.map((val) => ({
                  value: val
                })) : bankingDetails && bankingDetails[selectedOption.split(" ")[0]] && [{ value: bankingDetails[selectedOption.split(" ")[0]].BANK_ACC_NO }]}
                label={"Account Number"}
                detail={[]}
                bankStyle={bankStyle}
                lastChild={true}
                ccodAccount={true}
                customeSelectOption="Please Select"
              // customeSelectOption={bankingDetails && bankingDetails[selectedOption] && bankingDetails[selectedOption].BANK_ACC_NO[0]}
              />
            </div>
          </div>
        )
    }
  }

  return (
    <>
      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
      <RadiantBorder className="profile-details">
        <div className="profile-details-container">
          <div className="profile-details-heading-account-container">
            <p className={`profile-details-heading ${systemFlag === "las" ? "profile-details-las-heading" : ""}`} onClick={handleMobileClick}>
              {isMobile && (
                <img
                  src="assets/images/backArrow.svg"
                  alt="back-arrow"
                  className="back-arrow-image"
                />
              )}
              {systemFlag === "las" ? "LAS Details" : heading}
            </p>
            <AccountNumberDropDown
              optionArray={data?.accountNumberDropDownArray}
              optionState={optionState}
              setOptionState={setOptionState}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              labelFlag={true}
              labelText={"Account Number"}
              bankLas={bankLas}
              closeAcc={closeAcc}
              storeSelectAccNumberData={storeSelectAccNumberData}
            />
          </div>
          <div className="main-stepper-container">
            <div className="container-stepper">
              <div
                className={`container-stepper-inner ${systemFlag === "las" ? "container-stepper-las-inner" : ""}  ${bankStyle ? "bankstyle" : ""
                  } ${employmentType ? "employement" : ""} ${systemFlag === "ccod" ? "ccod" : ""}`}
              ></div>
            </div>

            <div className="stepper-right-components">
              {
                typeFlag === "profile" ?
                  profilDetailsSwitchCase() : bankDetaisSwitchCase()
              }
            </div>
          </div>
          {
            systemFlag === "las" ? <div className="btn-container"><Button onClick={submitProfileData} className="las-btn">Submit</Button></div> : ""
          }
          {lasFormSubmitModal ?
            <Modal>
              <div className='insurance-modal-popup' >
                <div className='close-btn' onClick={() => setLasFormSubmitModal(false)}>
                  {/* <img src="assets/images/crossImg.svg" alt="close"  /> */}
                </div>
                <div className='description'>
                  <p>
                    Thank you, details has been updated successfully.
                  </p>
                </div>
                <div className='proceed'>
                  <a onClick={() => setLasFormSubmitModal(false)} style={{ padding: "10px 50px", justifyContent: "center", top: "10%" }} href='javascript:void(0)'>Ok</a>
                </div>
              </div>
            </Modal> : null}
        </div>
      </RadiantBorder>
    </>
  );
};

ProfileDetails.prototypes = {
  heading: PropTypes.string,
  bankStyle: PropTypes.bool,
  isMobile: PropTypes.bool,
  setIsActive: PropTypes.func,
  optionState: PropTypes.bool,
  setOptionState: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func,
  storeSelectAccNumberData: PropTypes.func,
  systemFlag: PropTypes.string,
  currentContract: PropTypes.object,
  typeFlag: PropTypes.string,
  bankingDetails: PropTypes.object,
  bankLas: PropTypes.bool,
  closeAcc: PropTypes.bool,
  companylist: PropTypes.array
}
export default ProfileDetails;
