import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getDigiAuthUrl = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "state": "MTI4OjoxMDAwMDo6MmI3ZDYxNzQ2OTFkMzdmNzU4OTgyMzQ4MzNiNTk1Y2M6OmE0Yzk3OThhYjA1MDJiOGE0NTQ4MTczMmI5YTUwMzU0OjpsejlrUzVDbjNLa1dWL3ZyR1NzTUZ3PT0="
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/digilocker-internal/get-digi-auth-url/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/digilocker-internal/get-digi-auth-url/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getDigiAuthUrl;