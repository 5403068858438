import isEqVal from './isEqualByValue';

export default function StringToCamelCase(str) {
    return str.split(/[_-]|\s+/).map(function (word, index) {/*
                // If it is the first word make sure to lowercase all the chars.
                if (isEqVal(index,0)) {
                    return word.toLowerCase();
                }
                // If it is not the first word only upper case the first char and lowercase the rest.
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                */
        return isEqVal(index, 0) ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join('');
};