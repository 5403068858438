import React, { useEffect, useRef, useState } from "react";
import "./ProfileEdit.scss";
import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import ProfileCtaContainer from "../../container/ProfileCtaContainer";
import ReUsablesCta from "../../global/ReUsablesCta";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import Modal from "../../global/Modal";
import Button from "../../global/Button";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import userData from "../../screens/Overview/Data";
import DataMapping from "../../../../Shared/context/data-mapping";
import ProfileServiceObject from "../../services/Profile";
import uploadProfilePic from "../../apis/upload-profile-pic/upload-profile-pic";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import commonFunctionObject from "../../common-function";
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import PropTypes from 'prop-types';

const ProfileEdit = ({ portalType, active, setIsActive, handleClick, systemFlag, ProfileImg, lastLogin, gcid, userName, selectedOption }) => {
  const profileRef = useRef();
  const bankRef = useRef();
  const passwordRef = useRef();
  const helpRef = useRef();
  const branchRef = useRef();
  const termsRef = useRef();
  const aboutRef = useRef();
  const [themeState] = useContext(ThemeContext);
  const [editPhoto, setEditPhoto] = useState(false);
  const [data, dispatchData] = useContext(DataMapping)
  const [profilInput, setProFileInput] = useState();
  const [uploadError, setUploadError] = useState("");
  const [validation, setValidation] = useState(false);
  const { analytics } = useContext(AnalyticsContextProvider);
  const [profilePic, setProfilePic] = useState(ProfileImg)
  var bankFlag = (systemFlag === "las" || (systemFlag === "retail" && !commonFunctionObject.checkContractStatus(data?.getLoanPositionDTLS[selectedOption.split(" ")[0]].body.getLoanPositionDtlsReturn.loanStatus))) ? false : true;
  //const [bankClickFlag, setBankClickFlag] = useState(bankFlag)
  const [mainProfilePic, setMainProfilePic] = useState("")
  useEffect(() => {
    setProfilePic(ProfileImg)
    // setMainProfilePic(ProfileImg)
  }, [ProfileImg])

  const handleChange = (file) => {
    setProFileInput(file)
    let allowedExtension = ["jpg", "png", "jpeg"];
    if (file?.name) {
      let extension = file.name.split(".")
      if (file.size <= 54000) {
        if (allowedExtension.includes(extension[extension.length - 1].toLowerCase())) {
          setValidation(true)
          setUploadError("")
        } else {
          setUploadError("Please Select Supported Type")
          setValidation(false)
        }
      } else {
        setUploadError("File Size Must Be Less Than 50kb")
        setValidation(false)
      }
    } else {
      setUploadError("Please Select File")
      setValidation(false)
    }
  };

  const profileClickAnalytics = (event, name, ref, eventName) => {
    if (event && ref) {
      handleClick(event, name, ref)
    }
    var accountNumber = data?.currentActiveData.accountNumber;
    var currentUserData = data?.parsedUserData[accountNumber];
    var gcid = currentUserData.GCID;
    let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: gcid,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: eventName,
        componentName: "ProfileEdit",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "",
        eventName: eventName
      }
    }
    analytics.trackData("profiledetailInteraction", analytics_data);
  }

  const handldeSumbit = (event) => {

    if (validation && uploadError === "" && profilInput) {
      dispatchData({
        name: "loaderFlag",
        payload: true,
        type: STOREDATA
      })
      ProfileServiceObject.upLoadProfilePicService(profilInput, gcid, data?.authToken).then((value) => {
        if (value?.image) {
          let userData = data?.getUserDataV3
          userData.body.PROFILE_PIC = value?.image
          dispatchData({
            type: STOREDATA,
            name: "getUserDataV3",
            payload: userData
          });

          setProfilePic(value?.image)
          setMainProfilePic("data:image/png;base64," + value?.image)
          setEditPhoto(false)

        } else if (value?.apiFailure) {
          setUploadError("somthing went wrong")


        } else {

        }

        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA
        })
        setProFileInput(null)

      }).catch((value) => {

        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA
        })

      })
    } else if (profilInput) {
      if (profilInput.name !== "") {

      } else {
        setUploadError("Please Select File")
      }
    }
    else {
      setUploadError("Please Select File")
    }
  }

  //console.log(bankClickFlag)
  return (
    <ProfileCtaContainer>
      {editPhoto ? (
        <Modal>
          <div
            className="success-modal-container profile-edit"
            style={{
              background: themeState === THEME_DARK ? "#2e2f36" : "",
              color: themeState === THEME_DARK ? "#fff" : "#000",

            }}
          >
            <p className="upload-files-heading">Upload your profile picture</p>
            <div className="upload-bar-cont">
              <div
                className="upload-bar"
                style={{
                  background: `${themeState === "THEME_DARK" ? "#22232E" : ""}`,
                }}
              >
                <FileUploader handleChange={handleChange}>
                  <p
                    style={{
                      color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                    }}
                  >
                    {profilInput ? profilInput.name : "Click here to choose file to upload"}
                  </p>
                </FileUploader>
              </div>
              {uploadError ? <p style={{ color: "red", alignSelf: "flex-start" }}>{uploadError}</p> : null}
              <Button
                className={"file-submit-button"}
                style={{ width: "100%" }}
                onClick={handldeSumbit}
              >
                Submit
              </Button>
              <img
                onClick={() => setEditPhoto(false)}
                className="success-cross-image"
                src="assets/images/crossImg.svg"
                alt="close-icon"
              />
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="profile_edit_container">
        {portalType === 'collection' ? 
        null : 
        <img
          style={{
            filter: `${themeState === "THEME_DARK" ? "invert(1)" : ""}`,
          }}
          src="assets/images/editIcon.svg"
          alt="edit-icon"
          className="edit-icon"
          onClick={() => setEditPhoto(true)}
        />}

        <div className="profile_edit_cta_holder">
          <div className="profile_edit_profile_image_container">
            <div className="profile_edit_profile_image_container-circle">
              <img
                src={mainProfilePic && mainProfilePic !== "" ? mainProfilePic : data?.getUserDataV3?.body.PROFILE_PIC && data?.getUserDataV3.body.PROFILE_PIC !== "" ? "data:image/png;base64," + profilePic : profilePic}
                alt="profile-image"
                className="profile_edit_profile_image"
              />
            </div>
          </div>
          <div className="profile_edit_name_login_container">
            <p className="profile_edit_name capitalizedAphabets">{userName?.toLowerCase()}</p>
            <p className="profile_edit_login">
              Last Login : {lastLogin}
            </p>
          </div>
          {/* <div className="profile_edit_account_miscellaneous_main_container"> */}
          <div className="profile_edit_account_settings_container">
            <p className="profile_edit_account_label">Account Settings</p>
            <div
              ref={profileRef}
              className={`profile_edit_reusable_component_container ${active?.profile ? "active" : ""
                }`}
              onClick={(e) => profileClickAnalytics(e, "profile", profileRef, "Profile Details")
              }
            >
              <ReUsablesCta
                changeColor={active?.profile}
                label={"Profile Details"}
                image={"arrow-right.svg"}
                name="profile"
              />
            </div>
            <div
              ref={bankRef}
              className={`profile_edit_reusable_component_container ${active?.bank ? "active" : ""
                } ${!bankFlag ? "blockBank" : ""}`}
              onClick={(e) => profileClickAnalytics(e, "bank", bankRef, "Banking Details")
              }
            >
              <ReUsablesCta
                changeColor={active?.bank}
                label={"Banking Details"}
                image={"arrow-right.svg"}
                bankClickFlag={bankFlag}
                name="bank"
              />
            </div>
            <div
              ref={passwordRef}
              className={`profile_edit_reusable_component_container ${active?.password ? "active" : ""
                }`}
              onClick={(e) => profileClickAnalytics(e, "password", passwordRef, "Change Password")
              }
            >
              <ReUsablesCta
                lastChild={true}
                changeColor={active?.password}
                label={"Change Password"}
                image={"arrow-right.svg"}
                name="password"
              />
            </div>
          </div>
          <div className="profile_edit_miscellaneous_container">
            <p className="profile_edit_account_label">Miscellaneous</p>
            <div
              ref={helpRef}
              className={`profile_edit_reusable_component_container ${active?.help ? "active" : ""
                }`}
              onClick={(e) => profileClickAnalytics(e, "help", helpRef, "Help and FAQ")
              }
            >
              <ReUsablesCta
                changeColor={active?.help}
                label={"Help & Faq"}
                image={"arrow-right.svg"}
                name="help"
              />
            </div>
            <div
              ref={branchRef}
              className={`profile_edit_reusable_component_container ${active?.branch ? "active" : ""
                }`}
              onClick={(e) => profileClickAnalytics("", "", "", "Branch Locator")}
            >
              <a className="branch-link link" href="https://www.tatacapital.com/contact-us/branch-locator.html" target="_blank" >
                <ReUsablesCta
                  changeColor={active?.branch}
                  label={"Branch Locator"}
                  name="branch"
                // image={"arrow-right.svg"}
                /></a>
            </div>
            <div
              ref={termsRef}
              className={`profile_edit_reusable_component_container ${active?.terms ? "active" : ""
                }`}
              onClick={(e) => profileClickAnalytics("", "", "", "Terms and Conditions")}
            >
              <a className="branch-link link" href="assets/images/TermsandConditions.pdf" target="_blank">
                <ReUsablesCta
                  changeColor={active?.terms}
                  label={"Terms and Conditions"}
                  name="tnc"
                // image={"arrow-right.svg"}
                /></a>
            </div>
            <div
              ref={aboutRef}
              className={`profile_edit_reusable_component_container ${active?.about ? "active" : ""
                } last_child`}
              onClick={(e) => profileClickAnalytics("", "", "", "About Us")}
            >
              <a className="branch-link link" href="https://www.tatacapital.com/about-us.html" target="_blank">
                <ReUsablesCta
                  changeColor={active?.about}
                  lastChild={true}
                  label={"About Us"}
                  name="aboutus"
                // image={"arrow-right.svg"}
                />
              </a>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </ProfileCtaContainer>
  );
};

ProfileEdit.prototypes = {
  active: PropTypes.object,
  handleClick: PropTypes.func,
  systemFlag: PropTypes.string,
  ProfileImg: PropTypes.string,
  lastLogin: PropTypes.string,
  gcid: PropTypes.string,
  userName: PropTypes.string,
  selectedOption: PropTypes.string
}


export default ProfileEdit;
