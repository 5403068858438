import withPassword from "../../apis/with-password";
import getUserDataV3 from "../../apis/get-user-data-v3/get-user-data-v3";
import userLoginType from "../../apis/user-login-type/user-login-type";
import AesUtil from "../../utilities/AesUtil";
import generateOtp from "../../apis/generate-otp";
import validateOtp from "../../apis/validate-otp/validate-otp";
import getUserId from "../../apis/get-user-id/get-user-id";
import getUserContact from "../../apis/get-user-contact/get-user-contact";
import changePasswordPreLogin from "../../apis/change-password-pre-login/change-password-pre-logic";
import generateVoiceOtp from "../../apis/generate-voice-otp";
import commonFunctionObject from "../../common-function";
import generateCaptcha from "../../apis/generate-capcha/generate-captcha";
import getofferCollectionData from "../../apis/get-offer-details/get-collection-offer-details";
import getMinSettlemetAmountRes from "../../apis/calculate-minsettlement-amount";
import getNormaliseEmiOverdueRes from "../../apis/calculate-emi-waiver-amount";
import getODBCWaiverAmountRes from "../../apis/calculate-odbc-waiver-amount";
import getUpdatedSettlementDetails from "../../apis/update-settlement-offer-details/update-settlement-offer-details";

const LoginServiceObject = {};

const withPasswordService = (withPasswordRequest) => {
  return new Promise((resolve, reject) => {
    withPassword(withPasswordRequest)
      .then((withPasswordResponse) => {
        resolve(withPasswordResponse);
      })
      .catch((error) => {
        reject("04");
      });
  });
};

LoginServiceObject.withPasswordService = withPasswordService;

const getUserDataV3Service = (authToken) => {
  return new Promise((resolve, reject) => {
    let getOfferDataRequest = {
      header: {
        authToken: authToken,
        browser: "Chrome",
      },
      body: {},
    };
    getUserDataV3(getOfferDataRequest)
      .then((getUserDataV3response) => {
        if (getUserDataV3response.header.status === "SUCCESS") {
          resolve(getUserDataV3response);
        } else {
          reject("11");
        }
      })
      .catch((error) => {
        reject("10");
      });
  });
};
LoginServiceObject.getUserDataV3Service = getUserDataV3Service;

const userLoginTypeService = (authToken) => {
  return new Promise((resolve, reject) => {
    let userLoginTypeRequest = {
      header: {
        authToken: authToken,
      },
      body: {},
    };
    userLoginType(userLoginTypeRequest)
      .then((userLoginTypeResponse) => {
        if (userLoginTypeResponse.header.status === "SUCCESS") {
          resolve(userLoginTypeResponse);
        }
      })
      .catch((error) => {
        reject("101");
      });
  });
};
LoginServiceObject.userLoginTypeService = userLoginTypeService;

const callGererateOtpService = (requestJson, serviceType) => {
  return new Promise((resolve, reject) => {
    generateOtp(requestJson)
      .then((generateOtpResponse) => {
        console.log(generateOtpResponse);
        if (generateOtpResponse.header.status.toUpperCase() === "SUCCESS") {
          if (generateOtpResponse.body.retStatus.toUpperCase() === "SUCCESS") {
            resolve(generateOtpResponse);
          } else {
          }
        } else if (
          generateOtpResponse.header.status.toUpperCase() === "FAILURE"
        ) {
          if (Object.keys(generateOtpResponse.body).length > 0) {
            if (
              generateOtpResponse.body.message
                .toLowerCase()
                .search("invalid captcha") !== -1
            ) {
              reject("invalid captcha");
            } else if (
              generateOtpResponse.body.message
                .toLowerCase()
                .search("captcha expired") !== -1
            ) {
              reject("captcha expired");
            } else if (
              generateOtpResponse.body.message
                .toLowerCase()
                .includes("exceeded")
            ) {
              reject("number of attempts exceeded");
            } else {
              reject("captcha error");
            }
          } else {
            if (generateOtpResponse.errorBody) {
              if (generateOtpResponse.errorBody.message) {
                if (
                  generateOtpResponse.errorBody.message
                    .toLowerCase()
                    .search("empty user data") !== -1
                ) {
                  if (serviceType === "mobile") {
                    reject(
                      "Please Provide Your Registered Mobile No/E-Mail ID"
                    );
                  } else {
                    reject(
                      "Please Provide Your Registered Mobile No/E-Mail ID"
                    );
                  }
                } else {
                  reject("02");
                }
              } else {
                reject("02");
              }
            } else {
              reject("02");
            }
          }
        }
      })
      .catch((error) => {
        reject("02");
      });
  });
};

LoginServiceObject.callGererateOtpService = callGererateOtpService;

const validateOtpService = (authToken, otp, refNo, userInput) => {
  return new Promise((resolve, reject) => {
    let requestObj;
    if (userInput.search("@") > -1) {
      requestObj = {
        header: {
          grantType: "otp",
          serviceType: "email",
          authToken: authToken,
          context: "login",
        },
        body: {
          emailId: userInput,
          otpRef: refNo,
          otp: otp,
        },
      };
    } else {
      requestObj = {
        header: {
          grantType: "otp",
          serviceType: "mobile",
          authToken: authToken,
          context: "login",
        },
        body: {
          mobileNo: userInput,
          otpRef: refNo,
          otp: otp,
        },
      };
    }
    validateOtp(requestObj)
      .then((validateOtpResponse) => {
        if (validateOtpResponse.header.status.toUpperCase() === "SUCCESS") {
          if (validateOtpResponse.body.retStatus.toUpperCase() === "SUCCESS") {
            resolve(validateOtpResponse);
          } else {
            //todo
          }
        } else {
          reject("otp invalid");
        }
        resolve(validateOtpResponse);
      })
      .catch((error) => {
        reject("03");
      });
  });
};
LoginServiceObject.validateOtpService = validateOtpService;

const getUserIdService = (authToken, userInput) => {
  return new Promise((resolve, reject) => {
    let requestObj;
    if (userInput.search("@") > -1) {
      requestObj = {
        header: {
          authToken: authToken,
        },
        body: {
          emailId: userInput,
        },
      };
    } else {
      requestObj = {
        header: {
          authToken: authToken,
        },
        body: {
          mobileNo: userInput,
        },
      };
    }
    getUserId(requestObj)
      .then((getUserIdResponse) => {
        if (getUserIdResponse.header.status === "SUCCESS") {
          resolve(getUserIdResponse);
        }
      })
      .catch((error) => {
        reject();
      });
  });
};
LoginServiceObject.getUserIdService = getUserIdService;

const getUserContactService = (formDetails) => {
  return new Promise((resolve, reject) => {
    if (formDetails.valid === true) {
      var requestJSON = {
        header: {},
        body: {
          username: formDetails.value.userId,
        },
      };
      getUserContact(requestJSON)
        .then((getUserContactResponse) => {
          if (getUserContactResponse.header.status === "SUCCESS") {
            resolve(getUserContactResponse);
          } else {
            reject("FAILURE");
          }
        })
        .catch((error) => {
          reject("05");
        });
    }
  });
};

LoginServiceObject.getUserContactService = getUserContactService;

const changePreLoginPasswordService = (userID, password, rePassword) => {
  return new Promise((resolve, reject) => {
    changePasswordPreLogin({
      header: {
        firstLogin: true,
      },
      body: {
        userId: userID,
        newPassword: AesUtil.encryptData(password),
        confirmPassword: AesUtil.encryptData(rePassword),
      },
    }).then((changePassworPreLoginResponse) => {
      if (changePassworPreLoginResponse.header.status === "SUCCESS") {
        if (changePassworPreLoginResponse.body.status === true) {
          resolve(changePassworPreLoginResponse);
        } else if (changePassworPreLoginResponse.body.status === false) {
          reject("Password mismatch");
        }
      } else {
        reject("07");
      }
    });
  });
};

LoginServiceObject.changePreLoginPasswordService =
  changePreLoginPasswordService;

const generateVoiceOtpService = (authToken, userInput, refNo) => {
  return new Promise((resolve, reject) => {
    let requestObj = {
      header: {
        authToken: authToken,
      },
      body: {
        mobileNo: userInput,
        otpRef: refNo,
      },
    };

    generateVoiceOtp(requestObj)
      .then((generateVoiceOtpResponse) => {
        if (
          generateVoiceOtpResponse.header.status.toUpperCase() === "SUCCESS"
        ) {
          if (
            generateVoiceOtpResponse.body.status.toUpperCase() === "SUCCESS"
          ) {
            resolve(generateVoiceOtpResponse);
          } else {
            reject("101");
          }
        } else {
          if (
            generateVoiceOtpResponse.body.message
              .toLowerCase()
              .includes("exceeded")
          ) {
            reject("number of attempts exceeded");
          } else {
            reject("101");
          }
        }
      })
      .catch((error) => {
        reject("100");
      });
  });
};
LoginServiceObject.generateVoiceOtpService = generateVoiceOtpService;

const commonLoginAfterUserData = (
  getUserDataV3Response,
  loginType,
  userInput,
  portalType
) => {
  let mobileNo = "";
  let custName = "";
  // getUserDataV3Response.body.FIRST_NAME + " " + getUserDataV3Response.body.LAST_NAME
  let parsedUserData = {};
  if (loginType !== "mobNum") {
    if (
      getUserDataV3Response.body.primarySystem.SYSTEM_NAME.toLowerCase() ===
      "las"
    ) {
      getUserDataV3Response.body.otherSystemDetails.forEach((obj) => {
        if (obj.SYSTEM_NAME.toLowerCase() === "las") {
          mobileNo = obj.MOBILE_NO;
          custName = obj.FIRST_NAME + " " + obj.LAST_NAME;
        } else {
          console.log("system not found");
        }
      });
    } else if (
      getUserDataV3Response.body.primarySystem.SYSTEM_NAME.toLowerCase() ===
      "ccod"
    ) {
      getUserDataV3Response.body.otherSystemDetails.forEach((obj) => {
        if (obj.SYSTEM_NAME.toLowerCase() === "ccod") {
          mobileNo = obj.ccodContractDetails[0].sapContract.MOBILE;
          custName = obj.ccodContractDetails[0].cdiContract.CUSTOMER_NAME;
        } else {
          console.log("system not found");
        }
      });
    } else {
      mobileNo = commonFunctionObject.getLastestFieldValFromField(
        getUserDataV3Response.body.mobiles
      );
      custName =
        getUserDataV3Response.body.FIRST_NAME +
        " " +
        getUserDataV3Response.body.LAST_NAME;
    }
  } else {
    mobileNo = userInput;
    if (
      getUserDataV3Response.body.primarySystem.SYSTEM_NAME.toLowerCase() ===
      "las"
    ) {
      getUserDataV3Response.body.otherSystemDetails.forEach((obj) => {
        if (obj.SYSTEM_NAME.toLowerCase() === "las") {
          custName = obj.FIRST_NAME + " " + obj.LAST_NAME;
        } else {
          console.log("system not found");
        }
      });
    } else if (
      getUserDataV3Response.body.primarySystem.SYSTEM_NAME.toLowerCase() ===
      "ccod"
    ) {
      getUserDataV3Response.body.otherSystemDetails.forEach((obj) => {
        if (obj.SYSTEM_NAME.toLowerCase() === "ccod") {
          custName = obj.ccodContractDetails[0].cdiContract.CUSTOMER_NAME;
        } else {
          console.log("system not found");
        }
      });
    } else {
      custName =
        getUserDataV3Response.body.FIRST_NAME +
        " " +
        getUserDataV3Response.body.LAST_NAME;
    }
  }

  getUserDataV3Response?.body?.contracts?.forEach((loanData) => {
    loanData["system"] = "retail";
    let contractNumber =
      portalType === "collection"
        ? loanData.CONTRACT_NUMBER
        : commonFunctionObject.appendZeros(loanData.CONTRACT_NUMBER);
    parsedUserData[contractNumber] = loanData;
  });

  getUserDataV3Response?.body?.otherSystemDetails.forEach((loanData) => {
    if (loanData.SYSTEM_NAME.toLowerCase() === "ccod") {
      loanData.ccodContractDetails.forEach((ccodcontracts) => {
        ccodcontracts["system"] = "ccod";
        parsedUserData[ccodcontracts.sapContract.CUSTOMER_NO] = ccodcontracts;
      });
    }
  });

  let finalObjectForContext = {
    mobileNumber: mobileNo,
    customerName: custName,
    parsedUserData: parsedUserData,
  };

  return finalObjectForContext;
};
LoginServiceObject.commonLoginAfterUserData = commonLoginAfterUserData;

const generateCaptchaService = (generateCaptchaRequest) => {
  return new Promise((resolve, reject) => {
    generateCaptcha(generateCaptchaRequest)
      .then((generateCaptchaResponse) => {
        if (generateCaptchaResponse.header.status === "SUCCESS") {
          resolve(generateCaptchaResponse.body);
        } else {
          reject("Captcha Cannot Be Generated");
        }
      })
      .catch((error) => {
        reject(
          "We are experiencing technical difficulty in captcha services. Please try after sometime"
        );
      });
  });
};
LoginServiceObject.generateCaptchaService = generateCaptchaService;

const offerCollectionDetails = (authToken, userData) => {
  return new Promise((resolve, reject) => {
    let getOfferDataRequest = {
      header: {
        authToken: authToken,
        browser: "Chrome",
      },
      body: {
        lanDetails: userData,
      },
    };
    getofferCollectionData(getOfferDataRequest)
      .then((getOfferData) => {
        if (getOfferData.header.status === "SUCCESS") {
          // console.log(getOfferData, "getOfferData");
          resolve(getOfferData);
        } else {
          reject("11");
        }
      })
      .catch((error) => {
        reject("10");
      });
  });
};
LoginServiceObject.offerCollectionDetails = offerCollectionDetails;

const calMinSettlementAmount = (data, paymentData, settlementAmount) => {
  // console.log(data, paymentData, settlementAmount,"data, paymentData, settlementAmount");
  let productNames = {};

  return new Promise((resolve, reject) => {
    let getMinSettlementRequest = {
      header: {
        authToken: data?.authToken,
        company: paymentData["ENTITY"],
      },
      body: {
        loanAccountNo: paymentData["LOAN_ACCOUNT_NO"],
        bucket: paymentData["BKT"],
        product: paymentData["PRODUCT"],
        settlementAmount: settlementAmount,
      },
    };
    getMinSettlemetAmountRes(getMinSettlementRequest)
      .then((getMinSettlementResponse) => {
        if (getMinSettlementResponse.header.status === "SUCCESS") {
          // console.log(getMinSettlementResponse,"getMinSettlementResponse");
          resolve(getMinSettlementResponse);
        } else {
          reject("11");
        }
      })
      .catch((error) => {
        reject("10");
      });
  });
};
LoginServiceObject.calMinSettlementAmount = calMinSettlementAmount;

const setUpdatedSettlementDetails = (data, paymentData, isTrancheOpted, noOfTranchesOpted, settlementApprovedAmount) => {
  // console.log(data, paymentData, settlementAmount,"data, paymentData, settlementAmount");
  console.log(data,"data");
  let productNames = {};

  return new Promise((resolve, reject) => {
    let getMinSettlementRequest = {
      header: {
        authToken: data?.authToken,
        company: paymentData["ENTITY"],
      },
      body: {
        loanAccountNo: paymentData["LOAN_ACCOUNT_NO"],
        isTrancheOpted: isTrancheOpted,
        noOfTranchesOpted: noOfTranchesOpted,
        settlementAmount: settlementApprovedAmount,
      },
    };
    getUpdatedSettlementDetails(getMinSettlementRequest)
      .then((getUpdatedSettlementResponse) => {
        if (getUpdatedSettlementResponse.header.status === "SUCCESS") {
          // console.log(getUpdatedSettlementResponse,"getUpdatedSettlementResponse");
          resolve(getUpdatedSettlementResponse);
        } else {
          reject("11");
        }
      })
      .catch((error) => {
        reject("10");
      });
  });
};
LoginServiceObject.setUpdatedSettlementDetails = setUpdatedSettlementDetails;

const calculateNormaliseEmiOverdueRes = (data, paymentData, amount) => {
  // console.log(paymentData, "reqyesh");

  const emioverdueObject = paymentData.loanDetails.find(
    (item) => item.name === "EMI OVERDUE AMOUNT"
  );

  const chargesoverdueObject = paymentData.loanDetails.find(
    (item) => item.name === "CHARGES OVERDUE AMOUNT"
  );

  // console.log(chargesoverdueObject,"chargesoverdueObject");
  // console.log(emioverdueObject,"emioverdueObjectss");


  return new Promise((resolve, reject) => {
    let emiOverdueRequest = {
      header: {
        authToken: data?.authToken,
        company: paymentData?.offerData["ENTITY"],
      },
      body: {
        loanAccountNo: paymentData?.offerData["LOAN_ACCOUNT_NO"],
        bucket: paymentData?.offerData["BKT"],
        amount: amount,
        emiOverdueAmount: emioverdueObject.value,
        overdueCharges: chargesoverdueObject.value,
      },
    };
    getNormaliseEmiOverdueRes(emiOverdueRequest)
      .then((emiOverdueResponse) => {
        if (emiOverdueResponse.header.status === "SUCCESS") {
          // console.log(getMinSettlementResponse,"getMinSettlementResponse");
          resolve(emiOverdueResponse);
        } else {
          reject("11");
        }
      })
      .catch((error) => {
        reject("10");
      });
  });
};
LoginServiceObject.calculateNormaliseEmiOverdueRes =
  calculateNormaliseEmiOverdueRes;

const calculateODBCWaiverRes = (data, paymentData, amount, loanStatus) => {
  console.log(paymentData, "reqyesh");
  return new Promise((resolve, reject) => {
    let odbcOfferRequest = {
      header: {
        authToken: data?.authToken,
        company: paymentData?.offerData["ENTITY"],
      },
      body: {
        loanAccountNo: paymentData?.offerData["LOAN_ACCOUNT_NO"],
        // category: paymentData?.offerData["Category"],
        category: "B",
        loanStatus: "Live",
        amount: amount,
        odbcAmount: paymentData?.loanDetails[4]?.value,
      },
    };
    getODBCWaiverAmountRes(odbcOfferRequest)
      .then((odbcOfferResponse) => {
        if (odbcOfferResponse.header.status === "SUCCESS") {
          // console.log(getMinSettlementResponse,"getMinSettlementResponse");
          resolve(odbcOfferResponse);
        } else {
          reject("11");
        }
      })
      .catch((error) => {
        reject("10");
      });
  });
};
LoginServiceObject.calculateODBCWaiverRes = calculateODBCWaiverRes;

export default LoginServiceObject;
