import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const isDigiUser = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {}
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/db/is-digi-user/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/db/is-digi-user/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default isDigiUser;