import React, { useEffect, useState } from "react";
import './Tabs.scss'
import PropTypes from 'prop-types';

const Tabs = ({
    setTaborder,
    loginTab,
    setShowError
}) => {
    const [active, setActive] = useState(loginTab)

    const [tabArr, setTabArr] = useState([
        {
            title: 'Mobile Number',
            id: 'mobNum',
            tabStyle: 'c_active',
        },
        {
            title: 'Email ID',
            id: 'emailId',
            tabStyle: 'c_tab_style_two',
        },
        {
            title: 'User ID',
            id: 'userId',
            tabStyle: 'c_tab_style_three',
        },
    ])

    useEffect(() => {
        handleTabChanges();
        setTaborder(active)
    }, [active])

    
    const handleTabChanges = () => {
        setTabArr((oldState) => {
          const newState = [...oldState];
          if (active === 'mobNum') {
            newState[0].tabStyle = 'c_active';
            newState[1].tabStyle = 'c_tab_style_two';
            newState[2].tabStyle = 'c_tab_style_three';
          } else if (active === 'emailId') {
            newState[0].tabStyle = 'c_tab_style_two';
            newState[1].tabStyle = 'c_active';
            newState[2].tabStyle = 'c_tab_style_three';
          } else if (active === 'userId') {
            newState[0].tabStyle = 'c_tab_style_two';
            newState[1].tabStyle = 'c_tab_style_three';
            newState[2].tabStyle = 'c_active';
          } else {
            newState[0].tabStyle = '';
            newState[1].tabStyle = '';
            newState[2].tabStyle = '';
          }
          return newState;
        });
      };
      

    const handleTabClick = (id) => {
        setActive(() => id)
        setShowError(false)
    }

    return (
        <div className="c_tabs_container">
            <div className="c_border_gradient">
                <ul className={`c_tabs ${active}`}>
                    {
                        tabArr.map((tab) => <li key={tab.id} onClick={() => handleTabClick(tab.id)} className={`c_tab ${tab.tabStyle}`}>{tab.title}</li>)
                    }
                </ul>
            </div>
        </div>
    )
}

Tabs.propTypes = {
    setTaborder:PropTypes.func,
    setShowError:PropTypes.func,
    loginTab : PropTypes.string
}

export default Tabs