import isFunc from './isFunction';
import objKeys from './objectKeys';
import noopFunc from './noopFunc';


export default function defineObjectProperties(propContext, propsObj, fnGetNotifier, fnSetNotifier) {
    if (!isFunc(fnSetNotifier)) {
        fnSetNotifier = noopFunc;
    }

    var propertiesObj = {};
    objKeys(propsObj).forEach(function (key) {
        propertiesObj[key] = {
            get: isFunc(propsObj[key]) ? propsObj[key] : noopFunc,
            set: function (value) {
                fnSetNotifier(key, value);
            }
        }
    });
    return Object.defineProperties(propContext, propertiesObj);
}