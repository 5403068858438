import { Button } from 'antd'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import commonFunctionObject from '../../common-function'
import AnalyticsContextProvider from '../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider'
import DataMapping from '../../../../Shared/context/data-mapping'
import { STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type'
// import ThemeContext from '../../context/ThemeContext/store/ThemeContext'
import ThemeContext from '../../../../Shared/context/ThemeContext/store/ThemeContext'
import Input from '../../global/Input'
import Modal from '../../global/Modal'
import "./NocPopUp.scss";
import PropTypes from 'prop-types';

const NocPopUp = ({ userDetails, setemailPopup, showTriggerEmailPopUp, setFailurePopUpContent, setFailurePopUpModal, setNocOpenClosed }) => {
    const [contextData, dispatchData] = useContext(DataMapping)
    const [themeState] = useContext(ThemeContext);
    const [inputData, setInputData] = useState({})
    const [emailId, setEmailId] = useState(commonFunctionObject.getLastestFieldValFromField(userDetails.EMAIL))
    const [emailIdError, setEmailIdError] = useState("");
    const { analytics } = useContext(AnalyticsContextProvider)
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    useEffect(() => {
        var overdueprincipleAmount = contextData?.getLoanPositionDTLS[contextData?.currentActiveData?.accountNumber]?.body?.getLoanPositionDtlsReturn?.overdueprincipleAmount
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var finalDate = dd + '/' + mm + '/' + yyyy;
        var inputObject = {
            registeredEmailID: commonFunctionObject.getLastestFieldValFromField(userDetails.EMAIL),
            customerName: userDetails.FIRSTNAME + "" + userDetails.LASTNAME,
            requestDate: finalDate,
            product: userDetails?.productInfo?.DISPLAY_NAME ? userDetails?.productInfo?.DISPLAY_NAME : userDetails.PRODUCT,
            overdueAmount: commonFunctionObject.checkEmptyData(overdueprincipleAmount, "amount")
        }
        setInputData(inputObject)
    }, [])
    const handleSubmit = () => {
        var accountNumber = contextData?.currentActiveData.accountNumber;
        var currentUserData = contextData?.parsedUserData[accountNumber];
        let productName = contextData?.currentActiveData?.system === "las" ? "LAS" : contextData?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        let currentContract = contextData?.parsedUserData[contextData.currentActiveData.accountNumber];
        let productFamily = commonFunctionObject.getProductFamily(currentUserData);
        if (productName.toLowerCase() === "ccod") {
            var company = currentContract.cdiContract.COMPANY;
            var companyName = "";
            (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
        }
        else {
            var companyName = productFamily;
        }

        if (emailId.match(emailRegex)) {
            var requestObject = {
                "header": {
                    "authToken": contextData?.authToken,
                    //"productFamily": contractData.productValues.productInfo.PRODUCT_FAMILY,
                    "emailSent": true,
                },
                "body": {
                    "webtopId": userDetails.WEBTOP_NO,
                    "documentUploadType": "NDC",
                    "loanAccountNumber": userDetails.CONTRACT_NUMBER,
                }
            }
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA,
            });


            setNocOpenClosed(false);
            let analytics_data = {
                user: {
                    loginMethod: "",
                    loginStatus: "",
                    gcid: contextData?.getUserDataV3?.body?.PRIMARY_GCID,
                    loginType: "",
                    loanaccountNo: contextData?.currentActiveData?.accountNumber,
                    firstloginDate: ""
                },
                products: {
                    productCode: productName,
                },
                data: {
                    ctaText: "",
                    componentName: "Quick Links",
                    bannerTitle: "",
                    menuTitle: "",
                    faqTitle: "",
                    searchTerm: "",
                    lobActivity: companyName + ":" + productName,
                    status: "FAILURE",
                    eventName: "NOCDownload"
                }
            }
            analytics.trackData("NOCDownload", analytics_data)
            commonFunctionObject.extractDocWithoutSrCall(requestObject).then((value) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA,
                });
                setNocOpenClosed(false)
                var firstName = userDetails.FIRSTNAME;
                var lastName = userDetails.LASTNAME;
                var email = userDetails.EMAIL;

                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: contextData?.getUserDataV3?.body?.PRIMARY_GCID,
                        loginType: "",
                        loanaccountNo: contextData?.currentActiveData?.accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Quick Links",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "SUCCESS",
                        eventName: "NOCDownload"
                    }
                }
                analytics.trackData("NOCDownload", analytics_data)
                var dataObj = {
                    "documentName": "Noc",
                    "productFamily": "cfab",
                    "decscription": "Generate NOC letter for " + userDetails.CONTRACT_NUMBER + " loan account.",
                    "sebServiceRequestType": "nocLetter",
                    "webtopId": userDetails.WEBTOP_NO,
                }
                setemailPopup(true);
                //
                showTriggerEmailPopUp(
                    dataObj,
                    userDetails.CONTRACT_NUMBER,
                    value.body.isEmailAvailable,
                    commonFunctionObject.getLastestFieldValFromField(email),
                    firstName,
                    lastName
                );

            }).catch((error) => {

                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA,
                });
                setFailurePopUpContent({
                    type: "api-failure: generate-interest-certificate/v1",
                    message: "We are experiencing technical difficulty please try after sometime",
                    errorCode: error,
                });
                setFailurePopUpModal(true);
                setNocOpenClosed(false);
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: contextData?.getUserDataV3?.body?.PRIMARY_GCID,
                        loginType: "",
                        loanaccountNo: contextData?.currentActiveData?.accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "Quick Links",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: companyName + ":" + productName,
                        status: "FAILURE",
                        eventName: "NOCDownload"
                    }
                }
                analytics.trackData("NOCDownload", analytics_data)
            })
        } else {

        }
    }

    const handleChange = (e) => {
        if (emailRegex.test(e.target.value)) {
            setEmailId(e.target.value)
            setEmailIdError("")
        } else {
            setEmailIdError("Please Enter Your Valid Email Id")
            setEmailId(e.target.value)
        }

    }

    return (
        <div>
            <Modal>
                <div className={`noc-pop ${themeState}`}>
                    <div className='noc-head'>
                        <h2>Download NOC</h2>
                        <img
                            className="noc-popup-cancel-icon"
                            src="assets/images/cancel.svg"
                            alt="cancel-icon"
                            onClick={() => { setNocOpenClosed(false) }}
                        />
                    </div>
                    <div className='noc-body'>
                        <form>
                            <div className='noc-input input-container'>
                                <span>Registered Email ID</span>
                                <Input
                                    className={""}
                                    placeholder="Registered Email ID"
                                    value={emailId}
                                    disabled={true}
                                    error={emailIdError}
                                    id="reg-email"
                                    errorSpace={true}
                                    onChange={handleChange}
                                    editIcon={true}
                                />
                            </div>
                            <div className='noc-input input-container'>
                                <span>Customer Name</span>
                                <Input
                                    className={""}
                                    placeholder="Customer Name"
                                    value={inputData?.customerName}
                                    disabled={true}
                                    errorSpace={true}
                                />
                            </div>
                            <div className='noc-input input-container'>
                                <span>Request Date</span>
                                <Input
                                    className={""}
                                    placeholder="Request Date"
                                    value={inputData?.requestDate}
                                    disabled={true}
                                    errorSpace={true}
                                />
                            </div>
                            <div className='noc-input input-container'>
                                <span>Product</span>
                                <Input
                                    className={""}
                                    placeholder="Product"
                                    value={inputData?.product}
                                    disabled={true}
                                    errorSpace={true}
                                />
                            </div>
                            <div className='noc-input input-container'>
                                <span>Overdue Amount</span>
                                <Input
                                    className={""}
                                    placeholder="Overdue Amount"
                                    value={inputData?.overdueAmount}
                                    disabled={true}
                                    errorSpace={true}
                                />
                            </div>
                            <div className='noc-input input-container'>
                                <Button className='noc-input-button' onClick={handleSubmit} type={""}><p>Download</p><img src="assets/images/arrow-down-1.svg" alt="arrow" />
                                </Button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
        </div>
    )
}
NocPopUp.prototypes = {
    userDetails: PropTypes.object,
    setemailPopup: PropTypes.func,
    showTriggerEmailPopUp: PropTypes.func,
    setFailurePopUpContent: PropTypes.func,
    setFailurePopUpModal: PropTypes.func,
    setNocOpenClosed: PropTypes.func
}

export default NocPopUp