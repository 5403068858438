import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getContractPgsDetails = (data) => {
    // let requestJson = {
    //     "header": {
    //         "authToken": ""
    //     },
    //     "body": {
    //         "contractNo": "TCFPL0269000010001685",
    //         "company": "TCFSL"
    //     }
    // }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/dms/get-contract-pgs-details/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/dms/get-contract-pgs-details/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getContractPgsDetails