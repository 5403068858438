import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccountTabConatainer from "../../../Retail/src/container/AccountTabConatainer/AccountTabConatainer";
import CustomDropDown from "../../../Retail/src/global/CustomDropDown/CustomDropDown";
import "./RetailAccountDetails.scss";
import dataObj from "./Data";
import { useEffect } from "react";
import AccountDetailsServicObject from "../../../Retail/src/services/AccountDetailsService/AccountDetailsService";
import commonFunctionObject from "../../../Retail/src/common-function/commonfunctions";
import CCODProductInfo from "../../../Retail/src/components/CCODProductInfo/CCODProductInfo";
import FailurePopUp from "../../../Retail/src/global/FailurePopUp";
import LoanHoldingNavComponent from "../../../Retail/src/components/LoanHoldingNavComponent/LoanHoldingNavComponent";
import DataMapping from "../../../Shared/context/data-mapping";
import {
  OFFER_DETAIS,
  STOREACCOUNTDATA,
  STOREDATA,
} from "../../../Shared/context/data-mapping/action/action.type";
import useSessionAlive from "../../../Retail/src/customHooks/useSessionAlive";
import AccountNumberDropDown from "../../../Retail/src/global/AccountNumberDropDown/AccountNumberDropDown";
import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import jsHelper from "../../../Retail/src/utilities/jsHelper";
import ProfileServiceObject from "../../../Retail/src/services/Profile";


const RetailAccountDetails = ({portalType}) => {
  const navigate = useNavigate();
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });
  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");
  const [optionState, setOptionState] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [data, dispatchData] = useContext(DataMapping);
  const { isUserSessionAlive } = useSessionAlive();
  const [accoutNumberArray, setAccoutNumberArray] = useState([]);
  const [activeContract, setActiveContract] = useState("");
  const [systemFlag, setSystemFlag] = useState("");
  const [splitChargesData, setSplitChargesData] = useState({})
  const [assetDetails, setAssetDetails] = useState({})
  const getLasProps = useLocation();
  const [selectedOptionMobileViw, setSelectedOptionMobilView] = useState("Loan Details");

  const navigationObject = getLasProps?.state?.navigationObject;
  const ccodQuickLinkclick = getLasProps?.state?.redirectionFrom;


  let activeTabByState = ccodQuickLinkclick ? "Documents" : "Account Details";
  const [activeTab, setActiveTab] = useState(activeTabByState);
  const { analytics } = useContext(AnalyticsContextProvider)
  const [postOtpFlag, setPostOtpFlag] = useState(false)

  useEffect(() => {
    if (data.length === 0) {
      navigate("/login")
    } else {
      createAccountNumberDropDown();
      commonFunctionObject.scrollToTop();
      let analytics_data = {
        page: {
          name: "account-details",
          siteSection: "account-details",    //pass PL, BL, Home page
          pageType: "",
          siteSubSection: "",     //PL overview......
          pageURL: window.location.href,
          pathName: window.location.pathname
        },
        user: {
          loginMethod: jsHelper.toStr(data?.userLoginType?.body?.loginType),
          loginStatus: "",
          gcid: jsHelper.toStr(data?.getUserDataV3?.body?.primarySystem?.GCID),
          lobMembership: "",
          lastloginDate: jsHelper.toStr(data?.getUserDataV3?.body?.LAST_LOGGED_IN)
        },
        products: {
          productCode: ""
        }
      }

      if(portalType !== "collection"){
        analytics.trackData("pageInitialization", analytics_data);
      }
    }

  }, []);

  useEffect(() => {
    if (ccodQuickLinkclick) {
      setActiveTab('Documents');
      navigate('/account-details');
      setSelectedOptionMobilView("Documents")
    }
  }, [ccodQuickLinkclick]);

  const createAccountNumberDropDown = () => {
    if (data.length == 0) {
      navigate('login');
      return 0;
    }
    const arrayData = Object.keys(data?.parsedUserData).map((val) => ({
      value: val,
      system: data?.parsedUserData[val].system,
    }));
    console.log("ArrayData", arrayData);

    //setAccoutNumberArray(arrayData);
    let accountNumber = data?.currentActiveData.accountNumber
    let productName = data?.currentActiveData.system === "retail" ? commonFunctionObject.getProductName(data.parsedUserData[accountNumber]) : ""
    let shortName = data?.currentActiveData.system === "retail" ? commonFunctionObject.getProductShortName(productName) : data?.currentActiveData.system === "ccod" ? "CCOD" : "LAS"
    setActiveContract(data.currentActiveData.accountNumber.toString());
    setSystemFlag(data.currentActiveData.system);
    setSelectedOption(accountNumber + " (" + shortName + ")");
    // callSelectedContractRespectiveApi(
    //   data.currentActiveData.system,
    //   data.currentActiveData.accountNumber
    // );
  };

  const callSelectedContractRespectiveApi = (system, accNo) => {
    let AssetDetails = ["USED CAR REFINANCE", "TWO WHEELER", "USED CAR", "AUTO LOAN", "CAR LOAN", "USED CAR CNC"]
    if (system === "retail") {
      if (AssetDetails.includes(commonFunctionObject.getProductName(data.parsedUserData[accNo]).toUpperCase())) {


      } else {

      }
      // if (!data?.assetDetails[accNo] && AssetDetails.includes(commonFunctionObject.getProductName(data.parsedUserData[accNo].toUpperCase()))) {
      //   callAssetDetailsApi(accNo);
      // }
    }
  };
  const callSplitChargesApi = (accNo) => {
    var accountNumber = data?.currentActiveData.accountNumber
    var currentUserData = data?.parsedUserData[accountNumber]
    let productName = commonFunctionObject.getProductName(currentUserData);
    var gcid = currentUserData.GCID;
    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: gcid,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "",
        componentName: "Account Details",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "",
        eventName: "Split Charges",
        tabTitle: "Split of Charges"
      }
    }
    if(portalType !== "collection"){
      analytics.trackData("tabInteraction", analytics_data);
    }
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    if (!data?.splitChargesData?.[accNo]) {
      AccountDetailsServicObject.splitChargesApiService(accNo, data.authToken)
        .then((value) => {
          dispatchData({
            type: STOREACCOUNTDATA,
            name: "splitChargesData",
            payload: {
              response: value,
              contractNumber: accNo,
            },
          });

          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        })
        .catch((error) => {
          setFailurePopUpContent({
            apiName: "",
            message:
              "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            errorCode: error,
          });
          setFailurePopUpModal(true);
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        });
    } else {
      let splitChargeResponse = data?.splitChargesData[accNo];
      console.log("splitChargeResponse", splitChargeResponse);

      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA,
      });
    }
  };


  const callRetailsbankingDetails = (accountNumber) => {
    var currentUserData = data?.parsedUserData[accountNumber]
    let productName = commonFunctionObject.getProductName(currentUserData);
    var gcid = currentUserData.GCID;

    if (!data?.bankingDetail?.[accountNumber]) {
      dispatchData({
        name: "loaderFlag",
        payload: true,
        type: STOREDATA,
      });
      let analytics_data = {
        user: {
          loginMethod: "",
          loginStatus: "",
          gcid: gcid,
          loginType: "",
          loanaccountNo: accountNumber,
          firstloginDate: ""
        },
        products: {
          productCode: productName,
        },
        data: {
          ctaText: "",
          componentName: "Account Details",
          bannerTitle: "",
          menuTitle: "",
          faqTitle: "",
          searchTerm: "",
          lobActivity: "",
          status: "",
          eventName: "Banking Details",
          tabTitle: "Banking Details"
        }
      }
      if(portalType !== "collection"){
        analytics.trackData("tabInteraction", analytics_data);
      }

      ProfileServiceObject.getBankingDetailService(accountNumber, data.getLoanPositionDTLS[accountNumber], data?.authToken)
        .then((value) => {
          dispatchData({
            type: STOREACCOUNTDATA,
            name: "bankingDetail",
            payload: {
              response: value,
              contractNumber: accountNumber,
            },
          });
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        })
        .catch((error) => {
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
          setFailurePopUpContent({
            apiName: "",
            message:
              "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            errorCode: error,
          });
          setFailurePopUpModal(true)
          console.log(error);
        });
    }
  }


  const storeSelectAccNumberData = (accNo, tabName) => {

    setSystemFlag(data?.parsedUserData[accNo.split(" ")[0]].system);
    setSelectedOption(accNo);
    setActiveContract(accNo.split(" ")[0]);
    let bankingDetail = data?.parsedUserData[accNo.split(" ")[0]].system === "retail" ? commonFunctionObject.checkActiveStatus(data?.getLoanPositionDTLS?.[accNo.split(" ")[0]]) : ""
    if (activeTab === "split-charges") {
      let splitChargeFlag = commonFunctionObject.checkSplitCharges(data?.parsedUserData[accNo.split(" ")[0]])
      if (splitChargeFlag) {
        callSplitChargesApi(accNo.split(" ")[0])
      } else {
        setActiveTab("Loan Details")
        setSelectedOptionMobilView("Loan Details")
      }
    }
    if (tabName === "split-charges") {
      callSplitChargesApi(accNo.split(" ")[0])
    }

    if (tabName === "loan-detail") {
      setActiveTab("Loan Details")
    }

    if (tabName === "document") {
      setActiveTab("Documents")
    }

    if (activeTab === "asset-detail") {
      if (data?.assetDetails?.[accNo.split(" ")[0]]) {
        setActiveTab("asset-detail")
      } else {
        setActiveTab("Loan Details")
        setSelectedOptionMobilView("Loan Details")
      }
    }

    if (activeTab === "bank-details") {
      if (activeTab === "bank-details" && data?.parsedUserData[accNo.split(" ")[0]].system === "retail" && bankingDetail === "active") {
        callRetailsbankingDetails(accNo.split(" ")[0])
      } else {
        setActiveTab("Loan Details")
        setSelectedOptionMobilView("Loan Details")
      }
    }

  }

  useEffect(() => {
    isUserSessionAlive(data?.authToken);
  }, []);

  return (
    <div className="main-container account-details-container">
      {!postOtpFlag ?
        <AccountNumberDropDown
          optionArray={data.accountNumberDropDownArray}
          optionState={optionState}
          setOptionState={setOptionState}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          labelFlag={true}
          labelText="Select Loan"
          labelClass={"account-details-container-label"}
          storeSelectAccNumberData={storeSelectAccNumberData}
        />
        : null
      }
      {systemFlag ? (
        systemFlag === "retail" ? (
          <AccountTabConatainer activeContract={activeContract} splitChargesData={splitChargesData} assetDetails={assetDetails} quickLinksFlag={activeTab} storeSelectAccNumberData={storeSelectAccNumberData} callSplitChargesApi={callSplitChargesApi} activeTab={activeTab} setActiveTab={setActiveTab} callRetailsbankingDetails={callRetailsbankingDetails} systemFlag={systemFlag} setSelectedOptionMobilView={setSelectedOptionMobilView} selectedOptionMobileView={selectedOptionMobileViw} portalType={portalType}/>
        ) : systemFlag === "las" ? (
          <LoanHoldingNavComponent
            activeContract={activeContract}
            navigationObject={navigationObject}
            postOtpFlag={postOtpFlag}
            setPostOtpFlag={setPostOtpFlag}
          />
        ) : (
          <CCODProductInfo
            activeContract={activeContract}
            redirectionFrom={activeTab}
            activeTab={activeTab}
            quickLinksFlag={ccodQuickLinkclick}
            setActiveTab={setActiveTab}
            portalType={portalType}
          />
        )
      ) : null}

      {failurePopUpModal ? (
        <FailurePopUp
          onClick={() => setFailurePopUpModal(false)}
          visible={failurePopUpModal}
          setFailureModalStat={setFailurePopUpModal}
          failurePopUpContent={failurePopUpContent}
        />
      ) : null}
    </div>
  );
};

export default RetailAccountDetails;
