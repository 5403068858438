import React from "react";
import './CcodSoaPdfDownload.scss'
import PropTypes from 'prop-types';
const CcodSoaPdfDownload = ({ pdfData, startDate, endDate, company, soaTableRef }) => {


    const customerData = pdfData.soaResponseObj
    const transection = pdfData.soaTransArr

    //principle calculation 
    var total_out = parseFloat(customerData.TOT_OUT);
    var interest = parseFloat(customerData.OUT_INT);
    var charges = parseFloat(customerData.OUT_CHRG);
    var intCharge = parseFloat(interest + charges);
    var principle = parseFloat(total_out - intCharge);

    // let fromDateFinal = startDate && startDate.getFullYear() + "-" + startDate.getUTCMonth() + "-" + startDate.getDate()
    // let endDateFinal = endDate && endDate.getFullYear() + "-" + endDate.getUTCMonth() + "-" + endDate.getDate()

    return (
        <div className="ccod-soa-pdf">
            <div>
                {/* <button onClick={}>Download</button> */}
            </div>
            <div id="pdfElement" ref={soaTableRef} className="pdf">
                <div className="headerImage">
                    <img src="assets/images/tatalogo.png" alt="tata log" />
                </div>
                <p className="headerCompanyName">
                    {/* {
                        company && company.toLoweCase() === "tchfl" ? "TATA CAPITAL HOUSING FINANCE LIMITED" : "TATA CAPITAL FINANCIAL SERVICES LTD"
                    } */}
                </p>
                <p className="statementDate">
                    {/* Statement Period: From {fromDateFinal} To {endDateFinal} */}
                </p>
                <div>
                    <table className="table-1">
                        <tr>
                            <td>Customer Name</td>
                            <td>{customerData.NAME1}</td>
                            <td>Sanctioned Limit</td>
                            <td>{customerData.SANC_LIMIT}</td>
                            <td colSpan={2}>CURRENT OUTSTANDING</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>{customerData.ADDRESS}</td>
                            <td>Limit Sanctioned Date</td>
                            <td>{customerData.SANC_DATE}</td>
                            <td>Principal</td>
                            <td>{principle.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Product</td>
                            <td>{customerData.PRODUCT}</td>
                            <td>Sanctioned ROI</td>
                            <td>{customerData.SANC_ROI}</td>
                            <td>Interest</td>
                            <td>{customerData.OUT_INT}</td>
                        </tr>
                        <tr>
                            <td>Loan Account Number</td>
                            <td>{customerData.LIFNR}</td>
                            <td>Tenure (In Month)</td>
                            <td>{customerData.TENURE}</td>
                            <td>Charges</td>
                            <td>{customerData.OUT_CHRG}</td>
                        </tr>
                        <tr>
                            <td>Linked Term Loan Account Number</td>
                            <td>{customerData.BANQS}</td>
                            <td>Limit Expiry Date</td>
                            <td>{customerData.SANC_REV_DT}</td>
                            <td>Penal Interest</td>
                            <td>{customerData.PENAL}</td>
                        </tr>
                        <tr>
                            <td>Online Account Number</td>
                            <td>{customerData.ZGCID}</td>
                            <td>Current Sanctioned Limit</td>
                            <td>{customerData.SANC_LIMIT}</td>
                            <td>Total Outstanding</td>
                            <td>{customerData.TOT_OUT}</td>
                        </tr>
                        <tr>
                            <td>Branch Name</td>
                            <td>{customerData.DESCRIPTION}</td>
                            <td>Current ROI</td>
                            <td>{customerData.CURR_ROI}</td>
                            <td>Available Limit</td>
                            <td>{customerData.AVAIL_LIMIT}</td>
                        </tr>
                    </table>
                </div>

                <div>
                    <table className="table-1">
                        <tr>
                            <th>DATE</th>
                            <th>TRANSACTI ON DETAILS</th>
                            <th>DEBIT</th>
                            <th>CREDIT</th>
                            <th>BALANCE</th>
                            <th>DR/CR</th>
                        </tr>
                        {transection.map((value) => {
                            let transectionDetails = value.TRANS_DET + value.INST_NO + value.RCPT_NO
                            return (
                                <tr>
                                    <td>{value.BUDAT}</td>
                                    <td>{transectionDetails.toString()}</td>
                                    <td>{value.DEBIT}</td>
                                    <td>{value.CREDIT}</td>
                                    <td>{value.BALANCE}</td>
                                    <td>{value.DR_CR}</td>
                                </tr>
                            )
                        })}


                    </table>
                </div>
                <p className="query-para">
                    If you have any queries or require any assistance, you can reach us through our toll free number 18602676060 or e - mail us at customercare@tatacapital.com
                </p>
                <div className="footer">
                    <p>TATA CAPITAL FINANCIAL SERVICES LIMITED</p>
                    <p>Corporate Identity Number U67100MH2010PLC210201</p>
                    <p className="footer-address">Registered office: 11th Floor, Tower A, Peninsula Business Park, Ganpatrao Kadam Marg, Lower Parel, Mumbai 400013 India</p>
                    <p>Tel 91 22 6182 8282/91 22 6745 9000 Web: www.tatacapital.com</p>
                </div>
            </div>
        </div>
    )
}

CcodSoaPdfDownload.propTypes = {
    pdfData : PropTypes.object,
    soaTableRef : PropTypes.func
}

export default CcodSoaPdfDownload;