import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const generateVoiceOtp = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "webtopId": "540CZ0005920",
            "disbursalDate": "01/01/2020"
        }

    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/otp/generate-voice-otp/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/otp/generate-voice-otp/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default generateVoiceOtp;