import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getCustomerBankingDetails = (requestObject) => {
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/dms/get-customer-banking-details/v1',requestObject).then(response => {
        stalwartApiCall('POST', requestObject, '/shaft/api/dms/get-customer-banking-details/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getCustomerBankingDetails