import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getDocumentList = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "lockerNo": "242475666"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/dms/get-document-list/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/dms/get-document-list/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getDocumentList;