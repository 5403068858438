
var panHandlerObj = {
    // "loginOtp" : loginOtpSubmit,
};
var jocataStatusErrorObj = {
    "202": {
        "statusCode": "202",
        "description": "REQUEST_PENDING",
        "displayErrorCode": "90",
        "technicalErrorCode": "E-3008-C-202",
        "errorMessage": "Please enter valid PAN"
    },
    "403": {
        "statusCode": "403",
        "description": "UAZ",
        "displayErrorCode": "91",
        "technicalErrorCode": "E-3008-C-403",
        "errorMessage": "Please enter valid PAN"
    },
    "404": {
        "statusCode": "404",
        "description": "NO_RECORD_FOUND",
        "displayErrorCode": "92",
        "technicalErrorCode": "E-3008-C-404",
        "errorMessage": "Please enter valid PAN"
    },
    "410": {
        "statusCode": "410",
        "description": "NO_SYMMETRIC_KEY",
        "displayErrorCode": "93",
        "technicalErrorCode": "E-3008-C-410",
        "errorMessage": "Please enter valid PAN"
    },
    "411": {
        "statusCode": "411",
        "description": "NO_ENC_ASSERTION",
        "displayErrorCode": "94",
        "technicalErrorCode": "E-3008-C-411",
        "errorMessage": "Please enter valid PAN"
    },
    "412": {
        "statusCode": "412",
        "description": "SYM_KEY_DEC_FAILED",
        "displayErrorCode": "95",
        "technicalErrorCode": "E-3008-C-412",
        "errorMessage": "Please enter valid PAN"
    },
    "413": {
        "statusCode": "413",
        "description": "ASYM_DEC_FAILED",
        "displayErrorCode": "96",
        "technicalErrorCode": "E-3008-C-413",
        "errorMessage": "Please enter valid PAN"
    },
    "414": {
        "statusCode": "414",
        "description": "ASSERTION_UNMAR_ERR",
        "displayErrorCode": "97",
        "technicalErrorCode": "E-3008-C-414",
        "errorMessage": "Please enter valid PAN"
    },
    "415": {
        "statusCode": "415",
        "description": "SIG_ELMT_NOT_PRESENT",
        "displayErrorCode": "98",
        "technicalErrorCode": "E-3008-C-415",
        "errorMessage": "Please enter valid PAN"
    },
    "416": {
        "statusCode": "416",
        "description": "SIG_VALIDATION_FAILED",
        "displayErrorCode": "99",
        "technicalErrorCode": "E-3008-C-416",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "417": {
        "statusCode": "417",
        "description": "UNABLE_TO_GET_SIG_VALI",
        "displayErrorCode": "100",
        "technicalErrorCode": "E-3008-C-417",
        "errorMessage": "Please enter valid PAN"
    },
    "418": {
        "statusCode": "418",
        "description": "NO_ATTR_STMT",
        "displayErrorCode": "101",
        "technicalErrorCode": "E-3008-C-418",
        "errorMessage": "Please enter valid PAN"
    },
    "419": {
        "statusCode": "419",
        "description": "ATTR_SIZE_ZERO",
        "displayErrorCode": "102",
        "technicalErrorCode": "E-3008-C-419",
        "errorMessage": "Please enter valid PAN"
    },
    "500": {
        "statusCode": "500",
        "description": "INTERNAL_ERROR",
        "displayErrorCode": "103",
        "technicalErrorCode": "E-3008-C-500",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "502": {
        "statusCode": "502",
        "description": "INVALID_INPUT",
        "displayErrorCode": "105",
        "technicalErrorCode": "E-3008-C-502",
        "errorMessage": "Invalid input, please enter valid PAN"
    },
    "503": {
        "statusCode": "503",
        "description": "CONNECTION_TIMEOUT",
        "displayErrorCode": "106",
        "technicalErrorCode": "E-3008-C-503",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "504": {
        "statusCode": "504",
        "description": "EVOKE_SCRAP_EXCEPTION",
        "displayErrorCode": "107",
        "technicalErrorCode": "E-3008-C-504",
        "errorMessage": "Please enter valid PAN"
    },
    "505": {
        "statusCode": "505",
        "description": "EVOKE_UNKNOWN_EXCEP",
        "displayErrorCode": "108",
        "technicalErrorCode": "E-3008-C-505",
        "errorMessage": "Please enter valid PAN"
    },
    "506": {
        "statusCode": "506",
        "description": "INVALID_CAPTCHA",
        "displayErrorCode": "109",
        "technicalErrorCode": "E-3008-C-506",
        "errorMessage": "Please enter valid PAN"
    },
    "507": {
        "statusCode": "507",
        "description": "RECORD_NOT_FOUND",
        "displayErrorCode": "110",
        "technicalErrorCode": "E-3008-C-507",
        "errorMessage": "No record found, Please enter valid PAN"
    },
    "508": {
        "statusCode": "508",
        "description": "SOURCE_WEBSITE_DOWN",
        "displayErrorCode": "111",
        "technicalErrorCode": "E-3008-C-508",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "509": {
        "statusCode": "509",
        "description": "INVALID_TOKEN",
        "displayErrorCode": "112",
        "technicalErrorCode": "E-3008-C-509",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "510": {
        "statusCode": "510",
        "description": "NOT_SUBSCRIBED",
        "displayErrorCode": "113",
        "technicalErrorCode": "E-3008-C-510",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "512": {
        "statusCode": "512",
        "description": "REQUEST_TIMED_OUT",
        "displayErrorCode": "114",
        "technicalErrorCode": "E-3008-C-512",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "513": {
        "statusCode": "513",
        "description": "ITR_PROFILE_UPDATE",
        "displayErrorCode": "115",
        "technicalErrorCode": "E-3008-C-513",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "514": {
        "statusCode": "514",
        "description": "AADHAAR_ERROR",
        "displayErrorCode": "116",
        "technicalErrorCode": "E-3008-C-514",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "515": {
        "statusCode": "515",
        "description": "SERVICE_UTILITY_EXCEPTION",
        "displayErrorCode": "117",
        "technicalErrorCode": "E-3008-C-515",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "516": {
        "statusCode": "516",
        "description": "TAXPAYER_SEARCH",
        "displayErrorCode": "118",
        "technicalErrorCode": "E-3008-C-516",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "517": {
        "statusCode": "517",
        "description": "NO_ENC_JSON",
        "displayErrorCode": "119",
        "technicalErrorCode": "E-3008-C-517",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "518": {
        "statusCode": "518",
        "description": "NO_SIGNED_CONTENT",
        "displayErrorCode": "120",
        "technicalErrorCode": "E-3008-C-518",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "519": {
        "statusCode": "519",
        "description": "JSON_DEC_FAILED",
        "displayErrorCode": "121",
        "technicalErrorCode": "E-3008-C-519",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "520": {
        "statusCode": "520",
        "description": "RETRY_EXCEPTION",
        "displayErrorCode": "122",
        "technicalErrorCode": "E-3008-C-520",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "521": {
        "statusCode": "521",
        "description": "LINK_EXPIRED",
        "displayErrorCode": "123",
        "technicalErrorCode": "E-3008-C-521",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "586": {
        "statusCode": "586",
        "description": "IIS",
        "displayErrorCode": "124",
        "technicalErrorCode": "E-3008-C-586",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "589": {
        "statusCode": "589",
        "description": "RNA",
        "displayErrorCode": "125",
        "technicalErrorCode": "E-3008-C-589",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "590": {
        "statusCode": "590",
        "description": "GST_APIS_ISSUE",
        "displayErrorCode": "126",
        "technicalErrorCode": "E-3008-C-590",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "591": {
        "statusCode": "591",
        "description": "LIMIT_RECORDS_EXCEPTION",
        "displayErrorCode": "127",
        "technicalErrorCode": "E-3008-C-591",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "592": {
        "statusCode": "592",
        "description": "NO_PGP_DATA",
        "displayErrorCode": "128",
        "technicalErrorCode": "E-3008-C-592",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "596": {
        "statusCode": "596",
        "description": "CONSENT_NOT_AGREED",
        "displayErrorCode": "129",
        "technicalErrorCode": "E-3008-C-596",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "597": {
        "statusCode": "597",
        "description": "INVALID_TIMESTAMP",
        "displayErrorCode": "130",
        "technicalErrorCode": "E-3008-C-597",
        "errorMessage": "Invalid input, please enter valid PAN"
    },
    "598": {
        "statusCode": "598",
        "description": "INVALID_CREDENTIAL",
        "displayErrorCode": "131",
        "technicalErrorCode": "E-3008-C-598",
        "errorMessage": "Invalid input, please enter valid PAN"
    },
    "599": {
        "statusCode": "599",
        "description": "CONNECTION_REFUSED",
        "displayErrorCode": "132",
        "technicalErrorCode": "E-3008-C-599",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "600": {
        "statusCode": "600",
        "description": "SYSTEM_ERROR",
        "displayErrorCode": "133",
        "technicalErrorCode": "E-3008-C-600",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "601": {
        "statusCode": "601",
        "description": "AUTHENTICATION_FAILURE",
        "displayErrorCode": "134",
        "technicalErrorCode": "E-3008-C-601",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "602": {
        "statusCode": "602",
        "description": "USER_VALIDITY_EXPIRED",
        "displayErrorCode": "135",
        "technicalErrorCode": "E-3008-C-602",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "603": {
        "statusCode": "603",
        "description": "NOT_ENOUGH_BALANCE",
        "displayErrorCode": "136",
        "technicalErrorCode": "E-3008-C-603",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "604": {
        "statusCode": "604",
        "description": "INVALD_IPADDR",
        "displayErrorCode": "137",
        "technicalErrorCode": "E-3008-C-604",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "605": {
        "statusCode": "605",
        "description": "DATA_PROCESSING",
        "displayErrorCode": "138",
        "technicalErrorCode": "E-3008-C-605",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "606": {
        "statusCode": "606",
        "description": "DATA_EMPTY",
        "displayErrorCode": "139",
        "technicalErrorCode": "E-3008-C-606",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "607": {
        "statusCode": "607",
        "description": "UNAUTHORIZED_ACCESS",
        "displayErrorCode": "140",
        "technicalErrorCode": "E-3008-C-607",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "608": {
        "statusCode": "608",
        "description": "INVALID_PAYLOAD",
        "displayErrorCode": "141",
        "technicalErrorCode": "E-3008-C-608",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "609": {
        "statusCode": "609",
        "description": "WATCHOUT_API_STATUS_CODES",
        "displayErrorCode": "142",
        "technicalErrorCode": "E-3008-C-609",
        "errorMessage": "We are not able to validate your PAN, please try again after sometime"
    },
    "5000": {
        "statusCode": "5000",
        "description": "ISE",
        "displayErrorCode": "104",
        "technicalErrorCode": "E-3008-C-5000",
        "errorMessage": "Please enter valid PAN"
    },
};

var jocataMessageErrorObj = {
    "Marked as Fake": {
        "errorMessage": "Marked as Fake",
        "uniqueErrorCode": "F",
        "technicalErrorCode": "E-3008-C-F",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "143"
    },
    "Marked as Deactivated": {
        "errorMessage": "Marked as Deactivated",
        "uniqueErrorCode": "X",
        "technicalErrorCode": "E-3008-C-X",
        "technicalErrorMessage": "PAN is deactivated, please enter active PAN",
        "errorCode": "144"
    },
    "Deleted": {
        "errorMessage": "Deleted",
        "uniqueErrorCode": "D",
        "technicalErrorCode": "E-3008-C-D",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "145"
    },
    "Not present in Income Tax Department (ITD) database/Invalid PAN": {
        "errorMessage": "Not present in Income Tax Department (ITD) database/Invalid PAN",
        "uniqueErrorCode": "N",
        "technicalErrorCode": "E-3008-C-N",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "146"
    },
    "Existing and Valid but event marked as Amalgamation in ITD database": {
        "errorMessage": "Existing and Valid but event marked as Amalgamation in ITD database",
        "uniqueErrorCode": "EA",
        "technicalErrorCode": "E-3008-C-EA",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "147"
    },
    "Existing and Valid but event marked as Acquisition in ITD database": {
        "errorMessage": "Existing and Valid but event marked as Acquisition in ITD database",
        "uniqueErrorCode": "EC",
        "technicalErrorCode": "E-3008-C-EC",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "148"
    },
    "Existing and Valid but event marked as Death in ITD database": {
        "errorMessage": "Existing and Valid but event marked as Death in ITD database",
        "uniqueErrorCode": "ED",
        "technicalErrorCode": "E-3008-C-ED",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "149"
    },
    "Existing and Valid but event marked as Dissolution in ITD database": {
        "errorMessage": "Existing and Valid but event marked as Dissolution in ITD database",
        "uniqueErrorCode": "EI",
        "technicalErrorCode": "E-3008-C-EI",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "150"
    },
    "Existing and Valid but event marked as Liquidated in ITD database": {
        "errorMessage": "Existing and Valid but event marked as Liquidated in ITD database",
        "uniqueErrorCode": "EL",
        "technicalErrorCode": "E-3008-C-EL",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "151"
    },
    "Existing and Valid but event marked as Merger in ITD database": {
        "errorMessage": "Existing and Valid but event marked as Merger in ITD database",
        "uniqueErrorCode": "EM",
        "technicalErrorCode": "E-3008-C-EM",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "152"
    },
    "Existing and Valid but event marked as Partition in ITD database": {
        "errorMessage": "Existing and Valid but event marked as Partition in ITD database",
        "uniqueErrorCode": "EP",
        "technicalErrorCode": "E-3008-C-EP",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "153"
    },
    "Existing and Valid but event marked as Split in ITD database": {
        "errorMessage": "Existing and Valid but event marked as Split in ITD database",
        "uniqueErrorCode": "ES",
        "technicalErrorCode": "E-3008-C-ES",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "154"
    },
    "Existing and Valid but event marked as Under Liquidation in ITD database": {
        "errorMessage": "Existing and Valid but event marked as Under Liquidation in ITD database",
        "uniqueErrorCode": "EU",
        "technicalErrorCode": "E-3008-C-EU",
        "technicalErrorMessage": "Please enter valid PAN",
        "errorCode": "155"
    }
}


var validateStatusError = function (statusCode) {
    if (typeof (jocataStatusErrorObj[statusCode]) === "object") {
        return jocataStatusErrorObj[statusCode];
    }
}
panHandlerObj.validateStatusError = validateStatusError;

var validateMessageError = function (errorMessage) {
    if (typeof (jocataMessageErrorObj[errorMessage]) === "object") {
        return jocataMessageErrorObj[errorMessage];
    } else {
        console.log("error message : ", errorMessage);
    }
}
panHandlerObj.validateMessageError = validateMessageError;

export default panHandlerObj


