import React from "react";
import './LoanDetailsItem.scss';
import PropTypes from 'prop-types';

const LoanDetailsItem = ({ loanNameText = "", LoanAmount = "", emiDueText = "",type ="",color_red_class=""}) => {
    return (
        <div className="c_loan_details_item">
            <p className="c_loan_name_text">{loanNameText}</p>
            <span className="c_gradient_underline"></span>
            <p className={color_red_class ? `c_loan_amount ${color_red_class}`: "c_loan_amount"}>{type == "date" ? `${LoanAmount}`: `₹ ${LoanAmount ? parseFloat(LoanAmount).toLocaleString("en-IN") : "-"}`}</p>
            <p className="c_emi_due_text">{emiDueText}</p>
        </div >
    );
}

LoanDetailsItem.propTypes = {
    loanNameText: PropTypes.string,
    LoanAmount: PropTypes.number,
    emiDueText: PropTypes.any,
    type: PropTypes.string,
    color_red_class:PropTypes.string
}

export default LoanDetailsItem;
