import React from 'react';
import Button from '../../FormFields/Button/Button';
import './passwordRecover.scss';
import PropTypes from 'prop-types';


const PasswordRecover = ({setLoginTab,setCounter}) => {
  return (
    <div className='c_password_recover_wrap'>
        <div className="c_password_recover_head">
          <p className='c_password_recover_title'>Password Changed</p>
          <p className='c_password_recover_desc'>Your password has been changed successfully, Please Login with your new password</p>
        </div>
        <div className="c_password_recover_foot">
          <div className="c_password_recover_btn_wrp">
            <Button className="login" onClick={()=>{setLoginTab(()=> "userId");setCounter(()=>0)}}>
              Back to Log In
              <img
                className="c_arrow-right"
                src="assets/images/right-arrow-white.svg"
                alt=""
              />
            </Button>   
          </div>
        </div>
    </div>
  )
}

PasswordRecover.propTypes = {
  setLoginTab: PropTypes.func,
  setCounter: PropTypes.func,
}


export default PasswordRecover