import isLt from './isLessThan';

export default function isEqualByReference() {
    var mingArg = 2;
    if (isLt(arguments.length, mingArg)) {
        throw new Error("Minumum " + mingArg + " arguments required!");
        // throw new Error(jsHelper.toStr("Minumum " + mingArg + " arguments required!"))
    }
    for (var i = 0; i < arguments.length - 1; i++) {
        if (arguments[i] !== arguments[i + 1]) {
            return false;
        }
    }
    return true;
}