import axios from "axios"
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const withPassword = (data) => {

    let requestJson = {
        "header": {
            "grantType": "password",
            "context": "login"
        },
        "body": {
            password: "MTI4OjoxMDAwMDo6M2IxMjY5YWI0NzhlNzM4ODNiNmViOTEzOWU2NGE3MWY6OmMzMWQ3M2Q3YjIwMzBmNmI2ZGQ5ZWNmZmQwYmMwZjUzOjpKYm5lb2ErYTl4QjM4R0JjQ1AzNGtnPT0=",
            username: "242475666"
        }

    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/login/with-password/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/login/with-password/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default withPassword;