import apiConfigObj from "../../app-config/app-config";

class Analytics {
    // constructor() {
    //     if (!window._satellite) {
    //         window.adobeDataLayer = window.adobeDataLayer || [];
    //         let script = document ? document.createElement('script') : null;
    //         if (script) {
    //             let src = "";
    //             switch (apiConfigObj.appConfig["env"]) {
    //                 case "PRODUCTION":
    //                     src = "//assets.adobedtm.com/e4c76be5b9e7/62face33d842/launch-da37fd6dc488-development.min.js";
    //                     break;
    //                 case "DEVELOPMENT":
    //                     src = "//assets.adobedtm.com/e4c76be5b9e7/62face33d842/launch-da37fd6dc488-development.min.js";
    //                     break;
    //             }
    //             if (src) {
    //                 script.src = src;
    //                 script.async = true;
    //                 document.head.appendChild(script);
    //                 script.onload = function (e) {
    //                     // document.dispatchEvent(new CustomEvent('script:adobeAnalytics', { detail: { loaded: true, src: e.currentTarget.src } }))
    //                     window.isAdobeAnalyticsLoaded = true;
    //                 }
    //                 window.digitalData = {};

    //             }
    //         }
    //     }
    // }

    trackData(event,eventObj) {
        
        try {
            
            Object.keys(eventObj).forEach(key => {
                window.digitalData[key] = eventObj[key] ? eventObj[key] : "";
            })
            window.adobeDataLayer.push({
                event: event,
                ...eventObj
            })
            window._satellite.track(event, eventObj);
        } catch (error) {
            
            console.warn("Analytics Error: ", error);
        }
        return true;
    }
}

export default Analytics;