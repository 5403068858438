import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getEnachDetails = (reqObj) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "contractNumber": [
                "TCFUC0632000010078712"
            ]
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/db/get-enach-details/v1',reqObj).then(response => {
        stalwartApiCall('POST', reqObj, '/shaft/api/db/get-enach-details/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getEnachDetails;