import { React } from "react";
import "./banner.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="c_banner">
      <Slider {...settings}>
        <div className="c_radian_border_container">
          <picture>
            <source media="(min-width:768px)" srcSet="assets/images/tc_collection_banners_763X400_1.jpg" />
            <img className="c_img" src="assets/images/tc_collection_banners_386X382_1.jpg" alt="tc_collection_banners" />
          </picture>
        </div>
        <div className="c_radian_border_container">
          <picture>
              <source media="(min-width:768px)" srcSet="assets/images/tc_collection_banners_763X400_2.jpg" />
              <img className="c_img" src="assets/images/tc_collection_banners_386X382_2.jpg" alt="tc_collection_banners" />
            </picture>
        </div>
      </Slider>
    </div>
  );
};

export default Banner;