import axios from "axios"
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const viewServiceRequest = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "contractNumber": "TCFPL0269000010001685"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/sfdc/view-service-request/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/sfdc/view-service-request/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default viewServiceRequest;