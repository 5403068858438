import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

export const getServerTime = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {}
    }

    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/direct/get-server-time/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/direct/get-server-time/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}