import freezeObject from './freezeObject';
import isObj from './isObject';

export default function deepFreezeObject(obj) {
    for (var key in obj) {
        var val = obj[key];
        if (isObj(val)) {
            deepFreezeObject(val);
        }
    }
    return freezeObject(obj);
}