import React, { useState } from "react";
import Button from "../../global/Button";
import "./PayNowPopup.scss";
import Modal from "../../global/Modal";

import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import DataMapping from "../../../../Shared/context/data-mapping";
import { useEffect } from "react";
import LasAccountDetailsServiceObject from "../../services/LasAccountDetailsService";
import commonFunctionObject from "../../common-function";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
import AlertPopUp from "../../global/AlertPopUp";
import PropTypes from 'prop-types';

const PayNowPopup = ({ activeContract, setModalState, setFreezeFlag, setFreezeTime, setFunctionalityFreezeFlag, freezeFlag, freezeTimer }) => {
    const [themeState, dispatch, otpState, otpDispatch] =
        useContext(ThemeContext);
    const [payNowModalState, setPayNowModalState] = useState(false);
    const [data, dispatchData] = useContext(DataMapping);
    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    })
    const [alertPopUpModal, setAlertPopUpModal] = useState(false);
    const [alertPopUpContent, setAlertPopUpContent] = useState({
        "title": "",
        "message": ""
    })
    const [successPopUpModal, setSuccessPopUpModal] = useState(false);
    const [successPopUpContent, setSuccessPopUpContent] = useState("");
    const [payNowContent, setPayNowContent] = useState([
        {
            label: "Loan Account Number",
            value: ""
        },
        {
            label: "Total O/s Payment",
            value: ""
        },
    ])

    useEffect(() => {
        setPayNowContent(populatePayNowContent())
    }, [])

    const populatePayNowContent = () => {
        var payNowContentArray = []
        if (data?.["summary"]) {
            payNowContentArray = [
                {
                    label: "Loan Account Number",
                    value: data?.summary?.[activeContract][0].LoanAccount[0].Account_Number
                },
                {
                    label: "Total O/s Payment",
                    value: "₹" + parseFloat(Number(data?.summary?.[activeContract][0].LoanAccount[0].TotalOutstanding)).toLocaleString('en-IN')
                },
            ]

        } else {
            payNowContentArray = [
                {
                    label: "Loan Account Number",
                    value: ""
                },
                {
                    label: "Total O/s Payment",
                    value: ""
                },
            ]
        }
        return payNowContentArray

    }

    const checkValidation = (event) => {
        setAmount(event.target.value);
    }

    const validationfn = () => {

        var regx = /[0-9]/;
        var validationFlag = true;
        setAmountError("")
        if (amount.trim() !== '' && amount > 0) {
            if (regx.test(amount) && parseInt(amount) <= parseInt(payNowContent[1].value.replace(/,/g, '').replace('₹', ''))) {
                // $('#pay-now-error').hide();
                setAmountError("")
            } else {
                validationFlag = false;
                setAmountError("Please enter valid amount")
            }
        } else {
            validationFlag = false;
            setAmountError("Please enter valid amount")
        }

        return validationFlag
    }

    const onPayNowSubmit = () => {
        if (validationfn()) {
            let getServerTimeRequest = {
                "header": {
                    "authToken": data.authToken
                },
                "body": {
                }
            }
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            commonFunctionObject.getServerTimeService(getServerTimeRequest).then((currentServerDateTime) => {
                var serverTime = new Date(commonFunctionObject.replaceHyphenDate(currentServerDateTime));
                var getHours = serverTime.getHours();
                if (getHours >= 9 && getHours < 17) {
                    var scheduleDetailsVal = "";
                    var lasContaract = data?.summary?.[activeContract];
                    var name = lasContaract[0].LoanAccount[0].LoanAccountName;
                    if (lasContaract[0]?.LoanAccount[0]?.ScheduleDetails && lasContaract[0].LoanAccount[0].ScheduleDetails !== "") {
                        scheduleDetailsVal = (lasContaract[0].LoanAccount[0].ScheduleDetails[0].LoanNumber).toString()
                    }
                    var paymentGatewayRequest = {
                        "header": {
                            "authToken": data.authToken
                        },
                        "body": {
                            "customerName": name,
                            "contractNo": lasContaract[0].LoanAccount[0].Account_Number,
                            "emailId": lasContaract[0].mailID,
                            "mobileNo": lasContaract[0].MobileNumber.trim(),
                            "totalAmount": amount,
                            "payeeBankAccountNo": lasContaract[0].bankAccountNumber,
                            "payeeBankAccountName": lasContaract[0].bankName,
                            "gcid": lasContaract[0].gcid,
                            "scheduleLoanNo": scheduleDetailsVal
                        }
                    }

                    setFreezeFlag(true)
                    setFreezeTime(parseFloat(600000 / 1000).toString())
                    setFunctionalityFreezeFlag(true)
                    LasAccountDetailsServiceObject.paymentGatewayService(paymentGatewayRequest).then((finalURL) => {
                        window.open(finalURL, "_self");
                    }).catch((error) => {
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    })
                } else {
                    setAlertPopUpContent({
                        title: "Action Not Allowed",
                        message: "Kindly Initiate the Payment Request Between 9:00 am to 5:00 pm"
                    })
                    setAlertPopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                }
            }).catch((error) => {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            })
        }
    }


    return (
        <div>
            {alertPopUpModal ? <AlertPopUp
                onClick={() => setAlertPopUpModal(false)}
                visible={alertPopUpModal}
                setAlertModalStat={setAlertPopUpModal}
                alertPopUpContent={alertPopUpContent}
            /> : null}
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {successPopUpModal ? <SuccessPopUp
                onClick={() => setSuccessPopUpModal(false)}
                visible={successPopUpModal}
                setSuccessModalStat={setSuccessPopUpModal}
                successPopUpContent={successPopUpContent}
            /> : null}
            <Modal>
                <div
                    className="pay_Now_ModalMainDiv"
                    style={{
                        background: `${themeState === THEME_DARK ? "#23242E" : "#fff"}`,
                        color: `${themeState === THEME_DARK ? "#fff" : "#000"}`,
                    }}
                >
                    <div className="pay_NowModal_Heading_Div">
                        <p className="pay_Now_ModalHeadText">Pay Now</p>
                        <div className="pay_Now_CancelImgDiv">
                            <img
                                src="assets/images/cancel.svg"
                                className="pay_Now_CancelImg"
                                onClick={() => {
                                    setModalState(false);
                                }}
                            />
                        </div>
                    </div>
                    <div className="pay_Now_Wrap_Div">

                        {payNowContent && payNowContent.map((obj, key) => {
                            return (
                                <div className="pay_Now_Left_Div" key={key}>
                                    <div>
                                        <p className="pay_Now_ModatText">{obj.label}</p>
                                        <p className="pay_Now_NumberText">{obj.value}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="pay_Now_Request_Amount_Div">
                        <div className="pay_Now_AmountTP_TextDiv">
                            <p>Amount to Pay</p>
                        </div>
                        <div
                            className="pay_Now_Enter_Amount_FlexDiv"
                            style={{
                                backgroundColor:
                                    themeState === THEME_DARK ? "#2e2f36" : "#ecf0f5",
                            }}
                        >
                            <div
                                className="pay_Now_Rupee_ImgDiv"
                                style={{
                                    backgroundColor:
                                        themeState === THEME_DARK ? "#2e2f36" : "#ecf0f5",
                                }}
                            >
                                <img
                                    src="assets/images/rupee-Img.svg"
                                    style={{
                                        filter: `${themeState === THEME_DARK ? "invert(1)" : ""}`,
                                        height: 15,
                                        width: 15,
                                    }}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="pay_Now_Enter_Amount_Input"
                                    placeholder="Enter Amount"
                                    value={amount}
                                    style={{
                                        backgroundColor:
                                            themeState === THEME_DARK ? "#2e2f36" : "#ecf0f5",
                                    }}
                                    onChange={(event) => {
                                        checkValidation(event)
                                    }}
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="amountError">
                        <p>{amountError}</p>
                    </div>
                    {freezeFlag === true ? <div className="freezeMessageDiv">
                        <p className="freezeMessage">These feature will be available after {freezeTimer} mins</p>
                        <p className="freezeMessage">Please do not refresh the window or close the browser or click log out button till completion of freeze time.</p>
                    </div> : null}
                    <div className="pay_Now_Submit_BtnDiv">
                        <Button className="pay_Now_Submit_Btn " onClick={() => {
                            onPayNowSubmit()
                        }}
                            style={{ cursor: `${freezeFlag === true ? "no-drop" : "pointer"}` }}
                            disable={freezeFlag === true ? true : false}>
                            <p className="pay_Now_Submit_Btn_Text">Submit</p>
                            <img
                                src="assets/images/arrow-right.svg"
                                className="pay_Now_Submit_Arrow_Right"
                            />
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

PayNowPopup.propTypes = {
    activeContract: PropTypes.number,
    setModalState : PropTypes.func,
    setFreezeFlag : PropTypes.func,
    setFreezeTime : PropTypes.func,
    setFunctionalityFreezeFlag : PropTypes.func,
    freezeFlag : PropTypes.bool,
    freezeTimer : PropTypes.string
}


export default PayNowPopup;
