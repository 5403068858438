import React, { useContext } from "react";
import "./FailurePopUp.scss"
import Modal from "../../global/Modal";
import commonFunctionObject from "../../common-function";
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import PropTypes from 'prop-types';

const FailurePopUp = ({ onClick, setFailureModalStat, failurePopUpContent }) => {
    const { analytics } = useContext(AnalyticsContextProvider);

    commonFunctionObject.apiserverErrorAnalytics(failurePopUpContent.type, failurePopUpContent.message, analytics)


    return (
        <>
            <Modal>
                <div className="failure-container">
                    <div className="display-failure-popUp">
                        <div className="failure-img">
                            <img src="assets/images/failure-cross.svg" alt="" />
                        </div>
                        <div className="failure-popUp-content">
                            <div className="failure-popUp-header">
                                <p>Something went wrong</p>
                                <div className="failure-close-icon" onClick={onClick}>
                                    <img src="assets/images/red-cross.svg" alt="" />
                                </div>
                            </div>
                            <div className="failure-popUp-message">
                                <p>{failurePopUpContent.message} {isNaN(Number(failurePopUpContent.errorCode)) ? "" : failurePopUpContent.errorCode && "[" + failurePopUpContent.errorCode + "]"} </p>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

FailurePopUp.propTypes = {
    onClick: PropTypes.func,
    failurePopUpContent: PropTypes.object,
}

export default FailurePopUp