import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getGenerateSoaReport = (data) => {
    let requestJson = {
        "header": {
            "authToken": "4bc48227cff30755cac605b46f5ef8dc2f23dfd24369a04dd6a878520ed95966",
            "productFamily": "TCHFL"
        },

        "body": {
            "accountNumber": "TCHHL0456000100000502",
            "fromDate": "01/01/2020",
            "toDate": "01/03/2022"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/finnone/generate-soa-report/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/finnone/generate-soa-report/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getGenerateSoaReport;