import React from "react";
import "./ToolsAndCalcLoantype.scss";
import RadiantBorder from "../../global/RadiantBorder/RadiantBorder";
import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import DataMapping from "../../../../Shared/context/data-mapping";
import commonFunctionObject from "../../common-function";
const ToolsAndCalcLoantype = ({ label, imageName }) => {
  const [themeState] = useContext(ThemeContext);
  const { analytics } = useContext(AnalyticsContextProvider);
  const [data, setData] = useContext(DataMapping);
  const handleLoanProductClick = (label) => {
    var accountNumber = data?.currentActiveData.accountNumber
    var currentUserData = data?.parsedUserData[accountNumber]
    let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
    var gcid = currentUserData.GCID;
    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: gcid,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "",
        componentName: "ToolsAndCalcLoantype",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "",
        calculatorName: label,
        eventName: "Calculator"
      }
    }
    analytics.trackData("selectCalculator", analytics_data);
    switch (label) {
      case "Personal Loan":
        window.open('https://www.tatacapital.com/personal-loan/emi-calculator.html')
        break;

      case "Home Loan":
        window.open('https://www.tatacapital.com/home-loan/emi-calculator.html')
        break;

      case "Business Loan":
        window.open('https://www.tatacapital.com/business-loan/emi-calculator.html')
        break;

      case "Loan Against Property":
        window.open('https://www.tatacapital.com/loan-against-property/emi-calculator.html');
        break;

      case "Loan Against Securities":
        window.open('https://www.tatacapital.com/loan-against-securities/securities-calculator/loan-against-security-calculator.html');
        break;

      default:
        break;
    }
  }

  return (
    <div
      onClick={() => handleLoanProductClick(label)}
      style={{
        background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
      }}
      className="toolsncalc-loan-type-component-container"
    >
      <img
        src={`assets/images/${imageName}`}
        className="loan-type-image-icon"
      />
      <p
        className="toolsncalc-loan-type-label"
        style={{
          color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
        }}
      >
        {label}
      </p>
    </div>
  );
};

export default ToolsAndCalcLoantype;
