import andMany from './andOperatorMany';
import isDefined from './isDefined';
import _isLenPropDef from './isLengthPropertyDefined';
import isGt from './isGreaterThan';
import isEqRef from './isEqualByReference';
import isEqVal from './isEqualByValue';
import isFunction from './isFunction';
import andOperator from './andOperator';
import isBooleanFalse from './isBooleanFalse';
import isBooleanTrue from './isBooleanTrue';
import isLessThan from './isLessThan';

export default function _operatorOneWithMany(fnOp, args) {
    if (andMany(isFunction(fnOp), isDefined(args), _isLenPropDef(args))) {
        var status = isEqRef(fnOp, andOperator);
        if (isGt(args.length, 2)) {
            var value = args[0];
            var fnBool = isEqRef(fnOp, andOperator) ? isBooleanFalse : isBooleanTrue;
            for (var i = 1; isLessThan(i, args.length); i++) {
                status = fnOp(status, isEqVal(value, args[i]));
                if (fnBool(status)) {
                    break;
                }
            }
        }
        return status;
    }
    return false;
};