import isTrue from './isBooleanTrue';
import isIE from './isIE';
import isValidDate from './isValidDate';
import isNum from './isNumber';
import isString from './isString';
import commonFunctionObject from '../../../common-function';

export default function toDateByTimeZoneOffset(utcDate, timezoneOffset) {
    if (isTrue(isIE())) {
        utcDate = utcDate.replace(/-/g, "/");
    }
    if (isValidDate(utcDate) && isNum(timezoneOffset)) {
        utcDate = isString(utcDate) ? new Date(commonFunctionObject.replaceHyphenDate(Date.parse(utcDate))) : utcDate;
        return new Date(commonFunctionObject.replaceHyphenDate(Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), utcDate.getHours(), utcDate.getMinutes(), utcDate.getSeconds(), utcDate.getMilliseconds()) + ((timezoneOffset + utcDate.getTimezoneOffset()) * 60000)));
    }
    return utcDate;
} 