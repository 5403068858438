import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const generateCaptcha = (data) => {

    let requestJson = {
        
            "header":{
               "isLegacy":"n" 
            },
            "body":{}
        
    }

    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/services/miles/fetch-ltv-isin-mapping-details', requestJson).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/otp/generate-captcha/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default generateCaptcha;