import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const pincodeMaster = (data) => {

    let requestJson = {
        "header": {
            "authToken": "",
            "filter": "countryCode"
        },
        "body": {
            "value": "12501"
        }
    }

    return new Promise((resolve, reject) => {
        // axios.post("/shaft/api/master/pincode-master/v1", data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/master/pincode-master/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default pincodeMaster;