import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getTrenchReport = (data) => {

    let requestJson = {
        "header": {
            "authToken": "",
            "company": "CFAB"
        },
        "body": {
            "bukrs": "7000",
            "pName": "ZTCL_PL",
            "groupKey": "BAIPK5040D",
            "manuCoCode": "PLTCL00001",
            "date": "2023-02-03"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/ccod/get-trench-report/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/ccod/get-trench-report/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getTrenchReport;