const loanDetailsObject = {}
const amountInformation = [
    {
        "label": "Applied Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "loanAvailAmt",
        "productFamilay": [],
        "product": []
    },
    {
        "label": "Sanctioned Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "sanctionAmount",
        "productFamilay": [],
        "product": []
    },
    {
        "label": "Disbursed Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "disbursedAmount",
        "productFamilay": [],
        "product": []
    },
    {
        "label": "Outstanding Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "outstandingAmount",
        "productFamilay": ["cfab"],
        "product": []
    },
    {
        "label": "Total Overdue Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "overdueAmount",
        "productFamilay": [],
        "product": []
    },
    {
        "label": "Unpaid Interest Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "unpaidInterest",
        "productFamilay": [],
        "product": []
    }
    , {
        "label": "Unpaid Principal Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "unpaidPrincipleBalance",
        "productFamilay": ["cfab"]
    }, {
        "label": "Unpaid Charges Balance",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "unpaidChargeBalance",
        "productFamilay": []
    },
    {
        "label": "Overdue Principal Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "overdueprincipleAmount",
        "productFamilay": [],
        "product": []
    },
    {
        "label": "Overdue Charge Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "overdueChargeAmount",
        "productFamilay": [],
        "product": []
    },

    // {
    //     "label": "Termination Date",
    //     "status": ["closed"],
    //     "isActive": false,
    //     "apiKey": "terminationDate",
    //     "productFamilay": [],
    //     "product" : []

    // },
    // {
    //     "label": "Termination Date",
    //     "status": ["active", "closed"],
    //     "isActive": false,
    //     "apiKey": "noofAdvance",
    //     "productFamilay": [],
    //     "product": ["CONSUMER DURABLE"]

    // },

]

const interestDetails = [
    {
        "label": "Rate of Interest(%)",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "rateOfInterest",
        "productFamilay": []
    },
    {
        "label": "Current EMI Amount",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "emiAmount",
        "productFamilay": [],
        "product": []
    },
    {
       "label": "Last Paid EMI Date",
       "status": ["active", "closed"],
       "isActive": false,
       "apiKey": "lastamountpaidDate",
       "productFamilay": []
   },
   {
       "label": "Next EMI Due Date",
       "status": ["active", "closed"],
       "isActive": false,
       "apiKey": "nextEmiDate",
       "productFamilay": []
   }
    // , {
    //     "label": "Unpaid Interest Amount",
    //     "status": ["active", "closed"],
    //     "isActive": false,
    //     "apiKey": "unpaidInterest",
    //     "productFamilay": []
    // }, {
    //     "label": "Unpaid Charges Balance",
    //     "status": ["active", "closed"],
    //     "isActive": false,
    //     "apiKey": "unpaidChargeBalance",
    //     "productFamilay": []
    // }, {
    //     "label": "Unpaid Principal Balance",
    //     "status": ["active", "closed"],
    //     "isActive": false,
    //     "apiKey": "unpaidPrincipleBalance",
    //     "productFamilay": ["cfab"]
    // },
]

const loanTerm = [
    {
        "label": "Disbursement Date",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "loanAvailDate",
        "productFamilay": []
    },
    {
        "label": "Total Tenure (Months)",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "loanPeriod",
        "productFamilay": []
    }, {
        "label": "Balance Tenure (Months)",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "balanceTenure",
        "productFamilay": []
    }, 
    {
        "label": "Loan Maturity Date",
        "status": ["active", "closed"],
        "isActive": false,
        "apiKey": "emiendDate",
        "productFamilay": []
    },
]
loanDetailsObject.loanTerm = loanTerm
loanDetailsObject.interestDetails = interestDetails
loanDetailsObject.amountInformation = amountInformation
export default loanDetailsObject 