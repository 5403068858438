import React, { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import Modal from "../../global/Modal";
import './RcUpdationPopup.scss';
import PropTypes from 'prop-types';

const RcUpdationPopup = ({ setcheckredirection }) => {
    const [themeState] = useContext(ThemeContext);
    return (
        <div>
            <Modal>
                <div className='rcupdation-modal-popup' style={{
                    background: `${themeState === "THEME_DARK" ? "#22232E" : "#FFFFFF"}`
                }}>
                    <div className='close-btn'>
                    </div>
                    <div className='description' style={{
                        color: `${themeState === "THEME_DARK" ? "#fff" : "#000000"}`,
                    }}>
                        <p>
                            Vehicle registration number is already updated.
                        </p>
                    </div>
                    <div className='ok-btn'>
                        <a onClick={() => setcheckredirection(false)}>OK</a>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
RcUpdationPopup.prototypes = {
    setcheckredirection: PropTypes.func
}


export default RcUpdationPopup;
