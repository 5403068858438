import React, { useContext, useState } from "react";
import "./Logout.scss";
import { useLocation, useNavigate } from "react-router-dom";
import DataMapping from "../../../../Shared/context/data-mapping";
import LogoutServiceObject from "../../services/LogoutService";
import commonFunctionObject from "../../common-function/commonfunctions";
import QualitricsPopUp from "../../global/QualitricsPopUp";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import { resetAccountNumberArray } from "../../../../Collection/src/services/dispatchUserData";
// import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import AnalyticsContextProvider from '../../context/AnalyticsContext/AnalyticsContextProvider';
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import { eventLoggingDtlsApiCall } from "../../../../Collection/src/services/eventLoggingDtlsApiCall";

const Logout = ({ portalType }) => {
  const [contextData, dispatchData] = useContext(DataMapping);
  const navigate = useNavigate();
  const [qualitricsFlag, setQualitricsFlag] = useState(false);
  const [themeState] = useContext(ThemeContext);
  const { analytics } = useContext(AnalyticsContextProvider);

  const logoutUser = () => {
    var accountNumber = contextData?.currentActiveData.accountNumber;
    var currentUserData = contextData?.parsedUserData[accountNumber];
    var gcid = currentUserData.GCID;
    let productName =
      contextData?.currentActiveData?.system === "las"
        ? "LAS"
        : contextData?.currentActiveData?.system === "ccod"
        ? "CCOD"
        : commonFunctionObject.getProductName(currentUserData);
    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: gcid,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: "",
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "",
        componentName: "Footer",
        bannerTitle: "",
        menuTitle: "Logout",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "",
        eventName: "Menu Interaction",
      },
    };

    function formatDateTime(dateString) {
      // Step 1: Parse the input date string
      const date = new Date(dateString);

      // Helper function to pad single digit numbers with a leading zero
      const padZero = (num) => (num < 10 ? "0" + num : num);

      // Step 2: Format to desired output format
      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1); // Months are zero-indexed in JavaScript
      const day = padZero(date.getDate());
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());

      const formattedDateTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

      // Step 3: Extract login date and time
      const loginDateValue = `${year}/${month}/${day}`;
      const loginTimeValue = `${hours}:${minutes}:${seconds}`;

      // Output the results
      console.log(`Formatted DateTime: ${formattedDateTime}`);
      console.log(`loginDate: ${loginDateValue}`);
      console.log(`loginTime: ${loginTimeValue}`);

      return {
        formattedDateTime,
        loginDateValue,
        loginTimeValue,
      };
    }

    function convertUTCToIST(timeString) {
      // Parse the input time string (assumed to be in "HH:MM:SS" format)
      const [hours, minutes, seconds] = timeString.split(":").map(Number);

      // Create a new Date object for the current date in UTC with the given time
      const utcDate = new Date(Date.UTC(2024, 7, 2, hours, minutes, seconds)); // August is month 7 (0-indexed)

      // Add 5 hours and 30 minutes to the UTC time to convert to IST
      const istOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
      const istDate = new Date(utcDate.getTime() + istOffset);

      // Extract IST time components
      const padZero = (num) => (num < 10 ? "0" + num : num);

      const hoursIST = padZero(istDate.getUTCHours());
      const minutesIST = padZero(istDate.getUTCMinutes());
      const secondsIST = padZero(istDate.getUTCSeconds());

      const formattedISTTime = `${hoursIST}:${minutesIST}:${secondsIST}`;

      return formattedISTTime;
    }

    const eventUserDetails = contextData?.eventUserDetails || [];

    let newEventId = "";
    let newPaymentTransactionId = "";

    if (eventUserDetails.length > 1) {
      newEventId = "";
      newPaymentTransactionId = "";
    } else if (eventUserDetails.length == 1) {
      if (contextData?.uniqueTransactionId) {
        newEventId = contextData?.latestPaymentTransactionDetails?.eventIdData;
        newPaymentTransactionId =
          contextData?.latestPaymentTransactionDetails
            ?.paymentTransactionIdData;
      } else {
        newEventId = "";
        newPaymentTransactionId = "";
      }
    }

    let currentdate = new Date();
    let datetime = `${currentdate.getFullYear()}/${(
      "0" +
      (currentdate.getMonth() + 1)
    ).slice(-2)}/${("0" + currentdate.getDate()).slice(-2)} ${(
      "0" + currentdate.getHours()
    ).slice(-2)}:${("0" + currentdate.getMinutes()).slice(-2)}:${(
      "0" + currentdate.getSeconds()
    ).slice(-2)}`;

    const loggedInTimeDate = formatDateTime(
      contextData.getUserDataV3.body.LAST_LOGGED_IN
    );

    const istTimestamp = convertUTCToIST(loggedInTimeDate?.loginTimeValue);

    let finalPaymentOptionEntity = "";
    let finalPaymentOptionAmount = "";
    finalPaymentOptionEntity = contextData?.latestPaymentData
      ? contextData?.latestPaymentData?.phase == 1
        ? contextData?.latestPaymentData?.labelName
        : contextData?.latestPaymentData?.phase == 2
        ? ""
        : ""
      : "";
    finalPaymentOptionAmount = contextData?.latestPaymentData
      ? contextData?.latestPaymentData?.phase == 1
        ? contextData?.latestPaymentData?.amount.replace(/,/g, '')
        : contextData?.latestPaymentData?.phase == 2
        ? ""
        : ""
      : "";

    let logoutEventDetails = {
      eventName: "logoutClick",
      eventId: newEventId,
      eventTimestamp: datetime,
      primaryGcid:
        contextData.parsedUserData[contextData.currentActiveData.accountNumber]
          .PRIMARY_GCID,
      userId: contextData.getUserDataV3.body.USER_ID,
      token: contextData.authToken,
      loginDate: loggedInTimeDate?.loginDateValue,
      loginTime: istTimestamp,
      loginMethod: contextData?.setLoginType || null,
      paymentTransactionId: newPaymentTransactionId,
      finalAccountNumber: contextData.currentActiveData.accountNumber,
      finalPaymentOption: finalPaymentOptionEntity,
      finalPaymentAmt: finalPaymentOptionAmount,
      creditScoreChecked:
        contextData.getUserDataV3.body.CREDIT_SCORE === "" ? "N" : "Y",
      finalAdditionalMobile: "",
      finalAdditionalEmail: "",
      logoutTime: datetime,
      eventDetails:
        eventUserDetails.length == 1 && contextData.uniqueTransactionId
          ? []
          : eventUserDetails.length == 1 && !contextData.uniqueTransactionId
          ? contextData?.eventUserDetails
          : eventUserDetails.length > 1
          ? contextData?.eventUserDetails
          : [],
    };
    console.log(logoutEventDetails, "logoutEventDetails");

    eventLoggingDtlsApiCall(logoutEventDetails, contextData.authToken);

    analytics.trackData("menuInteraction", analytics_data);
    if (portalType !== "collection") {
      if (
        contextData.getUserDataV3.body.IS_FEEDBACK_SUBMITTED === "" ||
        contextData.getUserDataV3.body.IS_FEEDBACK_SUBMITTED === "N"
      ) {
        setQualitricsFlag(true);
      }
      // qualitricsApiCall();
      else {
        resetAccountNumberArray();
        logOutApiCall();
        navigate("/login");
      }
    } else {
      resetAccountNumberArray();
      logOutApiCall();
      navigate("/login");
    }
  };

  const logOutApiCall = () => {
    var reqObj = {
      header: {
        authToken: contextData.authToken,
      },
      body: {},
    };
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });

    LogoutServiceObject.logOutUserService(reqObj)
      .then(() => {
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
        // navigate("/login")
      })
      .catch(() => {
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
        navigate("/login");
      });
    navigate("/login");
  };
  return (
    <>
      {qualitricsFlag ? (
        <QualitricsPopUp logOutApiCall={logOutApiCall} />
      ) : null}

      <div
        className="logout-container "
        onClick={() => {
          logoutUser();
        }}
      >
        <div className="tooltip">
          <img src="assets/images/logout-icon.svg" alt="logout icon" />
          <div
            className={`${
              themeState === "THEME_DARK"
                ? "logout-tooltip THEME_DARK"
                : "logout-tooltip"
            }`}
          >
            <div className="tooltip-text">Logout</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logout;
