import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const convertOfferToOpportunity = (data) => {

    let requestJson = {
        "header": {
            "authToken": "117737a4e1105e8e04332a04ece539f0e1eceb1788a642bd8c9a7526b2575830",
        },
        "body": {
            "OfferId": "offer-202110815875",
            "Product": "Auto Name",

        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/sfdc/convert-offer-to-opportunity/v1', data).then(response => {
        stalwartApiCall('POST',data,'/shaft/api/sfdc/convert-offer-to-opportunity/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default convertOfferToOpportunity;