import React, { useEffect, useState, useRef } from "react";
import "./LASHoldingDetails.scss";
import CustomDropDown from "../../global/CustomDropDown";
import RadiantBorder from "../../global/RadiantBorder";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../../../Shared/context/ThemeContext/store/PageNameContext";
import { useContext } from "react";
import LasHoldingRow from "../LasHoldingRow/LasHoldingRow";
import LasHoldingCheckboxRow from "../LasHoldingCheckboxRow/LasHoldingCheckboxRow";
import Button from "../../global/Button";
import DrawDownRequestPopup from "../DrawDownRequestPopup";
import PayNowPopup from "../PayNowPopup";
import DepledgingPopup from "../DepledgingPopup";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREACCOUNTDATA, STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
import LasAccountDetailsServiceObject from "../../services/LasAccountDetailsService";
import commonFunctionObject from "../../common-function";
import OverviewServiceObject from "../../services/Overview";
import AlertPopUp from "../../global/AlertPopUp";
import PropTypes from 'prop-types';
const LASHoldingDetails = ({ activeContract, payModalState, setPayModalState, drawModalState, setDrawModalState, currentHoldingTableArray, setCurrentHoldingTableArray, freezeFlag, freezeTimer, noDataFlag, setFreezeFlag, setFreezeTime, setFunctionalityFreezeFlag, postOtpFlag, setPostOtpFlag, lasData, setLasData, refNo, setRefNo }) => {
  const [themeState, dispatch, otpState, otpDispatch] =
    useContext(ThemeContext);
  const [optionState, setOptionState] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [data, dispatchData] = useContext(DataMapping);
  const { pageName, setPageName } = useContext(pageNameContext);
  const [masterPercentage, setMasterPercentage] = useState(0);
  const [slavePercentage, setSlavePercentage] = useState(0);

  const [depModalState, setDepModalState] = useState(false);

  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    "apiName": "",
    "message": "",
    "errorCode": ""
  })
  const [alertPopUpModal, setAlertPopUpModal] = useState(false);
  const [alertPopUpContent, setAlertPopUpContent] = useState({
    "title": "",
    "message": ""
  })
  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");
  const [depledgeError, setDepledgeError] = useState("")
  const ref = useRef();
  const vertRef = useRef();
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const scrollVertical = (scrollOffset) => {
    ref.current.scrollTop += scrollOffset;
  };
  const handleMasterScroll = (e) => {
    let slave = e.target.parentNode.parentNode.querySelector(".table-two");
    let master = e.target;
    let height = master.scrollHeight - master.clientHeight;
    let percentage = (master.scrollTop / height) * 100;
    let slaveHeight = slave.scrollHeight - slave.clientHeight;
    let newSlavePosition = Math.ceil((master.scrollTop / height) * slaveHeight);
    setMasterPercentage(percentage);
    slave.scroll({
      top: newSlavePosition,
    });
  };

  const handleSlaveScroll = (e) => {
    let slave = e.target;
    let master = e.target.parentNode.parentNode.querySelector(
      ".table-one-rows-container"
    );
    let height = slave.scrollHeight - slave.clientHeight;
    let percentage = (slave.scrollTop / height) * 100;
    let masterHeight = master.scrollHeight - master.clientHeight;
    let newMasterPosition = (slave.scrollTop / height) * masterHeight;
    setSlavePercentage(percentage);
    master.scroll({
      top: newMasterPosition,
    });
  };

  const [optionArray, setOptionArray] = useState([])

  useEffect(() => {
    setPageName("Loan Against Security Detail");
    setHoldingTypeArray()
    populateCurrentHoldingTable();
  }, []);

  const setHoldingTypeArray = () => {
    var holdingTypeOption = [{
      value: "-"
    }]
    if (data?.["checkEquityFlag"]?.[activeContract] === false) {
      holdingTypeOption = [{
        value: "Mutual Funds"
      }]
      setSelectedOption("Mutual Funds")
    } else if (data?.["checkEquityFlag"]?.[activeContract] === true) {
      holdingTypeOption = [{
        value: "Equity"
      }]
      setSelectedOption("Equity")
    }
    setOptionArray(holdingTypeOption)

  }

  const initiateIncrementalPledging = () => {
    if (!isDebarred()) {
      var getHolidayDetailsRequest = {
        "header": {
          "authToken": data.authToken
        },
        "body": {
          "journeyType": "pledging"
        }
      }
      dispatchData({
        name: "loaderFlag",
        payload: true,
        type: STOREDATA
      })
      LasAccountDetailsServiceObject.getHolidayDetailsService(getHolidayDetailsRequest).then((getHolidayDetailsResponse) => {
        if (Object.keys(getHolidayDetailsResponse.body).length === 0) {
          let getServerTimeRequest = {
            "header": {
              "authToken": data.authToken
            },
            "body": {
            }
          }
          commonFunctionObject.getServerTimeService(getServerTimeRequest).then((getServerDateTime) => {

            var serverTime = new Date(commonFunctionObject.replaceHyphenDate(getServerDateTime));
            var getHours = serverTime.getHours();
            if (getHours >= 9 && getHours <= 17) {

              var currentAcHoldingDetails = data?.pledgeDetailsFoliowise[activeContract];
              var holdingType = data?.checkEquityFlag?.[activeContract] === true ? "Equity" : "Mutual-Funds";
              callGetPledgingURLApi(currentAcHoldingDetails, holdingType)
            } else {
              setAlertPopUpContent({
                title: "Action Not Allowed",
                message: "Kindly Initiate the Pledge/Lien request between 9:00 am to 6:00 pm",
              });
              setAlertPopUpModal(true)
              dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
              })
            }
          }).catch((error) => {
            setFailurePopUpContent({
              apiName: "",
              message: "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
              errorCode: error
            });
            setFailurePopUpModal(true)
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA
            })
          })

        } else {
          setFailurePopUpContent({
            apiName: "",
            message: "Additional Pledging is not available today due to" + getHolidayDetailsResponse.body.holidayDetails.holidayName + ". Please try on " + getHolidayDetailsResponse.body.holidayDetails.nextDepledgingWorkingDate + ". Thank you.",
            errorCode: ""
          });
          setFailurePopUpModal(true)
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA
          })
        }
      }).catch((error) => {
        setFailurePopUpContent({
          apiName: "",
          message: "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
          errorCode: error
        });
        setFailurePopUpModal(true)
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA
        })
      })
    } else {
      setAlertPopUpContent({
        title: "Action Not Allowed",
        message: "Due to regulatory restrictions, this service is not available to you. Kindly contact your RM for any clarification",
      });
      setAlertPopUpModal(true)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    }
  }

  const callGetPledgingURLApi = (currentAcHoldingDetails, holdingType) => {
    var reqObjData = [];
    var lasContaract = data?.summary?.[activeContract][0]
    var pledgingUrlRequest = {}
    if (holdingType.toLowerCase() === "equity") {
      var equityCheck = currentAcHoldingDetails.filter(function (value) {
        return value.AssetClass.toLowerCase() == 'demat';
      });
      equityCheck.forEach(function (value) {
        var obj = {};
        obj['isin'] = value.ISIN;
        obj['quantity'] = value.PledgeQty;
        obj['marketValue'] = value.MarketValue;
        reqObjData.push(obj);
      })
      pledgingUrlRequest = {
        "header": {
          "authToken": data.authToken,
          "pledgingType": holdingType == "Mutual-Funds" ? "mutualFund" : holdingType
        },
        "body": {
          "clientId": lasContaract.CustomerID,
          "gcid": lasContaract.gcid,
          "loanAccountNumber": lasContaract.LoanAccount[0].Account_Number,
          "data": reqObjData
        }
      }
    } else {
      pledgingUrlRequest = {
        "header": {
          "authToken": data.authToken,
          "pledgingType": holdingType == "Mutual-Funds" ? "mutualFund" : holdingType
        },
        "body": {
          "clientId": lasContaract.CustomerID,
          "gcid": lasContaract.gcid,
          "loanAccountNumber": lasContaract.LoanAccount[0].Account_Number
        }
      }

    }

    LasAccountDetailsServiceObject.getPledgingURLService(pledgingUrlRequest).then((finalURL) => {
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
      setFreezeTime(parseFloat(600000 / 1000).toString())
      setFreezeFlag(true)
      setFunctionalityFreezeFlag(true)
      window.open(finalURL, "_self");
    }).catch((error) => {
      setFailurePopUpContent({
        apiName: "",
        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
        errorCode: error
      });
      setFailurePopUpModal(true)
      dispatchData({
        name: "loaderFlag",
        payload: false,
        type: STOREDATA
      })
    })
  }

  // const [currentHoldingTableArray, setCurrentHoldingTableArray] = useState([])

  const isDebarred = () => {
    if (data?.["parsedUserData"]?.["lasAccounts"]) {
      if (data?.["parsedUserData"]?.["lasAccounts"].isDebarredCustomer.toUpperCase() === "Y") {
        return true
      } else {
        return false
      }
    }
  }

  const populateCurrentHoldingTable = () => {

    var currentHoldingArray = [];
    if (data?.["pledgeDetailsFoliowise"]?.[activeContract]) {
      data?.["pledgeDetailsFoliowise"]?.[activeContract].map((element) => {
        element["checkedFlag"] = false;
        currentHoldingArray.push(element)
      })

      setCurrentHoldingTableArray(currentHoldingArray)
    }
  }

  const setChecked = (event, key) => {
    if (event.target.checked === true) {
      currentHoldingTableArray[key]["depledgeAmount"] = "";
      currentHoldingTableArray[key]["depledgeErrorMsg"] = "";
      currentHoldingTableArray[key]["checkedFlag"] = true
    } else {
      currentHoldingTableArray[key]["depledgeAmount"] = "";
      currentHoldingTableArray[key]["depledgeErrorMsg"] = "";
      currentHoldingTableArray[key]["checkedFlag"] = false
    }
    console.log(currentHoldingTableArray)
  }

  const depledgingPopOpen = () => {
    var filteredHoldingsData = currentHoldingTableArray.filter((element) => {
      return element.checkedFlag === true;
    })
    if (filteredHoldingsData.length !== 0) {
      setDepledgeError("")
      if (!isDebarred()) {

        let getHolidayDetailsRequest = {
          "header": {
            "authToken": data.authToken
          },
          "body": {
            "journeyType": "depledging"
          }
        }

        dispatchData({
          name: "loaderFlag",
          type: STOREDATA,
          payload: true
        })

        LasAccountDetailsServiceObject.getHolidayDetailsService(getHolidayDetailsRequest).then((getHolidayDetailsResponse) => {
          if (Object.keys(getHolidayDetailsResponse.body).length === 0) {
            let getServerTimeRequest = {
              "header": {
                "authToken": data.authToken
              },
              "body": {
              }
            }
            commonFunctionObject.getServerTimeService(getServerTimeRequest).then((getServerDateTime) => {
              var serverTime = new Date(commonFunctionObject.replaceHyphenDate(getServerDateTime));
              var getHours = serverTime.getHours();
              if (getHours >= 9 && getHours <= 17) {
                if (data?.["checkEquityFlag"]?.[activeContract] === false) {
                  if (Number(data.summary[activeContract][0].LoanAccount[0].marginShortFall) >= 0) {
                    setDepModalState(true);
                    dispatchData({
                      name: "loaderFlag",
                      payload: false,
                      type: STOREDATA
                    })
                  } else {
                    setAlertPopUpContent({
                      title: "Action Not Allowed",
                      message: "Account is in Shortfall, Kindly Regularize Account to Unlien Units",
                    });
                    setAlertPopUpModal(true)
                    dispatchData({
                      name: "loaderFlag",
                      payload: false,
                      type: STOREDATA
                    })
                  }
                } else {
                  setDepModalState(true)
                  dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                  })
                }
              } else {
                setAlertPopUpContent({
                  title: "Action Not Allowed",
                  message: "Please initiate the depledging request between 9:00 am to 6:00 pm",
                });
                setAlertPopUpModal(true)
                dispatchData({
                  name: "loaderFlag",
                  payload: false,
                  type: STOREDATA
                })
              }
            }).catch((error) => {
              dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false
              })
            })
          } else {
            setFailurePopUpContent({
              apiName: "",
              message: "Depledging is not available today due to" + getHolidayDetailsResponse.body.holidayDetails.holidayName + ". Please try on " + getHolidayDetailsResponse.body.holidayDetails.nextDepledgingWorkingDate + ". Thank you.",
              errorCode: ""
            });
            setFailurePopUpModal(true)
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA
            })
          }

        }).catch(() => {
          setFailurePopUpContent({
            "apiName": "",
            "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            "errorCode": ""
          })
          setFailurePopUpModal(true)
          dispatchData({
            name: "loaderFlag",
            type: STOREDATA,
            payload: false
          })
        })

      } else {
        setAlertPopUpContent({
          "title": "Action Not Allowed",
          "message": "Due to regulatory restrictions, this service is not available to you. Kindly contact your RM for any clarification",
        })
        setAlertPopUpModal(true)
      }
    } else {
      setDepledgeError("Please Select a Scrip/Fund to Depledge")
    }

  }

  return (
    <>
      {alertPopUpModal ? <AlertPopUp
        onClick={() => setAlertPopUpModal(false)}
        visible={alertPopUpModal}
        setAlertModalStat={setAlertPopUpModal}
        alertPopUpContent={alertPopUpContent}
      /> : null}
      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
      {successPopUpModal ? <SuccessPopUp
        onClick={() => setSuccessPopUpModal(false)}
        visible={successPopUpModal}
        setSuccessModalStat={setSuccessPopUpModal}
        successPopUpContent={successPopUpContent}
      /> : null}
      {payModalState && <PayNowPopup activeContract={activeContract} setModalState={setPayModalState} setFreezeFlag={setFreezeFlag} setFreezeTime={setFreezeTime} setFunctionalityFreezeFlag={setFunctionalityFreezeFlag} freezeFlag={freezeFlag} freezeTimer={freezeTimer} />}
      {depModalState && <DepledgingPopup activeContract={activeContract} currentHoldingTableArray={currentHoldingTableArray} setModalState={setDepModalState} setSuccessPopUpModalLasFlag={setSuccessPopUpModal} setSuccessPopUpContentLas={setSuccessPopUpContent} setFailurePopUpModalLasFlag={setFailurePopUpModal} setFailurePopUpContentLas={setFailurePopUpContent} setFreezeFlag={setFreezeFlag} setFreezeTime={setFreezeTime} setFunctionalityFreezeFlag={setFunctionalityFreezeFlag} />}
      {drawModalState && (
        <DrawDownRequestPopup activeContract={activeContract} setModalState={setDrawModalState} postOtpFlag={postOtpFlag} setPostOtpFlag={setPostOtpFlag} setSuccessPopUpModalLasFlag={setSuccessPopUpModal} setSuccessPopUpContentLas={setSuccessPopUpContent} setFailurePopUpModalLasFlag={setFailurePopUpModal} setFailurePopUpContentLas={setFailurePopUpContent} setFreezeFlag={setFreezeFlag} setFreezeTime={setFreezeTime} setFunctionalityFreezeFlag={setFunctionalityFreezeFlag} freezeFlag={freezeFlag} freezeTimer={freezeTimer} lasData={lasData} setLasData={setLasData} refNo={refNo} setRefNo={setRefNo} />
      )}
      {
        !postOtpFlag ? <div className="las-holding-details-container">
          <div className="las-drop-down-container">
            <CustomDropDown
              optionArray={optionArray}
              optionState={optionState}
              setOptionState={setOptionState}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              labelFlag={true}
              labelText="Holding Type"
              className={"holding-drop-down"}
              labelClass={"holding-label-text"}
            />
          </div>
          <div className="las-holding-table-main-container">
            <p>See Current Pledgings</p>
          </div>

          <div className="las-holding-table-container">
            {currentHoldingTableArray.length === 0 ? <div className="noDataFoundContainer">
              <p>No Data Found</p>
            </div> :
              <RadiantBorder className={"two-table-radiant-container"}>
                <div className="two-tables-container">
                  <img
                    src="assets/images/blueTopIndicator.svg"
                    alt="left-arrow"
                    className="top-arrow"
                    onClick={() => scrollVertical(-10)}
                  />
                  <img
                    src="assets/images/blueBotIndicator.svg"
                    alt="left-arrow"
                    className="bot-arrow"
                    onClick={() => scrollVertical(10)}
                  />
                  {/* <div className="custom-scroll-bar-outer">
              <div className="custom-scroll-bar-inner"></div>
            </div> */}
                  <div className="table-one">
                    <p className="table-one-para">ISIN</p>
                    <div
                      className="table-one-rows-container"
                      onScroll={handleMasterScroll}
                    >
                      {currentHoldingTableArray.map((obj, key) => {
                        return (
                          <div className="holding-checkox-row" key={key}>
                            <div className="holding-checkbox">
                              <input type={"checkbox"} onChange={(event) => { setChecked(event, key) }} />
                            </div>

                            <label>{obj.ISIN}</label>
                          </div>
                        )
                      }
                        // <LasHoldingCheckboxRow label={obj.ISIN} />
                      )}
                    </div>
                  </div>
                  <div
                    ref={ref}
                    className="table-two"
                    onScroll={handleSlaveScroll}
                  // ref={vertRef}
                  >
                    <img
                      src="assets/images/blueLeftIndicator.svg"
                      alt="left-arrow"
                      className="left-arrow"
                      onClick={() => scroll(-30)}
                    />
                    <img
                      src="assets/images/blueRightIndicator.svg"
                      alt="left-arrow"
                      className="right-arrow"
                      onClick={() => scroll(30)}
                    />
                    {data?.["checkEquityFlag"]?.[activeContract] === true ?
                      <div
                        className="table-two-header-container"
                        style={{
                          background: `${themeState === "THEME_DARK" ? "#23242E" : ""
                            }`,
                        }}
                      >
                        <span className="header-1">Scrip/Scheme Name</span>
                        <span className="header-2">Pledged Qty</span>
                        <span className="header-3">Market Value</span>
                        <span className="header-4">LTV</span>
                        <span className="header-5">Eligibility</span>
                        <span className="header-6">Allowed Quantity</span>
                        <span className="header-7">Pledge Order Number</span>
                        <span className="header-8">D.P Account Number</span>
                      </div> :

                      <div
                        className="table-two-header-container"
                        style={{
                          background: `${themeState === "THEME_DARK" ? "#23242E" : ""
                            }`,
                        }}
                      >
                        <span className="header-1">Scrip/Scheme Name</span>
                        <span className="header-2">Pledged Qty</span>
                        <span className="header-3">Market Value</span>
                        <span className="header-4">LTV</span>
                        <span className="header-5">Eligibility</span>
                        <span className="header-6">Allowed Quantity</span>
                        <span className="header-6">Folio Number</span>
                      </div>

                    }
                    {data?.["checkEquityFlag"]?.[activeContract] === true ?
                      <div
                        className="table-two-row-container"
                        // onScroll={handleSlaveScroll}
                        ref={vertRef}
                      >
                        {currentHoldingTableArray.map((obj, index) => {
                          return (
                            <div className="holding-dynamic-row" key={index}>
                              <span className="header-1">{obj.ScripName}</span>
                              <span className="header-2">{obj.PledgeQty}</span>
                              <span className="header-3">{obj.MarketValue}</span>
                              <span className="header-4">{obj.LTV}</span>
                              <span className="header-5">{obj.Eligibility}</span>
                              <span className="header-6">{obj.PledgeQty}</span>
                              <span className="header-7">{obj.FolioNumber}</span>
                              <span className="header-8">{obj.DpAccountNumber}</span>
                            </div>
                          );
                          // <LasHoldingRow obj={obj} />
                        })}
                      </div> :
                      <div
                        className="table-two-row-container"
                        // onScroll={handleSlaveScroll}
                        ref={vertRef}
                      >
                        {currentHoldingTableArray.map((obj) => {
                          return (
                            <div className="holding-dynamic-row">
                              <span className="header-1">{obj.ScripName}</span>
                              <span className="header-2">{obj.PledgeQty}</span>
                              <span className="header-3">{obj.MarketValue}</span>
                              <span className="header-4">{obj.LTV}</span>
                              <span className="header-5">{obj.Eligibility}</span>
                              <span className="header-6">{obj.PledgeQty}</span>
                              <span className="header-7">{obj.FolioNumber}</span>
                            </div>
                          );
                          // <LasHoldingRow obj={obj} />
                        })}
                      </div>}


                  </div>
                </div>
                <div className="depledgingCheckError">
                  <p>{depledgeError}</p>
                </div>
                <div className="holding-button-container ">

                  <Button
                    style={{ cursor: `${freezeFlag === true ? "no-drop" : "pointer"}` }}
                    className=" loan-details-button holding-button holding-details-page"
                    onClick={() => depledgingPopOpen()}
                    disable={freezeFlag === true ? true : false}
                  >
                    Depledging
                    <img src="assets/images/arrow-right.svg" alt="arrow-right" />
                  </Button>
                </div>
              </RadiantBorder>
            }
            <div className="loan-details-buttons-container holding-details-page">
              <div className="loan-details-button">
                <Button
                  style={{ cursor: `${freezeFlag === true ? "no-drop" : "pointer"}` }}
                  className=" loan-details-button"
                  onClick={() => initiateIncrementalPledging()}
                  disable={freezeFlag === true ? true : false}
                >
                  Incremental Pledging
                  <img src="assets/images/arrow-right.svg" alt="arrow-right" />
                </Button>
              </div>
              <div className="loan-details-button">
                <Button
                  style={{ cursor: `${freezeFlag === true || noDataFlag === true ? "no-drop" : "pointer"}` }}
                  className=" loan-details-button"
                  onClick={() => setDrawModalState(true)}
                  disable={freezeFlag === true || noDataFlag === true ? true : false}
                >
                  Draw Down Request
                  <img src="assets/images/arrow-right.svg" alt="arrow-right" />
                </Button>
              </div>
              <div className="loan-details-button">
                <Button
                  style={{ cursor: `${freezeFlag === true ? "no-drop" : "pointer"}` }}
                  className=" loan-details-button"
                  onClick={() => setPayModalState(true)}
                  disable={freezeFlag === true ? true : false}
                >
                  Pay Due Now
                  <img src="assets/images/arrow-right.svg" alt="arrow-right" />
                </Button>
              </div>
            </div>
            {freezeFlag === true ? <div className="freezeMessageDiv">
              <p className="freezeMessage">These feature will be available after {freezeTimer} mins</p>
              <p className="freezeMessage">Please do not refresh the window or close the browser or click log out button till completion of freeze time.</p>
            </div> : null}

          </div>
        </div> : null
      }

    </>
  );
};

LASHoldingDetails.propTypes = {
  activeContract: PropTypes.string,
  drawModalState: PropTypes.bool,
  setDrawModalState: PropTypes.func,
  payModalState: PropTypes.bool,
  setPayModalState: PropTypes.func,
  currentHoldingTableArray: PropTypes.array,
  setCurrentHoldingTableArray: PropTypes.func,
  freezeTimer: PropTypes.string,
  freezeFlag: PropTypes.bool,
  setFreezeTime: PropTypes.func,
  setFreezeFlag: PropTypes.func,
  noDataFlag: PropTypes.bool,
  setFunctionalityFreezeFlag: PropTypes.func,
  postOtpFlag: PropTypes.bool,
  setPostOtpFlag: PropTypes.func,
  lasData: PropTypes.object,
  setLasData: PropTypes.func,
  refNo: PropTypes.string,
  setRefNo: PropTypes.func
};

export default LASHoldingDetails;
