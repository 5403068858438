import React, { useContext } from "react";
import "./EmailPopUp.scss"
import Modal from "../../global/Modal";
import Button from "../Button";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import { useLocation, useNavigate } from "react-router-dom";
import commonFunctionObject from "../../common-function";
import DataMapping from "../../../../Shared/context/data-mapping";
import PropTypes from 'prop-types';


const EmailPopUp = ({ emailPopUpObject, setemailPopup, accountNumber, changeRoute, screenName, setCurrentPage,portalType }) => {

    const [data, dispatchData] = useContext(DataMapping);
    var loanStatus = commonFunctionObject.checkContractStatus(data?.getLoanPositionDTLS[data?.currentActiveData.accountNumber].body.getLoanPositionDtlsReturn.loanStatus)
    const [themeState] = useContext(ThemeContext);
    const navigate = useNavigate();
    const checkLocation = useLocation();
    const redirectTo = (redirectTo) => {
        if (checkLocation.pathname.toLowerCase() === "/customer-service") {
            changeRoute("raiseNewTicket");
            setCurrentPage("change-email-id");
        } else {
            navigate("/customer-service", { state: { screenName: "change-email-id" } })
        }
    }


    return (
        <>
            <Modal style={{
                background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""
                    }`,
            }}>
                <div
                    className="welcome-letter-emal-popup email-modal-popup"
                    style={{
                        background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
                    }}
                >
                    <div className="close-btn" onClick={() => { setemailPopup(false) }}>
                        {/* <img src="assets/images/crossImg.svg" alt="close"  /> */}
                        <img src={"assets/images/crossImg.svg"} alt="close" />
                    </div>
                    <div className="description">
                        {
                            portalType === "collection" ? emailPopUpObject.emailFlag ? loanStatus ? 
                                <p>
                                    Dear <span class="email-account-number capitalizedAphabets">{emailPopUpObject.customerName.toLowerCase()}</span>,<br></br> We have sent <span class="email-account-number">{emailPopUpObject.certificateName}</span> for your Loan Account Number <span class="email-account-number">{emailPopUpObject.loanAccNumber}</span> on your registered email address <span class="email-account-number">{emailPopUpObject.email}</span> too.
                                    {/* {emailPopup} */}
                                    {/*  */}
                                </p> : <p>
                                    Dear <span class="email-account-number capitalizedAphabets">{emailPopUpObject.customerName.toLowerCase()}</span>,<br></br> We have sent <span class="email-account-number">{emailPopUpObject.certificateName}</span> for your Loan Account Number <span class="email-account-number">{emailPopUpObject.loanAccNumber}</span> on your registered email address <span class="email-account-number">{emailPopUpObject.email}</span> too.
                                    {/* {emailPopup} */}
                                    {/*  */}
                                </p>
                                : loanStatus ?
                                        <p>
                                        Dear <span class="email-account-number capitalizedAphabets">{emailPopUpObject.customerName.toLowerCase()}</span>,<br></br> We found that your email address is not registered with us.
                                    </p> : setemailPopup(false)
                            :
                              emailPopUpObject.emailFlag ? loanStatus ? 
                                <p
    
                                    style={{
                                        color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                    }}
                                >
                                    
                                    Dear <span class="email-account-number capitalizedAphabets">{emailPopUpObject.customerName.toLowerCase()}</span>,<br></br> We have sent <span class="email-account-number">{emailPopUpObject.certificateName}</span> for your Loan Account Number <span class="email-account-number">{emailPopUpObject.loanAccNumber}</span> on your registered email address <span class="email-account-number">{emailPopUpObject.email}</span> too. If you wish to change your email address, please <a className="email-sr-link" href='javascript:void(0)' id='emailSr-click'><span onClick={() => { redirectTo("change-email-id") }}>click here</span></a>
                                    {/* {emailPopup} */}
                                    {/*  */}
                                </p> : <p
    
                                    style={{
                                        color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                    }}
                                >
    
                                    Dear <span class="email-account-number capitalizedAphabets">{emailPopUpObject.customerName.toLowerCase()}</span>,<br></br> We have sent <span class="email-account-number">{emailPopUpObject.certificateName}</span> for your Loan Account Number <span class="email-account-number">{emailPopUpObject.loanAccNumber}</span> on your registered email address <span class="email-account-number">{emailPopUpObject.email}</span> too.
                                    {/* {emailPopup} */}
                                    {/*  */}
                                </p>
                                :
                                loanStatus ?
                                    <p
                                        style={{
                                            color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                        }}
                                    >
                                        Dear <span class="email-account-number capitalizedAphabets">{emailPopUpObject.customerName.toLowerCase()}</span>,<br></br> We found that your email address is not registered with us. Please <a className="email-sr-link" href='javascript:void(0)' id='emailSr-click'><span onClick={() => { redirectTo("change-email-id") }}>click here</span></a> to register your email address
                                    </p> : setemailPopup(false)
                            }
                        
                        {/* {emailPopUpObject.emailFlag ? loanStatus ? 
                            <p

                                style={{
                                    color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                }}
                            >
                                 */}
                                {/* Dear <span class="email-account-number capitalizedAphabets">{emailPopUpObject.customerName.toLowerCase()}</span>,<br></br> We have sent <span class="email-account-number">{emailPopUpObject.certificateName}</span> for your Loan Account Number <span class="email-account-number">{emailPopUpObject.loanAccNumber}</span> on your registered email address <span class="email-account-number">{emailPopUpObject.email}</span> too. If you wish to change your email address, please <a className="email-sr-link" href='javascript:void(0)' id='emailSr-click'><span onClick={() => { redirectTo("change-email-id") }}>click here</span></a> */}
                                {/* {emailPopup} */}
                                {/*  */}
                            {/* </p> : <p

                                style={{
                                    color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                }}
                            >

                                Dear <span class="email-account-number capitalizedAphabets">{emailPopUpObject.customerName.toLowerCase()}</span>,<br></br> We have sent <span class="email-account-number">{emailPopUpObject.certificateName}</span> for your Loan Account Number <span class="email-account-number">{emailPopUpObject.loanAccNumber}</span> on your registered email address <span class="email-account-number">{emailPopUpObject.email}</span> too.
                                {/* {emailPopup} */}
                                {/*  */}
                            {/* </p> } */}



                            {/* : */}
                            {/* loanStatus ? */}
                                {/* <p */}
                                    {/* style={{
                                        color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                    }}
                                >
                                    Dear <span class="email-account-number capitalizedAphabets">{emailPopUpObject.customerName.toLowerCase()}</span>,<br></br> We found that your email address is not registered with us. Please <a className="email-sr-link" href='javascript:void(0)' id='emailSr-click'><span onClick={() => { redirectTo("change-email-id") }}>click here</span></a> to register your email address */}
                                {/* </p> : setemailPopup(false) */}
                        {/* } */}
                        {/* {`YOu are ${cust}`} */}
                    </div>
                    <div className="proceed">
                        <Button className="email-popup-button" onClick={() => { setemailPopup(false) }}>OK</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

EmailPopUp.propTypes = {
    emailPopUpObject: PropTypes.object,
    setemailPopup: PropTypes.func,
    changeRoute: PropTypes.func,
    setCurrentPage: PropTypes.func
}

export default EmailPopUp