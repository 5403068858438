import React from "react";
import "./DidYouKnowText.scss";
import PropTypes from "prop-types";

const DidYouKnowText = ({ src = "", alt = "", text = "" }) => {
    return (
        <div className="c_did_you_know_text">
            <div className="c_did_you_know_text_and_img">
                <img className="c_did_you_know_img" src={src} alt={alt} />
                <p className="c_did_you_know_text_para">{text}</p>
            </div>
        </div>
    )
}

DidYouKnowText.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    text: PropTypes.string
}

export default DidYouKnowText;