import React, { useContext } from "react";
import "./CreditScoreReadMorePopUp.scss"
import Button from "../../global/Button";
import Modal from "../../global/Modal";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import PropTypes from 'prop-types';
const CreditScoreReadMorePopUp = ({ setModalState }) => {
    const [themeState] = useContext(ThemeContext);
    return (
        <>
            <Modal>
                <div
                    className="modalMainDiv"
                    style={{
                        background: `${themeState === THEME_DARK ? "#23242E" : "#fff"}`,
                        color: `${themeState === THEME_DARK ? "#fff" : "#000"}`,
                    }}
                >
                    <div className="cancelImgDiv">
                        <img src="assets/images/cancel.svg" className="cancelImg" onClick={() => { setModalState(false) }} />
                    </div>
                    <div className="faq-accodion-scroll">
                        <p className="modalHeadText">
                            CONSENT IN RELATION TO ACCESS TO CREDIT INFORMATION THROUGH
                            EXPERIAN
                        </p>
                        <hr />
                        <p className="modalText">
                            This End User Agreement is made between you and Tata Capital
                            Financial Services Limited, a private limited company having its
                            registered office at 11th Floor, Tower A, Peninsula Business
                            Park, Ganpatrao Kadam Marg, Lower Parel, Mumbai MH 400013 IN.
                            (“CLIENT”, “Us” or“We”, which term shall include its successors
                            and permitted assigns). The User and CLIENT shall be
                            collectively referred to as the “Parties” and individually as a
                            “Party”. You hereby consent to CLIENT being appointed as your
                            authorised representative to receive your Credit Information on
                            an ongoing basis until we derive personailzed pre-approved offer
                            for you or expiry of 6 months from date the consent is
                            collected; whichever is earlier.
                        </p>
                        <p className="modalText">
                            BY EXECUTING THIS AGREEMENT / CONSENT FORM, YOU ARE EXPRESSLY
                            AGREEING TO ACCESS THE EXPERIAN CREDIT INFORMATION REPORT AND
                            CREDIT SCORE, AGGREGATE SCORES, INFERENCES, REFERENCES AND
                            DETAILS (AS DEFINED BELOW)(TOGETHER REFERRED AS “CREDIT
                            INFORMATION”). YOU HEREBY ALSO IRREVOCABLY AND UNCONDITIONALLY
                            CONSENT TO SUCH CREDIT INFORMATION BEING PROVIDED BY EXPERIAN TO
                            YOU AND CLIENT BY USING EXPERIAN TOOLS, ALGORITHMS AND DEVICES
                            AND YOU HEREBY AGREE, ACKNOWLEDGE AND ACCEPT THE TERMS AND
                            CONDITIONS SET FORTH HEREIN.
                        </p>
                        <p className="modalHeadText">
                            TERMS AND CONDITIONS
                        </p>
                        <p className="modal-subHead">
                            Information Collection, Use, Confidentiality, No-Disclosure and Data Purging
                        </p>
                        <p className="modalText">
                            CLIENT shall access your Credit Information as your authorized representative and CLIENT shall use the Credit Information for limited End Use Purpose consisting of and in relation to the services proposed to be availed by you from CLIENT. We shall not aggregate, retain, store, copy, reproduce, republish, upload, post, transmit, sell or rent the Credit Information to any other person and the same cannot be copied or reproduced other than as agreed herein and in furtherance to applicable law including the CICRA.
                        </p>
                        <p className="modalText">
                            The Parties agree to protect and keep confidential the Credit Information both online and offline.
                        </p>
                        <p className="modalText">
                            The Credit Information shared by you, or received on by us on your behalf shall be destroyed, purged, erased immediately upon the completion of the transaction/ End Use Purpose for which the Credit Information report was procured, this period not being longer than 6 months.
                        </p>
                        <p className="modal-subHead">
                            Governing Law and Jurisdiction
                        </p>
                        <p className="modalText">
                            The relationship between you and CLIENT shall be governed by laws of India and all claims or disputes arising there from shall be subject to the exclusive jurisdiction of the courts of Mumbai.
                        </p>
                        <p className="modal-subHead">
                            Definitions:
                        </p>
                        <p className="modalText">
                            Capitalised terms used herein but not defined above shall have the following meanings:
                        </p>
                        <p className="modalText">
                            <span>“Business Day”</span> means a day (other than a public holiday) on which banks are open for general business in Mumbai.
                        </p>
                        <p className="modalText">
                            <span> “Credit Information Report”</span> means the credit information / scores/ aggregates / variables / inferences or reports which shall be generated by Experian;
                        </p>
                        <p className="modalText">
                            <span> “Credit Score” </span>  means the score which shall be mentioned on the Credit Information Report which shall be computed by Experian.
                        </p>
                        <p className="modalText">
                            <span>“CICRA”</span> shall mean the Credit Information Companies (Regulation) Act, 2005 read with the Credit Information Companies Rules, 2006 and the Credit Information Companies Regulations, 2006, and shall include any other rules and regulations prescribed thereunder.
                        </p>
                        <p className="modalText">
                            PLEASE READ THE ABOVE MENTIONED TERMS AND CONDITIONS AND CLICK ON “ACCEPT” FOLLOWED BY THE LINK BELOW TO COMPLETE THE AUTHORISATION PROCESS/ FOR SHARING OF YOUR CREDIT INFORMATION BY EXPERIAN WITH CLIENT IN ITS CAPACITY AS YOUR AUTHORISED REPRESENTATIVE.
                        </p>
                        <p className="modalText">
                            BY CLICKING “ACCEPT” YOU AGREE AND ACCEPT THE DISCLAIMERS AND TERMS AND CONDITIONS SET OUT HEREIN.
                        </p>

                    </div>
                    <div className="iAgreeBtnDiv">
                        <Button className="iAgreeBtn" onClick={() => {
                            setModalState(false)
                        }}>
                            <p className="btnText">I Agree</p>
                        </Button>
                    </div>
                    <div className="modalText-btn">
                        ** This document is an electronic record in terms of the Information Technology Act, 2000 and Rules made there under, and the amended provisions pertaining to electronic records.
                    </div>
                </div>
            </Modal>
        </>
    )
}

CreditScoreReadMorePopUp.propTypes = {
    setModalState : PropTypes.func
}

export default CreditScoreReadMorePopUp