import { useReducer } from "react";
import { THEME_LIGHT, THEME_DARK } from "../actions";
import { themeReducer } from "../reducer";
import { otpReducer } from "../reducer";
import ThemeContext from "./ThemeContext";

const ThemeProvider = ({ children }) => {
  const myContextObj = sessionStorage?.getItem("currentTheme");
  //   const myThemeState = JSON.parse(myContextObj)?.themeState;
  const [themeState, dispatch] = useReducer(themeReducer, myContextObj);
  const [otpState, otpDispatch] = useReducer(otpReducer, {
    email: null,
    mobileNo: null,
    card: null,
  });

  return (
    <ThemeContext.Provider
      value={[themeState, dispatch, otpState, otpDispatch]}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
