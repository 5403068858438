import React, { useContext, useEffect, useState } from "react";
import './DashboardInfo.scss';
import LoanDetailsItem from '../LoanDetailsItem/LoanDetailsItem';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import DataMapping from "../../../../Shared/context/data-mapping";

const DashboardInfo = ({
    data,
    textMoreDetails = "",
    src = "",
    alt = "",
    currentAccountNumber = '',
    preapprovedOfferFlag,
}) => {
    // console.log(data,"dataaaaaaaaazzzz");
    // console.log(currentAccountNumber,"currentAccountNumber");
    const [loanInfo, setLoanInfo] = useState([]);
    const navigate = useNavigate()
    const [contextdata, dispatcher] = useContext(DataMapping)
    const [foreClourseData, setForeClourseData] = useState([]);
    let currentActiveData = [];

    useEffect(() => {
        let index = data.findIndex(ele => ele.contractNumber === currentAccountNumber)
        currentActiveData = data[index]
        setLoanInfo(currentActiveData?.loanDetails)

    }, [data, currentAccountNumber])

    useEffect(() => {

        const { offers } = contextdata;
        const offerObj = offers?.body?.offerDetails.find(ele => ele['Loan Account No'] === currentAccountNumber)
        if (offerObj) {
            setForeClourseData([
                { name: "Foreclosure Amount", value: offerObj['FC'] },
                { name: "Foreclosure As on Date", value: "11/12/2023",type: "date" },
            ])
        }
    }, [loanInfo])

    

    return (
        <div className="c_dashboard_info">
            <div className="c_dashboard_info_second_div">
                {loanInfo?.map((loanDetails, index) => {
                    const loanAmount = typeof loanDetails.value === 'string' ? parseFloat(loanDetails.value) : loanDetails.value;
                    return <LoanDetailsItem
                        color_red_class={loanDetails.color_red_class ? loanDetails.color_red_class : null}
                        key={index}
                        loanNameText={loanDetails.name}
                        LoanAmount={loanAmount}
                        emiDueText={loanDetails?.text}
                        type={loanDetails.type}
                    />
                })}
                {
                    preapprovedOfferFlag && (foreClourseData && foreClourseData.map((ele,index)=>{
                        return <LoanDetailsItem
                        key={index}
                        loanNameText={ele.name}
                        LoanAmount={ele.value}
                        type={ele.type}
                    />
                    }))
                }


            </div>
            <div className="c_more_details_chevron_right">
                <a className="c_more_details" onClick={() => { navigate("/account-details") }}>{textMoreDetails}</a>
                <div className="c_chevron_right-div">
                    <img className="c_chevron_right" src={src} alt={alt} />
                    <img className="c_chevron_right" src={src} alt={alt} />
                    <img className="c_chevron_right" src={src} alt={alt} />
                </div>
            </div>
        </div>
    )
}

DashboardInfo.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    textMoreDetails: PropTypes.string,
    data: PropTypes.array,
    currentAccountNumber: PropTypes.string
}

export default DashboardInfo;