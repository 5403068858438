import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import Modal from "../../global/Modal";
import TipsComponent from "../../global/TipsComponent/TipsComponent";
import Label from "../Forms/Label";
import "./PaymentHistoryPopup.scss"
import PropTypes from 'prop-types';
const PaymentHistoryPopup = ({ setpaymetHistoryPopup, singleCard }) => {
    const [themeState] = useContext(ThemeContext);
    console.log(singleCard);
    const [finalPaymentObject, setFinalPaymentObject] = useState({})

    const [paymentDetails, setPaymentDetails] = useState([{
        label: "Payment Percent",
        value: singleCard.ele.paymentPercent
    },
    {
        label: "Card Status",
        value: singleCard.ele.ACCOUNT_STATUS_VALUE
    },
    {
        label: "Current Balance",
        value: singleCard.ele.Current_Balance
    },
    {
        label: "Lender",
        value: singleCard.ele.Subscriber_Name
    },
    {
        label: "Card Number",
        value: singleCard.ele.Account_Number
    },
    {
        label: "Highest Credit",
        value: singleCard.ele.Highest_Credit_or_Original_Loan_Amount
    },
    {
        label: "Amount Overdue",
        value: singleCard.ele.Amount_Past_Due
    }])

    const [paymentPopupObjArr, setpaymentPopupObjArr] = useState();
    var month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];


    useEffect(() => {
        populatepaymentDetails();
    },[]);

    const paymentHistoryContent = [
        {
            content: 'Time your payments to avoid hurting your Credit Score, try to get into the habit of paying in the mid cycle. – set up a reminder or set automatic bill payments.'
        }
    ]

    const populatepaymentDetails = () => {
        paymentPopupTemplate(singleCard.ele.CAIS_Account_History);
    }

    const paymentPopupTemplate = (value) => {
        console.log(value)
        var paymentPopupObj = {};

        
        value.forEach(function (elem) {
            if (paymentPopupObj[elem.Year]) {
                paymentPopupObj[elem.Year].push({

                    "month": Number(elem.Month),
                    "daysDue": elem.Days_Past_Due

                });
            } else {
                paymentPopupObj[elem.Year] = [
                    {
                        "month": Number(elem.Month),
                        "daysDue": elem.Days_Past_Due
                    }
                ];
            }
        });
        paymentPopupObj = sortArrayOfMonths(paymentPopupObj);

        setFinalPaymentObject(paymentPopupObj);
    }

    function sortArrayOfMonths(paymentPopupObj) {
        
        setpaymentPopupObjArr(paymentPopupObj)
        
        Object.keys(paymentPopupObj).forEach(function (yearArray) {
            paymentPopupObj[yearArray].sort(function compare(a, b) {
                if (a.month < b.month) {
                    return -1;
                }
                if (a.month > b.month) {
                    return 1;
                }
                return 0;
            });
        });
        return paymentPopupObj;
    }

   return (
        <div>
            <Modal>
                <div className="payment-history-pop-up" style={{ backgroundColor: `${themeState === "THEME_DARK" ? "#23242E" : "#F4F7F8"}` }}>
                    <div className="close-btn">
                        <img className="payment-history-close" src="assets/images/crossImg.svg" alt="cross image" onClick={() => { setpaymetHistoryPopup(false) }} />
                    </div>
                    <div className="payment-history-info">
                    <div>
                        <div className='payment-history-head'>
                            <div className='payment-history-title' style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }}>Payment Details</div>
                        </div>

                        <div className='payment-history-content'>
                            {
                                paymentDetails && paymentDetails.map((value, index) => (
                                    <div key={index} className="payment-history-label-container">
                                        <div className="payment-history-label-head">
                                            <p style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }}>{value.label}</p>
                                        </div>
                                        <div className='payment-history-value'>
                                            <p>{value.value}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div>
                        <div className='payment-history-head'>
                            <div className='payment-history-title' style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Payment History</div>
                        </div>
                        <div className="payment-history-table-container">
                            <table>
                                <thead style={{ background: `${themeState === "THEME_DARK" ? "#42444D" : "#E0EFFF"}` }}>
                                    <tr>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>DPD</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Jan</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Feb</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Mar</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Apr</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>May</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Jun</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Jul</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Aug</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Sep</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Oct</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Nov</th>
                                        <th style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "#000000"}` }}>Dec</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(finalPaymentObject).map((year,index) => {
                                            return (<tr key={index}>
                                                <td style={{ borderColor: `${themeState === "THEME_DARK" ? " #42444D" : "#E0EFFF;"}`, backgroundColor: `${themeState === "THEME_DARK" ? " #2E2F36" : "#FFFFFF"}`, color: `${themeState === "THEME_DARK" ? " #ffffff" : "#000000"}` }}>{year}</td>
                                                {
                                                    month.map((monthNumber,index) => {
                                                        var isMonthPresent = finalPaymentObject[year].filter(function (objMonth) {
                                                            return objMonth.month == monthNumber;
                                                        });
                                                        if (isMonthPresent.length == 0) {
                                                            return (<td key={index} style={{ borderColor: `${themeState === "THEME_DARK" ? " #42444D" : "#E0EFFF;"}`, backgroundColor: `${themeState === "THEME_DARK" ? " #2E2F36" : "#FFFFFF"}`, color: `${themeState === "THEME_DARK" ? " #ffffff" : "#000000"}` }}></td>)
                                                        } else {
                                                            if (isMonthPresent[0].daysDue >= 1 && isMonthPresent[0].daysDue <= 90) {
                                                                return (<td key={index} className="orange-indicator">{isMonthPresent[0].daysDue}</td>)
                                                            } else if (isMonthPresent[0].daysDue == 's' || isMonthPresent[0].daysDue == '0') {
                                                                return (<td key={index} className="green-indicator">{isMonthPresent[0].daysDue}</td>)
                                                            } else {
                                                                return (<td key={index} className="red-indicator">{isMonthPresent[0].daysDue}</td>)
                                                            }
                                                        }
                                                    })
                                                }
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='payment-history-popup-tips' style={{ color: `${themeState === "THEME_DARK" ? "#000000" : "000000"}` }}>
                        <p style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}`}}>Tips:</p>
                        <TipsComponent tipContent={paymentHistoryContent} />
                    </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

PaymentHistoryPopup.propTypes = {
    setpaymetHistoryPopup : PropTypes.func,
    singleCard : PropTypes.object
}

export default PaymentHistoryPopup;