import React from "react";
import "./ReUsablesCta.scss";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import { useContext } from "react";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import PropTypes from 'prop-types';
const ReUsablesCta = ({
  label,
  image,
  className,
  lastChild,
  borderRadius,
  changeColor,
  bankClickFlag,
  name
}) => {
  const [themeState] = useContext(ThemeContext);
  return (
    <div
      style={{
        border: lastChild ? "none" : "",
      }}
      className={`reUsable_cta_container ${className ? className : ""}`}
    >
      <label
        className="reUsable_cta_container_label"
        style={{
          color: changeColor ? "#1961AC" : !bankClickFlag && name === "bank" ? "#9a9b9c" : "",
          fontWeight: changeColor ? "700" : "",
          cursor: !bankClickFlag && name === "bank" ? "no-drop" : "pointer"
        }}
      >
        {label}
      </label>
      {image ?
        <img
          style={{
            filter: changeColor
              ? "invert(29%) sepia(32%) saturate(3451%) hue-rotate(193deg) brightness(90%) contrast(90%)"
              : !bankClickFlag && name === "bank" ? "invert(.25)" : themeState === THEME_DARK
                ? "invert(0)"
                : "",
          }}
          src={`assets/images/${image}`}
          className="reUsable_cta_right_arrow"
        /> : null}
    </div>
  );
};

ReUsablesCta.prototypes = {
  label: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  lastChild: PropTypes.bool,
  changeColor: PropTypes.bool,
  bankClickFlag: PropTypes.bool,
  name: PropTypes.string
}

export default ReUsablesCta;
