import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const logoutUser = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {}
    }
    return new Promise((resolve, reject) => {
        
        // axios.post('/shaft/api/logout/logout-user/v1', data).then(response => {
        stalwartApiCall('POST',data,'/shaft/api/logout/logout-user/v1').then(response => {
            
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default logoutUser;