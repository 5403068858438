import getQualitricsUrl from "../../apis/get-qualitrics-url/get-qualitrics-url";
import logoutUser from "../../apis/logout-user/logout-user";

const LogoutServiceObject = {}

const qualitricsService = (reqObj) => {
    return new Promise((resolve, reject) => {
        getQualitricsUrl(reqObj).then((qualitricsResponse) => {
            if (qualitricsResponse.header.status.toUpperCase() === 'SUCCESS') {
                console.log(qualitricsResponse);
                if (qualitricsResponse.body.finalUrl) {
                    resolve(qualitricsResponse.body.finalUrl);
                }
            } else {
                reject("L-54")
            }
        }).catch((error) => {
            reject("L-52")
        })
    })
}
LogoutServiceObject.qualitricsService = qualitricsService;

const logOutUserService = (reqObj) => {
    return new Promise((resolve, reject) => {
        logoutUser(reqObj).then((logoutUserResponse) => {
            console.log(logoutUserResponse);
            if (logoutUserResponse) {
                resolve();
            }
            else {
                reject("61");
            }
        }).catch((error) => {
            reject("61");
        })
    })
}
LogoutServiceObject.logOutUserService = logOutUserService;

export default LogoutServiceObject