import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const changePassword = (data) => {

    let requestJson = {
        "header": {
            "authToken": "",
            "firstLogin": false
        },
        "body": {
            "userId": "242475666",
            "password": "MTI4OjoxMDAwMDo6OTRkMDQ4OGUzMDAzYzNjNzM5NDE2YTAyYWVlMjk2ZmU6OmEzYWQ1NTVmNWMzZWE1MmI2ZTY1MzIxYmE0NjYzZjUzOjppZFVpYy95aVpnZ3J4bVR4akNRZnZ3PT0",
            "newPassword": "MTI4OjoxMDAwMDo6OTRkMDQ4OGUzMDAzYzNjNzM5NDE2YTAyYWVlMjk2ZmU6OmEzYWQ1NTVmNWMzZWE1MmI2ZTY1MzIxYmE0NjYzZjUzOjppZFVpYy95aVpnZ3J4bVR4akNRZnZ3PT0=",
            "confirmPassword": "MTI4OjoxMDAwMDo6Yjg1ZTUxY2NjMzAwMzQ4ZDM5YjQ2NThhOTIyZTk1NTE6OjYzYzFlY2UwNDMxOTI3OWNiNmVjMDU0Nzk0YmRiYmIzOjpsR2E4aExpeVIxcE05aC8rSVRDUDNBPT0="
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/db/change-password/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/db/change-password/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default changePassword;