import React, { useEffect, useState } from "react";
import Button from "../../global/Button";
import "./DrawDownRequestPopup.scss";
import Modal from "../../global/Modal";

import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import DataMapping from "../../../../Shared/context/data-mapping";
import commonFunctionObject from "../../common-function";
import LasAccountDetailsServiceObject from "../../services/LasAccountDetailsService";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
import { useNavigate } from "react-router-dom";
import RadiantBorder from "../../global/RadiantBorder";
import PostOtp from "../../global/PostOtp";
import AlertPopUp from "../../global/AlertPopUp";
import PropTypes from 'prop-types';

const DrawDownRequestPopup = ({ activeContract, setModalState, postOtpFlag, setPostOtpFlag, setSuccessPopUpModalLasFlag, setSuccessPopUpContentLas, setFailurePopUpModalLasFlag, setFailurePopUpContentLas, setFreezeTime, setFreezeFlag, setFunctionalityFreezeFlag, freezeFlag, freezeTimer, lasData, setLasData, refNo, setRefNo }) => {
    const navigate = useNavigate()
    const [themeState, dispatch, otpState, otpDispatch] = useContext(ThemeContext);
    const [data, dispatchData] = useContext(DataMapping);
    const [drawDownTabContent, setDrawDownTabContent] = useState([
        {
            label: "Loan Account Number",
            value: "",
        },
        {
            label: "Customer Name",
            value: "",
        },
        {
            label: "Product",
            value: "",
        },
        {
            label: "Requested Date",
            value: "",
        },
        {
            label: "Customer Bank Account",
            value: "",
        },
        {
            label: "Available Limit",
            value: "",
        },
    ])
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    })
    const [successPopUpModal, setSuccessPopUpModal] = useState(false);
    const [successPopUpContent, setSuccessPopUpContent] = useState("");
    const [alertPopUpModal, setAlertPopUpModal] = useState(false);
    const [alertPopUpContent, setAlertPopUpContent] = useState({
        "title": "",
        "message": "",
    })
    const [disbursalRequestAmount, setDisbursalRequestAmount] = useState("");
    const [disbursalRequestAmountError, setDisbursalRequestAmountError] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")

    useEffect(() => {
        setDrawDownTabContent(populateDrawDownTab())
    }, [])

    const populateDrawDownTab = () => {
        if (data?.["summary"]) {
            var availableAmount = ""
            if (data?.["checkEquityFlag"]?.[activeContract] === true) {
                var breData = data?.["pledgingBreResult"]?.[activeContract];
                availableAmount = "₹" + parseFloat(Number(breData["eligbileDrawingPower"])).toLocaleString('en-IN');
            } else {
                var sanctionedAmt = Number(data?.summary?.[activeContract][0].LoanAccount[0].sanctionedamount)
                var totalDP = Number(data?.summary?.[activeContract][0].LoanAccount[0].drawingPower)
                var totalOTS = Number(data?.summary?.[activeContract][0].LoanAccount[0].TotalOutstanding)
                var eligibleDPMF = ""
                if (sanctionedAmt < totalDP) {
                    eligibleDPMF = sanctionedAmt - totalOTS;
                } else {
                    eligibleDPMF = totalDP - totalOTS;
                }
                availableAmount = "₹" + parseFloat(Number(eligibleDPMF)).toLocaleString('en-IN');
            }
            var drawDownContentArray = [
                {
                    label: "Loan Account Number",
                    value: data?.summary?.[activeContract][0].LoanAccount[0].Account_Number,
                },
                {
                    label: "Customer Name",
                    value: data?.summary?.[activeContract][0].LoanAccount[0].LoanAccountName
                },
                {
                    label: "Product",
                    value: data?.summary?.[activeContract][0].LoanAccount[0].product,
                },
                {
                    label: "Requested Date",
                    value: commonFunctionObject.convertDDMMYYYY(new Date()),
                },
                {
                    label: "Customer Bank Account",
                    value: data?.summary?.[activeContract][0].bankAccountNumber,
                },
                {
                    label: "Available Limit",
                    value: availableAmount
                },
            ]
        }


        return drawDownContentArray
    }

    const onDisbursementClick = () => {
        if (validation()) {
            if (!isDebarred()) {
                var getHolidayDetailsRequest = {
                    "header": {
                        "authToken": data.authToken
                    },
                    "body": {
                        "journeyType": "disbursement"
                    }
                }
                dispatchData({
                    name: "loaderFlag",
                    type: STOREDATA,
                    payload: true
                })
                LasAccountDetailsServiceObject.getHolidayDetailsService(getHolidayDetailsRequest).then((getHolidayDetailsResponse) => {
                    if (Object.keys(getHolidayDetailsResponse.body).length === 0) {
                        dispatchData({
                            name: "loaderFlag",
                            type: STOREDATA,
                            payload: true
                        })
                        let getServerTimeRequest = {
                            "header": {
                                "authToken": data.authToken
                            },
                            "body": {
                            }
                        }
                        commonFunctionObject.getServerTimeService(getServerTimeRequest).then((getServerDateTime) => {

                            var serverTime = new Date(commonFunctionObject.replaceHyphenDate(getServerDateTime));
                            var getHours = serverTime.getHours();
                            if (getHours >= 9 && getHours <= 17) {
                                var quantityObj = {};
                                var holdingType = data?.checkEquityFlag[activeContract] === true ? "Equity" : "Mutual-Funds";
                                generateOtpValidation(quantityObj, holdingType);

                            } else {
                                setAlertPopUpContent({
                                    title: "Action Not Allowed",
                                    message: "Please initiate the drawdown request between 9:00 am to 6:00 pm",
                                });
                                setAlertPopUpModal(true)
                                dispatchData({
                                    name: "loaderFlag",
                                    payload: false,
                                    type: STOREDATA
                                })
                            }
                        }).catch((error) => {

                        })
                    } else {
                        setFailurePopUpContent({
                            apiName: "",
                            message: "Disbursement is not available today due to" + getHolidayDetailsResponse.body.holidayDetails.holidayName + ". Please try on " + getHolidayDetailsResponse.body.holidayDetails.nextDepledgingWorkingDate + ". Thank you.",
                            errorCode: ""
                        });
                        setFailurePopUpModal(true)
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    }
                }).catch(() => {
                    setFailurePopUpContent({
                        "apiName": "",
                        "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        "errorCode": ""
                    })
                    setFailurePopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        type: STOREDATA,
                        payload: false
                    })
                })
            } else {
                setAlertPopUpContent({
                    title: "Action Not Allowed",
                    message: "Due to regulatory restrictions, this service is not available to you. Kindly contact your RM for any clarification",
                });
                setAlertPopUpModal(true)
            }
        }

    }
    const generateOtpValidation = (quantityObj, holdingType) => {

        let finalDisbursalRequestAmount = parseFloat(disbursalRequestAmount.replace(/,/g, '').replace('₹', ''))
        var netAmount = "";
        if (data?.summary?.[activeContract][0].LoanAccount[0].ScheduleDetails) {
            var lasopenContracts = data?.summary?.[activeContract]
            var outstandingAmount = Number(lasopenContracts[0].LoanAccount[0].OutstandingCharges);
            if ((outstandingAmount, true) && !isNaN(outstandingAmount)) {
                netAmount = finalDisbursalRequestAmount - outstandingAmount;
            }
            if (holdingType === "Equity") {
                if (netAmount > 0) {

                    var breRequestArray = LasAccountDetailsServiceObject.createBreRequestListArray(quantityObj, data?.["pledgeDetailsFoliowise"]?.[activeContract], data?.["summary"]?.[activeContract]);
                    var filterBreRequestArr = LasAccountDetailsServiceObject.filterBreRequestArrFn(breRequestArray);
                    let pledgingBreResultRequest = {
                        header: {
                            authToken: data.authToken,
                        },
                        body: {
                            breRequestList: filterBreRequestArr,
                            clientId: lasopenContracts[0].CustomerID,
                            gcid: lasopenContracts[0].gcid,
                            sanctionLimit: lasopenContracts[0].LoanAccount[0].sanctionedamount,
                            totalOutstanding: lasopenContracts[0].LoanAccount[0].TotalOutstanding
                        },
                    }
                    LasAccountDetailsServiceObject.pledgingBreResultService(pledgingBreResultRequest).then((breResponse) => {
                        if (breResponse["isDisbursementAllowed"] == 1) {
                            generateDisbursementOtp(lasopenContracts, netAmount, outstandingAmount, finalDisbursalRequestAmount)
                        } else {
                            setFailurePopUpContent({
                                apiName: "",
                                message: "Disbursement is not allowed.",
                                errorCode: ""
                            });
                            setFailurePopUpModal(true)
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA
                            })

                        }
                    }).catch((error) => {

                    })
                } else {
                    setFailurePopUpContent({
                        apiName: "",
                        message: "Disbursement amount is less than charges. Kindly initiate a disbursement with amount greater than charges.",
                        errorCode: ""
                    });
                    setFailurePopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })

                }
            } else {
                generateDisbursementOtp(lasopenContracts, netAmount, outstandingAmount, finalDisbursalRequestAmount)
            }
        } else {
            setFailurePopUpContent({
                apiName: "",
                message: "Sorry. You cannot proceed since we don't have your loan account number",
                errorCode: ""
            });
            setFailurePopUpModal(true)
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })

        }
    }

    const generateDisbursementOtp = (lasopenContracts, netAmount, outstandingAmount, disbursalRequestAmount) => {
        setMobileNumber(lasopenContracts[0].MobileNumber.trim())
        // var mobileNumber = lasopenContracts[0].MobileNumber.trim();
        let generateMobileOtpRequest = {
            "header": {
                "grantType": "otp",
                "serviceType": "mobile",
                "authToken": data.authToken,
                "journeyType": "postLogin"
            },
            "body": {
                "mobileNo": lasopenContracts[0].MobileNumber.trim()
            }
        }
        dispatchData({
            name: "loaderFlag",
            type: STOREDATA,
            payload: true
        })
        LasAccountDetailsServiceObject.generateMobileOtpService(generateMobileOtpRequest).then((generateMobileOtpRefID) => {
            setRefNo(generateMobileOtpRefID)
            let newLasDataObject = {
                lasopenContracts: lasopenContracts,
                netAmount: netAmount,
                outstandingAmount: outstandingAmount,
                disbursalRequestAmount: disbursalRequestAmount
            }
            setLasData({ ...lasData, ...newLasDataObject })
            setPostOtpFlag(true)
            dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: false
            })
            // navigate("/otpPage", { state: { postlogin: true, userInput: mobileNumber, page: "las-disbursement", refID: generateMobileOtpRefID, postOnCallFlag: true, lasData: { lasopenContracts: lasopenContracts, netAmount: netAmount, outstandingAmount: outstandingAmount, disbursalRequestAmount: disbursalRequestAmount } } });
        }).catch(error => {
            if (error.toLowerCase() === "number of attempts exceeded") {
                setFailurePopUpContent({
                    apiName: "",
                    message: "Please login with User ID and Password.",
                    errorCode: ""
                })
                setFailurePopUpModal(true)
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            } else {
                setFailurePopUpContent({
                    apiName: "",
                    message: "We are currently facing error in OTP services. Please try again later.",
                    errorCode: ""
                });
                setFailurePopUpModal(true)
                dispatchData({
                    name: "loaderFlag",
                    type: STOREDATA,
                    payload: false
                })
            }
        })

    }

    const changeDisbursalRequestAmount = (event) => {
        setDisbursalRequestAmount(event.target.value)
    }

    const isDebarred = () => {
        if (data?.["parsedUserData"]?.["lasAccounts"]) {
            if (data?.["parsedUserData"]?.["lasAccounts"].isDebarredCustomer.toUpperCase() === "Y") {
                return true
            } else {
                return false
            }
        }
    }

    const validation = () => {
        var validationCheck = true;
        var availableAmount = Number(drawDownTabContent[5].value.replace('₹', '').replace(/,/g, '')).toFixed('2')
        if (disbursalRequestAmount == "" || disbursalRequestAmount <= 0 || isNaN(disbursalRequestAmount)) {
            setDisbursalRequestAmountError("Please Enter a Valid Amount")
            validationCheck = false
        } else if (parseFloat(availableAmount) <= 0) {
            setDisbursalRequestAmountError("Your Drawdown is More Than Sanction Limit or Disbursement Breaching Loan Schedule Limit. Kindly Revise Drawdown Request.")
            validationCheck = false
        } else if (parseFloat(disbursalRequestAmount) > parseFloat(availableAmount)) {
            setDisbursalRequestAmountError("Please Enter a Value Lesser Than the Available Limit")
            validationCheck = false
        } else {
            setDisbursalRequestAmountError("")
            validationCheck = true
        }

        return validationCheck
    }

    //   const [modalState, setModalState] = useState(false);

    return (
        <div>
            {alertPopUpModal ? <AlertPopUp
                onClick={() => setAlertPopUpModal(false)}
                visible={alertPopUpModal}
                setAlertModalStat={setAlertPopUpModal}
                alertPopUpContent={alertPopUpContent}
            /> : null}
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {successPopUpModal ? <SuccessPopUp
                onClick={() => setSuccessPopUpModal(false)}
                visible={successPopUpModal}
                setSuccessModalStat={setSuccessPopUpModal}
                successPopUpContent={successPopUpContent}
            /> : null}
            <Modal>
                <div
                    className="draw_Down_Request_ModalMainDiv"
                    style={{
                        background: `${themeState === THEME_DARK ? "#23242E" : "#fff"}`,
                        color: `${themeState === THEME_DARK ? "#fff" : "#000"}`,
                    }}
                >
                    <div className="modal_Heading_Div">
                        <p className="draw_Down_Request_ModalHeadText">Draw Down Request</p>
                        <div className="draw_downRCancelImgDiv">
                            <img
                                src="assets/images/cancel.svg"
                                className="draw_downRCancelImg"
                                onClick={() => {
                                    setModalState(false);
                                }}
                            />
                        </div>
                    </div>

                    <div className="draw_Down_Request_Wrap_Div">
                        {drawDownTabContent && drawDownTabContent.map((obj, key) => {
                            return (
                                <div className="draw_Down_Request_Wrap_Value_Div" key={key}>
                                    <p className="draw_Down_Request_ModatText">{obj.label}</p>
                                    <p className="draw_Down_Request_NumberText">{obj.value}</p>
                                </div>
                            )

                        })}
                    </div>

                    <div className="draw_Down_Request_Amount_Div">
                        <div>
                            <p>Disbursal Request Amount</p>
                        </div>
                        <div
                            className="draw_Down_Enter_Amount_FlexDiv"
                            style={{
                                backgroundColor:
                                    themeState === THEME_DARK ? "#2e2f36" : "#ecf0f5",
                            }}
                        >
                            <div
                                className="draw_Down_Rupee_ImgDiv"
                                style={{
                                    backgroundColor:
                                        themeState === THEME_DARK ? "#2e2f36" : "#ecf0f5",
                                }}
                            >
                                <img
                                    src="assets/images/rupee-Img.svg"
                                    style={{
                                        filter: `${themeState === THEME_DARK ? "invert(1)" : ""}`,
                                        height: 15,
                                        width: 15,
                                    }}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="draw_Down_Enter_Amount_Input"
                                    placeholder="Enter Amount"
                                    value={disbursalRequestAmount}
                                    onChange={(event) => {
                                        changeDisbursalRequestAmount(event)
                                    }}
                                    style={{
                                        backgroundColor:
                                            themeState === THEME_DARK ? "#2e2f36" : "#ecf0f5",
                                    }}
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="disbursalRequestAmountError">
                        <p>{disbursalRequestAmountError}</p>
                    </div>

                    {freezeFlag === true ? <div className="freezeMessageDiv">
                        <p className="freezeMessage">These feature will be available after {freezeTimer} mins</p>
                        <p className="freezeMessage">Please do not refresh the window or close the browser or click log out button till completion of freeze time.</p>
                    </div> : null}

                    <div className="draw_Down_Submit_BtnDiv">
                        <Button className="draw_Down_Submit_Btn" onClick={() => { onDisbursementClick() }} disable={freezeFlag === true ? true : false} style={{ cursor: `${freezeFlag === true ? "no-drop" : "pointer"}` }}>
                            <p className="draw_Down_Submit_Btn_Text ">Submit</p>
                            <img
                                src="assets/images/arrow-right.svg"
                                className="draw_Down_Submit_Arrow_Right"
                            />
                        </Button>
                    </div>
                </div>
            </Modal>
            {/* } */}
        </div>
    );
};

DrawDownRequestPopup.propTypes = {
    activeContract: PropTypes.string,
    setModalState: PropTypes.func,
    postOtpFlag: PropTypes.bool,
    setPostOtpFlag: PropTypes.func,
    setSuccessPopUpModalLasFlag: PropTypes.func,
    setSuccessPopUpContentLas: PropTypes.func,
    setFailurePopUpModalLasFlag: PropTypes.func,
    setFailurePopUpContentLas: PropTypes.func,
    setFreezeFlag: PropTypes.func,
    setFreezeTime: PropTypes.func,
    setFunctionalityFreezeFlag: PropTypes.func,
    freezeFlag: PropTypes.bool,
    freezeTimer: PropTypes.string,
    lasData: PropTypes.object,
    setLasData: PropTypes.func,
    refNo: PropTypes.string,
    setRefNo: PropTypes.func,
}

export default DrawDownRequestPopup;
