import React, { useState } from "react";
import "./LoanHoldingNavComponent.scss";
import LASLoanDetails from "../LASLoanDetails/LASLoanDetails";
import { TabNavItem } from "../TabNavItem/TabNavItem";
import LASHoldingDetails from "../LASHoldingDetails/LASHoldingDetails";
import Button from "../../global/Button";
import CustomDropDown from "../../global/CustomDropDown";
import { useEffect } from "react";
import { useContext } from "react";
import DataMapping from "../../../../Shared/context/data-mapping";
import LasAccountDetailsServiceObject from "../../services/LasAccountDetailsService";
import {
  OFFER_DETAIS,
  STOREACCOUNTDATA,
  STOREDATA,
} from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
import OverviewServiceObject from "../../services/Overview";
import commonFunctionObject from "../../common-function";
import { useLocation } from "react-router-dom";
import PostOtp from "../../global/PostOtp";
import RadiantBorder from "../../global/RadiantBorder";
import PropTypes from 'prop-types';

const LoanHoldingNavComponent = ({ activeContract, navigationObject, postOtpFlag, setPostOtpFlag }) => {
  const initialTabName = navigationObject
    ? navigationObject.tabName
    : "Loan Details";
  const [activeTab, setActiveTab] = useState(initialTabName);
  const [optionState, setOptionState] = useState(false);
  const [data, dispatchData] = useContext(DataMapping);
  const [drawModalState, setDrawModalState] = useState(false);
  const [payModalState, setPayModalState] = useState(false);
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [currentHoldingTableArray, setCurrentHoldingTableArray] = useState([]);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });
  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");
  const optionArray = [
    {
      value: "Loan Details",
    },
    {
      value: "Transact",
    },
  ];
  const [lasAmountData, setLasAmountData] = useState([
    {
      label: "Sanction Limit",
      value: "₹ 0.00",
    },
    {
      label: "Total Drawing Power",
      value: "₹ 0.00",
    },
    {
      label: "Available Drawing Power",
      value: "₹ 0.00",
    },
    {
      label: "Eligible Drawing Power",
      value: "₹ 0.00",
    },
    {
      label: "Margin Shortfall Amount",
      value: "₹ 0.00",
    },
    {
      label: "Current Pending Dues",
      value: "₹ 0.00",
    },
    {
      label: "Loan Expiry Date",
      value: "00-00-0000",
    },
  ]);
  const [outstandingData, setOutStandingData] = useState([
    {
      label: "Principal",
      value: "₹ 0.00",
    },
    {
      label: "Interest",
      value: "₹ 0.00",
    },
    {
      label: "Charges",
      value: "₹ 0.00",
    },
    {
      label: "Unbilled Interest",
      value: "₹ 0.00",
    },
    {
      label: "Unbilled Penal Interest",
      value: "₹ 0.00",
    },
    {
      label: "Total Outstanding",
      value: "₹ 0.00",
    },
  ]);

  const [freezeFlag, setFreezeFlag] = useState(false)
  const [freezeTimer, setFreezeTime] = useState("0")
  const [noDataFlag, setNoDataFlag] = useState(false)
  const [functionalityFreezeFlag, setFunctionalityFreezeFlag] = useState(false)
  const location = useLocation()
  const [lasData, setLasData] = useState({
    lasopenContracts: "",
    netAmount: "",
    outstandingAmount: "",
    disbursalRequestAmount: ""
  })
  const [refNo, setRefNo] = useState()

  useEffect(() => {
    callLASAccountDetailsOnLoadApi();
  }, []);

  useEffect(() => {
    populateCurrentHoldingTable();
  }, [payModalState, drawModalState]);

  const callLASAccountDetailsOnLoadApi = () => {
    callPledgeDetailsFoliowiseApi();
  };

  const callPledgeDetailsFoliowiseApi = () => {
    var lasopenContracts = data?.["summary"]?.[activeContract];
    var loanAccountDetails = lasopenContracts[0];
    loanAccountDetails.LoanAccount.forEach((element) => {
      // if (!data?.pledgeDetailsFoliowise?.[element.Account_Number]) {
      var pledgeDetailsFolioWiseRequest = {
        header: {
          authToken: data.authToken,
        },
        body: {
          inputFieldType: "LoanAccountID",
          inputFieldValue: element.Account_Number,
        },
      };
      dispatchData({
        name: "loaderFlag",
        payload: true,
        type: STOREDATA,
      });

      LasAccountDetailsServiceObject.pledgeDetailsFoliowiseService(
        pledgeDetailsFolioWiseRequest,
        element.Account_Number
      )
        .then((pledgeDetailsFolioWiseResponse) => {
          if (pledgeDetailsFolioWiseResponse === "noData") {
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA,
            });
            dispatchData({
              name: "pledgeDetailsFoliowise",
              type: STOREACCOUNTDATA,
              payload: {
                response: [],
                contractNumber: element.Account_Number,
              },
            });
            setNoDataFlag(true)
            callFetchLasTransactionApi(element.Account_Number)

          } else {
            dispatchData({
              name: "pledgeDetailsFoliowise",
              type: STOREACCOUNTDATA,
              payload: {
                response: pledgeDetailsFolioWiseResponse,
                contractNumber: element.Account_Number,
              },
            });

            var breRequestArray =
              LasAccountDetailsServiceObject.createBreRequestListArray(
                {},
                pledgeDetailsFolioWiseResponse,
                data?.["summary"]?.[activeContract]
              );
            if (breRequestArray.length > 0) {
              var pledginigBreResultRequest = {
                header: {
                  authToken: data.authToken,
                },
                body: {
                  breRequestList: breRequestArray,
                  clientId: data?.["summary"]?.[activeContract][0].CustomerID,
                  gcid: data?.["summary"]?.[activeContract][0].gcid,
                  sanctionLimit: data?.["summary"]?.[
                    activeContract
                  ][0].LoanAccount[0].sanctionedamount,
                  totalOutstanding: data?.["summary"]?.[
                    activeContract
                  ][0].LoanAccount[0].TotalOutstanding
                },
              };

              LasAccountDetailsServiceObject.pledgingBreResultService(
                pledginigBreResultRequest
              )
                .then((pledgingBreResultResponse) => {
                  console.log("pledging Bre called");
                  dispatchData({
                    name: "pledgingBreResult",
                    type: STOREACCOUNTDATA,
                    payload: {
                      response: pledgingBreResultResponse,
                      contractNumber: element.Account_Number,
                    },
                  });

                  dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA,
                  });
                  callFetchLasTransactionApi(
                    element.Account_Number,
                    pledgingBreResultResponse
                  );
                })
                .catch((error) => {
                  if (typeof (error) === "object") {
                    setFailurePopUpContent({
                      apiName: "",
                      message: error.message,
                      errorCode: "",
                    });
                    setFailurePopUpModal(true);
                  } else {
                    setFailurePopUpContent({
                      apiName: "",
                      message:
                        "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
                      errorCode: error,
                    });
                    setFailurePopUpModal(true);
                  }
                  dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA,
                  });
                });
            } else {
              callFetchLasTransactionApi(element.Account_Number);
            }
          }
        })
        .catch((error) => {
          setFailurePopUpContent({
            apiName: "",
            message:
              "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
            errorCode: error,
          });
          setFailurePopUpModal(true);
          dispatchData({
            name: "pledgeDetailsFoliowise",
            type: STOREACCOUNTDATA,
            payload: {
              response: [],
              contractNumber: element.Account_Number,
            },
          });
          setNoDataFlag(true)
          if (data?.checkEquityFlag[element.Account_Number]) {
            dispatchData({
              name: "pledgingBreResult",
              type: STOREACCOUNTDATA,
              payload: {
                response: [],
                contractNumber: element.Account_Number,
              },
            });
          }
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        });
    });
  };

  const callFetchLasTransactionApi = (
    accountNumber,
    pledgingBreResultResponse
  ) => {
    dispatchData({
      name: "loaderFlag",
      payload: true,
      type: STOREDATA,
    });
    let fetchLasTransactionRequest = {
      header: {
        authToken: data.authToken,
      },
      body: {
        contractNo: accountNumber,
      },
    };
    LasAccountDetailsServiceObject.fetchLasTransactionApiService(
      fetchLasTransactionRequest
    )
      .then((fetchLasTransactionApiResponse) => {
        var timer;
        var remainingTime;
        if (fetchLasTransactionApiResponse?.body?.isDepledging.toUpperCase() === "Y" || fetchLasTransactionApiResponse.body.isDisbursement.toUpperCase() === "Y") {
          setFunctionalityFreezeFlag(false)
          if (fetchLasTransactionApiResponse.body.transactionType.toUpperCase() === "DEPLEDGING") {
            remainingTime = fetchLasTransactionApiResponse.body.remainingTime;
            timer = parseInt(remainingTime / 1000);
            setFreezeFlag(true)
            transactionTimerLoad(timer)
          } else if (fetchLasTransactionApiResponse.body.transactionType.toUpperCase() === "DISBURSEMENT") {
            remainingTime = fetchLasTransactionApiResponse.body.remainingTime;
            timer = parseInt(remainingTime / 1000);
            setFreezeFlag(true)
            transactionTimerLoad(timer)
          }
        } else {
          setFreezeFlag(false)
          setFreezeTime((0).toString())
        }

        setLasAmountData(setAmountDetails(pledgingBreResultResponse));
        setOutStandingData(setOutStandingDetails());
        if (navigationObject) {
          if (navigationObject.clickName === "draw down request") {
            setDrawModalState(true);
          } else if (navigationObject.clickName === "pay now") {
            setPayModalState(true);
          } else {
            setDrawModalState(false);
            setPayModalState(false);
          }
        }
        checkRedirectionPortal()
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
      })
      .catch((error) => {
        setFailurePopUpContent({
          apiName: "",
          message:
            "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
          errorCode: "",
        });
        setFailurePopUpModal(true);
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA,
        });
      });
  };

  const setAmountDetails = (pledgingBreResultResponse) => {
    var localStringObj = {
      style: "currency",
      currency: "INR",
      decimal: "2",
    };
    var eligibleDrawingPower = "";
    if (data?.["checkEquityFlag"]) {
      if (data?.["checkEquityFlag"][activeContract] === true) {
        eligibleDrawingPower = pledgingBreResultResponse && pledgingBreResultResponse?.eligibleDrawingPower ? "₹" + parseFloat(Number(pledgingBreResultResponse?.eligibleDrawingPower)).toLocaleString("en-IN") : "₹0";
      } else {
        var sanctionedAmt = Number(
          data?.summary[
            activeContract
          ][0].LoanAccount[0].sanctionedamount
        );
        var totalDP = Number(
          data?.summary[activeContract][0].LoanAccount[0].drawingPower
        );
        var totalOTS = Number(
          data?.summary[
            activeContract
          ][0].LoanAccount[0].TotalOutstanding
        );
        if (sanctionedAmt < totalDP) {
          eligibleDrawingPower = "₹" + parseFloat(Number((sanctionedAmt - totalOTS))).toLocaleString(
            "en-IN",
          );
        } else {
          eligibleDrawingPower = "₹" + parseFloat(Number((totalDP - totalOTS))).toLocaleString(
            "en-IN",
          );
        }
      }
    } else {
    }
    var receivedMaturityDate = "-";
    var loanExpiryDate = "-";
    receivedMaturityDate =
      data.summary[activeContract][0].LoanAccount[0].ScheduleDetails[0]
        .LoanMaturityDate;
    var receivedMaturityDateSplit = receivedMaturityDate.split(" ");
    var receivedMaturityDte = receivedMaturityDateSplit[0].split("/");
    var maturityMonth = receivedMaturityDte[0];
    var maturityDate = receivedMaturityDte[1];
    var maturityYear = receivedMaturityDte[2];
    var loanDate = maturityDate < 10 ? "0" + maturityDate : maturityDate;
    var loanMonth = maturityMonth < 10 ? "0" + maturityMonth : maturityMonth;
    var loanYear = maturityYear < 10 ? "0" + maturityYear : maturityYear;
    loanExpiryDate = loanDate + "/" + loanMonth + "/" + loanYear;
    if (data?.["summary"]) {
      var amountDetailsArray = [
        {
          label: "Sanction Limit",
          value: data.summary[activeContract][0].LoanAccount[0].sanctionedamount ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].sanctionedamount)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Total Drawing Power",
          value: data.summary[activeContract][0].LoanAccount[0].drawingPower ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].drawingPower)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Available Drawing Power",
          value: data.summary[activeContract][0].LoanAccount[0].AmountAvailable_ForDisbursement ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].AmountAvailable_ForDisbursement)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Eligible Drawing Power",
          value: eligibleDrawingPower,
        },
        {
          label: "Margin Shortfall Amount",
          value: data.summary[activeContract][0].LoanAccount[0].marginShortFall ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].marginShortFall)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Current Pending Dues",
          value: data.summary[activeContract][0].LoanAccount[0].AmountDue ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].AmountDue)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Loan Expiry Date",
          value: loanExpiryDate,
        },
      ];

      return amountDetailsArray;
    }
  };
  const setOutStandingDetails = () => {
    if (data?.["summary"]) {
      var outstandingDetailsArray = [
        {
          label: "Principal",
          value: data.summary[activeContract][0].LoanAccount[0].principleoutstanding ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].principleoutstanding)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Interest",
          value: data.summary[activeContract][0].LoanAccount[0].OutstandingInterest ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].OutstandingInterest)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Charges",
          value: data.summary[activeContract][0].LoanAccount[0].OutstandingCharges ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].OutstandingCharges)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Unbilled Interest",
          value: data.summary[activeContract][0].LoanAccount[0].ScheduleDetails[0].UnbilledInterest ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].ScheduleDetails[0].UnbilledInterest)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Unbilled Penal Interest",
          value: data.summary[activeContract][0].LoanAccount[0].ScheduleDetails[0].PenalInterestAccruedNotDue ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].ScheduleDetails[0].PenalInterestAccruedNotDue)).toLocaleString("en-IN") : "₹0",
        },
        {
          label: "Total Outstanding",
          value: data.summary[activeContract][0].LoanAccount[0].TotalOutstanding ? "₹" + parseFloat(Number(data.summary[activeContract][0].LoanAccount[0].TotalOutstanding)).toLocaleString("en-IN") : "₹0",
        },
      ];

      return outstandingDetailsArray;
    }
  };

  const populateCurrentHoldingTable = () => {
    var currentHoldingArray = [];
    if (data?.["pledgeDetailsFoliowise"]?.[activeContract]) {
      data?.["pledgeDetailsFoliowise"]?.[activeContract].map((element) => {
        element["checkedFlag"] = false;
        currentHoldingArray.push(element);
      });
      setCurrentHoldingTableArray(currentHoldingArray);
    }
  };

  function transactionTimerLoad(duration) {
    var timer = duration,
      minutes,
      seconds;
    let summaryInterval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setFreezeTime(minutes + ":" + seconds)

      if (--timer <= 0) {
        timer = duration;
        setFreezeFlag(false)
        var userData = data?.["getUserDataV3"].body;
        var lasData = userData?.otherSystemDetails.filter((value) => {
          return value?.CLIENT_ID;
        });
        callLasApisAfterFreeze(lasData)
        clearInterval(summaryInterval)
      }
    }, 1000);


  }

  const callLasApisAfterFreeze = (lasData) => {
    var preApi = []
    var reqObj = {
      header: {
        authToken: data.authToken,
      },
      body: {
        commonClientCode: lasData[0].CLIENT_ID,
      },
    };
    dispatchData({
      name: "loaderFlag",
      type: STOREDATA,
      payload: true,
    });
    OverviewServiceObject.summaryService(reqObj, lasData, preApi)
      .then((summaryResponse) => {
        if (
          summaryResponse[0].LoanAccount[0].ProductCategory ===
          "Loan Against Shares _Digital"
        ) {
          dispatchData({
            type: STOREACCOUNTDATA,
            payload: {
              response: true,
              contractNumber:
                summaryResponse[0].LoanAccount[0].Account_Number,
            },
            name: "checkEquityFlag",
          });
        } else {
          dispatchData({
            type: STOREACCOUNTDATA,
            payload: {
              response: false,
              contractNumber:
                summaryResponse[0].LoanAccount[0].Account_Number,
            },
            name: "checkEquityFlag",
          });
        }
        dispatchData({
          type: STOREACCOUNTDATA,
          payload: {
            response: summaryResponse,
            contractNumber: summaryResponse[0].LoanAccount[0].Account_Number,
          },
          name: "summary",
        });
        callPledgeDetailsFoliowiseApi()
        // let parsedUserData = {};
      })
      .catch((error) => {
        if (error === "L-12") {
          setFailurePopUpContent({
            apiName: "",
            message:
              "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            errorCode: error,
          });
          setFailurePopUpModal(true);
        }
        dispatchData({
          name: "loaderFlag",
          type: STOREDATA,
          payload: false,
        });
      });
  }

  useEffect(() => {
    if (functionalityFreezeFlag) {
      transactionTimerLoad(freezeTimer)
    }
  }, [freezeFlag])

  const checkRedirectionPortal = () => {
    const getQueryParam = commonFunctionObject.getURLParams(location.search)
    if (getQueryParam != "" && typeof (getQueryParam) === "object") {
      if (getQueryParam.src === "payment") {
        if (getQueryParam.subsrc === "transaction-success") {
          setSuccessPopUpContent("Thank you, your transaction has been done successfully.")
          setSuccessPopUpModal(true)
        } else {
          setFailurePopUpContent({
            apiName: "",
            message: "Sorry, due to some technical issue your payment has not been completed successfully.",
            errorCode: "",
          });
          setFailurePopUpModal(true);
        }
        setFunctionalityFreezeFlag(true)
        setFreezeFlag(true)
        setFreezeTime(parseFloat(600000 / 1000).toString())
      }
    }
  }

  return (
    <div className="loan-holding-nav-component-container">
      {failurePopUpModal ? (
        <FailurePopUp
          onClick={() => setFailurePopUpModal(false)}
          visible={failurePopUpModal}
          setFailureModalStat={setFailurePopUpModal}
          failurePopUpContent={failurePopUpContent}
        />
      ) : null}
      {successPopUpModal ? (
        <SuccessPopUp
          onClick={() => setSuccessPopUpModal(false)}
          visible={successPopUpModal}
          setSuccessModalStat={setSuccessPopUpModal}
          successPopUpContent={successPopUpContent}
        />
      ) : null}
      {!postOtpFlag ? <>
        <div className="loan-features ccod-nav">
          <ul className="las loan-features-ul">
            <li>
              <TabNavItem
                title="Loan Details"
                id="Loan Details"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </li>
            <li>
              <TabNavItem
                title="Transact"
                id="Holding-Details"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </li>
          </ul>
        </div>
        <div className="mobile-custom-drop-down">
          <CustomDropDown
            optionArray={optionArray}
            optionState={optionState}
            setOptionState={setOptionState}
            selectedOption={activeTab}
            setSelectedOption={setActiveTab}
            labelFlag={false}
            labelText="Account Number"
            className={"ccod-drop-down"}
            labelClass={"account-details-container-label "}
          />
        </div>
        {activeTab === "Loan Details" ? (
          <LASLoanDetails
            lasAmountData={lasAmountData}
            outstandingData={outstandingData}
            activeContract={activeContract}
          />
        ) : (
          <LASHoldingDetails
            activeContract={activeContract}
            drawModalState={drawModalState}
            setDrawModalState={setDrawModalState}
            payModalState={payModalState}
            setPayModalState={setPayModalState}
            currentHoldingTableArray={currentHoldingTableArray}
            setCurrentHoldingTableArray={setCurrentHoldingTableArray}
            freezeTimer={freezeTimer}
            freezeFlag={freezeFlag}
            setFreezeTime={setFreezeTime}
            setFreezeFlag={setFreezeFlag}
            noDataFlag={noDataFlag}
            setFunctionalityFreezeFlag={setFunctionalityFreezeFlag}
            postOtpFlag={postOtpFlag}
            setPostOtpFlag={setPostOtpFlag}
            lasData={lasData}
            setLasData={setLasData}
            refNo={refNo}
            setRefNo={setRefNo}
          />
        )
        }
      </>
        :
        <RadiantBorder className="otp-post-login-container">
          <div className='otpDivPostLogin' >
            <PostOtp userInput={data?.summary[data?.currentActiveData?.accountNumber][0].MobileNumber.trim()} pageName="las-disbursement" refID={refNo} postOnCallFlag={true} lasData={lasData} setPostOtpFlag={setPostOtpFlag} postOtpFlag={postOtpFlag} setSuccessPopUpModalLasFlag={setSuccessPopUpModal} setSuccessPopUpContentLas={setSuccessPopUpContent} setModalState={setDrawModalState} setFailurePopUpModalLasFlag={setFailurePopUpModal} setFailurePopUpContentLas={setFailurePopUpContent} setFreezeFlag={setFreezeFlag} setFreezeTime={setFreezeTime} setFunctionalityFreezeFlag={setFunctionalityFreezeFlag} />
          </div>
        </RadiantBorder>}

    </div>
  );
};

LoanHoldingNavComponent.propTypes = {
  activeContract : PropTypes.string,
  navigationObject:PropTypes.object,
  postOtpFlag:PropTypes.bool,
  setPostOtpFlag:PropTypes.func
}

export default LoanHoldingNavComponent;
