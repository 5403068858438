import React, { useState, useContext } from "react";
import Modal from "../../global/Modal";
import './CustomerServicePopUp.scss';
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import Button from "../../global/Button";
import PropTypes from 'prop-types';

const CustomerServicePopUp = ({ onClick, setModalStat, Content }) => {
    const [themeState] = useContext(ThemeContext);

    return (
        <>
            <Modal style={{ background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""}`, }}>
                <div className="customer-service-pop-up" style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`, }}>
                    <div className="sr-pop-up-close-btn" onClick={onClick}>
                        <img src={"assets/images/crossImg.svg"} alt="close" />
                    </div>
                    <div className="sr-pop-up-description">
                        <p style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}`, }}>
                            {Content}
                        </p>
                    </div>
                    <div className="sr-pop-up-proceed">
                        <Button className="email-popup-button" onClick={onClick}>OK</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
CustomerServicePopUp.propTypes = {
    onClick: PropTypes.func,
    Content: PropTypes.string
}
export default CustomerServicePopUp