import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FailurePopUp from "../../../../Retail/src/global/FailurePopUp";
import LoginServiceObject from "../../../../Retail/src/services/Login";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import CreditScore from "../../components/CreditScore/CreditScore";
import DashboardData from "../../components/DashboardData/DashboardData";
import Payment from "../../components/Payment/Payment";
import SkeletonDashboardData from "../../components/SkeletonDashboardData";
import { initialLoadDetails } from "../../constants/initialLoanDetails";
import useReactiveForm from "../../hooks/useReactiveForm/useReactiveForm";
import Validators from "../../hooks/useReactiveForm/validator";
import AvailOfferModal from "../../modals/AvailOfferModal";
import CongratsModal from "../../modals/CongratsandSorryModal";
import OptingSettlementModal from "../../modals/OptingSettlementModal";
import PaymentPlanOfModal from "../../modals/PaymentPlanOfModal/PaymentPlanOfModal";
import ThankYouPopup from "../../modals/ThankYouPopup";
import {
  callVdSummaryAPI,
  dispatchLoanData,
} from "../../services/dispatchUserData";
import { populateOverviewData } from "../../services/populateOverviewData";
import { trenchApiCall } from "../../services/trenchApiCall";
import "./Overview.scss";
import { eventLoggingDtlsApiCall } from "../../services/eventLoggingDtlsApiCall";

const Overview = () => {
  let [loanData, setLoanData] = useState(initialLoadDetails);
  const [data, dispatchData] = useContext(DataMapping);
  const [dashBoardData, setDashBoardData] = useState([]);
  const navigate = useNavigate();
  const [invalidUserFlag, setInvalidUserFlag] = useState(false);
  const [paymentOptClickCount, setPaymentOptClickCount] = useState([]);
  const [invalidUserModal, setInvalidUserModal] = useState(false);
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });
  const [validateFlagEmail, setValidateFlagEmail] = useState(false);
  const [validateFlagMobile, setValidateFlagMobile] = useState(false);
  const [paymentForm, handleForm] = useReactiveForm({
    mobile: ["", [Validators.pattern(/^[6789][\d]{9}$/)]],
    email: ["", [Validators.mail]],
  });
  const [preapprovedOfferFlag, setPreapprovedOfferFlag] = useState(true);
  const [popOverFlag, setPopOverFlag] = useState(false);
  const [payNowFlag, setPayNowFlag] = useState(false);
  const [ischecked, setIsChecked] = useState(false);
  const [attemptFlag, setAttemptFlag] = useState(false);
  const [activeOffer, setActiveOffer] = useState("");
  const [dashPaymentData, setDashPaymentData] = useState("");
  const [successFailureFlag, setSuccessFailureFlag] = useState(false);
  const [paymentSummeryFlag, setPaymentSummeryFlag] = useState(false);
  const [journeyType, setJourneyType] = useState("");
  const [isTrancheOpted, setIsTrancheOpted] = useState("");
  const [noOfTranchesOpted, setNoOfTranchesOpted] = useState("");
  const [paymentFlag, setPaymentFlag] = useState(false);
  const [paymentModalDetails, setPaymentModalDetails] = useState({
    offerType: "",
    trancheNumber: "",
  });

  const [trancheData, setTrancheData] = useState({
    tranche1Data: "",
    tranche2Data: "",
    tranche1Date: "",
    tranche2Date: "",
  });
  const [paymentCardArr, setPaymentCardArr] = useState([
    {
      id: 1,
      labelName: "Total Overdue Amount",
      isActive: true,
      isInput: false,
      amount: "",
    },
    {
      id: 2,
      labelName: "EMI Overdue",
      isActive: false,
      isInput: false,
      amount: "0",
    },
    {
      id: 3,
      labelName: "Other Amount",
      isActive: false,
      isInput: true,
      amount: "",
    },
  ]);
  const [selectedLabel, setSelectedLabel] = useState("Total Overdue Amount");
  const [disableBtn, setDisableBtn] = useState(false);

  // console.log(paymentOptClickCount, "paymentOptClickCount");

  useEffect(() => {
    (async () => {
      const fetchData = async () => {
        const retailContractNumbers = [];
        // Extracting retail contract numbers from user data
        data.getUserDataV3?.body?.contracts.forEach((item) => {
          if (item?.system === "retail") {
            retailContractNumbers.push(item.CONTRACT_NUMBER);
          }
        });

        try {
          const offerDataResponse =
            await LoginServiceObject.offerCollectionDetails(
              data.authToken,
              retailContractNumbers
            );
          // console.log(offerDataResponse, "offerDataResponse");
          dispatchData({
            type: STOREDATA,
            name: "offers",
            payload: offerDataResponse,
          });
        } catch (error) {
          console.error("Error fetching offer data:", error);
        }
      };
      fetchData();
    })();
  }, [paymentFlag]);

  let preApi = [];
  useEffect(() => {
    dispatchLoanData(dispatchData, data, preApi, loanData, setLoanData);
    callVdSummaryAPI(dispatchData, data, preApi);
    trenchApiCall(dispatchData, data, preApi);
  }, [attemptFlag, paymentFlag]);

  useEffect(() => {
    let allContractsLength = data?.getUserDataV3?.body.contracts.length;
    let currentDatalength = data?.getLoanPositionDTLS
      ? Object.keys(data.getLoanPositionDTLS).length
      : 0;
    // console.log(allContractsLength,"allContractsLength", currentDatalength,"currentDatalength");
    if (currentDatalength == allContractsLength) {
      const dashboardDetails = populateOverviewData(data, preapprovedOfferFlag);
      // console.log(dashboardDetails, "dashboardDetails");
      let mergedData = dashboardDetails.map((ele) => {
        const offerObj = data?.offers?.body?.offerDetails.find((offer) => {
          return offer["LOAN_ACCOUNT_NO"] === ele.contractNumber;
        });

        const settlementFlag = offerObj
          ? offerObj["SETTLEMENT_CAMPAIGN"]
          : null;

        if (offerObj) {
          return {
            ...ele,
            offerData: offerObj,
            settlementFlag: settlementFlag,
          };
        } else {
          return ele;
        }
      });

      // If there are unmatched offers, add them to mergedData
      data?.offers?.body?.offerDetails.forEach((offer) => {
        const existingContractNumbers = dashboardDetails.map(
          (ele) => ele.contractNumber
        );
        if (!existingContractNumbers.includes(offer["LOAN_ACCOUNT_NO"])) {
          // Add the unmatched offer to mergedData
          mergedData.push({
            contractNumber: offer["LOAN_ACCOUNT_NO"],
            offerData: offer,
            settlementFlag: offer["SETTLEMENT_CAMPAIGN"],
          });
        }
      });

      var array1 = [];
      var array2 = [];
      var array3 = [];
      let offerWithArr = mergedData.filter((ele) => {
        if (ele.loanStatus === "Active" && ele.offerFlag === "Y") {
          array1.push(ele);
        } else if (ele.loanStatus === "Active" && ele.offerFlag === "N") {
          array2.push(ele);
        } else if (ele.loanStatus === "Closed" && ele.offerFlag === "Y") {
          array3.push(ele);
        }
      });

      const statusOrder = { Active: 1, Closed: 2 };

      array1.sort((a, b) => {
        const statusA = statusOrder[a.loanStatus];
        const statusB = statusOrder[b.loanStatus];

        if (statusA !== statusB) {
          return statusA - statusB; // Sort by "loanStatus" first
        } else if (a.loanStatus === "Active") {
          // Sort by "offerData" properties for Active accounts
          const activeKeysOrder = [
            "SETTLEMENT_CAMPAIGN",
            "EMI_OVERDUE_CAMPAIGN",
            "ODBC_CAMPAIGN",
            "LOS_POOL_CAMPAIGN",
            "ALREADY_SETTLED_POOL_CAMPAIGN",
          ];

          for (const key of activeKeysOrder) {
            const result = b?.offerData[key].localeCompare(a?.offerData[key]);
            if (result !== 0) {
              return result;
            }
          }

          // Add sorting based on "FC_POS" for Active accounts
          const fcPosA = parseInt(a.offerData.FC_POS, 10);
          const fcPosB = parseInt(b.offerData.FC_POS, 10);

          // Sort in descending order, change the comparison (fcPosA - fcPosB) to (fcPosB - fcPosA) for ascending order
          return fcPosB - fcPosA;
        }

        return 0;
      });
      // console.log(array1,"array1");
      // console.log(array2,"array2");
      // console.log(array3,"array3");

      let finalData = [...array1, ...array2, ...array3];
      // console.log(finalData,"finalData");
      setDashBoardData(finalData);
      setInvalidUserFlag(() => true);
    }
  }, [data, attemptFlag, paymentFlag]);

  useEffect(() => {
    if (!data?.authToken) {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (data.length === 0) {
      navigate("/login");
    } else {
      Promise.all(preApi).finally(() => {
        dispatchData({
          name: "loaderFlag",
          type: STOREDATA,
          payload: false,
        });
      });
    }
  }, []);

  const handleClickForPaymentOpt = (
    type,
    payOptType,
    inputType,
    mobEmailValue,
    activeLoan
  ) => {
    let temObj = {
      selectedTotalOverdueAmtPaymentCnt: 0,
      selectedEmiOverdueAmtPaymentCnt: 0,
      selectedOtherAmtPaymentCnt: 0,
      totalOverdueAmt: "",
      emiOverdueAmt: "",
      paymentStatus: "",
      gcid: "",
      accountNumber: "",
      bucket: "",
      product: "",
      entity: "",
      additionalMobile: "",
      additionalEmail: "",
      isSettlementOffer: "",
      isOfferAvailed: "",
      minimumSettlementAmount: "",
      totalForeclosureAmount: "",
      foreclosurePrincipalOutstanding: "",
      foreclosureInterest: "",
      foreclosureCharges: "",
      settlementAmountEntered1: "",
      settlementAmountEntered2: "",
      settlementAmountEntered3: "",
      softSettlementAmountEntered1: "",
      softSettlementAmountEntered2: "",
      softSettlementAmountEntered3: "",
      softSettlementAmountEntered4: "",
      softSettlementAmountEntered5: "",
      settlementOfferReason: "",
      settlementOfferReasonOthers: "",
      offerApprovalStatus: "",
      offerApprovedSettlementAmount: "",
      actualWaiverPrincipalOutstanding: "",
      actualWaiverInterest: "",
      actualWaiverCharges: "",
      actualWaiverForeclosure: "",
      availableTranches: "",
      availedTranches: "",
      tranche1DueDate: "",
      tranche1Amount: "",
      tranche1PaymentDate: "",
      tranche1PaymentStatus: "",
      tranche2DueDate: "",
      tranche2Amount: "",
      tranche2PaymentDate: "",
      tranche2PaymentStatus: "",
      offerPaymentStatus: "",
      isSettlementLetterDownloaded: "",
      transactionId: "",
    };

    if (type === "loancardclicked") {
      let index = dashBoardData.findIndex(
        (ele) => ele.contractNumber === activeLoan
      );
      let loanDetails = dashBoardData[index]?.loanDetails;

      let totalOverdueAmt = 0;
      let emiOverdueAmt = 0;

      loanDetails?.forEach((amount) => {
        if (
          amount.name === "EMI OVERDUE AMOUNT" ||
          amount.name == "CHARGES OVERDUE AMOUNT"
        ) {
          totalOverdueAmt += amount.value;
        }
        if (amount.name === "EMI OVERDUE AMOUNT") {
          emiOverdueAmt = amount.value;
        }
      });

      // let temObj = {
      //   gcid: data?.parsedUserData[activeLoan]?.GCID,
      //   accountNumber: activeLoan,
      //   selectedTotalOverdueAmtPaymentCnt: 0,
      //   selectedEmiOverdueAmtPaymentCnt: 0,
      //   selectedOtherAmtPaymentCnt: 0,
      //   totalOverdueAmt: totalOverdueAmt,
      //   emiOverdueAmt: emiOverdueAmt,
      //   additionalMobile: "",
      //   additionalEmail: "",
      //   settlementOffer: "",
      //   isOfferAvailed: "",
      // };
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        if (!newData.find((obj) => obj.accountNumber === activeLoan)) {
          newData.push(temObj);
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type === "handleNumEmail") {
      setPaymentOptClickCount((prev) => {
        let newState = [...prev];
        newState.forEach((obj) => {
          if (obj.accountNumber === data.currentActiveData.accountNumber) {
            // console.log(data.currentActiveData);
            if (inputType === "mobile") {
              obj.additionalMobile = mobEmailValue;
            } else {
              obj.additionalEmail = mobEmailValue;
            }
          }
        });
        dispatchData({
          name: "eventUserDetails",
          payload: newState,
          type: STOREDATA,
        });
        return newState;
      });
    } else if (type === "paymentoptclicked") {
      let loanAccNumber = activeLoan
        ? activeLoan
        : data.currentActiveData.accountNumber;
      setPaymentOptClickCount((prev) => {
        let newState = [...prev];
        newState.forEach((obj) => {
          if (obj.accountNumber === loanAccNumber) {
            if (payOptType === "Total Overdue Amount") {
              obj.selectedTotalOverdueAmtPaymentCnt += 1;
            } else if (payOptType === "EMI Overdue") {
              obj.selectedEmiOverdueAmtPaymentCnt += 1;
            } else if (payOptType === "Other Amount") {
              obj.selectedOtherAmtPaymentCnt += 1;
            }
          }
        });
        dispatchData({
          name: "eventUserDetails",
          payload: newState,
          type: STOREDATA,
        });
        return newState;
      });
    } else if (type == "isSettlementOffer") {
      let index = dashBoardData.findIndex(
        (ele) => ele.contractNumber === activeLoan
      );
      let loanDetails = dashBoardData[index]?.loanDetails;

      let totalOverdueAmt = 0;
      let emiOverdueAmt = 0;

      loanDetails?.forEach((amount) => {
        if (
          amount.name === "EMI OVERDUE AMOUNT" ||
          amount.name == "CHARGES OVERDUE AMOUNT"
        ) {
          totalOverdueAmt += amount.value;
        }
        if (amount.name === "EMI OVERDUE AMOUNT") {
          emiOverdueAmt = amount.value;
        }
      });

      let productData = data?.getUserDataV3.body.contracts.filter(
        (obj) => obj?.CONTRACT_NUMBER == activeLoan
      );
      // console.log(productData[0]?.productInfo?.PRODUCT,"pro");

      let bucketData = data?.offers
        ? data?.offers?.body?.offerDetails?.filter((obj) => {
          if (
            obj.LOAN_ACCOUNT_NO == activeLoan &&
            obj.SETTLEMENT_CAMPAIGN == "Y"
          ) {
            return obj;
          }
        })
        : "";
      // console.log(bucketData[0],"ede");
      if (preapprovedOfferFlag) {
        if (bucketData[0]?.IS_OFFER_ACCEPTED == "Y") {
          if (bucketData[0]?.NO_OF_TRANCHES_OPTED == 1) {
            if (bucketData[0]?.TRANCHE1_PAYMENT_STATUS) {
              if (bucketData[0]?.TRANCHE1_PAYMENT_STATUS == "Success") {
                if (inputType == "N") {
                  temObj.offerPaymentStatus = "";
                } else if (inputType == "Y") {
                  temObj.offerPaymentStatus = "Paid";
                }
              } else if (
                bucketData[0]?.TRANCHE1_PAYMENT_STATUS == "Pending" ||
                bucketData[0]?.TRANCHE1_PAYMENT_STATUS == "Failed" ||
                bucketData[0]?.TRANCHE1_PAYMENT_STATUS == "INITIATED"
              ) {
                if (inputType == "N") {
                  temObj.offerPaymentStatus = "";
                } else if (inputType == "Y") {
                  temObj.offerPaymentStatus = "Unpaid";
                }
              }
            } else {
              if (inputType == "N") {
                temObj.offerPaymentStatus = "";
              } else if (inputType == "Y") {
                temObj.offerPaymentStatus = "Unpaid";
              }
            }
          } else if (bucketData[0]?.NO_OF_TRANCHES_OPTED == 2) {
            if (bucketData[0]?.TRANCHE2_PAYMENT_STATUS) {
              if (bucketData[0]?.TRANCHE2_PAYMENT_STATUS == "Success") {
                if (inputType == "N") {
                  temObj.offerPaymentStatus = "";
                } else if (inputType == "Y") {
                  temObj.offerPaymentStatus = "Paid";
                }
              } else if (
                bucketData[0]?.TRANCHE2_PAYMENT_STATUS == "Failed" ||
                bucketData[0]?.TRANCHE2_PAYMENT_STATUS == "Pending" ||
                bucketData[0]?.TRANCHE2_PAYMENT_STATUS == "INITIATED"
              ) {
                if (inputType == "N") {
                  temObj.offerPaymentStatus = "";
                } else if (inputType == "Y") {
                  temObj.offerPaymentStatus = "Partially Paid";
                }
              }
            } else if (bucketData[0]?.TRANCHE1_PAYMENT_STATUS) {
              if (bucketData[0]?.TRANCHE1_PAYMENT_STATUS == "Success") {
                if (inputType == "N") {
                  temObj.offerPaymentStatus = "";
                } else if (inputType == "Y") {
                  temObj.offerPaymentStatus = "Partially Paid";
                }
              } else if (
                bucketData[0]?.TRANCHE1_PAYMENT_STATUS == "Pending" ||
                bucketData[0]?.TRANCHE1_PAYMENT_STATUS == "Failed" ||
                bucketData[0]?.TRANCHE1_PAYMENT_STATUS == "INITIATED"
              ) {
                if (inputType == "N") {
                  temObj.offerPaymentStatus = "";
                } else if (inputType == "Y") {
                  temObj.offerPaymentStatus = "Unpaid";
                }
              }
            } else {
              if (inputType == "N") {
                temObj.offerPaymentStatus = "";
              } else if (inputType == "Y") {
                temObj.offerPaymentStatus = "Unpaid";
              }
            }
          }
        } else {
          if (inputType == "N") {
            temObj.offerPaymentStatus = "";
          } else if (inputType == "Y") {
            temObj.offerPaymentStatus = "Unpaid";
          }
        }
      } else if (preapprovedOfferFlag == false) {
        if (inputType == "N") {
          temObj.offerPaymentStatus = "";
        } else if (inputType == "Y") {
          temObj.offerPaymentStatus = "Unpaid";
        }
      }

      temObj.product = productData[0]?.productInfo?.PRODUCT;
      temObj.entity = bucketData[0]?.ENTITY;
      temObj.bucket = bucketData[0]?.BKT;
      temObj.totalOverdueAmt = totalOverdueAmt;
      temObj.emiOverdueAmt = emiOverdueAmt;
      temObj.isSettlementOffer = inputType;
      temObj.accountNumber = activeLoan;
      temObj.availableTranches = bucketData[0]?.NO_OF_TRANCHES;
      temObj.gcid = data?.parsedUserData[activeLoan]?.GCID;
      // temObj.offerPaymentStatus = "Unpaid";

      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );
        if (existingObj) {
          existingObj.isSettlementOffer = inputType;
        } else {
          newData.push(temObj);
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "handlePaymentGridTransactionId") {
      // console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );

        if (existingObj) {
          existingObj.transactionId = inputType;
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "offerAvailClick") {
      // let temObj = {
      //     gcid: data?.parsedUserData[activeLoan]?.GCID,
      //     accountNumber: activeLoan,
      //     isOfferAvailed: inputType
      //   };
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );

        if (existingObj) {
          existingObj.isOfferAvailed = inputType;
        }

        if (inputType == "N") {
          existingObj.offerPaymentStatus = "";
        } else if (inputType == "Y") {
          existingObj.offerPaymentStatus = "Unpaid";
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "setLoanFcDetails") {
      // console.log(inputType, "inputTypess");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );
        let calMinSettleAmount = "";
        if (inputType.ENTITY == "HFL") {
          calMinSettleAmount = inputType.SETTLEMENT_AMOUNT_MIN;
        } else {
          calMinSettleAmount = "";
        }

        if (existingObj) {
          existingObj.totalForeclosureAmount = inputType.FC;
          existingObj.foreclosurePrincipalOutstanding = inputType.FC_POS;
          existingObj.foreclosureInterest = inputType.FC_INT;
          existingObj.foreclosureCharges = inputType.FC_CHARGES;
          existingObj.minimumSettlementAmount = calMinSettleAmount;
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "setMinimumSettlementAmount") {
      // console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );

        if (existingObj) {
          if (inputType.count == 2) {
            existingObj.settlementAmountEntered1 =
              inputType.entersettlementAmount;
          } else if (inputType.count == 1) {
            existingObj.settlementAmountEntered2 =
              inputType.entersettlementAmount;
          } else if (inputType.count == 0) {
            existingObj.settlementAmountEntered3 =
              inputType.entersettlementAmount;
          }

          existingObj.minimumSettlementAmount = inputType.minSettlementAmount;
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "handleSoftSettlementAmount") {
      // console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );

        if (existingObj) {
          if (existingObj.softSettlementAmountEntered1 === "") {
            existingObj.softSettlementAmountEntered1 =
              inputType.entersettlementAmount;
          } else if (existingObj.softSettlementAmountEntered2 === "") {
            existingObj.softSettlementAmountEntered2 =
              inputType.entersettlementAmount;
          } else if (existingObj.softSettlementAmountEntered3 === "") {
            existingObj.softSettlementAmountEntered3 =
              inputType.entersettlementAmount;
          } else if (existingObj.softSettlementAmountEntered4 === "") {
            existingObj.softSettlementAmountEntered4 =
              inputType.entersettlementAmount;
          } else if (existingObj.softSettlementAmountEntered5 === "") {
            existingObj.softSettlementAmountEntered5 =
              inputType.entersettlementAmount;
          } else {
            existingObj.softSettlementAmountEntered5 =
              inputType.entersettlementAmount;
          }

          existingObj.minimumSettlementAmount = inputType.minSettlementAmount;
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "handleReasonSelectionData") {
      // console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );

        if (existingObj) {
          existingObj.settlementOfferReason = inputType.reasons;
          existingObj.settlementOfferReasonOthers = inputType.otherReasonText;
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "handleAvailedTranches") {
      // console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );

        if (existingObj) {
          existingObj.availedTranches = inputType;
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "handleOfferApprovedStatus") {
      console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );

        // Convert all input values from strings to numbers
        let actualForeclosurePos = Number(inputType.fcPosValue);
        let actualForeclosureInterest = Number(inputType.fcInterestValue);
        let actualForeclosureCharges = Number(inputType.fcChargesValue);

        let finalOfferApprovedSettlementAmount = Number(
          inputType.offerApprovedSettlementAmount
        );

        // Initialize waiver variables
        let actualWaiverForeclosurePos = 0;
        let actualWaiverForeclosureInterest = 0;
        let actualWaiverForeclosureCharges = 0;

        // Step 1: Calculate waiver for POS value
        if (finalOfferApprovedSettlementAmount > actualForeclosurePos) {
          // Entire POS is waived
          actualWaiverForeclosurePos = 0;
          let remainingAmount =
            finalOfferApprovedSettlementAmount - actualForeclosurePos;

          // Step 2: Calculate waiver for Interest value
          if (remainingAmount > actualForeclosureInterest) {
            // Entire Interest is waived
            actualWaiverForeclosureInterest = 0;
            remainingAmount -= actualForeclosureInterest;

            // Step 3: Calculate waiver for Charges value
            if (remainingAmount >= actualForeclosureCharges) {
              // Entire Charges are waived
              actualWaiverForeclosureCharges = 0;
            } else {
              // Part of the Charges is waived
              actualWaiverForeclosureCharges =
                actualForeclosureCharges - remainingAmount;
            }
          } else {
            // Part of or no waiver for Interest
            actualWaiverForeclosureInterest =
              actualForeclosureInterest - remainingAmount;
            actualWaiverForeclosureCharges = actualForeclosureCharges; // No waiver on charges
          }
        } else {
          // If the settlement amount doesn't cover POS, no waiver is applied
          actualWaiverForeclosurePos =
            actualForeclosurePos - finalOfferApprovedSettlementAmount;
          actualWaiverForeclosureInterest = actualForeclosureInterest;
          actualWaiverForeclosureCharges = actualForeclosureCharges;
        }

        // Calculate total waiver value
        let actualWaiverForeclosureVal =
          actualWaiverForeclosurePos +
          actualWaiverForeclosureInterest +
          actualWaiverForeclosureCharges;

        // Format numbers to two decimal places where necessary
        function formatNumber(num) {
          return parseFloat(num).toFixed(2);
        }

        // Final object update
        if (existingObj) {
          existingObj.offerApprovalStatus = inputType.offerApprovalStatus;
          existingObj.offerApprovedSettlementAmount = formatNumber(
            finalOfferApprovedSettlementAmount
          );
          existingObj.actualWaiverForeclosure = formatNumber(
            actualWaiverForeclosureVal
          );
          existingObj.actualWaiverPrincipalOutstanding = formatNumber(
            actualWaiverForeclosurePos
          );
          existingObj.actualWaiverInterest = formatNumber(
            actualWaiverForeclosureInterest
          );
          existingObj.actualWaiverCharges = formatNumber(
            actualWaiverForeclosureCharges
          );
          existingObj.isSettlementLetterDownloaded =
            inputType.isSettlementLetterDownloaded;
          existingObj.tranche1Amount = inputType.tranche1Amount;
          existingObj.tranche1DueDate = inputType.tranche1DueDate;
          existingObj.tranche2Amount = inputType.tranche2Amount;
          existingObj.tranche2DueDate = inputType.tranche2DueDate;
        }

        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "handleCongratulationTransactionId") {
      // console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );

        if (existingObj) {
          existingObj.transactionId = inputType;
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "handleCongratulationCrossEvent") {
      const updateSettlementAmounts = (existingObj, inputType) => {
        const amounts = [
          "settlementAmountEntered1",
          "settlementAmountEntered2",
          "settlementAmountEntered3",
        ];

        if (existingObj[amounts[2]] !== "") {
          existingObj[amounts[2]] = inputType;
        } else if (existingObj[amounts[1]] !== "") {
          existingObj[amounts[1]] = inputType;
        } else if (existingObj[amounts[0]] !== "") {
          existingObj[amounts[0]] = inputType;
        }
      };

      // console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );
        if (existingObj) {
          updateSettlementAmounts(existingObj, inputType);
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });

        return newData;
      });
    } else if (type == "setFinalApprovedAmount") {
      // console.log(inputType, "inputType");

      const updateSettlementAmounts = (existingObj, inputType) => {
        const amounts = [
          "softSettlementAmountEntered5",
          "softSettlementAmountEntered4",
          "softSettlementAmountEntered3",
          "softSettlementAmountEntered2",
          "softSettlementAmountEntered1",
        ];

        for (let softAmountText of amounts) {
          if (
            existingObj[softAmountText] &&
            existingObj[softAmountText] == inputType.finalOfferApprovedAmount
          ) {
            existingObj[softAmountText] = "";
            break;
          }
        }
      };

      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );

        if (existingObj) {
          if (inputType.attempt == 3) {
            existingObj.settlementAmountEntered1 =
              inputType.finalOfferApprovedAmount;
            updateSettlementAmounts(existingObj, inputType);
          } else if (inputType.attempt == 2) {
            existingObj.settlementAmountEntered2 =
              inputType.finalOfferApprovedAmount;
            updateSettlementAmounts(existingObj, inputType);
          } else if (inputType.attempt == 1) {
            existingObj.settlementAmountEntered3 =
              inputType.finalOfferApprovedAmount;
            updateSettlementAmounts(existingObj, inputType);
          }
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "handleIsSettlementClick") {
      // console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );
        if (existingObj) {
          existingObj.isSettlementLetterDownloaded = inputType;
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    } else if (type == "paymentGridDetails") {
      // console.log(inputType, "inputType");
      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === activeLoan
        );
        // console.log(inputType, "inputType-data");

        if (inputType.trancheOpted == 1) {
          if (existingObj) {
            existingObj.tranche1DueDate = inputType.tranche1_due_date;
            existingObj.tranche1Amount = inputType.tranche1_due_amount;
            existingObj.tranche1PaymentDate = inputType.tranche1PaymentDate;
            existingObj.tranche1PaymentStatus = inputType.tranche1PaymentStatus;
            existingObj.tranche2DueDate = "";
            existingObj.tranche2Amount = "";
            existingObj.tranche2PaymentDate = "";
            existingObj.tranche2PaymentStatus = "";
            existingObj.minimumSettlementAmount =
              inputType.minimumSettlementAmountVal;
            existingObj.availableTranches = inputType.availableTranchesVal;
            existingObj.availedTranches = inputType.availedTranchesVal;
            existingObj.offerApprovedSettlementAmount =
              inputType.offerApprovedAmountVal;
            existingObj.totalForeclosureAmount = inputType.offer_FC;
            existingObj.foreclosurePrincipalOutstanding =
              inputType.offer_FC_POS;
            existingObj.foreclosureInterest = inputType.offer_FC_INT;
            existingObj.foreclosureCharges = inputType.offer_FC_CHARGES;
          }
        } else if (inputType.trancheOpted == 2) {
          if (existingObj) {
            existingObj.tranche1DueDate = inputType.tranche1_due_date_data;
            existingObj.tranche1Amount = inputType.tranche1_due_amount_data;
            existingObj.tranche1PaymentDate = inputType.tranche1PaymentDateData;
            existingObj.tranche1PaymentStatus =
              inputType.tranche1PaymentStatusData;
            existingObj.tranche2DueDate = inputType.tranche2_due_date_data;
            existingObj.tranche2Amount = inputType.tranche2_due_amount_data;
            existingObj.tranche2PaymentDate = inputType.tranche2PaymentDateData;
            existingObj.tranche2PaymentStatus =
              inputType.tranche2PaymentStatusData;
            existingObj.minimumSettlementAmount =
              inputType.minimumSettlementAmountVal;
            existingObj.availableTranches = inputType.availableTranchesVal;
            existingObj.availedTranches = inputType.availedTranchesVal;
            existingObj.offerApprovedSettlementAmount =
              inputType.offerApprovedAmountVal;
            existingObj.totalForeclosureAmount = inputType.offer_FC;
            existingObj.foreclosurePrincipalOutstanding =
              inputType.offer_FC_POS;
            existingObj.foreclosureInterest = inputType.offer_FC_INT;
            existingObj.foreclosureCharges = inputType.offer_FC_CHARGES;
          }
        }

        // console.log(newData,"newData");
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });
    }
  };

  useEffect(() => {
    if (preapprovedOfferFlag == false) {
      let currentAccountContext = data.currentActiveData;
      let currentItem = dashBoardData?.find(
        (ele) => ele.contractNumber === currentAccountContext.accountNumber
      );
      let currentAccountContextData = currentItem;
      // console.log(currentAccountContextData, "currentAccountContextData");

      let totalOverdueAmount = 0;
      let emiOverdue = 0;
      if (currentAccountContext?.system == "retail") {
        currentAccountContextData?.loanDetails.forEach((ele) => {
          if (
            ele.name == "EMI OVERDUE AMOUNT" ||
            ele.name == "CHARGES OVERDUE AMOUNT"
          ) {
            totalOverdueAmount += ele.value;
            // console.log(totalOverdueAmount, "tod");
          }
          if (ele.name == "EMI OVERDUE AMOUNT") {
            emiOverdue = ele.value;
            // console.log(emiOverdue, "emiOverdue");
          }
        });
        dispatchData({
          name: "prePaymentLabelDetails",
          payload: {
            "Total Overdue Amount": {
              labelName: "Total Overdue Amount",
              validAmount: totalOverdueAmount,
            },
            "EMI Overdue": {
              labelName: "Emi Overdue",
              validAmount: emiOverdue,
            },
            "Other Amount": {
              labelName: "Other Amount",
              validAmount: "",
            },
          },
          type: STOREDATA,
        });
        if (Number(totalOverdueAmount) === 0) {
          setDisableBtn(true);
        } else {
          setDisableBtn(false);
        }
        setSelectedLabel("Total Overdue Amount");
        dispatchData({
          name: "activePhase1PaymentLabel",
          payload: "Total Overdue Amount",
          type: STOREDATA,
        });
        setPaymentCardArr([
          {
            labelName: "Total Overdue Amount",
            isActive: true,
            isInput: false,
            amount: totalOverdueAmount,
            disabled: false,
            validAmount: totalOverdueAmount,
          },
          {
            labelName: "EMI Overdue",
            isActive: false,
            isInput: false,
            amount: emiOverdue,
            disabled: emiOverdue == 0 ? true : false,
          },
          {
            labelName: "Other Amount",
            isActive: false,
            disabled: false,
            isInput: true,
            amount: "",
            validAmount: totalOverdueAmount,
          },
        ]);
      } else {
        currentAccountContextData?.loanDetails.forEach((ele) => {
          if (ele.name == "OVERDUE AMOUNT") {
            totalOverdueAmount = ele.value;
          }
        });
        if (Number(totalOverdueAmount) === 0) {
          setDisableBtn(true);
        } else {
          setDisableBtn(false);
        }
        dispatchData({
          name: "prePaymentLabelDetails",
          payload: {
            "Total Overdue Amount": {
              labelName: "Total Overdue Amount",
              validAmount: totalOverdueAmount,
            },
            "EMI Overdue": {
              labelName: "EMI Overdue",
              validAmount: emiOverdue,
            },
            "Other Amount": {
              labelName: "Other Amount",
              validAmount: "",
            },
          },
          type: STOREDATA,
        });
        setSelectedLabel("Total Overdue Amount");
        dispatchData({
          name: "activePhase1PaymentLabel",
          payload: "Total Overdue Amount",
          type: STOREDATA,
        });

        setPaymentCardArr([
          {
            labelName: "Total Overdue Amount",
            isActive: true,
            isInput: false,
            amount: totalOverdueAmount,
            disabled: totalOverdueAmount == 0 ? true : false,
            validAmount: totalOverdueAmount,
          },
          {
            labelName: "Other Amount",
            isActive: false,
            disabled: totalOverdueAmount == 0 ? true : false,
            isInput: true,
            amount: "",
            validAmount: totalOverdueAmount,
          },
        ]);
      }
      if (
        data?.phase1PaymentPreDataLabel &&
        data?.phase1PaymentPreDataLabel !== ""
      ) {
        setSelectedLabel(data?.phase1PaymentPreDataLabel);
      }
    }
  }, [preapprovedOfferFlag, data.currentActiveData.accountNumber]);

  const handleActive = (index, currCard) => {
    setSelectedLabel(() => currCard.labelName);
    dispatchData({
      name: "activePhase1PaymentLabel",
      payload: currCard.labelName,
      type: STOREDATA,
    });
    if (currCard.labelName !== selectedLabel) {
      handleClickForPaymentOpt("paymentoptclicked", currCard.labelName);
    }
  };

  const setFinalPayOptClickObj = (
    uniqueTransactionId,
    callType,
    paymentStatus
  ) => {
    let finalPaymentAmt = "";
    let temObj = {};
    let currentdate = new Date();
    let datetime = `${currentdate.getFullYear()}/${(
      "0" +
      (currentdate.getMonth() + 1)
    ).slice(-2)}/${("0" + currentdate.getDate()).slice(-2)} ${(
      "0" + currentdate.getHours()
    ).slice(-2)}:${("0" + currentdate.getMinutes()).slice(-2)}:${(
      "0" + currentdate.getSeconds()
    ).slice(-2)}`;

    function formatDateTime(dateString) {
      // Step 1: Parse the input date string
      const date = new Date(dateString);

      // Helper function to pad single digit numbers with a leading zero
      const padZero = (num) => (num < 10 ? "0" + num : num);

      // Step 2: Format to desired output format
      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1); // Months are zero-indexed in JavaScript
      const day = padZero(date.getDate());
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());

      const formattedDateTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

      // Step 3: Extract login date and time
      const loginDateValue = `${year}/${month}/${day}`;
      const loginTimeValue = `${hours}:${minutes}:${seconds}`;

      // Output the results
      console.log(`Formatted DateTime: ${formattedDateTime}`);
      console.log(`loginDate: ${loginDateValue}`);
      console.log(`loginTime: ${loginTimeValue}`);

      return {
        formattedDateTime,
        loginDateValue,
        loginTimeValue,
      };
    }

    function convertUTCToIST(timeString) {
      // Parse the input time string (assumed to be in "HH:MM:SS" format)
      const [hours, minutes, seconds] = timeString.split(":").map(Number);

      // Create a new Date object for the current date in UTC with the given time
      const utcDate = new Date(Date.UTC(2024, 7, 2, hours, minutes, seconds)); // August is month 7 (0-indexed)

      // Add 5 hours and 30 minutes to the UTC time to convert to IST
      const istOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
      const istDate = new Date(utcDate.getTime() + istOffset);

      // Extract IST time components
      const padZero = (num) => (num < 10 ? "0" + num : num);

      const hoursIST = padZero(istDate.getUTCHours());
      const minutesIST = padZero(istDate.getUTCMinutes());
      const secondsIST = padZero(istDate.getUTCSeconds());

      const formattedISTTime = `${hoursIST}:${minutesIST}:${secondsIST}`;

      return formattedISTTime;
    }

    const loggedInTimeDate = formatDateTime(
      data.getUserDataV3.body.LAST_LOGGED_IN
    );

    const istTimestamp = convertUTCToIST(loggedInTimeDate?.loginTimeValue);

    paymentCardArr.forEach((obj) => {
      if (obj.labelName === selectedLabel) {
        finalPaymentAmt = obj.amount;
      }
    });

    if (callType === "paymentStatusRedirection") {
      let newEventData = {
        eventIdData: `${uniqueTransactionId}-${Math.round(
          Math.random() * 99999
        )}`,
        paymentTransactionIdData: uniqueTransactionId,
      };

      setPaymentOptClickCount((prev) => {
        let newData = [...prev];
        let existingObj = newData.find(
          (obj) => obj.accountNumber === data.currentActiveData.accountNumber
        );

        if (existingObj) {
          existingObj.transactionId = uniqueTransactionId;
        }
        dispatchData({
          name: "eventUserDetails",
          payload: newData,
          type: STOREDATA,
        });
        return newData;
      });

      dispatchData({
        name: "latestPaymentTransactionDetails",
        payload: newEventData,
        type: STOREDATA,
      });

      temObj = {
        eventName: "paymentStatusRedirection",
        eventId: newEventData.eventIdData,
        eventTimestamp: datetime,
        primaryGcid:
          data.parsedUserData[data.currentActiveData.accountNumber]
            .PRIMARY_GCID,
        loginDate: loggedInTimeDate?.loginDateValue,
        loginTime: istTimestamp,
        loginMethod: data?.setLoginType || null,
        paymentTransactionId: newEventData.paymentTransactionIdData,
        paymentStatus: data.callpgs ? paymentStatus : "",
        offerPaymentStatus: data.callpgs ? "" : paymentStatus,
      };
    } else if (callType === "paymentOptionClick") {
      temObj = {
        eventName: "paymentOptionClick",
        eventId: `${uniqueTransactionId}-${Math.round(Math.random() * 99999)}`,
        eventTimestamp: datetime,
        primaryGcid:
          data.parsedUserData[data.currentActiveData.accountNumber]
            .PRIMARY_GCID,
        userId: data.getUserDataV3.body.USER_ID,
        token: data.authToken,
        loginDate: loggedInTimeDate?.loginDateValue,
        loginTime: istTimestamp,
        loginMethod: data?.setLoginType || null,
        paymentTransactionId: uniqueTransactionId,
        finalAccountNumber: data.currentActiveData.accountNumber,
        finalPaymentOption: selectedLabel,
        finalPaymentAmt: finalPaymentAmt,
        creditScoreChecked:
          data.getUserDataV3.body.CREDIT_SCORE === "" ? "N" : "Y",
        finalAdditionalMobile: paymentForm.value.mobile,
        finalAdditionalEmail: paymentForm.value.email,
        eventDetails: [...paymentOptClickCount],
      };
    } else if (callType === "settlementOfferCongragulationPopupClick") {
      temObj = {
        eventName: "settlementOfferCongragulationPopupClick",
        eventId: `${uniqueTransactionId}-${Math.round(Math.random() * 99999)}`,
        eventTimestamp: datetime,
        primaryGcid:
          data.parsedUserData[data.currentActiveData.accountNumber]
            .PRIMARY_GCID,
        userId: data.getUserDataV3.body.USER_ID,
        token: data.authToken,
        loginDate: loggedInTimeDate?.loginDateValue,
        loginTime: istTimestamp,
        loginMethod: data?.setLoginType || null,
        paymentTransactionId: uniqueTransactionId,
        finalAccountNumber: data.currentActiveData.accountNumber,
        finalPaymentOption: "",
        finalPaymentAmt: "",
        creditScoreChecked:
          data.getUserDataV3.body.CREDIT_SCORE === "" ? "N" : "Y",
        finalAdditionalMobile: paymentForm.value.mobile,
        finalAdditionalEmail: paymentForm.value.email,
        logoutTime: "",
        eventDetails: [...paymentOptClickCount],
      };
    } else if (callType === "settlementOfferSummaryPopupClick") {
      temObj = {
        eventName: "settlementOfferSummaryPopupClick",
        eventId: `${uniqueTransactionId}-${Math.round(Math.random() * 99999)}`,
        eventTimestamp: datetime,
        primaryGcid:
          data.parsedUserData[data.currentActiveData.accountNumber]
            .PRIMARY_GCID,
        userId: data.getUserDataV3.body.USER_ID,
        token: data.authToken,
        loginDate: loggedInTimeDate?.loginDateValue,
        loginTime: istTimestamp,
        loginMethod: data?.setLoginType || null,
        paymentTransactionId: uniqueTransactionId,
        finalAccountNumber: data.currentActiveData.accountNumber,
        finalPaymentOption: "",
        finalPaymentAmt: "",
        creditScoreChecked:
          data.getUserDataV3.body.CREDIT_SCORE === "" ? "N" : "Y",
        finalAdditionalMobile: paymentForm.value.mobile,
        finalAdditionalEmail: paymentForm.value.email,
        logoutTime: "",
        eventDetails: [...paymentOptClickCount],
      };
    }

    eventLoggingDtlsApiCall(temObj, data.authToken);
  };

  useEffect(() => {
    if (dashBoardData && dashBoardData.length === 0 && invalidUserFlag) {
      setFailurePopUpContent({
        apiName: "",
        message: (
          <>
            Sorry, currently this service is not available. Please login to{" "}
            <a
              href="https://retailonline.tatacapital.com/#/login"
              target="_blank"
            >
              retailonline.tatacapital.com
            </a>
          </>
        ),
      });
      setFailurePopUpModal(true);
      setInvalidUserModal(true);
    }
  }, [dashBoardData]);

  const offerModalObj = {
    availOfferModal: AvailOfferModal,
    optingSettlementModal: OptingSettlementModal,
    paymentPlanModal: PaymentPlanOfModal,
    congratsModal: CongratsModal,
    thankYouModal: ThankYouPopup,
  };

  const CurrentOfferModal = offerModalObj[data?.offerModaLFlag?.accountType];

  let title = `Hello ${data?.customerName}, below is the summary of your pending dues`;
  return (
    <div className="c_overview c_main_div dark-bg">
      {failurePopUpModal ? (
        <FailurePopUp
          onClick={() => {
            setFailurePopUpModal(false);
            if (invalidUserModal) {
              navigate("/login");
            }
          }}
          setFailureModalStat={setFailurePopUpModal}
          failurePopUpContent={failurePopUpContent}
        />
      ) : null}
      <>
        <p id="c_overview_heading">
          {title}
          {data?.getUserData}
        </p>
        <div className="c_dashboard_data_payment_flex">
          <div className="c_dashboard_data_comp">
            {dashBoardData.length > 0 ? (
              <DashboardData
                handleClickForPaymentOpt={handleClickForPaymentOpt}
                data={dashBoardData}
                setPreapprovedOfferFlag={setPreapprovedOfferFlag}
                preapprovedOfferFlag={preapprovedOfferFlag}
                attemptFlag={attemptFlag}
                setActiveOffer={setActiveOffer}
                setDashPaymentData={setDashPaymentData}
                setJourneyType={setJourneyType}
                setPaymentSummeryFlag={setPaymentSummeryFlag}
                setAttemptFlag={setAttemptFlag}
                paymentFlag={paymentFlag}
                setPopOverFlag={setPopOverFlag}
                setPayNowFlag={setPayNowFlag}
                validateFlagEmail={validateFlagEmail}
                setValidateFlagEmail={setValidateFlagEmail}
                validateFlagMobile={validateFlagMobile}
                setValidateFlagMobile={setValidateFlagMobile}
                payNowFlag={payNowFlag}
                disableBtn={disableBtn}
                setDisableBtn={setDisableBtn}
              />
            ) : (
              <SkeletonDashboardData />
            )}
          </div>
          <div className="c_dashboard_payment_credit_score_comp">
            <Payment
              preapprovedOfferFlag={preapprovedOfferFlag}
              setPreapprovedOfferFlag={setPreapprovedOfferFlag}
              setFailurePopUpContent={setFailurePopUpContent}
              setFailurePopUpModal={setFailurePopUpModal}
              setPaymentOptClickCount={setPaymentOptClickCount}
              paymentOptClickCount={paymentOptClickCount}
              handleClickForPaymentOpt={handleClickForPaymentOpt}
              popOverFlag={popOverFlag}
              setPopOverFlag={setPopOverFlag}
              payNowFlag={payNowFlag}
              setPayNowFlag={setPayNowFlag}
              ischecked={ischecked}
              setIsChecked={setIsChecked}
              data={dashBoardData}
              attemptFlag={attemptFlag}
              paymentOptions="Payment options"
              additionalDetails="Please provide your additional details:"
              additionalNumber="Additional Phone Number"
              additionalEmailId="Additional Email ID"
              activeOffer={activeOffer}
              dashPaymentData={dashPaymentData}
              successFailureFlag={successFailureFlag}
              setSuccessFailureFlag={setSuccessFailureFlag}
              journeyType={journeyType}
              paymentForm={paymentForm}
              handleForm={handleForm}
              setPaymentFlag={setPaymentFlag}
              setAttemptFlag={setAttemptFlag}
              validateFlagEmail={validateFlagEmail}
              setValidateFlagEmail={setValidateFlagEmail}
              validateFlagMobile={validateFlagMobile}
              setValidateFlagMobile={setValidateFlagMobile}
              setFinalPayOptClickObj={setFinalPayOptClickObj}
              paymentCardArr={paymentCardArr}
              setPaymentCardArr={setPaymentCardArr}
              handleActive={handleActive}
              disableBtn={disableBtn}
              setDisableBtn={setDisableBtn}
              selectedLabel={selectedLabel}
            />
            <div>
              <CreditScore creditScoreText="Enhance your creditworthiness by acquiring a secure credit card. With our credit builder product, you can boost your credit score!" />
            </div>
          </div>
          {data?.offers?.body?.offerDetails.length > 0 &&
            data?.offerModaLFlag.modalFlag && (
              <CurrentOfferModal
                handleClickForPaymentOpt={handleClickForPaymentOpt}
                setPreapprovedOfferFlag={setPreapprovedOfferFlag}
                setPopOverFlag={setPopOverFlag}
                setPayNowFlag={setPayNowFlag}
                setIsChecked={setIsChecked}
                setAttemptFlag={setAttemptFlag}
                successFailureFlag={successFailureFlag}
                paymentSummeryFlag={paymentSummeryFlag}
                setPaymentSummeryFlag={setPaymentSummeryFlag}
                setSuccessFailureFlag={setSuccessFailureFlag}
                setFailurePopUpContent={setFailurePopUpContent}
                setFailurePopUpModal={setFailurePopUpModal}
                setInvalidUserModal={setInvalidUserModal}
                paymentForm={paymentForm}
                journeyType={journeyType}
                setJourneyType={setJourneyType}
                isTrancheOpted={isTrancheOpted}
                setIsTrancheOpted={setIsTrancheOpted}
                noOfTranchesOpted={noOfTranchesOpted}
                setNoOfTranchesOpted={setNoOfTranchesOpted}
                paymentModalDetails={paymentModalDetails}
                setPaymentModalDetails={setPaymentModalDetails}
                trancheData={trancheData}
                setTrancheData={setTrancheData}
                setPaymentFlag={setPaymentFlag}
                dashPaymentData={dashPaymentData}
                setFinalPayOptClickObj={setFinalPayOptClickObj}
              />
            )}
        </div>
      </>
    </div>
  );
};

Overview.propTypes = {
  data: PropTypes.string,
};

export default Overview;
