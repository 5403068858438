import React from 'react'
import './userIdGeneration.scss'
import Button from '../../FormFields/Button/Button';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';

const UserIdGeneration = ({ 
  setLoginTab, 
  setCounter, 
  newId 
}) => {
  const handleCopy = ()=>{
    navigator.clipboard.writeText(newId)
    toast("User ID Copied!", {
      position: "bottom-center",
      className: 'toast-bar'
  })
  }


  return (
    <div className='c_userid_generate_wrap'>
      <p className='c_userid_generate_title'>Your User ID</p>
      <p className='c_userid_generate_desc'>Here’s your User ID</p>
      <h2 className='c_userid_generate_id'>{newId}</h2>
      <div className="copy-userid">
        <p onClick={handleCopy}> <img className="c_arrow-right" src="assets/images/file_copy.svg" alt="file_copy" /> Copy your User ID</p>
      </div>
      <div className="c_userid_generate_btn_wrp">
      <ToastContainer />
        <Button className="login" onClick={() => { setLoginTab(() => "userId"); setCounter(0);}}>
          Back to Log In
          <img
            className="c_arrow-right"
            src="assets/images/right-arrow-white.svg"
            alt=""
          />
        </Button>
      </div>
    </div>
  )
}

UserIdGeneration.propTypes={
  setLoginTab : PropTypes.func,
  setCounter : PropTypes.func,
  newId:PropTypes.number
}

export default UserIdGeneration