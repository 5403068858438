import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import userSessionAlive from "../../apis/user-session-alive/user-session-alive";
import { RESET_CONTEXT } from "../../../../Shared/context/data-mapping/action/action.type";
import DataMapping from "../../../../Shared/context/data-mapping/data-mapping";

const useSessionAlive = () => {
    const navigate = useNavigate();
    const [, dispatch] = useContext(DataMapping)

    const isUserSessionAlive = async (authToken) => {
        try {
            const userSessionResponse = await userSessionAlive(authToken)
            return userSessionResponse
        }
        catch (err) {
            if (err.response?.status === 401) {
                sessionStorage.clear();
                dispatch({ type: RESET_CONTEXT })
                navigate("/login");
            }
        }

    }

    return { isUserSessionAlive }
}


export default useSessionAlive