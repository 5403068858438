import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const AcceptElockerTc = (data) =>{
    let requestJson = {
        "header": {
            "authToken": ""
          },
          "body": {
            "data": [
              {
                "column_name": "eLocker_TnC",
                "column_value": "Y"
              }
            ],
            "condition": {
              "column_name": "GCID",
              "column_value": ""
            }
          }
           
    }
    return new Promise((resolve, reject) => {
      stalwartApiCall('POST',data,'/shaft/api/db/update-user-info/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default AcceptElockerTc;