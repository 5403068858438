export const checkUserValid = (userDataResponse) => {
    const { otherSystemDetails , contracts} = userDataResponse.body;
    let valid = false;
    otherSystemDetails.map((system)=>{
        if (system.SYSTEM_NAME === "Ccod" ) {
            console.log('using CCOD');
            valid = true;
        }
    })
    if (!valid && contracts.length > 0) {
        console.log('using retail');
        valid = true;
    }
    return valid;
}