import React from "react";
import "./ThankYouPopup.scss";
import Modal from "../../components/Modal";
import { useContext } from "react";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import LoginServiceObject from "../../../../Retail/src/services/Login";
import { useEffect } from "react";
import Button from "../../FormFields/Button/Button";
import commonFunctionObject from "../../../../Retail/src/common-function";

export const ThankYouPopup = ({
  setPopOverFlag,
  setPayNowFlag,
  setIsChecked,
}) => {
  const [contextData, dispatchData] = useContext(DataMapping);

  const getOfferUpdatedDetails = (updatedData) => {
    dispatchData({
      type: STOREDATA,
      name: "offers",
      payload: updatedData,
    });

    dispatchData({
      name: "offerModaLFlag",
      payload: {
        modalFlag: false,
        accountType: "thankYouModal",
      },
      type: STOREDATA,
    });
  };

  const okClick = (loanAccNumber, authToken) => {
    let loanNumber = loanAccNumber.split();
    let getOfferData = LoginServiceObject.offerCollectionDetails(
      authToken,
      loanNumber
    ).then((offerDataResponse) => {
      getOfferUpdatedDetails(offerDataResponse);
      return offerDataResponse;
    });
  };

  return (
    <Modal>
      <div className="c_thankyou_wrap">
        <img
          className="c_thankyou_close"
          src="assets/images/gray-cross.svg"
          alt="gray-cross"
          onClick={() => {
            // handleCrossClick("paymentPlanModal", false);
            // handleOption("paymentPlanModal", true, true);
          }}
        />
        {/* {modalType == "failure" && (
               <h2 className="c_thankyou_title c_failure_msg_bold">
                 Transaction Unsuccessful!
               </h2>
             )} */}
        <img
          className="c_thankyou_icon"
          src={`assets/gifs/success.gif`}
          alt="green-tick"
        />
        <p className="c_thankyou_title">
          Thank you for making payment of ₹ 1,00,000/- towards your loan
          account, Amount will reflect in statement in 2 working days. Please
          make timely payment to avoid any additional charge getting levied and
          maintain good credit score.
        </p>
        <div className="c_congrats_modal_btn_wrapper">
          <Button
            type="submit"
            className="c_congrats_modal_btn"
            onClick={okClick(
              contextData.currentActiveData.accountNumber,
              contextData.authToken
            )}
          >
            Ok
            <img src="assets/images/vector.svg" alt="vector" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
