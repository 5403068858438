import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

export const saveDisbursementInfo = (data) => {

    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "loanNo": "3056",
            "bankName": "BANK OF MAHARASHTRA - 200755309850",
            "ifsc": "MAHB0000392",
            "bankAccountNo": "20075530985",
            "amount": "2003.45",
            "netAmount": "2003.45"
        }
    }

    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/las-esb/save-disbursement-info/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-esb/save-disbursement-info/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}