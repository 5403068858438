import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import apiConfigObj from '../../app-config/app-config';
import commonFunctionObject from '../../common-function';
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from '../../../../Shared/context/ThemeContext/store/ThemeContext';
import RadiantBorder from '../../global/RadiantBorder';
import CustomerServiceObject from '../../services/CustomerService';
import { useNavigate } from "react-router-dom"
import PropTypes from 'prop-types'



//styles
import './Accordion-style.scss'
// import pageNameContext from '../../context/ThemeContext/store/PageNameContext';
import pageNameContext from '../../../../Shared/context/ThemeContext/store/PageNameContext';
import { STOREDATA } from '../../../../Shared/context/data-mapping/action/action.type';
import DataMapping from '../../../../Shared/context/data-mapping';
import jsHelper from '../../utilities/jsHelper';

const Accordion = ({ faqContent, accordionTitle, serviceRequestObjsCategory, open, toggleOpen, id, arrayOptions, accordionType, className, serviceRequestOption, setScreenState, setSrPopUp, currentContract, systemFlag, querySwitchCases }) => {
  const [themeState] = useContext(ThemeContext);
  const [accordioncontent, setAccordionContent] = useState(false);
  const [subAccCount, setSubAccCount] = useState(0);
  const [data, dispatchData] = useContext(DataMapping)
  const accordionRef = useRef()

  useEffect(() => {
    console.log(subAccCount);
  }, [subAccCount])

  useEffect(() => {
    const onBodyClick = (e) => {
      if (!accordionRef.current?.contains(e.target)) {
        // setAccordionContent(false)
        toggleOpen(open !== id ? null : id)
      }
    }
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    }
  }, [])

  const subAccordionClick = (form) => {
    setScreenState(serviceRequestOption[form].tabName)
    // if (serviceRequestOption[form]?.popUpId) {
    //   setSrPopUp(serviceRequestOption[form].popUpId)
    // }
    if (serviceRequestOption[form].type.toLowerCase() === "query") {
      querySwitchCases(serviceRequestOption[form]["sub-src"])
    } else if (serviceRequestOption[form].type.toLowerCase() === "faq") {
      if (apiConfigObj.appConfig.env === "DEVELOPMENT") {
        window.open("https://tclu.tatacapital.com/contact-us/retail-service-faqs.html#" + serviceRequestOption[form].id, '_blank');
      } else {
        window.open("https://tatacapital.com/contact-us/retail-service-faqs.html#" + serviceRequestOption[form].id, '_blank');
      }
    }

    dispatchData({
      name: "digiDocData",
      payload: null,
      type: STOREDATA
    })

  }

  const renderFaq = (value) => {
    switch (value.title.toLowerCase()) {
      case "what are the list of services covered under service request?":
      case "i updated my pan details but same is not visible":
      case "i updated my date of birth but same is not visible":
        return <>
          <div className='faqContentPara'>
            {jsHelper.isDef(value.paragraph) ? <p className='faqListPara'>{value.paragraph}</p> : <></>}
            <ol>
              {
                value.numberList.map((data, index) => <li key={index} className='faqlistContent'>{data}</li>)
              }
            </ol>
          </div>
        </>
      case "how to change my mobile number?":
      case "can i foreclose my loan account?":
      case "how can i change my email id?":
        return <>
          <div className='faqContentPara'>
            <p>{value.paragraph}</p>
            <p>{value.paragraph1}</p>
          </div>
        </>
      case "i am not able to upload/download any document":
        return <>
          <p className='faqListPara'>
            Please check your file type and size of the file. File uploaded can be of max 1 MB per file and format accepted are jpg,jpeg, gif, png, bmp, doc, docx, xls, xlsx, pdf. If issue still persists please write to us at <a href='mailto:customercare@tatacapital.com'>customercare@tatacapital.com</a> along with error screenshot.
          </p>
        </>
      case "can i change/update my kyc details? if yes, how?":
        return <>
          <div className='faqContentPara'>
            <p>{value.paragraph}</p>
            <p>{value.paragraph1}</p>
            <p>{value.paragraph2}</p>
          </div>
        </>
      case "are there any foreclosure charges?":
      case "how and when can i make part pre-payment?":
        return <>
          <div className='faqContentPara'>
            <p>{value.paragraph}</p>
            <p>{value.paragraph1}</p>
          </div>
          {/* {jsHelper.isDef(value.paragraph) ? <p className='faqListPara'>{value.paragraph}</p> : <></>} */}
          <ul>
            {
              value.linkList.map((data, index) => <li key={index} className='faqlistContent'>{data.foreclosureChargesLoan} <a target="_blank" href={data.clickhereLink}> click here</a></li>)
            }
          </ul>
        </>
      case "how do i unsubscribe from tata capital’s credit score?":
        return <>
          <p className='faqListPara'>
            If you wish to unsubscribe then please email from your registered email ID - <a href='mailto:customercare@tatacapital.com'>customercare@tatacapital.com</a>
          </p>
        </>
      default:
        return <p className='faqContentPara'>{value.paragraph}</p>
    }
  }


  const render = () => {

    return <div ref={accordionRef} style={{}} className={`accordion-section ${className ? className : ""}`} onClick={(event) => {
      event.stopPropagation()
      setAccordionContent(!accordioncontent);
      toggleOpen(open === id ? null : id)
    }}>
      <div className={`accordion-container ${accordionType === "Servicerequest" ? "accordion-serviceRequest" : ""}`}>
        {
          accordionType === "Servicerequest" ? <p className={`accordion-title ${accordionType === "FAQ" ? "faq-border-bottom" : ""}`}>{serviceRequestObjsCategory}</p> :
            <p className={`accordion-title ${accordionType === "FAQ" ? "faq-border-bottom" : ""}`}>{accordionTitle}</p>
        }

        <img style={
          themeState === "THEME_DARK"
            ? { filter: "brightness(0) invert(1)" }
            : null
        }
          className={'accordion-arrow ' + `${open == id ? "accordion-arrow-invert" : ""}`} src="assets/images/arrow-down.svg" alt='down-arrow' />
      </div>
      {
        accordionType === "FAQ" ? <div className={"accordion-content " + `${open == id ? "accordion-content-active faq-border" : ""}`}>


          {
            renderFaq(faqContent)
          }

          {/* {
            arrayOptions?.length > 1 ? <ol className='accordion-content-list'>
              <p className='accordion-content-para'>Currently we offer below list of services:</p>
              {
                arrayOptions?.map((obj, index) => <li key={index}>{obj.value}</li>)
              }

            </ol> : arrayOptions.map((obj, index) => <p className='accordion-content-list' key={index}>{obj.value}</p>)
          } */}

        </div> :
          <div className={"accordion-content " + `${open == id ? "accordion-content-active" : ""}`} style={{ padding: "0px" }}>
            <div className='sr-accordion-content'>
              <ul className='accordion-content-list sr-accordion-list'>
                {
                  Object.keys(serviceRequestOption).map((value, index) => {
                    let system = "ccod"
                    var systemName = systemFlag
                    var productFamily = commonFunctionObject.getProductFamily(currentContract)
                    var contractStatusCheck = data?.getLoanPositionDTLS ? data?.getLoanPositionDTLS[data?.currentActiveData.accountNumber] ? commonFunctionObject.checkActiveStatus(data?.getLoanPositionDTLS[data?.currentActiveData.accountNumber]) : "active" : "active";
                    var productCheck = [];
                    var productName
                    if (currentContract.cdiContract) {
                      productName = ""
                      productCheck = ["ccod"]
                    } else {
                      productName = commonFunctionObject.getProductName(currentContract);
                      productCheck = Object.keys(serviceRequestOption).filter(function (srName) {
                        return jsHelper.arrIncludes(serviceRequestOption[value].product, productName.toLowerCase()) || serviceRequestOption[value].product.length == 0;
                      });
                    }

                    if (serviceRequestOption[value].system.includes(systemName) && serviceRequestOption[value].product_family.includes(productFamily) && productCheck.length > 0 && serviceRequestOption[value]["contract-status"].includes(contractStatusCheck)) {
                      return <li className='main-list' key={index} ><a className='sr-link' onClick={() => subAccordionClick(value)}>{value} </a></li>

                    }

                  })
                }
              </ul>

              {/* <ul className='accordion-content-list sr-accordion-list'>
                <li className='main-list'>
                  <a className='sr-link'>
                    Unpaid charges applied on my loan account
                  </a>
                </li>
                <li className='main-list'>
                  <a className='sr-link'>
                    Unpaid charges applied on my loan account
                  </a>
                </li>
                <li className='main-list'>
                  <a className='sr-link'>
                    Unpaid charges applied on my loan account
                  </a>
                </li>
                <li className='main-list'>
                  <a className='sr-link'>
                    Unpaid charges applied on my loan account
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
      }



    </div >
  }


  return (
    // // open == id ? (
    // //   <RadiantBorder className="list-sr-border">
    // //     {render()}
    // //   </RadiantBorder>
    // // ) :
    //   render()\

    <RadiantBorder className={`list-sr-border ${open !== id ? "hide-border" : ""}`}>
      {render()}
    </RadiantBorder>
  )
}

Accordion.propTypes = {
  faqContent: PropTypes.object,
  accordionTitle: PropTypes.string,
  serviceRequestObjsCategory: PropTypes.string,
  open: PropTypes.number,
  toggleOpen: PropTypes.func,
  id: PropTypes.number,
  accordionType: PropTypes.string,
  className: PropTypes.string,
  serviceRequestOption: PropTypes.object, setScreenState: PropTypes.func,
  setSrPopUp: PropTypes.func,
  currentContract: PropTypes.object,
  systemFlag: PropTypes.string,
  querySwitchCases: PropTypes.func
}

export default Accordion;