import React, { useState } from "react";
import Button from "../../global/Button";
import "./DepledgingPopup.scss";
import Modal from "../../global/Modal";

import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_DARK } from "../../context/ThemeContext/actions";
import { THEME_DARK } from "../../../../Shared/context/ThemeContext/actions";
import { useEffect } from "react";
import DataMapping from "../../../../Shared/context/data-mapping";
import LasAccountDetailsServiceObject from "../../services/LasAccountDetailsService";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
import commonFunctionObject from "../../common-function";
import AlertPopUp from "../../global/AlertPopUp";
import OkButtonPopUp from "../../global/OkButtonPopUp";
import PropTypes from 'prop-types';


const DepledgingPopup = ({ activeContract, currentHoldingTableArray, setModalState, setFreezeFlag, setFreezeTime, setFunctionalityFreezeFlag, setSuccessPopUpModalLasFlag, setSuccessPopUpContentLas, setFailurePopUpModalLasFlag, setFailurePopUpContentLas }) => {
    const [selectedDataArray, setSelectedDataArray] = useState([])
    const [data, dispatchData] = useContext(DataMapping)
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    })
    const [alertPopUpModal, setAlertPopUpModal] = useState(false);
    const [alertPopUpContent, setAlertPopUpContent] = useState({
        "title": "",
        "message": ""
    })
    const [successPopUpModal, setSuccessPopUpModal] = useState(false);
    const [successPopUpContent, setSuccessPopUpContent] = useState("");
    const [okButtonPopUpFlag, setOkButtonPopUpFlag] = useState(false)
    const [okButtonPopUpContent, setOkButtonPopUpContent] = useState("")
    const [themeState, dispatch, otpState, otpDispatch] =
        useContext(ThemeContext);
    //   const [depledgingModalState, setDepledgingModalState] = useState(false);
    const [depledgeBlock, setDepledgeBlock] = useState(false)
    const [depledgeBlockContent, setDepledgeBlockContent] = useState("")
    const sortSelectedHoldings = () => {
        var filteredHoldingsData = currentHoldingTableArray.filter((element) => {
            return element.checkedFlag === true;
        })

        setSelectedDataArray(filteredHoldingsData)
    }

    const setDepledgeAmountValidation = (event, key) => {
        const depledgeAmount = event.target.value
        selectedDataArray[key].depledgeAmount = depledgeAmount
        setSelectedDataArray(selectedDataArray)
    }

    const onDepledgeSubmit = () => {
        if (validation()) {
            saveCollaterAlReleaseValidations()
        } else {
        }
    }

    const reduceLtv = (amount, percentToReduce) => {
        return (percentToReduce / 100) * amount;
    }

    const validation = () => {
        var count = 0
        selectedDataArray.forEach((element, index) => {
            if (element.depledgeAmount == "" || isNaN(element.depledgeAmount) || element.depledgeAmount <= 0) {

                setSelectedDataArray((oldData) => {
                    let newData = [...oldData]
                    newData[index].depledgeErrorMsg = "Please Enter Depledge Quantity"

                    return newData
                })
                count += 1
            } else if (parseFloat(element.depledgeAmount) > parseFloat(element.PledgeQty)) {
                setSelectedDataArray((oldData) => {
                    let newData = [...oldData]
                    newData[index].depledgeErrorMsg = "Depledge Quantity Should Not be Greater Than Allowed Depledge Quantity"

                    return newData
                })
                count += 1
            } else {
                setSelectedDataArray((oldData) => {
                    let newData = [...oldData]
                    newData[index].depledgeErrorMsg = ""

                    return newData
                })
            }
        })
        if (count > 0) {
            return false
        } else {
            return true
        }
    }

    const saveCollaterAlReleaseValidations = () => {
        var qtyPlusMarketValue = 0
        if (data?.["checkEquityFlag"]?.[activeContract] === false) {
            selectedDataArray.forEach((value) => {
                qtyPlusMarketValue += reduceLtv(value.MarketRate * value.depledgeAmount, value.LTV);
            })
            var getDrawingPowerValue = data?.["summary"]?.[activeContract][0].LoanAccount[0].drawingPower;
            var totalOutStanding = data?.["summary"]?.[activeContract][0].LoanAccount[0].TotalOutstanding;
            var powerValueMinusMarketValue = (parseFloat(getDrawingPowerValue) - parseFloat(totalOutStanding)) - qtyPlusMarketValue;
            if (powerValueMinusMarketValue >= 0) {
                dispatchData({
                    name: "loaderFlag",
                    type: STOREDATA,
                    payload: true
                })

                var collateralBody = [];
                selectedDataArray.forEach(function (value, index) {
                    collateralBody.push({
                        pledgeType: 'lien',
                        loanAccount: data?.["summary"]?.[activeContract][0].LoanAccount[0].Account_Number,
                        isin: value.ISIN,
                        amfi: value.AMFI,
                        orderNo: value.FolioNumber,
                        pledgeeDpid: '',
                        pledgeeClientId: '',
                        releaseQuantity: value.depledgeAmount,
                    });
                });

                var saveCollateralReleaseRequestEquity = {
                    header: {
                        authToken: data.authToken,
                    },
                    body: {
                        depledgingDetails: collateralBody,
                    },
                }
                callSaveCollateralAPI(saveCollateralReleaseRequestEquity)
            } else {
                setAlertPopUpContent({
                    title: "Action Not Allowed",
                    message: "Unlien lesser units as sufficient cover is not available",
                });
                setAlertPopUpModal(true)
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }
        } else {
            var quantityObj = {};
            var eqQuantitiesArr = [];
            var releaseArr = []
            selectedDataArray.forEach(function (value, index) {
                quantityObj[value.ISIN] = value.depledgeAmount;
                releaseArr.push(value.depledgeAmount);
            });
            selectedDataArray.forEach(function (value, index) {
                if (quantityObj?.[value.ISIN]) {
                    var index = eqQuantitiesArr.map(function (element, index) {
                        return element.ISIN === value.ISIN ? index : "";
                    }).filter(function (element) {
                        return element !== '';
                    })[0];
                    eqQuantitiesArr[index] = quantityObj;
                } else {
                    quantityObj[value.ISIN] = Number(value.depledgeAmount);
                    eqQuantitiesArr.push(value.depledgeAmount);
                }
                qtyPlusMarketValue += reduceLtv(value.MarketRate * value.depledgeAmount, value.LTV);
            });

            var breRequestArray = LasAccountDetailsServiceObject.createBreRequestListArray(quantityObj, data?.["pledgeDetailsFoliowise"]?.[activeContract], data?.["summary"]?.[activeContract]);
            var filterBreRequestArr = LasAccountDetailsServiceObject.filterBreRequestArrFn(breRequestArray);
            let pledgingBreResultRequest = {
                header: {
                    authToken: data.authToken,
                },
                body: {
                    breRequestList: filterBreRequestArr,
                    clientId: data?.["summary"]?.[activeContract][0].CustomerID,
                    gcid: data?.["summary"]?.[activeContract][0].gcid,
                    sanctionLimit: data?.["summary"]?.[activeContract][0].LoanAccount[0].sanctionedamount,
                    totalOutstanding: data?.["summary"]?.[activeContract][0].LoanAccount[0].TotalOutstanding
                },
            }
            dispatchData({
                name: "loaderFlag",
                type: STOREDATA,
                payload: true
            })
            LasAccountDetailsServiceObject.pledgingBreResultService(pledgingBreResultRequest).then((breResponse) => {
                if (breResponse["isDepledgeAllowed"] == 1) {
                    var collateralBodyArr = []
                    selectedDataArray.forEach(function (value, index) {
                        collateralBodyArr.push({
                            pledgeType: 'demat',
                            loanAccount: data?.["summary"]?.[activeContract][0].LoanAccount[0].Account_Number,
                            isin: value.ISIN,
                            amfi: '', // empty for equity
                            orderNo: value.PledgeOrderNumber,
                            pledgeeDpid: value.PledgeeDPId,
                            pledgeeClientId: value.PledgeeClientId,
                            releaseQuantity: releaseArr[index],
                        });
                    });

                    var saveCollateralReleaseRequestEquity = {
                        header: {
                            authToken: data.authToken,
                        },
                        body: {
                            depledgingDetails: collateralBodyArr,
                        },
                    }
                    callSaveCollateralAPI(saveCollateralReleaseRequestEquity);
                } else if (breResponse["isDepledgeAllowed"] == 0) {

                    let message = "Amount required to de-pledge shares as per internal policy of TCFSL is Rs." + breResponse["eligbileDrawingPower"]
                    setDepledgeBlockContent(message)
                    setDepledgeBlock(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                }
            }).catch((error) => {
                if (typeof (error) === "object") {
                    setOkButtonPopUpContent(error.message)
                    setOkButtonPopUpFlag(true)
                } else {
                    setFailurePopUpContentLas({
                        "apiName": "",
                        "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        "errorCode": error
                    })
                    setFailurePopUpModalLasFlag(true)
                }
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            })
        }
    }


    const callSaveCollateralAPI = (saveCollateralRequest) => {
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        LasAccountDetailsServiceObject.saveCollateralApiService(saveCollateralRequest).then((saveCollateralReleaseResponse) => {
            setFreezeFlag(true)
            setFreezeTime(parseFloat(600000 / 1000).toString())
            setFunctionalityFreezeFlag(true)
            setSuccessPopUpContentLas("Your depledging request has been raised successfully!")
            setSuccessPopUpModalLasFlag(true)
            setModalState(false)
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
        }).catch((error) => {
            setFailurePopUpContentLas({
                "apiName": "",
                "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                "errorCode": error
            })
            setFailurePopUpModalLasFlag(true)
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
        })
    }


    const isDebarred = () => {
        if (data?.["parsedUserData"]?.["lasAccounts"]) {
            if (data?.["parsedUserData"]?.["lasAccounts"].isDebarredCustomer.toUpperCase() === "Y") {
                return true
            } else {
                return false
            }
        }
    }

    useEffect(() => {
        sortSelectedHoldings()
    }, [])

    return (
        <div>
            {alertPopUpModal ? <AlertPopUp
                onClick={() => setAlertPopUpModal(false)}
                visible={alertPopUpModal}
                setAlertModalStat={setAlertPopUpModal}
                alertPopUpContent={alertPopUpContent}
            /> : null}
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {successPopUpModal ? <SuccessPopUp
                onClick={() => setSuccessPopUpModal(false)}
                visible={successPopUpModal}
                setSuccessModalStat={setSuccessPopUpModal}
                successPopUpContent={successPopUpContent}
            /> : null}
            {okButtonPopUpFlag ? <OkButtonPopUp
                onClick={() => setOkButtonPopUpFlag(false)}
                visible={okButtonPopUpFlag}
                setModalStat={setOkButtonPopUpFlag}
                Content={okButtonPopUpContent} /> : null}
            <Modal>
                <div
                    className="depledging_ModalMainDiv"
                    style={{
                        background: `${themeState === THEME_DARK ? "#23242E" : "#fff"}`,
                        color: `${themeState === THEME_DARK ? "#fff" : "#000"}`,
                    }}
                >
                    <div className="depledging_Heading_Div">
                        <p className="depledging_ModalHeadText">Depledging</p>
                        <div className="depledging_CancelImgDiv">
                            <img
                                src="assets/images/cancel.svg"
                                className="depledging_CancelImg"
                                onClick={() => {
                                    setModalState(false);
                                }}
                            />
                        </div>
                    </div>
                    <div className="depledging-accodion-scroll">
                        <div className="depledging_Main_Wrap_Div">
                            <div className="depledging_wrap_Div" style={{ background: `${themeState === THEME_DARK ? "#23242E" : "#fff"}`, }}>
                                <div className="depledging_Left_Div">
                                    <div>
                                        <p className="depledging_ModalText_Head_Bold">ISIN</p>
                                    </div>
                                </div>

                                <div className="depledging_Second_Div">
                                    <div>
                                        <p className="depledging_ModalText_Head">
                                            Scrip/Scheme Name
                                        </p>
                                    </div>
                                </div>
                                <div className="depledging_Third_Div">
                                    <div>
                                        <p className="depledging_ModalText_Head">
                                            Pledged Quantity
                                        </p>
                                    </div>
                                </div>
                                <div className="depledging_Right_Div">
                                    <div>
                                        <p className="depledging_ModalText_Head">
                                            Depledged Quantity
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {selectedDataArray.map((data, key) => {
                                return (
                                    <div key={key}>
                                        <div className="depledging_Wrap_Value_Div">
                                            <div className="depledging_Left_Div">
                                                <div className="depledging_Text_Wrap_Div">
                                                    <p className="depledging_ModalText_Bold">
                                                        {data.ISIN}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="depledging_Second_Div">
                                                <div>
                                                    <p className="depledging_ModalText">
                                                        {data.ScripName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="depledging_Third_Div">
                                                <div>
                                                    <p className="depledging_ModalText">
                                                        {data.PledgeQty}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="depledging_Right_Div" key={key}>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="enter_Quantity_Input"
                                                        placeholder={data.depledgedQuantity}
                                                        style={{
                                                            backgroundColor:
                                                                themeState === THEME_DARK ? "#2e2f36" : "#ecf0f5",
                                                        }}
                                                        onChange={(event) => {
                                                            setDepledgeAmountValidation(event, key)
                                                        }}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                    />
                                                </div>
                                                <div className="depledgeAmountError">
                                                    {console.log(data.depledgeErrorMsg)}
                                                    <p>{data.depledgeErrorMsg}</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                );
                            })}
                        </div>

                        <div className="depledging_Confirm_BtnDiv">
                            <Button className="confirm_Btn" onClick={() => { onDepledgeSubmit() }}>
                                <p className="confirm_Btn_Text">Confirm</p>
                                <img
                                    src="assets/images/arrow-right.svg"
                                    className="confirm_Arrow_Right"
                                />
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            {depledgeBlock ?
                <Modal>
                    <div className='depledgeBlock-modal-popup'>
                        <div className='close-btn' onClick={() => setDepledgeBlock(false)}>
                            {/* <img src={"assets/images/crossImg.svg"} alt="close" /> */}
                        </div>
                        <div className='description'>
                            <p>
                                {depledgeBlockContent}
                            </p>
                        </div>
                        <div className='proceed' onClick={() => { setDepledgeBlock(false) }}>
                            <a href='javascript:void(0)'>OK</a>
                        </div>
                    </div>
                </Modal> : null}
        </div>
    );
};

DepledgingPopup.propTypes = { 
    activeContract : PropTypes.string, 
    currentHoldingTableArray : PropTypes.array,
    setModalState : PropTypes.func,
    setSuccessPopUpModalLasFlag : PropTypes.func,
    setSuccessPopUpContentLas : PropTypes.func,
    setFailurePopUpModalLasFlag : PropTypes.func,
    setFailurePopUpContentLas : PropTypes.func,
    setFreezeFlag : PropTypes.func,
    setFreezeTime : PropTypes.func,
    setFunctionalityFreezeFlag : PropTypes.func
  };

export default DepledgingPopup;
