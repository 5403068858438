import { useRef, useState } from "react";
import "./PostLoginFooter.scss";
import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import getData from "./PostLoginFooterData";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { basePath } from "../../constants/constantPath";
import { useNavigate } from "react-router-dom";
import ToolsAndCalcModal from "../ToolsAndCalcModal";
import DataMapping from "../../../../Shared/context/data-mapping";
import OverviewServiceObject from "../../services/Overview";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
import FailurePopUp from "../../global/FailurePopUp";
import SuccessPopUp from "../../global/SuccessPopUp";
import { theme } from "antd";
import Logout from "../Logout/Logout";
// import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import commonFunctionObject from "../../common-function";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../../../Shared/context/ThemeContext/store/PageNameContext";
import WhatsAppRegisterModal from "../WhatsAppRegisterModal";
import WhatsappServiceSubscribe from "../WhatsappServiceSubscribe";
import Iframe from '../IframeBBPS';


const footerArray = {
  retail: [
    {
      alt: "home",
      img: "assets/images/home-icon.svg",
    },
    {
      alt: "search",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "customer-service",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "document-download",
      img: "assets/images/home-icon.svg",
    },
    {
      alt: "bill-pay",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "credit-score",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "more-options",
      img: "assets/images/home-icon.svg"
    },

  ],
  ccod: [
    {
      alt: "home",
      img: "assets/images/home-icon.svg",
    },
    {
      alt: "search",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "customer-service",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "document-download",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "bill-pay",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "credit-score",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "more-options",
      img: "assets/images/home-icon.svg"
    },

  ],
  las: [
    {
      alt: "home",
      img: "assets/images/home-icon.svg",
    },
    {
      alt: "search",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "document-download",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "bill-pay",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "credit-score",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "more-options",
      img: "assets/images/home-icon.svg"
    },

  ],
  wealth: [
    {
      alt: "home",
      img: "assets/images/home-icon.svg",
    },
    {
      alt: "risk-profiling",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "manage-folio",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "customer-service",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "cart",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "search",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "moreoptions",
      img: "assets/images/home-icon.svg"
    },

  ]
}

const menuArcArray = {
  retail: [
    {
      alt: "Closed Accounts",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "Tools & Calculators",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "e-locker",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "whatsapp",
      img: "assets/images/home-icon.svg"
    }
  ],
  ccod: [
    {
      alt: "closed-accounts",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "Tools & Calculators",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "e-locker",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "whatsapp",
      img: "assets/images/home-icon.svg"
    }
  ],
  las: [
    {
      alt: "closed-accounts",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "Tools & Calculators",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "e-locker",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "whatsapp",
      img: "assets/images/home-icon.svg"
    }
  ],
  wealth: [
    {
      alt: "download-app",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "last-5-transactions",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "order-status",
      img: "assets/images/home-icon.svg"
    },
    {
      alt: "whatsapp",
      img: "assets/images/home-icon.svg"
    }
  ],
}

const collection = [
  {
    alt: "home",
    img: "assets/images/home-icon.svg",
  },
  {
    alt: "document-download",
    img: "assets/images/home-icon.svg",
  }

]

const PostLoginFooter = ({ portalType }) => {
  const navigate = useNavigate();
  const [themeState] = useContext(ThemeContext);
  const [openOptions, setOpenOptions] = useState(false);
  const [gridPopupMesage, setGridPopupfooterRefMessage] = useState(true);
  const gridPopupPhone = useRef();
  const [gridMessage, setGridMessage] = useState("");
  const [calculatorModal, setCalculatorModal] = useState(false);
  const [contextData, dispatchData] = useContext(DataMapping);
  const groupEmployeeRef = useRef();
  const { analytics } = useContext(AnalyticsContextProvider);
  const [whatsAppPopUp, setWhatsAppPopUp] = useState(false);
  const [whatsAppSuccessPopUp, setWhatsAppSuccessPopUp] = useState(false);
  const { setCustomerServicePageName, setPageName } = useContext(pageNameContext);
  // FOR DYNAMIC FOOTER ELEMENTS
  const [channelOpt, setChannelOpt] = useState("retail");
  var addingHeight = -80
  const width = window.innerWidth
  const [channelOptCon, setChannelOptCon] = useState({
    main: [],
    arc: [],
    ids: [],
    phoneFooter: [],
    phoneRow: [],
  });
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  const [footerIconArray, setFooterIconsArray] = useState(footerArray)
  const [collectionIcon, setCollectionIcon] = useState(collection);
  const [menuArray, setMenuArray] = useState(menuArcArray)
  const [mobileArray, setMobileArray] = useState({
    retail: [],
    ccod: [],
    las: [],
    wealth: []
  })

  const [mobileDropDownArrayObject, setMobileDropDownArrayObject] = useState({})

  const [successPopUpModal, setSuccessPopUpModal] = useState(false);
  const [successPopUpContent, setSuccessPopUpContent] = useState("");
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });

  useEffect(() => {
    var newFooterIconObject = {
      retail: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg",
          id: "home",
          portalType: 'collection',
        },
        // {
        //   alt: "Search",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/search-dark-icon.svg" : "assets/images/light-icon/search-light-icon.svg",
        //   id:"search"

        // },
        {
          alt: "Self Service",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/customer-service-dark-icon.svg" : "assets/images/light-icon/customer-service-light-icon.svg",
          id: "self-service"
        },
        {
          alt: "Your Documents",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/document-center-dark-icon.svg" : "assets/images/light-icon/document-center-light-icon.svg",
          id: "document",
          portalType: 'collection',
        },
        {
          alt: "Bill Pay",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-pay-dark-icon.svg" : "assets/images/light-icon/bill-pay-light-icon.svg",
          id: "bill-pay"

        },
        {
          alt: "Credit Score",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg"
        },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/menu-options-dark-icon.svg" : "assets/images/light-icon/menu-options-light-icon.svg",
          id: "more-options"

        },

      ],
      ccod: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg",
          id: "home",
          portalType: 'collection',
        },
        // {
        //   alt: "Search",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/search-dark-icon.svg" : "assets/images/light-icon/search-light-icon.svg"
        // },
        {
          alt: "Self Service",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/customer-service-dark-icon.svg" : "assets/images/light-icon/customer-service-light-icon.svg",
          id: "self-service"
        },
        {
          alt: "Your Documents",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/document-center-dark-icon.svg" : "assets/images/light-icon/document-center-light-icon.svg",
          id: "document",
          portalType: 'collection',
        },
        {
          alt: "Bill Pay",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-pay-dark-icon.svg" : "assets/images/light-icon/bill-pay-light-icon.svg",
          id: "bill-pay"
        },
        {
          alt: "Credit Score",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg"
        },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/menu-options-dark-icon.svg" : "assets/images/light-icon/menu-options-light-icon.svg",
          id: "more-options"
        },

      ],
      las: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg"
        },
        // {
        //   alt: "Search",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/search-dark-icon.svg" : "assets/images/light-icon/search-light-icon.svg"
        // },
        {
          alt: "Bill Pay",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/bill-pay-dark-icon.svg" : "assets/images/light-icon/bill-pay-light-icon.svg"
        },
        {
          alt: "Holding Statement",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/las-holding-statement-dark-icon.svg" : "assets/images/light-icon/las-holding-statement-light-icon.svg"
        },
        {
          alt: "Credit Score",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/credit-score-dark-icon.svg" : "assets/images/light-icon/credit-score-light-icon.svg"
        },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/menu-options-dark-icon.svg" : "assets/images/light-icon/menu-options-light-icon.svg"
        },

      ],
      wealth: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/dark-icon/dashboard-light-icon.svg"
        },
        {
          alt: "risk-profiling",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/dark-icon/dashboard-light-icon.svg"
        },
        {
          alt: "manage-folio",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/dark-icon/dashboard-light-icon.svg"
        },
        {
          alt: "customer-service",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/dark-icon/dashboard-light-icon.svg"
        },
        {
          alt: "cart",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/dark-icon/dashboard-light-icon.svg"
        },
        {
          alt: "search",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/dark-icon/dashboard-light-icon.svg"
        },
        {
          alt: "More Options",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/dark-icon/dashboard-light-icon.svg"
        },

      ],
      collection: [
        {
          alt: "Home",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/dashboard-dark-icon.svg" : "assets/images/light-icon/dashboard-light-icon.svg",
          id: "home",
        },
        {
          alt: "Your Documents",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/document-center-dark-icon.svg" : "assets/images/light-icon/document-center-light-icon.svg",
          id: "document",
        }

      ]
    }
    var newMenuArcObject = {
      retail: [
        // {
        //   alt: "Refer And Earn",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/refer-and-earn-dark-icon.svg" : "assets/images/light-icon/refer-and-earn-light-icon.svg"
        // },
        {
          alt: "Tools & Calculators",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
        },
        {
          alt: "E-Locker",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
        },
        {
          alt: "Whatsapp Servicing",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-dark-icon.svg" : "assets/images/light-icon/whatsapp-light-icon.svg"
        },
        // {
        //   alt: "Offers For You",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/personalised-offer-dark-icon.svg" : "assets/images/light-icon/personalised-offer-light-icon.svg"
        // },
        {
          alt: "Investment",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/investment-dark-icon.svg" : "assets/images/light-icon/investment-light-icon.svg"
        }
      ],
      ccod: [
        // {
        //   alt: "Refer And Earn",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/refer-and-earn-dark-icon.svg" : "assets/images/light-icon/refer-and-earn-light-icon.svg"
        // },
        {
          alt: "Tools & Calculators",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
        },
        {
          alt: "E-Locker",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
        },
        {
          alt: "Whatsapp Servicing",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-dark-icon.svg" : "assets/images/light-icon/whatsapp-light-icon.svg"
        },
        // {
        //   alt: "Offers For You",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/personalised-offer-dark-icon.svg" : "assets/images/light-icon/personalised-offer-light-icon.svg"
        // },
        {
          alt: "Investment",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/investment-dark-icon.svg" : "assets/images/light-icon/investment-light-icon.svg"
        }
      ],
      las: [
        // {
        //   alt: "Refer And Earn",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/refer-and-earn-dark-icon.svg" : "assets/images/light-icon/refer-and-earn-light-icon.svg"
        // },
        {
          alt: "Tools & Calculators",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/tools-and-calculator-dark-icon.svg" : "assets/images/light-icon/tools-and-calculator-light-icon.svg"
        },
        {
          alt: "E-Locker",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/e-locker-dark-icon.svg" : "assets/images/light-icon/e-locker-light-icon.svg"
        },
        // {
        //   alt: "Whatsapp Servicing",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-dark-icon.svg" : "assets/images/light-icon/whatsapp-light-icon.svg"
        // },
        // {
        //   alt: "Offers For You",
        //   img: themeState === "THEME_DARK" ? "assets/images/dark-icon/personalised-offer-dark-icon.svg" : "assets/images/light-icon/personalised-offer-light-icon.svg"
        // },
        {
          alt: "Investment",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/investment-dark-icon.svg" : "assets/images/light-icon/investment-light-icon.svg"
        }
      ],
      wealth: [
        {
          alt: "download-app",
          img: "assets/images/home-icon.svg"
        },
        {
          alt: "last-5-transactions",
          img: "assets/images/home-icon.svg"
        },
        {
          alt: "order-status",
          img: "assets/images/home-icon.svg"
        },
        {
          alt: "whatsapp",
          img: themeState === "THEME_DARK" ? "assets/images/dark-icon/whatsapp-dark-icon.svg" : "assets/images/light-icon/whatsapp-light-icon.svg"
        }
      ],
    }
    var mobileFooterObject = {
      retail: [],
      ccod: [],
      las: [],
      wealth: []
    }
    Object.keys(newFooterIconObject).forEach((keyName) => {
      Object.keys(mobileFooterObject).forEach((mobileKeyName) => {
        if (keyName === mobileKeyName) {
          newFooterIconObject[keyName].forEach((item) => {
            mobileFooterObject[mobileKeyName].push(item)
          })
          newMenuArcObject[keyName].forEach((item) => {
            mobileFooterObject[mobileKeyName].push(item)
          })
        }
      })
    })
    let newObject = makeMobileDropDownArray(mobileFooterObject);
    setMobileDropDownArrayObject(newObject)
    setMobileArray(mobileFooterObject)
    setMenuArray(newMenuArcObject)
    setFooterIconsArray(newFooterIconObject)
    setCollectionIcon(newFooterIconObject.collection)
  }, [width, themeState, contextData?.["currentActiveData"].system])


  useEffect(() => {
    const onBodyClick = (e) => {
      if (!groupEmployeeRef.current?.contains(e.target)) {
        setOpenOptions(false)
      }
      document.body.addEventListener('click', onBodyClick);
    }
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    }
  }, [])

  // TO rotate in Phone
  let temp = 0;
  // let temp1 = 10;
  const handleScroll = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    temp += e.deltaX;
    if (e.target !== e.currentTarget) {
      return;
    }

    // if (temp > 0 && temp < 70) {
    e.target.style.transform = `rotate(${-temp}deg)`;
    console.log("temp=" + temp);
    console.log("deltax=" + e.deltaX);
    console.log("rotation=" + e.target.style.transform);
    for (let i = 0; i < 9; i++) {
      e.target.childNodes[i].style.transform = `rotate(${temp}deg)`;
    }
    // }
  };

  const footerClickAnalytics = (menuTitle) => {
    var accountNumber = contextData?.currentActiveData.accountNumber;
    var currentUserData = contextData?.parsedUserData[accountNumber];
    var gcid = currentUserData.GCID;
    let productName = contextData?.currentActiveData?.system === "las" ? "LAS" : contextData?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
    if (portalType !== "collection") {

      let analytics_data = {
        user: {
          loginMethod: "",
          loginStatus: "",
          gcid: gcid,
          loginType: "",
          loanaccountNo: accountNumber,
          firstloginDate: ""
        },
        products: {
          productCode: productName,
        },
        data: {
          ctaText: "",
          componentName: "Footer",
          bannerTitle: "",
          menuTitle: menuTitle,
          faqTitle: "",
          searchTerm: "",
          lobActivity: "",
          status: "",
          eventName: menuTitle
        }
      }
      analytics.trackData("menuInteraction", analytics_data);
    }
  }

  //-------- TO Navigate to different pages based on footer icon click -------- //

  const handleFooterIconClick = (itemName, event) => {
    setOpenOptions(false)

    switch (itemName) {
      case "Home":
        footerClickAnalytics("Home");
        navigate("/overview")
        break;
      case "More Options":
        footerClickAnalytics("More Options");
        event.stopPropagation()
        setOpenOptions(!openOptions)
        break;
      case "Self Service":
        footerClickAnalytics("Customer Service");
        setCustomerServicePageName('banner');
        setPageName("Self Service");
        navigate("/customer-service")
        break;
      case "Offers For You":
        footerClickAnalytics("Offers For You");
        navigate("/offers")
        break;
      case "Investment":
        footerClickAnalytics("Investment");
        navigate("/investment")
        break;
      case "Holding Statement":
        footerClickAnalytics("Holding Statement");
        dispatchData({
          name: "loaderFlag",
          payload: true,
          type: STOREDATA,
        });
        OverviewServiceObject.holdingStatementService(
          contextData.authToken,
          contextData.currentActiveData.accountNumber
        )
          .then((holdingStatementResponse) => {
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA,
            });
          })
          .catch((error) => {
            setFailurePopUpContent({
              apiName: "holding-statement/v1",
              message:
                "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
              errorCode: error,
            });
            setFailurePopUpModal(true);
            dispatchData({
              name: "loaderFlag",
              payload: false,
              type: STOREDATA,
            });
          });
        break;
      case "E-Locker":
        footerClickAnalytics("E-Locker");
        navigate("/e-locker")
        break;
      case "Tools & Calculators":
        footerClickAnalytics("Tools & Calculators");
        setCalculatorModal(true)
        break;
      case "Your Documents":
        footerClickAnalytics("Document Centre");
        if (contextData?.["currentActiveData"].system === "retail") {
          navigate("/account-details", { state: { redirectionFrom: "soa" } });
          // window.location.reload();
        } else if (contextData?.["currentActiveData"].system === "ccod") {
          navigate("/account-details", { state: { redirectionFrom: "Documents" } });
          // window.location.reload();
        }
        break;

      case "Bill Pay":
        footerClickAnalytics("Bill Pay");
        let requestObj = {
          header: {
            authToken: contextData.authToken,
            theme: themeState === "THEME_LIGHT" ? "light" : "dark"
          },
          body: {
            mobileNumber: contextData.loginMobileNo,
          },
        };
        dispatchData({
          name: "loaderFlag",
          payload: true,
          type: STOREDATA,
        });
        OverviewServiceObject.billPayService(requestObj).then((finalUrl) => {
          navigate("/bill-payment", { state: { url: { finalUrl } } })
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        }).catch((error) => {
          setFailurePopUpContent({
            apiName: "get-bill-pay-link/v1",
            message:
              "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            errorCode: error,
          });
          setFailurePopUpModal(true);
          dispatchData({
            name: "loaderFlag",
            payload: false,
            type: STOREDATA,
          });
        });
        break;
      case "Credit Score":
        footerClickAnalytics("Credit Score");
        navigate('/credit-score')
        break;
      case "Whatsapp Servicing":
        if (width <= 900) {
          window.open("http://wa.me/917506756060?text=Hi", "_self")
        } else {
          setWhatsAppPopUp(true);
        }
        break;
      default:
        break;
    }

  };

  const makeMobileDropDownArray = (mobileFooterObject) => {
    var newArray = mobileFooterObject[contextData?.["currentActiveData"].system].filter((item, index) => {
      return index >= 2 && item.alt.toLowerCase() !== "more options"
    })
    // console.log("new", newArray)

    function sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    }
    var newObject = {}
    newObject[contextData?.["currentActiveData"].system] = sliceIntoChunks(newArray, 4);

    return newObject
  }

  return (
    <>
      {failurePopUpModal ? (
        <FailurePopUp
          onClick={() => setFailurePopUpModal(false)}
          visible={failurePopUpModal}
          setFailureModalStat={setFailurePopUpModal}
          failurePopUpContent={failurePopUpContent}
        />
      ) : null}
      {successPopUpModal ? (
        <SuccessPopUp
          onClick={() => setSuccessPopUpModal(false)}
          visible={successPopUpModal}
          setSuccessModalStat={setSuccessPopUpModal}
          successPopUpContent={successPopUpContent}
        />
      ) : null}

      {pathname !== "login" ? <div className="footer-space">
        {calculatorModal && (
          <ToolsAndCalcModal setCalculatorModal={setCalculatorModal} />
        )}
        {
          whatsAppPopUp && (
            <WhatsAppRegisterModal setWhatsAppPopUp={setWhatsAppPopUp} setWhatsAppSuccessPopUp={setWhatsAppSuccessPopUp} />
          )}
        {
          whatsAppSuccessPopUp && (
            <WhatsappServiceSubscribe setWhatsAppSuccessPopUp={setWhatsAppSuccessPopUp} />
          )
        }
        <div className="post-login-footer">
          <div className="post-login-footer-container">
            {/* <div className='post-login-footer-menu-container'> */}
            <div className={portalType === "collection" ? "menu-list-collection" : "menu-list"}>
              {/* TO Render Desktop Icons */}

              {width && width > 600 || portalType === 'collection' ?
                <>
                  {
                    portalType === "collection" ? (collectionIcon.map((item, index) => {
                      return (
                        <div className="menu-item tooltip" onClick={(event) => { handleFooterIconClick(item.alt, event) }} key={index}>
                          <img
                            src={item.img}
                            alt={item.alt}
                            id={item?.id}
                          ></img>
                          <div className={`${themeState === "THEME_DARK" ? "tooltip-div THEME_DARK" : "tooltip-div"}`} >
                            <div className="tooltip-text">{item.alt}</div>
                          </div>
                        </div>)
                    })) :
                      (footerIconArray && footerIconArray[contextData?.["currentActiveData"].system].map((item, index) => {
                        return (<div className="menu-item tooltip" onClick={(event) => { handleFooterIconClick(item.alt, event) }} key={index}>
                          <img
                            src={item.img}
                            alt={item.alt}
                            id={item.id}
                          ></img>
                          <div className={`${themeState === "THEME_DARK" ? "tooltip-div THEME_DARK" : "tooltip-div"}`} >
                            <div className="tooltip-text">{item.alt}</div>
                          </div>

                        </div>)
                      }))
                  }
                  {openOptions && openOptions === true ?
                    // <div className="grid-popup-main">
                    <div ref={groupEmployeeRef} style={{ backgroundImage: themeState === "THEME_DARK" ? `url("assets/images/dark-icon/dark-arch.svg")` : 'url("assets/images/light-icon/light-arch.svg")' }} className="grid-popup-desktop">
                      {menuArray[contextData?.["currentActiveData"].system].map((item, index) => {
                        return (
                          <div
                            className="grid-popup-item"
                            onMouseEnter={() => { setGridMessage(item.alt) }}
                            onClick={(event) => { handleFooterIconClick(item.alt, event) }}
                            key={index}
                          // onMouseEnter={(e) => gridPop(e)}
                          // onMouseLeave={() => gridPopOff()}
                          >
                            <img
                              src={item.img}
                              alt={item.alt}
                            ></img>
                          </div>
                        )
                      })}
                      {gridPopupMesage && (
                        <div className="grid-popup-item-message">
                          <div className="arc-message">
                            <img src={`${themeState === "THEME_DARK" ? "assets/images/curved-div-inverted.svg" : "assets/images/curved-div.svg"}`} alt="" />
                            <p>{gridMessage}</p>
                          </div>
                        </div>
                      )}
                    </div>
                    // </div> 
                    : null}
                </>
                :
                <>
                  {
                    mobileArray && mobileArray[contextData?.["currentActiveData"].system].map((item, index) => {
                      if (index < 2) {
                        return (<div className="menu-item" onClick={(event) => { handleFooterIconClick(item.alt, event) }}>
                          <img
                            src={item.img}
                            alt={item.alt}
                            id={item.id}
                          ></img>
                        </div>)
                      } else if (item.alt.toLowerCase() === "more options") {
                        return (<div className="menu-item" onClick={(event) => { handleFooterIconClick(item.alt, event) }}>
                          <img
                            src={item.img}
                            alt={item.alt}
                            id={item.id}
                          ></img>
                        </div>)
                      }
                    })
                  }
                  {openOptions && openOptions === true || contextData?.showOptions === true ?
                    mobileDropDownArrayObject && mobileDropDownArrayObject[contextData?.["currentActiveData"].system] && mobileDropDownArrayObject[contextData?.["currentActiveData"].system].map((itemArray, index) => {
                      return (<div ref={groupEmployeeRef} className="grid-popup-desktop" style={{ top: `calc( ${addingHeight}px * ${index + 1})`, backgroundImage: themeState === "THEME_DARK" ? `url("assets/images/dark-icon/dark-arch.svg")` : 'url("assets/images/light-icon/light-arch.svg")' }} >
                        {itemArray.map((item, index) => {
                          return (
                            <div
                              className="grid-popup-item"
                              onMouseEnter={() => { setGridMessage(item.alt) }}
                              onClick={(event) => { handleFooterIconClick(item.alt, event) }}

                            >
                              <img
                                src={item.img}
                                alt={item.alt}
                                id={item.id}
                              ></img>
                            </div>
                          )
                        })}
                        {index == 0 ? gridPopupMesage &&
                          <div className="grid-popup-item-message">
                            <div className="arc-message">
                              <img src={`${themeState === "THEME_DARK" ? "assets/images/curved-div-inverted.svg" : "assets/images/curved-div.svg"}`} alt="" />
                              <p>{gridMessage}</p>
                            </div>
                          </div>
                          : null}
                      </div>)

                    })
                    : null}
                </>
              }

              {/* User thumbnail for phone */}
              <div className={portalType === "collection" ? "user-image-phone menu-item-phone collection-menu-item-phone" : "user-image-phone menu-item-phone"}>
                <div className="tooltip">
                  <img
                    src={contextData?.getUserDataV3?.body.PROFILE_PIC !== undefined ? contextData?.getUserDataV3?.body.PROFILE_PIC !== "" ? "data:image/png;base64," + contextData?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg"}
                    alt="person"
                    onClick={() => {
                      navigate("/profile", { state: { redirectionFrom: "profile-details" } });
                      footerClickAnalytics("profile");
                    }}
                  ></img>
                  <div className={`${themeState === "THEME_DARK" ? "profile THEME_DARK" : "profile"}`}>
                    <div className="tooltip-text">Profile</div>
                  </div>
                </div>
              </div>
              <div className="user-image-desktop-div">
                <div className="tooltip">
                  <img
                    src={contextData?.getUserDataV3?.body.PROFILE_PIC !== undefined ? contextData?.getUserDataV3?.body.PROFILE_PIC !== "" ? "data:image/png;base64," + contextData?.getUserDataV3?.body?.PROFILE_PIC : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg" : themeState === "THEME_DARK" ? "assets/images/dark-icon/profile-dark-icon.svg" : "assets/images/light-icon/profile-light-icon.svg"}
                    alt="person"
                    className="user-image-desktop"
                    onClick={() => {
                      navigate("/profile", { state: { redirectionFrom: "profile-details" } });
                      footerClickAnalytics("profile");
                    }}
                  ></img>
                  <div className="profile">
                    <div className="tooltip-text">Profile</div>
                  </div>
                </div>
              </div>
              <Logout portalType={portalType} />
            </div>
          </div>
        </div>
      </div> : null}
    </>

  );
};

export default PostLoginFooter;
