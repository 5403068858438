import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import serviceRequest from "../../apis/service-request";
import commonFunctionObject from "../../common-function";
import SubsequentDisbursement from "../../components/SubsequentDisbursement";
import DataMapping from "../../../../Shared/context/data-mapping";
import { STOREDATA } from "../../../../Shared/context/data-mapping/action/action.type";
// import { THEME_LIGHT } from "../../context/ThemeContext/actions";
import { THEME_LIGHT } from "../../../../Shared/context/ThemeContext/actions";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../Shared/context/ThemeContext/store/ThemeContext";
import CustomerServiceObject from "../../services/CustomerService";
import CreditScoreServiceObject from "../../services/CreditScoreService";
import LasAccountDetailsServiceObject from "../../services/LasAccountDetailsService";
import LoginServiceObject from "../../services/Login";
import OnlineDisbursalServiceObject from "../../services/OnlineDisbursal/OnlineDisbursalService";
import Button from "../Button";
import FailurePopUp from "../FailurePopUp";
import SuccessPopUp from "../SuccessPopUp";
import "./PostOtp.scss"
import CustomerServicePopUp from "../../components/CustomerServicePopUp";
// import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import pageNameContext from "../../../../Shared/context/ThemeContext/store/PageNameContext";
import AnalyticsContextProvider from "../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import PropTypes from 'prop-types';

const PostOtp = ({ pageName, userInput, onlineDisbursalObject, refID, postOnCallFlag, lasData, formValueJson, termsAgreementClick, subsequentFile, paymentReciptFile, singleActionRequestJson, setPostOtpFlag, setSuccessPopUpContentLas, setSuccessPopUpModalLasFlag, setModalState, setFailurePopUpModalLasFlag, setFailurePopUpContentLas, setFreezeFlag, setFreezeTime, setFunctionalityFreezeFlag }) => {
    const [reffNo, setOtpReffNo] = useState(refID)
    const [themeState] = useContext(ThemeContext);
    const [optError, setoptError] = useState("")
    const [data, dispatchData] = useContext(DataMapping)
    let [otpTimer, setOTPTimer] = useState(40);
    const [otp, setOtp] = useState("");
    let [resendOtpChance, setResendOtpChance] = useState(0);
    const { customerServicePageName, setCustomerServicePageName, setPageName } = useContext(pageNameContext);
    const [onCallFlag, setOnCallFlag] = useState(false);
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    })
    const [successPopUpModal, setSuccessPopUpModal] = useState(false);
    const [successPopUpContent, setSuccessPopUpContent] = useState("")
    const [loaderFlag, setLoaderFlag] = useState(false);
    const [requestRaisedModal, setRequestRaisedModal] = useState(false);
    const [content, setContent] = useState("");
    const { analytics } = useContext(AnalyticsContextProvider);
    // const [subsequentPageHide, setsubsequentPageHide] = useState(false);


    var currentAcctounNumber = data?.currentActiveData.accountNumber
    var ccodData = data?.parsedUserData[currentAcctounNumber]
    var currentUserData = data?.parsedUserData[currentAcctounNumber];
    var buliderdetailsContract = data?.subsequentContracts;
    let timer
    const navigate = useNavigate()
    let timer2

    const handleOtpTimer = () => {
        setOTPTimer((oldState) => {
            if (oldState) {
                return oldState - 1
            } else {
                clearInterval(interval);
                return 0
            }
        })
    }

    let interval
    useEffect(() => {
        interval = setInterval(() => handleOtpTimer(), 1000);
        return () => clearInterval(interval);
    }, [resendOtpChance]);

    // useEffect(() => {
    //     if (otpTimer) {
    //         timer2 = setTimeout(handleOtpTimer, 1000);
    //     }
    // }, [otpTimer]);

    // useEffect(() => {
    //     otpTimerStart()
    // }, [])

    const handleChange = (otp) => {
        setoptError("");
        setOtp(otp);
        if (otp.length === 6) {
            redirect(otp);
        }
    };

    const redirect = (otp) => {

        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        OnlineDisbursalServiceObject.verifyOtpService(otp, reffNo, userInput, data?.authToken).then((validateOtpResponse) => {
            setOtp("")
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })

            if (pageName === "online-disbursal") {

                var company = ccodData.cdiContract.COMPANY;
                var companyName = "";
                (company.toUpperCase() === "TCFSL") ? companyName = "CFAB" : companyName = company.toUpperCase();
                dispatchData({
                    name: "loaderFlag",
                    payload: true,
                    type: STOREDATA
                })

                OnlineDisbursalServiceObject.pushOnlineDisbursalService(onlineDisbursalObject, ccodData.sapContract, companyName, currentAcctounNumber, data?.authToken).then((pushOnlineDisbursalResponse) => {
                    setSuccessPopUpContent("Your request is succesfully submitted. Your transaction id is " + pushOnlineDisbursalResponse)
                    setSuccessPopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                }).catch((error) => {
                    setFailurePopUpContent({
                        apiName: "",
                        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                })
            } else if (pageName === "las-disbursement") {
                setFreezeFlag(true)
                setFreezeTime(parseInt(300000 / 1000).toString())
                setFunctionalityFreezeFlag(true)
                let saveDisbursementInfoRequest = {
                    "header": {
                        "authToken": data.authToken
                    },
                    "body": {
                        "loanAccount": lasData.lasopenContracts[0].LoanAccount[0].Account_Number,
                        "loanNo": lasData.lasopenContracts[0].LoanAccount[0].ScheduleDetails[0].LoanNumber,
                        "bankName": lasData.lasopenContracts[0].bankName,
                        "ifsc": lasData.lasopenContracts[0].ifscCode,
                        "bankAccountNo": lasData.lasopenContracts[0].bankAccountNumber,
                        "amount": lasData.disbursalRequestAmount,
                        "deductedCharges": lasData.outstandingAmount,
                        "netAmount": lasData.netAmount
                    }
                }
                dispatchData({
                    name: "loaderFlag",
                    payload: true,
                    type: STOREDATA
                })
                LasAccountDetailsServiceObject.saveDisbursementInfoRequest(saveDisbursementInfoRequest).then((saveDisbursementInfoResponse) => {
                    if (saveDisbursementInfoResponse.body.retStatus === "SUCCESS") {
                        setModalState(false)
                        setSuccessPopUpContentLas("Your drawdown request has been placed successfully.")
                        setSuccessPopUpModalLasFlag(true)
                        setPostOtpFlag(false)
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    } else if (saveDisbursementInfoResponse.body.retStatus == 'FAILURE') {
                        var saveDisbursementInfoResponse = saveDisbursementInfoResponse.body.SaveDisbursement_Response;
                        if (saveDisbursementInfoResponse && saveDisbursementInfoResponse.DisbursementData) {
                            setFailurePopUpContentLas({
                                apiName: "save-disbursement-info/v1",
                                message: saveDisbursementInfoResponse.DisbursementData[0].RespRemarks,
                                errorCode: "L-36"
                            })
                            setFailurePopUpModalLasFlag(true)
                        } else {
                            setFailurePopUpContentLas({
                                apiName: "save-disbursement-info/v1",
                                message: "Sorry your request cannot be processed at the moment. Please try again after sometime",
                                errorCode: "L-36"
                            })
                            setFailurePopUpModalLasFlag(true)
                        }
                        setPostOtpFlag(false)
                        setModalState(false)
                    }
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                }).catch(error => {
                    setFailurePopUpContentLas({
                        apiName: "save-disbursement-info/v1",
                        message: "Sorry your request cannot be processed at the moment. Please try again after sometime",
                        errorCode: error
                    })
                    setFailurePopUpModalLasFlag(true)
                    setPostOtpFlag(false)
                    setModalState(false)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                })
            } else if (pageName === "credit-score-generation") {
                var accountNumber = data?.currentActiveData.accountNumber;
                var currentUserData = data?.parsedUserData[accountNumber];
                var gcid = currentUserData.GCID;
                let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
                let analytics_data = {
                    user: {
                        loginMethod: "",
                        loginStatus: "",
                        gcid: gcid,
                        loginType: "",
                        loanaccountNo: accountNumber,
                        firstloginDate: ""
                    },
                    products: {
                        productCode: productName,
                    },
                    data: {
                        ctaText: "",
                        componentName: "",
                        bannerTitle: "",
                        menuTitle: "",
                        faqTitle: "",
                        searchTerm: "",
                        lobActivity: "",
                        status: "",
                        otpStatus: "success",
                        eventName: "DC: Credit_Score_OTP_Verify"
                    }
                }
                analytics.trackData("creditscoreotpVerify", analytics_data);
                singleActionRequestJson.primaryGcid = data?.getUserDataV3.body.PRIMARY_GCID
                let singleActionRequestObject = {
                    "header": {
                        "authToken": data?.authToken
                    },
                    "body": singleActionRequestJson
                }
                dispatchData({
                    name: "loaderFlag",
                    payload: true,
                    type: STOREDATA
                })

                CreditScoreServiceObject.singleActionApiService(singleActionRequestObject).then((singleActionApiResponse) => {
                    var userData = data?.getUserDataV3
                    var currentLoggedInDate = userData.body.CURRENT_LOGGED_IN.split(" ")[0]
                    var currentLoggedInDateSplit = currentLoggedInDate.split("-")
                    var setPortalLastModifiedDate = currentLoggedInDateSplit[2] + "-" + currentLoggedInDateSplit[0] + "-" + currentLoggedInDateSplit[1]
                    userData.body["creditScoreDetails"] = {};
                    userData.body["creditScoreDetails"]["CREDIT_SCORE"] = singleActionApiResponse.body.creditScore;
                    userData.body["creditScoreDetails"]["PORTAL_LAST_MODIFIED"] = setPortalLastModifiedDate
                    dispatchData({
                        type: STOREDATA,
                        payload: userData,
                        name: "getUserDataV3"
                    })
                    setPostOtpFlag(false)
                    navigate("/credit-score", { state: { creditScore: singleActionApiResponse.body.creditScore } })
                }).catch((error) => {
                    if (error.toLowerCase().indexOf('email validation failed or phone validation failed. please try to invoke crq externally') !== -1) {
                        setFailurePopUpContent({
                            apiName: "",
                            message: "Dear Customer, mobile number does not match credit score records, please try again with another mobile number.",
                            errorCode: ""
                        })
                        setFailurePopUpModal(true)
                    } else if (error.toLowerCase().indexOf('consumer record not found') !== -1) {
                        setFailurePopUpContent({
                            apiName: "",
                            message: "Dear Customer, we did not find any Credit history against the details you have submitted.",
                            errorCode: ""
                        })
                        setFailurePopUpModal(true)
                    } else {
                        setFailurePopUpContent({
                            apiName: "",
                            message: "Sorry! there seems to be a technical glitch. Please try after sometime",
                            errorCode: "186"
                        })
                        setFailurePopUpModal(true)
                    }
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                })
            }
            else if (pageName === "subsequent-disbursement") {
                var accountNumber = data?.currentActiveData?.accountNumber
                var currentUserData = data?.parsedUserData[accountNumber]
                if (termsAgreementClick) {
                    dispatchData({
                        name: "loaderFlag",
                        payload: true,
                        type: STOREDATA
                    })
                    formValueJson["Previous Payment Receipt"] = "";
                    formValueJson["Demand Letter / Construction Estimate"] = "";
                    CustomerServiceObject.createPdfOfForm(formValueJson, currentUserData).then((pdfForm) => {
                        CustomerServiceObject.uploadRequestForm(pdfForm, formValueJson, data, currentUserData, buliderdetailsContract).then((response) => {
                            CustomerServiceObject.onlinetracheSrRaise(formValueJson, data, currentUserData, buliderdetailsContract).then((value) => {

                                serviceRequestFn(value.requestObj, value.serviceDetails)

                            })
                        }).catch(() => {
                            dispatchData({
                                name: "loaderFlag",
                                payload: false,
                                type: STOREDATA
                            })
                        })

                    }).catch(() => {
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    })


                }
                else {
                    dispatchData({
                        name: "loaderFlag",
                        payload: true,
                        type: STOREDATA
                    })
                    CustomerServiceObject.uploadDemandLetter(formValueJson, data, currentUserData, subsequentFile, paymentReciptFile).then((callSR) => {
                        serviceRequestFn(callSR.requestObj, callSR.serviceDetails);
                    }).catch((error) => {
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                        /*  if (error.search("Currently unable to process your request please visit nearest branch.") > -1) {
                             setFailurePopUpContent({
                                 apiName: "upload-document-by-webtop/v1",
                                 message: error,
                                 errorCode: ""
                             })
                             setFailurePopUpModal(true)
                         } else {
                             setFailurePopUpContent({
                                 apiName: "upload-document-by-webtop/v1",
                                 message: "We are currently unable to process your request. Please try after sometime",
                                 errorCode: error
                             })
                             setFailurePopUpModal(true)
                         } */
                    })
                }

                // setsubsequentPageHide(true)

            }
        }).catch((error) => {
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            setOtp("")
            if (error === "03") {
                setFailurePopUpContent({
                    apiName: "verify-otp/v1",
                    message: "We are experiencing technical difficulty in OTP services. Please try after sometime",
                    errorCode: error
                })
                setFailurePopUpModal(true)
            } else if (error === "otp invalid") {
                setoptError("Incorrect OTP Entered")
            } else {
                setFailurePopUpContent({
                    apiName: "verify-otp/v1",
                    message: "We are experiencing technical difficulty in OTP services. Please try after sometime",
                    errorCode: ""
                })
                setFailurePopUpModal(true)
            }
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })

        })
    }

    const backBtnRedirect = () => {
        if (pageName === "online-disbursal") {
            setPostOtpFlag(false)
            navigate("/online-disbursal")
        } else if (pageName === "credit-score-generation") {
            setPostOtpFlag(false)
            navigate("/credit-score")
        } else if (pageName === "las-disbursement") {
            setPostOtpFlag(false)
            navigate("/account-details")
        }
        else if (pageName === "subsequent-disbursement") {
            navigate("/customer-service")
        }

    };

    const onClosePopUp = () => {

        setRequestRaisedModal(false);
        setCustomerServicePageName('banner');
        navigate("/customer-service")
    }

    let encryptedEmail;
    let subString = "@"
    console.log(userInput)

    if (userInput.search("@") > -1) {
        const discardedEmail = userInput.slice(
            2,
            userInput.indexOf("@")
        );
        const discardedEmailArray = discardedEmail?.split("");
        const discardedEmailMap = discardedEmailArray?.map((item) => "*");
        const discardedEmailString = discardedEmailMap?.join("");

        encryptedEmail = userInput.slice(0, 2)
            .concat(
                discardedEmailString,
                userInput.slice(
                    userInput.indexOf("@"),
                    userInput.length
                )
            );
    } else {
        const discardedEmail = userInput.slice(
            2,
            6
        );
        const discardedEmailArray = discardedEmail?.split("");
        const discardedEmailMap = discardedEmailArray?.map((item) => "*");
        const discardedEmailString = discardedEmailMap?.join("");

        encryptedEmail = "**"
            .concat(
                discardedEmailString,
                userInput.slice(
                    6,
                    userInput.length
                )
            );
    }

    const serviceRequestFn = (reqestObj, shafterrorObj) => {
        var currentcontract = data?.currentActiveData.accountNumber
        var currentUserData = data?.parsedUserData[currentcontract];
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        });
        CustomerServiceObject.getServiceRequest(reqestObj, shafterrorObj, currentUserData).then((value) => {
            if (value.status.toLowerCase() === "success") {
                CustomerServiceObject.viewRequestService().then((response) => {
                    if (response.header.status == "SUCCESS") {
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    } else {
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                        alert("FAILURE")
                    }
                })
                navigate('/trackRequest', { state: { respData: value.srData } })
            } else {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                setRequestRaisedModal(true)
                setContent(value.massege)
            }
        })
    }

    const resendOtp = () => {

        // setOnCallFlag(false)
        if (resendOtpChance < 3) {
            setResendOtpChance(
                resendOtpChance += 1
            )
            var serviceType
            if (userInput.search("@") > -1) {
                serviceType = "email"
            } else {
                serviceType = "mobile"
            }
            dispatchData({
                name: "loaderFlag",
                payload: true,
                type: STOREDATA
            })
            if (serviceType === "mobile") {
                let generateMobileOtpRequest = {
                    "header": {
                        "grantType": "otp",
                        "serviceType": "mobile",
                        "authToken": data.authToken,
                        "journeyType": "postLogin"
                    },
                    "body": {
                        "mobileNo": userInput
                    }
                }
                dispatchData({
                    name: "loaderFlag",
                    type: STOREDATA,
                    payload: true
                })

                CreditScoreServiceObject.generateMobileOtpService(generateMobileOtpRequest).then((generateMobileOtpRefID) => {
                    setOTPTimer(40)
                    clearTimeout(timer)
                    handlereset()
                    setOtpReffNo(generateMobileOtpRefID)
                    setSuccessPopUpContent("New OTP is generated")
                    setSuccessPopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                }).catch((error) => {
                    if (error.toLowerCase() === "number of attempts exceeded") {
                        setFailurePopUpContent({
                            apiName: "",
                            message: "Please login with User ID and Password.",
                            errorCode: ""
                        })
                        setFailurePopUpModal(true)
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    } else {
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                        setFailurePopUpContent({
                            apiName: "",
                            message: "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
                            errorCode: error
                        })
                        setFailurePopUpModal(true)
                    }
                })
            } else {
                let requestObj = {
                    "header": {
                        "grantType": "otp",
                        "serviceType": "email",
                        "authToken": data?.authToken,
                        "journeyType": "postLogin"
                    },
                    "body": {
                        "emailId": userInput
                    }
                }
                OnlineDisbursalServiceObject.generateEmailOtpService(requestObj).then((generateEmailOtpRefID) => {
                    setOTPTimer(40)
                    clearTimeout(timer)
                    handlereset()
                    setOtpReffNo(generateEmailOtpRefID)
                    setSuccessPopUpContent("New OTP is generated")
                    setSuccessPopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                }).catch((error) => {
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                    setFailurePopUpContent({
                        apiName: "",
                        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                })
            }

        } else {
            setFailurePopUpContent({
                apiName: "",
                message: "Max Limit Reached",
                errorCode: "101"
            })
            setFailurePopUpModal(true)
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
        }
    }

    const handlereset = () => {
        setOTPTimer(40);
        clearInterval(interval);
    };

    return (
        <div className="post-otp-container">
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {successPopUpModal ? <SuccessPopUp
                onClick={() => {
                    if (pageName === "online-disbursal") {
                        navigate("/overview")
                        setSuccessPopUpModal(false)
                    } else {
                        setSuccessPopUpModal(false)
                    }
                }}
                visible={successPopUpModal}
                setSuccessModalStat={setSuccessPopUpModal}
                successPopUpContent={successPopUpContent}
            /> : null}
            {/* {
                subsequentPageHide ? <SubsequentDisbursement hideUi={true} /> : null
            } */}
            <div className="post-otp-container-backBtn">
                <Button
                    className={"login-ui-btns login-btn-3"}
                    onClick={backBtnRedirect}
                >
                    {themeState === THEME_LIGHT ? (
                        <img
                            src={"assets/images/backButtonArrow.svg"}
                            alt="backButtonArrow"
                        />
                    ) : (
                        <img
                            src={"assets/images/lightBackButtonArrow.svg"}
                            alt="lightBackButtonArrow"
                        />
                    )}
                    <label>Back</label>
                </Button>
            </div>

            <div className="post-otp-container-otpEnter">
                <div className="post-otp-container-otpEnter-section-1">
                    <span>Enter OTP</span>
                    <div></div>
                </div>
                <div className="post-otp-container-otpEnter-section-2">
                    {/* {postOnCallFlag ? <span>
                        We’ve called your registered mobile number to provide you an OTP.
                    </span> : */}{userInput.search("@") > -1 ? <span>
                        We've sent a 6 digit OTP to your registered Email ID{" "}
                        {encryptedEmail}
                    </span> : <span>
                        We've sent a 6 digit OTP to your registered mobile
                        number ending with {encryptedEmail}
                    </span>}
                </div>
                <div className="post-otp-container-otpEnter-section-3">
                    <span>OTP Valid for {otpTimer} secs</span>
                </div>
                <div className="post-otp-container-otpEnter-section-4">
                    <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span style={{ width: "15px" }}></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        isInputSecure={true}
                        inputStyle={{
                            border: "none",
                            outline: "none",

                            borderBottom: "1px solid #95A5BF",
                            width: "100%",
                            height: "40px",
                            fontSize: "16px",
                            fontWeight: "400",
                            caretColor: "blue",
                        }}
                    />
                    {
                        optError ? <div className="post-optError">{optError}</div> : null
                    }

                </div>
            </div>


            {
                postOnCallFlag ? <div className="post-otp-container-otpVerify">
                    <span>
                        Didn't receive any OTP? <p onClick={resendOtp}> Resend Now</p>
                    </span>
                    {/* {userInput.search("@") > -1 ? null : onCallFlag ? <p onClick={resendOtp}>Get OTP via SMS</p> : <p onClick={onCallOTP}>Get OTP via call</p>} */}
                </div> : null
            }


            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {requestRaisedModal ? <CustomerServicePopUp
                onClick={() => onClosePopUp()}
                visible={requestRaisedModal}
                setModalStat={setRequestRaisedModal}
                Content={content}
            /> : null}
        </div>
    );
}

PostOtp.propTypes = {
    pageName: PropTypes.string,
    userInput: PropTypes.string,
    onlineDisbursalObject: PropTypes.object,
    refID: PropTypes.string,
    postOnCallFlag: PropTypes.bool,
    lasData: PropTypes.object,
    formValueJson: PropTypes.object,
    termsAgreementClick: PropTypes.bool,
    subsequentFile: PropTypes.object,
    paymentReciptFile: PropTypes.object,
    singleActionRequestJson: PropTypes.object,
    setPostOtpFlag: PropTypes.func,
    setSuccessPopUpContentLas: PropTypes.func,
    setSuccessPopUpModalLasFlag: PropTypes.func,
    setModalState: PropTypes.func,
    setFailurePopUpModalLasFlag: PropTypes.func,
    setFailurePopUpContentLas: PropTypes.func,
    setFreezeFlag: PropTypes.func,
    setFreezeTime: PropTypes.func,
    setFunctionalityFreezeFlag: PropTypes.func
}

export default PostOtp;