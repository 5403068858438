import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

//Context
import { useContext } from "react";
// import ThemeContext from "../../../context/ThemeContext/store/ThemeContext";
import ThemeContext from "../../../../../Shared/context/ThemeContext/store/ThemeContext";
// import { THEME_LIGHT } from "../../../context/ThemeContext/actions";
import { THEME_LIGHT } from "../../../../../Shared/context/ThemeContext/actions";

//Components
import Button from "../../../global/Button";

//CSS
import "./OTP.scss";
import { useEffect } from "react";
import validateOtp from "../../../apis/validate-otp/validate-otp";
import getUserId from "../../../apis/get-user-id/get-user-id";
import DataMapping from "../../../../../Shared/context/data-mapping";
import getUserDataV3 from "../../../apis/get-user-data-v3/get-user-data-v3";
import userLoginType from "../../../apis/user-login-type/user-login-type";
import { STOREACCOUNTDATA, STOREDATA } from "../../../../../Shared/context/data-mapping/action/action.type";
import generateOtp from "../../../apis/generate-otp";
import generateVoiceOtp from "../../../apis/generate-voice-otp";
import LoginServiceObject from "../../../services/Login";
import Loader from "../../../global/Loader";
import FailurePopUp from "../../../global/FailurePopUp";
import SuccessPopUp from "../../../global/SuccessPopUp";
import commonFunctionObject from "../../../common-function";
import jsHelper from "../../../utilities/jsHelper";
import AnalyticsContextProvider from "../../../../../Shared/context/AnalyticsContext/AnalyticsContextProvider";
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';




const OTP = ({
    onMobileEmail,
    helpUI,
    onEnterPassword,
    onForgotPasswordCredentials,
    onForgotUserID,
    onUserID,
    userInput,
    refNo,
    mobileOrEmail,
    setMobileOrEmial,
    tempAuthToken,
    setTempAuthToken,
    setUserId,
    pageName,
    setRefNo,
    captcha,
    setcaptcha,
    getcaptchafunction
}) => {
    const [themeState, dispatch, otpState, otpDispatch] =
        useContext(ThemeContext);
    const [optError, setoptError] = useState("");
    const [cookie, setCookie] = useCookies();
    const [data, dispatchData] = useContext(DataMapping);
    let [otpTimer, setOTPTimer] = useState(30);
    let [callOtpTimer, setCallOtpTimer] = useState(40);
    // console.log(otpState);
    // console.log(otpState.card);
    const [otp, setOtp] = useState("");
    let [resendOtpChance, setResendOtpChance] = useState(1)
    const [onCallFlag, setOnCallFlag] = useState(false);
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [OTPResendError, setOTPResendError] = useState(false)
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    })
    const [successPopUpModal, setSuccessPopUpModal] = useState(false);
    const [successPopUpContent, setSuccessPopUpContent] = useState("")
    const [resendFlag, setResendFlag] = useState(false);
    const { analytics } = useContext(AnalyticsContextProvider);
    const navigate = useNavigate()
    let timer;
    const handleOtpTimer = () => {
        setOTPTimer((oldState) => {
            if (oldState) {
                if (oldState < 10) {
                    setResendFlag(true)
                }
                return oldState - 1
            } else {
                clearInterval(interval);
                return 0
            }
        })
    }

    let interval;
    useEffect(() => {
        if (onCallFlag) {

            if (otpTimer === 0) {
                window.location.reload();
            }
        }
        interval = setInterval(() => handleOtpTimer(), 1000);

        return () => clearInterval(interval);
    }, [otpTimer]);

    const handleTimer = () => {
        setCallOtpTimer((prevState) => {
            return prevState - 1
        })
    }

    var interval2;
    useEffect(() => {
        interval2 = setInterval(() => handleTimer(), 1000);
        if (callOtpTimer === 0) {
            if (mobileOrEmail === "mobile") {
                onCallOTP();
            }
            clearInterval(interval2);
        }
        return () => clearInterval(interval2);
    }, [callOtpTimer]);



    const onCallOTP = () => {
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        });
        let analytics_data = {
            user: {
                loginMethod: "",
                loginStatus: "",
                gcid: "",
                loginType: "",
                loanaccountNo: "",
                firstloginDate: ""
            },
            products: {
                productCode: "",
            },
            data: {
                ctaText: "Sending OTP via call",
                componentName: "OTP",
                bannerTitle: "",
                menuTitle: "",
                faqTitle: "",
                searchTerm: "",
                lobActivity: "",
                status: "",
                eventName: "OTP on call"
            }
        }
        analytics.trackData("ctaInteraction", analytics_data)

        console.log("Cookiess", cookie);
        LoginServiceObject.generateVoiceOtpService(tempAuthToken, userInput, refNo).then((generateVoiceOtpResponse) => {
            setOTPTimer(120);
            clearTimeout(timer);
            setOnCallFlag(true);
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            });

        }).catch((error) => {
            setOnCallFlag(false);
            if (error.toLowerCase() === "number of attempts exceeded") {
                setFailurePopUpContent({
                    apiName: "",
                    message: "Please login with User ID and Password.",
                    errorCode: ""
                })
                setFailurePopUpModal(true)
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }
            else {
                setFailurePopUpContent({
                    apiName: "generate-voice-otp/v1",
                    message: "We are currently facing error in Call OTP services. Please try again later",
                    errorCode: error
                })
                setFailurePopUpModal(true);
                // window.location.reload();
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }
        })
    }

    const onClosePopUp = () => {
        setFailurePopUpModal(false);
        window.location.reload();
    }

    // useEffect(() => {    
    //     if (otpTimer) {
    //         timer2 = setTimeout(handleOtpTimer, 1000);
    //     }
    // }, [otpTimer]);

    // useEffect(() => {
    //     otpTimerStart()
    // }, [])

    const handleChange = (otp) => {
        setoptError("");
        setOtp(otp);
        if (otp.length === 6) {
            redirect(otp);
        }
    };

    const redirect = (otp) => {

        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        LoginServiceObject.validateOtpService(tempAuthToken, otp, refNo, userInput).then((validateOtpResponse) => {
            setOtp("")
            if (pageName === "forgotUserID") {
                LoginServiceObject.getUserIdService(data.authToken, userInput).then((getUserIdResponse) => {
                    setUserId(getUserIdResponse.body.PRIMARY_GCID)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    });
                    if (userInput.search("@") > -1) {
                        let analytics_data = {
                            user: {
                                loginMethod: "",
                                loginStatus: "",
                                gcid: getUserIdResponse.body.PRIMARY_GCID,
                                loginType: "",
                                loanaccountNo: "",
                                firstloginDate: ""
                            },
                            products: {
                                productCode: "",
                            },
                            data: {
                                ctaText: "",
                                componentName: "OTP",
                                bannerTitle: "",
                                menuTitle: "",
                                faqTitle: "",
                                searchTerm: "",
                                lobActivity: "",
                                status: "",
                                eventName: "Forgot user ID using E-mail id"
                            }
                        }
                        analytics.trackData("continuebuttonClick", analytics_data)
                    } else {
                        let analytics_data = {
                            user: {
                                loginMethod: "",
                                loginStatus: "",
                                gcid: getUserIdResponse.body.PRIMARY_GCID,
                                loginType: "",
                                loanaccountNo: "",
                                firstloginDate: ""
                            },
                            products: {
                                productCode: "",
                            },
                            data: {
                                ctaText: "",
                                componentName: "OTP",
                                bannerTitle: "",
                                menuTitle: "",
                                faqTitle: "",
                                searchTerm: "",
                                lobActivity: "",
                                status: "",
                                eventName: "Forgot user ID using mobile number"
                            }
                        }
                        analytics.trackData("continuebuttonClick", analytics_data)
                    }
                    onUserID()
                }).catch((error) => {
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                })
            } else if (pageName === "forgotPassword") {

                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
                onEnterPassword();
            } else if (pageName === "mobileEmailLogin") {
                dispatchData({
                    type: STOREDATA,
                    name: "authToken",
                    payload: validateOtpResponse.header.authToken
                });

                setCookie('Authorization', validateOtpResponse.header.authToken, { path: "/" });


                LoginServiceObject.getUserDataV3Service(validateOtpResponse.header.authToken).then((getUserDataV3Response) => {
                    let getFinalData = "";
                    if (userInput.search("@") > -1) {
                        getFinalData = LoginServiceObject.commonLoginAfterUserData(getUserDataV3Response, "email");
                    } else {
                        getFinalData = LoginServiceObject.commonLoginAfterUserData(getUserDataV3Response, "mobileNumber", userInput);
                    }
                    dispatchData({
                        type: STOREDATA,
                        name: "loginMobileNo",
                        payload: getFinalData.mobileNumber
                    })
                    dispatchData({
                        type: STOREDATA,
                        name: "getUserDataV3",
                        payload: getUserDataV3Response
                    });
                    dispatchData({
                        type: STOREDATA,
                        name: "customerName",
                        payload: jsHelper.toSentence(getFinalData.customerName)
                    });

                    dispatchData({
                        type: STOREDATA,
                        name: "parsedUserData",
                        payload: getFinalData.parsedUserData
                    })
                    if (getUserDataV3Response.body?.OVERLAY_DISPLAY && getUserDataV3Response.body?.OVERLAY_DISPLAY.toUpperCase() === "Y") {
                        dispatchData({
                            type: STOREDATA,
                            name: "overLayFlag",
                            payload: true
                        })
                    } else {
                        dispatchData({
                            type: STOREDATA,
                            name: "overLayFlag",
                            payload: false
                        })
                    }

                    LoginServiceObject.userLoginTypeService(validateOtpResponse.header.authToken).then((userLoginTypeResponse) => {
                        dispatchData({
                            type: STOREDATA,
                            name: "userLoginType",
                            payload: userLoginTypeResponse
                        });
                        console.log(getUserDataV3Response);
                        if (userInput.search("@") > -1) {
                            let analytics_data = {
                                user: {
                                    loginMethod: "Email",
                                    loginStatus: "success",
                                    gcid: getUserDataV3Response.body.PRIMARY_GCID,
                                    loginType: "",
                                    productCode: "",
                                    loanaccountNo: "",
                                    firstloginDate: ""
                                },
                                products: {
                                    productCode: "",
                                },
                                data: {
                                    ctaText: "",
                                    componentName: "",
                                    bannerTitle: "",
                                    menuTitle: "",
                                    faqTitle: "",
                                    searchTerm: "",
                                    lobActivity: "",
                                    status: "",
                                    eventName: "Using Email id"
                                }
                            }
                            analytics.trackData("loginSuccess", analytics_data)
                        } else {
                            let analytics_data = {
                                user: {
                                    loginMethod: "Mobile",
                                    loginStatus: "success",
                                    gcid: getUserDataV3Response.body.PRIMARY_GCID,
                                    loginType: "",
                                    productCode: "",
                                    loanaccountNo: "",
                                    firstloginDate: ""
                                },
                                products: {
                                    productCode: "",
                                },
                                data: {
                                    ctaText: "",
                                    componentName: "",
                                    bannerTitle: "",
                                    menuTitle: "",
                                    faqTitle: "",
                                    searchTerm: "",
                                    lobActivity: "",
                                    status: "",
                                    eventName: "Using Mobile Number"
                                }
                            }
                            analytics.trackData("loginSuccess", analytics_data);
                        }
                        navigate("/overview")
                    }).catch((error) => {
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        });
                        if (userInput.search("@") > -1) {
                            let analytics_data = {
                                user: {
                                    loginMethod: "Email",
                                    loginStatus: "failure",
                                    gcid: "",
                                    loginType: "",
                                    productCode: "",
                                    loanaccountNo: "",
                                    firstloginDate: ""
                                },
                                products: {
                                    productCode: "",
                                },
                                data: {
                                    ctaText: "",
                                    componentName: "",
                                    bannerTitle: "",
                                    menuTitle: "",
                                    faqTitle: "",
                                    searchTerm: "",
                                    lobActivity: "",
                                    status: "",
                                    eventName: "Using Email id"
                                }
                            }
                            analytics.trackData("loginSuccess", analytics_data)
                        } else {
                            let analytics_data = {
                                user: {
                                    loginMethod: "Mobile",
                                    loginStatus: "failure",
                                    gcid: "",
                                    loginType: "",
                                    productCode: "",
                                    loanaccountNo: "",
                                    firstloginDate: ""
                                },
                                products: {
                                    productCode: "",
                                },
                                data: {
                                    ctaText: "",
                                    componentName: "",
                                    bannerTitle: "",
                                    menuTitle: "",
                                    faqTitle: "",
                                    searchTerm: "",
                                    lobActivity: "",
                                    status: "",
                                    eventName: "Using Mobile Number"
                                }
                            }
                            analytics.trackData("loginSuccess", analytics_data)
                        }
                    })
                }).catch((error) => {
                    setFailurePopUpContent({
                        apiName: "",
                        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    });
                    if (userInput.search("@") > -1) {
                        let analytics_data = {
                            user: {
                                loginMethod: "Email",
                                loginStatus: "failure",
                                gcid: "",
                                loginType: "",
                                productCode: "",
                                loanaccountNo: "",
                                firstloginDate: ""
                            },
                            products: {
                                productCode: "",
                            },
                            data: {
                                ctaText: "",
                                componentName: "",
                                bannerTitle: "",
                                menuTitle: "",
                                faqTitle: "",
                                searchTerm: "",
                                lobActivity: "",
                                status: "",
                                eventName: "Using Email id"
                            }
                        }
                        analytics.trackData("loginSuccess", analytics_data)
                    } else {
                        let analytics_data = {
                            user: {
                                loginMethod: "Mobile",
                                loginStatus: "failure",
                                gcid: "",
                                loginType: "",
                                productCode: "",
                                loanaccountNo: "",
                                firstloginDate: ""
                            },
                            products: {
                                productCode: "",
                            },
                            data: {
                                ctaText: "",
                                componentName: "",
                                bannerTitle: "",
                                menuTitle: "",
                                faqTitle: "",
                                searchTerm: "",
                                lobActivity: "",
                                status: "",
                                eventName: "Using Mobile Number"
                            }
                        }
                        analytics.trackData("loginSuccess", analytics_data)
                    }
                })
            } else {
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }
        }).catch((error) => {
            setOtp("")
            if (error === "03") {
                setFailurePopUpContent({
                    apiName: "verify-otp/v1",
                    message: "We are experiencing technical difficulty in OTP services. Please try after sometime",
                    errorCode: error
                })
                setFailurePopUpModal(true)
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            } else if (error === "otp invalid") {
                setoptError("Incorrect OTP Entered")
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            } else {
                setFailurePopUpContent({
                    apiName: "verify-otp/v1",
                    message: "We are experiencing technical difficulty in OTP services. Please try after sometime",
                    errorCode: ""
                })
                setFailurePopUpModal(true)
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                })
            }

        })
        /* if (userInput.search("@") > -1) {
            requestObj = {
                "header": {
                    "grantType": "otp",
                    "serviceType": "email",
                    "authToken": data.authToken,
                    "context": "login"
                },
                "body": {
                    "emailId": userInput,
                    "otpRef": refNo,
                    "otp": otp
                }
            }
        } else {
            requestObj = {
                "header": {
                    "grantType": "otp",
                    "serviceType": "mobile",
                    "authToken": data.authToken,
                    "context": "login"
                },
                "body": {
                    "mobileNo": userInput,
                    "otpRef": refNo,
                    "otp": otp
                }
            }

        }
        validateOtp(requestObj).then((response) => {
            console.log(response);
            if (response.header.status == "SUCCESS") {
                if (response.body.retStatus == "SUCCESS") {
                    if (pageName === "forgotUserID") {
                        callGetUserIdApi()
                    } else if (pageName === "forgotPassword") {
                        onEnterPassword();
                    } else if (pageName === "mobileEmailLogin") {
                        dispatchData({
                            type: STOREDATA,
                            name: "authToken",
                            payload: response.header.authToken
                        })
                        callGetUserDataV3(response.header.authToken);
                    }
                }
            } else {
                if (response.body.statusCode == -1) {
                    setoptError("Incorrect OTP Entered Please Enter Correct Otp")
                } else {
                    setoptError("Incorrect OTP Entered Please Enter Correct Otp")
                }

            }
        }).catch(((error) => {
            console.log(error);
        })) */
    }

    const backBtnRedirect = () => {
        if (pageName === "forgotUserID") {
            onForgotUserID();
        } else if (pageName === "mobileEmailLogin") {
            setcaptcha("")
            getcaptchafunction()
            onMobileEmail()
        } else if (pageName === "forgotPassword") {
            onForgotPasswordCredentials()
        }
    };

    let encryptedEmail;
    let encryptedMobile;
    let subString = "@"
    // console.log(userInput)

    if (userInput.search("@") > -1) {
        const discardedEmail = userInput.slice(
            2,
            userInput.indexOf("@")
        );
        const discardedEmailArray = discardedEmail?.split("");
        const discardedEmailMap = discardedEmailArray?.map((item) => "*");
        const discardedEmailString = discardedEmailMap?.join("");

        encryptedEmail = userInput.slice(0, 2)
            .concat(
                discardedEmailString,
                userInput.slice(
                    userInput.indexOf("@"),
                    userInput.length
                )
            );
    } else {
        const discardedEmail = userInput.slice(
            2,
            6
        );
        const discardedEmailArray = discardedEmail?.split("");
        const discardedEmailMap = discardedEmailArray?.map((item) => "*");
        const discardedEmailString = discardedEmailMap?.join("");

        encryptedMobile = "**"
            .concat(
                discardedEmailString,
                userInput.slice(
                    6,
                    userInput.length
                )
            );
    }

    const resendOtp = () => {
        if (!OTPResendError) {
            setOTPTimer(30)
            setOnCallFlag(false)
            if (resendOtpChance < 3) {
                setResendOtpChance((resendOtpChance) =>
                    resendOtpChance += 1
                )
                var serviceType
                if (userInput.search("@") > -1) {
                    serviceType = "email"
                } else {
                    serviceType = "mobile"
                }
                dispatchData({
                    name: "loaderFlag",
                    payload: true,
                    type: STOREDATA
                })
                let requestJson = {}
                if (serviceType === "mobile") {
                    requestJson = {
                        "header": {
                            "grantType": "otp",
                            "serviceType": serviceType,
                            "journeyType": "postLogin"
                        },
                        "body": {
                            "mobileNo": userInput,
                        }
                    }
                } else {
                    requestJson = {
                        "header": {
                            "grantType": "otp",
                            "serviceType": serviceType,
                            "journeyType": "postLogin"
                        },
                        "body": {
                            "emailId": userInput,
                        }
                    }
                }
                LoginServiceObject.callGererateOtpService(requestJson, serviceType).then((generateOtpResponse) => {
                    setRefNo(generateOtpResponse.body.otpRefNo)
                    setTempAuthToken(generateOtpResponse.header.authToken)
                    setSuccessPopUpContent("New OTP is generated")
                    setSuccessPopUpModal(true)
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                }).catch((error) => {
                    if (error.toLowerCase() === "number of attempts exceeded") {
                        setFailurePopUpContent({
                            apiName: "",
                            message: "Please login with User ID and Password.",
                            errorCode: ""
                        })
                        setFailurePopUpModal(true)
                        dispatchData({
                            name: "loaderFlag",
                            payload: false,
                            type: STOREDATA
                        })
                    }
                    dispatchData({
                        name: "loaderFlag",
                        payload: false,
                        type: STOREDATA
                    })
                })
            } else {
                setOTPResendError(true)
            }
        }

    }


    return (
        <div className="otp-container">
            {failurePopUpModal ? <FailurePopUp
                onClick={() => onClosePopUp()}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {successPopUpModal ? <SuccessPopUp
                onClick={() => setSuccessPopUpModal(false)}
                visible={successPopUpModal}
                setSuccessModalStat={setSuccessPopUpModal}
                successPopUpContent={successPopUpContent}
            /> : null}
            <div className="otp-container-backBtn">
                <Button
                    className={"login-ui-btns login-btn-3"}
                    onClick={backBtnRedirect}
                >
                    {themeState === THEME_LIGHT ? (
                        <img
                            src={"assets/images/backButtonArrow.svg"}
                            alt="backButtonArrow"
                        />
                    ) : (
                        <img
                            src={"assets/images/lightBackButtonArrow.svg"}
                            alt="lightBackButtonArrow"
                        />
                    )}
                    <label>Back</label>
                </Button>
            </div>

            <div className="otp-container-otpEnter">
                <div className="otp-container-otpEnter-section-1">
                    <h2>Enter OTP</h2>
                    <div></div>
                </div>
                <div className="otp-container-otp-sections">
                    <div className="otp-container-otpEnter-section-2">
                        {onCallFlag ? <span>
                            We’ve called your registered mobile number to provide you an OTP.
                        </span> : userInput.search("@") > -1 ? <span>
                            We've sent a 6 digit OTP to your registered Email ID{" "}
                            {encryptedEmail}
                        </span> : <span>
                            We've sent a 6 digit OTP to your registered mobile
                            number ending with {encryptedMobile}
                        </span>}
                    </div>
                    <div className="otp-container-otpEnter-section-3">
                        {!OTPResendError && <span>OTP Valid for {otpTimer} secs</span>}
                    </div>
                    <div className="otp-container-otpEnter-section-4">
                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            separator={<span style={{ width: "15px" }}></span>}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            isInputSecure={true}
                            inputStyle={{
                                border: "none",
                                outline: "none",

                                borderBottom: "1px solid #95A5BF",
                                width: "100%",
                                height: "40px",
                                fontSize: "16px",
                                fontWeight: "400",
                                caretColor: "blue",
                            }}
                        />
                        {
                            optError ? <div className="optError">{optError}</div> : <div className="optError"></div>
                        }

                    </div>
                    <div className="otp-container-otpEnter-section-3">
                        {mobileOrEmail === "mobile" ?
                            <span>
                                Sending OTP via call in {callOtpTimer} secs
                            </span> :

                            // <span> Didn't receive any OTP? <p className={`${otpTimer === 0 ? "dynamicResend" : "staticResend"} `} onClick={resendOtp}> Resend Now</p></span>
                            <span> Didn't receive any OTP? {otpTimer === 0 && !OTPResendError ? <p className="dynamicResend" onClick={resendOtp}> Resend Now</p> : <p className="staticResend">Resend Now</p>}
                            </span>
                        }




                    </div>
                </div>

            </div>

            <div className="otp-container-otpVerify">

                {OTPResendError && <span><p className="resendErrorAttempt">You have Reached Max OTP limit</p></span>}
                {/*  <span>
                    Didn't receive any OTP?
                    {resendFlag ? <p className="dynamicResend" onClick={resendOtp}> Resend Now</p> : <p className="staticResend">Resend Now</p>}
                </span>
                 {userInput.search("@") > -1 ? null : onCallFlag ? "" : <p className="onCallOtp" onClick={onCallOTP}>Get OTP via call</p>} */}
            </div>
        </div>
    );
};

OTP.propTypes = {
    onMobileEmail: PropTypes.func,
    onEnterPassword: PropTypes.func,
    onForgotPasswordCredentials: PropTypes.func,
    onForgotUserID: PropTypes.func,
    onUserID: PropTypes.func,
    userInput: PropTypes.string,
    refNo: PropTypes.string,
    mobileOrEmail: PropTypes.string,
    tempAuthToken: PropTypes.string,
    setTempAuthToken: PropTypes.func,
    setUserId: PropTypes.func,
    pageName: PropTypes.string,
    setRefNo: PropTypes.func,
    setcaptcha: PropTypes.func,
    getcaptchafunction: PropTypes.func


}

export default OTP;
