export function maskDetails(userInput) {
    userInput = userInput.toString();
    if (userInput.search("@") > -1) {
        const discardedEmail = userInput.slice(2, userInput.indexOf("@"));
        const discardedEmailArray = discardedEmail?.split("");
        const discardedEmailMap = discardedEmailArray?.map(() => "*");
        const discardedEmailString = discardedEmailMap?.join("");
        return {encryptedValue :userInput.slice(0, 2).concat(discardedEmailString, userInput.slice(userInput.indexOf("@"), userInput.length)) , context: "email"}
    } else {
        const discardedEmail = userInput.slice(2, 6);
        const discardedEmailArray = discardedEmail?.split("");
        const discardedEmailMap = discardedEmailArray?.map(() => "*");
        const discardedEmailString = discardedEmailMap?.join("");
        return {encryptedValue :"**".concat(discardedEmailString, userInput.slice(6, userInput.length)), context:"mobile"}
    }
}