import axios from "axios";
import { useNavigate } from "react-router-dom";
import logoutUser from "../../apis/logout-user/logout-user";
import DataMapping from "../../../../Shared/context/data-mapping";
import LogoutServiceObject from "../../services/LogoutService";
import { redirect } from "react-router-dom"


const stalwartApiCall = (method, data, url, headers = {}) => {

    const axiosRequest = {
        method: method,
        data: data,
        url,
        headers: {
            ...headers
        }
    }
    return new Promise((resolve, reject) => {
        return axios(axiosRequest).then((response) => {

            switch (response.status) {
                case 200:
                    resolve(response);
                    break;
                case 401:
                    let authToken = axiosRequest.data.header.authToken;
                    if (axiosRequest.url.toLowerCase().search('logout-user') !== -1) {
                        redirect('/login');
                    } else {
                        let requestJson = {
                            "header": {
                                "authToken": authToken
                            },
                            "body": {}
                        }
                        logOutApiCall(requestJson);
                    }
                    break;
                default:
                    reject(response);
            }
        }).catch(error => {
            switch (error.response.status) {
                case 200:
                    reject(error);
                    break;
                case 401:
                    let authToken = axiosRequest.data.header.authToken;
                    if (axiosRequest.url.toLowerCase().search('logout-user') !== -1) {
                        redirect("login");
                    }else{
                        let requestJson = {
                            "header": {
                                "authToken": authToken
                            },
                            "body": {}
                        }
                        logOutApiCall(requestJson);
                        redirect("login");
                    }
                    break;
                default:
                    reject(error);
            }
            reject(error);
        })
    })
}

const logOutApiCall = (reqObj) => {
    LogoutServiceObject.logOutUserService(reqObj).then((response) => {
        console.log("Logut");
    }).catch(() => {
        redirect("login")
    });
    redirect("login")
}

export default stalwartApiCall;