import React from 'react';
import './footer.scss';

const Footer = () => {
  return (
    <div className='footer_wrapper'>
        <div className="copyright">
            <p>Copyright © 2023 TATA CAPITAL LIMITED </p>
        </div>
    </div>
  )
}

export default Footer