import AcceptElockerTc from "../../apis/Accept-elocker-tc"
import createFolderAndUploadDoc from "../../apis/create-folder-and-upload-doc/create-folder-and-upload-doc"
import getDocumentId from "../../apis/get-document-by-id/get-document-by-id"
import getDocumentList from "../../apis/get-document-list/get-document-list"
import commonFunctionObject from "../../common-function"

const ElockerServiceObject = {
}

var mimetypeJson = {
    'doc': "application/msword",
    'xls': "application/vnd.ms-excel",
    'jpg': "image/jpeg",
    'jpeg': "image/jpeg",
    'png': 'image/png',
    'bmp': 'image/bmp',
    'gif': 'image/gif',
    'pdf': 'application/pdf',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

const acceptTnCService = (userData, authToken) => {
    return new Promise((resolve, reject) => {
        var reqObj = {
            "header": {
                "authToken": authToken
            },
            "body": {
                "data": [
                    {
                        "column_name": "eLocker_TnC",
                        "column_value": "Y"
                    }
                ],
                "condition": {
                    "column_name": "USER_ID",
                    "column_value": userData.USER_ID
                }
            }
        }
        AcceptElockerTc(reqObj).then((response) => {
            acceptTnCHandler(response)
        }).catch((error) => {
            // serviceError = {
            //   "type": "api-failure: update-user-info/v1",
            //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            //   "code": "9001-A",
            //   "errorId": "64"

            // }
        })
        function acceptTnCHandler(rspObj) {
            if (rspObj?.header?.status === "SUCCESS") {
                if (rspObj?.body?.result) {
                    resolve()
                    // setUserData({ ...userData, "ELOCKER_TNC": "Y" });
                } else if (rspObj.errorBody) {
                    // serviceError = {
                    //   "type": "api-failure:update-user-info/v1",
                    //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    //   "code": "9001-B",
                    //   "errorId": "65"

                    // }
                    reject("65")
                } else {
                    // serviceError = {
                    //   "type": "api-failure:update-user-info/v1",
                    //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    //   "code": "9001-B",
                    //   "errorId": "65"

                    // }
                    reject("65")
                }
            } else {
                // serviceError = {
                //   "type": "api-failure:update-user-info/v1",
                //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                //   "code": "9001-B",
                //   "errorId": "65"

                // }
                reject("65")
            }
        }
    })


}
ElockerServiceObject.acceptTnCService = acceptTnCService

const getDocumnetByListService = (userData, authToken) => {
    return new Promise((resolve, reject) => {
        var reqObj = {

            "header": {
                "authToken": authToken
            },
            "body": {
                "lockerNo": userData.GCID
            }

        }
        getDocumentList(reqObj).then((response) => {
            if (response.header.status === "SUCCESS") {
                var docResp = response.body["ns2:geteLOCKERDocListResponse"].return.TCL;
                var rspType = Object.keys(docResp);
                console.log(rspType);
                if (rspType[0] === "eLOCKER") {
                    //use hasown property
                    // docListSuccessHandler(docResp.eLOCKER.DocumentType);
                    // setLockerdata(docResp.eLOCKER.DocumentType)
                    resolve(docResp.eLOCKER.DocumentType)
                } else if (rspType[0] === "InvalidRequestResponse") {
                    resolve([])
                    // setLockerdata([])
                } else {
                    console.warn("response type is not defined");
                }
            } else {
                // serviceError = {
                //   "type": "api-failure: get-document-list/v1",
                //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                //   "code": "9002-B",
                //   "errorId": "67"

                // }
                reject("67")
            }

        }).catch((error) => {
            // serviceError = {
            //   "type": "api-failure: get-document-list/v1",
            //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            //   "code": "9002-A",
            //   "errorId": "66"

            // }
            reject("66")
        })
    })
}

ElockerServiceObject.getDocumnetByListService = getDocumnetByListService


const downloadDocumentService = (obj, authToken) => {
    return new Promise((resolve, reject) => {
        let reqObj = {
            "header": {
                "authToken": authToken
            },
            "body": {
                "documentId": obj.r_object_id
            }
        }

        getDocumentId(reqObj).then((response) => {
            if (response.header.status.toLowerCase() === "success") {
                const resp = response.body.docExtractbyIdResponse.docExtractbyIdReturn.TCL;
                if (resp.ResultType.toLowerCase() === "success") {
                    let base64Convert = commonFunctionObject.base64ToArrayBuffer(resp.DMSRESPONSE)
                    let mimeType;
                    let mimeTypeObj = Object.keys(mimetypeJson);
                    mimeTypeObj.forEach(function (value) {
                        if (value === obj.type) {
                            mimeType = mimetypeJson[value];
                        }
                    });
                    let fileName = obj.filename + "." + obj.content_type;
                    commonFunctionObject.saveByteArray(fileName, base64Convert, mimeType);
                    resolve()
                } else {
                    // serviceError = {
                    //   "type": "api-failure: get-document-by-id/v1",
                    //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    //   "code": "9003-C",
                    //   "errorId": "84"

                    // }
                    reject("84")
                }
            } else {
                // serviceError = {
                //   "type": "api-failure:get-document-by-id/v1",
                //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                //   "code": "9003-B",
                //   "errorId": "69"

                // }
                reject("69")

            }
        }).catch((error) => {
            console.log(error);
            // serviceError = {
            //   "type": "api-failure: get-document-by-id/v1",
            //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
            //   "code": "9003-A",
            //   "errorId": "68"

            // }
            reject("68")
        })
    })
}
ElockerServiceObject.downloadDocumentService = downloadDocumentService




const createFolderAndUploadDocService = (uploadedFile, userData, option, authToken) => {

    return new Promise((resolve, reject) => {

        commonFunctionObject.convertToBase64([uploadedFile], true).then(function (fileBase64StrArr) {
            var base64Doc = fileBase64StrArr[0];
            var reqObj = {
                "header": {
                    "authToken": authToken
                },
                "body": {
                    "gcid": userData.GCID,
                    "documentUploadType": uploadedFile.name,
                    "base64": base64Doc,
                    "mimeType": uploadedFile.type
                }
            }

            createFolderAndUploadDoc(reqObj, option).then((response) => {
                console.log(response);
                if (response.header.status === "SUCCESS") {
                    resolve(response.body.message)
                } else {
                    // console.log("Response Header Status Undefined ");
                    // // popOpen("sorry-pop");
                    // serviceError = {
                    //   "type": "api-failure: create-folder-and-upload-doc/v1",
                    //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                    //   "code": "9004-B",
                    //   "errorId": "71"

                    // }
                    // setAnalyticsErrorLog();
                    // displayErrorModal(serviceError);
                    reject("71")
                }

            }).catch((error) => {
                reject()
                // serviceError = {
                //   "type": "api-failure: create-folder-and-upload-doc/v1",
                //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                //   "code": "9004-A",
                //   "errorId": "70"

                // }
                reject("70")
            })
        })


    })
}

ElockerServiceObject.createFolderAndUploadDocService = createFolderAndUploadDocService


export default ElockerServiceObject

