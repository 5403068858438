import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const validateOtp = (data) => {

    let requestJson = {
        "header": {
            "grantType": "otp",
            "serviceType": "mobile",
            "authToken": "temp-mobile-9594779789-5ecc2337fe7381740fb9ef3a220e05f64b932dab4281ac076796230fb40863fc",
            "context": "login"
        },
        "body": {
            "mobileNo": "9594779789",
            "otpRef": "135793",
            "otp": "888888"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/login/validate-otp/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/login/validate-otp/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default validateOtp;